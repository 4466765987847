import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Modal } from "@thingsw/pitta-design-system";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  CAMERA,
  deleteCamera,
  loadCamera,
  loadCameras,
  loadFirmwareInfos,
  rebootCamera,
  updateCameras,
} from "../features/Camera/slice";
import { loadSubscription } from "../features/Payment/slice";
import { RootState } from "../features/store";
import { CameraAddScreen } from "../screens/CameraAddScreen";
import { CameraPlaybackScreen } from "../screens/CameraPlaybackScreen";
import { CameraFirmwareUpdateScreen } from "../screens/CameraRemoteFirmwareUpdateScreen";
import { CameraScreen } from "../screens/CameraScreen";
import { DMC200FirmwareUpdateScreen } from "../screens/DMC200FirmwareUpdateScreen";
import { CameraSettingRouter } from "./CameraSettingRouter";
import { getFirmwareConfig } from "../utils/Firmware";
import { EditProfileModal } from "../components/uxui/EditProfileModal";
import { OnlyWifiModelRenameModal } from "../components/uxui/OnlyWifiModelRenameModal";
import {
  Webviewer,
  ICameraInfo,
  ENABLED_CLOUD_OLD_MODELS,
  OLD_MODELS,
} from "@thingsw/pitta-modules";
import { RemoveCloudModal } from "../components/modals/RemoveCloudModal";
import { loadGroups } from "../features/Group/slice";
import { RemoveCloudSuccessModal } from "../components/modals/RemoveCloudSuccessModal";
import { LIVEVIEW } from "../features/LiveView/slice";

export const CameraRouter = (props: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { path } = useRouteMatch();
  const psnMatch = useRouteMatch<{ psn: string }>(`${path}/:psn`);
  const liveViewMatch = useRouteMatch<{ psn: string }>(
    `${path}/:psn/live-view`
  );
  const gpsTrackingMatch = useRouteMatch<{ psn: string }>(
    `${path}/:psn/gps-tracking`
  );
  const settingsMatch = useRouteMatch<{ psn: string }>(`${path}/:psn/settings`);
  const dmsReportMatch = useRouteMatch<{ psn: string }>(`${path}/:psn/report`);

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { loading, type, cameraList } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const { multiliveview } = useSelector((state: RootState) => state[LIVEVIEW]);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRebootCamera, setOpenRebootCamera] = useState(false);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [camera, setCamera] = useState<ICameraInfo>();
  const [renameMode, setRenameMode] = useState<"profile" | "rename">("profile");
  const [, /*camName*/ setCamName] = useState("");
  const [removeMode, setRemoveMode] = useState<"remove" | "delete">("remove");
  const [openRemoveCloudSuccess, setOpenRemoveCloudSuccess] = useState(false);
  const [openRemoveCloud, setOpenRemoveCloud] = useState(false);
  const [removeCam, setRemoveCam] = useState<ICameraInfo>();
  // const [camNameError, setCamNameError] = useState(false);
  // const [camNameLengthErr, setCamNameLengthErr] = useState(false);

  useEffect(() => {
    function tick() {
      dispatch(updateCameras());
    }

    let timer: NodeJS.Timeout | null = null;
    let id = setInterval(tick, 30 * 1000);
    if (location.pathname.indexOf("live-view") > -1) {
      timer = setTimeout(tick, 500);
    } else if (!location.pathname.endsWith("/cameras")) {
      tick();
    }

    return () => {
      clearInterval(id);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (cameraList && psnMatch) {
      const found = _.find(
        cameraList?.deviceListInfo,
        (dev) => dev.device.psn === psnMatch.params.psn
      )?.device;
      setCamera(found);
      // mantis - 11504, cam rename 후 카메라이름 모달창 재오픈시 이전이름 남아있던 이슈 수정 (Leehj)
      setCurrentCam(found);
      if (psnMatch.params.psn !== "add-camera") {
        if (!found) {
          history.replace("/cameras");
        }
      }
    }
  }, [cameraList, history, psnMatch, psnMatch?.params.psn]);

  useEffect(() => {
    dispatch(loadFirmwareInfos());
    dispatch(loadSubscription());
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      setOpenDeleteModal(false);
      setOpenRenameModal(false);
      setOpenRebootCamera(false);
    }
  }, [loading]);

  const UXUI = useMemo(() => {
    if (camera) {
      return !!getFirmwareConfig(camera)?.UXUI;
    }
    return undefined;
  }, [camera]);

  // const handleCamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   // if (value.length <= 30) {
  //   setCamName(value);
  //   // }
  // };

  const handleRename = useCallback(
    (cam: ICameraInfo) => {
      setCurrentCam(cam);
      if (location.pathname === "/cameras") {
        setCamName(cam.dev_name);
      } else {
        camera && setCamName(camera?.dev_name);
      }
      if (cam) {
        if (
          // _.includes(ENABLED_CLOUD_OLD_MODELS, cam?.model) ||
          // _.includes(NEW_MODELS, cam?.model) ||
          // _.includes(SIGMASTAR_MODELS, cam?.model)
          // "NEW_MODELS" 코드 제거, 클라우드가 가능하지 않은 구모델을 제외한 모든 모델로 수정 (hongcs 24.03.25)
          !_.includes(
            _.filter(OLD_MODELS, (d) => !ENABLED_CLOUD_OLD_MODELS.includes(d)),
            cam?.model
          )
        ) {
          setRenameMode("profile");
        } else {
          setRenameMode("rename");
        }
        setOpenRenameModal(true);
      }
    },
    [camera, location.pathname]
  );

  const handleDelete = useMemo(() => {
    console.log("handleRemove");
    return props.deleteCamPerm
      ? (camera: ICameraInfo) => {
          if (camera.reg_category === "wifi") {
            setRemoveMode("delete");
          } else {
            setRemoveMode("remove");
          }

          setOpenRemoveCloud(true);
          setRemoveCam(camera);
        }
      : undefined;
  }, [props.deleteCamPerm]);

  const handleRebootCamera = useCallback((cam: ICameraInfo) => {
    setCurrentCam(cam);
    setOpenRebootCamera(true);
  }, []);

  const renderScreen = useCallback(() => {
    if (liveViewMatch && (camera?.active === "on" || multiliveview)) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="live-view"
          psn={liveViewMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (gpsTrackingMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="gps-tracking"
          psn={gpsTrackingMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (settingsMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="settings"
          psn={settingsMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (dmsReportMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="report"
          psn={dmsReportMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (psnMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="playback"
          psn={psnMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
  }, [
    camera?.active,
    dmsReportMatch,
    gpsTrackingMatch,
    handleDelete,
    handleRebootCamera,
    handleRename,
    liveViewMatch,
    multiliveview,
    props,
    psnMatch,
    settingsMatch,
  ]);

  const renderCameraScreen = useCallback(() => {
    return (
      <CameraScreen
        {...props}
        onRename={handleRename}
        onDelete={handleDelete}
        onRebootCamera={handleRebootCamera}
        // 카메라스크린헤더, mode와 searchKey를 알기위한 props
        webMode={props.mode}
        webSearchKey={props.webSearchKey}
      />
    );
  }, [props, handleRename, handleDelete, handleRebootCamera]);

  return (
    <>
      <Switch>
        <Route exact path={path} render={renderCameraScreen} />
        <Route path={`${path}/add-camera`} component={CameraAddScreen} />
        <Route path={`${path}/:psn/playback`} render={renderScreen} />
        <Route path={`${path}/:psn/live-view`} render={renderScreen} />
        <Route path={`${path}/:psn/gps-tracking`} render={renderScreen} />
        {UXUI === true ? (
          <Route
            path={`${path}/:psn/settings`}
            render={() => (
              <CameraSettingRouter
                {...props}
                mode="settings"
                psn={psnMatch?.params.psn}
                onRename={handleRename}
                onDelete={handleDelete}
                onRebootCamera={handleRebootCamera}
              />
            )}
          />
        ) : (
          <Route path={`${path}/:psn/settings`} render={renderScreen} />
        )}

        <Route path={`${path}/:psn/report`} render={renderScreen} />
        <Route
          path={`${path}/:psn/fota`}
          render={() => (
            <CameraFirmwareUpdateScreen {...props} psn={psnMatch?.params.psn} />
          )}
        />
        <Route
          path={`${path}/:psn/fota-dmc200`}
          render={() => (
            <DMC200FirmwareUpdateScreen {...props} psn={psnMatch?.params.psn} />
          )}
        />
      </Switch>

      {/* mantis - 11425, 카메라 테이블에서 항상 Rename모달이 출력되는 이슈 수정 (Leehj) */}
      <EditProfileModal
        open={openRenameModal && renameMode === "profile"}
        camera={currentCam}
        onClose={() => setOpenRenameModal(false)}
        onSuccess={() => {
          setOpenRenameModal(false);
          dispatch(loadCameras());
          // mantis - 11504, state[CAMERA].camera의 dev_name이 변경되지 않는 이슈 수정 (Leehj)
          if (currentCam) {
            dispatch(loadCamera(currentCam.psn));
          }
        }}
      />
      {/* mantis - 10379, ONLY Wi-Fi모델은 rename 모달창 출력 (Leehj) */}
      <OnlyWifiModelRenameModal
        open={openRenameModal && renameMode === "rename"}
        camera={currentCam}
        onClose={() => setOpenRenameModal(false)}
      />

      <Modal
        open={openDeleteModal}
        mobile={mobile}
        onClose={() => setOpenDeleteModal(false)}
        onClickNegative={() => setOpenDeleteModal(false)}
        onClickPositive={() =>
          currentCam && dispatch(deleteCamera(currentCam.psn))
        }
        heading={t("Delete")}
        close
        loading={loading && type === deleteCamera.type}
        content={`Are you sure you want to delete this camera?<br/>
        <b>Warning: You can’t undo this action. All your cloud files will be lost.<b>`}
        LButton="Cancel"
        RButton="Delete"
        Secondary
      />
      <Modal
        open={openRebootCamera}
        mobile={mobile}
        onClose={() => setOpenRebootCamera(false)}
        onClickNegative={() => setOpenRebootCamera(false)}
        onClickPositive={() => currentCam && dispatch(rebootCamera(currentCam))}
        heading={t("Reboot Camera")}
        close
        loading={loading && type === rebootCamera.type}
        content={t("Camera will be rebooted_")}
        LButton={t("No")}
        RButton={t("Yes")}
      />

      <RemoveCloudModal
        open={openRemoveCloud}
        camera={removeCam}
        onClose={() => setOpenRemoveCloud(false)}
        onSuccess={() => {
          if (removeCam?.reg_category !== "wifi") {
            setOpenRemoveCloudSuccess(true);
          }
          setOpenRemoveCloud(false);
          dispatch(loadCameras());
          // mantis - 11006, RemoveCloud 성공시 loadGroups실행하여 RemoveCloud한 캠이 캠리스트에 출력되도록 수정 (Leehj)
          dispatch(loadGroups());
        }}
        mode={removeMode}
      />
      <RemoveCloudSuccessModal
        open={openRemoveCloudSuccess}
        onClose={() => setOpenRemoveCloudSuccess(false)}
      />
    </>
  );
};
