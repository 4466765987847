import React from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import { LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    minHeight: 32,
    padding: theme.spacing(0, 2),
  },
  checkIcon: {
    color: LightColors.primary["7"],
    fontSize: 20,
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.375) }
      : { marginLeft: theme.spacing(0.375) }),
  },
  typo2: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(3) }
      : { marginLeft: theme.spacing(3) }),
  },
  typy2Check: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(2.5) }
      : { marginLeft: theme.spacing(2.5) }),
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
}));

export const MiniAddCameraHeader = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography
        category="Default"
        variant={path === "/cameras/add-camera-sim" ? "CaptionBold" : "Caption"}
        htmlColor={
          path === "/cameras/add-camera-sim" ||
          path === "/cameras/choose-connection" ||
          path === "/cameras/connect-to-cloud"
            ? LightColors.primary["7"]
            : LightColors.primary["1"]
        }
        className={classes.nowrap}
      >
        1. {t("Add camera")}
      </Typography>

      {path !== "/cameras/add-camera-sim" && (
        <CheckCircleOutlineIcon className={classes.checkIcon} />
      )}

      <Typography
        category="Default"
        variant={
          path === "/cameras/choose-connection" ||
          path === "/cameras/connect-to-cloud"
            ? "CaptionBold"
            : "Caption"
        }
        htmlColor={
          path === "/cameras/choose-connection" ||
          path === "/cameras/connect-to-cloud"
            ? LightColors.primary["7"]
            : LightColors.primary["1"]
        }
        className={clsx(classes.typo2, classes.nowrap, {
          [classes.typy2Check]: path !== "/cameras/add-camera-sim",
        })}
      >
        2. {t("Connect to cloud")}
      </Typography>
    </div>
  );
};
