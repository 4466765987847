// 리액트 기능 임포트
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
// -- 다국어 지원 텍스트 컴포넌트
import { useTranslation } from "react-i18next";
// -- 디자인 시스템
import { Button, IconButton } from "@thingsw/pitta-design-system";
import { Typography } from "@thingsw/pitta-design-system";

// -- 아이콘
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import axios from "axios";
import clsx from "clsx";
import { FirmwareCautionModal } from "./modals/FirmwareCautionModal";
import {
  ICameraInfo,
  IFirmwareInfo,
  LightColors,
  Webviewer,
} from "@thingsw/pitta-modules";

// CSS 스타일
const useStyles = makeStyles((theme: Theme) => ({
  mobileCamNameDiv: {
    display: "flex",
    justifyContent: "center",
  },

  // 상단 firmware update info
  updateInfoDiv: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
    },
  },
  bgDeactive: {
    backgroundColor: `${LightColors.primary["6"]}73`,
  },
  bgActive: {
    backgroundColor: LightColors.primary["10"],
  },
  title: {
    marginBottom: theme.spacing(1.75),
  },
  BtnDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginTop: theme.spacing(2.25),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
      flexDirection: "row",
      marginTop: theme.spacing(1.5),
    },
  },
  openBtn: {
    display: "flex",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
    },
  },
  iconBtn: {
    color: LightColors.primary["7"],
    marginLeft: theme.spacing(0.5),
  },

  releaseNoteInfoDiv: {
    backgroundColor: LightColors.primary["0"],
    opacity: 0.75,
    padding: theme.spacing(1),
    minHeight: 216,
    // overflowY: "auto",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: theme.spacing(1.5),
    },
  },

  liStyle: {
    color: LightColors.primary["2"],
  },

  scrollbarStyle: {
    height: "100%",
    overflowX: "hidden",
    flex: 1,
    maxHeight: "calc(100vh - 120px)",
  },

  // 하단 DMC200 update info
  dmcUpdateInfoDiv: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    borderRadius: 4,
  },
  dmcTitle: {
    marginBottom: theme.spacing(1.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(2.25),
    },
  },
  dmcBtnDiv: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2.25),
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },

  // 1-1-2.업데이트 버튼
  updateBtn: {
    whiteSpace: "nowrap",
    width: "100%",
    height: "36px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      width: "151px",
    },
  },
  // 1-1-2-1.버튼 텍스트 사이즈 (font 규격 외)
  btnTxt: {
    height: "20px",
  },
}));

interface CameraFirmwareUpdateScreenProps {
  camera: ICameraInfo;
  firmware: IFirmwareInfo;
  newFw?: boolean;
  dmcFirmware: IFirmwareInfo;
  onUpdate: (fw: IFirmwareInfo, dmc?: boolean) => void;
}

////////////////////////////////////////////////////////////////////////////////////////
// 실제 컴포넌트
export const DMC200CameraRemoteFirmwareUpdate = (
  props: CameraFirmwareUpdateScreenProps
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    camera,
    firmware,
    newFw: newBlackvueFw,
    dmcFirmware,
    onUpdate,
  } = props;
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const [open, setOpen] = useState<boolean>(false);
  const [mainDashCamUpdate, setMainDashCamUpdate] = useState<boolean>(false);
  const [DMSInfoOpen, setDMSInfoOpen] = useState<boolean>(false);
  const [openCautionModal, setOpenCautionModal] = useState<boolean>(false);
  const [releaseNote, setReleaseNote] = useState<string>();
  const [dmcReleaseNote, setDmcReleaseNote] = useState<string>();

  useEffect(() => {
    const getReleaseNote = async (fw: IFirmwareInfo) => {
      const url =
        process.env.NODE_ENV === "development"
          ? fw.release_note.replace("http://fota.blackvuecloud.com", "/fota")
          : fw.release_note.replace("http://", "https://");

      const note = await axios.get(url);
      // eslint-disable-next-line no-control-regex
      setReleaseNote(note.data.replace(new RegExp("\n", "g"), "<br/>"));
    };

    if (firmware) {
      getReleaseNote(firmware);
    }
  }, [firmware]);

  useEffect(() => {
    const getReleaseNote = async (fw: IFirmwareInfo) => {
      const url =
        process.env.NODE_ENV === "development"
          ? fw.release_note.replace("http://fota.blackvuecloud.com", "/fota")
          : fw.release_note.replace("http://", "https://");

      const note = await axios.get(url);
      // eslint-disable-next-line no-control-regex
      setDmcReleaseNote(note.data.replace(new RegExp("\n", "g"), "<br/>"));
    };

    if (dmcFirmware) {
      getReleaseNote(dmcFirmware);
    }
  }, [dmcFirmware]);

  const handleUpdate = useCallback(() => {
    // dmc200연결 이력 있으면 주의modal open
    if (camera.dms_version) {
      setOpenCautionModal(true);
    } else {
      onUpdate(dmcFirmware, true);
    }
  }, [camera.dms_version, dmcFirmware, onUpdate]);

  //main dashCam
  const handleMaindashCamUpdate = useCallback(() => {
    // dmc200연결 이력 있으면 주의modal open
    // if (camera.dms_version) {
    //   setMainDashCamUpdate(true);
    //   setOpenCautionModal(true);
    // } else {
    //   onUpdate(firmware);
    // }
    //메인캠은 그냥 업데이트하도록 수정
    onUpdate(firmware);
  }, [firmware, onUpdate]);

  return (
    <div dir={theme.direction}>
      {mobile && (
        <div className={classes.mobileCamNameDiv}>
          <Typography
            category="Default"
            variant="SmallBold"
            htmlColor={LightColors.primary["2"]}
          >
            {camera?.dev_name}
          </Typography>
        </div>
      )}
      <div
        className={clsx(classes.updateInfoDiv, {
          [classes.bgActive]: newBlackvueFw,
          [classes.bgDeactive]: !newBlackvueFw,
        })}
      >
        <>
          <Typography category="Default" variant="H6" className={classes.title}>
            {newBlackvueFw
              ? // Mantis-8556 이슈 : en.json 에 문구 추가
                t("BlackVue’s New firmware_")
              : t("BlackVue’s firmware is up_")}
          </Typography>
          <Typography category="Default" variant="Body">
            [{camera.model}]
          </Typography>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            v{firmware.version}
          </Typography>
          <div className={classes.BtnDiv}>
            <div className={classes.openBtn} onClick={() => setOpen((o) => !o)}>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["7"]}
              >
                {t("Release note")}
              </Typography>
              <IconButton className={classes.iconBtn}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>

            {newBlackvueFw && (
              <Button
                className={classes.updateBtn}
                size="medium"
                color="primary"
                onClick={handleMaindashCamUpdate}
              >
                <Typography category="Default" variant="Body">
                  {t("Update")}
                </Typography>
              </Button>
            )}
          </div>
          {open && (
            <div className={classes.releaseNoteInfoDiv}>
              <SimpleBarReact style={{ maxHeight: 200 }}>
                <div>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["2"]}
                    dangerouslySetInnerHTML={{ __html: releaseNote ?? "" }}
                  />
                </div>
              </SimpleBarReact>
            </div>
          )}
        </>
      </div>

      <div
        className={clsx(classes.dmcUpdateInfoDiv, {
          [classes.bgActive]: !newBlackvueFw,
          [classes.bgDeactive]: newBlackvueFw,
        })}
        style={{ margin: theme.spacing(4, 0) }}
      >
        <>
          <Typography
            category="Default"
            variant="H6"
            className={classes.dmcTitle}
          >
            {t("DMS’s New firmware_")}
          </Typography>
          <Typography category="Default" variant="Body">
            [DMC200]
          </Typography>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            v{dmcFirmware.version}
          </Typography>
          <div className={classes.dmcBtnDiv}>
            <div
              className={classes.openBtn}
              onClick={() => setDMSInfoOpen((o) => !o)}
            >
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["7"]}
              >
                {t("Release note")}
              </Typography>
              <IconButton className={classes.iconBtn}>
                {DMSInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>

            <Button
              className={classes.updateBtn}
              size="medium"
              variant={newBlackvueFw ? "outlined" : "contained"}
              color="primary"
              disabled={newBlackvueFw}
              onClick={handleUpdate}
            >
              <Typography category="Default" variant="Body">
                {t("Update")}
              </Typography>
            </Button>
          </div>
          {DMSInfoOpen && (
            <div className={classes.releaseNoteInfoDiv}>
              <SimpleBarReact style={{ maxHeight: 200 }}>
                <div>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["2"]}
                    dangerouslySetInnerHTML={{ __html: dmcReleaseNote ?? "" }}
                  />
                </div>
              </SimpleBarReact>
            </div>
          )}
        </>
      </div>
      <ul style={{ paddingLeft: theme.spacing(3) }}>
        <li className={classes.liStyle}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            When two updates of BlackVue and DMS exist at the same time, please
            upgrade DMS separately after upgrading BlackVue for device
            interoperability.
          </Typography>
        </li>
        <li className={classes.liStyle}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            Wait for the camera to reboot and connect to the cloud before
            updating the firmware of the DMS camera.
          </Typography>
        </li>
      </ul>

      <FirmwareCautionModal
        open={openCautionModal}
        onClose={() => setOpenCautionModal(false)}
        onClickPositive={() => {
          if (mainDashCamUpdate) {
            onUpdate(firmware);
          } else {
            onUpdate(dmcFirmware, true);
          }
          setMainDashCamUpdate(false);
          setOpenCautionModal(false);
        }}
        onClickNegative={() => setOpenCautionModal(false)}
      />
    </div>
  );
};
