import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  Fonts,
  IconButton,
  Menu,
  RadioButton,
  Tooltip,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { MemberTable } from "@thingsw/pitta-design-system/dist/components/MemberTable";

import { EmptyItems } from "../components/EmptyItems";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { useHistory } from "react-router-dom";
import {
  deleteMembers,
  loadMemberPaging,
  MEMBER,
} from "../features/Member/slice";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";

import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { USER } from "../features/User/slice";
import { CameraMenu } from "../components/cameras/CameraMenu";
import { MobileCameraMenu } from "../components/MobileCameraMenu";
import { NoResults } from "../components/NoResults";
import { GROUP, loadGroups } from "../features/Group/slice";
import { CAMERA } from "../features/Camera/slice";
import { AutoSizer } from "react-virtualized";
import Scrollbars from "react-custom-scrollbars";
import {
  Webviewer,
  LightColors,
  IMembersInfo,
  ROWS_PER_PAGE,
  UserNames,
} from "@thingsw/pitta-modules";

export interface IMembersProps {
  email: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "auto",
    height: (props: ScreenDefaultProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
        : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
    marginTop: (props: ScreenDefaultProps) =>
      props.error ? 68 + 127 : 68 + 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) =>
        props.error ? 68 + 85 : 68 + 56,
    },
  },
  subHeaderDiv: {
    backgroundColor: LightColors.primary["0"],
    top: (props: ScreenDefaultProps) => (props.error ? 127 : 56),
    ...(theme.direction === "rtl"
      ? { left: 0, right: 0 }
      : { left: 235, right: 0 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
      margin: theme.spacing(0, 4),
      ...(theme.direction === "rtl"
        ? { left: 0, right: 235 }
        : { left: 235, right: 0 }),
    },
    position: "fixed",
    zIndex: 90,
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  subHeaderDivClosed: {
    left: 73,
  },
  subHeaderDivMobile: {
    left: 0,
  },
  subHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 68,
    backgroundColor: LightColors.primary["0"],
    padding: theme.spacing(0, 2),
    top: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0),
    },
  },
  btnDiv: {
    transition: theme.transitions.create("width"),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
    },
  },
  inviteBtn: {
    minWidth: 163,
    margin: theme.spacing(2, 0),
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    position: "absolute",
    zIndex: 2,
    backgroundColor: LightColors.primary["0"],
    ...(theme.direction === "rtl" ? { left: 16 } : { right: 16 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    },
  },
  searchBtnG: {
    width: "unset",
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    },
  },
  mobileSearchOpen: {
    marginRight: 0,
  },
  webSearchOpen: {
    marginRight: 0,
    width: "calc(100% - 32px)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  body: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    marginBottom: 0,
    // overflowY: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflowY: "hidden",
    },
  },
  listPane: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
  },
  alignStart: {
    alignItems: "flex-start",
  },
  iconBtn: {
    color: LightColors.primary["1"],
    padding: theme.spacing(0.375),
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(5.5) }
      : { marginRight: theme.spacing(5.5) }),
  },
  hidden: {
    display: "none",
  },
  tableDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 4),
      justifyContent: "unset",
    },
  },
  loadingDiv: {
    display: "flex",
    // @ts-ignore
    // eslint-disable-next-line no-dupe-keys
    display: "-webkit-flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    height: "100%",
  },
  modalPaper: {
    maxWidth: 438,
    backgroundColor: "red",
  },
  selectRoot: {
    display: "none",
  },
  actions: {
    marginLeft: 0,
  },
  caption: {
    ...Fonts.Default.Small,
    color: LightColors.primary["3"],
  },
  paginationDiv: {
    display: "flex",
    justifyContent: "flex-end",
    minHeight: 30,
    marginTop: theme.spacing(0.625),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 0,
    },
  },
  pageSortBtn: {
    minHeight: 24,
    minWidth: 69,
    padding: 0,
    marginRight: theme.spacing(1.625),
  },
  pageArrowBtn: {
    padding: 3,
    color: LightColors.primary["1"],
  },
  filterDiv: {
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 469,
    },
  },
  filterTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  filterListDiv: {
    flex: 1,
    padding: theme.spacing(1, 3),
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  filterItemDiv: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: 0,
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 421,
    },
  },
  radioBtn: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
  },
  radioFormControl: {
    ...(theme.direction === "rtl" ? { marginRight: 8 } : { marginLeft: 8 }),
  },
  groupFilterTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(2),
  },
  filterAppliedText: {
    marginTop: theme.spacing(14),
    textAlign: "center",
  },
  linkText: {
    cursor: "pointer",
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
  },
  noResultDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  noResultWrap: {
    display: "flex",
    flexGrow: 1,
    // mantis - 8526
    // noResult 결과 브라우져를 크기를 조정하여도 테이블 헤드를 밀어내지 않도록 수정.
    height: "calc(var(--vh, 1vh) * 100 - 50)",
  },
  searchBtnOpen: {
    width: "100%",
  },
  groupNoResultDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "calc(100vh - 620px)",
    [theme.breakpoints.up(800)]: {
      // height: "calc(100vh - 640px)",
    },
  },
  groupNoResult: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface MemberScreenProps {
  onDeleteMember?: (member: IMembersInfo) => void;
  onDeleteMembers?: (member: IMembersInfo[]) => void;
}

export const MembersScreen = (
  props: MemberScreenProps & ScreenDefaultProps
) => {
  const { openMenu, onDeleteMember, onDeleteMembers } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const history = useHistory();

  const { userProfile, email } = useSelector((state: RootState) => state[USER]);
  const { groupsList } = useSelector((state: RootState) => state[GROUP]);
  const groups = useSelector(
    (state: RootState) => state[GROUP].groupsList?.DashCamList
  );
  const cameras = useSelector((state: RootState) => state[CAMERA].cameraList);

  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [moreEmail, setMoreEmail] = useState<string>();
  const [anchorMoreRef, setAnchorMoreRef] = useState<
    React.RefObject<HTMLElement> | undefined
  >();

  const [openSearch, setOpenSearch] = useState(false);
  const [openGroupSearch, setOpenGroupSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [filterRole, setFilterRole] = useState("All");
  const [appliedRole, setAppliedRole] = useState("All");
  const [filterStatus, setFilterStatus] = useState("All");
  const [appliedStatus, setAppliedStatus] = useState("All");
  const [filterGroup, setFilterGroup] = useState("All");
  const [appliedGroup, setAppliedGroup] = useState("All");
  const [filterApplied, setFilterApplied] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const {
    type,
    loading,
    currentPage: reduxCurrentPage,
    memberList,
  } = useSelector((state: RootState) => state[MEMBER]);

  const [groupSearchKey, setGroupSearchKey] = useState("");

  useEffect(() => {
    dispatch(loadGroups());
  }, [dispatch]);

  useEffect(() => {
    if (reduxCurrentPage) {
      setCurrentPage(
        Math.floor((reduxCurrentPage.startIndex - 1) / ROWS_PER_PAGE)
      );
    }
  }, [reduxCurrentPage]);

  useEffect(() => {
    if (type === deleteMembers.type && !loading) {
      setOpenCamMenu(false);
    }
  }, [loading, type]);

  const requestMembers = useCallback(
    (key?: string) => {
      dispatch(
        loadMemberPaging({
          startIndex: 1 + currentPage * ROWS_PER_PAGE,
          endIndex: (currentPage + 1) * ROWS_PER_PAGE,
          ordering: sortOrder === "desc" ? 0 : 1,
          searchKey: key ? encodeURIComponent(key) : key,
        })
      );
    },
    [dispatch, currentPage, sortOrder]
  );

  useEffect(() => {
    if (memberList) {
      setMaxPage(Math.ceil(memberList.inviteMemberCount ?? 0 / ROWS_PER_PAGE));
    }
  }, [memberList]);

  useEffect(() => {
    requestMembers();
  }, [requestMembers]);

  useEffect(() => {
    if (
      appliedRole === "All" &&
      appliedStatus === "All" &&
      appliedGroup === "All"
    ) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
  }, [appliedRole, appliedStatus, appliedGroup]);

  const handlePageSort = () => {
    // setClickMenu((prevOpen) => !prevOpen);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback((event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
    // setClickMenu(false);
  }, []);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
      // setClickMenu(false);
    }
  }

  const handleClick = useCallback(
    (email) => {
      return history.push(`/members/${email}`);
    },
    [history]
  );

  const handleCheckOpenModal = useCallback(
    (emails: string[]) => {
      if (memberList && memberList.inviteMember) {
        const members = _.filter(memberList.inviteMember, (o) =>
          _.includes(emails, o.email)
        );
        if (members.length > 0) {
          onDeleteMembers?.(members);
        }
      }
    },
    [onDeleteMembers, memberList]
  );

  const handleOpenDeleteModal = useCallback(
    (email) => {
      if (memberList && memberList.inviteMember) {
        const member = _.find(
          memberList.inviteMember,
          (o) => o.email === email
        );
        if (member) {
          onDeleteMember?.(member);
        }
      }
    },
    [onDeleteMember, memberList]
  );

  const filteredGroups = useMemo(() => {
    return _.filter(
      groupsList?.DashCamList,
      (group) =>
        group.GroupName.toLowerCase().indexOf(groupSearchKey.toLowerCase()) > -1
    );
  }, [groupSearchKey, groupsList?.DashCamList]);

  const renderMemberList = useCallback(() => {
    let members = _.sortBy(memberList?.inviteMember ?? [], "dateInvited");
    if (appliedRole !== "All") {
      members = _.filter(members, (m) => m.role === appliedRole);
    }
    if (appliedStatus !== "All") {
      members = _.filter(members, (m) => m.mailStatus === appliedStatus);
    }
    if (appliedGroup !== "All" && appliedGroup !== "None") {
      members = _.filter(members, (m) => m.groupName === appliedGroup);
    } else if (appliedGroup === "None") {
      members = _.filter(members, (m) => m.groupName === "");
    }
    if (sortOrder === "desc") {
      members = _.reverse(members);
    }

    if (loading) {
      return (
        <div className={classes.loadingDiv}>
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    }
    if (
      (!loading && memberList?.inviteMember?.length === 0) ||
      memberList === undefined
    ) {
      if (searchKey) {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoResults />
          </div>
        );
      } else {
        return (
          <EmptyItems variant={mobile ? "small" : "default"} mode="member" />
        );
      }
    }

    const memberTableMarkup = (
      <MemberTable
        currentLoginEmail={email}
        filterNodata={filterApplied && members.length === 0}
        disableDelete={userProfile?.userType !== "Master"}
        members={members}
        groupList={groups}
        cameras={cameras}
        onClick={handleClick}
        onDeleteAll={handleCheckOpenModal}
        onClickMore={(email, anchor) => {
          setAnchorMoreRef(anchor);
          setMoreEmail(email);
          if (moreEmail === email) {
            setOpenCamMenu((o) => !o);
          } else {
            setOpenCamMenu(true);
          }
        }}
        t={t}
      />
    );

    return (
      <>
        {memberList &&
          memberList.inviteMember &&
          memberList.inviteMember.length > 0 &&
          !loading && (
            <div className={classes.tableDiv}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    overflowX: "auto",
                    height: members.length === 0 ? "" : "100%", // AutoSizer 높이를 100%로 주면 맴버리스트가 없음에도 스크롤바가 생기는 현상 방지를 위해 추가
                    // overflowY: "hidden",
                    minWidth: filterApplied && members.length === 0 ? 1000 : "",
                    // mantis - 8526
                    // 브라우져 화면을 조정하였을 때, 테이블 헤드를 noResult 템플릿이 덮어내어 최소 height 41px 값으로 수정함.
                    minHeight: "41px",
                  }}
                >
                  <AutoSizer>
                    {({ width, height }) =>
                      mobile ? (
                        memberTableMarkup
                      ) : (
                        <Scrollbars
                          style={{
                            height:
                              filterApplied || searchKey ? height - 10 : height, // 필터, 검색 적용 시 스크롤바가 생기는 현상 방지를 위해 추가
                            width,
                          }}
                          autoHide
                        >
                          {memberTableMarkup}
                        </Scrollbars>
                      )
                    }
                  </AutoSizer>
                </div>
                {filterApplied && members.length === 0 && (
                  <div className={classes.noResultWrap}>
                    <div
                      className={classes.noResultDiv}
                      style={{ marginTop: mobile ? 17 : "" }}
                    >
                      <Typography
                        category="Default"
                        variant="H6"
                        htmlColor={LightColors.primary["3"]}
                        style={{ marginBottom: 8 }}
                      >
                        {t("No results")}
                      </Typography>

                      <Typography
                        category="Default"
                        variant="Body"
                        htmlColor={LightColors.primary["3"]}
                        style={{ marginBottom: 24 }}
                      >
                        {t("Try changing or_")}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleResetFilter();
                          setAppliedRole("All");
                          setAppliedStatus("All");
                          setAppliedGroup("All");
                        }}
                      >
                        {t("Remove all filters")}
                      </Button>
                    </div>
                  </div>
                )}
                {filterApplied && members.length > 0 && (
                  <div className={classes.filterAppliedText}>
                    <Typography category="Default" variant="Small">
                      {t("Some members may_")}
                    </Typography>{" "}
                    <Typography
                      category="Default"
                      variant="Small"
                      className={classes.linkText}
                      onClick={() => {
                        handleResetFilter();
                        setAppliedRole("All");
                        setAppliedStatus("All");
                        setAppliedGroup("All");
                      }}
                    >
                      {t("Show all members")}
                    </Typography>
                  </div>
                )}
                {!filterApplied && (
                  <div className={classes.paginationDiv}>
                    <Button
                      variant="text"
                      ref={anchorRef}
                      onClick={handlePageSort}
                      className={classes.pageSortBtn}
                    >
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["3"]}
                      >
                        {t("n of n", {
                          a: `${1 + currentPage * ROWS_PER_PAGE}–${Math.min(
                            (currentPage + 1) * ROWS_PER_PAGE,
                            memberList.inviteMemberCount ?? 0
                          )}`,
                          b: memberList.inviteMemberCount,
                        })}
                      </Typography>
                    </Button>
                    <Menu
                      style={{ margin: 20 }}
                      open={open}
                      anchorEl={anchorRef.current}
                      onClickAway={handleClose}
                      onKeyDown={handleListKeyDown}
                      placement="bottom-start"
                      modifiers={{ preventOverflow: { enabled: false } }}
                    >
                      <WebMenuItem onClick={() => setSortOrder("desc")}>
                        <Typography category="Default" variant="Body">
                          {t("Newest")}
                        </Typography>
                      </WebMenuItem>
                      <WebMenuItem onClick={() => setSortOrder("asc")}>
                        <Typography category="Default" variant="Body">
                          {t("Oldest")}
                        </Typography>
                      </WebMenuItem>
                    </Menu>
                    <div>
                      <IconButton
                        disabled={currentPage === 0}
                        className={classes.pageArrowBtn}
                        style={{ marginRight: 10 }}
                        onClick={() =>
                          setCurrentPage((p) => Math.max(p - 1, 0))
                        }
                      >
                        {theme.direction === "rtl" ? (
                          <ChevronRightIcon />
                        ) : (
                          <ChevronLeftIcon />
                        )}
                      </IconButton>
                      <IconButton
                        disabled={
                          (currentPage + 1) * ROWS_PER_PAGE >=
                          (memberList?.inviteMemberCount ?? 0)
                        }
                        className={classes.pageArrowBtn}
                        onClick={() =>
                          setCurrentPage((p) => Math.min(p + 1, maxPage))
                        }
                      >
                        {theme.direction === "rtl" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </>
    );
  }, [
    memberList,
    appliedRole,
    appliedStatus,
    appliedGroup,
    sortOrder,
    loading,
    classes.tableDiv,
    classes.noResultWrap,
    classes.noResultDiv,
    classes.filterAppliedText,
    classes.linkText,
    classes.paginationDiv,
    classes.pageSortBtn,
    classes.pageArrowBtn,
    classes.loadingDiv,
    filterApplied,
    email,
    userProfile?.userType,
    groups,
    cameras,
    handleClick,
    handleCheckOpenModal,
    t,
    mobile,
    currentPage,
    open,
    handleClose,
    theme.direction,
    searchKey,
    moreEmail,
    maxPage,
  ]);

  const handleFilterClose = () => {
    setOpenFilter(false);
    setFilterRole(appliedRole);
    setFilterStatus(appliedStatus);
    setFilterGroup(appliedGroup);
    setOpenGroupSearch(false);
    setGroupSearchKey("");
  };

  const handleResetFilter = () => {
    setFilterRole("All");
    setFilterStatus("All");
    setFilterGroup("All");
    setGroupSearchKey("");
  };

  return (
    <div className={classes.root} dir={theme.direction}>
      <div
        className={clsx(classes.subHeaderDiv, {
          [classes.subHeaderDivClosed]: !openMenu,
          [classes.subHeaderDivMobile]: mobile,
        })}
      >
        <div className={classes.subHeader}>
          <div className={clsx(classes.btnDiv)}>
            <Button
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => history.push("/members/invite-member")}
              className={clsx(classes.inviteBtn, {
                [classes.hidden]: mobile && openSearch,
              })}
            >
              {t("Invite member")}
            </Button>
          </div>
          <div
            className={clsx(classes.btnDiv, {
              [classes.webSearchOpen]: openSearch,
            })}
          >
            <Tooltip
              disableTouchListener={mobile}
              placement="top"
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "0, -15px",
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }}
              title={
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["0"]}
                >
                  {t("Filter")}
                </Typography>
              }
            >
              <IconButton
                className={classes.iconBtn}
                onClick={() => setOpenFilter((o) => !o)}
              >
                <div style={{ position: "relative" }}>
                  <FilterListIcon style={{ display: "block" }} />
                  {filterApplied && (
                    <div
                      style={{
                        width: 6,
                        height: 6,
                        borderRadius: 3,
                        backgroundColor: LightColors.secondary["11"],
                        position: "absolute",
                        top: -3,
                        right: -3,
                      }}
                    />
                  )}
                </div>
              </IconButton>
            </Tooltip>
            <Input
              inputID="memberSearch"
              placeholder={`${t("Search Name or email")}`}
              startIcon={
                <Tooltip
                  disableTouchListener={mobile}
                  placement="top"
                  PopperProps={{
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "0, -12px",
                      },
                      flip: {
                        enabled: false,
                      },
                    },
                  }}
                  title={
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["0"]}
                    >
                      {t("search")}
                    </Typography>
                  }
                >
                  <SearchIcon />
                </Tooltip>
              }
              onSearch={() => {
                requestMembers(searchKey.trim());
              }}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              className={clsx(classes.searchBtn, {
                [classes.mobileSearchOpen]: mobile,
                [classes.webSearchOpen]: openSearch,
              })}
              search
              dense
              onOpenSearch={() => setOpenSearch(true)}
              onCloseSearch={() => {
                setSearchKey("");
                requestMembers("");
                setOpenSearch(false);
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.body}>{renderMemberList()}</div>

      <Drawer
        anchor={theme.direction === "rtl" ? "left" : "right"}
        open={openFilter}
      >
        <div className={classes.filterDiv} dir={theme.direction}>
          <div className={classes.filterTitleDiv}>
            <Typography category="Default" variant="H6">
              {t("Filter")}
            </Typography>
            <IconButton onClick={handleFilterClose} style={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className={classes.filterListDiv}>
            <div className={classes.filterItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Role")}
              </Typography>
              <div>
                <FormControl className={classes.radioFormControl}>
                  <RadioGroup
                    value={filterRole}
                    onChange={(e) => setFilterRole(e.target.value)}
                  >
                    <RadioButton
                      labelClassName={classes.radioBtn}
                      style={
                        theme.direction === "rtl"
                          ? { marginLeft: 3 }
                          : { marginRight: 3 }
                      }
                      value="All"
                      label={
                        <>
                          <Typography category="Default" variant="Body">
                            {t("All")}
                          </Typography>
                          <Typography
                            category="Default"
                            variant="Caption"
                            htmlColor={LightColors.primary["2"]}
                          >
                            {` ${memberList?.inviteMember?.length}`}
                          </Typography>
                        </>
                      }
                    />
                    <RadioButton
                      labelClassName={classes.radioBtn}
                      style={
                        theme.direction === "rtl"
                          ? { marginLeft: 3 }
                          : { marginRight: 3 }
                      }
                      value="SubMaster"
                      label={
                        <>
                          <Typography category="Default" variant="Body">
                            {t("Admin")}
                          </Typography>
                          <Typography
                            category="Default"
                            variant="Caption"
                            htmlColor={LightColors.primary["2"]}
                          >
                            {` ${
                              _.filter(
                                memberList?.inviteMember,
                                (m) => m.role === "SubMaster"
                              ).length
                            }`}
                          </Typography>
                        </>
                      }
                    />
                    <RadioButton
                      labelClassName={classes.radioBtn}
                      style={
                        theme.direction === "rtl"
                          ? { marginLeft: 3 }
                          : { marginRight: 3 }
                      }
                      value="User"
                      label={
                        <>
                          <Typography category="Default" variant="Body">
                            {t("Driver")}
                          </Typography>
                          <Typography
                            category="Default"
                            variant="Caption"
                            htmlColor={LightColors.primary["2"]}
                          >
                            {` ${
                              _.filter(
                                memberList?.inviteMember,
                                (m) => m.role === "User"
                              ).length
                            }`}
                          </Typography>
                        </>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className={classes.filterItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Status")}
              </Typography>
              <div>
                <FormControl className={classes.radioFormControl}>
                  <RadioGroup
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  >
                    <RadioButton
                      labelClassName={classes.radioBtn}
                      style={
                        theme.direction === "rtl"
                          ? { marginLeft: 3 }
                          : { marginRight: 3 }
                      }
                      value="All"
                      label={
                        <Typography category="Default" variant="Body">
                          {t("All")}
                        </Typography>
                      }
                    />
                    <RadioButton
                      labelClassName={classes.radioBtn}
                      style={
                        theme.direction === "rtl"
                          ? { marginLeft: 3 }
                          : { marginRight: 3 }
                      }
                      value="A"
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Accepted")}
                        </Typography>
                      }
                    />
                    <RadioButton
                      labelClassName={classes.radioBtn}
                      style={
                        theme.direction === "rtl"
                          ? { marginLeft: 3 }
                          : { marginRight: 3 }
                      }
                      value="E"
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Expired")}
                        </Typography>
                      }
                    />
                    <RadioButton
                      labelClassName={classes.radioBtn}
                      style={
                        theme.direction === "rtl"
                          ? { marginLeft: 3 }
                          : { marginRight: 3 }
                      }
                      value="P"
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Pending")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div
              className={clsx(
                classes.filterItemDiv,
                filteredGroups.length === 0 &&
                  groupSearchKey &&
                  classes.groupNoResult
              )}
            >
              <div className={classes.groupFilterTitleDiv}>
                {!openGroupSearch && (
                  <Typography category="Default" variant="BodyBold">
                    {t("Group")}
                  </Typography>
                )}
                <Input
                  inputID="groupsSearch"
                  placeholder={t("search")}
                  value={groupSearchKey}
                  onChange={(e) => setGroupSearchKey(e.target.value)}
                  startIcon={
                    <Tooltip
                      disableTouchListener={mobile}
                      placement="top"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0, -12px",
                          },
                          flip: {
                            enabled: false,
                          },
                        },
                      }}
                      title={
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["0"]}
                        >
                          {t("search")}
                        </Typography>
                      }
                    >
                      <SearchIcon style={{ padding: 0 }} />
                    </Tooltip>
                  }
                  // startIcon={<SearchIcon style={{ padding: 0 }} />}
                  className={clsx(classes.searchBtnG, {
                    [classes.searchBtnOpen]: openGroupSearch,
                  })}
                  search
                  dense
                  variant="standard"
                  onOpenSearch={() => setOpenGroupSearch(true)}
                  onCloseSearch={() => {
                    setOpenGroupSearch(false);
                    setGroupSearchKey("");
                  }}
                />
              </div>
              {filteredGroups.length === 0 && groupSearchKey && (
                <div className={classes.groupNoResultDiv}>
                  <NoResults />
                </div>
              )}
              <div>
                <FormControl
                  className={classes.radioFormControl}
                  style={{ width: "100%" }}
                >
                  <RadioGroup
                    value={filterGroup}
                    onChange={(e) => setFilterGroup(e.target.value)}
                  >
                    {!groupSearchKey && (
                      <>
                        <RadioButton
                          labelClassName={classes.radioBtn}
                          style={
                            theme.direction === "rtl"
                              ? { marginLeft: 3 }
                              : { marginRight: 3 }
                          }
                          value="All"
                          label={
                            <Typography category="Default" variant="Body">
                              {t("All")}
                            </Typography>
                          }
                        />
                        <RadioButton
                          labelClassName={classes.radioBtn}
                          style={
                            theme.direction === "rtl"
                              ? { marginLeft: 3 }
                              : { marginRight: 3 }
                          }
                          value="None"
                          label={
                            <Typography category="Default" variant="Body">
                              {t("None")}
                            </Typography>
                          }
                        />
                      </>
                    )}

                    {filteredGroups.length > 0 &&
                      _.map(filteredGroups, (grp) => (
                        <RadioButton
                          labelClassName={classes.radioBtn}
                          style={
                            theme.direction === "rtl"
                              ? { marginLeft: 3 }
                              : { marginRight: 3 }
                          }
                          value={grp.GroupName}
                          label={
                            <Typography category="Default" variant="Body">
                              {grp.GroupName}
                            </Typography>
                          }
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={classes.filterBtnDiv}>
            <Button
              variant="outlined"
              color="primary"
              style={
                theme.direction === "rtl"
                  ? { marginLeft: theme.spacing(2) }
                  : { marginRight: theme.spacing(2) }
              }
              disabled={
                filterRole === "All" &&
                filterStatus === "All" &&
                filterGroup === "All"
              }
              onClick={handleResetFilter}
            >
              {t("Clear")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                filterRole === appliedRole &&
                filterStatus === appliedStatus &&
                filterGroup === appliedGroup
              }
              onClick={() => {
                setOpenFilter(false);
                setAppliedRole(filterRole);
                setAppliedStatus(filterStatus);
                setAppliedGroup(filterGroup);
                setGroupSearchKey("");
                setOpenGroupSearch(false);
                requestMembers("");
              }}
            >
              {t("OK")}
            </Button>
          </div>
        </div>
      </Drawer>

      {!mobile && (
        <CameraMenu
          open={openCamMenu}
          anchorRef={anchorMoreRef}
          hideDivider
          placement="bottom-end"
          onClickAway={() => setOpenCamMenu(false)}
          onDelete={
            userProfile?.userType && UserNames[userProfile.userType] === "Admin"
              ? null
              : () => moreEmail && handleOpenDeleteModal(moreEmail)
          }
          dense
        />
      )}
      {mobile && (
        <MobileCameraMenu
          open={openCamMenu}
          onClose={() => setOpenCamMenu(false)}
          onDelete={
            userProfile?.userType && UserNames[userProfile.userType] === "Admin"
              ? null
              : () => {
                  moreEmail && handleOpenDeleteModal(moreEmail);
                  setOpenCamMenu(false);
                }
          }
        />
      )}
    </div>
  );
};
