import { Theme, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

export const Accordion = withStyles((theme: Theme) => ({
  root: {
    "&.Mui-expanded": {
      margin: 0,
    },
    "&::before": { backgroundColor: "transparent", height: 0 },
  },
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    minHeight: 56,
    padding: theme.spacing(1, 2),
    "&.Mui-expanded": {
      minHeight: 56,
    },
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    padding: 0,
    marginRight: 0,
    "&.Mui-expanded": {
      padding: 0,
    },
  },
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
}))(MuiAccordionDetails);
