import React, { useCallback, useEffect, useState } from "react";
import { FormControlLabel } from "@material-ui/core";
import {
  Button,
  CheckBox,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { CAMERA, loadCameras } from "../../features/Camera/slice";
import _ from "lodash";
import { PermissionProps } from "../../hoc/withViewerTemplate";
import * as Api from "../../apis";
import { USER } from "../../features/User/slice";
import { openToast } from "../../features/Toast/slice";
import {
  LightColors,
  Webviewer,
  ICameraInfo,
  OnOffType,
  RESULT_CODE,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  //////////
  mgNone: {
    margin: 0,
  },

  box: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 48,
    marginTop: 14,
    display: "flex",
    borderRadius: 4,
    ...(theme.direction === "rtl"
      ? { padding: "8px 10px 12px 0" }
      : { padding: "8px 0 12px 10px" }),
    marginBottom: 32,
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      border: `1px solid ${LightColors.primary["6"]}`,
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      marginBottom: 32,
      flexDirection: "column",
    },
  },

  bigBox: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    marginTop: 14,
    borderRadius: 4,
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 16,
      width: "100%",
      maxWidth: 672,
      maxHeight: 192,
      marginBottom: 16,
      borderRadius: 4,
    },
  },
  boderBottomBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
  },
  boderNoneBox: {
    maxWidth: 288,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
    },
  },
  pdL36: {
    paddingLeft: 36,
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
  },
  mgBAs: {
    marginBottom: 32,
    maxWidth: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  pdUsed: {
    ...(theme.direction === "rtl" ? { paddingRight: 30 } : { paddingLeft: 30 }),
  },
  all: {
    marginTop: 18,
    marginBottom: 18,
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  eventmapModalContent: {
    padding: theme.spacing(3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
}));

export const PrivacyPanel = ({
  shareToEventmapPerm,
}: Partial<PermissionProps>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { camera, cameraList } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);
  const [cameraInfo, setCameraInfo] = useState<ICameraInfo>();

  const [agree_gps, setAgreeGPS] = useState(false);
  const [share_gps, setShareGPS] = useState(false);
  const [share_video, setShareVideo] = useState(false);
  const [share_audio, setShareAudio] = useState(false);
  const [share_leu_bigdata, setShareLeuBigdata] = useState(false);
  const [share_dev_name, setShareDevName] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [openNoPermModal, setOpenNoPermModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getBoolean = useCallback((val: "on" | "off" | undefined) => {
    return val === "on" ? true : false;
  }, []);

  useEffect(() => {
    const cam = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === camera?.psn)
      .value();
    setCameraInfo((c) => {
      if (c?.psn !== cam?.psn) {
        return cam;
      } else if (c?.active !== cam?.active) {
        return cam;
        // mantis - 9593, Allow GPS access 체크가 변경된 상태로 보여지지 않는 이슈 수정 / 변경된 상태가 있는 경우 cam으로 set(Leehj)
        // 저장시 체크 깜빡이는 이슈 - return c;로 항상 빠지는 것 방지하여 else안으로 실행문 이동.(Leehj)
      } else if (
        c?.agree_gps !== cam?.agree_gps ||
        c?.share_gps !== cam?.share_gps ||
        c?.share_video !== cam?.share_video ||
        c?.share_audio !== cam?.share_audio ||
        c?.share_dev_name !== cam?.share_dev_name ||
        c?.share_leu_bigdata !== cam?.share_leu_bigdata
      ) {
        return cam;
      } else {
        return c;
      }
    });
  }, [camera?.psn, cameraList?.deviceListInfo]);

  const getOnOff = (val: boolean): OnOffType => {
    return val ? "on" : "off";
  };

  useEffect(() => {
    setAgreeGPS(getBoolean(cameraInfo?.agree_gps));
    setShareGPS(getBoolean(cameraInfo?.share_gps));
    setShareVideo(getBoolean(cameraInfo?.share_video));
    setShareAudio(getBoolean(cameraInfo?.share_audio));
    setShareDevName(getBoolean(cameraInfo?.share_dev_name));
    setShareLeuBigdata(getBoolean(cameraInfo?.share_leu_bigdata));
  }, [getBoolean, cameraInfo]);

  useEffect(() => {
    if (
      getBoolean(cameraInfo?.agree_gps) !== agree_gps ||
      getBoolean(cameraInfo?.share_gps) !== share_gps ||
      getBoolean(cameraInfo?.share_video) !== share_video ||
      getBoolean(cameraInfo?.share_audio) !== share_audio ||
      getBoolean(cameraInfo?.share_leu_bigdata) !== share_leu_bigdata ||
      getBoolean(cameraInfo?.share_dev_name) !== share_dev_name
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [
    getBoolean,
    agree_gps,
    share_gps,
    share_video,
    share_audio,
    share_dev_name,
    share_leu_bigdata,
    cameraInfo?.agree_gps,
    cameraInfo?.share_gps,
    cameraInfo?.share_video,
    cameraInfo?.share_audio,
    cameraInfo?.share_leu_bigdata,
    cameraInfo?.share_dev_name,
  ]);

  const handleUpdate = useCallback(async () => {
    if (cameraInfo && email && loginInfo) {
      setLoading(true);
      setDisableSave(true);
      try {
        const payload = {
          psn: cameraInfo.psn,
          agree_gps: getOnOff(agree_gps),
          share_gps: getOnOff(share_gps),
          share_video: getOnOff(share_video),
          share_audio: getOnOff(share_audio),
          share_dev_name: getOnOff(share_dev_name),
          share_leu_bigdata: getOnOff(share_leu_bigdata),
        };

        const resp = await Api.updatePrivacy(
          email,
          loginInfo.user_token,
          cameraInfo.psn,
          payload.agree_gps,
          payload.share_gps,
          payload.share_audio,
          payload.share_video,
          payload.share_dev_name,
          payload.share_leu_bigdata
        );
        const { resultcode, response } = resp.data as {
          resultcode: RESULT_CODE;
          message: string;
          response?: {
            reason:
              | "NO_PERMISSION_PRIVACY"
              | "NO_PERMISSION_SHARING_VIDEO_TO_EVENTMAP";
          };
        };
        if (resultcode === "BC_ERR_OK") {
          if (response?.reason) {
            if (
              response.reason === "NO_PERMISSION_SHARING_VIDEO_TO_EVENTMAP" &&
              payload.share_leu_bigdata !== cameraInfo?.share_leu_bigdata
            ) {
              setOpenNoPermModal(true);
            }
            // share_leu_bigdata 변수 말고 다른 변수가 수정됐는데, privacy 권한 없어서 에러나는 경우
            console.log(
              response.reason,
              payload,
              payload.share_leu_bigdata,
              cameraInfo?.share_leu_bigdata
            );
            if (
              response.reason === "NO_PERMISSION_PRIVACY" &&
              payload.share_leu_bigdata === cameraInfo?.share_leu_bigdata
            ) {
              setOpenNoPermModal(true);
            }
          } else {
            dispatch(openToast({ message: "Camera settings saved_" }));
          }
        } else if (resultcode === "BC_ERR_PERMISSION") {
          setOpenNoPermModal(true);
        }
        dispatch(loadCameras());
      } catch (e: any) {
        console.log("PrivacyPanel", "handleUpdate", e.message);
      } finally {
        setLoading(false);
      }
    }
  }, [
    cameraInfo,
    email,
    loginInfo,
    agree_gps,
    share_gps,
    share_video,
    share_audio,
    share_dev_name,
    share_leu_bigdata,
    dispatch,
  ]);

  // const eventmapShareMarkup = useMemo(() => {
  //   return (
  //     <>
  //       <div className={classes.bigBox}>
  //         <div className={classes.boderNoneBox}>
  //           <FormControlLabel
  //             className={classes.mgNone}
  //             control={
  //               <CheckBox
  //                 // GPS acess OFF설정시 이벤트맵 자동공유 설정을 OFF시키고 비활성화 처리 (Leehj)
  //                 disabled={!shareToEventmapPerm || !agree_gps}
  //                 checked={share_leu_bigdata}
  //                 onChange={(_e, checked) => {
  //                   setShareLeuBigdata(checked);
  //                 }}
  //                 color="primary"
  //               />
  //             }
  //             label={
  //               <Typography category="Default" variant="Body">
  //                 {t("Automatically add Live Event_")}
  //               </Typography>
  //             }
  //           />
  //         </div>
  //       </div>
  //       <div className={classes.mgBAs}>
  //         <Typography
  //           category="Default"
  //           variant="Small"
  //           htmlColor={LightColors.primary["2"]}
  //         >
  //           {t("If you want to remove videos_")}
  //         </Typography>
  //       </div>
  //     </>
  //   );
  // }, [
  //   agree_gps,
  //   classes.bigBox,
  //   classes.boderNoneBox,
  //   classes.mgBAs,
  //   classes.mgNone,
  //   shareToEventmapPerm,
  //   share_leu_bigdata,
  //   t,
  // ]);

  return (
    <div className={classes.all}>
      <Typography category="Default" variant="H6">
        {t("Private")}
      </Typography>
      <div className={classes.box}>
        <div>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={agree_gps}
                onChange={(_e, checked) => {
                  setAgreeGPS(checked);
                  if (agree_gps === !checked) {
                    setShareGPS(false);
                  }
                  if (!checked) {
                    setShareVideo(false);
                    setShareAudio(false);
                    setShareDevName(false);
                    // GPS acess OFF설정시 이벤트맵 자동공유 설정을 OFF시키고 비활성화 처리 (Leehj)
                    setShareLeuBigdata(false);
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Allow GPS access")}
              </Typography>
            }
          />
        </div>
        <div className={classes.pdUsed}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("If not allowed_")}
            {/* {t("Used to access_2")} */}
          </Typography>
        </div>
      </div>
      <Typography category="Default" variant="H6">
        {t("Public")}
      </Typography>
      <div className={classes.bigBox}>
        <div className={classes.boderBottomBox}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                // mantis - 10540, Allow GPS access off인경우 해당 항목 비활성화 처리(Leehj)
                disabled={!agree_gps}
                checked={share_gps}
                onChange={(_e, checked) => {
                  if (agree_gps === !checked) {
                    setShareGPS(false);
                  } else {
                    setShareGPS(checked);
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Share location")}
              </Typography>
            }
          />
        </div>

        <div
          className={clsx({
            [classes.boderNoneBox]: share_video,
            [classes.boderBottomBox]: !share_video,
          })}
        >
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={share_video}
                disabled={!share_gps}
                onChange={(_e, checked) => {
                  setShareVideo(checked);
                  if (!checked) {
                    setShareAudio(false);
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Share live view")}
              </Typography>
            }
          />
        </div>
        {share_video && (
          <div className={clsx(classes.boderBottomBox, classes.pdL36)}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={share_audio}
                  disabled={!share_gps}
                  onChange={(_e, checked) => setShareAudio(checked)}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Share live audio")}
                </Typography>
              }
            />
          </div>
        )}
        <div className={classes.boderNoneBox}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={share_dev_name}
                disabled={!share_gps}
                onChange={(_e, checked) => setShareDevName(checked)}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {/* uxui 설정4차수정 - 모델 구분 없이 모두 Camera Profile로 변경 되어야한다.Share camera’s name -> Share camera profile(Leehj)  */}
                {t("Share camera profile")}
              </Typography>
            }
          />
        </div>
      </div>{" "}
      <div className={classes.mgBAs}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {/* 
          mantis : 8484
          Setting > Privacy > Public > 하단 OSD 추가
          */}
          {t("By sharing your_2")}
        </Typography>
      </div>
      {/* {eventmapShareMarkup} */}
      {mobile && (
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonSize}
            disabled={disableSave}
            loading={loading}
            onClick={handleUpdate}
          >
            {t("Save")}
          </Button>
        </div>
      )}
      {!mobile && (
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={disableSave}
            loading={loading}
            onClick={handleUpdate}
          >
            {t("Save")}
          </Button>
        </div>
      )}
      <Modal
        open={openNoPermModal}
        close
        onClose={() => setOpenNoPermModal(false)}
        onClickPositive={() => setOpenNoPermModal(false)}
        heading=" "
        content={
          <Typography
            category="Default"
            variant="Body"
            dangerouslySetInnerHTML={{
              __html: t("You do not have permission_"),
            }}
          />
        }
        RButton={t("OK")}
        actionClassName={classes.modalBottom}
        contentClassName={classes.eventmapModalContent}
        className={classes.modalWrap}
      />
    </div>
  );
};
