import { detect } from "detect-browser";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventVideoModal } from "../../components/modals/EventVideoModal";
import { ReportVideoModal } from "../../components/modals/ReportVideoModal";

import { PAYMENT } from "../../features/Payment/slice";
import { RootState } from "../../features/store";
import { openToast } from "../../features/Toast/slice";
import { USER } from "../../features/User/slice";
import { eventmapAxiosInst } from "../../utils";
import { EventVideo, sendMessageBack } from "@thingsw/pitta-modules";

interface EventmapVideoScreenProps {
  videoId: number;
  app: boolean;
}

export const EventmapVideoScreen = ({
  videoId,
  app,
}: EventmapVideoScreenProps) => {
  const dispatch = useDispatch();
  const [eventId, setEventId] = useState(-1);
  const [videos, setVideos] = useState<EventVideo[]>([]);
  const [videoIndex, setVideoIndex] = useState(-1);
  const [loadingReportModal, setLoadingReportModal] = useState(false);
  const [openReportVideoModal, setOpenReportVideoModal] = useState<boolean>(
    false
  );
  const [reportedVideoId, setReportedVideoId] = useState<number>();
  const { userProfile, email } = useSelector((state: RootState) => state[USER]);
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  useEffect(() => {
    //@ts-ignore
    window.backPressed = () => {
      sendMessageBack();
    };
    return () => {
      //@ts-ignore
      window.backPressed = undefined;
    };
  }, []);

  useEffect(() => {
    const getVideo = async () => {
      let query = "";
      if (userProfile?.userType === "SubMaster") {
        query = `?email=${subscriptionInfo?.masterEmail}`;
      }
      const resp = await eventmapAxiosInst.get(
        `/event-videos/${videoId}${query}`
      );
      const video = resp.data as EventVideo;
      setEventId(video.eventId);
      setVideos([video]);
      setVideoIndex(0);
    };
    getVideo();
  }, [subscriptionInfo?.masterEmail, userProfile?.userType, videoId]);

  const handleClose = useCallback(() => {
    const browser = detect();
    if (browser?.name === "ios-webview") {
      //@ts-ignore
      return webkit.messageHandlers.back.postMessage({});
    }
    if (browser?.name === "chromium-webview") {
      //@ts-ignore
      window.Webviewer?.back();
    }
  }, []);

  const handleReporting = useCallback(
    async (reason: string) => {
      if (userProfile && reportedVideoId) {
        setLoadingReportModal(true);

        try {
          await eventmapAxiosInst.delete(
            `/event-videos/${reportedVideoId}/report`,
            {
              data: {
                // mantis 9350 - 모바일에서 이유작성에 줄바꿈시 에러나는 이슈 수정 (Leehj)
                reason: _.replace(reason, /\n/g, "\\n"),
                reporter: email,
                reporterName: `${userProfile.firstName} ${userProfile.lastName}`,
              },
            }
          );
          setOpenReportVideoModal(false);

          dispatch(
            openToast({
              message: "The video has been reported_",
            })
          );
        } catch (e) {
          console.error(e);

          dispatch(
            openToast({
              message: "Internal server error_",
            })
          );
        } finally {
          setLoadingReportModal(false);
        }
      }
    },
    [dispatch, email, reportedVideoId, userProfile]
  );

  return (
    <>
      <EventVideoModal
        eventId={eventId}
        open
        zoom={16}
        onClose={handleClose}
        myEventVideos={videos}
        mode="my-videos"
        myVideoIndex={videoIndex}
        lineVideos={[]}
        lineIndex={-1}
        mobile={app}
        app={app}
        mapType="map"
        onOpenReportVideoModal={(videoId) => {
          setReportedVideoId(videoId);
          setOpenReportVideoModal(true);
        }}
      />
      <ReportVideoModal
        open={openReportVideoModal}
        loading={loadingReportModal}
        onClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpenReportVideoModal(false);
        }}
        onClickNegative={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpenReportVideoModal(false);
        }}
        onClickPositive={handleReporting}
      />
    </>
  );
};
