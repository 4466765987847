import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../store";
import { USER, logout } from "../User/slice";
import {
  loadSubscription,
  loadTransactions,
  loadWebSubscription,
  cancelSubscription,
  successLoadSubscription,
  successLoadTransactions,
  successLoadWebSubscription,
  getFreetrialState,
  successGetFreetrialState,
} from "./slice";
import * as Api from "../../apis";
import { PayloadAction } from "@reduxjs/toolkit";
import { openToast } from "../Toast/slice";
import {
  IUserLoginInfo,
  RESULT_CODE,
  ISubscriptionInfo,
  Ifreetrial,
  IWebSubscriptionInfo,
  ITransaction,
  STRIPE_OS_TYPE,
} from "@thingsw/pitta-modules";

function* handleLoadSubscription() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const res = yield call(
      Api.getSubscription,
      email,
      loginInfo.user_token,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ISubscriptionInfo;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadSubscription(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleGetFreetrialState() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const res = yield call(Api.getFreetrialState, email, loginInfo.user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: Ifreetrial;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successGetFreetrialState(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleGetWebSubscription() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const res = yield call(
      Api.getWebSubscription,
      email,
      loginInfo.user_token,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IWebSubscriptionInfo;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadWebSubscription(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleLoadTransactions() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const res = yield call(
      Api.getTransactionHistory,
      email,
      loginInfo.user_token,
      tokenType
    );
    const {
      resultcode,
      response: { transaction },
    } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { transaction: ITransaction[] };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadTransactions(transaction));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleCancelSubscription({
  payload,
}: PayloadAction<string | undefined>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.requestCancelSubscription,
      email,
      user_token,
      STRIPE_OS_TYPE,
      payload
    );
    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      // yield put(successRequest());
      yield put(openToast({ message: "Subscription is Canceled" }));
      yield put(loadSubscription());
      yield put(loadWebSubscription());
    }
  } catch (err) {
    console.error(err);
  }
}
// function* handleGetSubscribeToken({ payload }: PayloadAction<Payment>) {
//   try {
//     const email = yield select((state: RootState) => state[USER].email);
//     const { user_token } = (yield select(
//       (state: RootState) => state[USER].loginInfo
//     )) as IUserLoginInfo;

//     const resp = yield call(Api.doWebSubscribe, email, user_token, payload);
//     console.log(resp.data);
//   } catch (err) {
//     console.error(err);
//   }
// }

export function* watchPayment() {
  yield takeLatest(loadSubscription, handleLoadSubscription);
  yield takeLatest(loadWebSubscription, handleGetWebSubscription);
  yield takeLatest(loadTransactions, handleLoadTransactions);
  yield takeLatest(cancelSubscription, handleCancelSubscription);
  yield takeLatest(getFreetrialState, handleGetFreetrialState);
}
