import { CircularProgress } from "@material-ui/core";
import {
  BatteryCharging,
  BatteryChecked,
  BatteryLink,
  BatteryNonCharging,
  BatteryPieAlert,
} from "@thingsw/pitta-design-system";
import React from "react";

interface BatteryStatusIconProps {
  status: "full" | "alert" | "charging" | "nonCharging" | "link" | "loading";
}

const BatteryStatusIcon = ({ status }: BatteryStatusIconProps) => {
  switch (status) {
    case "full":
      return <BatteryChecked />;
    case "alert":
      return <BatteryPieAlert />;
    case "charging":
      return <BatteryCharging />;
    case "nonCharging":
      return <BatteryNonCharging />;
    case "link":
      return <BatteryLink />;
    default:
      return (
        <CircularProgress
          style={{
            width: 24,
            height: 24,
          }}
        />
      );
  }
};

export default BatteryStatusIcon;
