import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Webviewer,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    margin: 15,
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#1E1E23" : "#FFFFFF",
    borderColor: (props: any) => props.darkMode ? "#323236" : "#D4D4D5",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      position: "absolute",
      right: 0,
      margin: 58,
      width: 343,
    },
  },
  modalTitle: {
    padding: "30px 23px 12px 23px",
    minHeight: 22,
  },
  modalContent: {
    padding: "12px 24px",
    marginBottom: 24,
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
  },
  button: {
    width: "100%",
  },
}));

export interface NotificationInfoModalProps {  
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  darkMode?: boolean;
}

export const NotificationInfoModal = ({
  open,
  onClose,
  title,
  description,
  darkMode,
}: NotificationInfoModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ darkMode });

  return (
    <Modal
      open={open}
      onClose={onClose}
      heading={title}      
      className={classes.modal}
      titleClassName={classes.modalTitle}
      contentClassName={classes.modalContent}
      RButtonClassName={classes.button}
      RButton={t("OK")}
      onClickPositive={onClose}
      fullWidthBtn
      content={
        <>
          <div>
            <Typography
              category="Default"
              variant="Small"
            >
              {description}
            </Typography>
          </div>
        </>
      }
    />
  );
};
