import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppBackReason, ILiveViewState } from "@thingsw/pitta-modules";

let initialState: ILiveViewState = {
  removePlayer: false,
  reason: undefined,
  multiliveview: false,
};

const slice = createSlice({
  name: "liveview",
  initialState,
  reducers: {
    finishLiveview: (
      state,
      { payload }: PayloadAction<AppBackReason | undefined>
    ) => {
      console.log("CloudNative", "finishLiveview", payload);
      if (!state.removePlayer) {
        state.reason = payload;
        state.removePlayer = true;
      }
    },
    startLiveView: (state) => {
      state.removePlayer = false;
    },
    setMultiLiveview: (state, { payload }: PayloadAction<boolean>) => {
      state.multiliveview = payload;
    },
    setRemovePlayer: (state, { payload }: PayloadAction<boolean>) => {
      state.removePlayer = payload;
    },
  },
});

export const {
  finishLiveview,
  startLiveView,
  setMultiLiveview,
  setRemovePlayer,
} = slice.actions;
export const LIVEVIEW = slice.name;
export default slice.reducer;
