import LocationNormal from "./location-normal.svg";
import LocationHover from "./location-hover.svg";
import LocationActive from "./location-active.svg";
import EventNormal from "./event-normal.svg";
import EventHover from "./event-hover.svg";
import EventActive from "./event-active.svg";
import ManualNormal from "./manual-normal.svg";
import ManualHover from "./manual-hover.svg";
import ManualActive from "./manual-active.svg";
import ParkingNormal from "./parking-normal.svg";
import ParkingHover from "./parking-hover.svg";
import ParkingActive from "./parking-active.svg";
import ImpactIcon from "./impact-icon.svg";
import ImpactIconActive from "./impact-icon-active.svg";
import RecklessIcon from "./reckless-icon.svg";
import RecklessIconActive from "./reckless-icon-active.svg";
import ParkingIcon from "./parking-icon.svg";
import ParkingIconActive from "./parking-icon-active.svg";
import ManualIcon from "./manual-icon.svg";
import ManualIconActive from "./manual-icon-active.svg";

const Icons = [
  {
    src: LocationNormal,
    name: "location",
    width: 24,
    height: 24,
  },
  {
    src: LocationHover,
    name: "location-hover",
    width: 22,
    height: 22,
  },
  {
    src: LocationActive,
    name: "location-active",
    width: 40,
    height: 40,
  },
  {
    src: EventNormal,
    name: "event",
    width: 24,
    height: 24,
  },
  {
    src: EventHover,
    name: "event-hover",
    width: 40,
    height: 50,
  },
  {
    src: EventActive,
    name: "event-active",
    width: 40,
    height: 50,
  },
  {
    src: ManualNormal,
    name: "manual",
    width: 24,
    height: 24,
  },
  {
    src: ManualHover,
    name: "manual-hover",
    width: 40,
    height: 50,
  },
  {
    src: ManualActive,
    name: "manual-active",
    width: 40,
    height: 50,
  },
  {
    src: ParkingNormal,
    name: "parking-dot-icon",
    width: 24,
    height: 24,
  },
  {
    src: ParkingHover,
    name: "parking-hover",
    width: 40,
    height: 50,
  },
  {
    src: ParkingActive,
    name: "parking-active",
    width: 40,
    height: 50,
  },
  {
    src: ImpactIcon,
    name: "impact-icon",
    width: 34,
    height: 39,
  },
  {
    src: ImpactIconActive,
    name: "impact-icon-active",
    width: 34,
    height: 39,
  },
  {
    src: RecklessIcon,
    name: "reckless-icon",
    width: 34,
    height: 39,
  },
  {
    src: RecklessIconActive,
    name: "reckless-icon-active",
    width: 34,
    height: 39,
  },
  {
    src: ParkingIcon,
    name: "parking-icon",
    width: 34,
    height: 39,
  },
  {
    src: ParkingIconActive,
    name: "parking-icon-active",
    width: 34,
    height: 39,
  },
  {
    src: ManualIcon,
    name: "manual-icon",
    width: 34,
    height: 39,
  },
  {
    src: ManualIconActive,
    name: "manual-icon-active",
    width: 34,
    height: 39,
  },
];

export default Icons;
