import { useTheme, useMediaQuery, makeStyles, Theme } from "@material-ui/core";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PlaybackHeader } from "../components/cameras/PlaybackHeader";
import { FileUploadModal } from "../components/FileUploadModal";
import {
  CAMERA,
  loadFirmwareConfig,
  loadFirmwareSettings,
  loadCamera,
  loadCameras,
} from "../features/Camera/slice";
import { RootState } from "../features/store";
import { THEME } from "../features/Theme/slice";
import { VOD, loadVODUploadList } from "../features/VOD/slice";
import {
  CameraPlaybackScreenProps,
  ScreenModeToIndex,
} from "../screens/CameraPlaybackScreen";
import { ScreenDefaultProps } from "./withViewerTemplate";
import {
  Webviewer,
  UPLOADING_QUEUE_UPDATE_INTERVAL,
  PermissionProps,
  ICameraInfo,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "calc(var(--vh, 1vh) * 100 - 56px - 165px)",
    // mantis :  8831 - KimSeongHyun
    // Scroll Top을 위해 margin을 padding으로 수정
    paddingTop: (props: ScreenDefaultProps) =>
      48 + (props.headerDivRef?.offsetHeight ?? 56),
    // marginTop: (props: ScreenDefaultProps) =>
    //   48 + (props.headerDivRef?.offsetHeight ?? 56),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: ScreenDefaultProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 52px)"
          : "calc(var(--vh, 1vh) * 100 - 56px - 52px)",
      // margin: theme.spacing(5, 4, 2, 4),
      // mantis :  8831 - KimSeongHyun
      // Scroll Top을 위해 margin을 padding으로 수정
      paddingTop: (props: ScreenDefaultProps) =>
        props.error ? 45 + 85 : 45 + 56,
      // marginTop: (props: ScreenDefaultProps) =>
      //   props.error ? 48 + 85 : 48 + 56,
    },
  },
  downUploadModal: {
    position: "fixed",
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    zIndex: 99,
    [theme.breakpoints.up(Webviewer.mobile)]:
      theme.direction === "rtl"
        ? {
            width: 437,
            left: 32,
          }
        : {
            width: 437,
            right: 32,
          },
  },
}));
const withCameraTemplate = (WrappedComponent: React.FC<any>) => (
  props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps
) => {
  const {
    mode,
    psn,
    // openMenu,
    // onRename,
    // onDelete,
    // onUpdate,
    // onRebootCamera,
    // ...permissions
  } = props;

  // const {} = permissions;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const mobileMedia = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { fullscreen } = useSelector((state: RootState) => state[THEME]);
  const [mobile, setMobile] = useState(mobileMedia);

  const { progressDisplayOrder } = useSelector(
    (state: RootState) => state[VOD]
  );

  useEffect(() => {
    if (!fullscreen) {
      setMobile(mobileMedia);
    }
  }, [fullscreen, mobileMedia]);

  const { cameraList, firmware } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [value, setValue] = useState(ScreenModeToIndex[mode]);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();

  useEffect(() => {
    const body = document.getElementById("body-container");
    body?.setAttribute("style", "");
  });

  useEffect(() => {
    const cam = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === psn)
      .value();
    setCurrentCam((c) => {
      if (c?.psn !== cam?.psn) {
        return cam;
      } else if (c?.active !== cam?.active) {
        return cam;
        // mantis - 11504, 시그마모델 cam rename하면 모바일 상단 카메라명 변경되도록 수정 (Leehj)
      } else if (c?.dev_name !== cam?.dev_name) {
        return cam;
      }
      return c;
    });
  }, [psn, cameraList?.deviceListInfo]);

  useEffect(() => {
    if (currentCam) {
      dispatch(loadFirmwareConfig(currentCam));
    }
  }, [currentCam, dispatch]);

  useEffect(() => {
    if (currentCam && firmware?.psn !== currentCam.psn) {
      const load = () => {
        dispatch(loadFirmwareSettings(currentCam.psn));
      };

      const timer = setTimeout(load, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentCam, dispatch, firmware?.psn]);

  useEffect(() => {
    function tick() {
      if (currentCam) {
        dispatch(loadVODUploadList(currentCam));
      }
    }
    if (currentCam && currentCam.active === "on") {
      let timer: number | null = null;
      let id = setInterval(tick, UPLOADING_QUEUE_UPDATE_INTERVAL);
      if (location.pathname.indexOf("live-view") > -1) {
        timer = setTimeout(tick, 500);
      } else {
        tick();
      }
      return () => {
        clearInterval(id);
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [dispatch, currentCam, location]);

  useEffect(() => {
    if (currentCam?.psn !== psn) {
      dispatch(loadCamera(psn));
    }
  }, [currentCam?.psn, dispatch, psn]);

  useEffect(() => {
    if (!cameraList) {
      dispatch(loadCameras());
    }
  }, [cameraList, dispatch]);

  useEffect(() => {
    setValue(ScreenModeToIndex[mode]);
  }, [mode]);

  return (
    // mantis :  8831 - KimSeongHyun
    // 탭 이동시 스크롤 최상위로 이동
    <div className={classes.root} dir={theme.direction} id="scrollTop">
      <PlaybackHeader
        {...props}
        mobile={mobile}
        value={value}
        currentCam={currentCam}
      />

      <div
        style={mobile ? { height: "100%", paddingTop: 30 } : { height: "100%" }}
      >
        <WrappedComponent {...props} currentCam={currentCam} value={value} />
        {/* mantis - 12545, 같은 부모아래로 업로드모달 이동 및 오른쪽 하단으로 고정 / 아랍어는 왼쪽 하단 */}
        <div
          className={classes.downUploadModal}
          style={{ flexDirection: progressDisplayOrder }}
        >
          <FileUploadModal />
        </div>
      </div>
    </div>
  );
};

export default withCameraTemplate;
