import axios from "axios";
import { eventmapAxiosInst } from "../utils";
import {
  API_GATEWAY_URI,
  IDelUploadList,
  jwtAxiosInst,
} from "@thingsw/pitta-modules";

export const getVODList = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  userToken: string,
  psn: string,
  tokenType: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/vod_list?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=${tokenType}`
  );
};

export const getVODToken = (
  email: string,
  userToken: string,
  psn: string,
  filename: string
) => {
  return jwtAxiosInst.post(`/Media/vodTokenReq`, {
    email,
    user_token: userToken,
    psn,
    filename,
    tokenType: "web",
  });
};

export const getCNThumbnail = (
  email: string,
  user_token: string,
  psn: string,
  filename: string,
  types: ("t" | "g" | "s")[],
  tokenType: string
) => {
  return jwtAxiosInst.post(`/IoT/devicecommand`, {
    command: "Metadata",
    email,
    user_token,
    tokenType,
    psn,
    param1: filename,
    param2: types,
  });
};

export const getThumbnail = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  userToken: string,
  psn: string,
  filename: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/vod_file?email=${email}&user_token=${userToken}&psn=${psn}&filename=${filename}&tokenType=web`,
    { responseType: "arraybuffer" }
  );
};

export const getVOD = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  userToken: string,
  psn: string,
  filename: string,
  vod_token: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/vod_file?email=${email}&user_token=${userToken}&psn=${psn}&filename=${filename}&vod_token=${vod_token}&tokenType=web`,
    { responseType: "arraybuffer" }
  );
};

export const getCloudVODList = (
  email: string,
  userToken: string,
  psn: string
) => {
  return jwtAxiosInst.get(
    `/BCS/s3FileList.php?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const getCloudVODFile = (
  email: string,
  userToken: string,
  psn: string,
  filename: string,
  tokenType: string
) => {
  return jwtAxiosInst.get(
    `/BCS/userS3PresignedUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&filename=${filename}&tokenType=${tokenType}`
  );
};

export const deleteCloudVODFile = (
  email: string,
  userToken: string,
  psn: string,
  filenames: string[],
  vodCount?: number
) => {
  return jwtAxiosInst.post(`/BCS/multiDcf.php`, {
    email,
    user_token: userToken,
    psn,
    s3_mfd_info: {
      type: "select",
      filelist: filenames,
    },
    tokenType: "web",
    vodCount,
  });
};

export const getEventVODList = (
  email: string,
  userToken: string,
  psn: string
) => {
  return jwtAxiosInst.get(
    `/BCS/evtLrGetList.php?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const getEventVODFile = (
  email: string,
  userToken: string,
  psn: string,
  rid: string,
  tokenType: string
) => {
  return jwtAxiosInst.get(
    `/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
  );
};

export const deleteEventVODFile = (
  email: string,
  userToken: string,
  psn: string,
  rids: string[]
) => {
  // return axios.get(
  //   `${API_SERVER_URI}/BCS/evtLrDelFile.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
  // );
  return axios.delete(`${API_GATEWAY_URI}/CloudService/DeleteEventUpload`, {
    data: {
      email,
      userToken,
      tokenType: "web",
      psn,
      rid: rids,
    },
  });
};

export const moveEventVODFile = (
  email: string,
  userToken: string,
  psn: string,
  rids: string[]
) => {
  // return axios.put(`${API_GATEWAY_URI}/CloudService/MoveEventUpload`, {
  //   email,
  //   userToken,
  //   psn,
  //   rid: rids,
  //   tokenType: "web",
  // });
  return axios.put(`${API_GATEWAY_URI}/CloudService/MoveEventUpload`, {
    email,
    userToken,
    tokenType: "web",
    psn,
    rid: rids,
  });
};

export const uploadCNVODFile = (
  email: string,
  psn: string,
  filename: string
) => {
  return jwtAxiosInst.post(
    `/IoT/uploadReq`,
    {
      email,
      psn,
      filename,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const uploadVODFile = (
  upload_server: string,
  upload_port: string,
  email: string,
  userToken: string,
  psn: string,
  filename: string
) => {
  return axios.post(
    `https://${upload_server}:${upload_port}/BCS/upload_req`,
    {
      email,
      user_token: userToken,
      psn,
      filename,
      token_type: "web",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getCNVODUploadList = (email: string, psn: string) => {
  return jwtAxiosInst.post(
    `/IoT/queueList`,
    {
      email,
      psn,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getVODUploadList = (
  upload_server: string,
  upload_port: string,
  email: string,
  userToken: string,
  psn: string
) => {
  // if (process.env.NODE_ENV === "development") {
  //   return axios.post(
  //     `/upload/BCS/queue_list`,
  //     {
  //       email,
  //       user_token: userToken,
  //       psn,
  //       token_type: "web",
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );
  // }
  return axios.post(
    `https://${upload_server}:${upload_port}/BCS/queue_list`,
    {
      email,
      user_token: userToken,
      psn,
      token_type: "web",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteCNVODUploadList = (
  email: string,
  psn: string,
  removelist: IDelUploadList[]
) => {
  return jwtAxiosInst.post(
    `/IoT/queueDelete`,
    {
      email,
      psn,
      removelist,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteVODUploadList = (
  upload_server: string,
  upload_port: string,
  email: string,
  userToken: string,
  psn: string,
  removelist: IDelUploadList[]
) => {
  return axios.post(
    `https://${upload_server}:${upload_port}/BCS/queue_delete`,
    {
      email,
      user_token: userToken,
      psn,
      token_type: "web",
      removelist,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getHashtags = (
  email: string,
  userToken: string,
  tokenType: string
) => {
  return eventmapAxiosInst.get(`/hashtags`);
};
