import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Modal, Typography } from "@thingsw/pitta-design-system";
import SimpleBarReact from "simplebar-react";
import clsx from "clsx";
import { eventMaponboardingImgBase64 } from "./eventMaponboardingImg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 764,
    boxSizing: "border-box",
    padding: theme.spacing(0, 3),
  },
  content: {
    marginTop: theme.spacing(4),
    textAlign: "center",
    maxWidth: 620,
  },
  contentWrap: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  modalTitle: {
    minHeight: 16,
    paddingBottom: theme.spacing(2),
  },
  modalContentWrap: {
    display: "flex",
    border: "none",
    padding: theme.spacing(3.25, 0, 2.75, 0),
  },
  scrollbarStyle: {
    height: "100%",
    overflowX: "hidden",
    flex: 1,
    maxHeight: "calc(100vh - 250px)",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonWrap: {
    width: "100%",
    paddingTop: theme.spacing(4.75),
    alignItems: "center",
  },
  buttonWrapStep3: {
    paddingTop: theme.spacing(7.75),
  },
}));

interface EventMapOnboardingStep1Props {
  eventMapStep: number;
  onClose: () => void;
  onNext: () => void;
  onBack: () => void;
}

export const EventMapOnboardingStepWeb = ({
  eventMapStep,
  onClose,
  onNext,
  onBack,
}: EventMapOnboardingStep1Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const imgMarkup = useMemo(() => {
    if (eventMapStep === 1) {
      return (
        <img
          // mantis - 11518 첫 페이지의 이미지만 src에 data형식으로 적용 (leehj)
          src={eventMaponboardingImgBase64}
          alt="eventMaponboarding"
          style={{ width: 718 }}
        />
      );
    } else if (eventMapStep === 2) {
      return (
        <img
          srcSet="/images/eventMaponboarding2.png 1x"
          alt="eventMaponboarding2"
          style={{ width: 718 }}
        />
      );
    } else if (eventMapStep === 3) {
      return (
        <img
          srcSet="/images/eventMaponboarding3.png 1x"
          alt="eventMaponboarding3"
          style={{ width: 718 }}
        />
      );
    } else if (eventMapStep === 4) {
      return (
        <img
          srcSet="/images/eventMaponboarding4.png 1x"
          alt="eventMaponboarding4"
          style={{ width: 718 }}
        />
      );
    }
  }, [eventMapStep]);

  const contentMarkup = useMemo(() => {
    if (eventMapStep === 1) {
      return "Event map collects_";
    } else if (eventMapStep === 2) {
      return "Share your Live_";
    } else if (eventMapStep === 3) {
      return "You can check_";
    } else if (eventMapStep === 4) {
      return "You can filter_";
    }
  }, [eventMapStep]);

  const pageNumMarkup = useMemo(() => {
    if (eventMapStep === 1) {
      return "1 / 4";
    } else if (eventMapStep === 2) {
      return "2 / 4";
    } else if (eventMapStep === 3) {
      return "3 / 4";
    } else if (eventMapStep === 4) {
      return "4 / 4";
    }
  }, [eventMapStep]);

  return (
    <Modal
      className={classes.root}
      open
      heading={"     "}
      close
      onClose={onClose}
      content={
        <SimpleBarReact className={classes.scrollbarStyle}>
          <div className={classes.contentWrap}>
            {imgMarkup}
            <Typography
              category="Default"
              variant="Body"
              className={classes.content}
            >
              {t(`${contentMarkup}`)}
            </Typography>

            <div
              className={clsx(classes.spaceBetween, classes.buttonWrap, {
                [classes.buttonWrapStep3]:
                  eventMapStep === 3 || eventMapStep === 4,
              })}
            >
              <Typography category="Default" variant="Body">
                {pageNumMarkup}
              </Typography>
              <div>
                {eventMapStep >= 2 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onBack}
                    style={{ minWidth: 92, marginRight: 16 }}
                  >
                    {t("Back")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onNext}
                  style={{ width: 100 }}
                >
                  {eventMapStep === 4 ? t("OK") : t("Next")}
                </Button>
              </div>
            </div>
          </div>
        </SimpleBarReact>
      }
      titleClassName={classes.modalTitle}
      contentClassName={classes.modalContentWrap}
    />
  );
};
