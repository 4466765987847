import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILocationWSState } from "@thingsw/pitta-modules";

let initialState: ILocationWSState = {
  gpsWsReadyState: WebSocket.CLOSED,
  multipleMap: false,
  gpsPsnsMyDevices: false,
};

const slice = createSlice({
  name: "location-ws",
  initialState,
  reducers: {
    initGPSWs: () => {},
    closeGPSWs: () => {},
    setGPSWsReadyState: (state, { payload }: PayloadAction<number>) => {
      state.gpsWsReadyState = payload;
    },
    setGPSWs: (state, { payload }: PayloadAction<WebSocket>) => {
      state.gpsWS = payload;
    },
    setMultipleMap: (state, { payload }: PayloadAction<boolean>) => {
      state.multipleMap = payload;
    },
    setGpsPsnsMyDevices: (state, { payload }: PayloadAction<boolean>) => {
      state.gpsPsnsMyDevices = payload;
    },
  },
});

export const {
  initGPSWs,
  closeGPSWs,
  setGPSWsReadyState,
  setGPSWs,
  setMultipleMap,
  setGpsPsnsMyDevices,
} = slice.actions;

export const LOCATION_WS = slice.name;
export default slice.reducer;
