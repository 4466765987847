import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { MaintenanceNoticeModal } from "./modals/MaintenanceNoticeModal";
import { ServerMaintenanceModal } from "./modals/ServerMaintenanceModal";
import { MAINTENANCE_SERVER_URI } from "@thingsw/pitta-modules";

interface MaintenanceHandlerProps {
  onMaintenance?: () => void;
  disablePre?: boolean;
  disableModal?: boolean;
  forceShow?: boolean;
  onCloseModal?: () => void;
}

export const MaintenanceHandler = ({
  onMaintenance,
  disablePre,
  disableModal,
  forceShow,
  onCloseModal,
}: MaintenanceHandlerProps) => {
  const [openPreMaint, setOpenPreMaint] = useState(false);
  const [openMaintModal, setOpenMaintModal] = useState(false);
  const [maintStart, setMaintStart] = useState<moment.Moment>();
  const [maintEnd, setMaintEnd] = useState<moment.Moment>();
  const [maintUrl, setMaintUrl] = useState<string>();

  useEffect(() => {
    const checkMaintenance = async () => {
      const resp = await axios.get(`${MAINTENANCE_SERVER_URI}`);
      const { maintenance } = resp.data;
      const now = moment.utc();
      const { pre, ing } = maintenance.schedule;
      const isPre =
        now.isSameOrAfter(moment.utc(pre.pre_start)) &&
        now.isSameOrBefore(moment.utc(pre.pre_end));
      const isIng =
        now.isSameOrAfter(moment.utc(ing.ing_start)) &&
        now.isSameOrBefore(moment.utc(ing.ing_end));
      setMaintStart(moment.utc(ing.ing_start));
      setMaintEnd(moment.utc(ing.ing_end));
      setMaintUrl(maintenance.noti_url);

      const noRemind = localStorage.getItem(
        "pitta-webviewer:maintenance-notice"
      );

      if (isIng) {
        onMaintenance?.();
      }

      if (maintenance.noti_type === "all") {
        if (isIng) {
          setOpenMaintModal(true);
        } else if (isPre && noRemind !== "true") {
          setOpenPreMaint(true);
        }
      }

      if (maintenance.noti_type === "pre" && isPre && noRemind !== "true") {
        setOpenPreMaint(true);
      }

      if (maintenance.noti_type === "ing" && isIng) {
        setOpenMaintModal(true);
      }
    };

    checkMaintenance();
  }, [onMaintenance]);

  const handleMaintenanceCloseModal = useCallback(() => {
    setOpenPreMaint(false);
    localStorage.setItem("pitta-webviewer:maintenance-notice", "true");
  }, []);

  return (
    <>
      {!disablePre && !disableModal && maintStart && maintEnd && maintUrl && (
        <MaintenanceNoticeModal
          open={openPreMaint}
          onClose={() => {
            setOpenPreMaint(false);
            onCloseModal?.();
          }}
          onRemindClose={handleMaintenanceCloseModal}
          start={maintStart}
          end={maintEnd}
          url={maintUrl}
        />
      )}
      {(!disableModal || forceShow) && maintStart && maintEnd && (
        <ServerMaintenanceModal
          open={openMaintModal || !!forceShow}
          onClose={() => {
            setOpenMaintModal(false);
            onCloseModal?.();
          }}
          start={maintStart}
          end={maintEnd}
        />
      )}
    </>
  );
};
