import React, { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { RadioButton } from "@thingsw/pitta-design-system/dist/components/RadioButton";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
  getFormAsyncErrors,
} from "redux-form";
import { useTranslation } from "react-i18next";

import { FormControl, RadioGroup } from "@material-ui/core";
import { Button, Typography } from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { loadMembers, MEMBER } from "../features/Member/slice";
import { RootState } from "../features/store";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { USER } from "../features/User/slice";
import { TFunction } from "i18next";
import _ from "lodash";
import { ERROR, setError } from "../features/Error/slice";

import * as yup from "yup";
import validator from "./validator";
import { LightColors, Webviewer, IMemberForm } from "@thingsw/pitta-modules";

interface FieldProps {
  label?: string;
  error?: string;
  value?: string;
  checked?: boolean;
  subMaster?: string;
  t: TFunction;
  disableAdmin?: boolean;
  isSubmaster?: boolean;
}

const renderEmailField = ({
  label,
  input,
  meta: { error: metaError },
  error,
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={!!error || !!metaError}
      helperText={(error && t(error)) || (metaError && t(metaError))}
      {...input}
      {...custom}
    />
  );
};

const radioButton = ({
  input,
  value,
  label,
  checked,
  subMaster,
  t,
  disableAdmin,
  isSubmaster,
  ...rest
}: WrappedFieldProps & FieldProps) => (
  <FormControl>
    <RadioGroup {...input} {...rest} value={input.value}>
      <RadioButton
        style={{ padding: 3 }}
        defaultChecked={checked}
        value="User"
        label={
          <Typography category="Default" variant="Body">
            {t("Driver")}
          </Typography>
        }
      />

      <RadioButton
        value="SubMaster"
        disabled={disableAdmin}
        style={{ padding: 3 }}
        label={
          <div>
            <Typography category="Default" variant="Body">
              {t("Admin")}{" "}
            </Typography>
            {disableAdmin && !isSubmaster ? (
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.secondary[11]}
                style={{ opacity: 0.38 }}
              >
                {t("Reached the limit_", { a: subMaster })}
              </Typography>
            ) : (
              <Typography category="Default" variant="Body">
                {subMaster}
              </Typography>
            )}
          </div>
        }
      ></RadioButton>
    </RadioGroup>
  </FormControl>
);

const useStyles = makeStyles((theme: Theme) => ({
  email: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
    },
  },
  role: {
    display: "flex",
    flexDirection: "column",
  },
  btnDiv: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sendBtn: {
    whiteSpace: "nowrap",
    width: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(2) }
        : { marginRight: theme.spacing(2) }),
      marginBottom: 0,
      width: "fit-content",
    },
  },
  cancelBtn: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
    },
  },
  emailError: {
    paddingBottom: 3,
  },
  roleWrap: {
    margin: "13px 0px 21px -3px",
  },
  cntDiv: {
    display: "flex",
    marginTop: "3px",
    marginBottom: "24px",
    justifyContent: "flex-end",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginTop: "4px",
    },
  },
}));

const schema = yup.object().shape({
  guestEmail: yup
    .string()
    .trim()
    .email("Enter a valid email")
    .required("Enter email"),
});

const Invite = (props: InjectedFormProps<IMemberForm, {}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const { loading } = useSelector((state: RootState) => state[MEMBER]);
  const { error } = useSelector((state: RootState) => state[ERROR]);
  const subMaster = useSelector(
    (state: RootState) => state[USER].userProfile?.subMaster
  );
  const userType = useSelector(
    (state: RootState) => state[USER].userProfile?.userType
  );

  useEffect(() => {
    dispatch(loadMembers());
  }, [dispatch]);

  const memberList = useSelector(
    (state: RootState) => state[MEMBER].members?.inviteMember
  );

  const formError = getFormAsyncErrors("InviteForm")(state);

  const checkAdminDisabled = () => {
    const subMasterNum = subMaster?.split("/").filter((x) => x);
    const inviteNum = _.nth(subMasterNum, 0);
    const limitNum = _.nth(subMasterNum, 1);
    const result = inviteNum === limitNum;
    if (userType === "Master" && result === true) {
      return true;
    } else if (userType === "Master" && result === false) {
      return false;
    } else if (userType === "SubMaster") {
      return true;
    } else {
      return false;
    }
  };

  function handleBack() {
    history.replace("/members");
  }

  const [input, setInput] = React.useState({
    guestEmail: "",
  });
  const { guestEmail } = input;

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((i) => ({
      ...i,
      [name]: value,
    }));
  }, []);

  const handleEmailCheck = useMemo(() => {
    if (guestEmail.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [guestEmail]);

  const invitedMember = useMemo(() => {
    const membersEmail = _.map(memberList, _.iteratee("email"));
    return _.includes(membersEmail, guestEmail);
  }, [guestEmail, memberList]);

  const handleInvite = () => {
    if (invitedMember) {
      dispatch(setError("An invitation was_"));
    } else {
      dispatch(submit("InviteForm"));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          className={clsx(
            classes.email,
            error && classes.emailError,
            formError && classes.emailError
          )}
          name="guestEmail"
          label={t("Email")}
          onChange={handleChange}
          t={t}
          component={renderEmailField}
          error={error && t(error)}
          autoFocus
          maxLength={256}
        />
        {/* mantis - 7606 이메일 글자 수 ui 추가 */}
        <div className={classes.cntDiv}>
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["2"]}
          >
            {guestEmail.length}/256
          </Typography>
        </div>
        <div className={classes.role}>
          <Typography category="Default" variant="H6">
            {t("Role")}
          </Typography>
          <Field
            className={classes.roleWrap}
            name="userType"
            subMaster={subMaster}
            t={t}
            disableAdmin={checkAdminDisabled()}
            isSubmaster={userType === "SubMaster"}
            component={radioButton}
            onChange={handleChange}
          />
        </div>
        <div className={classes.btnDiv}>
          <Button
            color="primary"
            onClick={handleInvite}
            className={classes.sendBtn}
            disabled={handleEmailCheck}
            loading={loading}
          >
            {t("Send invite")}
          </Button>
          <Button
            onClick={handleBack}
            className={classes.cancelBtn}
            variant="outlined"
            color="primary"
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    </form>
  );
};

const InviteForm = reduxForm<IMemberForm, {}>({
  form: "InviteForm",
  asyncValidate: validator(schema),
  initialValues: {
    userType: "User",
  },
})(Invite);

export default InviteForm;
