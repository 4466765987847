import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Typography } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeofencePanel } from "../components/geofence/GeofencePanel";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeofence,
  GEOFENCE,
  reset,
  updateGeofence,
} from "../features/Geofence/slice";
import _ from "lodash";
import * as turf from "@turf/turf";
import { RootState } from "../features/store";
import { MobileDrawer } from "../components/MobileDrawer";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import {
  // convertGeofenceToGeometries,
  simulateDblClick,
} from "../utils/GoogleMap";
import { loadCameras } from "../features/Camera/slice";
import { MapboxGeofenceMap } from "../components/maps/MapboxGeofenceMap";
import { IGeofence, LightColors, Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: (props: ScreenDefaultProps) =>
      // props.error
      //   ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
      //   : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px - 56px)"
        : "calc(var(--vh, 1vh) * 100 - 56px )",
    marginTop: (props: ScreenDefaultProps) =>
      props.error ? 68 + 127 : 68 + 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) =>
        // props.error ? 68 + 85 : 68 + 56,
        props.error ? 68 + 85 : 56,
    },
  },
  subHeaderDiv: {
    backgroundColor: LightColors.primary["0"],
    top: (props: ScreenDefaultProps) => (props.error ? 127 : 56),
    margin: theme.spacing(0, 2),
    ...(theme.direction === "rtl"
      ? { left: 0, right: 0 }
      : { left: 235, right: 0 }),
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      top: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
      margin: theme.spacing(0, 4),
      ...(theme.direction === "rtl"
        ? { left: 0, right: 235 }
        : { left: 235, right: 0 }),
    },
    // right: 0,
    position: "fixed",
    zIndex: 90,
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  subHeaderDivClosed: {
    left: 73,
  },
  subHeaderDivMobile: {
    left: 0,
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    height: 68,
    backgroundColor: LightColors.primary["0"],
  },
  btnDiv: {
    transition: theme.transitions.create("width"),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: "auto",
    },
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(3) }
      : { marginRight: theme.spacing(3) }),
  },
  addBtn: {
    minWidth: 163,
    margin: theme.spacing(2, 0),
  },
  body: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    overflow: "auto",
  },
  mapDiv: {
    width: "100%",
    height: (props: ScreenDefaultProps) =>
      // props.error
      //   ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
      //   : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px - 56px - 56px - 68px)"
        : "calc(var(--vh, 1vh) * 100 - 56px - 56px - 68px)",

    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      height: (props: ScreenDefaultProps) =>
        // props.error
        //   ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
        //   : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 56px)"
          : "calc(var(--vh, 1vh) * 100 - 56px)",
    },
  },
  searchDiv: {
    width: 244,
    position: "absolute",
    backgroundColor: LightColors.primary["0"],
    top: 16,
    right: 60,
    borderRadius: 4,
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: 320,
      right: 76,
    },
  },
  searchIcon: {
    "& svg": {
      fontSize: "18px!important",
      color: LightColors.primary["3"],
    },
  },
  panelDiv: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
    height: "calc(100% - 32px)",
    margin: theme.spacing(2),
  },
}));

interface GeofenceScreenProps {
  mode?: "view" | "add";
}

export const GeofenceScreen = (
  props: GeofenceScreenProps & ScreenDefaultProps
) => {
  const { openMenu, mode } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(
    theme.breakpoints.down(Webviewer.geofenceMobile)
  );

  const { geofences, undoList, redoList } = useSelector(
    (state: RootState) => state[GEOFENCE]
  );

  const mapDivRef = useRef<HTMLDivElement>(null);

  const [clearMode, setClearMode] = useState(false);
  const [selectedFence /*setSelectedFence*/] = useState<IGeofence>();
  // const [openDelete, setOpenDelete] = useState(false);
  const [focusedName, setFocusedName] = useState(false);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    if (mode === "add") {
      dispatch(reset());
    }
  }, [dispatch, mode]);

  useEffect(() => {
    if (mode === "add" && geofences.length >= 20) {
      history.goBack();
    }
  }, [geofences.length, history, mode]);

  useEffect(() => {
    if (clearMode) {
      setClearMode(false);
    }
  }, [clearMode]);

  const handleCancel = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (focusedName) {
        return;
      }
      const key = e.key || e.keyCode;
      switch (key) {
        case "Enter":
          if (mode === "add") {
            const { offsetLeft, offsetTop } = mapDivRef.current ?? {
              offsetLeft: 634,
              offsetTop: 348,
            };
            const { clientWidth, clientHeight } = mapDivRef.current ?? {
              clientWidth: 0,
              clientHeight: 0,
            };

            simulateDblClick(
              offsetLeft + clientWidth / 2,
              offsetTop + clientHeight / 2
            );
          }
          break;
        case "Escape":
          handleCancel();
          break;
        case "Backspace":
          // handleUndo();
          break;
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [focusedName, handleCancel, mode]);

  const geofencePanelMarkup = useMemo(() => {
    return (
      <GeofencePanel
        mode={mode}
        mobile={mobile}
        disableUndo={undoList.length < 2}
        disableRedo={redoList.length === 0}
        selectedFence={selectedFence}
        onFocus={(b) => setFocusedName(b)}
        onCancel={handleCancel}
        onSave={({ mode, geofence }) => {
          console.log("GeofenceScreen", "onSave", "geofence", geofence);
          // polygon
          if (geofence.fenceType === 1) {
            const g = _.last(undoList);
            const data = {
              ...geofence,
              polygon: _.map(g?.geometry.coordinates?.[0], (coord) => ({
                lng: coord[0],
                lat: coord[1],
              })),
            };
            // console.log("GeofenceScreen", "onSave", "data", data);
            if (mode === "update") {
              dispatch(updateGeofence(data));
            } else {
              dispatch(addGeofence(data));
            }
          } else if (geofence.fenceType === 4) {
            const g = _.last(undoList);
            const data = {
              ...geofence,
              polyline: _.map(g?.geometry.coordinates, (coord) => ({
                lng: coord[0],
                lat: coord[1],
              })),
            };
            if (mode === "update") {
              dispatch(updateGeofence(data));
            } else {
              dispatch(addGeofence(data));
            }
          } else if (geofence.fenceType === 3) {
            const g = _.last(undoList);
            const center = g?.properties?.center;
            const radius = g?.properties?.radiusInKm * 1000;
            if (center && radius) {
              const data = {
                ...geofence,
                circle: [
                  {
                    lat: center[1],
                    lng: center[0],
                    radius,
                  },
                ],
              } as IGeofence;
              if (mode === "update") {
                dispatch(updateGeofence(data));
              } else {
                dispatch(addGeofence(data));
              }
            }
          } else if (geofence.fenceType === 2) {
            const g = _.last(undoList);
            if (g) {
              const bbox = turf.bbox(g);
              const northeast = [bbox[2], bbox[3]];
              const southwest = [bbox[0], bbox[1]];

              const data = {
                ...geofence,
                rectangle: [
                  // 8839 - geofence rectangle 저장 순서 변경
                  { lat: southwest[1], lng: southwest[0] },
                  { lat: southwest[1], lng: northeast[0] },
                  { lat: northeast[1], lng: northeast[0] },
                  { lat: northeast[1], lng: southwest[0] },
                ],
              } as IGeofence;

              if (mode === "update") {
                dispatch(updateGeofence(data));
              } else {
                dispatch(addGeofence(data));
              }
            }
            // if (g?.bounds) {
            //   const data = {
            //     ...geofence,
            //     rectangle: [
            //       // 8839 - geofence rectangle 저장 순서 변경
            //       { lat: g.bounds.south, lng: g.bounds.west },
            //       { lat: g.bounds.south, lng: g.bounds.east },
            //       { lat: g.bounds.north, lng: g.bounds.east },
            //       { lat: g.bounds.north, lng: g.bounds.west },
            //     ],
            //   } as IGeofence;

            //   if (mode === "update") {
            //     dispatch(updateGeofence(data));
            //   } else {
            //     dispatch(addGeofence(data));
            //   }
            // }
          }
        }}
      />
    );
  }, [
    dispatch,
    handleCancel,
    mobile,
    mode,
    redoList.length,
    selectedFence,
    undoList,
  ]);

  // const geometries = useMemo(() => {
  //   return convertGeofenceToGeometries(geofences);
  // }, [geofences]);

  // // 불필요한 geofence 업데이트 방지 (7212)
  // const handleSelectGeofence = useCallback(
  //   (fenceId: string) => {
  //     setSelectedFence(_.find(geofences, (fence) => fence.fenceId === fenceId));
  //   },
  //   [geofences]
  // );

  return (
    <div className={classes.root} dir={theme.direction}>
      {mobile && (
        <div
          className={clsx(classes.subHeaderDiv, {
            [classes.subHeaderDivClosed]: !openMenu,
            [classes.subHeaderDivMobile]: mobile,
          })}
        >
          <div className={classes.subHeader}>
            <div className={clsx(classes.btnDiv)}>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => history.push("/geofences/add-geofence")}
                className={clsx(classes.addBtn)}
                disabled={mode === "add" || geofences.length >= 20}
              >
                {t("Add geofence")}
              </Button>
            </div>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {geofences.length} / 20
            </Typography>
          </div>
        </div>
      )}

      <div className={classes.body}>
        <div className={classes.mapDiv} ref={mapDivRef}>
          <MapboxGeofenceMap mode={mode} />
        </div>
        {!mobile && (
          <div className={classes.panelDiv} dir={theme.direction}>
            {geofencePanelMarkup}
          </div>
        )}
        {mobile && (
          <MobileDrawer mode="geofence">{geofencePanelMarkup}</MobileDrawer>
        )}
      </div>
      {/* <Modal
        open={openDelete}
        content={t("Are you sure_delete_zone")}
        heading={t("Delete")}
        onClickAway={() => setOpenDelete(false)}
        onClickNegative={() => setOpenDelete(false)}
        onClickPositive={() => {
          handleCancel();
          setOpenDelete(false);
        }}
        RButton={t("OK")}
        LButton={t("Cancel")}
      /> */}
    </div>
  );
};
