import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type MsgType =
  | "ALARM_MANUAL"
  // Normal event recording
  | "ALARM_EVENT"
  | "ALARM_SPEED"
  | "ALARM_HARSHBRAKING"
  | "ALARM_ACCELERATION"
  | "ALARM_SHARPTURN"

  // Parking event recording
  | "ALARM_PARK_EVENT"
  | "ALARM_PARK_MOTION"
  | "ALARM_PARK_IN"
  | "ALARM_PARK_OUT"

  // Geofencing
  | "ALARM_GEOFENCE_ENTER"
  | "ALARM_GEOFENCE_EXIT"
  | "ALARM_GEOFENCE_PASS"
  | "ALARM_GEOFENCE"

  // Battery Cloud
  | "ALARM_BATTERY_DISCHARGE"

  // Driver monitoring system (DMS)
  | "ALARM_DROWSY"
  //mantis - 8775, 2차졸음 타입 추가
  | "ALARM_DROWSY_SECOND"
  | "ALARM_CALLING"
  | "ALARM_SMOKING"
  | "ALARM_MASK_OFF"
  | "ALARM_DISTRACTED"
  | "ALARM_UNDETECTED"
  | "ALARM_DETECTED"
  | "ALARM_SEATBELT"

  // Etc
  | "DEVICE_CONNECT"
  | "DEVICE_DISCONNECT"
  | "REQ_SHARING_DEVICE"
  | "NOTICE"
  | "ALARM_CLOUDSTORAGE"
  | "ALARM_LOW_VOLTAGE"
  | "ALARM_SETTING_SAVED"
  | "ALARM_FATIGUE";

export const MSG_CODE_TYPE: MsgType[] = [
  "ALARM_MANUAL",
  // Normal event recording
  "ALARM_EVENT",
  "ALARM_SPEED",
  "ALARM_HARSHBRAKING",
  "ALARM_ACCELERATION",
  "ALARM_SHARPTURN",

  // Parking event recording
  "ALARM_PARK_EVENT",
  "ALARM_PARK_MOTION",
  "ALARM_PARK_IN",
  "ALARM_PARK_OUT",

  // Geofencing
  "ALARM_GEOFENCE_ENTER",
  "ALARM_GEOFENCE_EXIT",
  "ALARM_GEOFENCE_PASS",
  "ALARM_GEOFENCE",

  // Battery Cloud
  "ALARM_BATTERY_DISCHARGE",

  // Driver monitoring system (DMS)
  "ALARM_CALLING",
  "ALARM_SMOKING",
  "ALARM_MASK_OFF",
  "ALARM_DROWSY",
  "ALARM_DROWSY_SECOND",
  "ALARM_DISTRACTED",
  "ALARM_UNDETECTED",
  "ALARM_DETECTED",
  "ALARM_SEATBELT",

  // Etc
  "DEVICE_CONNECT",
  "DEVICE_DISCONNECT",
  "ALARM_CLOUDSTORAGE",
  "ALARM_LOW_VOLTAGE",

  "ALARM_SETTING_SAVED",
  "ALARM_FATIGUE",
];

export interface EventFile {
  rid: string;
  filename: string;
  exp: string;
}
export interface LatestEventOrig {
  psn: string;
  msg_code: MsgType;
  msg: string;
  img_url: string;
  event_file: string;
  vdate: string;
}

export interface ILatestEvent {
  psn: string;
  msg_code: MsgType;
  msg: string;
  img_url?: string;
  event_file?: string;
  vdate?: moment.Moment;
}

export interface IUXUILatestEvent {
  _id: string;
  email?: string;
  psn: string;
  alarm_type: MsgType;
  msg: string;
  img_url?: string;
  event_file?: string;
  cdate?: moment.Moment;
  readDT?: moment.Moment;
  first?: boolean;
  last?: boolean;
  //대시캠 타임존 반영안된 서버 UTC 시간
  originalCdate?: moment.Moment;
}

export interface EventFileList {
  usage: number;
  limit: number;
  filelist: EventFile[];
}

export interface IEventRequest {
  psn: string[];
  msg_code: string[];
  startDate: string;
  endDate: string;
}

interface IEventState {
  loading: boolean;
  eventFileList?: EventFileList;
  latestEvents: IUXUILatestEvent[];
  events: IUXUILatestEvent[];
}

let initialState: IEventState = {
  loading: false,
  eventFileList: undefined,
  latestEvents: [],
  events: [],
};

const slice = createSlice({
  name: "event",
  initialState,
  reducers: {
    resetEvent: (state) => {
      state.loading = false;
      state.eventFileList = undefined;
      state.latestEvents = [];
      state.events = [];
    },
    loadEvent: (state, _action: PayloadAction<IEventRequest>) => {
      state.loading = true;
    },
    successLoadEvent: (
      state,
      { payload }: PayloadAction<IUXUILatestEvent[]>
    ) => {
      state.loading = false;
      state.events = payload;
    },
    loadLatestEvent: (state, _action: PayloadAction<string[]>) => {
      state.loading = true;
    },
    successLoadLatestEvent: (
      state,
      { payload }: PayloadAction<IUXUILatestEvent[]>
    ) => {
      state.loading = false;
      state.latestEvents = payload;
    },
  },
});

export const {
  resetEvent,
  loadEvent,
  successLoadEvent,
  loadLatestEvent,
  successLoadLatestEvent,
} = slice.actions;

export const EVENT = slice.name;
export default slice.reducer;
