import React, { useEffect, useState } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  Avatars,
  DashCamList,
  Fonts,
  Modal,
  RadioButton,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/styles";

import Input from "@thingsw/pitta-design-system/dist/components/Input";
import SearchIcon from "@material-ui/icons/Search";
import { FormControl, RadioGroup, useMediaQuery } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableContainer from "@material-ui/core/TableContainer";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableRow from "@material-ui/core/TableRow";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import _ from "lodash";
import { addAdmin, GROUP } from "../../features/Group/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { loadMembers, MEMBER } from "../../features/Member/slice";
import {
  Webviewer,
  LightColors,
  IGroupUserList,
  StatusColors,
  StatusNames,
  IMembersInfo,
} from "@thingsw/pitta-modules";

const ADMIN_HEADERS = ["Name, Email", "Status"];

const TableHeaderCell = withStyles((theme: Theme) => ({
  head: {
    padding: theme.spacing(1.5),
    "&:first-child": {
      padding: "12px 7px 10px",
    },
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),

    [theme.breakpoints.up(Webviewer.mobile)]: {
      "&:first-child": {
        padding: "12px 7px",
      },
    },
    color: LightColors.primary["2"],
    ...Fonts.Default.Small,
  },
}))(MuiTableCell);

const TableCell = withStyles((theme: Theme) => ({
  root: {
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),
  },
  head: {
    padding: 5,
  },
  body: {
    padding: theme.spacing(1.5) - 1,

    color: LightColors.primary["1"],
    ...Fonts.Default.Body,
  },
}))(MuiTableCell);

const useStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    padding: 6,
    marginRight: -6,
  },
  searchBtn: {
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginBottom: theme.spacing(0.375),
      width: "100%",
      justifyContent: "flex-end",
      marginRight: 0,
    },
  },
  searchIcon: {
    "& svg": {
      fontSize: "1.125rem!important",
    },
  },
  groupNameText: {
    marginBottom: theme.spacing(2),
  },
  adminTablewrap: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  avatarDiv: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  row: {
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginBottom: 2,
    },
  },
  infoDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: -5,
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
  },
  tdName: {
    minWidth: 382,
    width: 388,
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 22 }
      : { paddingLeft: 0, paddingRight: 22 }),
  },
  tdNameRadioBtn: {
    ...(theme.direction === "rtl" ? { paddingLeft: 12 } : { paddingRight: 12 }),
  },
  tdStatus: {
    verticalAlign: "top",
    minWidth: 86,
    width: 104,
  },
  modalContent: {
    marginTop: -2,
    marginBottom: -2,
  },
  modalContentWrap: {
    padding: theme.spacing(2.5, 3, 3.25),
    display: "block",
  },
}));

interface GroupAddAdminModalProps {
  user?: IGroupUserList;
  open: boolean;
  group?: DashCamList;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

export const GroupAddAdminModal = ({
  user,
  open,
  group,
  onClose,
  onClickNegative,
  onClickPositive,
}: GroupAddAdminModalProps) => {
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const members = useSelector(
    (state: RootState) => state[MEMBER].members?.inviteMember
  );

  const { loading, type } = useSelector((state: RootState) => state[GROUP]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [searchedMembers, setSearchedMembers] = useState<IMembersInfo[]>([]);
  const membersArr = _.map(searchedMembers, _.iteratee("email"));

  const [admin, setAdmin] = useState<string>(membersArr[0]);

  useEffect(() => {
    dispatch(loadMembers());
  }, [dispatch]);

  useEffect(() => {
    if (members) {
      const adminMembers = _.filter(
        members,
        (m) =>
          m.role === "SubMaster" && m.groupName === "" && m.mailStatus !== "E"
      );

      const searchedMembers = _.filter(adminMembers, (m) => {
        return (
          m.email.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
          (!!m.firstName &&
            m.firstName.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) ||
          (!!m.lastName &&
            m.lastName.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
        );
      });

      setSearchedMembers(searchedMembers);
    }
  }, [members, searchKey]);

  useEffect(() => {
    if (searchedMembers.length > 0) {
      setAdmin(searchedMembers[0].email);
    } else {
      setAdmin(undefined);
    }
  }, [searchedMembers]);

  useEffect(() => {
    return () => {
      setAdmin(membersArr[0]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddAdmin = (group: DashCamList) => {
    if (admin) {
      dispatch(
        addAdmin({
          guestEmail: admin,
          groupManagementID: group.GroupID,
        })
      );
    }
  };

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      loading={loading && type === addAdmin.type}
      onClickNegative={onClickNegative}
      onClickPositive={(e) => {
        group && handleAddAdmin(group);
      }}
      heading={t("Add admin")}
      contentClassName={classes.modalContentWrap}
      content={
        <div className={classes.modalContent}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
            className={classes.groupNameText}
          >
            {group?.GroupName}
          </Typography>
          <Input
            autoFocus
            placeholder={t("Name or email")}
            startIcon={
              <SearchIcon style={{ color: LightColors.primary["3"] }} />
            }
            startIconClassName={classes.searchIcon}
            className={classes.searchBtn}
            dense
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          />

          <MuiTableContainer className={classes.adminTablewrap}>
            <MuiTable aria-label="simple table">
              <MuiTableHead>
                <MuiTableRow>
                  {_.map(ADMIN_HEADERS, (header) => (
                    <TableHeaderCell>{t(header)}</TableHeaderCell>
                  ))}
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                <>
                  {_.map(searchedMembers, (member, index) => (
                    <MuiTableRow>
                      <RadioGroup
                        style={{ margin: 0, display: "contents" }}
                        value={admin ?? ""}
                        onChange={(e) => setAdmin(e.target.value)}
                      >
                        <TableCell className={classes.tdName}>
                          <div style={{ display: "flex" }}>
                            <FormControl
                              style={{
                                display: "flex",
                                margin: 0,
                                justifyContent: "center",
                              }}
                              className={classes.tdNameRadioBtn}
                            >
                              <RadioButton
                                value={member.email}
                                style={{ padding: 3, marginLeft: 2 }}
                              />
                            </FormControl>
                            <div className={classes.infoDiv}>
                              <div className={classes.avatarDiv}>
                                <Avatars
                                  imgSrc={member.profileImg}
                                  // Mantis-8566
                                  // name={member.lastname} >> {member.email}로 수정함.
                                  name={member.email}
                                />
                              </div>
                              <div>
                                <div className={classes.row}>
                                  {member.firstName && member.lastName ? (
                                    <Typography
                                      category="Default"
                                      variant="Body"
                                      htmlColor={LightColors.primary["1"]}
                                    >
                                      {`${member.firstName} ${member.lastName}`}
                                    </Typography>
                                  ) : (
                                    "–"
                                  )}
                                </div>

                                <div className={classes.row}>
                                  <Typography
                                    category="Default"
                                    variant="Caption"
                                    htmlColor={LightColors.primary["2"]}
                                  >
                                    {member.email}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tdStatus}>
                          <div className={classes.statusDiv}>
                            <FiberManualRecordIcon
                              style={{
                                color: `${StatusColors[member.mailStatus]}`,
                                fontSize: 15,
                              }}
                            />
                            <Typography
                              category="Default"
                              variant="Body"
                              style={{
                                marginLeft: 5.5,
                              }}
                            >
                              {StatusNames[member.mailStatus]}
                            </Typography>
                          </div>
                        </TableCell>
                      </RadioGroup>
                    </MuiTableRow>
                  ))}
                </>
              </MuiTableBody>
            </MuiTable>
          </MuiTableContainer>
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Add")}
      RButtonDisabled={admin === undefined}
      close
      fullSize={mobile && true}
    />
  );
};
