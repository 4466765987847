import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  makeStyles,
  Modal,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { WebviewerMenu } from "../components/WebviewerMenu";
import { WebviewerHeader } from "../components/WebviewerHeader";
import clsx from "clsx";
import {
  Avatars,
  Button,
  GooglePlay,
  IconButton,
  LogoOnly,
  Toast,
  Typography,
  Modal as ModalPricing,
} from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { closeToast, TOAST } from "../features/Toast/slice";
import { useTranslation } from "react-i18next";
import { WebviewerMenuList } from "../components/WebviewerMenuList";

import {
  loadUserNotification,
  loadUserPermissions,
  logout,
  USER,
} from "../features/User/slice";
import { PAYMENT } from "../features/Payment/slice";
import { getPlanFromServiceID, PlanType } from "../utils/Service";

import CloseIcon from "@material-ui/icons/Close";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import LanguageIcon from "@material-ui/icons/Language";
import AppleIcon from "@material-ui/icons/Apple";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { LangMenuItems } from "../components/LangMenuItems";
import { clearError, ERROR } from "../features/Error/slice";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { disconnectWebPush, loadPushToken } from "../features/PushEvent/slice";
import { CAMERA } from "../features/Camera/slice";
import { Pricing } from "../components/Pricing";
import { setPermission } from "../features/Permission/slice";
import {
  changeDirection,
  setFontFamily,
  setMenuOpen,
  setNeedToChangeLang,
  THEME,
} from "../features/Theme/slice";
import WebPushNotification from "../components/WebPushNotification";
import "simplebar/src/simplebar.css";
import { changeLang } from "../utils/ChangeLang";
import { initGPSWs, LOCATION_WS } from "../features/LocationWS/slice";
import {
  SemanticDarkColors,
  Webviewer,
  UserNames,
  Languages,
  ERROR_TIMEOUT,
  Color,
  createPermissions,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(var(--vh, 1vh) * 100)",
    backgroundColor: (props: any) =>
      props.color === "dark"
        ? SemanticDarkColors.primary["1"]
        : props.colors.primary["0"],
    transition: theme.transitions.create("margin"),
    // padding: theme.spacing(0, 4),
    // marginTop: theme.spacing(7),
    overflowY: "hidden",
  },
  rootOpen: {
    ...(theme.direction === "rtl" ? { marginRight: 235 } : { marginLeft: 235 }),
    // maxWidth: 1920 - 235,
  },
  rootClose: {
    ...(theme.direction === "rtl" ? { marginRight: 73 } : { marginLeft: 73 }),
    maxWidth: 1920 - 73,
  },
  rootMapOpen: {
    maxWidth: "calc(100vw - 235px)",
  },
  rootMapClose: {
    maxWidth: "calc(100vw - 73px)",
  },
  mobileDrawerPaper: {
    width: "100%",
  },
  mobileMenuDiv: {
    backgroundColor: (props: any) => props.colors.primary["0"],
    width: "100%",
    height: "calc(var(--vh, 1vh) * 100)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    transition: theme.transitions.create("transform"),
  },
  langMobileDiv: {
    justifyContent: "flex-start",
  },
  mainMenuClose: {
    transform: "translateX(-100%)",
  },
  langDivClose: {
    transform: "translateX(100%)",
  },
  langDivOpen: {
    transform: "translateX(0)",
  },
  mobileDrawerDiv: {
    // width: "100%",
    flex: 1,
    overflowY: "auto",
  },
  mobileLogoDiv: {
    display: "flex",
    minHeight: 56,
    padding: theme.spacing(1.25, 2),
    borderBottom: (props: any) => `1px solid  ${props.colors.primary["6"]}`,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  userInfoDiv: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(2) }
      : { marginLeft: theme.spacing(2) }),
  },
  menuListDiv: {
    padding: theme.spacing(1, 0),
    borderBottom: (props: any) => `1px solid  ${props.colors.primary["6"]}`,
  },
  upgradeBtn: {
    margin: theme.spacing(2),
  },
  btnDiv: {
    padding: theme.spacing(2, 3),
  },
  menuBtnDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.75, 0),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    color: (props: any) => props.colors.primary["1"],
    "&:hover": {
      color: (props: any) => props.colors.primary["8"],
    },
    "&:active": {
      color: (props: any) => props.colors.primary["8"],
    },
  },
  appIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.875) }
      : { marginRight: theme.spacing(1.875) }),
  },
  langIcon: {
    // fontSize: 18,
    padding: theme.spacing(0, 0.25),
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.875) }
      : { marginRight: theme.spacing(1.875) }),
  },
  textOnly: {
    margin: theme.spacing(0, 4.75),
    color: (props: any) => props.colors.primary["3"],
  },
  backBtn: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  modalContent: { padding: 0 },
}));

export interface LocationHistory {
  to: string;
  from: string;
}

export interface ScreenDefaultProps {
  openMenu: boolean;
  error?: string;
  plan?: PlanType;
  headerDivRef?: HTMLDivElement;
  app?: boolean;
  colors: Color;
  color: "dark" | "light";
  noHeader?: boolean;
}

const withViewerTemplate = (WrappedComponent: React.FC<any>) => (
  props: any
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const locationCamMode = useLocation<{ mode?: "map" | "list" }>();
  const { i18n, t } = useTranslation();
  const theme = useTheme() as Theme;
  const defaultMobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const geofenceMobile = useMediaQuery(
    theme.breakpoints.down(Webviewer.geofenceMobile)
  );
  const history = useHistory();

  const headerDivRef = useRef<HTMLDivElement>(null);

  const {
    email,
    userProfile,
    loginInfo,
    permissions: perm,
    userNotification,
  } = useSelector((state: RootState) => state[USER]);
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  const { open, message, option, notification } = useSelector(
    (state: RootState) => state[TOAST]
  );
  const { camera } = useSelector((state: RootState) => state[CAMERA]);
  const { error } = useSelector((state: RootState) => state[ERROR]);
  const { menuOpen, needToChangeLang, lang, colors, color } = useSelector(
    (state: RootState) => state[THEME]
  );
  const { gpsWsReadyState } = useSelector(
    (state: RootState) => state[LOCATION_WS]
  );
  const classes = useStyles({ colors, color });
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openLangMenu, setOpenLangMenu] = useState(false);
  const [withMap, setWithMap] = useState(false);
  const [openPricing, setOpenPricing] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [planName, setPlanName] = useState<string>();
  const [pathname, setPathname] = useState<string>();

  const [mode, setMode] = useState<"map" | "list">("map");
  const [webSearchKey, setWebSearchKey] = useState("");

  // mantis - 10192, cameraScreen 화면은 Map으로 표시되어지나 상단 헤더버튼이 List로 선택되어져 표시되는 이슈 수정(Leehj)
  useEffect(() => {
    setMode(locationCamMode.state?.mode ?? "map");
  }, [locationCamMode]);

  // Cloud Native 2차 대시캠 위치 얻어오는 websocket 처리
  useEffect(() => {
    console.log("GPS_WS", "initGPSWs", loginInfo, email, gpsWsReadyState);
    if (loginInfo && email && gpsWsReadyState === WebSocket.CLOSED) {
      console.log("GPS_WS", "initGPSWs");
      dispatch(initGPSWs());
      const interval = setInterval(() => {
        console.log("GPS_WS", "clear", "initGPSWs");
        dispatch(initGPSWs());
      }, 10 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, email, gpsWsReadyState, loginInfo]);

  useEffect(() => {
    console.log("needToChangeLang", needToChangeLang, lang);
    if (needToChangeLang) {
      changeLang(i18n, lang, dispatch);
      dispatch(setNeedToChangeLang(false));
    }
  }, [dispatch, i18n, lang, needToChangeLang]);

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (location.pathname.startsWith("/geofences")) {
      setMobile(geofenceMobile);
    } else {
      setMobile(defaultMobile);
    }
  }, [defaultMobile, geofenceMobile, location.pathname]);

  useEffect(() => {
    setPathname(location.pathname);
    if (pathname) {
      setOpenPricing(false);
    }
  }, [location.pathname, pathname]);

  const permissions = useMemo(() => {
    return createPermissions({
      userProfile,
      perm,
      subscriptionInfo,
      planName,
    });
  }, [perm, planName, subscriptionInfo, userProfile]);

  useEffect(() => {
    dispatch(setPermission(permissions));
  }, [dispatch, permissions]);

  useEffect(() => {
    if (email && userNotification && loginInfo) {
      if (userNotification.device_alarm === "on") {
        dispatch(loadPushToken(email));
      } else {
        console.log("disconnectWebPush");
        dispatch(disconnectWebPush());
      }
    }
  }, [dispatch, email, loginInfo, userNotification]);

  useEffect(() => {
    // dispatch(loadCameras());
    dispatch(loadUserPermissions());
    dispatch(loadUserNotification());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearError());
    const regex = new RegExp("^/cameras/([A-za-z0-9]+)/gps-tracking");
    if (
      _.includes(
        ["/cameras", "/gps-tracking", "/geofences", "/geofences/add-geofence"],
        location.pathname
      ) ||
      !!location.pathname.match(regex)
    ) {
      setWithMap(true);
    } else {
      setWithMap(false);
    }
  }, [dispatch, location]);

  const plan = useMemo(
    () =>
      subscriptionInfo && getPlanFromServiceID(subscriptionInfo.servicePlanID),
    [subscriptionInfo]
  );

  const shownError = useMemo(() => {
    const regex1 = new RegExp("^/cameras/([A-za-z0-9]+)/playback");
    const regex2 = new RegExp("^/cameras/([A-za-z0-9]+)/live-view");
    if (
      !!location.pathname.match(regex1) ||
      !!location.pathname.match(regex2)
    ) {
      return error ?? camera?.active === "off" ? "e" : undefined;
    }
    return error;
  }, [camera?.active, error, location.pathname]);

  useEffect(() => {
    if (error) {
      const timerId = setTimeout(() => {
        dispatch(clearError());
      }, ERROR_TIMEOUT);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [dispatch, error]);

  const upgradeBtnMarkup = useMemo(() => {
    if (
      subscriptionInfo &&
      subscriptionInfo.paymentMethod !== "oneplan" &&
      (_.includes([1, 1004, 1005], subscriptionInfo.servicePlanID) ||
        (subscriptionInfo.servicePlanID >= 10001 &&
          subscriptionInfo.servicePlanID <= 10003))
    ) {
      return (
        <div className={classes.upgradeBtn}>
          <Button
            color="primary"
            fullWidth
            startIcon={<CardGiftcardIcon />}
            onClick={() => {
              setOpenPricing(true);
            }}
          >
            {t("Upgrade Plan")}
          </Button>
        </div>
      );
    }
  }, [classes.upgradeBtn, subscriptionInfo, t]);

  const toggleMenuOpen = useCallback(() => {
    dispatch(setMenuOpen(!menuOpen));
  }, [dispatch, menuOpen]);

  return (
    <div style={{ backgroundColor: colors.primary["0"] }}>
      {!mobile && (
        <WebviewerMenu open={menuOpen} onToggleMenu={() => toggleMenuOpen()} />
      )}
      <div
        className={clsx(classes.root, {
          [classes.rootOpen]: menuOpen && !mobile,
          [classes.rootMapOpen]: menuOpen && withMap && !mobile,
          [classes.rootClose]: !menuOpen && !mobile,
          [classes.rootMapClose]: !menuOpen && withMap && !mobile,
        })}
      >
        <WebviewerHeader
          ref={headerDivRef}
          openMenu={menuOpen}
          error={error}
          mobile={mobile}
          onToggleMenu={
            mobile ? () => setMobileMenuOpen((o) => !o) : () => toggleMenuOpen()
          }
          // 카메라스크린헤더, mode와 searchKey를 알기위한 props
          onModeChange={(mode) => setMode(mode)}
          mode={mode}
          webSearchKey={webSearchKey}
          onSearchKey={(search) => setWebSearchKey(search ?? "")}
        />
        <WrappedComponent
          {...props}
          {...permissions}
          openMenu={menuOpen}
          error={shownError}
          plan={plan}
          headerDivRef={headerDivRef.current}
          color={color}
          colors={colors}
          // 카메라스크린헤더, mode와 searchKey를 알기위한 props
          mode={mode}
          webSearchKey={webSearchKey}
        />
      </div>

      <Toast
        open={open}
        message={(message && t(message, option)) ?? ""}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          dispatch(closeToast());
        }}
        // mantis - 11079, app과 모바일에서 notification설정 변경시 토스트팝업 가운데로 출력되도록 수정 (Leehj)
        notification={notification}
      />
      {mobile && (
        <Modal
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          // onOpen={onOpen}
          classes={{ paper: classes.mobileDrawerPaper }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={mobileMenuOpen} timeout={{ enter: 150, exit: 250 }}>
            <div style={{ position: "relative" }}>
              <div
                dir={theme.direction}
                className={clsx(classes.mobileMenuDiv, {
                  [classes.mainMenuClose]: openLangMenu,
                })}
              >
                <div
                  className={clsx(classes.mobileLogoDiv, classes.spaceBetween)}
                >
                  <LogoOnly width={36} height={36} />
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => setMobileMenuOpen(false)}
                    darkColor={color === "dark"}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  className={classes.mobileLogoDiv}
                  onClick={() => {
                    history.push("/account");
                    setMobileMenuOpen(false);
                  }}
                >
                  <Avatars
                    // Mantis-8566
                    // name={lastName} >> {userProfile?.email}로 수정함.
                    name={userProfile?.email}
                    imgSrc={userProfile?.profilePath}
                  />
                  <div className={classes.userInfoDiv}>
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["1"]}
                    >
                      {userProfile?.firstName} {userProfile?.lastName}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={colors.primary["2"]}
                    >
                      {userProfile?.email}
                    </Typography>
                    {subscriptionInfo?.paymentMethod === "oneplan" ? (
                      <Typography
                        category="Default"
                        variant="Body"
                        htmlColor={colors.primary["7"]}
                      >
                        {t("One Plan")}
                      </Typography>
                    ) : (
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={colors.primary["2"]}
                      >
                        {`${
                          subscriptionInfo &&
                          t(getPlanFromServiceID(subscriptionInfo.servicePlanID))
                        }  ${
                          subscriptionInfo &&
                          getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
                            "Fleet plan" &&
                          userProfile?.userType
                            ? `· ${t(UserNames[userProfile?.userType])}`
                            : ""
                        }`}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className={classes.mobileDrawerDiv}>
                  <div className={classes.menuListDiv}>
                    <WebviewerMenuList
                      open={true}
                      onClose={() => setMobileMenuOpen(false)}
                    />
                  </div>
                  <div className={classes.btnDiv}>
                    <div
                      className={classes.menuBtnDiv}
                      onClick={() => setOpenLangMenu(true)}
                    >
                      <LanguageIcon className={classes.langIcon} />

                      <Typography category="Default" variant="Body">
                        {Languages[i18n.language]}
                      </Typography>
                    </div>
                    <div
                      className={classes.menuBtnDiv}
                      onClick={() => {
                        window.open(
                          "https://apps.apple.com/kr/app/blackvue/id1049209637",
                          "_blank"
                        );
                      }}
                    >
                      <AppleIcon className={classes.appIcon} />

                      <Typography category="Default" variant="Body">
                        {t("iOS")}
                      </Typography>
                    </div>
                    <div
                      className={classes.menuBtnDiv}
                      onClick={() => {
                        window.open(
                          "https://play.google.com/store/apps/details?id=comb.blackvuec",
                          "_blank"
                        );
                      }}
                    >
                      <GooglePlay className={classes.appIcon} />

                      <Typography category="Default" variant="Body">
                        {t("Android")}
                      </Typography>
                    </div>

                    <div
                      className={clsx(classes.menuBtnDiv, classes.textOnly)}
                      onClick={() => {
                        window.open(
                          // 한국어 / 일본어 일시 해당 국가의 Terms & Policy로 이동 (KimSH)
                          i18n.language === "ko"
                            ? "https://blackvue.com/ko/terms-conditions-korean/"
                            : i18n.language === "ja"
                            ? "https://blackvue.com/ja/terms-conditions-japan/"
                            : "https://blackvue.com/warranty-terms-conditions/",
                          "_blank"
                        );
                      }}
                    >
                      <Typography category="Default" variant="Body">
                        {t("Terms & Policies")}
                      </Typography>
                    </div>

                    <div
                      className={clsx(classes.menuBtnDiv, classes.textOnly)}
                      onClick={() => {
                        dispatch(logout());
                      }}
                    >
                      <Typography category="Default" variant="Body">
                        {t("Log out")}
                      </Typography>
                    </div>
                  </div>
                </div>
                {upgradeBtnMarkup}
              </div>

              <div
                className={clsx(classes.mobileMenuDiv, classes.langMobileDiv, {
                  [classes.langDivClose]: !openLangMenu,
                  [classes.langDivOpen]: openLangMenu,
                })}
              >
                <div
                  className={classes.mobileLogoDiv}
                  style={{ alignItems: "center" }}
                >
                  <IconButton
                    className={classes.backBtn}
                    onClick={() => setOpenLangMenu(false)}
                    darkColor={color === "dark"}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography category="Default" variant="H6">
                    {t("Language")}
                  </Typography>
                </div>
                <div style={{ overflowY: "auto" }}>
                  <LangMenuItems
                    mode="webviewer"
                    mobile={mobile}
                    language={i18n.language}
                    onChangeLanguage={(evt, lang) => {
                      i18n.changeLanguage(lang);
                      if (lang === "ar") {
                        dispatch(changeDirection("rtl"));
                        dispatch(setFontFamily("Noto Naskh Arabic UI"));
                      } else {
                        switch (lang) {
                          case "ko":
                            dispatch(setFontFamily("Noto Sans KR"));
                            break;
                          case "ja":
                            dispatch(setFontFamily("Noto Sans JP"));
                            break;
                          case "sc":
                            dispatch(setFontFamily("Noto Sans SC"));
                            break;
                          case "th":
                            dispatch(setFontFamily("Noto Sans Thai UI"));
                            break;
                          default:
                            dispatch(setFontFamily("Roboto"));
                            break;
                        }
                        dispatch(changeDirection("ltr"));
                      }
                      localStorage.setItem("pitta-webviewer-pref-lang", lang);
                      setOpenLangMenu(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
      <ModalPricing
        open={openPricing}
        content={<Pricing modal onClose={() => setOpenPricing(false)} />}
        close
        onClose={() => {
          setOpenPricing(false);
        }}
        fullSize
        contentClassName={clsx(mobile && classes.modalContent)}
      />
      <WebPushNotification />
    </div>
  );
};

export default withViewerTemplate;
