import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { eventmapAxiosInst, eventmapJwtAxiosInst } from "../../utils";
import { generateAuthToken } from "../../utils/Auth";
import {
  IUserState,
  ILogin,
  IUserLoginInfo,
  jwtAxiosInst,
  IUserProfile,
  ISendMail,
  ISignup,
  IUserConfirm,
  IUploadImage,
  ILoadProfileImage,
  IUserName,
  IChangeName,
  IChangePasswd,
  IUserUsageInfo,
  IUserSettings,
  ISetNotification,
  ILoadNotification,
  AuthType,
  IPermissions,
  createOnFulfilled,
} from "@thingsw/pitta-modules";

let initialState: IUserState = {
  loading: false,
  //@ts-ignore
  mobile_uuid: localStorage.getItem("pitta-webviewer:mobile-uuid"),
  tokenType: "web",
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    logout: (state, _action: PayloadAction<boolean | undefined>) => {
      state.loading = false;
      console.log("UserSlice", "logout", state.jwtInterceptorId);
    },
    clearUser: (state) => {
      state.loading = false;
      state.loginInfo = undefined;
      state.userProfile = undefined;
      state.email = undefined;
      state.oauthToken = undefined;
      state.needToConfirmEmail = undefined;
      state.name = undefined;
      state.usageInfo = undefined;
      state.error = undefined;
      state.userNotification = undefined;
      state.activated = false;
    },
    userLogin: (
      state,
      _action: PayloadAction<
        ILogin & { goToCamera?: boolean; remember?: boolean; returnTo?: string }
      >
    ) => {
      state.loading = true;
    },
    successLogin: (state, { payload }: PayloadAction<IUserLoginInfo>) => {
      state.loading = false;
      state.loginInfo = payload;

      if (state.jwtInterceptorId !== undefined) {
        jwtAxiosInst.interceptors.request.eject(state.jwtInterceptorId);
        state.jwtInterceptorId = undefined;
      }
      if (state.eventmapJwtInterceptorId !== undefined) {
        eventmapJwtAxiosInst.interceptors.request.eject(
          state.eventmapJwtInterceptorId
        );
        state.eventmapJwtInterceptorId = undefined;
      }
      if (state.eventmapInterceptorId !== undefined) {
        eventmapAxiosInst.interceptors.request.eject(
          state.eventmapInterceptorId
        );
        state.eventmapInterceptorId = undefined;
      }

      if (state.email && payload.user_token && state.tokenType) {
        const authToken = generateAuthToken(state.email, payload.user_token);
        state.eventmapInterceptorId = eventmapAxiosInst.interceptors.request.use(
          createOnFulfilled(authToken, state.tokenType),
          undefined
        );
      }

      if (payload.jsonwebtoken) {
        state.jwtInterceptorId = jwtAxiosInst.interceptors.request.use(
          createOnFulfilled(payload.jsonwebtoken),
          undefined
        );
        state.eventmapJwtInterceptorId = eventmapJwtAxiosInst.interceptors.request.use(
          createOnFulfilled(payload.jsonwebtoken),
          undefined
        );
      }
    },
    successRequest: (state) => {
      state.loading = false;
    },
    successSignup: (state) => {
      state.loading = false;
      state.needToConfirmEmail = true;
    },
    needToConfirm: (state) => {
      state.needToConfirmEmail = true;
    },
    clearNeedToConfirmEmail: (state) => {
      state.needToConfirmEmail = false;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    loadUserProfile: (state, _action: PayloadAction<"signup" | undefined>) => {
      state.loading = true;
    },
    successLoadUserProfile: (
      state,
      { payload }: PayloadAction<IUserProfile>
    ) => {
      state.loading = false;
      state.userProfile = payload;
    },
    saveEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    saveOauthToken: (state, { payload }: PayloadAction<string>) => {
      state.oauthToken = payload;
    },
    sendResetMail: (state, _action: PayloadAction<ISendMail>) => {
      state.loading = true;
    },
    create: (state, action: PayloadAction<ISignup>) => {
      state.loading = true;
      state.email = action.payload.email;
    },
    confirm: (state, action: PayloadAction<IUserConfirm>) => {
      state.loading = true;
      state.cameraCnt = action.payload.cameraCnt;
    },
    uploadImage: (state, action: PayloadAction<IUploadImage>) => {
      state.loading = true;
    },
    loadProfileImage: (
      state,
      { payload }: PayloadAction<ILoadProfileImage>
    ) => {
      state.loading = false;
      state.userProfile = { ...state.userProfile, ...payload } as IUserProfile;
    },
    successChangeName: (state, { payload }: PayloadAction<IUserName>) => {
      state.loading = false;
      state.userProfile = { ...state.userProfile, ...payload } as IUserProfile;
    },
    changeName: (state, action: PayloadAction<IChangeName>) => {
      state.loading = true;
      state.type = action.type;
    },
    changePasswd: (state, action: PayloadAction<IChangePasswd>) => {
      state.loading = true;
      state.type = action.type;
      state.pwChangeError = undefined;
    },
    successChangePasswd: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.loginInfo = {
        ...state.loginInfo,
        user_token: payload,
      } as IUserLoginInfo;
    },

    failChangePasswd: (state) => {
      state.loading = false;
      state.pwChangeError = true;
    },
    loadUserToken: (state) => {
      state.loading = true;
    },
    deleteAccountMail: (state) => {
      state.loading = true;
    },
    clearActionType: (state) => {
      state.type = undefined;
    },
    loadUsageInfo: (state) => {
      state.loading = true;
    },
    successLoadUsageInfo: (
      state,
      { payload }: PayloadAction<IUserUsageInfo>
    ) => {
      state.loading = false;
      state.usageInfo = payload;
      // state.usageInfo = { ...payload, LimitLiveView: 125 };
    },
    loadUserSettings: (state) => {
      state.loading = true;
    },
    successLoadUserSettings: (
      state,
      { payload }: PayloadAction<IUserSettings>
    ) => {
      state.loading = false;
      state.userSettings = payload;
    },
    updateUserSettings: (state, _action: PayloadAction<IUserSettings>) => {
      state.loading = true;
    },
    setUserNotification: (state, _action: PayloadAction<ISetNotification>) => {
      state.loading = true;
    },
    loadUserNotification: (state) => {
      state.loading = true;
    },
    successLoadUserNotification: (
      state,
      { payload }: PayloadAction<ILoadNotification>
    ) => {
      state.loading = false;
      state.userNotification = payload;
    },
    linkSocialAccount: (
      state,
      _action: PayloadAction<{
        email: string;
        passwd: string;
        oauthToken: string;
        signupType: AuthType;
      }>
    ) => {
      state.loading = true;
    },
    successActivate: (state) => {
      state.loading = false;
      state.activated = true;
    },
    loadUserPermissions: (state) => {},
    successLoadUserPermissions: (
      state,
      { payload }: PayloadAction<IPermissions>
    ) => {
      state.permissions = payload;
    },
    updateLiveviewUsage: (
      state,
      action: PayloadAction<{ psn: string; usage: number }>
    ) => {},
    setLoginInfo: (
      state,
      action: PayloadAction<{
        email: string;
        user_token: string;
        token_type: "web" | "app";
        gps_server?: string;
        gps_port?: string;
        jsonwebtoken?: string;
      }>
    ) => {
      const {
        email,
        user_token,
        token_type,
        gps_server,
        gps_port,
        jsonwebtoken,
      } = action.payload;

      if (state.email !== email) {
        state.email = email;
      }

      state.loginInfo = {
        user_token,
        was_server: "",
        was_port: 0,
        gps_server: gps_server ?? "",
        gps_port: gps_port ?? "0",
        gps_tracking_server: "",
        gps_tracking_port: 0,
        geo_fence_server: "",
        geo_fence_port: 0,
        userType: "User",
        loginType: "blackvue",
        jsonwebtoken,
      };

      if (state.tokenType !== token_type) {
        state.tokenType = token_type;
      }
    },
    setJwtInterceptorId: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.jwtInterceptorId = payload;
    },
    setEventmapJwtInterceptorId: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.eventmapJwtInterceptorId = payload;
    },
    setEventmapInterceptorId: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.eventmapInterceptorId = payload;
    },
  },
});

export const {
  setError,
  clearError,
  userLogin,
  successLogin,
  create,
  confirm,
  logout,
  clearUser,
  loadUserProfile,
  successLoadUserProfile,
  saveEmail,
  saveOauthToken,
  sendResetMail,
  successRequest,
  successSignup,
  clearNeedToConfirmEmail,
  clearLoading,
  uploadImage,
  loadProfileImage,
  changeName,
  successChangeName,
  changePasswd,
  successChangePasswd,
  loadUserToken,
  deleteAccountMail,
  loadUsageInfo,
  successLoadUsageInfo,
  clearActionType,
  failChangePasswd,
  loadUserSettings,
  successLoadUserSettings,
  updateUserSettings,
  setUserNotification,
  loadUserNotification,
  successLoadUserNotification,
  linkSocialAccount,
  successActivate,
  loadUserPermissions,
  successLoadUserPermissions,
  // userSubscription,
  // loadSubscription,
  needToConfirm,
  updateLiveviewUsage,
  setLoginInfo,
  setJwtInterceptorId,
  setEventmapJwtInterceptorId,
  setEventmapInterceptorId,
} = slice.actions;
export const USER = slice.name;
export default slice.reducer;
