import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface PrivacyModalProps {
  open: boolean;
  onClose?: () => void;
  onClickPositive?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  eventmapModalContent: {
    padding: theme.spacing(3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
  RButton: {
    borderRadius: 10,
  },
}));

export const PrivacyModal = ({
  open,
  onClose,
  onClickPositive,
}: PrivacyModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      close
      onClose={onClose}
      onClickPositive={onClickPositive}
      heading=" "
      content={
        <Typography
          category="Default"
          variant="Body"
          dangerouslySetInnerHTML={{
            __html: t("You do not have permission_"),
          }}
        />
      }
      RButton={t("OK")}
      actionClassName={classes.modalBottom}
      contentClassName={classes.eventmapModalContent}
      className={classes.modalWrap}
      RButtonClassName={classes.RButton}
    />
  );
};
