import { makeStyles, Theme } from "@material-ui/core/styles";
import SimpleBarReact from "simplebar-react";
import { ShareEvent } from "../events/ShareEvent";
import { Modal } from "@thingsw/pitta-design-system";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { setSelectedHashtags } from "../../features/VOD/slice";
import { PortalProps } from "@material-ui/core";
import { EventVideo } from "@thingsw/pitta-modules";
import React from "react";

interface EventShareModalProps {
  open: boolean;
  onClose: () => void;
  onOpenHashtag: () => void;
  onSuccessSave?: (description: string, hashtags: number[]) => void;
  onFailSave?: (reason: string) => void;
  psn?: string;
  filename?: string;
  thmRid?: string;
  rid?: string;
  edit?: boolean;
  selectedEventVideo?: EventVideo;
  onEventVideo?: (eventVideos?: EventVideo[]) => void;
  container?: PortalProps["container"];
  mode?: 0 | 1 | 2;
}

//tab기준
const tablet = 660;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    [theme.breakpoints.up(tablet)]: {
      minWidth: 470,
      borderRadius: 12,
      maxWidth: 590,
      // paddingRight: 56,
      // paddingLeft: 56,
    },
  },
  title: {
    height: 48,
    padding: "0px 13px 0 16px",
    [theme.breakpoints.up(tablet)]: {
      height: 56,
      padding: "13px 13px 0 24px",
    },
  },
  paperScrollPaper: {
    maxHeight: "100%",
    [theme.breakpoints.up(tablet)]: {
      maxHeight: "inherit",
    },
  },
  paperWidthSm: {
    height: "100%",
    [theme.breakpoints.up(tablet)]: {
      height: "auto",
    },
  },
  modalContent: {
    padding: "16px 0px 8px",
    [theme.breakpoints.up(tablet)]: {
      padding: "20px 0px 32px",
    },
  },
  action: {
    padding: "20px 24px 24px",
  },
  scrollbarStyle: {
    height: "100%",
    flex: 1,
    [theme.breakpoints.up(tablet)]: {
      overflowX: "hidden",
      maxHeight: "calc(100vh - 200px)",
    },
  },
}));

export const EventShareModal = ({
  open,
  onClose,
  onOpenHashtag,
  onSuccessSave,
  onFailSave,
  psn,
  filename,
  thmRid,
  rid,
  selectedEventVideo,
  onEventVideo,
  container,
  mode,
}: EventShareModalProps) => {
  const classes = useStyles();

  const [description, setDescription] = useState<string>("");
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      container={container}
      onClose={(e) => {
        e.stopPropagation();
        onClose();
        dispatch(setSelectedHashtags([]));
        setDescription("");
      }}
      className={classes.root}
      titleClassName={classes.title}
      paperScrollPaperClassName={classes.paperScrollPaper}
      paperWidthSmClassName={classes.paperWidthSm}
      contentClassName={classes.modalContent}
      actionClassName={classes.action}
      // disablePortal
      heading={" "}
      content={
        <SimpleBarReact className={classes.scrollbarStyle}>
          <ShareEvent
            psn={psn}
            filename={filename}
            thmRid={thmRid}
            rid={rid}
            selectedEventVideo={selectedEventVideo}
            onDescription={(description) => setDescription(description)}
            sendDescription={description}
            onOpenHashtag={onOpenHashtag}
            onClose={() => {
              onClose();
              setDescription("");
              // dispatch(setSelectedHashtags([]));
            }}
            onSuccessSave={onSuccessSave}
            onFailSave={onFailSave}
            onEventVideo={(eventVideos) => onEventVideo?.(eventVideos)}
            mode={mode}
          />
        </SimpleBarReact>
      }
      close
      btnCenter
    />
  );
};
