import axios from "axios";
import qs from "qs";
import {
  API_GATEWAY_URI,
  AddCameraRequest,
  FIRMWARE_INFO_URI,
  IEmailNotiSettings,
  IFirmwareSettings,
  IFirmwareSettings2,
  IFotaIDPayload,
  ILiveEventSettings,
  OnOffType,
  getLbURI,
  jwtAxiosInst,
} from "@thingsw/pitta-modules";

export const getCamList = (
  email: string,
  userToken: string,
  tokenType: string,
  keyword?: string,
  startIndex?: number,
  endIndex?: number
) => {
  return jwtAxiosInst.get(
    `/DashCam/DeviceList?email=${email}&userToken=${userToken}&keyword=${
      keyword ?? ""
    }&startIndex=${startIndex ?? ""}&endIndex=${
      endIndex ?? ""
    }&tokenType=${tokenType}`
  );
};

// export const checkCamReg = (
//   email: string,
//   userToken: string,
//   tokenType: string
// ) => {
//   return axios.get(
//     `${API_SERVER_URI}/BCS/chkCamReg.php?email=${email}&user_token=${userToken}&tokenType=${tokenType}`
//   );
// };

export const getCamInfo = (
  email: string,
  userToken: string,
  psn: string,
  tokenType: string
) => {
  return jwtAxiosInst.get(
    `/BCS/deviceInfo.php?email=${email}&user_token=${userToken}&psn=${psn}&token_type=${tokenType}`
  );
};

export const addCamera = (
  email: string,
  userToken: string,
  payload: AddCameraRequest,
  tokenType: string
) => {
  const data = qs.stringify({
    email,
    user_token: userToken,
    tokenType,
    ...payload,
    share_leu_bigdata: payload.agree_eventmap,
  });
  return jwtAxiosInst.post(`/BCS/deviceRegister.php`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

// 이제 사용하지 않는 api (Leehj)
// export const renameCamera = (
//   email: string,
//   userToken: string,
//   psn: string,
//   devName: string
// ) => {
//   return jwtAxiosInst.get(
//     `/BCS/deviceChangeName.php?email=${email}&user_token=${userToken}&psn=${psn}&dev_name=${encodeURIComponent(
//       devName
//     )}&token_type=web`
//   );
// };

// mantis - 11277, 31.4 API로 변경(Leehj)
export const renameCamera = (
  email: string,
  psn: string,
  devName: string,
  regCategory: string
) => {
  return jwtAxiosInst.put(`/DashCam/DeviceList`, {
    email,
    psn,
    dev_name: devName,
    regCategory: regCategory,
  });
};

export const deleteCamera = (email: string, userToken: string, psn: string) => {
  return jwtAxiosInst.get(
    `/BCS/deviceDelete.php?email=${email}&user_token=${userToken}&psn=${psn}&token_type=web`
  );
};

export const updatePrivacy = (
  email: string,
  userToken: string,
  psn: string,
  agree_gps: OnOffType,
  share_gps: OnOffType,
  share_audio: OnOffType,
  share_video: OnOffType,
  share_dev_name: OnOffType,
  share_leu_bigdata: OnOffType
) => {
  return jwtAxiosInst.get(
    `/BCS/openGpsSet.php?email=${email}&user_token=${userToken}&psn=${psn}&share_video=${share_video}&share_audio=${share_audio}&share_dev_name=${share_dev_name}&share_gps=${share_gps}&agree_gps=${agree_gps}&share_leu_bigdata=${share_leu_bigdata}&tokenType=web`
  );
};

export const getFotaStatus = (
  email: string,
  userToken: string,
  psn: string | undefined
) => {
  return jwtAxiosInst.get(
    `/BCS/fotaAstatusGet.php?email=${email}&user_token=${userToken}&token_type=web${
      psn ? `&psn=${psn}` : ""
    }`
  );
};

export const getNotification = (
  email: string,
  userToken: string,
  psn: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetNotificationSettings?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=${tokenType}`
  );
};

export const updateNotification = (
  email: string,
  userToken: string,
  psn: string,
  setType: "email" | "phone",
  settings: IEmailNotiSettings,
  send: OnOffType,
  tokenType: string
) => {
  return axios.post(`${API_GATEWAY_URI}/DashCam/SetNotificationSettings`, {
    email,
    user_token: userToken,
    psn,
    tokenType,
    setType,
    // ...(setType === "email"
    //   ? { emailSettings: JSON.stringify(settings) }
    //   : { phoneSettings: JSON.stringify(settings) }),
    ...(setType === "email"
      ? { emailSettings: settings }
      : { phoneSettings: settings }),
    ...(setType === "email"
      ? {
          emailSend: send,
        }
      : { phoneSend: send }),
  });
};

export const getLiveEventSettings = (
  email: string,
  userToken: string,
  psn: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetLiveEventUploadSettings?email=${email}&userToken=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const updateCNLiveEventSettings = (
  email: string,
  psn: string,
  settings: ILiveEventSettings
) => {
  return jwtAxiosInst.post(`/IoT/liveEventUploadSet`, {
    email,
    psn,
    settings,
  });
};

export const updateLiveEventSettings = (
  email: string,
  userToken: string,
  psn: string,
  setting: ILiveEventSettings
) => {
  return axios.put(`${API_GATEWAY_URI}/DashCam/SetLiveEventUploadSettings`, {
    email,
    userToken,
    tokenType: "web",
    psn,
    setting,
  });
};

export const getFwInfos = () => {
  return axios.get(`${FIRMWARE_INFO_URI}`);
};

export const getFirmwareSettings = (
  email: string,
  user_token: string,
  psn: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/CloudSetting/GetCloudHTTPFirmwareSetting?email=${email}&user_token=${user_token}&psn=${psn}&tokenType=${tokenType}`
  );
};

export const updateCNFirmwareSettings = (firmware: IFirmwareSettings2) => {
  return jwtAxiosInst.post(`/IoT/transferCS`, {
    tokenType: "web",
    ...firmware,
  });
};

export const updateFirmwareSettings = (
  firmware: IFirmwareSettings,
  user_token: string
) => {
  return axios.put(
    `${API_GATEWAY_URI}/CloudSetting/UpdateCloudHTTPFirmwareSetting`,
    {
      user_token,
      tokenType: "web",
      ...firmware,
    }
  );
};
export const getNotificationEmail = (
  email: string,
  user_token: string,
  psn: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetNotificationEmail?email=${email}&user_token=${user_token}&psn=${psn}&tokenType=${tokenType}`
  );
};

export const inviteEmail = (
  email: string,
  user_token: string,
  psn: string,
  guestEmail: string,
  companyID: string,
  tokenType: string
) => {
  return axios.post(`${API_GATEWAY_URI}/DashCam/PostNotificationEmail`, {
    email,
    user_token,
    tokenType,
    psn,
    guestEmail,
    companyID,
  });
};
export const FixMasterEmailNotification = (
  email: string,
  user_token: string,
  psn: string,
  guestEmail: string,
  mailStatus: string,
  tokenType: string
) => {
  return axios.put(`${API_GATEWAY_URI}/DashCam/PutNotificationEmail`, {
    email,
    user_token,
    tokenType,
    psn,
    guestEmail,
    mailStatus,
  });
};

export const deleteEmail = (
  email: string,
  user_token: string,
  psn: string,
  guestEmail: string,
  tokenType: string
) => {
  return axios.delete(`${API_GATEWAY_URI}/DashCam/DeleteNotificationEmail`, {
    data: {
      email,
      user_token,
      tokenType,
      psn,
      guestEmail,
    },
  });
};

export const getFotaID = (
  email: string,
  user_token: string,
  payload: IFotaIDPayload
) => {
  const data = qs.stringify({
    email,
    user_token,
    token_type: "web",
    ...payload,
    fota_url: encodeURIComponent(
      Buffer.from(payload.fota_url).toString("base64")
    ),
    file_size: payload.file_size.toString(),
  });

  return jwtAxiosInst.post(`/BCS/fotaAidGet.php`, data);
};

export const getCNFotaStart = (
  email: string,
  user_token: string,
  fotaid: string,
  payload: IFotaIDPayload
) => {
  return jwtAxiosInst.post(`/IoT/devicecommand`, {
    email,
    user_token,
    psn: payload.psn,
    tokenType: "web",
    command: "FotaStart",
    fw_model: payload.fw_model,
    fw_version: payload.now_fw_version,
    fota_url: encodeURIComponent(
      Buffer.from(payload.fota_url).toString("base64")
    ),
    file_size: payload.file_size,
    fota_lang: payload.lang,
    checksum: payload.checksum,
    fotaid,
  });
};

export const getFotaStart = (
  email: string,
  user_token: string,
  lb_server_name: string,
  lb_http_port: string,
  fotaid: string,
  payload: IFotaIDPayload
) => {
  const url = getLbURI(lb_server_name, lb_http_port);

  const params = new URLSearchParams();
  params.append("email", email);
  params.append("user_token", user_token);
  params.append("tokenType", "web");
  params.append("psn", payload.psn);
  params.append("fw_model", payload.fw_model);
  params.append("fw_version", payload.now_fw_version);
  params.append("fota_url", Buffer.from(payload.fota_url).toString("base64"));
  params.append("file_size", payload.file_size.toString());
  params.append("fota_lang", payload.lang);
  params.append("checksum", payload.checksum);

  return axios.get(
    `${url}/proc/fota_start?${params.toString()}&fotaid=${fotaid}`
  );
};

export const rebootCNCamera = (
  email: string,
  user_token: string,
  psn: string
) => {
  return jwtAxiosInst.post(`/IoT/devicecommand`, {
    email,
    user_token,
    psn,
    tokenType: "web",
    command: "RebootDevice",
  });
};

export const rebootCamera = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  user_token: string,
  psn: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/device_reboot?email=${email}&psn=${psn}&user_token=${user_token}&tokenType=web`
  );
};

export const updateLiveviewUsage = (
  email: string,
  user_token: string,
  psn: string,
  usage: number,
  tokenType: string
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/PostUserInfo`, {
    email,
    user_token,
    psn,
    usage,
    tokenType,
    div: 1,
  });
};

export const loadPrivacy = (email: string, psn: string, user_token: string) => {
  return jwtAxiosInst.get(
    `/BCS/openGpsGet.php?email=${email}&user_token=${user_token}&psn=${psn}&tokenType=web`
  );
};

export const setShareingEventamp = (
  email: string,
  user_token: string,
  sharedEventMap: string
) => {
  return axios.post(`${API_GATEWAY_URI}/BigData/SetFirstSharedEventMap`, {
    email,
    userToken: user_token,
    tokenType: "web",
    sharedEventMap,
  });
};

export const setFatigueRiskAlertTime = (
  email: string,
  user_token: string,
  psn: string,
  tokenType: string,
  fatigueSend: string,
  fatigueTime: number
) => {
  return jwtAxiosInst.post(`/IoT/devicecommand`, {
    email,
    user_token,
    psn: psn,
    tokenType,
    command: "FatigueSetTime",
    param1: fatigueSend,
    param2: fatigueTime,
  });
};

export const updateTimeSync = (
  psn: string,
  time: number,
) => {
  return jwtAxiosInst.post(`/DashCam/timeSync`, {
    psn,
    time,
  });
};
