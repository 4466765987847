import React, { useEffect } from "react";
import {
  RouteProps,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeDirection, setFontFamily } from "../features/Theme/slice";
import { useQuery } from "../hooks/useQuery";

const DefaultRoute = (props: RouteProps) => {
  const dispatch = useDispatch();
  const { i18n: i18next } = useTranslation();
  const { path } = useRouteMatch();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    let queryLang = query.get("lang");
    if (queryLang) return;
    const prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
    let lang = prefLang || navigator.language.toLowerCase().substring(0, 2);
    // mantis - 8444, pathname이 billing페이지인 경우 이전에 저장되어있던 언어X, 랜딩페이지 언어로 적용
    if (location.pathname === "/billing") {
      lang = i18next.language;
    }
    if (process.env.REACT_APP_ENV !== "qa") {
      lang = "en";
    }
    document.documentElement.lang = lang;

    if (path === "/") {
      // mantis - 8444, pathname이 billing페이지인 경우 한국어,중국어,일본어,영어이외에도 번역되도록 수정
      if (location.pathname === "/billing") {
        switch (lang) {
          case "ar":
            i18next.changeLanguage("ar");
            dispatch(changeDirection("rtl"));
            dispatch(setFontFamily("Noto Naskh Arabic UI"));
            break;
          case "de":
          case "es":
          case "fi":
          case "fr":
          case "id":
          case "it":
          case "nb":
          case "nl":
          case "pl":
          case "ru":
          case "vi":
            i18next.changeLanguage(lang);
            dispatch(setFontFamily("Roboto"));
            break;
          case "th":
            i18next.changeLanguage(lang);
            dispatch(setFontFamily("Noto Sans Thai UI"));
            break;
          case "ja":
            i18next.changeLanguage(lang);
            dispatch(setFontFamily("Noto Sans JP"));
            break;
          case "ko":
            i18next.changeLanguage(lang);
            dispatch(setFontFamily("Noto Sans KR"));
            break;
          case "zh":
          case "sc":
            i18next.changeLanguage("sc");
            dispatch(setFontFamily("Noto Sans SC"));
            break;
          default:
            i18next.changeLanguage("en");
            dispatch(setFontFamily("Roboto"));
        }
      } else {
        dispatch(changeDirection("ltr"));
        switch (lang) {
          case "ko":
            i18next.changeLanguage("ko");
            dispatch(setFontFamily("Noto Sans KR"));
            break;
          case "ja":
            i18next.changeLanguage("ja");
            dispatch(setFontFamily("Noto Sans JP"));
            break;
          case "zh":
          case "sc":
            i18next.changeLanguage("sc");
            dispatch(setFontFamily("Noto Sans SC"));
            break;
          default:
            i18next.changeLanguage("en");
            dispatch(setFontFamily("Roboto"));
        }
      }
    }
  }, [dispatch, i18next, location.pathname, path, query]);

  return <Route {...props} />;
};

export default DefaultRoute;
