"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQuery = void 0;
var _react = require("react");
// A custom hook that builds on useLocation to parse

// the query string for you.
var useQuery = function useQuery() {
  var search = window.location.search;
  return (0, _react.useMemo)(function () {
    return new URLSearchParams(search);
  }, [search]);
};
exports.useQuery = useQuery;