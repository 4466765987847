import { configureStore } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore, createTransform } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storageSession from "redux-persist/lib/storage/session";

import { createBrowserHistory } from "history";

import createRootReducer from "./rootReducer";
import { watchUser } from "./User/saga";
import { USER } from "./User/slice";
import { routerMiddleware } from "connected-react-router";
import { watchCamera } from "./Camera/saga";
import { watchPayment } from "./Payment/saga";
import { watchMember } from "./Member/saga";
import { watchGPS } from "./GPS/saga";
import { watchEvent } from "./Event/saga";
import { watchVOD } from "./VOD/saga";
import { watchGroup } from "./Group/saga";
import { watchGeofence } from "./Geofence/saga";
import { watchSocialUser } from "./Social/saga";
import { watchReport } from "./Report/saga";
import { watchPushEvent } from "./PushEvent/saga";
import { watchError } from "./Error/saga";
import { watchLocationWS } from "./LocationWS/saga";
import { endBattery, watchBattery } from "./Battery/saga";

const persistConfig = {
  key: "pitta-webviewer",
  whitelist: [USER],
  storage: storageSession,
  transforms: [
    createTransform((inboundState: any, key) => {
      if (key === USER) {
        return {
          loginInfo: inboundState.loginInfo,
          email: inboundState.email,
          mobile_uuid:
            inboundState.mobile_uuid ??
            localStorage.getItem("pitta-webviewer:mobile-uuid"),
          tokenType: inboundState.tokenType ?? "web",
        };
      }
      return { ...inboundState };
    }),
  ],
};

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

function* rootSaga() {
  yield all([
    watchUser(),
    watchCamera(),
    watchPayment(),
    watchGPS(),
    watchEvent(),
    watchVOD(),
    watchMember(),
    watchGroup(),
    watchGeofence(),
    watchSocialUser(),
    watchReport(),
    watchPushEvent(),
    watchError(),
    watchLocationWS(),
    watchBattery(),
    endBattery(),
  ]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware({});

  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [routerMiddleware(history), sagaMiddleware],
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};

export type AppDispatch = ReturnType<typeof createStore>;
export default createStore;
