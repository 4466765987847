import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";

import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../CameraPlaybackScreen";
import {
  Button,
  Selector,
  Switch,
  Typography,
} from "@thingsw/pitta-design-system";
import { Card, FormHelperText, useMediaQuery } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  CAMERA,
  loadFirmwareSettings,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import clsx from "clsx";
import { RootState } from "../../features/store";
import _ from "lodash";

import { Prompt, useHistory } from "react-router-dom";
import { getFirmwareConfig } from "../../utils/Firmware";
import { DiscardChangeUXUIModal } from "../../components/uxui/DiscardChangeUXUIModal";
import {
  Webviewer,
  LightColors,
  TabNameInfo3,
  TabNameInfo4,
  TabNameInfo5,
  TrueFalsetype,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E8E8E8",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: "calc(var(--vh, 1vh) * 100 - 103px)",
    },
  },

  container: {
    padding: "24px 16px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 24,
    },
  },

  titleDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    marginLeft: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 20,
    },
  },

  cardContainer: {
    marginBottom: 8,
  },

  subTitle: {
    padding: "0px 28px 0px 20px",
    marginBottom: 8,
  },

  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    marginBottom: 7,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },

  cameraSsidTitle: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "16px 20px 15px",
  },

  boderBottomHotSpotBox: {
    padding: "16px 20px 8px",
  },

  boderNoneTwoRowBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 672,
    padding: "15px 19px 24px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "15px 35.5px 24px 36px",
      justifyContent: "space-between",
      flexDirection: "row",
    },
  },

  inputWrap: {
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "46.71%",
    },
  },

  ssidDiv: {
    marginBottom: 15,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },

  input: {
    minWidth: 256,
    paddingRight: 0,
    paddingBottom: 3,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: "max-content",
      maxWidth: 280,
    },
  },

  inputValueStyle: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1px 13px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  wiFiBandCard: {
    padding: "9px 20px 11px",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "8px 20px 11px",
    },
  },

  wifiBandText: {
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 1,
      marginBottom: 0,
    },
  },

  wifiSelectDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 267,
    },
  },

  select: {
    borderRadius: 12,
    padding: "8px 24px",
  },
  selectPaper: {
    width: 234,
  },
  seamlessPairingCard: {
    display: "flex",
    alignItems: "center",
    padding: "9px 20px 8px 24px",
    borderRadius: 12,
    marginBottom: 16,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "13px 20px 11px 24px",
      maxWidth: 671,
    },
  },

  switch: {
    marginRight: 19,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
  },

  hotSpotTitle: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },

  hotSpotText: {
    marginBottom: 9,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 8,
      marginBottom: 0,
    },
  },
  hotSpotInputDiv: {
    marginBottom: 15,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },
  LTECard: {
    display: "flex",
    alignItems: "center",
    padding: "15px 24px",
    borderRadius: 12,
    marginBottom: 7,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 8,
      maxWidth: 671,
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  padding: {
    padding: theme.spacing(0, 2, 1.375, 2),
  },
}));

export const CameraCameraConnectivitySettingScreen = (
  props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera, firmware, firmwareConfig, loading, type } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const cameraInfo = useSelector((state: RootState) =>
    _.find(
      state[CAMERA].cameraList?.deviceListInfo,
      (cam) => cam.device.psn === camera?.psn
    )
  );

  const [disabledChange, setDisabledChange] = useState(true);
  const [currentTabObj3, setCurrentTabObj3] = useState<TabNameInfo3>();
  const [currentWifiObj, setCurrentWifiObj] = useState<TabNameInfo4>();
  const [currnetCloudObj, setCurrentCloudObj] = useState<TabNameInfo5>();
  const [currentCloudInfo, setCurrentCloudInfo] = useState<TabNameInfo5>();

  const [ssidError, setSsidError] = useState(false);
  const [pwError, setPwError] = useState(false);
  // hotspot error
  //  mantis - 10195, Hotspot Setting은 INPUT 제한이 없으므로 helperText제거 (Leehj) 23.01.16
  // const [hotSpotError, setHotSpotError] = useState(false);
  // const [hotSpotPwError, sethotSpotPwError] = useState(false);

  // input focus - 포커싱되면 헬퍼텍스트를 파란색으로 변경하기 위해
  const [ssidFocus, setSsidFocus] = useState(false);
  const [pwFocus, setPwFocus] = useState(false);

  const [targetLocation, setTargetLocation] = useState<Location>();
  const [openDiscardModal, setOpenDiscardModal] = useState(false);

  const systemSettings = useMemo(() => {
    return firmwareConfig?.System;
  }, [firmwareConfig?.System]);

  const wifiSettings = useMemo(() => {
    return firmwareConfig?.Wifi;
  }, [firmwareConfig?.Wifi]);

  // mantis - 13830, Cloud 연결상태 Hotspot Setting 비활성화 처리(Hongcs) 24.05.23
  const connectedHotspot = useMemo(() => {
    if (cameraInfo?.device.active === "on") {
      if (
        currentCloudInfo?.sta_ssid === cameraInfo?.device.cur_ssid &&
        cameraInfo.device.cur_ssid !== ""
      ) {
        return true;
      }      
    }
    return false;
  }, [
    currentCloudInfo,
    cameraInfo?.device.active,
    cameraInfo?.device.cur_ssid,
  ]);

  const connectText = useMemo(() => {
    return (
      <div style={{ display: "flex" }}>
        <Typography
          category="Default"
          variant="SmallBold"
          htmlColor={LightColors.primary["2"]}
          className={classes.padding}
        >
          {t("Currently connected Hotspot")}
        </Typography>
      </div>
    );
  }, [classes.padding, t]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Cloud"
      );
      if (currentTab) {
        setCurrentCloudInfo(currentTab.section_info as TabNameInfo5);
      }
    }
  }, [firmware]);

  // mantis - 12614, Wifi Band 모델 확인 방식 수정 (Hongcs) 23.10.12
  const wifiBand_MODEL = useMemo(() => {
    // mantis - 13612, DR800 GOLD 모델은 2.4GHz만 지원, Wi-Fi Band 항목 미노출
    if (camera && camera.model === "DR800 GOLD") {
      return false;
    }

    if (camera) {
      return !!getFirmwareConfig(camera)?.Wifi?.WifiBand;
    }
    return undefined;
  }, [camera]);

  // LTE 항목 노출 확인 방식 수정 23.10.12 (Hongcs) 23.10.12
  const LTE_MODEL = useMemo(() => {
    if (camera) {
      return !!getFirmwareConfig(camera)?.Wifi?.onstart_LTE;
    }
    return undefined;
  }, [camera]);

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
        // 새로고침이나 창 종료시에는 해당 모달 출력 방지
        setOpenDiscardModal(false);
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );

      const currentTabWifi = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Wifi"
      );

      const currentTabCloud = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Cloud"
      );

      if (currentTab3) {
        setCurrentTabObj3(currentTab3.section_info as TabNameInfo3);
      }
      if (currentTabWifi) {
        setCurrentWifiObj(currentTabWifi.section_info as TabNameInfo4);
      }
      if (currentTabCloud) {
        setCurrentCloudObj(currentTabCloud.section_info as TabNameInfo5);
      }
    }
  }, [firmware]);

  // 저장버튼 비활성화 체크
  useEffect(() => {
    if (firmware) {
      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );

      const currentWifiTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Wifi"
      );

      const currentTabCloud = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Cloud"
      );

      const predicts3 = _.keys(currentTab3?.section_info as TabNameInfo3).map(
        (k) => {
          const key = k as keyof TabNameInfo3;
          return (
            (currentTab3?.section_info as TabNameInfo3)[key] ===
            currentTabObj3?.[key]
          );
        }
      );

      const predictsWifi = _.keys(
        currentWifiTab?.section_info as TabNameInfo4
      ).map((k) => {
        const key = k as keyof TabNameInfo4;
        return (
          (currentWifiTab?.section_info as TabNameInfo4)[key] ===
          currentWifiObj?.[key]
        );
      });

      const predictsCloud = _.keys(
        currentTabCloud?.section_info as TabNameInfo5
      ).map((k) => {
        const key = k as keyof TabNameInfo5;
        return (
          (currentTabCloud?.section_info as TabNameInfo5)[key] ===
          currnetCloudObj?.[key]
        );
      });

      setDisabledChange(
        _.every(predictsWifi) && _.every(predicts3) && _.every(predictsCloud)
      );
    }
  }, [currentWifiObj, currentTabObj3, currnetCloudObj, firmware]);

  // WIFI Credentials 적용 통일화 작업 시나리오 적용 (Leehj)
  const checkSSID = useCallback((value: string) => {
    const ssid = value;
    if (ssid.length === 0) {
      setSsidError(false);
      setPwError(false);
      return true;
    } else if (ssid.length < 5 || ssid.trim() === "") {
      setSsidError(true);
      return false;
    } else {
      setSsidError(false);
      return true;
    }
  }, []);

  // WIFI Credentials 적용 통일화 작업 시나리오 적용 (Leehj)
  const checkPW = useCallback((value: string) => {
    const pw = value;
    if (pw.length === 0) {
      setSsidError(false);
      setPwError(false);
      return true;
    } else if (pw.length < 8 || pw.trim() === "") {
      setPwError(true);
      return false;
    } else {
      setPwError(false);
      return true;
    }
  }, []);

  const getBoolean = (TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };

  const handleUpdate = () => {
    if (
      currentTabObj3 &&
      currentWifiObj &&
      currnetCloudObj &&
      camera &&
      firmware
    ) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) =>
            c.section_name !== "Tab3" &&
            c.section_name !== "Wifi" &&
            c.section_name !== "Cloud"
        ),
      };
      updated.cloud_settings = [
        { section_info: currentTabObj3, section_name: "Tab3" },
        { section_info: currentWifiObj, section_name: "Wifi" },
        { section_info: currnetCloudObj, section_name: "Cloud" },
        ...updated.cloud_settings,
      ];
      if (
        !checkPW(currentWifiObj.ap_pw) &&
        !checkSSID(currentWifiObj.ap_ssid)
      ) {
        return;
      } else if (
        !checkPW(currentWifiObj.ap_pw) ||
        !checkSSID(currentWifiObj.ap_ssid)
      ) {
        return;
      } else {
        dispatch(updateFirmwareSettings({ firmware: updated }));
      }
    }
  };

  return (
    <div className={classes.root}>
      <Prompt
        when={!disabledChange && !targetLocation}
        message={(location: any) => {
          setTargetLocation(location);
          // 세팅화면이 아닌 페이지로 이동하는 경우에만 discard모달 출력
          if (location.pathname.indexOf("settings") === -1) {
            setOpenDiscardModal(true);
            return false;
          } else {
            return true;
          }
        }}
      />
      <div className={classes.container} style={{ marginTop: -20 }}>
        <input
          type="email"
          name="hidden"
          id="hidden"
          style={{ width: 0, height: 0, border: 0, padding: 0 }}
        />
        <input
          type="password"
          name="hidden"
          id="hidden"
          style={{ width: 0, height: 0, border: 0, padding: 0 }}
        />
        <div className={classes.titleDiv}>
          <Typography category="Default" variant="H6">
            {t("Camera connectivity")}
          </Typography>
        </div>
        <div className={classes.cardContainer} style={{ marginBottom: 16 }}>
          <div className={classes.subTitle} style={{ marginBottom: 7 }}>
            <Typography category="Default" variant="SmallBold">
              {t("Direct")}
            </Typography>
          </div>

          <Card variant="outlined" className={classes.card}>
            {wifiSettings?.["LoginCredentials"] && (
              <>
                <div className={classes.cameraSsidTitle}>
                  {/* mantis - 11151, 텍스트 굵기 피그마 디자인 적용(Leehj) */}
                  <Typography category="Default" variant="Body">
                    {/* mantis - 11255, Camera Wi-Fi credentials -> Camera SSID 문구 변경 (Leehj) */}
                    {t("Camera Wi-Fi credentials")}
                  </Typography>
                </div>
                <div className={classes.boderNoneTwoRowBox}>
                  <div className={clsx(classes.inputWrap, classes.ssidDiv)}>
                    <Input
                      label={t("Network name(SSID)")}
                      value={currentWifiObj?.ap_ssid ?? ""}
                      autoComplete="nope"
                      onChange={(e) => {
                        if (e.target.value.length <= 32) {
                          setCurrentWifiObj((c) => {
                            return c && { ...c, ap_ssid: e.target.value };
                          });
                        }
                      }}
                      className={classes.input}
                      error={ssidError}
                      onFocus={() => {
                        setSsidFocus(true);
                      }}
                      onBlur={() => {
                        setSsidFocus(false);
                      }}
                    />
                    {ssidError ? (
                      <FormHelperText
                        style={{
                          color: LightColors.secondary["11"],
                          margin: "3px 16px 0",
                        }}
                      >
                        {t("SSID must be_")}
                      </FormHelperText>
                    ) : (
                      <div className={classes.inputValueStyle}>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={
                            ssidFocus
                              ? LightColors.primary["7"]
                              : LightColors.primary["2"]
                          }
                        >
                          {t("5 to 32 characters long")}
                        </Typography>
                        {/* mantis - 11232, 운트 부분 미노출로 수정 (Leehj) */}
                        {/* <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={
                            ssidFocus
                              ? LightColors.primary["7"]
                              : LightColors.primary["2"]
                          }
                        >
                          {currentWifiObj?.ap_ssid.length ?? "0"}/32
                        </Typography> */}
                      </div>
                    )}
                  </div>

                  <div className={classes.inputWrap}>
                    <Input
                      label={t("Password")}
                      value={currentWifiObj?.ap_pw ?? ""}
                      onChange={(e) => {
                        if (e.target.value.length <= 32) {
                          setCurrentWifiObj((c) => {
                            return c && { ...c, ap_pw: e.target.value };
                          });
                        }
                      }}
                      password
                      className={classes.input}
                      error={pwError}
                      onFocus={() => {
                        setPwFocus(true);
                      }}
                      onBlur={() => {
                        setPwFocus(false);
                      }}
                    />

                    {pwError ? (
                      <FormHelperText
                        style={{
                          color: LightColors.secondary["11"],
                          margin: "3px 16px 0",
                        }}
                      >
                        {t("Password must be_")}
                      </FormHelperText>
                    ) : (
                      <div className={classes.inputValueStyle}>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={
                            pwFocus
                              ? LightColors.primary["7"]
                              : LightColors.primary["2"]
                          }
                        >
                          {t("8 to 32 characters long")}
                        </Typography>
                        {/* mantis - 11232, 운트 부분 미노출로 수정 (Leehj) */}
                        {/* <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={
                            pwFocus
                              ? LightColors.primary["7"]
                              : LightColors.primary["2"]
                          }
                        >
                          {currentWifiObj?.ap_pw.length ?? "0"}/32
                        </Typography> */}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </Card>

          {wifiBand_MODEL && (
            <Card
              variant="outlined"
              className={clsx(classes.card, classes.wiFiBandCard)}
            >
              <div className={classes.wifiBandText}>
                <Typography category="Default" variant="Body">
                  {t("Wi-Fi band")}
                </Typography>
              </div>

              <div className={classes.wifiSelectDiv}>
                <Selector
                  outlinedInputClassName={classes.select}
                  menuItemClassName={classes.selectPaper}
                  t={t}
                  keyValue={currentWifiObj?.WiFiBand ?? "0"}
                  dense
                  mobile={mobile}
                  onChange={(e) => {
                    setCurrentWifiObj((c) => {
                      return c && { ...c, WiFiBand: e.key as TrueFalsetype };
                    });
                  }}
                  items={[
                    {
                      key: "0",
                      value: "5 GHz",
                    },
                    {
                      key: "1",
                      value: "2.4 GHz",
                    },
                  ]}
                />
              </div>
            </Card>
          )}

          <Card variant="outlined" className={classes.seamlessPairingCard}>
            {systemSettings?.["Seamless"] && (
              <>
                <div className={classes.switch}>
                  <Switch
                    checked={getBoolean(currentTabObj3?.BTPair ?? "1")}
                    onChange={(e) => {
                      setCurrentTabObj3((c) => {
                        return (
                          c && { ...c, BTPair: e.target.checked ? "1" : "0" }
                        );
                      });
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography category="Default" variant="Body">
                    {t("Seamless pairing")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["3"]}
                    style={{ marginTop: mobile ? 3 : 2 }}
                  >
                    {t("When OFF, connecting_")}
                  </Typography>
                </div>
              </>
            )}
          </Card>

          <div className={classes.subTitle}>
            <Typography category="Default" variant="SmallBold">
              {t("Remote")}
            </Typography>
          </div>

          {LTE_MODEL && (
            <Card variant="outlined" className={classes.LTECard}>
              <div className={classes.switch}>
                <Switch
                  checked={getBoolean(currentWifiObj?.onstart_LTE ?? "1")}
                  onChange={(e) => {
                    setCurrentWifiObj((c) => {
                      return (
                        c && {
                          ...c,
                          onstart_LTE: e.target.checked ? "1" : "0",
                        }
                      );
                    });
                  }}
                />
              </div>
              <Typography category="Default" variant="Body">
                {t("LTE")}
              </Typography>
            </Card>
          )}

          <Card variant="outlined" className={classes.card}>
            <div className={clsx(classes.boderBottomHotSpotBox)}>
              <div className={classes.hotSpotTitle}>
                <div className={classes.hotSpotText}>
                  {/* mantis - 11151, 텍스트 굵기 피그마 디자인 적용(Leehj) */}
                  <Typography category="Default" variant="Body">
                    {t("Hotspot settings")}
                  </Typography>
                </div>
                {!wifiBand_MODEL && (
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["3"]}
                  >
                    * {t("The BlackVue dashcam_2")}
                  </Typography>
                )}
              </div>
            </div>
            {connectedHotspot === true ? connectText : ""}
            <div className={classes.boderNoneTwoRowBox}>
              <div className={clsx(classes.inputWrap, classes.hotSpotInputDiv)}>
                <Input
                  label={t("Hotspot name")}
                  value={currnetCloudObj?.sta_ssid ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length <= 32) {
                      setCurrentCloudObj((c) => {
                        return c && { ...c, sta_ssid: e.target.value };
                      });
                    }
                  }}
                  className={classes.input}
                  //  mantis - 10195, Hotspot Setting은 INPUT 제한이 없으므로 helperText제거 (Leehj) 23.01.16
                  // error={hotSpotError}
                  // onFocus={() => {
                  //   setHotSpotFocus(true);
                  // }}
                  // onBlur={() => {
                  //   setHotSpotFocus(false);
                  // }}
                  disabled={connectedHotspot}
                />
              </div>

              <div className={classes.inputWrap}>
                <Input
                  label={t("Password")}
                  value={currnetCloudObj?.sta_pw ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length <= 32) {
                      setCurrentCloudObj((c) => {
                        return c && { ...c, sta_pw: e.target.value };
                      });
                    }
                  }}
                  password
                  className={classes.input}
                  //  mantis - 10195, Hotspot Setting은 INPUT 제한이 없으므로 helperText제거 (Leehj) 23.01.16
                  // error={hotSpotPwError}
                  // onFocus={() => {
                  //   sethotSpotPwFocus(true);
                  // }}
                  // onBlur={() => {
                  //   sethotSpotPwFocus(false);
                  // }}
                  disabled={connectedHotspot}
                />
              </div>
            </div>
          </Card>
        </div>

        <Button
          color="primary"
          onClick={handleUpdate}
          disabled={disabledChange}
          loading={loading && type === updateFirmwareSettings.type}
        >
          {t("Save")}
        </Button>
      </div>

      {openDiscardModal && (
        <DiscardChangeUXUIModal
          open={openDiscardModal}
          onClose={() => {
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          // mantis - 11445, 디자인 변경되면서 버튼 기능도 변경 (Leehj)
          onClickNegative={() => {
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          onClickPositive={() => {
            handleUpdate();
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
        />
      )}
    </div>
  );
};
