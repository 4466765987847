import React from "react";
import { Modal } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface DiscardChangeUXUIModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  titleDiv: {
    padding: "24px 24px 0",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px 0",
    },
  },
  contentDiv: {
    padding: "3px 0",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "5px 0",
    },
  },
  closeIcon: {
    top: 13,
    right: 18,
  },
  btnWrap: {
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-end",
    },
  },
  btnStyle: {
    minWidth: "151.5px",
    [theme.breakpoints.up("sm")]: {
      minWidth: "139.5px",
    },
  },
}));

export const DiscardChangeUXUIModal = ({
  open,
  onClose,
  onClickNegative,
  onClickPositive,
}: DiscardChangeUXUIModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  return (
    <Modal
      btnCenterMobile={mobile}
      titleClassName={classes.titleDiv}
      contentClassName={classes.contentDiv}
      closeStyle={classes.closeIcon}
      LButtonClassName={classes.btnStyle}
      RButtonClassName={classes.btnStyle}
      actionClassName={classes.btnWrap}
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      // mantis - 11445, 문구 변경 (Leehj)
      heading={t("Do you want_")}
      close
      content={""}
      LButton={t("Cancel")}
      RButton={t("Save")}
      Secondary={false}
    />
  );
};
