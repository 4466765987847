import React, { useMemo } from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface TimeSyncMoalProps {
  open: boolean;
  onClose?: () => void;
  onClickPositive?: () => void;
  guideType: "feature" | "format";
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  modalWrap: {
    maxWidth: 520,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
  RButton: {
    borderRadius: 10,
  },
  LButton: {
    borderRadius: 10,
  },
  mainTxt: {
    marginTop: 16,
    marginBottom: 16,
  },
  headTxt: {
  },
  subTxt: {
    color: "#68686E",
    whiteSpace: "pre-line",
  },
}));

export const TimeSyncModal = ({
  open,
  onClose,
  onClickPositive,
  guideType,
  loading,
}: TimeSyncMoalProps) => {
  const classes = useStyles({ guideType });
  const { t } = useTranslation();

  const message = useMemo(() => {
    return guideType === "feature"
      ? t("A new feature in the BlackVue settings_")
      : t("You can sync the time between_")
  }, [guideType, t]);

  return (
    <Modal
      open={open}
      loading={loading}
      onClose={onClose}
      onClickPositive={onClickPositive}
      onClickNegative={onClose}      
      content={
        <>
          {guideType === "format" && (
            <div className={classes.headTxt}>
              <Typography
                category="Default"
                variant="H6"
              >
                {t("Time Sysnc")}
              </Typography>
            </div>
          )}
          <div className={classes.mainTxt}>
            <Typography
              category="Default"
              variant="Body"
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          </div>
          {guideType === "feature" && (
            <div className={classes.subTxt}>
              <Typography
                category="Default"
                variant="Body"
                dangerouslySetInnerHTML={{
                  __html: t("Feature location_"),
                }}
              />
            </div>
          )}
        </>
      }
      LButton={guideType === "feature" ? undefined : t("Cancel")}
      RButton={t("OK")}
      actionClassName={classes.modalBottom}
      contentClassName={classes.modalContent}
      className={classes.modalWrap}
      Secondary={guideType === "feature" ? false : true}
      LButtonClassName={classes.LButton}
      RButtonClassName={classes.RButton}
    />
  );
};
