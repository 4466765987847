import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";

import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../CameraPlaybackScreen";
import { RootState } from "../../features/store";
import {
  CAMERA,
  loadFirmwareSettings,
  loadLiveEventSettings,
  updateFirmwareSettings,
  updateLiveEventSettings,
} from "../../features/Camera/slice";
import { useEffect, useState } from "react";
import _ from "lodash";
import { PriorityeventPanel } from "../../components/cameras/PriorityeventPanel";
import { RecklessDrivingpanel } from "../../components/cameras/RecklessDrivingpanel";
import { LiveEventUploadPanel } from "../../components/cameras/LiveEventUploadPanel";
import { PleaseSelectModal } from "../../components/cameras/PleaseSelectModal";
import { Prompt, useHistory } from "react-router-dom";
import { DiscardChangeUXUIModal } from "../../components/uxui/DiscardChangeUXUIModal";
import {
  Webviewer,
  ILiveEventSettings,
  TabNameInfo1,
  TabNameInfo2,
  TabNameInfo3,
  OPTION_CAMERA_MODELS,
  TrueFalsetype,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E8E8E8",
    // 하단의 바탕색이 흰색으로 보여서 height: "100%" 주석처리
    // height: "100%",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  container: {
    padding: "24px 16px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 24,
    },
  },

  titleDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    marginLeft: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 20,
    },
  },

  button: {
    marginTop: 15,
  },
}));

export const CameraEventTriggersSettingScreen = (
  props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { camera, firmware, liveEventSettings, loading, type } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [
    currentLiveEvent,
    setCurrentLiveEvent,
  ] = useState<ILiveEventSettings>();
  const [currentTab1Obj, setCurrentTab1Obj] = useState<TabNameInfo1>();
  const [currentTab2Obj, setCurrentTab2Obj] = useState<TabNameInfo2>();
  const [currentTab3Obj, setCurrentTab3Obj] = useState<TabNameInfo3>();
  const [disabledChange, setDisabledChange] = useState(true);
  const [disabledSave, setDisabledSave] = useState(false);

  const [openPleaseModal, setOpenPleaseModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [targetLocation, setTargetLocation] = useState<Location>();

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
        // 새로고침이나 창 종료시에는 해당 모달 출력 방지
        setOpenDiscardModal(false);
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
      dispatch(loadLiveEventSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (liveEventSettings) {
      setCurrentLiveEvent(liveEventSettings);
    }
  }, [liveEventSettings]);

  useEffect(() => {
    if (firmware) {
      const currentTab1 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      const currentTab2 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab2"
      );
      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      if (currentTab1) {
        setCurrentTab1Obj(currentTab1.section_info as TabNameInfo1);
      }
      if (currentTab2) {
        setCurrentTab2Obj(currentTab2.section_info as TabNameInfo2);
      }
      if (currentTab3) {
        setCurrentTab3Obj(currentTab3.section_info as TabNameInfo3);
      }
    }
  }, [firmware]);

  // 저장버튼 비활성화 체크
  useEffect(() => {
    if (firmware) {
      const currentTab1 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      const currentTab2 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab2"
      );

      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      const predicts1 = _.keys(currentTab1?.section_info as TabNameInfo1).map(
        (k) => {
          const key = k as keyof TabNameInfo1;
          return (
            (currentTab1?.section_info as TabNameInfo1)[key] ===
            currentTab1Obj?.[key]
          );
        }
      );
      const predicts2 = _.keys(currentTab2?.section_info as TabNameInfo2).map(
        (k) => {
          const key = k as keyof TabNameInfo2;
          return (
            (currentTab2?.section_info as TabNameInfo2)[key] ===
            currentTab2Obj?.[key]
          );
        }
      );
      const predicts3 = _.keys(currentTab3?.section_info as TabNameInfo3).map(
        (k) => {
          const key = k as keyof TabNameInfo3;
          return (
            (currentTab3?.section_info as TabNameInfo3)[key] ===
            currentTab3Obj?.[key]
          );
        }
      );
      setDisabledChange(
        _.every(predicts1) && _.every(predicts2) && _.every(predicts3)
      );
    }
  }, [
    currentLiveEvent,
    currentTab1Obj,
    currentTab2Obj,
    currentTab3Obj,
    firmware,
    liveEventSettings,
  ]);

  const handleLiveEventChange = (
    key: keyof ILiveEventSettings,
    value: boolean
  ) => {
    // 비디오 소스 중 최소 하나는 선택해야한다.
    if (key === "Vs1Chan" && value === false) {
      if (_.includes(OPTION_CAMERA_MODELS, camera?.model)) {
        if (
          currentLiveEvent?.Vs2Chan !== "on" &&
          currentLiveEvent?.Vs3Chan !== "on"
        ) {
          return setOpenPleaseModal(true);
        }
      } else if (currentLiveEvent?.Vs2Chan !== "on") {
        return setOpenPleaseModal(true);
      }
    } else if (key === "Vs2Chan" && value === false) {
      if (_.includes(OPTION_CAMERA_MODELS, camera?.model)) {
        if (
          currentLiveEvent?.Vs1Chan !== "on" &&
          currentLiveEvent?.Vs3Chan !== "on"
        ) {
          return setOpenPleaseModal(true);
        }
      } else if (currentLiveEvent?.Vs1Chan !== "on") {
        return setOpenPleaseModal(true);
      }
    } else if (key === "Vs3Chan" && value === false) {
      if (
        currentLiveEvent?.Vs1Chan === "off" &&
        currentLiveEvent?.Vs2Chan === "off"
      ) {
        return setOpenPleaseModal(true);
      }
    }

    if (currentLiveEvent && camera) {
      //  uxui 설정4차수정 - Box제품의 경우, 설정표에따라 front, rear, optional 모두 선택가능하도록 변경(Leehj)
      dispatch(
        updateLiveEventSettings({
          camera: camera,
          setting: {
            ...currentLiveEvent,
            [key]: value ? "on" : "off",
          },
        })
      );
      //@ts-ignore
      dataLayer.push({
        event: "livesettings.save",
        ...{
          ...liveEventSettings,
          [key]: value ? "on" : "off",
        },
      });
    }
  };

  const handleUpdate = () => {
    if (
      currentTab1Obj &&
      currentTab2Obj &&
      currentTab3Obj &&
      camera &&
      firmware
    ) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) =>
            c.section_name !== "Tab1" &&
            c.section_name !== "Tab2" &&
            c.section_name !== "Tab3"
        ),
      };
      updated.cloud_settings = [
        { section_info: currentTab1Obj, section_name: "Tab1" },
        { section_info: currentTab2Obj, section_name: "Tab2" },
        { section_info: currentTab3Obj, section_name: "Tab3" },
        ...updated.cloud_settings,
      ];
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  const handleResetLiveEvent = () => {
    const isOnLiveEvent =
      currentLiveEvent?.NerOverSpeed === "on" ||
      currentLiveEvent?.NerAcceleration === "on" ||
      currentLiveEvent?.NerHarshBraking === "on" ||
      currentLiveEvent?.NerSharpTurn === "on";

    if (currentLiveEvent && camera && isOnLiveEvent) {
      dispatch(
        updateLiveEventSettings({
          camera: camera,
          setting: {
            ...currentLiveEvent,
            NerOverSpeed: "off",
            NerAcceleration: "off",
            NerHarshBraking: "off",
            NerSharpTurn: "off",
          },
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <Prompt
        when={!disabledChange && !targetLocation}
        message={(location: any) => {
          setTargetLocation(location);
          // 세팅화면이 아닌 페이지로 이동하는 경우에만 discard모달 출력
          if (location.pathname.indexOf("settings") === -1) {
            setOpenDiscardModal(true);
            return false;
          } else {
            return true;
          }
        }}
      />
      <div className={classes.container}>
        <div className={classes.titleDiv}>
          <Typography category="Default" variant="H6">
            {t("Event triggers")}
          </Typography>
        </div>

        <PriorityeventPanel
          currentLiveEvent={currentLiveEvent}
          currentTab1Obj={currentTab1Obj}
          currentTab2Obj={currentTab2Obj}
          currentTab3Obj={currentTab3Obj}
          onChangeTab1Setting={(key: keyof TabNameInfo1, val: boolean) => {
            setCurrentTab1Obj(
              (c) => ({ ...c, [key]: val ? "1" : "0" } as TabNameInfo1)
            );
          }}
          onChangeTab2Setting={(
            key: keyof TabNameInfo2,
            val: string | TrueFalsetype
          ) => {
            setCurrentTab2Obj((c) => ({ ...c, [key]: val } as TabNameInfo2));
          }}
          onChangeTab3Setting={(
            key: keyof TabNameInfo3,
            val: string | boolean
          ) => {
            if (typeof val === "boolean") {
              setCurrentTab3Obj(
                (c) => ({ ...c, [key]: val ? "1" : "0" } as TabNameInfo3)
              );
            } else if (typeof val === "string") {
              setCurrentTab3Obj((c) => ({ ...c, [key]: val } as TabNameInfo3));
            }
          }}
          // 각 항목에 대해 설정을 변경하면 바로 서버로 설정값 반영한다.
          onLiveEventUpload={handleLiveEventChange}
          // 라이브 이벤트 업로드 변경되면 api호출하는 동안 슬라이더 클릭 못하게 처리(Leehj) 23.01.16
          liveUploadLoading={loading && type === updateLiveEventSettings.type}
        />

        <RecklessDrivingpanel
          currentLiveEvent={currentLiveEvent}
          currentTab3Obj={currentTab3Obj}
          onChangeTab3Setting={(
            key: keyof TabNameInfo3,
            val: string | boolean
          ) => {
            if (typeof val === "boolean") {
              setCurrentTab3Obj(
                (c) => ({ ...c, [key]: val ? "1" : "0" } as TabNameInfo3)
              );
            } else {
              setCurrentTab3Obj((c) => ({ ...c, [key]: val } as TabNameInfo3));
            }
          }}
          // 각 항목에 대해 설정을 변경하면 바로 서버로 설정값 반영한다.
          onLiveEventUpload={handleLiveEventChange}
          // 위험운전 단위 변경 시 라이브 이벤트 설정값 초기화(OFF)
          handleResetLiveEvent={handleResetLiveEvent}
          // 라이브 이벤트 업로드 변경되면 api호출하는 동안 슬라이더 클릭 못하게 처리(Leehj) 23.01.16
          liveUploadLoading={loading && type === updateLiveEventSettings.type}
          onDisabledSave={(val: boolean) => {
            setDisabledSave(val);
          }}
        />

        <LiveEventUploadPanel
          currentLiveEvent={currentLiveEvent}
          onLiveEventUpload={handleLiveEventChange}
        />

        <Button
          className={classes.button}
          color="primary"
          disabled={disabledChange || disabledSave}
          onClick={handleUpdate}
          loading={loading && type === updateFirmwareSettings.type}
        >
          {t("Save")}
        </Button>
      </div>

      {/* pleaseModal */}
      <PleaseSelectModal
        open={openPleaseModal}
        onClose={() => setOpenPleaseModal(false)}
        onClickPositive={() => setOpenPleaseModal(false)}
      />
      {openDiscardModal && (
        <DiscardChangeUXUIModal
          open={openDiscardModal}
          onClose={() => {
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          // mantis - 11445, 디자인 변경되면서 버튼 기능도 변경 (Leehj)
          onClickNegative={() => {
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          onClickPositive={() => {
            handleUpdate();
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
        />
      )}
    </div>
  );
};
