import axios from "axios";
import {
  API_GATEWAY_URI,
  IMember,
  ListPaging,
  RoleType,
} from "@thingsw/pitta-modules";

export const inviteMember = (member: IMember) => {
  return axios.post(`${API_GATEWAY_URI}/Account/InviteMember`, {
    ...member,
    tokenType: "web",
  });
};

export const getMemberList = (
  email: string,
  user_token: string,
  tokenType: string,
  paging?: ListPaging
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserInviteMemberInfo?email=${email}&user_token=${user_token}&searchKey=${
      paging?.searchKey ?? ""
    }&startIndex=${paging?.startIndex ?? ""}&endIndex=${
      paging?.endIndex ?? ""
    }&ordering=${paging?.ordering ?? "0"}&tokenType=${tokenType}`
  );
};

export const removeMember = (
  deleteEmail: string,
  deleteEmailUserType: RoleType,
  email: string,
  user_token: string
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserInviteMemberDelete`, {
    deleteEmail,
    deleteEmailUserType,
    email,
    user_token,
    tokenType: "web",
  });
};

export const getUserRole = (email: string, userToken: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserRole?email?=${email}&user_token=${userToken}&tokenType=web`
  );
};
