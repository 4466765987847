import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  MobileMenu,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import SecurityIcon from "@material-ui/icons/Security";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import { useTranslation } from "react-i18next";
import { PrivacyPanel } from "./PrivacyPanel";
import { LiveEventSettingPanel } from "./LiveEventSettingPanel";
import { FirmwarePanel } from "./FirmwarePanel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { CAMERA, loadFirmwareConfig } from "../../features/Camera/slice";
import _ from "lodash";
import { PermissionProps } from "../../hoc/withViewerTemplate";
import {
  LightColors,
  Webviewer,
  ICameraInfo,
  MODEL_KEY,
  CAMERA_SETTING_UNSUPPORTED,
  PSN650,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  appDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,

    "&:last-child": {
      marginBottom: 0,
    },
  },
  menuIcon: {
    fontSize: 24,
    color: LightColors.primary["1"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      fontSize: 24,
      color: LightColors.primary["1"],
      padding: 3,
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(1) }
        : { marginRight: theme.spacing(1) }),
    },
  },
  anchor: {
    color: "inherit",
    "& a": {
      color: "inherit",
    },
    "&:hover": {
      cursor: "pointer",
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
    "&:active": {
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
  },
  menuHoriz: {
    margin: "0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
    },
  },
  iconMenu: {
    width: 202,
    marginTop: 24,
    ...(theme.direction === "rtl" ? { marginLeft: 32 } : { marginRight: 32 }),
  },

  Pd: {
    padding: "4px 10px 10px 6px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "8px 10px 10px 8px",
    },
  },
  mgR32: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 32,
      flexGrow: 1,
    },
  },
  mobmenu: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    margin: "19px 0 24px 0",
    // mantis - 10739, 모바일 가운데 정렬 되도록 수정 (Leehj)
    paddingBottom: 8,
  },

  check7: {
    color: LightColors.primary["7"],
  },
  Pdnone: {
    padding: 0,
    minHeight: 36,
    // mantis - 10739, 모바일 가운데 정렬 되도록 수정 (Leehj)
    // marginBottom: 8,
  },
  webMenu: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  menuActive: {
    color: LightColors.primary["7"],
    "&>svg": {
      color: LightColors.primary["7"],
    },
  },
  bgColor: {
    backgroundColor: LightColors.primary["0"],
    // mantis - 10739, 모바일 가운데 정렬 되도록 수정 (Leehj)
    display: "flex",
    alignItems: "center",
  },
  webMenu2: {
    padding: "0",
  },

  disabled: {
    color: LightColors.primary["1"],
    opacity: 0.5,
    "&:hover": {
      color: LightColors.primary["1"],
      opacity: 0.5,
      cursor: "default",
      "& svg": {
        color: LightColors.primary["1"],
        fill: LightColors.primary["1"],
        opacity: 0.5,
      },
    },
  },
}));

export const CameraSettingPanel = ({
  privacyPerm,
  pushNotificationsPerm,
  eventAutoUploadPerm,
  firmwareSettingPerm,
  shareToEventmapPerm,
}: PermissionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const anchorRef = useRef<HTMLDivElement>(null);
  const camera = useSelector((state: RootState) => state[CAMERA].camera);
  const [cameraInfo, setCameraInfo] = useState<ICameraInfo>();

  const cameraList = useSelector(
    (state: RootState) => state[CAMERA].cameraList
  );

  useEffect(() => {
    const cm = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === camera?.psn)
      .value();
    setCameraInfo((c) => {
      if (c?.psn !== cm?.psn) {
        return cm;
      } else if (c?.active !== cm?.active) {
        return cm;
      } else if (
        (!c?.latitude && cm?.latitude) ||
        (!c?.longitude && cm?.latitude)
      ) {
        return cm;
      }
      return c;
    });
  }, [camera?.psn, cameraList?.deviceListInfo]);

  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(-1);

  // 미지원 모델 Live Event Upload / Push notifications / Firmware 메뉴 disable 처리 (7103)
  const menuDisabled = useMemo(() => {
    let disable = false;
    if (cameraInfo) {
      const model = cameraInfo.model as MODEL_KEY;
      const version = CAMERA_SETTING_UNSUPPORTED[model];
      if (version) {
        disable = version >= parseFloat(cameraInfo.fw_ver);
      }
    }
    return disable || _.includes(PSN650, cameraInfo?.psn.substr(0, 4));
  }, [cameraInfo]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (cameraInfo) {
      dispatch(loadFirmwareConfig(cameraInfo));
    }
  }, [cameraInfo, dispatch]);

  useEffect(() => {
    if (privacyPerm) {
      setMenu(0);
    } else if (eventAutoUploadPerm) {
      setMenu(1);
    } else if (pushNotificationsPerm) {
      setMenu(2);
    } else if (firmwareSettingPerm) {
      setMenu(3);
    }
  }, [
    eventAutoUploadPerm,
    firmwareSettingPerm,
    privacyPerm,
    pushNotificationsPerm,
  ]);

  const newLocal = useMemo(() => {
    switch (menu) {
      case 0:
        return <SecurityIcon style={{ padding: 3, marginRight: 4 }} />;
      case 1:
        return <CloudUploadIcon style={{ padding: 3, marginRight: 4 }} />;
      case 2:
        return <NotificationsIcon style={{ padding: 3, marginRight: 4 }} />;
      default:
        return <SettingsIcon style={{ padding: 3, marginRight: 4 }} />;
    }
  }, [menu]);

  return (
    <div className={classes.menuHoriz}>
      {mobile && (
        <div ref={anchorRef} className={classes.mobmenu}>
          <WebMenuItem
            classes={{ root: clsx(classes.bgColor, classes.Pdnone) }}
            // className={classes.Pdnone}
            onClick={handleToggle}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            startIcon={newLocal}
            endIcon={<ExpandMoreIcon />}
          >
            {menu === 0 && t("Privacy")}
            {menu === 1 && t("Live event upload")}
            {/* {menu === 2 && t("Notifications")} */}
            {menu === 3 && t("Firmware")}
          </WebMenuItem>
          <MobileMenu open={open} onClose={() => setOpen(false)}>
            <WebMenuItem
              onClick={() => {
                setMenu(0);
                setOpen(false);
              }}
              classes={{ root: classes.bgColor }}
              className={clsx({
                [classes.disabled]: !privacyPerm,
              })}
              startIcon={
                <SecurityIcon
                  className={classes.menuIcon}
                  style={{ marginRight: 8 }}
                />
              }
              endIcon={
                menu === 0 && (
                  <CheckIcon
                    fontSize="small"
                    className={clsx(classes.menuIcon, classes.check7)}
                  />
                )
              }
            >
              {t("Privacy")}
            </WebMenuItem>
            <WebMenuItem
              disabled={
                (cameraInfo && cameraInfo.fw_ver === "") ||
                !eventAutoUploadPerm ||
                menuDisabled
              }
              onClick={() => {
                setMenu(1);
                setOpen(false);
              }}
              classes={{ root: classes.bgColor }}
              startIcon={
                <CloudUploadIcon
                  className={classes.menuIcon}
                  style={{ marginRight: 8 }}
                />
              }
              endIcon={
                menu === 1 && (
                  <CheckIcon
                    fontSize="small"
                    className={clsx(classes.menuIcon, classes.check7)}
                  />
                )
              }
            >
              {t("Live event upload")}
            </WebMenuItem>
            {/* <WebMenuItem
              disabled={
                (cameraInfo && cameraInfo.fw_ver === "") ||
                !pushNotificationsPerm ||
                menuDisabled
              }
              onClick={() => {
                setMenu(2);
                setOpen(false);
              }}
              classes={{ root: classes.bgColor }}
              startIcon={
                <NotificationsIcon
                  className={classes.menuIcon}
                  style={{ marginRight: 8 }}
                />
              }
              endIcon={
                menu === 2 && (
                  <CheckIcon
                    fontSize="small"
                    className={clsx(classes.menuIcon, classes.check7)}
                  />
                )
              }
            >
              {t("Notifications")}
            </WebMenuItem> */}
            <WebMenuItem
              disabled={
                (cameraInfo && cameraInfo.fw_ver === "") ||
                !firmwareSettingPerm ||
                menuDisabled
              }
              onClick={() => {
                setMenu(3);
                setOpen(false);
              }}
              classes={{ root: classes.bgColor }}
              startIcon={
                <SettingsIcon
                  className={classes.menuIcon}
                  style={{ marginRight: 8 }}
                />
              }
              endIcon={
                menu === 3 && (
                  <CheckIcon
                    fontSize="small"
                    className={clsx(classes.menuIcon, classes.check7)}
                  />
                )
              }
            >
              {t("Firmware")}
            </WebMenuItem>
          </MobileMenu>
        </div>
      )}

      {!mobile && (
        <div className={classes.iconMenu}>
          <div
            className={clsx(classes.appDiv, classes.anchor, {
              [classes.menuActive]: menu === 0,
              [classes.disabled]: !privacyPerm,
            })}
            onClick={() => {
              if (privacyPerm) {
                setMenu(0);
              }
            }}
          >
            <SecurityIcon
              className={clsx(classes.menuIcon, {
                [classes.disabled]: !privacyPerm,
              })}
            />
            <Typography
              category="Default"
              variant={menu === 0 ? "BodyBold" : "Body"}
            >
              {t("Privacy")}
            </Typography>
          </div>
          <>
            <div
              className={clsx(classes.appDiv, classes.anchor, {
                [classes.menuActive]: menu === 1,
                [classes.disabled]:
                  (cameraInfo && cameraInfo.fw_ver === "") ||
                  !eventAutoUploadPerm ||
                  menuDisabled,
              })}
              onClick={() => {
                if (
                  cameraInfo &&
                  cameraInfo.fw_ver &&
                  eventAutoUploadPerm &&
                  !menuDisabled
                ) {
                  setMenu(1);
                }
              }}
            >
              <CloudUploadIcon
                className={clsx(classes.menuIcon, {
                  [classes.disabled]:
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !eventAutoUploadPerm ||
                    menuDisabled,
                })}
              />

              <Typography
                category="Default"
                variant={menu === 1 ? "BodyBold" : "Body"}
              >
                {t("Live event upload")}
              </Typography>
            </div>
            {/* uxui 설정4차수정 - 구모델의 경우 Notification 항목은 노출되면 안된다.(Leehj)  */}
            {/* {(_.includes(SIGMASTAR_MODELS, camera?.model) ||
              _.includes(NEW_MODELS, camera?.model)) && (
              <div
                className={clsx(classes.appDiv, classes.anchor, {
                  [classes.menuActive]: menu === 2,
                  [classes.disabled]:
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !pushNotificationsPerm ||
                    menuDisabled,
                })}
                onClick={() => {
                  if (
                    cameraInfo &&
                    cameraInfo.fw_ver &&
                    pushNotificationsPerm &&
                    !menuDisabled
                  ) {
                    setMenu(2);
                  }
                }}
              >
                <NotificationsIcon
                  className={clsx(classes.menuIcon, {
                    [classes.disabled]:
                      (cameraInfo && cameraInfo.fw_ver === "") ||
                      !pushNotificationsPerm ||
                      menuDisabled,
                  })}
                />
                <Typography
                  category="Default"
                  variant={menu === 2 ? "BodyBold" : "Body"}
                >
                  {t("Notifications")}
                </Typography>
              </div>
            )} */}
            <div
              className={clsx(classes.appDiv, classes.anchor, {
                [classes.menuActive]: menu === 3,
                [classes.disabled]:
                  (cameraInfo && cameraInfo.fw_ver === "") ||
                  !firmwareSettingPerm ||
                  menuDisabled,
              })}
              onClick={() => {
                if (
                  cameraInfo &&
                  cameraInfo.fw_ver &&
                  firmwareSettingPerm &&
                  !menuDisabled
                ) {
                  setMenu(3);
                }
              }}
            >
              <SettingsIcon
                className={clsx(classes.menuIcon, {
                  [classes.disabled]:
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !firmwareSettingPerm ||
                    menuDisabled,
                })}
              />
              <Typography
                category="Default"
                variant={menu === 3 ? "BodyBold" : "Body"}
              >
                {t("Firmware")}
              </Typography>
            </div>
          </>
        </div>
      )}
      <div className={classes.mgR32}>
        {menu === 0 && (
          <PrivacyPanel shareToEventmapPerm={shareToEventmapPerm} />
        )}
        {menu === 1 && <LiveEventSettingPanel />}
        {/* uxui 설정4차수정 - 구모델의 경우 Notification 항목은 노출되면 안된다.(Leehj)  */}
        {/* {menu === 2 &&
          (_.includes(SIGMASTAR_MODELS, camera?.model) ||
            _.includes(NEW_MODELS, camera?.model)) && (
            <NotificationSettingPanel />
          )} */}
        {menu === 3 && <FirmwarePanel />}
      </div>
    </div>
  );
};
