import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import {
  Button,
  CheckBox,
  IconButton,
  Typography,
} from "@thingsw/pitta-design-system";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/Accordion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUserPermissions,
  MEMBER,
  updateUserPermissions,
} from "../features/Member/slice";
import { RootState } from "../features/store";
import _ from "lodash";
import {
  loadUserPermissions as loadMyPermission,
  USER,
} from "../features/User/slice";
import { Webviewer, LightColors, IPermissions } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  filterDiv: {
    // width: 469,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 469,
    },
  },
  filterTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 56,
  },
  filterListDiv: {
    flex: 1,
  },
  accordionSummary: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  accordionSummary2: {
    padding: theme.spacing(2, 3, 2, 3),
  },
  accordionDetail: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0, 3),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  expandIcon: {
    padding: 0,
  },
  cameraCheckBoxLabel: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(-0.75) }
      : { marginLeft: theme.spacing(-0.75) }),
    paddingBottom: theme.spacing(2),
    alignItems: "start",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
    },
  },
  filterBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(2),
  },
  cancelBtn: {
    ...(theme.direction === "rtl" ? { marginLeft: 16 } : { marginRight: 16 }),
  },
  noBorder: {
    borderBottom: 0,
  },
  checkbox: {
    padding: theme.spacing(0, 0.375),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0.375),
    },
  },
}));

export interface PermissionDrawerProps {
  email: string;
  openDrawer: boolean;
  onClose: () => void;
}

export const PermissionDrawer = ({
  email,
  openDrawer,
  onClose,
}: PermissionDrawerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;

  const { permissions: permOrig, loading } = useSelector(
    (state: RootState) => state[MEMBER]
  );
  const { email: userEmail, permissions: myPerm, userProfile } = useSelector(
    (state: RootState) => state[USER]
  );

  const [openFilePanel, setOpenFilePanel] = useState(true);
  const [openFeaturePanel, setOpenFeaturePanel] = useState(true);
  const [openSettingsPanel, setOpenSettingsPanel] = useState(true);
  const [permissions, setPermissions] = useState<IPermissions>();
  const [readonly, setReadonly] = useState(true);

  useEffect(() => {
    if (userProfile?.userType === "Master") {
      setReadonly(false);
    }
  }, [userProfile?.userType]);

  useEffect(() => {
    if (email) {
      if (email === userEmail) {
        dispatch(loadMyPermission());
      } else {
        dispatch(loadUserPermissions(email));
      }
    }
  }, [dispatch, email, userEmail]);

  useEffect(() => {
    if (email === userEmail) {
      if (myPerm) {
        setPermissions(myPerm);
      }
    } else {
      if (permOrig) {
        setPermissions(permOrig);
      }
    }
  }, [email, myPerm, permOrig, userEmail]);

  return permissions ? (
    <Drawer anchor="right" open={openDrawer}>
      <div className={classes.filterDiv} dir={theme.direction}>
        <div className={classes.filterTitleDiv}>
          <Typography category="Default" variant="H6">
            {t("Permissions")}
          </Typography>
          <IconButton onClick={() => onClose()} style={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.filterListDiv}>
          <Accordion
            elevation={0}
            expanded={openFilePanel}
            onChange={() => setOpenFilePanel((o) => !o)}
          >
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div>
                <Typography category="Default" variant="BodyBold">
                  {t("Files")}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.accordionDetail}>
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      className={classes.checkbox}
                      checked={!!permissions?.cloudFilePlay}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            cloudFilePlay: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Download and playback_cloud")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={permissions.myBlackVueFilePlay}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            myBlackVueFilePlay: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Download and Playback_SD")}
                    </Typography>
                  }
                />

                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={permissions.cloudUploadManual}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            cloudUploadManual: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Upload")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.fileDelete}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            fileDelete: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Delete")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={permissions.fileMove}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            fileMove: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Move")}
                    </Typography>
                  }
                />

                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={permissions.sharingVideoToEventMap}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            sharingVideoToEventMap: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Sharing video to Event Map")}
                    </Typography>
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            expanded={openFeaturePanel}
            onChange={() => setOpenFeaturePanel((o) => !o)}
          >
            <AccordionSummary
              className={classes.accordionSummary2}
              expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div>
                <Typography category="Default" variant="BodyBold">
                  {t("Features")}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.accordionDetail}>
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={permissions.liveView >= 0}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            liveView: checked ? 0 : -1,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Live view")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.gpsTracking}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            gpsTracking: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("GPS tracking")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.geoFence}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            geoFence: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Geofence")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.driveReports}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            driveReports: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Driving Reports")}
                    </Typography>
                  }
                />
                {/* mantis - 11828, FOTA 항목 추가(Leehj) */}
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.firmwareUpdate}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            firmwareUpdate: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("FOTA")}
                    </Typography>
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            expanded={openSettingsPanel}
            onChange={() => setOpenSettingsPanel((o) => !o)}
          >
            <AccordionSummary
              className={classes.accordionSummary2}
              expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div>
                <Typography category="Default" variant="BodyBold">
                  {t("Settings")}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={clsx(classes.accordionDetail, classes.noBorder)}>
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.firmwareSettings}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            // uxui Firmware, Live Event Upload, privacy 항목 -> Camrea setting 항목
                            firmwareSettings: checked,
                            eventAutoUpload: checked,
                            privacy: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {/* mantis - 10493, 오타수정 */}
                      {t("Camera setting")}
                    </Typography>
                  }
                />

                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.pushNotifications}
                      onChange={(e, checked) => {
                        // mantis - 8501, Notification 권한변경 시 setPushNotification, setEmailNotification에도 동일하게 적용(app에도 적용위함)
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            pushNotifications: checked,
                            setPushNotification: checked,
                            setEmailNotification: checked,
                          });
                        }
                      }}
                    />
                  }
                  // uxui프로젝트 Notification->Notification setting으로 문구 변경
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Notification setting")}
                    </Typography>
                  }
                />
                {/* mantis - 11828 Camera profile setting 항목 추가(Leehj) */}
                <FormControlLabel
                  className={classes.cameraCheckBoxLabel}
                  control={
                    <CheckBox
                      color="primary"
                      disabled={readonly}
                      checked={!!permissions?.changeDashcamName}
                      onChange={(e, checked) => {
                        if (permissions) {
                          setPermissions({
                            ...permissions,
                            changeDashcamName: checked,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Camera profile setting")}
                    </Typography>
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={classes.filterBtnDiv}>
          <Button
            variant="outlined"
            color="primary"
            disabled={readonly}
            className={classes.cancelBtn}
            onClick={() => setPermissions(permOrig)}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={_.isEqual(permOrig, permissions) || readonly}
            loading={loading}
            onClick={() => {
              permissions &&
                dispatch(
                  updateUserPermissions({ roleSetEmail: email, permissions })
                );
              onClose();
            }}
          >
            {t("Update")}
          </Button>
        </div>
      </div>
    </Drawer>
  ) : (
    <div></div>
  );
};
