import { detect } from "detect-browser";
export const exceedLiveview = () => {
  const browser = detect();
  if (browser?.name === "ios-webview") {
    //@ts-ignore
    return webkit.messageHandlers.exceedLiveview.postMessage({});
  }
  if (browser?.name === "chromium-webview") {
    //@ts-ignore
    window.Webviewer?.exceedLiveview?.();
  }
};

export const sendMessageBack = (reason?: string) => {
  const browser = detect();
  if (browser?.name === "ios-webview") {
    //@ts-ignore
    return webkit.messageHandlers.back.postMessage({ reason });
  }
  if (browser?.name === "chromium-webview") {
    //@ts-ignore
    window.Webviewer?.back(JSON.stringify({ reason }));
  }
};

export const hideGNB = () => {
  console.log("Eventmap", "hideGNB");
  const browserName = detect()?.name;
  if (
    browserName === "ios-webview" &&
    //@ts-ignore
    webkit.messageHandlers.hideGNB
  ) {
    //@ts-ignore
    webkit.messageHandlers.hideGNB.postMessage({});
  }
  if (
    browserName === "chromium-webview" &&
    //@ts-ignore
    window.Webviewer?.hideGNB
  ) {
    //@ts-ignore
    window.Webviewer?.hideGNB?.();
  }
};

export const showGNB = () => {
  console.log("Eventmap", "showGNB");
  const browserName = detect()?.name;
  if (
    browserName === "ios-webview" &&
    //@ts-ignore
    webkit.messageHandlers.showGNB
  ) {
    //@ts-ignore
    webkit.messageHandlers.showGNB.postMessage({});
  }
  if (
    browserName === "chromium-webview" &&
    //@ts-ignore
    window.Webviewer?.showGNB
  ) {
    //@ts-ignore
    window.Webviewer?.showGNB?.();
  }
};
