import { DarkColors, LightColors } from "@thingsw/pitta-modules";

export const gaugeColor = (value: number, status: string) => {
  if (status === "charging") {
    return {
      first: LightColors.secondary["15"],
      second: DarkColors.secondary["15"],
    };
  }

  if (status === "disconnected") {
    return {
      first: LightColors.primary["2"],
      second: LightColors.primary["3"],
    };
  }

  if (value >= 60) {
    return {
      first: LightColors.primary["7"],
      second: LightColors.primary["8"],
    };
  }

  if (value >= 20) {
    return {
      first: LightColors.secondary["17"],
      second: DarkColors.secondary["19"],
    };
  }

  if (value <= 19) {
    return {
      first: LightColors.secondary["11"],
      second: LightColors.secondary["12"],
    };
  }

  return {
    first: LightColors.primary["5"],
    second: LightColors.primary["6"],
  };
};
