import React, { useEffect, useMemo, useRef, useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Avatars,
  Container,
  LogoFull,
  LogoOnly,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import { AppBar } from "./AppBar";
import { Toolbar } from "./Toolbar";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import { useMediaQuery } from "@material-ui/core";
import { getPlanFromServiceID } from "../utils/Service";
import { PAYMENT } from "../features/Payment/slice";
import { ProfileMenu } from "./ProfileMenu";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";
import { LightColors, UserNames, Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  grow: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  marginR13: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.625) }
      : { marginRight: theme.spacing(1.625) }),
  },
  marginL7: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(7) }
      : { marginRight: theme.spacing(7) }),
  },
  tootip: {
    display: "flex",
    flexDirection: "column",
  },
  typoDiv: {
    display: "flex",
    ...(theme.direction === "rtl"
      ? { marginRight: 56.81 }
      : { marginLeft: 56.81 }),
  },
  typo2: {
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(4) }
      : { marginLeft: theme.spacing(4) }),
  },
  typy2Check: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(3.5) }
      : { marginLeft: theme.spacing(3.5) }),
  },
  checkIcon: {
    color: LightColors.primary["7"],
    ...(theme.direction === "rtl" ? { marginRight: 3 } : { marginLeft: 3 }),
  },
}));

export const AddCameraHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user_token = useSelector(
    (state: RootState) => state[USER].loginInfo?.user_token
  );
  const { userProfile } = useSelector((state: RootState) => state[USER]);

  const [openAvatarTooltip, setOpenAvatarTooltip] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { path } = useRouteMatch();

  const profileAnchorRef = useRef<HTMLDivElement>(null);
  const [planName, setPlanName] = useState<string>();
  const [imgSrc, setImgSrc] = useState<string>();

  const subscriptionInfo = useSelector(
    (state: RootState) => state[PAYMENT].subscriptionInfo
  );

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    setImgSrc((s) => {
      if (userProfile?.profilePath) {
        return s === userProfile.profilePath ? s : userProfile.profilePath;
      }
      return s;
    });
  }, [userProfile?.lastName, userProfile?.profilePath]);

  const AvatarMarkup = useMemo(() => {
    return (
      <>
        <Tooltip
          disableTouchListener={mobile}
          placement="bottom"
          open={openAvatarTooltip && !openProfileMenu}
          onOpen={() => setOpenAvatarTooltip(true)}
          onClose={() => setOpenAvatarTooltip(false)}
          title={
            <div className={classes.tootip}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {userProfile?.firstName} {userProfile?.lastName}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {userProfile?.email}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {t(planName ?? "")}
                {planName === "Fleet plan" &&
                  userProfile?.userType &&
                  ` · ${t(UserNames[userProfile.userType])}`}
              </Typography>
            </div>
          }
        >
          <Avatars
            style={{ cursor: "pointer" }}
            ref={profileAnchorRef}
            imgSrc={imgSrc}
            // Mantis-8566
            // name={lastName} >> {userProfile?.email}로 수정함.
            name={userProfile?.email}
            onClick={() => {
              setOpenProfileMenu(true);
            }}
          />
        </Tooltip>
      </>
    );
  }, [
    classes.tootip,
    imgSrc,
    mobile,
    openAvatarTooltip,
    openProfileMenu,
    planName,
    t,
    userProfile?.email,
    userProfile?.firstName,
    userProfile?.lastName,
    userProfile?.userType,
  ]);

  const getLinkName = useMemo(() => {
    if (user_token && (path === "/signup" || path === "/login")) {
      return "/";
    } else if (user_token) {
      return "/cameras";
    } else {
      return "/";
    }
  }, [path, user_token]);

  return (
    <AppBar color="default">
      <Container>
        <Toolbar>
          {/* mobile */}
          <Link to={getLinkName} className={classes.vcenter}>
            <Hidden mdUp>
              <LogoOnly width={36} height={36} />
            </Hidden>
            <Hidden smDown>
              <LogoFull width={166} height={29} />
            </Hidden>
          </Link>
          <div className={classes.grow}>
            <div>
              <Hidden smDown>
                <div className={classes.typoDiv}>
                  <Typography
                    category="Default"
                    variant={
                      path === "/cameras/add-camera-sim" ? "BodyBold" : "Body"
                    }
                    htmlColor={
                      path === "/cameras/add-camera-sim" ||
                      path === "/cameras/choose-connection" ||
                      path === "/cameras/connect-to-cloud"
                        ? LightColors.primary["7"]
                        : LightColors.primary["1"]
                    }
                  >
                    1. {t("Add camera")}
                  </Typography>
                  {(path === "/cameras/choose-connection" ||
                    path === "/cameras/connect-to-cloud") && (
                    <CheckCircleOutlineIcon className={classes.checkIcon} />
                  )}
                  <div
                    className={clsx(classes.typo2, {
                      [classes.typy2Check]: path !== "/cameras/add-camera-sim",
                    })}
                  >
                    <Typography
                      category="Default"
                      variant={
                        path === "/cameras/choose-connection" ||
                        path === "/cameras/connect-to-cloud"
                          ? "BodyBold"
                          : "Body"
                      }
                      htmlColor={
                        path === "/cameras/choose-connection" ||
                        path === "/cameras/connect-to-cloud"
                          ? LightColors.primary["7"]
                          : LightColors.primary["1"]
                      }
                    >
                      2. {t("Connect to cloud")}
                    </Typography>
                  </div>
                </div>
              </Hidden>
            </div>
            <div>{AvatarMarkup}</div>
            {/* mantis - 8418, safari에서 hover시 아이콘 움직이는 이슈 / AvatarMarkup에서 menu분리하여 수정 */}
            <ProfileMenu
              open={openProfileMenu}
              anchorEl={profileAnchorRef.current}
              placement="bottom-end"
              onClickAway={() => setOpenProfileMenu(false)}
              onCloseMenu={() => setOpenProfileMenu(false)}
            />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
