import React, { ChangeEvent, useCallback, useState } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
  updateSyncErrors,
} from "redux-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import validator from "./validator";
import { FormControlLabel, FormHelperText } from "@material-ui/core";
import { Button, CheckBox, Typography } from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { setError, USER } from "../features/User/slice";
import { TFunction } from "i18next";
import { RootState } from "../features/store";
import { ERROR } from "../features/Error/slice";
import {
  Webviewer,
  ISignupForm,
  LightColors,
  TermsConditions,
  Privacy_Policy,
  InformationWe,
  TermsOfLocation,
} from "@thingsw/pitta-modules";

interface FieldProps {
  label?: string;
  helperText?: string;
  value?: string;
  t: TFunction;
  error?: string;
}

const schema = yup.object().shape({
  firstName: yup.string().trim().required("Enter first name"),
  lastName: yup.string().trim().required("Enter last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter_email_2")
    .required("Enter email"),
  passwd: yup
    .string()
    .required("Enter password")
    .matches(
      /(^(?=.*[a-zA-Z])(?=.*[0-9]))|(^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])|(^(?=.*[0-9])(?=.*[!@#$%^&*])))/,
      "Use 8 or more characters_"
    )
    .min(8, "Use 8 or more characters_")
    .max(32, "Use 8 or more characters_"),
  tos: yup
    .boolean()
    .oneOf([true], "Please agree to_")
    .required("Please agree to_"),
  age: yup
    .boolean()
    .oneOf([true], "You must be 14 years_")
    .required("You must be 14 years_"),
});

const renderEmailField = ({
  label,
  input,
  helperText,
  error,
  meta: { touched, invalid, error: metaError },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && (!!error || !!metaError)}
      helperText={(touched ? t(error ?? metaError) : undefined) || helperText}
      {...input}
      {...custom}
    />
  );
};
const CheckboxFormControlLabel = withStyles({
  root: {
    marginLeft: -5,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    padding: "3px 0 0 2px",
  },
})(FormControlLabel);

const useStyles = makeStyles((theme: Theme) => ({
  inputDiv: {
    marginBottom: theme.spacing(3),
  },
  pwDiv: {
    marginBottom: theme.spacing(2.375),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  formControlLabelRoot: {
    marginLeft: -5,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  formControlLabel: {
    padding: "3px 0 0 2px",
  },
  agreeDiv: {
    display: "flex",
    flexDirection: "column",
  },
  signUpBtn: {
    marginBottom: theme.spacing(2.25),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  tosDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(0.75),
    },
  },
}));

const Signup = (props: InjectedFormProps<ISignupForm, {}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, initialValues, change, touch } = props;
  const { t } = useTranslation();
  const { loading } = useSelector((state: RootState) => state[USER]);
  const { error } = useSelector((state: RootState) => state[ERROR]);

  const { i18n } = useTranslation();
  const lang =
    i18n.language ?? navigator.language.toLowerCase().substring(0, 2);
  const [CheckList, setCheckList] = useState<any>([]);

  const getLink = (links: any) => {
    let link;
    switch (lang) {
      case "ko":
        link = links.ko;
        break;
      case "jp":
        link = links.jp;
        break;
      default:
        link = links.en;
    }
    return link;
  };

  const handleSignup = () => {
    // mantis : 8419
    // 잘못된 코드 입력 후 재 진입 시 에러 초기화
    dispatch(setError(""));
    dispatch(submit("SignupForm"));
  };

  const handleAllCheck = () => {
    if (CheckList.length === 3) {
      setCheckList([]);
      change("tos", false);
      change("age", false);
      change("subscribe", false);
      dispatch(
        updateSyncErrors(
          "SignupForm",
          {
            tos: "Please agree to_",
            age: "You must be 14 years_",
          },
          undefined
        )
      );
    } else {
      setCheckList(["tos", "age", "subscribe"]);
      change("tos", true);
      change("age", true);
      change("subscribe", true);
      dispatch(
        updateSyncErrors(
          "SignupForm",
          {
            tos: undefined,
            age: undefined,
          },
          undefined
        )
      );
    }
    touch(...["tos", "age"]);
  };

  const onChangeEach = (e: ChangeEvent<HTMLInputElement>, input: any) => {
    if (e.target.checked) {
      setCheckList([...CheckList, input.name]);
      input.onChange(true);
    } else {
      setCheckList(CheckList.filter((item: any) => item !== input.name));
      input.onChange(false);
    }
    touch(...[input.name]);
  };

  const [input, setInput] = useState({
    passwd: "",
  });

  const { passwd } = input;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleCheckLength = useCallback(() => {
    const checkSym = /[!@#$%^&*]/;
    const checkLetter = /[a-zA-Z]/;
    const checkNum = /[0-9]/;
    if (passwd.length === 0) {
      return "";
    } else if (
      passwd.length >= 8 &&
      ((checkSym.test(passwd) && checkLetter.test(passwd)) ||
        (checkSym.test(passwd) && checkNum.test(passwd)) ||
        (checkLetter.test(passwd) && checkNum.test(passwd)))
    ) {
      return "success";
    } else if (passwd.length <= 7) {
      return "fail";
    } else {
      return "fail";
      // return "warning";
    }
  }, [passwd]);

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // mantis : 8419
      // 잘못된 코드 입력 후 재 진입 시 에러 초기화
      dispatch(setError(""));
      dispatch(submit("SignupForm"));
    }
  };

  const renderCheckField = ({
    label,
    input,
    meta: { touched, invalid, error },
    t,
  }: WrappedFieldProps & FieldProps) => {
    return (
      <div>
        <CheckboxFormControlLabel
          control={
            <CheckBox
              name={input.name}
              color="primary"
              value={input.value}
              onChange={(e) => {
                onChangeEach(e, input);
              }}
              checked={input.value ? true : false}
            />
          }
          label={label}
        />
        {touched && error && input.value === false && (
          <FormHelperText
            style={{ color: LightColors.secondary["11"], marginLeft: 28 }}
          >
            {touched && t(error)}
          </FormHelperText>
        )}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleEnterkey}>
      <div className={classes.inputDiv}>
        <Field
          name="firstName"
          label={t("First name")}
          component={renderEmailField}
          autoFocus
          t={t}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 30);
          }}
        />
      </div>
      <div className={classes.inputDiv}>
        <Field
          name="lastName"
          label={t("Last name")}
          component={renderEmailField}
          t={t}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 30);
          }}
        />
      </div>
      <div className={classes.inputDiv}>
        <Field
          type="email"
          name="email"
          label={t("Email")}
          component={renderEmailField}
          readOnly={initialValues.email}
          t={t}
          error={error && t(error)}
        />
      </div>
      <div className={classes.pwDiv}>
        <Field
          name="passwd"
          onChange={handleChange}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 32);
          }}
          value={passwd}
          label={t("Create a password")}
          password={true}
          createPW={false}
          mode={handleCheckLength()}
          helperText={t("Use 8 or more characters_")}
          component={renderEmailField}
          t={t}
          error={error && t(error)}
        />
      </div>

      <Button
        fullWidth
        color="primary"
        onClick={handleSignup}
        loading={loading}
        className={classes.signUpBtn}
      >
        {t("Sign up")}
      </Button>

      <div className={classes.agreeDiv}>
        <CheckboxFormControlLabel
          control={
            <CheckBox
              name="checkall"
              color="primary"
              onChange={handleAllCheck}
              checked={CheckList.length === 3 ? true : false}
            />
          }
          label={
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={LightColors.primary["1"]}
            >
              {t("I have read and agree_")}
            </Typography>
          }
        />
        <div className={classes.tosDiv}>
          <Field
            name="tos"
            value="terms"
            label={
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("I agree to the_")}{" "}
                <a
                  href={getLink(TermsConditions)}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Terms of service")}
                </a>
                ,{" "}
                <a
                  href={getLink(Privacy_Policy)}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Privacy policy")}
                </a>
                ,{" "}
                <a
                  href={getLink(InformationWe)}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Information We Collect_")}
                </a>{" "}
                {t("And")}{" "}
                <a
                  href={getLink(TermsOfLocation)}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Terms of Location-Based_")}
                </a>
                ({t("Required")})
              </Typography>
            }
            component={renderCheckField}
            t={t}
          />
        </div>

        <Field
          name="age"
          value="Age"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("14 years old_")}({t("Required")})
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />

        <Field
          name="subscribe"
          value="Subscribe"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Subscribe to newsletter")}({t("Optional")})
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />
      </div>
    </form>
  );
};

const SignupForm = reduxForm<ISignupForm, {}>({
  form: "SignupForm",
  asyncValidate: validator(schema),
  persistentSubmitErrors: true,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Signup);

export default SignupForm;
