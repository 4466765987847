import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Modal,
  PlaceIcon,
  Typography,
  UploadVideoIcon,
} from "@thingsw/pitta-design-system";
import { Webviewer, LightColors } from "@thingsw/pitta-modules";
import clsx from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  contentDiv: {
    padding: "18px 24px 24px 23px",
  },

  header: {
    padding: "10px 56px 0px 23px",
    lineHeight: "21px",
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  iconContent: {
    display: "flex",
    alignItems: "center",
    marginBottom: 18,
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  iconStyle: {
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  textStyle: {
    paddingLeft: 16,
    paddingBottom: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  counter: {
    justifyContent: "space-between",
    padding: "0px 25px 24px 23px",
  },

  LBtn: {
    padding: "7px 17px",
  },
}));

type Mode = "gps" | "eventmap";

interface AllowModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onAllow: (mode: Mode, allow: boolean) => void;
}

export const AddCameraAllowModal = ({
  open,
  loading,
  onClose,
  onAllow,
}: AllowModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [step, setStep] = useState<Mode>("gps");

  const handleAllow = useCallback(() => {
    if (step === "gps") {
      setStep("eventmap");

      onAllow("gps", true);
    } else if (step === "eventmap") {
      onAllow("eventmap", true);
    }
  }, [onAllow, step]);

  const handleDisallow = useCallback(() => {
    if (step === "gps") {
      setStep("eventmap");

      onAllow("gps", false);
    } else if (step === "eventmap") {
      onAllow("eventmap", false);
    }
  }, [onAllow, step]);

  const pageNumMarkup = useMemo(() => {
    if (step === "gps") {
      return (
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.primary["2"]}
        >
          1 of 2
        </Typography>
      );
    } else if (step === "eventmap") {
      return (
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.primary["2"]}
        >
          2 of 2
        </Typography>
      );
    }
  }, [step]);

  return (
    <Modal
      open={open}
      titleClassName={classes.header}
      heading={
        step === "gps"
          ? t("Allow the BlackVue_")
          : t("Want to contribute your_")
      }
      contentClassName={classes.contentDiv}
      content={
        <div>
          <div className={clsx(classes.iconContent)}>
            <div className={classes.iconStyle}>
              {step === "gps" ? <PlaceIcon /> : <UploadVideoIcon />}
            </div>
            <div className={classes.textStyle}>
              <Typography category="Default" variant="Body">
                {step === "gps" ? t("We use this_") : t("With event map_")}
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              dangerouslySetInnerHTML={{
                __html: t(step === "gps" ? "You can edit_" : "You can edit_3", {
                  a: `<strong>BLACKVUE CLOUD > Camera settings > Privacy settings.</strong>`,
                }),
              }}
            ></Typography>
          </div>
        </div>
      }
      close
      onClose={onClose}
      btnCenter
      selectedNum={
        <Typography category="Default" variant="Body">
          {pageNumMarkup}
        </Typography>
      }
      RButton={t("Allow")}
      onClickPositive={handleAllow}
      onClickNegative={handleDisallow}
      LButton={t("Don't Allow")}
      actionClassName={classes.counter}
      LButtonClassName={classes.LBtn}
      loading={loading}
    />
  );
};
