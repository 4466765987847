import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Selector,
  Switch,
  Tooltip,
  Typography,
  RadioButton,
} from "@thingsw/pitta-design-system";

import { ScreenDefaultProps } from "../../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../CameraPlaybackScreen";
import { Card, Divider, useMediaQuery, RadioGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next/";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CAMERA,
  loadFirmwareSettings,
  loadLiveEventSettings,
  updateFirmwareSettings,
  updateLiveEventSettings,
} from "../../features/Camera/slice";
import clsx from "clsx";
import { SettingSlider } from "../../components/SettingSlider";
import InfoIcon from "@material-ui/icons/Info";
import { RootState } from "../../features/store";
import _ from "lodash";
import { PleaseSelectModal } from "../../components/cameras/PleaseSelectModal";

import { Prompt, useHistory } from "react-router-dom";
import { DiscardChangeUXUIModal } from "../../components/uxui/DiscardChangeUXUIModal";
import {
  Webviewer,
  LightColors,
  TabNameInfo3,
  ILiveEventSettings,
  DMC200_MODELS,
  BOXPRO_MODELS,
  TrueFalsetype,
  OnOffType,
  DMC100_MODELS,
  DsmSensitivityUXUI,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E8E8E8",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: "calc(var(--vh, 1vh) * 100 - 103px)",
    },
  },
  container: {
    padding: "24px 16px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 24,
    },
  },

  titleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
    marginLeft: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
      marginLeft: 20,
    },
  },

  titleLeftDiv: {
    display: "flex",
    alignItems: "center",
  },

  infoIcon: {
    color: LightColors.primary["2"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },

  content: {
    padding: "0px 28px 0px 20px",
    marginBottom: 7,
  },

  oneBoxCard: {
    display: "flex",
    minHeight: 48,
    alignItems: "center",
    padding: "16px 22px 16px 24px",
    borderRadius: 12,
    marginBottom: 8,
    // filter:
    //   "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.05))",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px 16px 24px",
      maxWidth: 671,
    },
  },

  parkingModeBox: {
    padding: "8px 16px 8px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px 16px 24px",
    },
  },

  title: {
    padding: "0 21px 8px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },

  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    marginBottom: 8,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },

  selectCard: {
    marginBottom: 7,
    overflow: "inherit",
    filter: "none",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05);",
  },

  switch: {
    marginRight: 19,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
  },

  boderBottomBox: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "12px 24px 11px",
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
  },

  boderBottomNoneBox: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "12px 24px 11px",
  },

  boderNoneTwoRowBox: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "6px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "8.5px 24px",
    },
  },

  textColume: {
    display: "flex",
    flexDirection: "column",
  },
  cardFirstDivider: {
    margin: "15px 0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },
  cardSecondDivider: {
    margin: "14px 0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },
  contentDivider: {
    margin: "0 20px",
  },

  selectDiv: {
    paddingTop: 16,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      minWidth: 267,
      padding: "0",
    },
  },

  select: {
    borderRadius: 12,
    padding: "8px 24px",
  },

  selectPaper: {
    width: 234,
  },

  selectOptionWrap: {
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    padding: "10px 18px 10px 20px",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      padding: "10px 20px",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  sensitivityDiv: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
    },
  },

  volumeCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05);",
    maxWidth: 672,
    overflow: "inherit",
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  volumeOptionWrap: {
    display: "flex",
    padding: "10px 21px 16px 21px",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "10px 23px 14px 21px",
    },
  },

  volumeInfoDiv: {
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    minHeight: 24,
    marginBottom: 18,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 4,
      marginBottom: 0,
    },
  },

  volumeText: {
    marginRight: 5,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 13,
    },
  },

  volumeSliderDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginLeft: -4,
    height: 24,
  },

  slider: {
    padding: "0 0px 0 5px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0 0px 0 8px",
    },
  },

  sliderMin: {
    marginTop: 6,
    marginRight: 10,
    justifyContent: "end",
  },

  InfoIconDiv: {
    display: "flex",
    marginRight: 8,
  },
  ul: {
    margin: "0px 0px 0px",
    padding: "0px 1px",
    "& li": {
      listStylePosition: "outside",
      listStyleType: "none",
      lineHeight: "14px",
      textIndent: "-16px",
      marginLeft: 18,
    },
  },
  button: {
    marginTop: 32,
    // 툴팁위로 버튼이 올라오는 이슈 해결위해 position적용
    position: "inherit",
  },
  disabledText: {
    opacity: 0.35,
  },
  thresholdPadding: {
    padding: "6px 19px 0px 19px",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "6px 20px 2px 20px",
      flexDirection: "row",
    },
  },
  thresholdDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "50%",
      paddingRight: 27,
    },
  },
  thresholdInfo: {
    padding: "1px 19px 2px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "1px 20px 2px 20px",
    },
  },
  thresholdSliderDiv: {
    width: "100%",
    marginRight: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "50%",
      marginRight: 0,
    },
  },
  sliderRoot: {
    padding: "16px 0",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "20px 0",
    },
  },
  sliderInnerDiv: {
    paddingLeft: 11,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingLeft: 13,
    },
  },
  sliderRail: {
    color: LightColors.primary["1"],
  },
  trackRail: {
    color: LightColors.primary["3"],
    opacity: 0.38,
  },
  imgDivider: {
    width: 303,
    height: 1,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 1,
      height: 160,
    },
  },
  optionWrap: {
    width: "100%",
    marginTop: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 8,
    },
  },
  rearOptionWrap: {
    marginTop: 31,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxHeight: 134,
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  radioBtn: {
    padding: 3,
    marginRight: 8,
    marginTop: 1,
  },
  imgWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
    "& > div": {
      flex: 1,
    },
  },
  firstImgDiv: {
    maxWidth: 200,
    marginRight: 40,
  },
  secondImgDiv: {
    maxWidth: 200,
    marginTop: 8,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
      marginLeft: 40,
    },
  },
  img: {
    marginLeft: 4,
    marginBottom: 10,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      marginBottom: 0,
    },
    "& img": {
      maxWidth: "100%",
    },
  },
}));

export const CameraDMSSettingScreen = (
  props: CameraPlaybackScreenProps & ScreenDefaultProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const history = useHistory();

  const { camera, firmware, liveEventSettings, loading, type } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const [disabledChange, setDisabledChange] = useState(true);
  const [current, setCurrent] = useState<TabNameInfo3>();
  const [
    currentLiveEvent,
    setCurrentLiveEvent,
  ] = useState<ILiveEventSettings>();
  const [openPleaseModal, setOpenPleaseModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [targetLocation, setTargetLocation] = useState<Location>();

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
        // 새로고침이나 창 종료시에는 해당 모달 출력 방지
        setOpenDiscardModal(false);
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  const dmc200_DMS = useMemo(
    () => _.includes([...DMC200_MODELS, ...BOXPRO_MODELS], camera?.model),
    [camera?.model]
  );

  const isBoxPro = useMemo(() => _.includes(BOXPRO_MODELS, camera?.model), [
    camera?.model,
  ]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
      dispatch(loadLiveEventSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );

      if (currentTab) {
        setCurrent(currentTab.section_info as TabNameInfo3);
      }
    }
  }, [firmware]);

  useEffect(() => {
    if (liveEventSettings) {
      setCurrentLiveEvent(liveEventSettings);
    }
  }, [liveEventSettings]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      const predicts = _.keys(currentTab?.section_info as TabNameInfo3).map(
        (k) => {
          const key = k as keyof TabNameInfo3;
          return (
            (currentTab?.section_info as TabNameInfo3)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [firmware, current]);

  const calcMPH = (unit: string, speed: string, defaultValue: string) => {
    if (speed === "0") {
      return defaultValue;
    }
    //mph
    if (unit === "1") {
      return speed;
    }
    // kmh
    return (parseFloat(speed) * 0.6213).toFixed(0);
  };

  const calcKMH = (unit: string, speed: string, defaultValue: string) => {
    if (speed === "0") {
      return defaultValue;
    }
    //mph
    if (unit === "1") {
      return (parseFloat(speed) * 1.6093).toFixed(0);
    }
    // kmh
    return speed;
  };

  const getBoolean = useCallback((TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  }, []);

  const getBooleanToONOFF = useCallback((TrueFalse?: boolean) => {
    return TrueFalse === true ? "on" : "off";
  }, []);

  const getNumberToONOFF = useCallback((num?: string) => {
    return num === "1" ? "on" : "off";
  }, []);

  const getBooleanONOFF = useCallback((onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  }, []);

  const handleChange = useCallback(
    (key: keyof TabNameInfo3, value: boolean | string) => {
      if (typeof value === "boolean") {
        setCurrent((c) => ({ ...c, [key]: value ? "1" : "0" } as TabNameInfo3));
      } else if (typeof value === "string") {
        setCurrent((c) => ({ ...c, [key]: value } as TabNameInfo3));
      }
    },
    []
  );

  useEffect(() => {
    if (dmc200_DMS && current) {
      current["DsmDetectBox"] === undefined &&
        handleChange("DsmDetectBox", "0");
      current["DsmUndetected"] === undefined &&
        handleChange("DsmUndetected", "1");
      current["DsmDrowsy"] === undefined && handleChange("DsmDrowsy", "1");
      current["DsmDistracted"] === undefined &&
        handleChange("DsmDistracted", "1");
      current["DsmCalling"] === undefined && handleChange("DsmCalling", "1");
      current["DsmMaskOff"] === undefined && handleChange("DsmMaskOff", "1");
      current["DsmSmoking"] === undefined && handleChange("DsmSmoking", "1");
      current["DsmParkingMode"] === undefined &&
        handleChange("DsmParkingMode", "1");
      current["DsmLed"] === undefined && handleChange("DsmLed", "1");
      current["DsmSensitivity"] === undefined &&
        handleChange("DsmSensitivity", "2");
      current["DsmVolume"] === undefined && handleChange("DsmVolume", "2");
      current["DsmRotate"] === undefined && handleChange("DsmRotate", "0");
    }
    // DMS 모델 기능 추가 (hongcs 24.03.19)
    if (_.includes(BOXPRO_MODELS, camera?.model) && current) {
      current["DsmSeatBelt"] === undefined && handleChange("DsmSeatBelt", "1");
      current["DsmDrowsySensitivity"] === undefined &&
        handleChange("DsmDrowsySensitivity", "1");
      current["DsmDistractedSensitivity"] === undefined &&
        handleChange("DsmDistractedSensitivity", "1");
      if (current["DsmDistractedLimit"] === undefined) {
        current?.EventSpeedUnit === "1"
          ? handleChange("DsmDistractedLimit", "3")
          : handleChange("DsmDistractedLimit", "5");
      }
    }
  }, [dmc200_DMS, handleChange, current, camera?.model]);

  const handleUpdate = useCallback(() => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab3"
        ),
      };
      updated.cloud_settings = [
        { section_info: current, section_name: "Tab3" },
        ...updated.cloud_settings,
      ];
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }

    // if (currentLiveEvent && camera) {
    //   dispatch(
    //     updateLiveEventSettings({ camera: camera, setting: currentLiveEvent })
    //   );
    //   //@ts-ignore
    //   dataLayer.push({
    //     event: "livesettings.save",
    //     ...currentLiveEvent,
    //   });
    // }
  }, [camera, current, dispatch, firmware]);

  // 서버로 바로 적용되도록 수정 (Leehj) 23.01.16
  const handleLiveEventChange = useCallback(
    (
      key: keyof ILiveEventSettings,
      checked: boolean,
      chageSmoking?: boolean
    ) => {
      if (currentLiveEvent && camera) {
        if (chageSmoking) {
          dispatch(
            updateLiveEventSettings({
              camera: camera,
              setting: {
                ...currentLiveEvent,
                DmsSmoking: checked ? "on" : "off",
                [key]: checked ? "on" : "off",
              },
            })
          );
          //@ts-ignore
          dataLayer.push({
            event: "livesettings.save",
            ...currentLiveEvent,
            DmsSmoking: checked ? "on" : "off",
            [key]: checked ? "on" : "off",
          });
        } else {
          dispatch(
            updateLiveEventSettings({
              camera: camera,
              setting: {
                ...currentLiveEvent,
                [key]: checked ? "on" : "off",
              },
            })
          );
          //@ts-ignore
          dataLayer.push({
            event: "livesettings.save",
            ...currentLiveEvent,
            [key]: checked ? "on" : "off",
          });
        }
      }
    },
    [camera, currentLiveEvent, dispatch]
  );

  const renderOneBoxCard = useCallback(
    (
      label: string,
      description: string,
      key: keyof TabNameInfo3,
      defaultVal: string,
      options?: { className?: string; checkFn?: () => boolean }
    ) => {
      return (
        <Card className={clsx(classes.oneBoxCard, options?.className)}>
          <div className={classes.switch}>
            <Switch
              checked={getBoolean(
                (current?.[key] ?? defaultVal) as TrueFalsetype
              )}
              onChange={(e: any) => {
                handleChange(key, e.target.checked);
              }}
            />
          </div>
          <div className={classes.textColume}>
            <Typography category="Default" variant="Body">
              {t(label)}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
            >
              {t(description)}
            </Typography>
          </div>
        </Card>
      );
    },
    [
      classes.oneBoxCard,
      classes.switch,
      classes.textColume,
      current,
      getBoolean,
      handleChange,
      t,
    ]
  );

  const renderBoxCardRow = useCallback(
    (
      label: string,
      description: string,
      key: keyof TabNameInfo3,
      defaultVal: string,
      liveKey: keyof ILiveEventSettings
    ) => {
      return (
        <>
          <div className={classes.boderBottomNoneBox}>
            <div className={classes.switch}>
              <Switch
                checked={getBoolean(
                  (current?.[key] ?? defaultVal) as TrueFalsetype
                )}
                onChange={(e: any) => {
                  handleChange(key, e.target.checked);
                  if (
                    currentLiveEvent?.[liveKey] !==
                    getBooleanToONOFF(e.target.checked)
                  )
                    handleLiveEventChange(liveKey, e.target.checked);
                }}
                disabled={
                  loading &&
                  currentLiveEvent?.[liveKey] !==
                    getNumberToONOFF(current?.[key])
                }
              />
            </div>
            <div className={classes.textColume}>
              <Typography category="Default" variant="Body">
                {t(label)}
              </Typography>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
                style={{ marginTop: 2 }}
              >
                {t(description)}
              </Typography>
            </div>
          </div>
        </>
      );
    },
    [
      current,
      currentLiveEvent,
      classes.boderBottomNoneBox,
      classes.switch,
      classes.textColume,
      getBoolean,
      getBooleanToONOFF,
      getNumberToONOFF,
      handleChange,
      handleLiveEventChange,
      t,
      loading,
    ]
  );

  const renderLiveEventRow = useCallback(
    (
      key: keyof TabNameInfo3,
      liveKey: keyof ILiveEventSettings,
      liveEventDefaultVal: string
    ) => {
      return (
        <>
          <div className={classes.switch}>
            <Switch
              checked={getBooleanONOFF(
                (currentLiveEvent?.[liveKey] ??
                  liveEventDefaultVal) as OnOffType
              )}
              onChange={async (event: any) => {
                handleLiveEventChange(liveKey, event.target.checked);
              }}
              disabled={!getBoolean(current?.[key] as TrueFalsetype)}
            />
          </div>
          <div className={classes.textColume}>
            <Typography
              category="Default"
              variant="Body"
              className={clsx(
                !getBoolean(current?.[key] as TrueFalsetype) &&
                  classes.disabledText
              )}
            >
              {t("Live event upload")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
              className={clsx(
                !getBoolean(current?.[key] as TrueFalsetype) &&
                  classes.disabledText
              )}
            >
              {t("Event file will_")}
            </Typography>
          </div>
        </>
      );
    },
    [
      current,
      currentLiveEvent,
      classes.switch,
      classes.textColume,
      classes.disabledText,
      getBoolean,
      getBooleanONOFF,
      handleLiveEventChange,
      t,
    ]
  );

  return (
    <div className={classes.root}>
      <Prompt
        when={!disabledChange && !targetLocation}
        message={(location: any) => {
          setTargetLocation(location);
          // 세팅화면이 아닌 페이지로 이동하는 경우에만 discard모달 출력
          if (location.pathname.indexOf("settings") === -1) {
            setOpenDiscardModal(true);
            return false;
          } else {
            return true;
          }
        }}
      />
      <div className={classes.container}>
        <div className={classes.titleDiv}>
          <div className={classes.titleLeftDiv}>
            <Typography category="Default" variant="H6">
              {/* mantis - 11125, Driver Monitoring System (DMS)-> DMS 로 문구 수정 (Leehj) */}
              DMS
            </Typography>
          </div>
          {!isBoxPro && (
            <div style={{ marginRight: mobile ? 21 : 40, display: "flex" }}>
              <Tooltip
                setting
                disableTouchListener={mobile}
                title={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography category="Default" variant="CaptionBold">
                      {t("DMS setting")}
                    </Typography>
                    <br />
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: `${t("Every event of DMC200_2")}`,
                      }}
                    />
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("Driver detection box")}
                    </Typography>
                    <Typography category="Default" variant="Caption">
                      {t("The DMS system has_2")}
                    </Typography>
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {/* mantis - 11125, DMS detection-> DMS Detection로 문구 수정 (Leehj) */}
                      {t("DMS detection")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: `${t("There are detection criteria for_")}`,
                      }}
                    />
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("DMS camera recording_")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: t("DMS camera recording_onoff"),
                      }}
                    />
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("LED")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: t("LED lighting for each event_"),
                      }}
                    />
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("Sensitivity")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: t("Drowsy and Distracted each have_"),
                      }}
                    />
                  </div>
                }
                placement="right"
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </div>
          )}
        </div>

        {!isBoxPro && (
          <>
            {renderOneBoxCard(
              "Driver detection box_2",
              "",
              "DsmDetectBox",
              "0"
            )}
            <Divider className={classes.cardFirstDivider} />
          </>
        )}

        <div className={classes.title}>
          <Typography category="Default" variant="SmallBold">
            {t("DMS detection")}
          </Typography>
          {!isBoxPro && (
            <div style={{ display: "flex" }}>
              <Tooltip
                setting
                disableTouchListener={mobile}
                title={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography category="Default" variant="CaptionBold">
                      {t("DMS detection")}
                    </Typography>
                    <br />
                    <Typography category="Default" variant="Caption">
                      {t("Every event of DMC200_2")}
                    </Typography>
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("Drowsiness")}
                    </Typography>
                    <Typography category="Default" variant="Caption">
                      {t("When driver’s eyes close_")}
                    </Typography>
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("Distracted")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: t("Distracted occurs_"),
                      }}
                    />
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("Undetected")}
                    </Typography>
                    <Typography category="Default" variant="Caption">
                      {t("An undetected event occurs_")}
                    </Typography>
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("Hand distraction")}
                    </Typography>
                    <Typography category="Default" variant="Caption">
                      {t("Hand distraction event occurs_")}
                    </Typography>
                    <br />
                    <Typography category="Default" variant="CaptionBold">
                      {t("Mask off")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: t("Mask off event occurs_"),
                      }}
                    />
                  </div>
                }
                placement="right"
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </div>
          )}
        </div>
        {isBoxPro && (
          <div className={classes.title}>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Every event of DMC200_2")}
            </Typography>
          </div>
        )}

        {!isBoxPro ? (
          <Card className={classes.card}>
            {renderBoxCardRow("Drowsiness", "", "DsmDrowsy", "1", "DmsDrowsy")}
            <Divider className={classes.contentDivider} />
            <div className={classes.boderNoneTwoRowBox}>
              {renderLiveEventRow("DsmDrowsy", "DmsDrowsy", "1")}
            </div>
          </Card>
        ) : (
          <Card className={classes.card}>
            {renderBoxCardRow(
              "Drowsiness",
              "Occurs when the driver closes_",
              "DsmDrowsy",
              "1",
              "DmsDrowsy"
            )}
            <Divider className={classes.contentDivider} />
            <div className={classes.textColume}>
              <div className={clsx(classes.selectOptionWrap)}>
                <div className={classes.sensitivityDiv}>
                  <Typography
                    category="Default"
                    variant="SmallBold"
                    className={clsx(
                      current?.DsmDrowsy === "0" && classes.disabledText
                    )}
                    style={{ marginRight: mobile ? 8 : 0 }}
                  >
                    {t("Sensitivity")}
                  </Typography>
                </div>
                <div className={classes.selectDiv}>
                  <Selector
                    setting
                    outlinedInputClassName={classes.select}
                    menuItemClassName={classes.selectPaper}
                    t={t}
                    dense
                    mobile={mobile}
                    keyValue={(() => {
                      if (current?.DsmDrowsySensitivity)
                        return current?.DsmDrowsySensitivity;
                      return "1";
                    })()}
                    onChange={(e) => {
                      handleChange("DsmDrowsySensitivity", e.key);
                    }}
                    items={_.map(DsmSensitivityUXUI, (val, key) => {
                      return {
                        key: key,
                        value: val,
                      };
                    })}
                    disabled={current?.DsmDrowsy === "0"}
                  />
                </div>
              </div>
              <div style={{ padding: "0 20px 10px" }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                  className={clsx(
                    current?.DsmDrowsy === "0" && classes.disabledText
                  )}
                  style={{ marginTop: 2 }}
                >
                  {t(
                    "Set by driver’s head position angle from the front and its lasting time."
                  )}
                </Typography>
              </div>
            </div>
            <Divider className={classes.contentDivider} />
            <div className={classes.boderNoneTwoRowBox}>
              {renderLiveEventRow("DsmDrowsy", "DmsDrowsy", "1")}
            </div>
          </Card>
        )}
        {!isBoxPro ? (
          <Card className={classes.card}>
            {renderBoxCardRow(
              "Distracted",
              "",
              "DsmDistracted",
              "1",
              "DmsDistracted"
            )}
            <Divider className={classes.contentDivider} />
            <div className={classes.boderNoneTwoRowBox}>
              {renderLiveEventRow("DsmDistracted", "DmsDistracted", "1")}
            </div>
          </Card>
        ) : (
          <Card className={classes.card}>
            {renderBoxCardRow(
              "Distracted",
              "Occurs when the driver's head_",
              "DsmDistracted",
              "1",
              "DmsDistracted"
            )}
            <Divider className={classes.contentDivider} />
            <div className={classes.textColume}>
              <div className={clsx(classes.selectOptionWrap)}>
                <div className={classes.sensitivityDiv}>
                  <Typography
                    category="Default"
                    variant="SmallBold"
                    className={clsx(
                      current?.DsmDistracted === "0" && classes.disabledText
                    )}
                    style={{ marginRight: mobile ? 8 : 0 }}
                  >
                    {t("Sensitivity")}
                  </Typography>
                </div>
                <div className={classes.selectDiv}>
                  <Selector
                    setting
                    outlinedInputClassName={classes.select}
                    menuItemClassName={classes.selectPaper}
                    t={t}
                    dense
                    mobile={mobile}
                    keyValue={(() => {
                      if (current?.DsmDistractedSensitivity)
                        return current?.DsmDistractedSensitivity;
                      return "1";
                    })()}
                    onChange={(e) => {
                      handleChange("DsmDistractedSensitivity", e.key);
                    }}
                    items={_.map(DsmSensitivityUXUI, (val, key) => {
                      return {
                        key: key,
                        value: val,
                      };
                    })}
                    disabled={current?.DsmDistracted === "0"}
                  />
                </div>
              </div>
              <div style={{ padding: "0 20px 10px" }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                  className={clsx(
                    current?.DsmDistracted === "0" && classes.disabledText
                  )}
                  style={{ marginTop: 2 }}
                >
                  {t(
                    "Set by driver’s head position angle from the front and its lasting time."
                  )}
                </Typography>
              </div>
            </div>
            <Divider className={classes.contentDivider} />
            <div className={classes.textColume}>
              <div
                className={clsx(
                  classes.boderBottomNoneBox,
                  classes.thresholdPadding
                )}
              >
                <div className={classes.thresholdDiv}>
                  <Typography
                    category="Default"
                    variant="SmallBold"
                    style={{ lineHeight: 1 }}
                    // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                    className={clsx(
                      current?.DsmDistracted === "0" && classes.disabledText
                    )}
                  >
                    {t("Threshold")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="SmallBold"
                    htmlColor={
                      current?.DsmDistracted !== "0"
                        ? LightColors.primary["7"]
                        : LightColors.primary["1"]
                    }
                    style={{ lineHeight: 1 }}
                    // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                    className={clsx(
                      current?.DsmDistracted === "0" && classes.disabledText
                    )}
                  >
                    {current?.EventSpeedUnit === "0"
                      ? `${calcKMH(
                          current?.EventSpeedUnit ?? "0",
                          current?.DsmDistractedLimit ?? "0",
                          "5"
                        )}km/h (${calcMPH(
                          current?.EventSpeedUnit ?? "0",
                          current?.DsmDistractedLimit ?? "0",
                          "3"
                        )}MPH)`
                      : `${calcMPH(
                          current?.EventSpeedUnit ?? "0",
                          current?.DsmDistractedLimit ?? "0",
                          "3"
                        )}MPH (${calcKMH(
                          current?.EventSpeedUnit ?? "0",
                          current?.DsmDistractedLimit ?? "0",
                          "5"
                        )}km/h)`}
                  </Typography>
                </div>
                <div className={classes.thresholdSliderDiv}>
                  <SettingSlider
                    rootClasses={classes.sliderRoot}
                    sliderClasses={classes.sliderInnerDiv}
                    railClasses={clsx(
                      current?.DsmDistractedLimit === "0" && classes.sliderRail
                    )}
                    trackClasses={clsx(
                      current?.DsmDistractedLimit === "0" && classes.trackRail
                    )}
                    min={current?.EventSpeedUnit === "1" ? 6 : 10}
                    max={current?.EventSpeedUnit === "1" ? 31 : 50}
                    value={parseInt(
                      current?.DsmDistractedLimit === undefined ||
                        current?.DsmDistractedLimit === "0"
                        ? current?.EventSpeedUnit === "1"
                          ? "3"
                          : "5"
                        : current.DsmDistractedLimit
                    )}
                    disabled={current?.DsmDistracted === "0"}
                    onChange={(e, value) => {
                      const v = value as number;
                      handleChange("DsmDistractedLimit", v.toString());
                    }}
                  />
                </div>
              </div>
              <div style={{ padding: "0 20px 10px" }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                  className={clsx(
                    current?.DsmDistracted === "0" && classes.disabledText
                  )}
                  style={{ marginTop: 2 }}
                >
                  {t("Only detects distraction above_")}
                </Typography>
              </div>
            </div>
            <Divider className={classes.contentDivider} />
            <div className={classes.boderNoneTwoRowBox}>
              {renderLiveEventRow("DsmDistracted", "DmsDistracted", "1")}
            </div>
          </Card>
        )}
        {isBoxPro && (
          <Card className={classes.card}>
            {renderBoxCardRow(
              "Seat Belt",
              "Occurs when the driver is not wearing_",
              "DsmSeatBelt",
              "1",
              "DmsSeatBelt"
            )}
            <Divider className={classes.contentDivider} />
            <div className={classes.boderNoneTwoRowBox}>
              {renderLiveEventRow("DsmSeatBelt", "DmsSeatBelt", "1")}
            </div>
          </Card>
        )}
        {!isBoxPro ? (
          <Card className={classes.card}>
            {renderBoxCardRow(
              "Undetected",
              "",
              "DsmUndetected",
              "1",
              "DmsUndetected"
            )}
            <Divider className={classes.contentDivider} />
            <div className={classes.boderNoneTwoRowBox}>
              {renderLiveEventRow("DsmUndetected", "DmsUndetected", "1")}
            </div>
          </Card>
        ) : (
          <Card className={classes.card}>
            {renderBoxCardRow(
              "Undetected",
              "Occurs when the driver is not detected in front of the camera for 1 minute.",
              "DsmUndetected",
              "1",
              "DmsUndetected"
            )}
            <Divider className={classes.contentDivider} />
            <div className={classes.boderNoneTwoRowBox}>
              {renderLiveEventRow("DsmUndetected", "DmsUndetected", "1")}
            </div>
          </Card>
        )}
        {/* Hand distraction card */}
        {!isBoxPro && (
          <>
            <Card variant="outlined" className={classes.card}>
              <div className={classes.boderBottomNoneBox}>
                <div className={classes.switch}>
                  <Switch
                    checked={
                      getBoolean(current?.DsmCalling ?? "1") &&
                      getBoolean(current?.DsmSmoking ?? "1")
                    }
                    onChange={(e: any) => {
                      handleChange("DsmCalling", e.target.checked);
                      handleChange("DsmSmoking", e.target.checked);
                      if (
                        currentLiveEvent?.DmsCalling === "on" &&
                        currentLiveEvent.DmsSmoking === "on"
                      ) {
                        // Live Event Upload 스위치 변경하면 DmsCalling와 DmsSmoking 한번에 변경(Leehj) 23.01.16
                        handleLiveEventChange(
                          "DmsCalling",
                          e.target.checked,
                          true
                        );
                      }
                    }}
                  />
                </div>

                <Typography category="Default" variant="Body">
                  {t("Hand distraction")}
                </Typography>
              </div>
              <Divider className={classes.contentDivider} />
              <div className={classes.boderNoneTwoRowBox}>
                <div className={classes.switch}>
                  <Switch
                    disabled={
                      !(
                        getBoolean(current?.DsmCalling ?? "1") &&
                        getBoolean(current?.DsmSmoking ?? "1")
                      )
                    }
                    checked={
                      getBooleanONOFF(currentLiveEvent?.DmsCalling) &&
                      getBooleanONOFF(currentLiveEvent?.DmsSmoking)
                    }
                    onChange={(event: any) => {
                      // Live Event Upload 스위치 변경하면 DmsCalling와 DmsSmoking 한번에 변경(Leehj) 23.01.16
                      handleLiveEventChange(
                        "DmsCalling",
                        event.target.checked,
                        true
                      );
                    }}
                  />
                </div>
                <div className={classes.textColume}>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={clsx(
                      !(
                        getBoolean(current?.DsmCalling ?? "1") &&
                        getBoolean(current?.DsmSmoking ?? "1")
                      ) && classes.disabledText
                    )}
                  >
                    {t("Live event upload")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["2"]}
                    style={{ marginTop: 2 }}
                    className={clsx(
                      !(
                        getBoolean(current?.DsmCalling ?? "1") &&
                        getBoolean(current?.DsmSmoking ?? "1")
                      ) && classes.disabledText
                    )}
                  >
                    {t("Event file will_")}
                  </Typography>
                </div>
              </div>
            </Card>
            {/* mantis - 11125, Mask Off -> Mask로 문구 수정 (Leehj) */}
            {renderOneBoxCard("Mask_2", "", "DsmMaskOff", "0")}
            <Divider className={classes.cardSecondDivider} />

            {renderOneBoxCard(
              "DMS camera recording_2",
              "",
              "DsmParkingMode",
              "1",
              {
                className: classes.parkingModeBox,
              }
            )}
            {renderOneBoxCard("LED", "", "DsmLed", "1")}

            <Card
              variant="outlined"
              className={clsx(classes.card, classes.selectCard)}
            >
              <div className={clsx(classes.selectOptionWrap)}>
                <div className={classes.sensitivityDiv}>
                  <Typography
                    category="Default"
                    variant="Body"
                    style={{ marginRight: mobile ? 8 : 0 }}
                  >
                    {t("Sensitivity")}
                  </Typography>
                  <div className={classes.InfoIconDiv}>
                    <Tooltip
                      disableTouchListener={mobile}
                      setting
                      PopperProps={{
                        disablePortal: true,
                      }}
                      widthLarge
                      title={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography category="Default" variant="CaptionBold">
                            {t("DMS event detection sensitivity")}
                          </Typography>
                          <br />

                          <Typography category="Default" variant="Caption">
                            * {t("Only drowsy and distracted include_")}
                          </Typography>
                          <br />

                          <Typography
                            category="Default"
                            variant="Caption"
                            dangerouslySetInnerHTML={{
                              __html: t("Drowsiness sensitivity is_"),
                            }}
                          />
                        </div>
                      }
                      placement="left"
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                </div>
                <div className={classes.selectDiv}>
                  <Selector
                    setting
                    outlinedInputClassName={classes.select}
                    menuItemClassName={classes.selectPaper}
                    t={t}
                    dense
                    mobile={mobile}
                    keyValue={(() => {
                      if (current?.DsmSensitivity)
                        return current?.DsmSensitivity;
                      if (
                        _.includes(
                          [...DMC200_MODELS, ...BOXPRO_MODELS],
                          camera?.model
                        )
                      )
                        return "2";
                      if (_.includes(DMC100_MODELS, camera?.model)) return "1";
                    })()}
                    onChange={(e) => {
                      handleChange("DsmSensitivity", e.key);
                    }}
                    items={_.map(DsmSensitivityUXUI, (val, key) => {
                      return {
                        key: key,
                        value: val,
                      };
                    })}
                  />
                </div>
              </div>
            </Card>

            <Card className={clsx(classes.volumeCard)}>
              <div className={clsx(classes.volumeOptionWrap)}>
                <div className={classes.volumeInfoDiv}>
                  <Typography category="Default" variant="Body">
                    {t("Volume")}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      category="Default"
                      variant="SmallBold"
                      htmlColor={LightColors.primary["7"]}
                      className={classes.volumeText}
                    >
                      {current?.DsmVolume ?? "2"}
                    </Typography>
                  </div>
                </div>

                <div className={classes.volumeSliderDiv}>
                  <SettingSlider
                    min={0}
                    max={dmc200_DMS ? 3 : 4}
                    value={parseInt(current?.DsmVolume ?? "2")}
                    onChange={(e, value) => {
                      const v = value as number;
                      handleChange("DsmVolume", v.toString());
                    }}
                    sliderClasses={classes.slider}
                    sliderMinClasses={classes.sliderMin}
                  />
                </div>
              </div>
            </Card>
          </>
        )}
        {isBoxPro && (
          <>
            <Divider className={classes.cardFirstDivider} />
            <Card
              className={clsx(classes.oneBoxCard)}
              style={{
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "space-between",
                overflow: "visible",
              }}
            >
              <div className={classes.textColume}>
                <Typography category="Default" variant="Body">
                  {t("Drive Position")}
                </Typography>
              </div>
              
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
                style={{ marginTop: 2 }}
              >{t("Please configure this setting_")}</Typography>

              <div className={classes.optionWrap}>
                <div>
                  <RadioGroup
                    className={classes.radioGroup}
                    value={current?.DsmDrivePosition ?? "2"}
                    onChange={(e) => {
                      handleChange("DsmDrivePosition", e.target.value);
                    }}
                  >
                    <div className={classes.imgWrap}>
                      <div className={classes.firstImgDiv}>
                        <RadioButton
                          className={classes.radioBtn}
                          value="2"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {t("Right-Hand Drive")}
                            </Typography>
                          }
                        />
                        <div className={classes.img}>
                          <img
                            srcSet={"/images/settings_drivePosition_right.png"}
                            alt="rightHandDrive"
                          />
                        </div>
                      </div>

                      <Divider className={classes.imgDivider} />
                      <div className={classes.secondImgDiv}>
                        <RadioButton
                          className={classes.radioBtn}
                          value="1"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {t("Left-Hand Drive")}
                            </Typography>
                          }
                        />
                        <div className={classes.img}>
                          <img
                            srcSet={"/images/settings_drivePosition_left.png"}
                            alt="leftHandDrive"
                          />
                        </div>
                      </div>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </Card>
          </>
        )}
        {isBoxPro && (
          <>
            <Divider className={classes.cardFirstDivider} />
            {renderOneBoxCard(
              "Driver detection box_2",
              "Overlays a face detection box_",
              "DsmDetectBox",
              "0"
            )}
          </>
        )}
        <Button
          className={classes.button}
          color="primary"
          onClick={handleUpdate}
          disabled={disabledChange}
          loading={loading && type === updateFirmwareSettings.type}
        >
          {t("Save")}
        </Button>
      </div>

      {/* pleaseModal */}
      <PleaseSelectModal
        open={openPleaseModal}
        onClose={() => setOpenPleaseModal(false)}
        onClickPositive={() => setOpenPleaseModal(false)}
      />
      {openDiscardModal && (
        <DiscardChangeUXUIModal
          open={openDiscardModal}
          onClose={() => {
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          // mantis - 11445, 디자인 변경되면서 버튼 기능도 변경 (Leehj)
          onClickNegative={() => {
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          onClickPositive={() => {
            handleUpdate();
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
        />
      )}
    </div>
  );
};
