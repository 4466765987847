import React, { useMemo } from "react";
import { LoginHeader } from "../components/LoginHeader";
import { MinimalFooter } from "../components/MinimalFooter";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Button, Success, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import {
  LightColors,
  Webviewer,
  FLEET_PRICE,
  FLEET_EXTRA,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(var(--vh, 1vh) * 100 - 56px)",
    marginTop: 56,
    padding: theme.spacing(0, 2),
    backgroundColor: LightColors.primary["0"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
    },
  },

  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    padding: theme.spacing(2.125, 3, 2.5),
    backgroundColor: `${LightColors.primary["6"]}73`,
    borderRadius: 4,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 438,
      padding: theme.spacing(2, 3, 2.5),
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(3, 0, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.625, 0, 0),
    },
  },

  marginB1: {
    marginBottom: theme.spacing(1),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  paddingB1: {
    paddingBottom: theme.spacing(1),
  },
  paddingB2: {
    paddingBottom: theme.spacing(2),
  },
  paddingT1: {
    paddingTop: theme.spacing(1),
  },
  paddingT2: {
    paddingTop: theme.spacing(2),
  },
  btnDiv: {
    display: "flex",
    marginTop: theme.spacing(4),
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 438,
      flexDirection: "row",
    },
  },
  btn: {
    flex: 1,
  },
  btnSpace: {
    width: theme.spacing(2),
    height: theme.spacing(1),
  },
  successText: {
    marginBottom: 32,
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      textAlign: "inherit",
      marginBottom: 34,
    },
  },
  subscriptionText: {
    paddingBottom: theme.spacing(1.5),
  },
  fleetPlanText: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: theme.spacing(3) - 2,
    },
  },
}));

export const SubscriptionSuccessScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const location = useLocation<{ cnt: number }>();
  const history = useHistory();

  const totalPrice = useMemo(() => {
    if (location.state?.cnt) {
      return (FLEET_PRICE + (location.state.cnt - 1) * FLEET_EXTRA).toFixed(2);
    }
    return 0;
  }, [location]);

  return (
    <div>
      <LoginHeader />
      <div className={classes.root}>
        <div className={classes.content}>
          <Success
            style={{
              width: 108,
              height: 108,
              fill: "transparent",
              marginBottom: 16,
            }}
          />
          <Typography
            category="Default"
            variant="H1"
            className={classes.successText}
          >
            {t("Successfully subscribed!")}
          </Typography>

          <div className={classes.fleetPlan}>
            <Typography
              category="Default"
              variant="H6"
              htmlColor={LightColors.primary["1"]}
              className={classes.fleetPlanText}
              // className={clsx(classes.marginB1, mobile && classes.marginB3)}
            >
              {t("Fleet plan")}
            </Typography>

            <div>
              <div
                className={clsx(classes.flexBetween, classes.subscriptionText)}
              >
                <Typography category="Default" variant="Body">
                  {t("Subscription")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ textAlign: "right" }}
                >
                  {FLEET_PRICE} USD/mo
                </Typography>
              </div>
              <div
                className={classes.flexBetween}
                style={{ paddingBottom: mobile ? 14 : 15 }}
              >
                <Typography category="Default" variant="Body">
                  {t("Extra cameras")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ textAlign: "right" }}
                >
                  {/* {((camCnt - 1) * FLEET_EXTRA).toFixed(2)} USD/mo */}
                  {(location.state?.cnt ?? 1) - 1} × {FLEET_EXTRA.toFixed(2)}{" "}
                  USD/mo
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  borderBottom: `1px solid ${LightColors.primary["5"]}`,
                }}
              ></div>
              <div className={clsx(classes.flexBetween, classes.paddingT2)}>
                <Typography category="Default" variant="BodyBold">
                  {t("Total")}
                </Typography>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ textAlign: "right" }}
                >
                  {totalPrice} USD/mo
                </Typography>
              </div>
            </div>
          </div>

          <div className={classes.btnDiv}>
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              onClick={() => history.push("/cameras")}
            >
              {t("OK")}
            </Button>
            <div className={classes.btnSpace}></div>
            <Button
              className={classes.btn}
              variant="outlined"
              color="primary"
              onClick={() => history.push("/account/subscription")}
            >
              {t("Go to Subscription")}
            </Button>
          </div>
        </div>
        <MinimalFooter />
      </div>
    </div>
  );
};
