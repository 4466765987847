import { PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  put,
  takeLatest,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";

import {
  changeBatterySetting,
  failLoad,
  loadBatteryHistory,
  loadBatterySetting,
  successLoadBatteryHistory,
  successLoadBatterySetting,
  loadBatteryManual,
  successloadBatteryManual,
  successBatteryManualEnd,
  successChangeBatterySetting,
  successBatteryManualStart,
  BatteryManualFail,
  BatteryPowerOff,
  batteryNotStarted,
  batteryManualEnd,
} from "./slice";
import * as Api from "../../apis";
import {
  BatteryFailPart,
  BatteryHistoryItem,
  BatteryManualCommand,
  BatteryManualStatus,
  BatteryModel,
  BatterySettings,
  ICameraInfo,
  RESULT_CODE,
} from "@thingsw/pitta-modules";
import { setError } from "../Error/slice";

function* handleLoadBatteryHistory({
  payload,
}: PayloadAction<{
  camera: ICameraInfo;
  period: 1 | 7;
}>) {
  try {
    const res = yield call(
      Api.getBatteryHistory,
      payload.camera.psn,
      payload.camera.battery.ssid,
      payload.period
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: BatteryHistoryItem[];
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadBatteryHistory({ items: response, period: payload.period })
      );
    }
  } catch (err) {
    yield put(setError(err.message));
    yield put(
      failLoad(
        payload.period === 1
          ? BatteryFailPart.DAYHISTORY
          : BatteryFailPart.WEEKHISTORY
      )
    );
  }
}

function* handleBatteryManual({
  payload,
}: PayloadAction<{
  camera:
    | ICameraInfo
    | {
        psn: string;
        battery: {
          ssid: string;
        };
      };
  command: BatteryManualCommand;
}>) {
  try {
    const res = yield call(Api.batteryManual, {
      command: payload.command,
      psn: payload.camera.psn,
      ssid: payload.camera.battery.ssid,
    });

    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response?: {
        status?: BatteryManualStatus;
        battery?: {
          psn: string;
          ssid: string;
          value: string;
          model: BatteryModel;
          version: string;
          date: string;
        };
      };
    };

    if (resultcode === "BC_ERR_OK") {
      if (response?.status === BatteryManualStatus.NOT_STARTED) {
        yield put(batteryNotStarted());
        return;
      }

      if (response?.status === "fail") {
        yield put(failLoad(BatteryFailPart.INFO));
      }

      if (payload.command === BatteryManualCommand.END) {
        yield put(successBatteryManualEnd());
      }

      if (payload.command === BatteryManualCommand.START) {
        yield put(successBatteryManualStart(response?.status));
      }

      if (payload.command === BatteryManualCommand.MANUAL) {
        yield put(successloadBatteryManual(response));
      }
    } else if (resultcode === "BC_ERR_TIMEOUT") {
      yield put(BatteryPowerOff());
    } else {
      if (payload.command === BatteryManualCommand.START) {
        yield put(failLoad(BatteryFailPart.INFO));
      } else {
        yield put(BatteryManualFail());
      }
    }
  } catch (err) {
    yield put(setError(err.message));
    yield put(failLoad(BatteryFailPart.INFO));
  }
}

function* handleBatteryManualEnd({ payload }: PayloadAction<ICameraInfo>) {
  try {
    const res = yield call(Api.batteryManual, {
      command: BatteryManualCommand.END,
      psn: payload.psn,
      ssid: payload.battery.ssid,
    });

    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response?: {
        status?: BatteryManualStatus;
        battery?: {
          psn: string;
          ssid: string;
          value: string;
          model: BatteryModel;
          version: string;
          date: string;
        };
      };
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successBatteryManualEnd());
    }
  } catch (err) {
    yield put(setError(err.message));
    yield put(failLoad(BatteryFailPart.INFO));
  }
}

function* handleLoadBatterySetting({
  payload,
}: PayloadAction<{ camera: ICameraInfo }>) {
  try {
    const res = yield call(
      Api.getBatterySetting,
      payload.camera.psn,
      payload.camera.battery.ssid
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: BatterySettings;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadBatterySetting(response));
    }
  } catch (err) {
    yield put(setError(err.message));
    yield put(failLoad(BatteryFailPart.SETTINGS));
  }
}

function* handleChangleBatterySetting({
  payload,
}: PayloadAction<{ camera: ICameraInfo; setting: Partial<BatterySettings> }>) {
  try {
    const res = yield call(
      Api.changeBatterySetting,
      payload.camera.psn,
      payload.camera.battery.ssid,
      payload.setting
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successChangeBatterySetting(payload.setting));
    } else {
      yield put(failLoad(BatteryFailPart.SETTINGS));
    }
  } catch (err) {
    yield put(setError(err.message));
    yield put(failLoad(BatteryFailPart.SETTINGS));
  }
}

export function* watchBattery() {
  yield takeEvery(loadBatteryHistory, handleLoadBatteryHistory);
  yield takeLatest(loadBatterySetting, handleLoadBatterySetting);
  yield takeLatest(changeBatterySetting, handleChangleBatterySetting);
  // 13369 - timeout이 10초인데, 10초마다 새로 실행시켜서 응답을 처리 못하고 새로운 generator가 실행되서 무한로딩에 빠짐
  yield takeLeading(loadBatteryManual, handleBatteryManual);
}

export function* endBattery() {
  yield takeEvery(batteryManualEnd, handleBatteryManualEnd);
}
