import { i18n } from "i18next";
import _ from "lodash";
import { Dispatch } from "redux";
import { changeDirection, setFontFamily } from "../features/Theme/slice";
import { Languages } from "@thingsw/pitta-modules";

export const changeLang = (
  i18n: i18n,
  lang: string,
  dispatch: Dispatch<any>
) => {
  if (!_.includes(_.keys(Languages), lang)) {
    lang = "en";
  }
  switch (lang) {
    case "ar":
      i18n.changeLanguage("ar");
      dispatch(changeDirection("rtl"));
      dispatch(setFontFamily("Noto Naskh Arabic UI"));
      break;
    case "de":
    case "es":
    case "fi":
    case "fr":
    case "id":
    case "it":
    case "nb":
    case "nl":
    case "pl":
    case "ru":
    case "vi":
      i18n.changeLanguage(lang);
      dispatch(setFontFamily("Roboto"));
      break;
    case "th":
      i18n.changeLanguage(lang);
      dispatch(setFontFamily("Noto Sans Thai UI"));
      break;
    case "ja":
      i18n.changeLanguage(lang);
      dispatch(setFontFamily("Noto Sans JP"));
      break;
    case "ko":
      i18n.changeLanguage(lang);
      dispatch(setFontFamily("Noto Sans KR"));
      break;
    case "zh":
    case "sc":
      i18n.changeLanguage("sc");
      dispatch(setFontFamily("Noto Sans SC"));
      break;
    default:
      i18n.changeLanguage("en");
      dispatch(setFontFamily("Roboto"));
  }
};
