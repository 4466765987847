import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Divider,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Switch, Typography, Tooltip } from "@thingsw/pitta-design-system";

import { CAMERA } from "../../features/Camera/slice";
import { SettingSlider } from "../SettingSlider";
import clsx from "clsx";
import InfoIcon from "@material-ui/icons/Info";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import _ from "lodash";
import {
  Webviewer,
  LightColors,
  ILiveEventSettings,
  TabNameInfo1,
  TabNameInfo2,
  TabNameInfo3,
  TrueFalsetype,
  OnOffType,
  OPTION_CAMERA_MODELS,
  BOXPRO_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: "0 20px 7px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    maxWidth: 673,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  switch: {
    marginRight: 19,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
  },

  protectSwitch: {
    margin: "13px 20px 0 0",
    display: "flex",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "13px 12px 0 0",
      width: 48,
    },
  },

  boderBottomBox: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "12px 24px 11px",
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
  },

  boderBottomNoneBox: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "12px 24px",
  },

  boderNoneTwoRowBox: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    alignItems: "center",
    padding: "8px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "4.5px 24px",
    },
  },

  impactPadding: {
    padding: "16px 24px 4px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "4.5px 24px",
    },
  },

  detectParkingPadding: {
    padding: "16px 24px 25px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "12px 24px",
    },
  },

  marginBottom12: {
    marginBottom: 12,
  },

  textColume: {
    display: "flex",
    flexDirection: "column",
  },

  cardDivider: {
    margin: "15px 0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },

  contentDivider: {
    margin: "0 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "3px 20px 12px",
    },
  },

  sensitivityDiv: {
    margin: "12px 20px 8px",
  },

  sliderOptionWrap: {
    display: "flex",
    padding: "10px 27px 8px 20px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      padding: "3px 25px 7px 20px",
    },
  },

  sliderSecondOptionWrap: {
    padding: "3px 23px 7px 20px",
  },

  parkingImpactPadding: {
    padding: "4px 23px 14px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "3px 23px 7px 20px",
    },
  },

  parkingImpactSidePadding: {
    padding: "4px 23px 7px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "3px 23px 7px 20px",
    },
  },

  sliderThirdOptionWrap: {
    padding: "10px 23px 7px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "3px 23px 7px 20px",
    },
  },

  sliderInfoDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "48.3%",
      marginTop: 3,
    },
  },

  sliderDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: 42,
    marginTop: 6,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
      width: 311,
    },
  },

  protectEventDiv: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 21px 14px 24px",
    marginBottom: 23,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 24px 15px",
    },
  },

  protectInfoText: {
    display: "flex",
    flexDirection: "column",
  },

  ul: {
    margin: "0px 0px 17px 17px",
    padding: "0px 1px",
    "& li": {
      listStylePosition: "outside",
      listStyleType: "none",
      textIndent: "-15px",
      lineHeight: "14px",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        lineHeight: "14px",
        textIndent: "2px",
      },
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0px 0px 16px",
    },
  },
  infoIcon: {
    color: LightColors.primary["2"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },
  disabledText: {
    opacity: 0.35,
  },
  infoIconDisabled: {
    "&:hover, &:active": {
      color: LightColors.primary["2"],
    },
  },
  sliderRail: {
    color: LightColors.primary["1"],
  },
}));

interface PriorityeventPanelProps {
  currentLiveEvent?: ILiveEventSettings;
  currentTab1Obj?: TabNameInfo1;
  currentTab2Obj?: TabNameInfo2;
  currentTab3Obj?: TabNameInfo3;
  onChangeTab1Setting: (key: keyof TabNameInfo1, val: boolean) => void;
  onChangeTab2Setting: (
    key: keyof TabNameInfo2,
    val: string | TrueFalsetype
  ) => void;
  onChangeTab3Setting: (key: keyof TabNameInfo3, val: string | boolean) => void;
  onLiveEventUpload: (key: keyof ILiveEventSettings, val: boolean) => void;
  liveUploadLoading?: boolean;
}

export const PriorityeventPanel = ({
  currentLiveEvent,
  currentTab1Obj,
  currentTab2Obj,
  currentTab3Obj,
  onChangeTab1Setting,
  onChangeTab2Setting,
  onChangeTab3Setting,
  onLiveEventUpload,
  liveUploadLoading,
}: PriorityeventPanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const [drivingImpact, setDrivingImpact] = useState<boolean>(false);
  const [parkingImpact, setParkingImpact] = useState<boolean>(false);

  const { camera } = useSelector((state: RootState) => state[CAMERA]);

  const isBoxPro = _.includes(BOXPRO_MODELS, camera?.model);

  const getBooleanOnOFF = (onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  };

  const getBoolean = (TrueFalse: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };

  // Driving impact 민감도가 다 "0"이면 Driving impact스위치 off
  useEffect(() => {
    if (
      currentTab2Obj?.NORMALSENSOR1 === "0" &&
      currentTab2Obj?.NORMALSENSOR2 === "0" &&
      currentTab2Obj?.NORMALSENSOR3 === "0"
    ) {
      setDrivingImpact(false);
    } else {
      setDrivingImpact(true);
    }
  }, [
    currentTab2Obj?.NORMALSENSOR1,
    currentTab2Obj?.NORMALSENSOR2,
    currentTab2Obj?.NORMALSENSOR3,
  ]);

  // Parking impact 민감도가 다 "0"이면 Parking impact스위치 off
  useEffect(() => {
    if (
      currentTab2Obj?.PARKINGSENSOR1 === "0" &&
      currentTab2Obj?.PARKINGSENSOR2 === "0" &&
      currentTab2Obj?.PARKINGSENSOR3 === "0"
    ) {
      setParkingImpact(false);
    } else {
      setParkingImpact(true);
    }
  }, [
    currentTab2Obj?.PARKINGSENSOR1,
    currentTab2Obj?.PARKINGSENSOR2,
    currentTab2Obj?.PARKINGSENSOR3,
  ]);

  const is800GoldSeries = useMemo(() => {
    return _.includes(["DR800 GOLD", "DR800Gold Pro"], camera?.model);
  }, [camera?.model]);

  return (
    <>
      <div className={classes.title}>
        <Typography category="Default" variant="SmallBold">
          {/* mantis - 11122, Priority event -> Priority Event로 문구 수정 (Leehj) */}
          {t("Priority event")}
        </Typography>
      </div>

      {/* Manual recording card */}
      <Card className={classes.card}>
        <div className={classes.boderBottomNoneBox}>
          <div className={classes.switch}>
            <Switch
              disabled
              checked={getBoolean(currentTab3Obj?.["PSENSOR"] as TrueFalsetype)}
            />
          </div>

          <Typography category="Default" variant="Body">
            {t("Manual recording")}
          </Typography>
        </div>

        <Divider className={classes.contentDivider} style={{ marginBottom: 0 }} />
        
        <div className={classes.boderNoneTwoRowBox}>
          <div className={classes.switch}>
            <Switch
              checked={getBooleanOnOFF(
                currentLiveEvent?.ManualRecording ?? "off"
              )}
              disabled={currentTab3Obj?.["PSENSOR"] !== "1"}
              onChange={(event) => {
                // 각 항목에 대해 설정을 변경하면 바로 서버로 설정값 반영한다.
                onLiveEventUpload("ManualRecording", event.target.checked);
              }}
            />
          </div>
          <div className={classes.textColume}>
            <Typography category="Default" variant="Body">
              {t("Live Event Upload_3")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
            >
              {/* mantis - 10685, 문구 변경(Leehj) */}
              {/* mantis - 11122, Manual Event files_ -> Event file will_로 문구 수정 (Leehj) */}
              {t("Event file will_")}
            </Typography>
          </div>
        </div>
      </Card>

      <Divider className={classes.cardDivider} />

      {/* Driving impact card */}
      <Card className={classes.card}>
        <div className={classes.boderBottomBox}>
          <div className={classes.switch}>
            <Switch
              checked={drivingImpact}
              onChange={(e) => {
                // off하면 스위치 off하고 민감도는 다 "0", on하면 스위치 다 on하고 민감도는 기본값이 5로 적용
                if (!e.target.checked) {
                  setDrivingImpact(e.target.checked);
                  onChangeTab2Setting("NORMALSENSOR1", "0");
                  onChangeTab2Setting("NORMALSENSOR2", "0");
                  onChangeTab2Setting("NORMALSENSOR3", "0");
                  // Live Event Upload는 기본값 ON.(Leehj) 23.01.10
                  // 해당스위치 off되면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.16
                  if (currentLiveEvent?.NerImpactDetection === "on") {
                    onLiveEventUpload("NerImpactDetection", false);
                  }
                  onChangeTab3Setting("EVENTSTARTVOICE", "0");
                } else {
                  setDrivingImpact(e.target.checked);
                  // mantis - 11091, 800GOLD는 호주에만 판매하는 특정 모델로 디폴트 값 다른모델과 다름 (Leehj)
                  onChangeTab2Setting("NORMALSENSOR1", is800GoldSeries ? "2" : "5");
                  onChangeTab2Setting("NORMALSENSOR2", is800GoldSeries ? "2" : "5");
                  onChangeTab2Setting("NORMALSENSOR3", is800GoldSeries ? "2" : "5");
                  onLiveEventUpload("NerImpactDetection", true);
                  // OFF -> ON 으로 변경될때는 Beep Alert은 기본값 OFF로 노출 (Leehj) 23.01.10
                  onChangeTab3Setting("EVENTSTARTVOICE", "0");
                }
              }}
            />
          </div>
          {/* mantis - 11253, 한국어 문구 수정 /  충격감지 -> 주행중 충격.(Leehj) */}
          <Typography category="Default" variant="Body">
            {t("Driving Impact")}
          </Typography>
        </div>

        <div className={classes.sensitivityDiv}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["3"]}
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
            className={clsx(!drivingImpact && classes.disabledText)}
          >
            {t("Sensitivity")}
          </Typography>
        </div>

        <div className={classes.sliderOptionWrap}>
          <div className={classes.sliderInfoDiv}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {t("Up & down")}
            </Typography>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={
                drivingImpact
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {currentTab2Obj?.NORMALSENSOR1}
            </Typography>
          </div>

          <div className={classes.sliderDiv}>
            <SettingSlider
              min={0}
              max={10}
              value={parseInt(currentTab2Obj?.NORMALSENSOR1 ?? "5")}
              disabled={!drivingImpact}
              railClasses={clsx(!drivingImpact && classes.sliderRail)}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab2Setting("NORMALSENSOR1", v.toString());
                  if (
                    v === 0 &&
                    currentTab2Obj?.NORMALSENSOR2 === "0" &&
                    currentTab2Obj?.NORMALSENSOR3 === "0"
                  ) {
                    onChangeTab3Setting("EVENTSTARTVOICE", "0");
                    // Live Event Upload는 기본값 ON.(Leehj) 23.01.10
                    // 민감도 다 0이면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.16
                    if (currentLiveEvent?.NerImpactDetection === "on") {
                      onLiveEventUpload("NerImpactDetection", false);
                    }
                  }
                }
              }}
            />
          </div>
        </div>        

        <div
          className={clsx(
            classes.sliderOptionWrap,
            classes.sliderThirdOptionWrap
          )}
        >
          <div className={classes.sliderInfoDiv}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.16
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {t("Side to side")}
            </Typography>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={
                drivingImpact
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.16
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {currentTab2Obj?.NORMALSENSOR2}
            </Typography>
          </div>
          <div className={classes.sliderDiv}>
            <SettingSlider
              min={0}
              max={10}
              value={parseInt(currentTab2Obj?.NORMALSENSOR2 ?? "5")}
              disabled={!drivingImpact}
              railClasses={clsx(!drivingImpact && classes.sliderRail)}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab2Setting("NORMALSENSOR2", v.toString());
                  if (
                    v === 0 &&
                    currentTab2Obj?.NORMALSENSOR1 === "0" &&
                    currentTab2Obj?.NORMALSENSOR3 === "0"
                  ) {
                    onChangeTab3Setting("EVENTSTARTVOICE", "0");
                    // Live Event Upload는 기본값 ON.(Leehj) 23.01.10
                    // 민감도 다 0이면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.16
                    if (currentLiveEvent?.NerImpactDetection === "on") {
                      onLiveEventUpload("NerImpactDetection", false);
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <div className={classes.sliderOptionWrap}>
          <div className={classes.sliderInfoDiv}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.16
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {t("Front & back")}
            </Typography>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={
                drivingImpact
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.16
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {currentTab2Obj?.NORMALSENSOR3}
            </Typography>
          </div>
          <div className={classes.sliderDiv}>
            <SettingSlider
              min={0}
              max={10}
              value={parseInt(currentTab2Obj?.NORMALSENSOR3 ?? "5")}
              disabled={!drivingImpact}
              railClasses={clsx(!drivingImpact && classes.sliderRail)}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab2Setting("NORMALSENSOR3", v.toString());
                  if (
                    v === 0 &&
                    currentTab2Obj?.NORMALSENSOR1 === "0" &&
                    currentTab2Obj?.NORMALSENSOR2 === "0"
                  ) {
                    onChangeTab3Setting("EVENTSTARTVOICE", "0");
                    // Live Event Upload는 기본값 ON.(Leehj) 23.01.10
                    // 민감도 다 0이면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.16
                    if (currentLiveEvent?.NerImpactDetection === "on") {
                      onLiveEventUpload("NerImpactDetection", false);
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <Divider className={classes.contentDivider} />

        <div
          className={clsx(
            classes.boderNoneTwoRowBox,
            classes.marginBottom12,
            classes.impactPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              checked={getBooleanOnOFF(
                currentLiveEvent?.NerImpactDetection ?? "on"
              )}
              onChange={(event) => {
                onLiveEventUpload("NerImpactDetection", event.target.checked);
              }}
              // 해당스위치 off되면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.16
              disabled={!drivingImpact}
            />
          </div>
          <div className={classes.textColume}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.16
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {t("Live Event Upload_3")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.16
              className={clsx(!drivingImpact && classes.disabledText)}
            >
              {t("Event file will_")}
            </Typography>
          </div>
        </div>
        <div className={classes.boderBottomNoneBox}>
          <div className={classes.switch}>
            <Switch
              checked={getBoolean(
                currentTab3Obj?.EVENTSTARTVOICE ?? ("0" as TrueFalsetype)
              )}
              onChange={(e) => {
                onChangeTab3Setting("EVENTSTARTVOICE", e.target.checked);
              }}
              // 해당스위치 off되면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.16
              disabled={!drivingImpact}
            />
          </div>

          <Typography
            category="Default"
            variant="Body"
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.16
            className={clsx(!drivingImpact && classes.disabledText)}
          >
            {/* mantis - 11122, Beep alert -> Beep Alert로 문구 수정 (Leehj) */}
            {/* mantis - 14142, Box Pro 모델 Beep alert -> Voice Alert로 문구 수정 (Hongcs) */}
            {isBoxPro ? t("Voice Alert") : t("Beep Alert")}
          </Typography>
        </div>
      </Card>

      <Divider className={classes.cardDivider} />

      {/* Parking impact card */}
      <Card className={classes.card}>
        <div className={classes.boderBottomBox}>
          <div className={classes.switch}>
            <Switch
              checked={parkingImpact}
              onChange={(e) => {
                // off하면 스위치 off하고 민감도는 다 "0", on하면 스위치 다 on하고 민감도는 기본값이 9로 적용
                if (!e.target.checked) {
                  setParkingImpact(e.target.checked);
                  onChangeTab2Setting("PARKINGSENSOR1", "0");
                  onChangeTab2Setting("PARKINGSENSOR2", "0");
                  onChangeTab2Setting("PARKINGSENSOR3", "0");
                  if (currentLiveEvent?.PerImpactDetection === "on") {
                    onLiveEventUpload("PerImpactDetection", false);
                  }
                  onChangeTab3Setting("PARKINGEVENTVOICE", "0");
                } else {
                  setParkingImpact(e.target.checked);
                  // mantis - 11091, 800GOLD는 호주에만 판매하는 특정 모델로 디폴트 값 다른모델과 다름 (Leehj)
                  onChangeTab2Setting("PARKINGSENSOR1", is800GoldSeries ? "7" : "9");
                  onChangeTab2Setting("PARKINGSENSOR2", is800GoldSeries ? "7" : "9");
                  onChangeTab2Setting("PARKINGSENSOR3", is800GoldSeries ? "7" : "9");
                  onLiveEventUpload("PerImpactDetection", true);
                  onChangeTab3Setting("PARKINGEVENTVOICE", "1");
                }
              }}
            />
          </div>

          <Typography category="Default" variant="Body">
            {t("Parking impact")}
          </Typography>
        </div>
        <div className={classes.sensitivityDiv}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["3"]}
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(!parkingImpact && classes.disabledText)}
          >
            {t("Sensitivity")}
          </Typography>
        </div>

        <div
          className={clsx(
            classes.sliderOptionWrap,
            classes.sliderSecondOptionWrap
          )}
        >
          <div className={classes.sliderInfoDiv}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {t("Up & down")}
            </Typography>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={
                parkingImpact
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {currentTab2Obj && parseInt(currentTab2Obj.PARKINGSENSOR1)}
            </Typography>
          </div>
          <div className={classes.sliderDiv}>
            <SettingSlider
              min={0}
              max={10}
              // parkingImpact OFF 누르면 비활성화 (Leehj) 23.01.10
              disabled={!parkingImpact}
              railClasses={clsx(!parkingImpact && classes.sliderRail)}
              value={parseInt(currentTab2Obj?.PARKINGSENSOR1 ?? "9")}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab2Setting("PARKINGSENSOR1", v.toString());
                  if (
                    v === 0 &&
                    currentTab2Obj?.PARKINGSENSOR2 === "0" &&
                    currentTab2Obj?.PARKINGSENSOR3 === "0"
                  ) {
                    onChangeTab3Setting("PARKINGEVENTVOICE", "0");
                    // Live Event Upload는 기본값 ON.(Leehj) 23.01.10
                    // 민감도 다 0이면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.13
                    if (currentLiveEvent?.PerImpactDetection === "on") {
                      onLiveEventUpload("PerImpactDetection", false);
                    }
                  }
                }
              }}
            />
          </div>
        </div>        

        <div
          className={clsx(
            classes.sliderOptionWrap,
            classes.parkingImpactSidePadding
          )}
        >
          <div className={classes.sliderInfoDiv}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {t("Side to side")}
            </Typography>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={
                parkingImpact
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {currentTab2Obj && parseInt(currentTab2Obj.PARKINGSENSOR2)}
            </Typography>
          </div>
          <div className={classes.sliderDiv}>
            <SettingSlider
              min={0}
              max={10}
              // parkingImpact OFF 누르면 비활성화 (Leehj) 23.01.10
              disabled={!parkingImpact}
              railClasses={clsx(!parkingImpact && classes.sliderRail)}
              value={parseInt(currentTab2Obj?.PARKINGSENSOR2 ?? "9")}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab2Setting("PARKINGSENSOR2", v.toString());
                  if (
                    v === 0 &&
                    currentTab2Obj?.PARKINGSENSOR1 === "0" &&
                    currentTab2Obj?.PARKINGSENSOR3 === "0"
                  ) {
                    onChangeTab3Setting("PARKINGEVENTVOICE", "0");
                    // Live Event Upload는 기본값 ON.(Leehj) 23.01.10
                    // 민감도 다 0이면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.13
                    if (currentLiveEvent?.PerImpactDetection === "on") {
                      onLiveEventUpload("PerImpactDetection", false);
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <div
          className={clsx(
            classes.sliderOptionWrap,
            classes.parkingImpactPadding
          )}
        >
          <div className={classes.sliderInfoDiv}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {t("Front & back")}
            </Typography>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={
                parkingImpact
                  ? LightColors.primary["7"]
                  : LightColors.primary["1"]
              }
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {currentTab2Obj && parseInt(currentTab2Obj.PARKINGSENSOR3)}
            </Typography>
          </div>
          <div className={classes.sliderDiv}>
            <SettingSlider
              min={0}
              max={10}
              // parkingImpact OFF 누르면 비활성화 (Leehj) 23.01.10
              disabled={!parkingImpact}
              railClasses={clsx(!parkingImpact && classes.sliderRail)}
              value={parseInt(currentTab2Obj?.PARKINGSENSOR3 ?? "9")}
              onChange={(e, value) => {
                if (!liveUploadLoading) {
                  const v = value as number;
                  onChangeTab2Setting("PARKINGSENSOR3", v.toString());
                  if (
                    v === 0 &&
                    currentTab2Obj?.PARKINGSENSOR1 === "0" &&
                    currentTab2Obj?.PARKINGSENSOR2 === "0"
                  ) {
                    onChangeTab3Setting("PARKINGEVENTVOICE", "0");
                    // Live Event Upload는 기본값 ON.(Leehj) 23.01.10
                    // 민감도 다 0이면 Live Event Upload는 비활성화로 off.(Leehj) 23.01.13
                    if (currentLiveEvent?.PerImpactDetection === "on") {
                      onLiveEventUpload("PerImpactDetection", false);
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        <Divider className={classes.contentDivider} />

        <div
          className={clsx(
            classes.boderNoneTwoRowBox,
            classes.marginBottom12,
            classes.impactPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              // parkingImpact OFF 누르면 비활성화 (Leehj) 23.01.10
              disabled={!parkingImpact}
              checked={getBooleanOnOFF(
                currentLiveEvent?.PerImpactDetection ?? "on"
              )}
              onChange={(event) => {
                onLiveEventUpload("PerImpactDetection", event.target.checked);
              }}
            />
          </div>
          <div className={classes.textColume}>
            <Typography
              category="Default"
              variant="Body"
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {t("Live Event Upload_3")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginTop: 2 }}
              // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
              className={clsx(!parkingImpact && classes.disabledText)}
            >
              {t("Event file will_")}
            </Typography>
          </div>
        </div>
        {/* mantis - 10555 , 모바일 디자인 간격 수정 */}
        <div
          className={clsx(
            classes.boderBottomNoneBox,
            classes.detectParkingPadding
          )}
        >
          <div className={classes.switch}>
            <Switch
              // parkingImpact OFF 누르면 비활성화 (Leehj) 23.01.10
              disabled={!parkingImpact}
              checked={getBoolean(
                currentTab3Obj?.PARKINGEVENTVOICE ?? ("1" as TrueFalsetype)
              )}
              onChange={(e) => {
                onChangeTab3Setting("PARKINGEVENTVOICE", e.target.checked);
              }}
            />
          </div>

          <Typography
            category="Default"
            variant="Body"
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx(!parkingImpact && classes.disabledText)}
          >
            {t("Parking Impact History_")}
          </Typography>
          {/* mantis - 10555 , 모바일 디자인 간격 수정  */}
          <div style={{ marginLeft: mobile ? 8 : 16, display: "flex" }}>
            <Tooltip
              disableTouchListener={mobile}
              disableHoverListener={!parkingImpact}
              // 22.11.10 문구 수정되어 적용
              title={
                <Typography
                  category="Default"
                  variant="Caption"
                  dangerouslySetInnerHTML={{
                    __html: t("When exiting Parking_"),
                  }}
                ></Typography>
              }
              placement="right"
            >
              <InfoIcon
                className={clsx(
                  classes.infoIcon,
                  !parkingImpact && classes.disabledText,
                  classes.infoIconDisabled
                )}
              />
            </Tooltip>
          </div>
        </div>
      </Card>
      <Divider className={classes.cardDivider} />

      {/* Protect event files card */}
      <Card className={clsx(classes.card, classes.protectEventDiv)}>
        <div className={classes.protectSwitch}>
          <Switch
            checked={getBoolean(currentTab1Obj?.OverwriteLock ?? "0")}
            onChange={(e) => {
              onChangeTab1Setting("LockEvent", e.target.checked);
              onChangeTab1Setting("OverwriteLock", e.target.checked);
            }}
          />
        </div>

        <div className={classes.protectInfoText}>
          <Typography
            category="Default"
            variant="Body"
            style={{ marginBottom: 9 }}
          >
            {t("Protect event files")}
          </Typography>

          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["2"]}
          >
            {/* 박스제품인 경우 51개 아니면 50개 (Leehj) 23.02.20  */}
            {t("Turning on this_", {
              a: _.includes(OPTION_CAMERA_MODELS, camera?.model) ? 51 : 50,
            })}
          </Typography>
          {/* 번역 osd 1개로 수정 / 박스제품인 경우 51개 아니면 50개 (Leehj) 23.02.20 */}
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["2"]}
            dangerouslySetInnerHTML={{
              __html: t("Protected files include_", {
                a: _.includes(OPTION_CAMERA_MODELS, camera?.model) ? 51 : 50,
              }),
            }}
          />

          {/* <ul className={classes.ul}>
            <li>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                style={{ marginRight: 3 }}
              >
                ㆍ
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Impact Events in_")}
              </Typography>
            </li>
            <li>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                style={{ marginRight: 3 }}
              >
                ㆍ
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Manual Events_")}
              </Typography>
            </li>
          </ul>

          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["2"]}
          >
            {t("Once 50 Events_")}
          </Typography> */}
        </div>
      </Card>
    </>
  );
};
