import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  IconButton,
  RadioButton,
  Sliders,
  Typography,
} from "@thingsw/pitta-design-system";
import { Selector } from "@thingsw/pitta-design-system/dist/components/Selector";

import clsx from "clsx";
import {
  CAMERA,
  loadFirmwareSettings,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import _ from "lodash";
import { FirmwareFormatModal } from "./FirmwareFormatModal";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DiscardChangeModal } from "./DiscardChangeModal";
import { FirmwareSettingModal } from "./FirmwareSettingModal";
import { DashcamRestartingModal } from "./DashcamRestartingModal";
import {
  LightColors,
  Webviewer,
  TabNameInfo1,
  TrueFalsetype,
  HDR_RESTARTING_MODEL,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 476,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  boderBottom: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    paddingTop: 0,
    paddingRight: 15,
    display: "flex",
    flexDirection: "column",
    padding: "12px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      alignItems: "center",
      padding: "0 16px",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  boderNoneBox: {
    display: "flex",
    flexDirection: "column",
    padding: "12px 16px 24px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  boderNoneBoxSpace: {
    width: "100%",
    maxWidth: 672,
    minHeight: 82,
    display: "flex",
    padding: "0 16px",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 48,
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 90,
    padding: 10,
    paddingTop: 0,
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 71,
    },
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  wid327: {
    paddingTop: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 327,
      padding: "0",
    },
  },
  wid189: {
    paddingTop: 12,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0",
    },
  },
  wid112: {
    paddingTop: 12,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0",
    },
  },
  wid224: {
    minWidth: 250,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 224,
    },
  },
  pdT8: {
    paddingTop: 8,
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  wid112in: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: 112,
  },
  wid189in: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: 189,
  },
  ArrowBack: {
    maxWidth: "100%",
    maxHeight: 21,
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  ArrowBackPd: {
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 7 }
      : { paddingLeft: 0, paddingRight: 7 }),
  },
  padding10: {
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
  },
  sliderText: {
    ...(theme.direction === "rtl" ? { marginRight: 25 } : { marginLeft: 25 }),
  },
}));

interface VideoFirmwarePanelProps {
  onClose: () => void;
}

export const VideoFirmwarePanel = ({ onClose }: VideoFirmwarePanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const [openRestartingdModal, setOpenRestartingModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openFirmwareFormat, setOpenFirmwareFormat] = useState(false);
  const { camera, firmware, firmwareConfig, loading } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [disabledChange, setDisabledChange] = useState(true);
  const [current, setCurrent] = useState<TabNameInfo1>();
  const [currentTab, setCurrentTab] = useState<TabNameInfo1>();
  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);

  const settings = useMemo(() => {
    return firmwareConfig?.Video;
  }, [firmwareConfig?.Video]);

  useEffect(() => {
    if (!loading) {
      setOpenFirmwareFormat(false);
    }
  }, [loading]);

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );

      if (currentTab) {
        setCurrent(currentTab.section_info as TabNameInfo1);
      }
    }
  }, [firmware]);
  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [dispatch, camera]);

  const handleChange = (key: keyof TabNameInfo1, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo1));
  };

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      setCurrentTab(currentTab?.section_info as TabNameInfo1);
      const predicts = _.keys(currentTab?.section_info as TabNameInfo1).map(
        (k) => {
          const key = k as keyof TabNameInfo1;
          return (
            (currentTab?.section_info as TabNameInfo1)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [firmware, current]);

  const handleUpdate = () => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab1"
        ),
      };

      if (
        (DR750S2CH &&
          (current?.ImageSetting === "0" || current?.ImageSetting === "4")) ||
        (DR750S1CH &&
          (current?.ImageSetting === "0" || current?.ImageSetting === "3")) ||
        (DR900S && current.ImageSetting === "0") ||
        (DR750G2CH &&
          (current.ImageSetting === "0" || current.ImageSetting === "4")) ||
        (DR750G1CH &&
          (current.ImageSetting === "0" || current.ImageSetting === "3"))
      ) {
        updated.cloud_settings = [
          {
            section_info: { ...current, VideoQuality: "0", RecordTime: "1" },
            section_name: "Tab1",
          },
          ...updated.cloud_settings,
        ];
      } else {
        updated.cloud_settings = [
          { section_info: current, section_name: "Tab1" },
          ...updated.cloud_settings,
        ];
      }
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  const noHDR = useMemo(
    () =>
      _.includes(
        [
          "DR750-2CH LTE",
          "DR750X-1CH",
          "DR750X-2CH",
          "DR750S-1CH",
          "DR750S-2CH",
          "DR750X Plus",
          "DR750G-1CH",
          "DR750G-2CH",
        ],
        camera?.model
      ),
    [camera?.model]
  );

  const FormatModel = useMemo(
    () =>
      _.includes(
        [
          "DR750G-1CH",
          "DR750G-2CH",
          "DR900S-1CH",
          "DR900S-2CH",
          "DR750S-1CH",
          "DR750S-2CH",
        ],
        camera?.model
      ),
    [camera?.model]
  );

  const DR750G2CH = useMemo(() => camera && camera.model === "DR750G-2CH", [
    camera,
  ]);
  const DR750G1CH = useMemo(() => camera && camera.model === "DR750G-1CH", [
    camera,
  ]);

  const DR900S = useMemo(
    () => _.includes(["DR900S-1CH", "DR900S-2CH"], camera?.model),
    [camera?.model]
  );

  const DR750S1CH = useMemo(() => camera && camera.model === "DR750S-1CH", [
    camera,
  ]);
  const DR750S2CH = useMemo(() => camera && camera.model === "DR750S-2CH", [
    camera,
  ]);

  const firmwareREV = useMemo(() => {
    if (
      firmware &&
      parseFloat(firmware?.firmware_version) >= parseFloat("1.003")
    ) {
      return true;
    }
  }, [firmware]);

  const VideoQuality = useMemo(() => {
    if (
      (DR900S && current?.ImageSetting === "0") ||
      (DR750S2CH && current?.ImageSetting === "4") ||
      (DR750S1CH && current?.ImageSetting === "3") ||
      (DR750G2CH && current?.ImageSetting === "4") ||
      (DR750G1CH && current?.ImageSetting === "3")
    ) {
      const option = settings?.["VideoQuality"]?.options4k?.[0];
      return option ? [option] : [];
    } else if (
      (DR750S2CH && current?.ImageSetting === "0") ||
      (DR750S1CH && current?.ImageSetting === "0") ||
      (DR750G2CH && current?.ImageSetting === "0") ||
      (DR750G1CH && current?.ImageSetting === "0")
    ) {
      const option = settings?.["VideoQuality"]?.options4k?.[1];
      return option ? [option] : [];
    } else {
      return settings?.["VideoQuality"]?.options ?? [];
    }
  }, [
    DR900S,
    current?.ImageSetting,
    DR750S2CH,
    DR750S1CH,
    DR750G2CH,
    DR750G1CH,
    settings,
  ]);

  return (
    <>
      <div
        className={classes.ArrowBack}
        onClick={() => {
          if (currentTab?.VideoQuality !== current?.VideoQuality) {
            setOpenDiscardModal(true);
          } else {
            onClose();
          }
        }}
      >
        <IconButton className={classes.ArrowBackPd}>
          <ArrowBackIcon></ArrowBackIcon>
        </IconButton>
        <Typography category="Default" variant="H6">
          {t("Video")}
        </Typography>
      </div>
      <div className={classes.bigBox}>
        {settings?.["ImageSetting"] && (
          <div className={classes.boderNoneBox}>
            <Typography category="Default" variant="Body">
              {t("Resolution")}
            </Typography>

            {/* mantis - 10941, 옵션이 한개인 경우 셀렉트박스가 아니라 고정된 값인 텍스트로 출력 (Leehj) */}
            {settings?.["ImageSetting"].options.length > 1 ? (
              <div className={classes.wid327}>
                <Selector
                  t={t}
                  keyValue={current?.ImageSetting}
                  dense
                  mobile={mobile}
                  onChange={(e) => {
                    setCurrent((c) => {
                      return c && { ...c, ImageSetting: e.key };
                    });
                  }}
                  items={settings?.["ImageSetting"].options}
                />
              </div>
            ) : (
              <Typography
                category="Default"
                variant="Body"
                style={{ textAlign: "end" }}
              >
                {t(settings?.ImageSetting?.options["0"].value ?? "")}
              </Typography>
            )}
          </div>
        )}

        {DR750S1CH || DR750S2CH || DR900S || DR750G2CH || DR750G1CH ? (
          <>
            {settings?.["VideoQuality"] && (
              <div className={classes.boderBottom} style={{ border: "none" }}>
                <Typography category="Default" variant="Body">
                  {t("Image quality")}
                </Typography>
                <div className={classes.wid189}>
                  <div className={classes.wid189in}>
                    <Selector
                      t={t}
                      disabled={
                        (DR750S1CH &&
                          (current?.ImageSetting === "0" ||
                            current?.ImageSetting === "3")) ||
                        (DR750S2CH &&
                          (current?.ImageSetting === "0" ||
                            current?.ImageSetting === "4")) ||
                        (DR900S && current?.ImageSetting === "0") ||
                        (DR750G2CH &&
                          (current?.ImageSetting === "0" ||
                            current?.ImageSetting === "4")) ||
                        (DR750G1CH &&
                          (current?.ImageSetting === "0" ||
                            current?.ImageSetting === "3"))
                      }
                      keyValue={current?.VideoQuality}
                      onChange={(e) => {
                        setCurrent((c) => {
                          return c && { ...c, VideoQuality: e.key };
                        });
                      }}
                      dense
                      mobile={mobile}
                      items={VideoQuality}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {settings?.["VideoQuality"] && (
              <div className={classes.boderBottom} style={{ border: "none" }}>
                <Typography category="Default" variant="Body">
                  {t("Image quality")}
                </Typography>
                <div className={classes.wid189}>
                  <div className={classes.wid189in}>
                    <Selector
                      t={t}
                      keyValue={current?.VideoQuality}
                      onChange={(e) => {
                        setCurrent((c) => {
                          return c && { ...c, VideoQuality: e.key };
                        });
                      }}
                      dense
                      mobile={mobile}
                      items={settings["VideoQuality"].options}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {/* Video codec - Radio Button*/}
        {/* mantis - 11085, DR900S은 레코딩 화면에서 해당항목 노출 (Leehj) */}
        {settings?.["VCodecType"] && !DR900S && (
          <>
            <div
              className={classes.boderNoneBox}
              style={{
                borderTop: `1px solid ${LightColors.primary["6"]}`,
                marginBottom: 9,
              }}
            >
              <Typography category="Default" variant="BodyBold">
                {t("Video codec")}
              </Typography>
            </div>
            <div className={classes.column}>
              <FormControl className={classes.padding10}>
                <RadioGroup
                  value={current?.VCodecType ?? "0"}
                  onChange={(e) =>
                    setCurrent((c) => {
                      return (
                        c && {
                          ...c,
                          VCodecType: e.target.value as TrueFalsetype,
                        }
                      );
                    })
                  }
                >
                  <RadioButton
                    style={{ padding: 3 }}
                    value="0"
                    label={
                      <Typography category="Default" variant="Body">
                        {t("H.265 (HEVC)")}
                      </Typography>
                    }
                  />
                  <div
                    style={{
                      marginTop: -10,
                      marginBottom: 21,
                    }}
                  >
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Recommended for better_")}
                    </Typography>
                  </div>
                  <RadioButton
                    style={{ padding: 3 }}
                    value="1"
                    label={
                      <Typography category="Default" variant="Body">
                        {t("H.264 (AVC)")}
                      </Typography>
                    }
                  />{" "}
                  <div style={{ marginTop: -10 }}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("For full compatibility_")}
                    </Typography>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}

        {settings?.["NightVision"] && (
          <div
            className={clsx(
              classes.boderNoneBox,
              classes.pdT8,
              classes.padding10
            )}
          >
            <FormControlLabel
              style={{ margin: 0 }}
              control={
                <CheckBox
                  checked={
                    current?.NightVision === "2" || current?.NightVision === "1"
                  }
                  onChange={(e) => {
                    handleChange("NightVision", e.target.checked);
                  }}
                  disabled={settings?.["NightVision"]?.disabled}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {noHDR ? t("Enhanced night vision") : t("HDR night vision")}
                </Typography>
              }
            />

            {settings?.["NightVision"].options && (
              <div className={classes.wid189}>
                <div className={classes.wid189in}>
                  <Selector
                    t={t}
                    keyValue={current?.NightVision}
                    disabled={
                      current?.NightVision === "0" ||
                      settings?.["NightVision"]?.disabled
                    }
                    dense
                    mobile={mobile}
                    onChange={(e) => {
                      setCurrent((c) => {
                        return c && { ...c, NightVision: e.key };
                      });
                    }}
                    items={settings["NightVision"].options}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {/* Brightness - Sliders*/}
        {camera?.model === "DR750X Plus" && firmwareREV ? (
          ""
        ) : (
          <>
            {(settings?.["FrontBrightness"] ||
              settings?.["RearBrightness"]) && (
              <div
                className={classes.boderNoneBox}
                style={{ borderTop: `1px solid ${LightColors.primary["6"]}` }}
              >
                <Typography category="Default" variant="BodyBold">
                  {t("Brightness")}
                </Typography>
              </div>
            )}
            {settings?.["FrontBrightness"] && (
              <div className={classes.boderNoneBoxSpace}>
                <div>
                  <Typography category="Default" variant="Body">
                    {t("Front")}
                  </Typography>
                </div>
                <div className={classes.wid224}>
                  <Sliders
                    max={5}
                    min={1}
                    value={parseInt(current?.FrontBrightness ?? "1") + 1}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            FrontBrightness: (v - 1).toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Small"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.FrontBrightness) + 1}
                  </Typography>
                </div>
              </div>
            )}

            {settings?.["RearBrightness"] && (
              <div className={classes.boderNoneBoxSpace}>
                <div>
                  <Typography category="Default" variant="Body">
                    {t("Rear")}
                  </Typography>
                </div>
                <div className={classes.wid224}>
                  <Sliders
                    min={1}
                    max={5}
                    value={parseInt(current?.RearBrightness ?? "1") + 1}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            RearBrightness: (v - 1).toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Small"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.RearBrightness) + 1}
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* Button*/}
      <Button
        className={classes.buttonSize}
        variant="contained"
        color="primary"
        disabled={disabledChange}
        loading={loading}
        onClick={() => {
          if (
            _.includes(HDR_RESTARTING_MODEL, camera?.model) &&
            currentTab?.NightVision !== current?.NightVision
          ) {
            setOpenRestartingModal(true);
          } else if (currentTab?.VCodecType !== current?.VCodecType) {
            setOpenFirmwareModal(true);
          } else if (
            (FormatModel &&
              currentTab?.ImageSetting !== current?.ImageSetting) ||
            currentTab?.VideoQuality !== current?.VideoQuality
          ) {
            setOpenFirmwareFormat(true);
          } else {
            handleUpdate();
          }
        }}
      >
        {t("Save")}
      </Button>
      {/* save Button 누르면 나오는 경고창 모달*/}
      {openFirmwareFormat && (
        <FirmwareFormatModal
          loading={loading}
          open={openFirmwareFormat}
          onClose={() => setOpenFirmwareFormat(false)}
          onClickNegative={() => setOpenFirmwareFormat(false)}
          onClickPositive={() => {
            handleUpdate();
            setOpenFirmwareFormat(false);
          }}
        />
      )}
      {openDiscardModal && (
        <DiscardChangeModal
          open={openDiscardModal}
          onClose={() => setOpenDiscardModal(false)}
          onClickNegative={() => setOpenDiscardModal(false)}
          onClickPositive={() => {
            onClose();
          }}
        />
      )}
      <DashcamRestartingModal
        open={openRestartingdModal}
        onClose={() => setOpenRestartingModal(false)}
        onClickNegative={() => setOpenRestartingModal(false)}
        onClickPositive={() => {
          handleUpdate();
          setOpenRestartingModal(false);
        }}
      />
      <FirmwareSettingModal
        open={openFirmwareModal}
        onClose={() => setOpenFirmwareModal(false)}
        onClickNegative={() => setOpenFirmwareModal(false)}
        onClickPositive={() => {
          handleUpdate();
          setOpenFirmwareModal(false);
        }}
      />
    </>
  );
};
