import {
  DashcamSettings,
  MobileMenu,
  Typography,
  WebMenuItem,
  EditIcon,
  Trash,
} from "@thingsw/pitta-design-system";
import React from "react";
import { useTranslation } from "react-i18next";

import SettingsIcon from "@material-ui/icons/Settings";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ICameraInfo, LightColors, IGeofence } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: LightColors.secondary["11"],
  },
  deleteIcon: {
    display: "block",
    fill: LightColors.primary["1"],
  },

  newDiv: {
    backgroundColor: LightColors.secondary["11"],
    borderRadius: 12,
    width: 43,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
}));

interface MobileCameraMenuProps {
  open: boolean;
  camera?: ICameraInfo;
  newFw?: boolean;
  onClose: () => void;
  onRename?: (() => void) | null;
  onSettings?: (() => void) | null;
  onUpdate?: (() => void) | null;
  onSpeedPriority?: (() => void) | null;
  onDelete?: ((camera: ICameraInfo) => void) | null;
  onRebootCamera?: (() => void) | null;
  geofence?: IGeofence;
  isAdjustPriority: boolean;
}

export const MobileCameraMenu = ({
  open,
  camera,
  newFw,
  onClose,
  onRename,
  onSettings,
  onUpdate,
  onSpeedPriority,
  onDelete,
  onRebootCamera,
  geofence,
  isAdjustPriority,
}: MobileCameraMenuProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MobileMenu open={open} onClose={onClose}>
      {onRename !== undefined && (
        <WebMenuItem
          disabled={onRename === null}
          startIcon={<EditIcon />}
          onClick={onRename === null ? undefined : onRename}
          dense
        >
          {t("Rename")}
        </WebMenuItem>
      )}
      {onSettings !== undefined && (
        <WebMenuItem
          disabled={onSettings === null}
          startIcon={<SettingsIcon fontSize="small" />}
          onClick={onSettings === null ? undefined : onSettings}
          dense
        >
          {t("Settings")}
        </WebMenuItem>
      )}
      {onUpdate !== undefined && (
        <WebMenuItem
          disabled={onUpdate === null || camera?.active === "off"}
          startIcon={
            <DashcamSettings fontSize="small" className={classes.deleteIcon} />
          }
          endIcon={
            newFw ? (
              <div className={classes.newDiv}>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["0"]}
                >
                  NEW
                </Typography>
              </div>
            ) : undefined
          }
          onClick={onUpdate === null ? undefined : onUpdate}
          dense
        >
          {t("Remote firmware update")}
        </WebMenuItem>
      )}
      {onRebootCamera !== undefined && (
        <WebMenuItem
          startIcon={<RefreshIcon fontSize="small" />}
          disabled={onRebootCamera === null || camera?.active === "off"}
          onClick={onRebootCamera === null ? undefined : onRebootCamera}
          dense
        >
          {t("Reboot Camera")}
        </WebMenuItem>
      )}
      {(onSpeedPriority
        && isAdjustPriority) && (
        <WebMenuItem
          disabled={!onSpeedPriority}
          startIcon={
            <img
              src="/images/geofence-priority.svg"
              style={{ display: "block" }}
              alt="priority"
            />
          }
          onClick={onSpeedPriority}
          dense
        >
          {t("Set Speed Priority")}
        </WebMenuItem>
      )}
      {onDelete !== undefined && (
        <WebMenuItem
          disabled={onDelete === null}
          className={classes.delete}
          startIcon={<Trash htmlColor={LightColors.secondary["11"]} />}
          onClick={
            onDelete === null ? undefined : () => camera && onDelete(camera)
          }
          dense
        >
          {t("Delete")}
        </WebMenuItem>
      )}
    </MobileMenu>
  );
};
