import moment, { DurationInputArg2 } from "moment";

export const durationToHMS = (inp: number, unit: DurationInputArg2) => {
  const dur = moment.duration(inp, unit);
  const hours = Math.floor(dur.asHours());
  var mins = Math.floor(dur.asMinutes()) - hours * 60;
  var secs = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;
  return `${hours}h ${mins}m ${secs}s`;
};

export const downloadCSVFile = (csv: string, filename: string) => {
  const downloadLink = document.createElement("a");
  const blob = new Blob([csv], {
    type: "text/csv;charset=utf-8",
  });
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = filename;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
