import { BaseSchema } from "yup";

const validator = function <T>(schema: BaseSchema<T>) {
  return async (formValues: any) => {
    try {
      await schema.validate(formValues, { abortEarly: false });
      return {};
    } catch (errors) {
      throw errors.inner.reduce(
        (errors: any, err: { path: any; message: any }) => ({
          ...errors,
          [err.path]: err.message,
        }),
        {}
      );
    }
  };
};

export default validator;
