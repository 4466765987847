import _ from "lodash";
import {
  IUserProfile,
  IPermissions,
  ISubscriptionInfo,
  PermissionProps,
} from "@thingsw/pitta-modules";

export const generateAuthToken = (email: string, token: string) => {
  const buffer = Buffer.from(`${email}:${token}`, "utf8");
  return `${buffer.toString("base64")}`;
};

export const createPermissions = (param: {
  userProfile?: IUserProfile;
  perm?: IPermissions;
  subscriptionInfo?: ISubscriptionInfo;
  planName?: string;
}) => {
  const { userProfile, perm, subscriptionInfo, planName } = param;
  return {
    uploadVodPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.cloudUploadManual) ||
      subscriptionInfo?.servicePlanID === 1 ||
      // mantis - 12546, Smart plan도 권한 추가 (Leehj)
      planName === "Lite plan" ||
      planName === "Smart plan",
    // mantis - 8882, admin의 다운 권한이 있는 경우에만 다운가능하도록 수정(Leehj)
    downloadCameraVodPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.myBlackVueFilePlay) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    downloadCloudVodPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.cloudFilePlay) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    deleteVodPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.fileDelete) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    moveToCloudVodPerm:
      _.includes(["Master"], userProfile?.userType) ||
      (userProfile?.userType === "SubMaster" && perm?.fileMove) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    cameraVodPerm:
      // mantis - 8882, admin camera탭 활성화되도록 수정(Leehj)
      _.includes(["Master", "SubMaster"], userProfile?.userType) ||
      (userProfile?.userType === "User" && perm?.myBlackVueFilePlay) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    cloudVodPerm:
      // mantis - 8882, admin camera탭 cloud탭 활성화되도록 수정(Leehj)
      _.includes(["Master", "SubMaster"], userProfile?.userType) ||
      (userProfile?.userType === "User" && perm?.cloudFilePlay) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    fotaCamPerm:
      _.includes(["Master"], userProfile?.userType) ||
      // mantis - 11828, admin 계정 FOTA 권한이 없는 경우 비활성화 되도록 수정 (Leehj)
      (_.includes(["SubMaster"], userProfile?.userType) &&
        perm?.firmwareUpdate) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    settingsCamPerm:
      // _.includes(["Master", "SubMaster"], userProfile?.userType) ||
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" &&
        (perm?.privacy ||
          perm?.eventAutoUpload ||
          // mantis - 10244, admin 계정은 camera setting권한 없는 경우 비활성화 되도록 수정 (Leehj)
          // perm?.pushNotifications ||
          perm?.firmwareSettings)) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    renameCamPerm:
      _.includes(["Master"], userProfile?.userType) ||
      // mantis - 11828, admin 계정 Camera profile setting 권한이 없는 경우 비활성화 되도록 수정 (Leehj)
      (_.includes(["SubMaster"], userProfile?.userType) &&
        perm?.changeDashcamName) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    deleteCamPerm:
      _.includes(["Master"], userProfile?.userType) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",

    rebootCamPerm:
      _.includes(["Master"], userProfile?.userType) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",

    // Playback more Tab 권한 추가

    moreTabPerm:
      _.includes(["Master", "SubMaster"], userProfile?.userType) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",

    //

    gpsTrackingPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.gpsTracking) ||
      planName === "Smart plan",
    geofencePerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.geoFence),
    reportPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.driveReports) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    liveviewPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.liveView !== -1) ||
      (userProfile?.userType === "User" && perm?.liveView !== -1) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    // 멀티 라이브뷰 권한
    multiLiveviewPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.liveView !== -1) ||
      (userProfile?.userType === "User" && perm?.liveView !== -1),
    privacyPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.privacy) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    pushNotificationsPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.pushNotifications) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    eventAutoUploadPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.eventAutoUpload) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    firmwareSettingPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.firmwareSettings) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
    downloadCSVReportPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.driveReports),
    shareToEventmapPerm:
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && perm?.sharingVideoToEventMap) ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan" ||
      planName === "Basic plan" ||
      planName === "Lite plan",
  } as PermissionProps;
};
