import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useQuery } from "../hooks/useQuery";
import { MaintenancePreScreen } from "../screens/app/MaintenancePreScreen";
import { MaintenancingScreen } from "../screens/app/MaintenancingScreen";
import { changeLang } from "../utils/ChangeLang";

export const MaintenanceRouter = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const query = useQuery();
  const { i18n } = useTranslation();

  useEffect(() => {
    let lang = query.get("lang");
    if (!lang) {
      lang = "en";
    }
    changeLang(i18n, lang, dispatch);
  }, [dispatch, i18n, query]);

  return (
    <Switch>
      <Route path={`${path}/pre`} component={MaintenancePreScreen} />
      <Route path={`${path}/ing`} component={MaintenancingScreen} />
    </Switch>
  );
};
