import React from "react";
import { Modal } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface DownloadVideoExceedModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalTitle: {
    minHeight: 16,
    // mantis - 10500, 모바일일 때 x 버튼 겹치는 이슈 수정 (Leehj)
    padding: `25px 40px 0 24px`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: `25px 24px 0 13px` }
        : { padding: `25px 13px 0 24px` }),
    },
  },
  exceedModalRoot: {
    minWidth: 288,
    margin: 16,
  },
  exceedModalContentDiv: {
    maxWidth: 450,
    padding: "16px 24px 57px",
  },
  // mantis - 10500, 모바일일 때 하단 버튼 가운데 정렬 (Leehj)
  btnWrap: {
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-end",
    },
  },
  btn: {
    minWidth: 139,
    whiteSpace: "nowrap",
  },
}));

export const DownloadVideoExceedModal = ({
  open,
  onClose,
  onClickPositive,
  onClickNegative,
}: DownloadVideoExceedModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      open={open}
      close
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      heading={t("Are you running_Download")}
      content={t("Upgrade your plan_Download")}
      LButton={t("Stay")}
      RButton={t("Go to Fleet Plan")}
      titleClassName={classes.modalTitle}
      className={classes.exceedModalRoot}
      contentClassName={classes.exceedModalContentDiv}
      RButtonClassName={classes.btn}
      LButtonClassName={classes.btn}
      actionClassName={classes.btnWrap}
    />
  );
};
