import {
  IGroupNameForm,
  API_GATEWAY_URI,
  ListPaging,
  IChangeGroupName,
  IGroupAddDelCamera,
  IAddUserFromCam,
  IDelUserFromCam,
  IAddDelAdmin,
} from "@thingsw/pitta-modules";
import axios from "axios";

export const addGroup = (
  email: string,
  user_token: string,
  groupData: IGroupNameForm
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserGroupAdd`, {
    groupNameList: [groupData.groupNameList],
    email,
    user_token,
    tokenType: "web",
  });
};

export const getGroupsList = (
  email: string,
  user_token: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetAllocationDashCamList?Email=${email}&UserToken=${user_token}&TokenType=${tokenType}`
  );
};

export const getGroupsListPaging = (
  email: string,
  user_token: string,
  paging?: ListPaging
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetGroupList?email=${email}&userToken=${user_token}&keyword=${
      paging?.searchKey ?? ""
    }&startIndex=${paging?.startIndex ?? ""}&endIndex=${
      paging?.endIndex ?? ""
    }&ordering=${paging?.ordering ?? "0"}&tokenType=web`
  );
};

export const changeGroupName = (
  email: string,
  user_token: string,
  groupRenameList: IChangeGroupName
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserGroupRename`, {
    email,
    user_token,
    tokenType: "web",
    groupRenameList: [groupRenameList],
  });
};

export const deleteGroup = (
  email: string,
  user_token: string,
  groups: string[]
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserGroupDelete`, {
    email,
    user_token,
    groupDeleteIDList: groups,
    tokenType: "web",
  });
};

export const addCam = (
  email: string,
  user_token: string,
  add: IGroupAddDelCamera[]
) => {
  return axios.put(
    `${API_GATEWAY_URI}/DashCam/PushMasterGroup
  `,
    {
      Email: email,
      UserToken: user_token,
      Add: add,
      TokenType: "web",
    }
  );
};

export const getUserList = (email: string, user_token: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetAllocationUserList?Email=${email}&UserToken=${user_token}&TokenType=web`
  );
};

export const deleteCam = (
  email: string,
  user_token: string,
  del: IGroupAddDelCamera[]
) => {
  return axios.delete(`${API_GATEWAY_URI}/DashCam/PopMasterGroup`, {
    data: {
      Email: email,
      UserToken: user_token,
      Del: del,
      TokenType: "web",
    },
  });
};

export const addUserFromCam = (
  email: string,
  user_token: string,
  addUser: IAddUserFromCam
) => {
  return axios.put(
    `${API_GATEWAY_URI}/DashCam/AllocateUser
  `,
    {
      Email: email,
      UserToken: user_token,
      GroupID: addUser.GroupID,
      PSN: addUser.PSN,
      UserEmail: addUser.UserEmail,
      TokenType: "web",
    }
  );
};

export const deleteUser = (
  email: string,
  user_token: string,
  delUser: IDelUserFromCam
) => {
  return axios.delete(`${API_GATEWAY_URI}/DashCam/DeallocateUser`, {
    data: {
      Email: email,
      UserToken: user_token,
      GroupID: delUser.GroupID,
      PSN: delUser.PSN,
      UserEmail: delUser.UserEmail,
      TokenType: "web",
    },
  });
};

export const addAdmin = (
  email: string,
  user_token: string,
  addAdmin: IAddDelAdmin
) => {
  return axios.post(
    `${API_GATEWAY_URI}/Account/AssignSubMasterToGroup
  `,
    {
      email,
      user_Token: user_token,
      guestEmail: addAdmin.guestEmail,
      groupManagementID: addAdmin.groupManagementID,
      tokenType: "web",
    }
  );
};

export const deleteAdmin = (
  email: string,
  user_token: string,
  delAdmin: IAddDelAdmin
) => {
  return axios.delete(`${API_GATEWAY_URI}/Account/DecoupleSubMasterFromGroup`, {
    data: {
      email,
      user_token,
      guestEmail: delAdmin.guestEmail,
      groupManagementID: delAdmin.groupManagementID,
      tokenType: "web",
    },
  });
};
