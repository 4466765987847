import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alerts,
  LocationType,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadFirmwareSettings } from "../../features/Camera/slice";
import { ILatestEvent, IUXUILatestEvent } from "../../features/Event/slice";
import { RootState } from "../../features/store";
import {
  loadUrgentVODToken,
  loadVODList,
  successloadVODList,
  VOD,
} from "../../features/VOD/slice";
import { convertLowFilenameToOrignal } from "../../utils/VOD";
import { VideoPlayer } from "./VideoPlayer";
import { USER } from "../../features/User/slice";
import {
  ICameraInfo,
  IGPSLocation,
  ITrackData,
  IVOD,
  jwtAxiosInst,
  LightColors,
  MODELS_2CH,
  MODELS_3CH,
  OPTION_CAMERA_MODELS,
  TabNameInfo1,
  Webviewer,
} from "@thingsw/pitta-modules";
import { MapboxLocationMapWrapper } from "../maps/MapboxLocationMapWrapper";
import { THEME } from "../../features/Theme/slice";

interface VideoModalProps {
  open: boolean;
  camera: ICameraInfo;
  mode: number; // 0: playback, 1: cloud, 2: live event upload, 3:auto
  data?: ITrackData;
  // mantis - 11871,11872, Latest events출력 시 api변경하여 IUXUILatestEvent 타입추가 (Leehj)
  event?: ILatestEvent | IUXUILatestEvent;
  notification?: boolean;
  gps?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  app?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
  },
  mapDiv: {
    height: 259,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: 444,
    },
    position: "relative",
    marginTop: theme.spacing(3),
  },
  contentDiv: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 18.75),
    },
  },
  contentDivFullscreen: {
    zIndex: 1200,
  },
  modalTitle: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 3.75),
    },
    display: "flex",
    alignItems: "center",
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },
  alert: {
    transition: theme.transitions.create("height"),
    margin: theme.spacing(0, 0, 1, 0),
  },
  errorClose: {
    height: 0,
  },
}));

export const VideoPlayerModal = (props: VideoModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, camera, mode, data, event, notification, onClose, gps } = props;

  const dispatch = useDispatch();

  const userState = useSelector((state: RootState) => state[USER]);
  const { fullscreen } = useSelector((state: RootState) => state[THEME]);
  const cameraState = useSelector((state: RootState) => state[CAMERA]);

  const { firmware, cameraList, firmwareConfig } = cameraState;
  const { email, loginInfo, tokenType } = userState;

  const videoSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const [cam, setCam] = useState<ICameraInfo>();
  const [locations, setLocations] = useState<IGPSLocation[]>([]);
  const [location, setLocation] = useState<IGPSLocation>();
  const [timestamp, setTimestamp] = useState<number>();
  const [playVOD, setPlayVOD] = useState<IVOD>();
  const [error, setError] = useState(false);

  const { vodList } = useSelector((state: RootState) => state[VOD]);

  const CLOUD_NATIVE3 = useMemo(() => {
    if (firmwareConfig === undefined) {
      return undefined;
    }
    return !!firmwareConfig?.CloudNative3;
  }, [firmwareConfig]);

  // mantis - 11844, 해당 파일의 rear or Interior 영상을 찾아서 보여줌 / 없으면 비활성화하기 위한 api호출 (Leehj)
  useEffect(() => {
    const requestCNVod = async (camera: ICameraInfo) => {
      const resp = await jwtAxiosInst.post(`/IoT/devicecommand`, {
        command: "VODList",
        email,
        user_token: loginInfo?.user_token,
        tokenType: tokenType,
        psn: camera?.psn,
      });
      if (resp.data?.["filelist"]) {
        dispatch(successloadVODList(resp.data["filelist"]));
      }
    };
    if (camera) {
      if (CLOUD_NATIVE3 === true) {
        requestCNVod(camera);
      } else if (CLOUD_NATIVE3 === false) {
        dispatch(
          loadVODList({
            lb_server_name: camera.lb_server_name,
            lb_http_port: camera.lb_http_port,
            psn: camera.psn,
          })
        );
      }
    }
  }, [
    CLOUD_NATIVE3,
    camera,
    dispatch,
    email,
    loginInfo?.user_token,
    tokenType,
  ]);

  //Blackvue7 Box 옵션 카메라 방향 추가
  const isOptionModel = useMemo(
    () => _.includes(OPTION_CAMERA_MODELS, camera?.model),
    [camera?.model]
  );

  const changeVOD = useCallback(
    (
      filename: string,
      direction: LocationType,
      track?: ITrackData,
      front?: boolean,
      rear?: boolean,
      third?: boolean
      // rid_front?: string,
      // rid_rear?: string,
      // rid_3?: string
    ) => {
      const timeStrings = filename.split("_");
      const time = moment(
        `${timeStrings[0]} ${timeStrings[1]}`,
        "YYYYMMDD hhmmss"
      );

      if (mode === 0) {
        dispatch(
          loadUrgentVODToken({
            psn: camera.psn,
            filename: filename,
          })
        );
      }

      let fname = track?.sub_front || filename;
      let rid = track?.rid_front;
      if (direction === "Rear") {
        fname = track?.sub_rear || filename;
        rid = track?.rid_rear;
      } else if (direction === "Interior" || direction === "Option") {
        fname = track?.sub_3 || filename;
        rid = track?.rid_3;
      }

      setPlayVOD({
        filename: convertLowFilenameToOrignal(fname),
        lowFilename: fname,
        rid,
        lowRid: rid,
        direction,
        event: "Event",
        time,
        hasLow: true,
        hasFront: !!(track?.rid_front || track?.sub_front) || front,
        hasRear: !!(track?.rid_rear || track?.sub_rear) || rear,
        hasInterior:
          !isOptionModel && (!!(track?.rid_3 || track?.sub_3) || third),
        hasOption: isOptionModel && (!!(track?.rid_3 || track?.sub_3) || third),
      });
    },
    [camera.psn, dispatch, isOptionModel, mode]
  );

  const getEventFile: (
    event: ILatestEvent | IUXUILatestEvent,
    dir?: LocationType
  ) => [string | undefined, LocationType] = useCallback(
    (event: ILatestEvent | IUXUILatestEvent, dir?: LocationType) => {
      const { event_file } = event;
      if (event_file) {
        const fnames = _.split(event_file, ".");
        const names = _.split(fnames[0], "_");
        let evt = _.last(names);
        let direction: LocationType = dir ?? "Front";
        if (!dir) {
          switch (evt?.[1]) {
            case "I":
              direction = "Interior";
              break;
            case "R":
              direction = "Rear";
              break;
            case "O":
              direction = "Option";
              break;
            default:
              direction = "Front";
              break;
          }
        } else if (evt) {
          switch (dir) {
            case "Front":
              evt = evt[0] + "F";
              break;
            case "Rear":
              evt = evt[0] + "R";
              break;
            case "Interior":
              evt = evt[0] + "I";
              break;
            case "Option":
              evt = evt[0] + "O";
              break;
          }
        }
        if (evt && evt.length === 2) {
          evt = evt + "S";
        }
        console.log("videoPlayer", "getEventFile", event, direction);
        const fname =
          _.join([..._.slice(names, 0, names.length - 1), evt], "_") +
          "." +
          fnames[1];
        return [fname, direction];
      }
      return [undefined, "Front"];
    },
    []
  );

  useEffect(() => {
    if (cam) {
      dispatch(loadFirmwareSettings(cam.psn));
    }
  }, [cam, dispatch]);

  useEffect(() => {
    if (event) {
      const [fname, direction] = getEventFile(event);
      if (fname && direction) {
        if (mode === 0) {
          // 9960
          // mantis - 11844, 3ch모델도 rear 탭 출력되도록 수정 (Leehj)
          const hasRear =
            _.includes(MODELS_2CH, camera.model) ||
            _.includes(MODELS_3CH, camera.model);
          const has3 = _.includes(MODELS_3CH, camera.model);
          changeVOD(fname, direction, undefined, true, hasRear, has3);
        } else {
          changeVOD(fname, direction);
        }
      }
    } else if (data) {
      const {
        sub_front,
        sub_rear,
        sub_3,
        front_playable,
        rear_playable,
        sub3_playable,
      } = data;
      const isAI = (data.mtype >= 6 && data.mtype <= 12) || data.mtype === 17;
      if (isAI && sub_3) {
        changeVOD(sub_3, "Interior", data);
      } else if (front_playable && sub_front) {
        changeVOD(sub_front, "Front", data);
      } else if (rear_playable && sub_rear) {
        changeVOD(sub_rear, "Rear", data);
      } else if (sub3_playable && sub_3) {
        changeVOD(sub_3, "Interior", data);
      }
    } else {
      setPlayVOD(undefined);
    }
  }, [changeVOD, camera, data, event, mode, getEventFile]);

  // useEffect(() => {
  //   if (cam && cam.latitude && cam.longitude) {
  //     setLocation({
  //       lat: parseFloat(cam.latitude),
  //       lng: parseFloat(cam.longitude),
  //       name: cam.dev_name,
  //       mode: "0",
  //     } as IGPSLocation);
  //   }
  //   // else {
  //   //   const cancel = new AbortController();
  //   //   dispatch(loadMyCemrasLocation({ cancel }));
  //   //   return () => {
  //   //     cancel.abort();
  //   //   };
  //   // }
  // }, [cam, dispatch]);

  useEffect(() => {
    const cm = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === camera?.psn)
      .value();
    setCam((c) => {
      if (c?.psn !== cm?.psn) {
        return cm;
      } else if (c?.active !== cm?.active) {
        return cm;
      } else if (
        (!c?.latitude && cm?.latitude) ||
        (!c?.longitude && cm?.latitude)
      ) {
        return cm;
      }
      return c;
    });
  }, [camera, cameraList?.deviceListInfo]);

  useEffect(() => {
    if (timestamp) {
      const last = _.chain(locations)
        .filter((loc) => (loc.time ?? 0) <= timestamp)
        .last()
        .value();

      if (last) {
        setLocation(last);
      }
    }
  }, [timestamp, locations]);

  const playFrontVOD = useCallback(() => {
    if (data) {
      const { sub_front } = data;
      if (sub_front) {
        changeVOD(sub_front, "Front", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Front");
      if (fname && direction) {
        if (mode === 0) {
          // 9960
          // mantis - 11844, 3ch모델도 rear 탭 출력되도록 수정 (Leehj)
          const hasRear =
            _.includes(MODELS_2CH, camera.model) ||
            _.includes(MODELS_3CH, camera.model);
          const has3 = _.includes(MODELS_3CH, camera.model);
          changeVOD(fname, direction, undefined, true, hasRear, has3);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [camera.model, changeVOD, data, event, getEventFile, mode]);

  const playRearVOD = useCallback(() => {
    if (data) {
      const { sub_rear } = data;
      if (sub_rear) {
        changeVOD(sub_rear, "Rear", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Rear");
      if (fname && direction) {
        if (mode === 0) {
          // 9960
          // mantis - 11844, 3ch모델도 rear 탭 출력되도록 수정 (Leehj)
          const hasRear =
            _.includes(MODELS_2CH, camera.model) ||
            _.includes(MODELS_3CH, camera.model);
          const has3 = _.includes(MODELS_3CH, camera.model);
          changeVOD(fname, direction, undefined, true, hasRear, has3);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [camera.model, changeVOD, data, event, getEventFile, mode]);

  const playInteriorVOD = useCallback(() => {
    if (data) {
      // 재생 방향이 잘못 merge되어 수정 (8516)
      const { sub_3 } = data;
      if (sub_3) {
        changeVOD(sub_3, "Interior", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Interior");
      if (fname && direction) {
        if (mode === 0) {
          // 9960
          // mantis - 11844, 3ch모델도 rear 탭 출력되도록 수정 (Leehj)
          const hasRear =
            _.includes(MODELS_2CH, camera.model) ||
            _.includes(MODELS_3CH, camera.model);
          const has3 = _.includes(MODELS_3CH, camera.model);
          changeVOD(fname, direction, undefined, true, hasRear, has3);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [camera.model, changeVOD, data, event, getEventFile, mode]);

  const playOptionVOD = useCallback(() => {
    if (data) {
      const { sub_3 } = data;
      if (sub_3) {
        changeVOD(sub_3, "Option", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Option");
      if (fname && direction) {
        if (mode === 0) {
          changeVOD(fname, direction, undefined, true, true, true);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [changeVOD, data, event, getEventFile, mode]);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  const videoPlayer = useMemo(() => {
    let rmode = mode;
    if (mode === 3) {
      if (playVOD?.rid) {
        rmode = 2;
      } else {
        rmode = 1;
      }
    }

    const sameDateVod = _.filter(vodList, (item) =>
      _.includes(
        item.filename,
        playVOD?.filename.split("_").slice(0, 2).join("_")
      )
    );

    const isRearVod = _.filter(sameDateVod, (item) => item.rear);
    const isInteriorVod = _.filter(sameDateVod, (item) => item.interior);

    return (
      <VideoPlayer
        playback
        mode={rmode}
        quality="low"
        camera={cam}
        vod={playVOD}
        singleVideo
        noTheater
        fullHeight
        notification={notification}
        onUpdateGPS={setLocations}
        onUpdateTime={setTimestamp}
        onRequestFront={playFrontVOD}
        onRequestRear={playRearVOD}
        onRequestInterior={playInteriorVOD}
        onRequestOption={playOptionVOD}
        onError={handleError}
        showDrows={false}
        // mantis - 11844,해당 파일의 rear or Interior 영상을 찾아서 보여줌 / 없으면 비활성화 (Leehj)
        rearDisabled={isRearVod.length === 0}
        interiorDisabled={isInteriorVod.length === 0}
        gpsTracking={gps}
      />
    );
  }, [
    mode,
    vodList,
    cam,
    playVOD,
    notification,
    playFrontVOD,
    playRearVOD,
    playInteriorVOD,
    playOptionVOD,
    handleError,
    gps,
  ]);

  return (
    <Modal
      className={classes.root}
      contentClassName={clsx(classes.contentDiv, {
        [classes.contentDivFullscreen]: fullscreen,
      })}
      heading={
        gps
          ? `${data?.vdate.format("YYYY-MM-DD HH:mm:ss")} · ${playVOD?.event} ${
              playVOD?.direction
            }`
          : `${camera.dev_name} (${camera.model})`
      }
      titleClassName={classes.modalTitle}
      open={open}
      onClose={onClose}
      content={
        <div>
          {error && (
            <Alerts
              mode="web"
              severity="error"
              className={clsx(classes.alert, {
                [classes.errorClose]: !error,
              })}
            >
              {t("An error occurred_again")}
            </Alerts>
          )}
          {videoPlayer}
          <div className={classes.mapDiv}>
            {videoSettings?.["UseGpsInfo"] === "1" && (
              <MapboxLocationMapWrapper
                location={location}
                // noMarker={!playVOD}
                playback
              />
            )}
            {playVOD && videoSettings?.["UseGpsInfo"] === "0" && (
              <div className={classes.loadingDiv}>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  htmlColor={LightColors.primary["0"]}
                >
                  {t("GPS Location recording off")}
                </Typography>
              </div>
            )}
          </div>
        </div>
      }
      close
    />
  );
};
