import React, { useCallback, useState } from "react";
import { Modal } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { FormHelperText } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadNotificationEmailList } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { inviteEmail } from "../../apis";
import { openToast } from "../../features/Toast/slice";
import { Webviewer, RESULT_CODE, LightColors } from "@thingsw/pitta-modules";

interface InviteModalProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onClickNegative: () => void;
  onClickPositive: () => void;
  darkMode?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#1E1E23" : "#FFFFFF",
    borderColor: (props: any) => props.darkMode ? "#323236" : "#D4D4D5",
  },
  content: {
    padding: "19px 16px 21px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "22px 32px 21px",
    },
    "& .MuiInputBase-root": {
      color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
      backgroundColor: (props: any) => props.darkMode ? "#121216 " : "#FFFFFF",
    },
  },
  titleDiv: {
    padding: "24px 44px 0 24px",
    minHeight: 33,
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },
  LButton: {
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#323236" : "#FFFFFF",
    borderColor: (props: any) => props.darkMode ? "#636367" : "#D4D4D5",
  },
  RButton: {
    "&.Mui-disabled": {
      backgroundColor: (props: any) => props.darkMode ? "rgba(208, 235, 255, 0.3)" : "rgba(0, 149, 224, 0.35)",
    },
  },
}));

export const InviteModal = ({
  open,
  // loading,
  onClose,
  onClickNegative,
  onClickPositive,
  darkMode,
}: InviteModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ darkMode });
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera } = useSelector((state: RootState) => state[CAMERA]);
  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );

  const [guestEmail, setGuestEmail] = useState<string>("");
  const [error, setError] = useState("");
  const [disableInvite, setDisableInvite] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const checkEmail = useCallback((value: string) => {
    let email = value;
    // eslint-disable-next-line no-useless-escape
    let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regExp.test(email) === false) {
      // mantis - 10648, 문구 변경 (Leehj)
      setError("Please enter_email");
      setDisableInvite(true);
    } else {
      setError("");
      setDisableInvite(false);
    }
  }, []);

  return (
    <Modal
      open={open}
      className={classes.modal}
      loading={loading}
      mobile={mobile}
      onClose={() => {
        onClose();
        setGuestEmail("");
      }}
      onClickNegative={() => {
        onClickNegative();
        setGuestEmail("");
      }}
      // 10629 - API 호출 완료까지 팝업 안닫히게 수정
      // API에 토큰 타입 추가 (mckim)
      onClickPositive={async () => {
        setLoading(true);
        try {
          if (camera && email && loginInfo) {
            const res = await inviteEmail(
              email,
              loginInfo.user_token,
              camera.psn,
              guestEmail,
              "",
              tokenType
            );

            const { resultcode } = res.data as {
              resultcode: RESULT_CODE;
              message: string;
            };
            if (resultcode === "BC_ERR_OK") {
              dispatch(openToast({ message: "Invite sent" }));
              dispatch(loadNotificationEmailList(camera.psn));

              onClickPositive();
              setGuestEmail("");
            } else if (resultcode === "BC_ERR_DUPLICATED") {
              // mantis - 10831,10827 Send invite 동일 메일 중복 추가 진행 시 에러문구 출력 (Leehj)
              setError("Email is already_");
            }
          }
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }}
      contentClassName={classes.content}
      titleClassName={classes.titleDiv}
      heading={t("Send invite")}
      close
      content={
        <div>
          <Input
            error={!!error}
            label="Email"
            value={guestEmail}
            onChange={(e) => {
              checkEmail(e.target.value);
              setGuestEmail(e.target.value);
            }}
          />
          {/* mantis - 10831,10827 에러 상황별로 문구 다르게 나오도록 수정 (Leehj) */}
          {error ? (
            <FormHelperText
              style={{ color: LightColors.secondary["11"], marginLeft: 16 }}
            >
              {t(error)}
            </FormHelperText>
          ) : (
            ""
          )}
        </div>
      }
      RButtonDisabled={disableInvite}
      LButton={t("Cancel")}
      RButton={t("Send")}
      RButtonClassName={classes.RButton}
      LButtonClassName={classes.LButton}
    />
  );
};
