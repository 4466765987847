import { useMemo } from "react";
import {
  BATTERY_CLOUD_SUPPORTED,
  FW_VERSION,
  ICameraInfo,
  MODEL_KEY,
} from "@thingsw/pitta-modules";

const useAbleBatteryCloud = (camera?: ICameraInfo) => {
  const isAbleBatteryCloud = useMemo(() => {
    if (!camera) {
      return false;
    }

    const cameraModel = camera.model as MODEL_KEY;
    const cameraVersion = parseFloat(camera.fw_ver) as FW_VERSION;

    if (BATTERY_CLOUD_SUPPORTED.hasOwnProperty(cameraModel)) {
      const supportedVersion = BATTERY_CLOUD_SUPPORTED[cameraModel];

      if (supportedVersion && cameraVersion >= supportedVersion) {
        return true;
      }
    }

    return false;
  }, [camera]);

  return isAbleBatteryCloud;
};

export default useAbleBatteryCloud;
