import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AccountScreen } from "../screens/AccountScreen";

export const AccountRouter = (props: any) => {
  const { path } = useRouteMatch();
  const profileMatch = useRouteMatch<{ psn: string }>(`${path}/profile`);
  const subscriptionMatch = useRouteMatch<{ psn: string }>(
    `${path}/subscription`
  );
  const settingsMatch = useRouteMatch<{ psn: string }>(`${path}/settings`);
  const usageMatch = useRouteMatch<{ psn: string }>(`${path}/usage`);

  const renderAccountScreen = () => {
    if (profileMatch) {
      return <AccountScreen {...props} mode="profile" />;
    }
    if (subscriptionMatch) {
      return <AccountScreen {...props} mode="subscription" />;
    }
    if (settingsMatch) {
      return <AccountScreen {...props} mode="settings" />;
    }
    if (usageMatch) {
      return <AccountScreen {...props} mode="usage" />;
    }
    return <AccountScreen {...props} mode="profile" />;
  };

  return (
    <Switch>
      <Route path={path} render={renderAccountScreen} />
    </Switch>
  );
};
