import { makeStyles, Theme } from "@material-ui/core";
import {
  IconButton,
  Tab,
  Tabs,
  Typography,
  Tooltip,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { openToast } from "../../features/Toast/slice";
import { MobileCameraMenu } from "../uxui/MobileCameraMenu";
import { CameraMenu } from "../uxui/CameraMenu";
import {
  ICameraInfo,
  SemanticDarkColors,
  Webviewer,
  PSN650,
  AvailableFw,
} from "@thingsw/pitta-modules";

interface PlaybackHeaderProps {
  openMenu: boolean;
  currentCam?: ICameraInfo;
  mobile: boolean;
  value: number;
  onRename?: (camera: ICameraInfo) => void;
  onDelete?: (camera: ICameraInfo) => void;
  onUpdate?: (camera: ICameraInfo) => void;
  onRebootCamera?: (camera: ICameraInfo) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tabDiv: {
    backgroundColor: (props: ScreenDefaultProps) =>
      props.color === "dark"
        ? SemanticDarkColors.primary["1"]
        : props.colors.primary["0"],
    top: (props: ScreenDefaultProps) => props.headerDivRef?.offsetHeight ?? 56,
    ...(theme.direction === "rtl"
      ? { left: 0, right: 0 }
      : { left: 235, right: 0 }),
    position: "fixed",
    zIndex: 90,
    height: 53 + 29,
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 4),
      height: 53,
      ...(theme.direction === "rtl"
        ? { left: 0, right: 235 }
        : { left: 235, right: 0 }),
    },
  },
  tabDivClosed: {
    left: 73,
  },
  tabDivMobile: {
    left: 0,
    flexDirection: "column",
    alignItems: "unset",
  },
  mobileCamNameDiv: {
    display: "flex",
    paddingTop: theme.spacing(1),
    justifyContent: "center",
  },
  tabScroll: {
    borderBottom: (props: ScreenDefaultProps) =>
      `1px solid ${props.colors.primary["5"]}`,
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflowX: "unset",
      width: "100%",
    },
  },
  tab: {
    display: "flex",
    // justifyContent: "space-between",
    minWidth: 570,
    width: "100%",
    height: 52,
    alignItems: "flex-end",
  },
  tabItem: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: 6,
    whiteSpace: "nowrap",
    color: (props: ScreenDefaultProps) => props.colors.primary["1"],
  },
}));
export const PlaybackHeader = (
  props: PlaybackHeaderProps & ScreenDefaultProps & PermissionProps
) => {
  const {
    openMenu,
    currentCam,
    mobile,
    value,
    onRename,
    onDelete,
    onRebootCamera,
  } = props;
  const {
    gpsTrackingPerm,
    reportPerm,
    settingsCamPerm,
    renameCamPerm,
    fotaCamPerm,
    deleteCamPerm,
    liveviewPerm,
    rebootCamPerm,
    moreTabPerm,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const match = useRouteMatch<{ psn: string }>("/cameras/:psn");
  const history = useHistory();

  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );

  const [openLiveviewTooltip, setOpenLiveviewTooltip] = useState(false);
  const [openGpsTooltip, setOpenGpsTooltip] = useState(false);
  const [openReportTooltip, setOpenReportTooltip] = useState(false);
  const [openSettingsTooltip, setOpenSettingsTooltip] = useState(false);
  const [openMoreTabTooltip, setOpenMoreTabTooltip] = useState(false);
  const [openCamMenu, setOpenCamMenu] = useState(false);

  // mantis : 8831 - KimSeongHyun
  // 탭 이동시 스크롤 최상위로 이동
  const scrollToTop = useCallback(() => {
    document.getElementById("scrollTop")?.scrollIntoView(true);
  }, []);

  const handleLiveTooltipHover = useCallback(() => {
    if (!liveviewPerm) {
      setOpenLiveviewTooltip(true);
    }
  }, [liveviewPerm]);

  const handleMoreTabTooltipHover = useCallback(() => {
    if (!moreTabPerm) {
      setOpenMoreTabTooltip(true);
    } else {
      return openMoreTabTooltip;
    }
  }, [moreTabPerm, openMoreTabTooltip]);

  const handleGpsTooltipHover = useCallback(() => {
    if (!gpsTrackingPerm && !mobile) {
      setOpenGpsTooltip(true);
    }
  }, [gpsTrackingPerm, mobile]);

  const handleReportTooltipHover = useCallback(() => {
    if (!reportPerm) {
      setOpenReportTooltip(true);
    }
  }, [reportPerm]);

  const handleSettingsTooltipHover = useCallback(() => {
    if (!settingsCamPerm) {
      setOpenSettingsTooltip(true);
    }
  }, [settingsCamPerm]);

  const handleRename = useMemo(() => {
    return renameCamPerm
      ? () => {
          setOpenCamMenu(false);
          currentCam && onRename?.(currentCam);
          // setCurrentCam(camera?.dev_name);
        }
      : null;
  }, [currentCam, onRename, renameCamPerm]);

  const handleRebbotCam = useMemo(() => {
    return rebootCamPerm
      ? () => {
          currentCam && onRebootCamera?.(currentCam);
          setOpenCamMenu(false);
        }
      : null;
  }, [currentCam, onRebootCamera, rebootCamPerm]);

  const handleFOTA: (() => void) | null = useMemo(() => {
    return fotaCamPerm && !_.includes(PSN650, currentCam?.psn.substr(0, 4))
      ? (fw?: AvailableFw) => {
          if (fw) {
            if (currentCam && currentCam.active === "on") {
              if (fw.dmc200) {
                history.push(`/cameras/${currentCam.psn}/fota-dmc200`);
              } else if (fw.blackvue) {
                history.push(`/cameras/${currentCam.psn}/fota`);
              }
            }
          } else {
            dispatch(openToast({ message: "Firmware is up_" }));
            setOpenCamMenu(false);
          }
        }
      : null;
  }, [currentCam, dispatch, fotaCamPerm, history]);

  const handleDelete = useMemo(() => {
    return deleteCamPerm
      ? () => {
          setOpenCamMenu(false);
          currentCam && onDelete?.(currentCam);
        }
      : null;
  }, [currentCam, deleteCamPerm, onDelete]);

  return (
    <div
      className={clsx(classes.tabDiv, {
        [classes.tabDivClosed]: !openMenu,
        [classes.tabDivMobile]: mobile,
      })}
    >
      {mobile && (
        <div className={classes.mobileCamNameDiv}>
          <Typography
            category="Default"
            variant="SmallBold"
            htmlColor={props.colors.primary["2"]}
          >
            {currentCam?.dev_name}
          </Typography>
        </div>
      )}

      <div className={classes.tabScroll}>
        <div className={clsx(classes.tab)}>
          <Tabs value={value}>
            <Tab
              className={classes.tabItem}
              label={t("Playback")}
              onClick={() => {
                history.push(`/cameras/${match?.params.psn}/playback`);
                // mantis :  8831 - KimSeongHyun
                // 탭 이동시 스크롤 최상위로 이동
                scrollToTop();
              }}
            />

            <Tab
              className={classes.tabItem}
              label={
                <Tooltip
                  open={openLiveviewTooltip}
                  title={t("No permission") ?? "No permission"}
                  placement="bottom"
                  PopperProps={{
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "0, -12px",
                      },
                      flip: {
                        enabled: false,
                      },
                    },
                  }}
                >
                  <div
                    onMouseOver={handleLiveTooltipHover}
                    onMouseOut={() => {
                      setOpenLiveviewTooltip(false);
                    }}
                  >
                    {t("Live view")}
                  </div>
                </Tooltip>
              }
              disabled={currentCam?.active === "off" || !liveviewPerm}
              onClick={() => {
                history.push(`/cameras/${match?.params.psn}/live-view`, {
                  clicked: true,
                });
                // mantis :  8831 - KimSeongHyun
                // 탭 이동시 스크롤 최상위로 이동
                scrollToTop();
              }}
            />

            <Tooltip
              open={openGpsTooltip}
              title={
                <Typography category="Default" variant="Caption">
                  {userProfile?.userType === "User" ||
                  userProfile?.userType === "SubMaster"
                    ? t("No permission")
                    : t("Fleet Plan only_")}
                </Typography>
              }
              placement="bottom"
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "0, -12px",
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }}
            >
              <div
                onMouseOver={handleGpsTooltipHover}
                onMouseOut={() => {
                  setOpenGpsTooltip(false);
                }}
              >
                <Tab
                  className={classes.tabItem}
                  disabled={!gpsTrackingPerm}
                  label={t("GPS tracking")}
                  onClick={() => {
                    history.push(`/cameras/${match?.params.psn}/gps-tracking`);
                    // mantis :  8831 - KimSeongHyun
                    // 탭 이동시 스크롤 최상위로 이동
                    scrollToTop();
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip
              open={openReportTooltip}
              title={t("No permission") ?? "No permission"}
              placement="bottom"
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "0, -12px",
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }}
            >
              <div
                onMouseOver={handleReportTooltipHover}
                onMouseOut={() => {
                  setOpenReportTooltip(false);
                }}
              >
                <Tab
                  className={classes.tabItem}
                  disabled={
                    !reportPerm ||
                    _.includes(PSN650, currentCam?.psn.substr(0, 4))
                  }
                  label={t("Report")}
                  onClick={() => {
                    history.push(`/cameras/${match?.params.psn}/report`);
                    // mantis :  8831 - KimSeongHyun
                    // 탭 이동시 스크롤 최상위로 이동
                    scrollToTop();
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip
              open={openSettingsTooltip}
              title={t("No permission") ?? "No permission"}
              placement="bottom"
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "0, -12px",
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }}
            >
              <div
                onMouseOver={handleSettingsTooltipHover}
                onMouseOut={() => {
                  setOpenSettingsTooltip(false);
                }}
              >
                <Tab
                  className={classes.tabItem}
                  disabled={!settingsCamPerm}
                  label={t("Settings")}
                  onClick={() => {
                    history.push(`/cameras/${match?.params.psn}/settings`);
                    scrollToTop();
                  }}
                />
              </div>
            </Tooltip>
          </Tabs>

          <Tooltip
            disableTouchListener={mobile}
            placement={moreTabPerm ? "top" : "bottom"}
            PopperProps={{
              modifiers: {
                offset: {
                  enabled: true,
                  offset: moreTabPerm ? "0, -20px" : "0, -12px",
                },
                flip: {
                  enabled: false,
                },
              },
            }}
            title={
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={props.colors.primary["0"]}
              >
                {moreTabPerm ? t("More") : t("No permission")}
              </Typography>
            }
          >
            <div
              onMouseOver={handleMoreTabTooltipHover}
              onMouseOut={() => {
                setOpenMoreTabTooltip(false);
              }}
            >
              <IconButton
                ref={anchorRef}
                onClick={() => setOpenCamMenu(true)}
                disabled={!moreTabPerm}
                style={{ padding: 6 }}
              >
                <MoreHorizIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>

      {!mobile && (
        <CameraMenu
          {...props}
          open={openCamMenu}
          camera={currentCam}
          anchorRef={anchorRef}
          placement="bottom-start"
          onClickAway={() => setOpenCamMenu(false)}
          // mantis - 11504, 시그마모델의 경우 셋팅 서브메뉴 중 camera profile 무반응 이슈 수정 (Leehj)
          onEditProfile={handleRename}
          onUpdate={handleFOTA}
          onDelete={handleDelete}
          onRebootCamera={handleRebbotCam}
        />
      )}
      {mobile && (
        <MobileCameraMenu
          {...props}
          camera={currentCam}
          open={openCamMenu}
          onClose={() => setOpenCamMenu(false)}
          // mantis - 11504, 시그마모델의 경우 셋팅 서브메뉴 중 camera profile 무반응 이슈 수정 (Leehj)
          onEditProfile={handleRename}
          onUpdate={handleFOTA}
          onDelete={handleDelete}
          onRebootCamera={handleRebbotCam}
        />
      )}
    </div>
  );
};
