import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { IconButton, Typography } from "@thingsw/pitta-design-system";
import FileTypeContainer from "@thingsw/pitta-design-system/dist/components/FileTypeContainer";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import _ from "lodash";
import {
  LightColors,
  Webviewer,
  EventVideo,
  MTYPE_TO_ECODE,
  EventCode,
  FileTypeName,
  AI_EVENTS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    cursor: "pointer",
  },
  blockedRoot: {
    cursor: "unset",
    position: "absolute",
    zIndex: 999,
    backgroundColor: `${LightColors.primary["4"]}D9`,
    height: 95,
    margin: theme.spacing(1),
    borderRadius: 8,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // margin: theme.spacing(1.5, 0.5),
    },
  },
  hoverRoot: {
    backgroundColor: LightColors.primary["10"],
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(1, 1.875, 1.125, 0) }
      : { padding: theme.spacing(1, 0, 1.125, 1.875) }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(1, 2, 1.125, 0) }
        : { padding: theme.spacing(1, 0, 1.125, 2) }),
    },
  },
  blockedContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "19.5px 7px ",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "19.5px 7.5px ",
      maxWidth: 359,
    },
  },
  blockedInfo: {
    display: "flex",
    alignItems: "center",
    backgroundColor: LightColors.primary["0"],
    borderRadius: 8,
    padding: "7px 8px",
    // minWidth: 296,
  },
  galaxyS5SizeContainer: {
    width: "auto",
    flex: 1,
  },
  dateTimeDiv: {
    display: "flex",
    minWidth: 76,
    "& > span:first-child": {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(1) }
        : { marginRight: theme.spacing(1) }),
    },
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.5), marginRight: theme.spacing(0.5) }
      : { marginRight: theme.spacing(1.5), marginLeft: theme.spacing(0.5) }),
    marginBottom: 3.5,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 89,
      marginLeft: 3,
    },
  },
  eventDetailDiv: {
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      boxSizing: "inherit",
      // mantis-8550
      // 일어의 경우 1줄로 나오도록 하기위해 padding값을 수정하였고, rtl 값 추가.
      // ...(theme.direction === "rtl"
      //   ? { padding: theme.spacing(1.5, 1, 1.5, 2) }
      //   : { padding: theme.spacing(1.5, 2, 1.5, 1) }),
    },
  },
  eventDetailInfo: {
    display: "flex",
    flexDirection: "column",
  },
  reportIconDiv: {
    display: "flex",
    ...(theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }),
  },
  blockedTextDiv: {
    boxSizing: "border-box",
    borderRadius: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // width: 230,
      boxSizing: "inherit",
    },
  },
  hoverEventDetailDiv: {
    ...(theme.direction === "rtl"
      ? { paddingLeft: theme.spacing(0) }
      : { paddingRight: theme.spacing(0) }),
  },
  shareStatusDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: 14,
    marginBottom: 2,
    "& > svg": {
      ...(theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }),
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 16,
      marginBottom: 0,
    },
  },
  hoverBarDiv: {
    minWidth: 3,
    display: "flex",
  },
  hoverBarPending: {
    backgroundColor: LightColors.secondary["11"],
  },
  removeBtnDiv: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    minWidth: 44,
  },
  removeBtn: {
    marginTop: 21,
    color: LightColors.primary["3"],
    padding: theme.spacing(0, 1),
  },
  blockedIcon: {
    color: LightColors.primary["2"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    // 이벤트맵 신고영상 제거버튼 누르고 팝업을 닫으면 제거버튼UI 사라지는 이슈 수정(Leehj) 23.01.10
    "&:active, &:focus": {
      color: LightColors.primary["2"],
    },
  },
  blockedRemoveBtnDiv: {
    padding: theme.spacing(0, 1, 0, 1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 1.5),
      minWidth: 48,
    },
  },
  eventName: {
    marginLeft: 7,
    marginTop: 1,
  },
}));

interface SharedEventListItemProps {
  event: EventVideo;
  onRemove: (event: EventVideo) => void;
  onPlay: (event: EventVideo) => void;
}

export const SharedEventListItem = ({
  event,
  onRemove,
  onPlay,
}: SharedEventListItemProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const galaxyS5Size = useMediaQuery(theme.breakpoints.down(361));

  const [hover, setHover] = useState(false);

  const [date, eventType, fileType, thumb, hashtags] = useMemo(() => {
    const names = event.filename.split("_");

    let mode = MTYPE_TO_ECODE[event.type] as EventCode;
    let fileType = "Event" as FileTypeName;

    // Parking impact이벤트영상은 공유리스트 chip - Event로 표시하도록 수정 (Leehj) 23.01.12
    if (event.mode === "P" && event.type !== 0) {
      mode = "I";
    }
    if (event.mode === "P" && event.type === 0) {
      fileType = "Parking";
    }
    if (event.mode === "M") {
      mode = "M";
      fileType = "Manual";
    }
    // My Shared Vidoes목록에서 normal 및 parking이벤트 chip표시 못하고 있는 이슈 수정 (Leehj) 23.01.10
    if (event.mode === "N") {
      fileType = "Normal";
    }

    return [
      moment.utc(`${names[0]} ${names[1]}`, "YYYYMMDD HH:mm:ss"),
      mode,
      fileType,
      event.thumb,
      event.hashtags,
    ];
  }, [event]);

  // uxui 이벤트 리스트에서 사라진 부분으로 인한 주석처리(Leehj)
  // const pendingMarkup = useMemo(() => {
  //   return (
  //     <div className={classes.shareStatusDiv}>
  //       <svg
  //         width="14"
  //         height="14"
  //         viewBox="0 0 14 14"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <circle
  //           cx="7"
  //           cy="7"
  //           r="5"
  //           fill="#D81A26"
  //           stroke="#F7D5D7"
  //           strokeWidth="4"
  //         />
  //       </svg>
  //       <Typography
  //         category="Default"
  //         variant="Caption"
  //         htmlColor={LightColors.secondary["11"]}
  //       >
  //         {t("Share pending")}
  //       </Typography>
  //     </div>
  //   );
  // }, [classes.shareStatusDiv, t]);

  // const sharedMarkup = useMemo(() => {
  //   return (
  //     <div className={classes.shareStatusDiv}>
  //       <svg
  //         width="14"
  //         height="14"
  //         viewBox="0 0 14 14"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <circle
  //           cx="7"
  //           cy="7"
  //           r="5"
  //           fill="#00AA64"
  //           stroke="#D0EFE2"
  //           strokeWidth="4"
  //         />
  //       </svg>

  //       <Typography
  //         category="Default"
  //         variant="Caption"
  //         htmlColor={LightColors.secondary["15"]}
  //       >
  //         {t("Shared")}
  //       </Typography>
  //     </div>
  //   );
  // }, [classes.shareStatusDiv, t]);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      style={{ position: "relative" }}
      //  mantis - 9344, 공유된 영상 선택 후 취소 시 삭제 아이콘 생성 되지 않던 이슈 수정(Leehj)
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={
        event.blocked
          ? undefined
          : () => {
              if (event.video) {
                onPlay(event);
              }
            }
      }
    >
      {event.blocked && (
        <div className={clsx(classes.root, classes.blockedRoot)}>
          <div className={clsx(classes.container, classes.blockedContainer)}>
            <div className={classes.blockedInfo}>
              <div className={classes.reportIconDiv}>
                <InfoIcon className={classes.blockedIcon} />
              </div>

              <div className={clsx(classes.blockedTextDiv)}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                  dangerouslySetInnerHTML={{
                    __html: t("This video has been reported_bold"),
                  }}
                ></Typography>
              </div>

              <div
                className={clsx(
                  classes.removeBtnDiv,
                  classes.blockedRemoveBtnDiv
                )}
              >
                {(hover || mobile) && (
                  <IconButton
                    className={classes.blockedIcon}
                    onClick={() => {
                      onRemove(event);
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={clsx(classes.root, {
          [classes.hoverRoot]: hover && !event.blocked,
        })}
      >
        <div
          className={clsx(classes.container, {
            [classes.galaxyS5SizeContainer]: galaxyS5Size,
          })}
        >
          <div className={classes.dateTimeDiv}>
            {/* mantis-8550 */}
            {/* 모바일 화면에서 시간 영역 2줄을 1줄로 만들기 위해서 style 적용 */}
            <Typography
              category="Default"
              variant="SmallBold"
              style={{ whiteSpace: "nowrap" }}
            >
              {date.format("YYYY-MM-DD")}
            </Typography>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["3"]}
              style={{ marginTop: 2 }}
            >
              {date.format("HH:mm:ss")}
            </Typography>
          </div>

          <div
            className={clsx(classes.eventDetailDiv, {
              [classes.hoverEventDetailDiv]: hover,
            })}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="event-thumbnail"
                src={thumb}
                // 썸네일의 에러가 있는 경우 파란 차 이미지출력(Leehj)
                onError={(e: any) => {
                  e.target.src = "/images/default-event-thumb.png";
                }}
                style={{
                  width: 96,
                  height: 54,
                  borderRadius: 8,
                  marginRight: 15,
                  marginTop: 3,
                }}
              />
            </div>

            <div className={classes.eventDetailInfo}>
              <div
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  // mantis - 11046, 아이폰se 해시태그가 3개 이상 길어졌을 때 삭제버튼 밀리는 이슈 수정 (Leehj)
                  maxWidth: galaxyS5Size ? 148 : 189,
                }}
              >
                <Typography
                  category="Default"
                  variant="SmallBold"
                  // 해시태그없을 경우를 위해 height값 넣어줌(Leehj)
                  style={{
                    marginBottom: 1,
                    marginLeft: 1,
                    height: 21,
                  }}
                >
                  {_.map(hashtags, (tag) => `${tag.name} `)}
                </Typography>
              </div>

              <div style={{ display: "flex", marginBottom: 4 }}>
                <FileTypeContainer
                  // mantis - 8454, eventType이 M인경우 Manual아이콘 표시되도록 수정
                  fileType={fileType}
                  // mantis-8550
                  // style 속성 추가하여 width: 190px 추가함
                  // style={{ width: "190px" }}
                  eventCode={eventType}
                  isAI={_.includes(AI_EVENTS, eventType)}
                  eventNameClassName={classes.eventName}
                  t={t}
                />
              </div>

              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["3"]}
                style={{ marginLeft: 4 }}
              >
                Exp.{" "}
                {moment(date).add(365, "day").format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </div>
          </div>
        </div>
        {!event.blocked && (
          <div
            className={classes.removeBtnDiv}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(event);
            }}
          >
            {(hover || mobile) && (
              <IconButton className={classes.removeBtn} onClick={() => {}}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
