import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import Input from "@thingsw/pitta-design-system/dist/components/Input";

import HelpIcon from "@material-ui/icons/Help";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
import SearchIcon from "@material-ui/icons/Search";
import { AddCameraGuideModal } from "../modals/AddCameraGuideModal";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { PAYMENT } from "../../features/Payment/slice";
import { CAMERA } from "../../features/Camera/slice";
import { SubMgrModal } from "../accounts/SubMgrModal";
import { Webviewer, LightColors } from "@thingsw/pitta-modules";
import { getPlanFromServiceID } from "../../utils/Service";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  subHeaderDiv: {
    display: "flex",
    width: "100%",
  },
  subHeaderDivOpen: {
    ...(theme.direction === "rtl" ? { right: 235 } : { left: 235 }),
  },
  subHeaderMobile: {
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 116,
    padding: theme.spacing(2, 2),

    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      height: 56,
      width: "100%",
      padding: "16px 12px 16px 19px",
    },
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "fit-content",
    },
  },
  dotText: {
    margin: "0 3px",
  },
  directionRow: {
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 21 } : { marginRight: 21 }),
    },
  },
  searchBtnOpen: {
    width: "100%",
    marginRight: 0,
  },
  btnGroup: {
    width: "fit-content",
  },
  hide: {
    display: "none",
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
}));

interface CameraSubHeaderProps {
  openMenu: boolean;
  webSearchKey: string;
  onSearchKey: (search?: string) => void;
  onModeChange: (mode: "map" | "list") => void;
  mode: "map" | "list";
}

export const CameraSubHeader = ({
  openMenu,
  webSearchKey,
  onSearchKey,
  onModeChange,
  mode,
}: CameraSubHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { userProfile } = useSelector((state: RootState) => state[USER]);
  const { subscriptionInfo, webSubscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

  const [openSearch, setOpenSearch] = useState(false);
  const [nReal, setNReal] = useState(0);
  const [nSlot, setNSlot] = useState(0);

  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [openCancelFleetModal, setOpenCancelFleetModal] = useState(false);

  useEffect(() => {
    if (subscriptionInfo) {
      setNSlot(subscriptionInfo.cameraLimit);
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (cameraList) {
      setNReal(cameraList.deviceCount ?? 0);
    }
  }, [cameraList]);

  const handleScreenTypeChange = useCallback(
    (value: number) => {
      if (value === 0) {
        onModeChange("map");
      } else if (value === 1) {
        onModeChange("list");
      }
    },
    [onModeChange]
  );

  // 카메라 검색아이콘 출력 plan확인 (Leehj)
  const isSearchPlan = useMemo(() => {
    if (
      subscriptionInfo &&
      // 카메라 1대이상일 경우만 노출
      cameraList &&
      cameraList.deviceListInfo &&
      cameraList.deviceListInfo.length >= 1
    ) {
      switch (getPlanFromServiceID(subscriptionInfo.servicePlanID)) {
        case "Fleet plan":
          if (_.includes(["Master"], userProfile?.userType)) {
            return true;
          }
          break;
        //  Free, Lite, smart계정 Camera list 검색 버튼 노출 (Leehj)
        case "Free plan":
        case "Lite plan":
        case "Smart plan":
          return true;
        default:
          return false;
      }
    }
  }, [cameraList, subscriptionInfo, userProfile?.userType]);

  const B2BFleetOrPlus = useMemo(() => {
    return (
      subscriptionInfo &&
      (subscriptionInfo?.servicePlanID === 1001 ||
        subscriptionInfo?.servicePlanID === 1998 ||
        subscriptionInfo?.servicePlanID === 1999 ||
        (subscriptionInfo?.servicePlanID >= 4001 &&
          subscriptionInfo?.servicePlanID <= 4029) ||
        subscriptionInfo?.servicePlanID === 1999 ||
        (subscriptionInfo?.servicePlanID >= 30001 &&
          subscriptionInfo?.servicePlanID <= 30999) ||
        (subscriptionInfo.servicePlanID >= 50001 &&
          subscriptionInfo.servicePlanID <= 50999) ||
        (subscriptionInfo.servicePlanID >= 20001 &&
          subscriptionInfo.servicePlanID <= 20999))
    );
  }, [subscriptionInfo]);

  const manageBtnMarkup = useMemo(() => {
    if (
      userProfile?.userType === "Master" ||
      // mantis - 10435 admin계정도 관리항목 나오도록 수정 (Leehj)
      userProfile?.userType === "SubMaster" ||
      userProfile?.userType === "User" ||
      // free, lite plan도 The number of Cloud cameras 출력되도록 수정 (Leehj)
      (subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
          "Smart plan") ||
      // mantis - 12187, lite plan 카메라수 출력안되는 이슈 수정 (Leehj)
      (subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Lite plan") ||
      (subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Free plan")
    ) {
      const needToCancel =
        subscriptionInfo &&
        subscriptionInfo.servicePlanID >= 4001 &&
        subscriptionInfo.servicePlanID <= 4029;
      return (
        <div>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            {t("n cameras", { n: nReal })} / {t("n slots", { n: nSlot })}
          </Typography>
          {/* owner에서만 출력되도록 수정 - lite신규요금제 기획서참고 (Leehj) */}
          {B2BFleetOrPlus &&
            userProfile?.userType === "Master" &&
            ((webSubscriptionInfo && !webSubscriptionInfo.upcomming.canceled) ||
              // mantis - 11818, stripe로 결제하지 않은 경우 && paymentMethod === "B2B Tester"인 경우에도 노출 (Leehj)
              (subscriptionInfo &&
                subscriptionInfo.paymentMethod === "B2B Tester")) && (
              <>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                  className={classes.dotText}
                >
                  ·
                </Typography>

                <Typography
                  category="Default"
                  variant="Body"
                  onClick={() => {
                    if (needToCancel) {
                      setOpenCancelFleetModal(true);
                    } else {
                      setOpenManageModal(true);
                    }
                  }}
                  color="primary"
                  style={{ cursor: "pointer" }}
                  htmlColor={LightColors.primary["7"]}
                >
                  {t("Manage")}
                </Typography>
              </>
            )}
        </div>
      );
    }
  }, [
    B2BFleetOrPlus,
    classes.dotText,
    nReal,
    nSlot,
    subscriptionInfo,
    t,
    userProfile?.userType,
    webSubscriptionInfo,
  ]);

  return (
    <div
      className={clsx(classes.subHeaderDiv, {
        [classes.subHeaderMobile]: mobile,
        [classes.subHeaderDivOpen]: openMenu,
      })}
    >
      <div className={classes.subHeader}>
        <div className={clsx(classes.btnDiv)}>
          {manageBtnMarkup}

          <Tooltip
            blueTooltip
            disableTouchListener={mobile}
            title={
              <Typography category="Default" variant="Caption">
                {t("How to add camera")}
              </Typography>
            }
            placement="bottom"
          >
            <IconButton
              style={{ marginInlineStart: 13 }}
              onClick={() => setOpenGuideModal(true)}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={clsx(classes.btnDiv, {
            [classes.directionRow]: mobile,
            [classes.justifyBetween]: mobile && mode === "list",
          })}
        >
          {mode === "list" && isSearchPlan && (
            <Input
              placeholder={t("search")}
              startIcon={<SearchIcon />}
              className={clsx(classes.searchBtn, {
                [classes.searchBtnOpen]: mobile && openSearch,
              })}
              search
              dense
              value={webSearchKey}
              onChange={(e) => onSearchKey(e.target.value)}
              onOpenSearch={() => setOpenSearch(true)}
              onCloseSearch={() => {
                setOpenSearch(false);
                // mantis - 12714, x 클릭시 검색내용 초기화 적용 (Leehj)
                onSearchKey("");
              }}
              inputID="cameraSearch"
            />
          )}
          <ButtonGroup
            value={mode === "map" ? 0 : 1}
            onChange={handleScreenTypeChange}
            className={clsx(classes.btnGroup, {
              [classes.hide]: mobile && openSearch,
            })}
          >
            <Button startIcon={<MapIcon />}>{t("Map")}</Button>
            <Button startIcon={<ListIcon />}>{t("List")}</Button>
          </ButtonGroup>
        </div>
      </div>

      <AddCameraGuideModal
        open={openGuideModal}
        fullsize={mobile}
        onClose={() => setOpenGuideModal(false)}
        mode="cloud"
      />
      {openManageModal && (
        <SubMgrModal
          open={openManageModal}
          onClose={() => setOpenManageModal(false)}
          onClickNegative={() => setOpenManageModal(false)}
          onClickPositive={() => {
            setOpenManageModal(false);
          }}
        />
      )}
      <Modal
        open={openCancelFleetModal}
        close
        onClose={() => setOpenCancelFleetModal(false)}
        heading={t("Cancel your inapp_")}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: t("You have cancel_").replace(
                // eslint-disable-next-line no-control-regex
                new RegExp("\n", "g"),
                "<br/>"
              ),
            }}
          />
        }
        RButton={t("OK")}
        actionClassName={classes.modalBottom}
        className={classes.modalWrap}
      />
    </div>
  );
};
