export function getTextHeight(
  text: string,
  classNames: string[],
  offset: number = 0
): number {
  const measure = document.getElementById("measure-layer");
  const container = document.createElement("div");
  for (let className of classNames) {
    container.classList.add(className);
  }

  measure?.appendChild(container);
  const element = document.createElement("div");
  element.innerHTML = text;
  container?.appendChild(element);
  const height = element.offsetHeight;
  measure?.removeChild(container);
  return height - offset;
}
