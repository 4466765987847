import { makeStyles, Theme } from "@material-ui/core";
import { Sliders, Typography } from "@thingsw/pitta-design-system";
import { LightColors } from "@thingsw/pitta-modules";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  sliderDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0 0px 0 13px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 0px 0 11px",
    },
  },

  maxDiv: {
    marginTop: 4,
    marginLeft: 14,
    width: 12,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },

  minDiv: {
    marginTop: 7,
    marginRight: 6,
    width: 16,
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {},
  },
  railClasses: {},
}));

interface FSliderProps {
  max: number;
  min: number;
  value: number;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
  // class 추가
  rootClasses?: string;
  railClasses?: string;
  sliderClasses?: string;
  sliderMinClasses?: string;
  trackClasses?: string;
}

export const SettingSlider = (props: FSliderProps) => {
  const {
    max,
    min,
    value,
    disabled,
    onChange,
    rootClasses,
    railClasses,
    sliderClasses,
    sliderMinClasses,
    trackClasses,
  } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.sliderDiv, sliderClasses)}>
      <div className={clsx(classes.minDiv, sliderMinClasses)}>
        <Typography
          category="Default"
          variant="Caption"
          // mantis - 8717
          // Reckless driving 체크 해제시 Sliders 해제 색상처럼 변하도록 htmlColor 수정
          htmlColor={
            disabled ? LightColors.primary["4"] : LightColors.primary["2"]
          }
        >
          {min}
        </Typography>
      </div>

      <Sliders
        setting
        // 툴팁 없는 디자인으로 변경되어 적용 및 class추가
        noTooltip
        rootClasses={rootClasses}
        railClasses={railClasses}
        trackClasses={trackClasses}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <div className={classes.maxDiv}>
        <Typography
          category="Default"
          variant="Caption"
          // mantis - 8717
          // Reckless driving 체크 해제시 Sliders 해제 색상처럼 변하도록 htmlColor 수정
          htmlColor={
            disabled ? LightColors.primary["4"] : LightColors.primary["2"]
          }
        >
          {max}
        </Typography>
      </div>
    </div>
  );
};
