import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../features/store";
import { openToast } from "../../features/Toast/slice";
import { USER } from "../../features/User/slice";
import {
  ICameraInfo,
  jwtAxiosInst,
  LightColors,
  Webviewer,
} from "@thingsw/pitta-modules";
import React from "react";

interface RemoveCloudModalProps {
  open: boolean;
  camera?: ICameraInfo;
  onClose: () => void;
  onSuccess: () => void;
  mode: "remove" | "delete";
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 15,
  },
  titleDiv: {
    padding: "25px 16px 0 16px",
    minHeight: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "25px 44px 0 24px",
    },
  },
  contentDiv: {
    padding: "12px 16px 32px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "15px 24px 32px",
    },
  },
  mobileBtnDiv: {
    padding: "0px 16px 24px",
    // mantis 10496 모바일 일 때 버튼 가운데로 이동(Leehj)
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-end",
    },
  },
  blueText: {
    marginTop: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 3,
    },
  },
  btnStyle: {
    minWidth: 151.5,
  },
}));

export const RemoveCloudModal = ({
  open,
  camera,
  onClose,
  onSuccess,
  mode,
}: RemoveCloudModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );

  const [loading, setLoading] = useState(false);

  const handleRemove = useCallback(async () => {
    if (camera) {
      try {
        setLoading(true);

        const resp = await jwtAxiosInst.delete("/DashCam/DeviceList", {
          data: {
            email,
            user_token: loginInfo?.user_token,
            psn: camera.psn,
            tokenType,
            regCategory: camera.reg_category || "cloud",
          },
        });
        if (resp.data.resultcode === "BC_ERR_OK") {
          onSuccess();
        } else {
          dispatch(openToast({ message: "Failed to remove from cloud." }));
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  }, [camera, dispatch, email, loginInfo?.user_token, onSuccess, tokenType]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      close={!mobile}
      heading={t(mode === "delete" ? "Delete" : "Remove from cloud")}
      titleClassName={classes.titleDiv}
      contentClassName={classes.contentDiv}
      actionClassName={clsx({
        [classes.mobileBtnDiv]: mobile,
      })}
      className={classes.root}
      LButtonClassName={clsx(mobile && classes.btnStyle)}
      RButtonClassName={clsx(mobile && classes.btnStyle)}
      content={
        <>
          <Typography
            category="Default"
            variant="Body"
            style={{ marginBottom: 2 }}
          >
            {t(
              mode === "delete"
                ? "Are you sure_camera"
                : "Are you sure you want to Remove_"
            )}
          </Typography>
          <br />
          <br />
          <div className={classes.blueText}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={
                mode === "delete"
                  ? LightColors.secondary["12"]
                  : LightColors.primary["8"]
              }
            >
              {t(
                mode === "delete"
                  ? "You can restore the data if_"
                  : "Remove: It doesn’t mean_"
              )}
            </Typography>
          </div>
          {camera?.battery && (
            <>
              <br />
              <Typography category="Default" variant="Small">
                {t("The paired battery_")}
              </Typography>
            </>
          )}
        </>
      }
      RButton={t(mode === "delete" ? "Delete" : "Remove")}
      LButton={t("NO")}
      onClickNegative={onClose}
      onClickPositive={handleRemove}
      loading={loading}
      Secondary={mode === "delete"}
    />
  );
};
