import React, { useEffect, useState } from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, Tooltip, Typography } from "@thingsw/pitta-design-system";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { loadUserProfile, USER } from "../features/User/slice";
import { AccountProfilePanel } from "../components/accounts/AccountProfilePanel";
import { SubscriptionPanel } from "../components/accounts/SubscriptionPanel";
import { SettingsPanel } from "../components/accounts/SettingsPanel";
import { UsagePanel } from "../components/accounts/UsagePanel";
import { useHistory } from "react-router-dom";
import { RootState } from "../features/store";
import { Webviewer, LightColors } from "@thingsw/pitta-modules";

export type AccountScreenMode =
  | "profile"
  | "subscription"
  | "settings"
  | "usage";

export type subMode = "Fleet" | "Free" | "Smart";

const AccountScreenModeToIndex: { [key in AccountScreenMode]: number } = {
  profile: 0,
  subscription: 1,
  settings: 2,
  usage: 3,
};

interface AccountScreenProps {
  openMenu?: boolean;
  error?: string;
  mode: AccountScreenMode;
  sub: subMode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "calc(var(--vh, 1vh) * 100 - 56px - 52px)",
    marginTop: 52 + 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: AccountScreenProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 52px)"
          : "calc(var(--vh, 1vh) * 100 - 56px - 52px)",
      margin: theme.spacing(5, 4, 2, 4),
      marginTop: (props: AccountScreenProps) =>
        props.error ? 52 + 85 : 52 + 56,
    },
  },
  tabDiv: {
    overflowX: "auto",
    backgroundColor: LightColors.primary["0"],
    top: (props: AccountScreenProps) => (props.error ? 85 : 56),
    ...(theme.direction === "rtl"
      ? { left: 0, right: 0 }
      : { left: 235, right: 0 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(0, 4),
      ...(theme.direction === "rtl"
        ? { left: 0, right: 235 }
        : { left: 235, right: 0 }),
    },
    position: "fixed",
    zIndex: 1,
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tabDivClosed: {
    left: 73,
  },
  tabDivMobile: {
    left: 0,
  },
  tab: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: 540,
    height: 52,
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
    alignItems: "flex-end",
  },
  tabItem: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: 6,
  },
  body: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    overflowY: "auto",
    // padding: theme.spacing(0, 16, 24),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
      overflowY: "unset",
    },
  },
  input: {
    width: 30,
    height: 30,
    opacity: 0,
  },
  freeUser: {},
}));

export const AccountScreen = (props: AccountScreenProps) => {
  const { mode, openMenu } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const history = useHistory();

  const [value, setValue] = useState(AccountScreenModeToIndex[mode]);
  const [openSubTooltip, setOpenSubTooltip] = React.useState(false);
  const [openUsageTooltip, setOpenUsageTooltip] = React.useState(false);

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const loginInfo = useSelector((state: RootState) => state[USER].loginInfo);

  useEffect(() => {
    dispatch(loadUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setValue(AccountScreenModeToIndex[mode]);
  }, [mode]);

  const handleSubHover = () => {
    if (loginInfo?.userType === "User" || loginInfo?.userType === "SubMaster") {
      setOpenSubTooltip(true);
    }
  };

  const handleUsageHover = () => {
    if (loginInfo?.userType === "User" || loginInfo?.userType === "SubMaster") {
      setOpenUsageTooltip(true);
    }
  };

  return (
    <div className={classes.root} dir={theme.direction}>
      <div
        className={clsx(classes.tabDiv, {
          [classes.tabDivClosed]: !openMenu,
          [classes.tabDivMobile]: mobile,
        })}
      >
        <div className={classes.tab}>
          <Tabs value={value}>
            <Tab
              className={clsx({ [classes.tabItem]: mode === "profile" })}
              onClick={() => {
                history.push("/account/profile");
              }}
              label={t("Profile")}
            />
            <Tooltip
              open={openSubTooltip}
              title={
                <Typography category="Default" variant="Caption">
                  {t("Available to master")}
                </Typography>
              }
              placement="top"
            >
              <div
                onMouseOver={handleSubHover}
                onMouseOut={() => {
                  setOpenSubTooltip(false);
                }}
              >
                <Tab
                  disabled={
                    loginInfo?.userType === "User" ||
                    loginInfo?.userType === "SubMaster"
                  }
                  className={clsx({
                    [classes.tabItem]: mode === "subscription",
                  })}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    history.push("/account/subscription");
                  }}
                  label={t("Subscription")}
                />
              </div>
            </Tooltip>

            <Tab
              className={clsx({ [classes.tabItem]: mode === "settings" })}
              // style={{ width: 96 }}
              onClick={() => {
                history.push("/account/settings");
              }}
              label={t("Settings")}
            />

            <Tooltip
              open={openUsageTooltip}
              title={
                <Typography category="Default" variant="Caption">
                  {t("Available to master")}
                </Typography>
              }
              placement="top"
            >
              <div
                onMouseOver={handleUsageHover}
                onMouseOut={() => {
                  setOpenUsageTooltip(false);
                }}
              >
                <Tab
                  disabled={
                    loginInfo?.userType === "User" ||
                    loginInfo?.userType === "SubMaster"
                  }
                  className={clsx({ [classes.tabItem]: mode === "usage" })}
                  onClick={() => {
                    history.push("/account/usage");
                  }}
                  label={t("Usage")}
                />
              </div>
            </Tooltip>
          </Tabs>
        </div>
      </div>
      <div className={classes.body}>
        {mode === "profile" && <AccountProfilePanel />}
        {mode === "subscription" && <SubscriptionPanel />}
        {mode === "settings" && <SettingsPanel />}
        {mode === "usage" && <UsagePanel />}
      </div>
    </div>
  );
};
