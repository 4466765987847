import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IGroupState,
  IDashCamList,
  IGroupList,
  IChangeGroupName,
  IGroupUserList,
  IGroupAddDelCamera,
  IAddUserFromCam,
  IDelUserFromCam,
  IAddDelAdmin,
  IGroupNameForm,
  ListPaging,
  ROWS_PER_PAGE,
} from "@thingsw/pitta-modules";

let initialState: IGroupState = {
  loading: false,
  groupsList: undefined,
  usersList: undefined,
  groupsListPaging: undefined,
  selected: [],
  allChecked: "none",
  listPaging: {
    startIndex: 1,
    endIndex: ROWS_PER_PAGE,
    ordering: 0,
  },
};

const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    clearGroup: (state) => {
      state.loading = false;
      state.groupsList = undefined;
      state.usersList = undefined;
      state.groupsListPaging = undefined;
      state.type = undefined;
      state.selected = undefined;
      state.allChecked = undefined;
      state.listPaging = {
        startIndex: 1,
        endIndex: ROWS_PER_PAGE,
        ordering: 0,
      };
    },
    loadGroups: (state) => {
      state.loading = true;
    },
    successLoadGroups: (state, { payload }: PayloadAction<IDashCamList>) => {
      state.loading = false;
      state.groupsList = payload;
    },
    loadGroupsPaging: (state, { payload }: PayloadAction<ListPaging>) => {
      state.loading = true;
      state.listPaging = payload;
    },
    successLoadGroupsPaging: (
      state,
      { payload }: PayloadAction<IGroupList>
    ) => {
      state.loading = false;
      state.groupsListPaging = payload;
    },
    failLoad: (state) => {
      state.loading = false;
    },
    addGroup: (state, _action: PayloadAction<IGroupNameForm>) => {
      state.loading = true;
    },
    successAdd: (state) => {
      state.loading = false;
    },
    changeGroupName: (state, action: PayloadAction<IChangeGroupName>) => {
      state.loading = true;
      state.type = action.type;
    },
    successChange: (state) => {
      state.loading = false;
      state.type = undefined;
    },
    deleteGroup: (state, action: PayloadAction<string[]>) => {
      state.loading = true;
      state.type = action.type;
    },
    loadGroupUser: (state, action: PayloadAction<undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGroupsUser: (
      state,
      { payload }: PayloadAction<IGroupUserList>
    ) => {
      state.loading = false;
      state.usersList = payload;
    },
    groupAddCameras: (state, action: PayloadAction<IGroupAddDelCamera[]>) => {
      state.loading = true;
      state.type = action.type;
    },
    deleteCamera: (state, action: PayloadAction<IGroupAddDelCamera[]>) => {
      state.loading = true;
      state.type = action.type;
    },
    addUser: (state, action: PayloadAction<IAddUserFromCam>) => {
      state.loading = true;
      state.type = action.type;
    },
    deleteUser: (state, action: PayloadAction<IDelUserFromCam>) => {
      state.loading = true;
      state.type = action.type;
    },
    success: (state) => {
      state.loading = false;
    },
    deleteAdmin: (state, action: PayloadAction<IAddDelAdmin>) => {
      state.loading = true;
      state.type = action.type;
    },
    addAdmin: (state, action: PayloadAction<IAddDelAdmin>) => {
      state.loading = true;
      state.type = action.type;
    },
    clearCheckBox: (state) => {
      state.selected = [];
      state.allChecked = "none";
    },
    clearLoading: (state) => {
      state.loading = false;
      state.type = undefined;
    },
  },
});

export const {
  clearGroup,
  addGroup,
  successAdd,
  loadGroups,
  successLoadGroups,
  loadGroupsPaging,
  successLoadGroupsPaging,
  failLoad,
  changeGroupName,
  successChange,
  deleteGroup,
  groupAddCameras,
  loadGroupUser,
  deleteCamera,
  addUser,
  successLoadGroupsUser,
  deleteUser,
  success,
  deleteAdmin,
  addAdmin,
  clearCheckBox,
  clearLoading,
} = slice.actions;

export const GROUP = slice.name;
export default slice.reducer;
