import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  AddCameraRequest,
  ICameraCluster,
  ICameraInfo,
  ICameraList,
  ICameraState,
  IDeleteEmail,
  IEmailInvite,
  IEmailNotiSettings,
  IFirmware,
  IFirmwareConfig,
  IFirmwareSettings,
  IFotaIDPayload,
  IFwUpdateInfo,
  ILatLngBounds,
  ILiveEventSettings,
  IMasterEmailNotification,
  INotificationEmailList,
  INotificationSettings,
  IPrivacy,
  IPublicCamera,
  IPublicCameraOrig,
  OnOffType,
} from "@thingsw/pitta-modules";

let initialState: ICameraState = {
  loading: false,
  camera: undefined,
  publicCamera: undefined,
  cameraList: undefined,
  fwUpdateInfos: [],
  firmwares: [],
  emailList: undefined,
  publicCams: [],
  isLocationLoaded: false,
  cameraError: undefined,
  groups: [],
  fotaFailed: false,
  cameraTimeSync: false,
};

const slice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    setSharedEventMap: (
      state,
      { payload }: PayloadAction<OnOffType | undefined | null>
    ) => {
      state.sharedEventMap = payload;
    },
    setCameraError: (state, { payload }: PayloadAction<string>) => {
      state.cameraError = payload;
    },
    clearCameraError: (state) => {
      state.cameraError = undefined;
    },
    clearCamera: (state) => {
      state.camera = undefined;
    },
    resetCamera: (state) => {
      state.loading = false;
      state.type = undefined;
      state.camera = undefined;
      state.cameraList = undefined;
      state.camReg = undefined;
      state.fwUpdateInfos = [];
      state.notificationSettings = undefined;
      state.EmailNotiSettings = undefined;
      state.liveEventSettings = undefined;
      state.firmwares = [];
      state.firmware = undefined;
      state.publicCamera = undefined;
      state.publicCams = [];
      // state.cloud = undefined;
    },
    addCamera: (state, action: PayloadAction<AddCameraRequest>) => {
      state.loading = true;
      state.type = action.type;
    },
    renameCamera: (
      state,
      action: PayloadAction<{
        psn: string;
        devName: string;
        // mantis - 11277, api변경하면서 regCategory 추가 (Leehj)
        regCategory: string;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    deleteCamera: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    loadCamera: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadCamera: (state, { payload }: PayloadAction<ICameraInfo>) => {
      state.loading = false;
      state.camera = payload;
    },
    loadCameras: (state, action: PayloadAction<undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    updateCameras: (state, action: PayloadAction<undefined>) => {
      state.type = action.type;
    },
    successLoadCameras: (state, { payload }: PayloadAction<ICameraList>) => {
      state.loading = false;
      state.groups = _.chain(payload.deviceListInfo)
        .groupBy((dev) => dev.groupName)
        .map((values, key) => {
          console.log("key", key, "values", values);
          const { groupManagementID, groupName, groupRegDT } = values[0];
          return {
            groupManagementID: groupManagementID ?? "",
            groupName: groupName ?? "",
            groupRegDT: groupRegDT ?? "",
            cameras: _.map(values, (dev) => dev.device),
          };
        })
        .value();

      state.cameraList = {
        ...payload,
        deviceListInfo: _.chain(payload.deviceListInfo)
          .filter((devInfo) => !!devInfo.device?.psn)
          .map((devInfo) => {
            // 9774 - 저장되어 있던 좌표값이 있으면 유지할 수 있도록 수정
            const dev = _.find(
              state.cameraList?.deviceListInfo,
              (pd) => pd.device.psn === devInfo.device.psn
            )?.device;
            return {
              ...devInfo,
              device: {
                ...dev,
                ...devInfo.device,
                mode: devInfo.device.mode?.toString(),
                // mantis - 10091, 카메라이름 변경 후 모달 재진입 시 변경한 이름으로 출력되도록 수정(Leehj)
                dev_name: devInfo.device.dev_name,
                // mantis - 10094, privacy setting 설정 변경 후 변경된 내용 바로 적용되도록 수정(Leehj)
                agree_gps: devInfo.device.agree_gps,
                share_gps: devInfo.device.share_gps,
                share_video: devInfo.device.share_video,
                share_audio: devInfo.device.share_audio,
                share_dev_name: devInfo.device.share_dev_name,
                share_leu_bigdata: devInfo.device.share_leu_bigdata,
                battery: devInfo.device.battery,
              },
            };
          })
          .value(),
      };
    },
    successUpdateCameras: (state, { payload }: PayloadAction<ICameraList>) => {
      state.loading = false;
      if (state.cameraList) {
        _.map(state.cameraList.deviceListInfo, (dev) => dev.device.psn);
        state.cameraList = {
          ...state.cameraList,
          deviceListInfo: _.map(state.cameraList.deviceListInfo, (devInfo) => {
            const dev = _.find(
              payload.deviceListInfo,
              (pd) => pd.device.psn === devInfo.device.psn
            )?.device;

            return {
              ...devInfo,
              device: {
                ...devInfo.device,
                ...dev,
                mode: dev?.mode?.toString() || devInfo.device.mode,
              },
            };
          }),
        };
      }
    },
    // loadCamReg: (state) => {},
    // successLoadCamReg: (state, { payload }: PayloadAction<ICamRegInfo>) => {
    //   state.camReg = payload;
    // },
    failLoad: (state) => {
      state.loading = false;
    },
    loadMyCemrasLocation: (
      state,
      _action: PayloadAction<{
        bounds?: ILatLngBounds;
        cancel: AbortController;
      }>
    ) => {},
    successLoadCameraCluster: (
      state,
      { payload }: PayloadAction<ICameraCluster | undefined>
    ) => {
      state.clusters = payload;
    },
    successLoadCameraLocation: (
      state,
      { payload }: PayloadAction<{ cams: ICameraInfo[]; clearCam?: boolean }>
    ) => {
      // console.log("successLoadCameraLocation", payload);
      if (state.cameraList) {
        state.cameraList = {
          ...state.cameraList,
          deviceListInfo: _.map(state.cameraList.deviceListInfo, (devInfo) => {
            let dev = _.find(
              payload.cams,
              (pd) =>
                pd.psn === devInfo.device.psn &&
                devInfo.device.reg_category !== "wifi"
            );
            if (dev && dev.latitude && dev.longitude) {
              const lat = parseFloat(dev.latitude);
              const lng = parseFloat(dev.longitude);
              if ((lat === 0 && lng === 0) || isNaN(lat) || isNaN(lng)) {
                dev = undefined;
              }
            }
            if (!dev && payload.clearCam) {
              devInfo.device.latitude = undefined;
              devInfo.device.longitude = undefined;
            }

            return {
              ...devInfo,
              device: {
                ...devInfo.device,
                ..._.omit(dev, ["profile_img"]),
              },
            };
          }),
        };
        const psns = _.chain(state.cameraList.deviceListInfo)
          .filter((dev) => dev.device.reg_category !== "wifi")
          .map((dev) => dev.device.psn)
          .value();
        const pubs = _.filter(
          payload.cams,
          (p) => !_.includes(psns, p.psn)
        ).map((p) => {
          const info = p as IPublicCameraOrig;
          const { latitude, longitude, ...rest } = info;
          return {
            ...rest,
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          };
        });
        state.publicCams = _.unionBy(pubs, state.publicCams, "psn");
        // console.log("aaaa", state.publicCams);
        state.isLocationLoaded = true;
      }
    },
    successLoadPublicCameraLocation: (
      state,
      { payload }: PayloadAction<IPublicCamera[]>
    ) => {
      // console.log("successLoadPublicCameraLocation", payload);
      state.publicCams = _.unionBy(payload, state.publicCams, "psn");
      // state.publicCams = payload;
    },
    loadPrivacy: (state, { payload }: PayloadAction<string>) => {},
    successLoadPrivacy: (state, { payload }: PayloadAction<IPrivacy>) => {
      state.privacy = payload;
    },
    updatePrivacy: (
      state,
      action: PayloadAction<{
        psn: string;
        agree_gps: OnOffType;
        share_gps: OnOffType;
        share_video: OnOffType;
        share_audio: OnOffType;
        share_dev_name: OnOffType;
        share_leu_bigdata: OnOffType;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successUpdatePrivacy: (
      state,
      {
        payload: {
          psn,
          agree_gps,
          share_audio,
          share_dev_name,
          share_gps,
          share_video,
          share_leu_bigdata,
        },
      }: PayloadAction<{
        psn: string;
        agree_gps: OnOffType;
        share_gps: OnOffType;
        share_video: OnOffType;
        share_audio: OnOffType;
        share_dev_name: OnOffType;
        share_leu_bigdata: OnOffType;
      }>
    ) => {
      state.cameraList = {
        ...state.cameraList,
        deviceListInfo: _.map(state.cameraList?.deviceListInfo, (dev) => {
          if (psn === dev.device.psn) {
            return {
              device: {
                ...dev.device,
                agree_gps,
                share_audio,
                share_dev_name,
                share_gps,
                share_video,
                share_leu_bigdata,
              },
            };
          }
          return dev;
        }),
      };
      state.loading = false;
      state.type = undefined;
    },
    loadFwUpdateInfo: (state, action: PayloadAction<string | undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    clearFwUpdateInfo: (state) => {
      state.fwUpdateInfos = [];
    },
    loadNotification: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successUpdateNotification: (
      state,
      { payload }: PayloadAction<INotificationSettings>
    ) => {
      state.loading = false;
      state.notificationSettings = payload;
    },
    updateNotification: (
      state,
      action: PayloadAction<{
        setType: "email" | "phone";
        settings: IEmailNotiSettings;
        send: OnOffType;
        fatigueSend?: OnOffType;
        fatigueTime?: number;
        // mantis - 11079, app과 모바일에서 notification설정 변경시 토스트팝업 가운데로 출력되도록 props추가 (Leehj)
        positionCenter?: boolean;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },

    successFwUpdateInfo: (
      state,
      { payload }: PayloadAction<IFwUpdateInfo[]>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.fwUpdateInfos = payload;
    },
    loadLiveEventSettings: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadLiveEventSettings: (
      state,
      { payload }: PayloadAction<ILiveEventSettings>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.liveEventSettings = payload;
    },
    updateLiveEventSettings: (
      state,
      action: PayloadAction<{
        camera: ICameraInfo;
        setting: ILiveEventSettings;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    loadFirmwareInfos: () => {},
    successLoadFirmwareInfos: (
      state,
      { payload }: PayloadAction<IFirmware[]>
    ) => {
      state.firmwares = payload;
    },
    loadFirmwareConfig: (state, _action: PayloadAction<ICameraInfo>) => {},
    setFirmwareConfig: (state, { payload }: PayloadAction<IFirmwareConfig>) => {
      state.firmwareConfig = payload;
    },
    loadFirmwareSettings: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadFirmwareSettings: (
      state,
      { payload }: PayloadAction<IFirmwareSettings>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.firmware = payload;
    },
    // EmailList 조회
    loadNotificationEmailList: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadNotificationEmailList: (
      state,
      { payload }: PayloadAction<INotificationEmailList>
    ) => {
      state.loading = false;
      state.notificationEmail = payload;
    },
    // confirm mail 발송
    invite: (state, action: PayloadAction<IEmailInvite>) => {
      state.loading = true;
      state.type = action.type;
    },
    successInvite: (state) => {
      state.loading = false;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    // 본인 알림 유무 수정
    fixMasterEmailNotification: (
      state,
      action: PayloadAction<IMasterEmailNotification>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    // email 삭제
    deleteEmail: (state, action: PayloadAction<IDeleteEmail>) => {
      state.loading = true;
      state.type = action.type;
    },
    successDelete: (state) => {
      state.loading = false;
    },
    updateFirmwareSettings: (
      state,
      action: PayloadAction<{
        firmware: IFirmwareSettings;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    requestFota: (state, action: PayloadAction<IFotaIDPayload>) => {
      state.loading = true;
      state.type = action.type;
    },
    resetLocationLoaded: (state) => {
      state.isLocationLoaded = false;
    },
    loadPublicCamera: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadPublicCamera: (
      state,
      { payload }: PayloadAction<ICameraInfo>
    ) => {
      state.loading = false;
      state.publicCamera = payload;
    },
    clearPublicCamera: (state) => {
      state.publicCamera = undefined;
    },
    rebootCamera: (state, action: PayloadAction<ICameraInfo>) => {
      state.loading = true;
      state.type = action.type;
    },
    loadFreeCamLocation: (
      state,
      action: PayloadAction<{ camera: ICameraInfo; cancel: AbortController }>
    ) => {},
    setFotaFailed: (state, action: PayloadAction<boolean>) => {
      state.fotaFailed = action.payload;
    },
    setCameraTimeSync: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.cameraTimeSync = payload;
    },
  },
});

export const {
  clearCamera,
  resetCamera,
  addCamera,
  renameCamera,
  deleteCamera,
  loadCamera,
  successLoadCamera,
  loadCameras,
  successLoadCameras,
  // loadCamReg,
  // successLoadCamReg,
  failLoad,
  loadMyCemrasLocation,
  successLoadCameraLocation,
  updatePrivacy,
  successUpdatePrivacy,
  loadFwUpdateInfo,
  successFwUpdateInfo,
  loadNotification,
  updateNotification,
  successUpdateNotification,
  loadLiveEventSettings,
  successLoadLiveEventSettings,
  updateLiveEventSettings,
  loadFirmwareInfos,
  successLoadFirmwareInfos,
  loadFirmwareSettings,
  successLoadFirmwareSettings,
  updateFirmwareSettings,
  loadNotificationEmailList,
  successLoadNotificationEmailList,
  invite,
  successInvite,
  clearLoading,
  fixMasterEmailNotification,
  deleteEmail,
  successDelete,
  requestFota,
  successLoadPublicCameraLocation,
  updateCameras,
  successUpdateCameras,
  resetLocationLoaded,
  loadPublicCamera,
  successLoadPublicCamera,
  clearPublicCamera,
  successLoadCameraCluster,
  loadFirmwareConfig,
  setFirmwareConfig,
  rebootCamera,
  clearFwUpdateInfo,
  setCameraError,
  clearCameraError,
  loadPrivacy,
  successLoadPrivacy,
  setSharedEventMap,
  loadFreeCamLocation,
  setFotaFailed,
  setCameraTimeSync,
} = slice.actions;
export const CAMERA = slice.name;
export default slice.reducer;
