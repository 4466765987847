export type PlanType =
  | "Free plan"
  | "Fleet plan"
  | "Basic plan"
  | "Smart plan"
  | "Lite plan";

export const getPlanFromServiceID = (id: number) => {
  if (id === 1) {
    return "Free plan";
  } else if (id >= 1001 && id <= 1003) {
    return "Fleet plan";
  } else if (id === 1004) {
    return "Basic plan";
  } else if (id === 1005) {
    return "Smart plan";
  } else if (id === 1998 || id === 1999) {
    return "Fleet plan";
  } else if (id >= 4001 && id <= 4029) {
    return "Fleet plan";
    // mantis - 12319,12316 스마트계정 요금제 추가 (Leehj)
  } else if (id >= 10001 && id <= 10015) {
    return "Smart plan";
  } else if (id >= 20001 && id <= 20999) {
    return "Fleet plan";
  } else if (id >= 30001 && id <= 30999) {
    return "Fleet plan";
  } else if (id >= 50001 && id <= 50999) {
    return "Plus plan";
    // mantis - 12311, 12312, 12313,12314, 12320 lite계정 요금제 추가 (Leehj)
  } else if (id >=  11001 && id <= 11015) {
    return "Lite plan";
  }
  return "";
};
