import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme, useTheme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface PleaseSelectModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

export const PleaseSelectModal = ({
  open,
  onClose,
  onClickPositive,
}: PleaseSelectModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickPositive={onClickPositive}
      close
      content={
        <div style={{ paddingTop: 18 }}>
          <Typography variant="Body" category="Default">
            {t("Please select one of the video sources")}
          </Typography>
        </div>
      }
      RButton={t("OK")}
    />
  );
};
