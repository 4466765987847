import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadCameras } from "../../features/Camera/slice";
import {
  BatteryStatusPanel,
  PANEL_MODE,
} from "../../components/uxui/BatteryStatusPanel";
import React from "react";
import { RootState } from "../../features/store";
import {
  BatteryManualCommand,
  ICameraInfo,
  sendMessageBack,
} from "@thingsw/pitta-modules";
import _ from "lodash";
import {
  batteryManualEnd,
  loadBatteryManual,
  updateBatteryInfo,
} from "../../features/Battery/slice";
import { BatterySettingPanel } from "../../components/uxui/BatterySettingPanel";

interface BatteryStatusScreenProps {
  psn: string;
}

export const BatteryStatusScreen = ({ psn }: BatteryStatusScreenProps) => {
  const dispatch = useDispatch();

  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

  const [camera, setCamera] = useState<ICameraInfo>();
  const [mode, setMode] = useState<PANEL_MODE>("battery");

  useEffect(() => {
    if (camera) {
      //@ts-ignore
      window.backPressed = () => {
        dispatch(batteryManualEnd(camera));
        sendMessageBack();
      };
    }

    return () => {
      //@ts-ignore
      window.backPressed = undefined;
    };
  }, [camera, dispatch]);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    const cam = _.find(
      cameraList?.deviceListInfo,
      (dev) => dev.device.psn === psn
    )?.device;
    setCamera(cam);
  }, [cameraList?.deviceListInfo, psn]);

  useEffect(() => {
    if (camera) {
      dispatch(
        updateBatteryInfo({
          percent: camera.battery.battery_percent,
          temperature: camera.battery.celsius,
        })
      );

      dispatch(
        loadBatteryManual({
          camera: camera,
          command: BatteryManualCommand.START,
        })
      );
    }
  }, [camera, dispatch]);

  const handlePanelState = useCallback((mode: PANEL_MODE) => {
    if (mode === "list") {
      return sendMessageBack();
    }
    setMode(mode);
  }, []);

  return (
    <>
      <BatteryStatusPanel
        open={mode === "battery"}
        setPanelState={handlePanelState}
        currentCam={camera}
        app
      />
      <BatterySettingPanel
        open={mode === "setting"}
        mobile
        setPanelState={handlePanelState}
        currentCam={camera}
      />
    </>
  );
};
