import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListPaging } from "../../types";
import {
  IMemberState,
  IMemberList,
  IMember,
  IPermissions,
  RoleType,
} from "@thingsw/pitta-modules";

let initialState: IMemberState = {
  loading: false,
  memberList: undefined,
  members: undefined,
};

const slice = createSlice({
  name: "member",
  initialState,
  reducers: {
    resetMember: (state) => {
      state.loading = false;
      state.memberList = undefined;
      state.members = undefined;
      state.permissions = undefined;
      state.type = undefined;
      state.currentPage = undefined;
    },
    loadMembers: (state) => {
      state.loading = true;
    },
    successLoadMembers: (state, { payload }: PayloadAction<IMemberList>) => {
      state.loading = false;
      state.members = payload;
    },
    loadMemberPaging: (state, action: PayloadAction<ListPaging>) => {
      state.loading = true;
      state.currentPage = action.payload;
    },
    successLoadMemberPaging: (
      state,
      { payload }: PayloadAction<IMemberList>
    ) => {
      state.loading = false;
      state.memberList = payload;
    },
    invite: (state, _action: PayloadAction<IMember>) => {
      state.loading = true;
    },
    deleteMembers: (
      state,
      action: PayloadAction<
        {
          deleteEmail: string;
          deleteEmailUserType: RoleType;
        }[]
      >
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successInvite: (state) => {
      state.loading = false;
    },
    successDeleteMembers: (state) => {
      state.loading = false;
    },
    loadUserPermissions: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    successLoadUserPermissions: (
      state,
      { payload }: PayloadAction<IPermissions>
    ) => {
      state.loading = false;
      state.permissions = payload;
    },
    updateUserPermissions: (
      state,
      action: PayloadAction<{ roleSetEmail: string; permissions: IPermissions }>
    ) => {
      state.loading = true;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
  },
});

export const {
  resetMember,
  loadMembers,
  successLoadMembers,
  deleteMembers,
  loadMemberPaging,
  successLoadMemberPaging,
  invite,
  successInvite,
  successDeleteMembers,
  loadUserPermissions,
  successLoadUserPermissions,
  updateUserPermissions,
  clearLoading,
} = slice.actions;
export const MEMBER = slice.name;
export default slice.reducer;
