import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Typography } from "@thingsw/pitta-design-system";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../features/store";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import {
  CAMERA,
  loadCameras,
  loadFwUpdateInfo,
  requestFota,
} from "../features/Camera/slice";
import _ from "lodash";
import { useCallback } from "react";
import {
  LightColors,
  Webviewer,
  ICameraInfo,
  IFirmwareInfo,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: LightColors.primary["0"],
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    position: "fixed",
    right: 0,
    bottom: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 437,
      ...(theme.direction === "rtl" ? { left: 16 } : { right: 16 }),
    },
    overflowY: "hidden",
    transition: theme.transitions.create("height"),
  },
  rootClose: {
    height: 40,
  },
  header: {
    width: "100%",
    height: 40,
    backgroundColor: LightColors.primary["1"],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { paddingRight: theme.spacing(2) }
      : { paddingLeft: theme.spacing(2) }),
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  iconBtn: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
    color: LightColors.primary["0"],
  },
  cancelDiv: {
    width: "100%",
    height: 36,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  cancelBtn: {
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    cursor: "pointer",
  },
  listDiv: {
    overflowY: "auto",
  },
  itemDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 36,
    padding: theme.spacing(0, 2),
  },
  descriptionDiv: {
    padding: theme.spacing(0.75, 2),
  },
  circleRoot: {
    position: "relative",
  },
  circleBottom: {
    color: LightColors.primary["6"],
  },
  circleTop: {
    position: "absolute",
    left: 0,
  },
  retryBtn: {
    cursor: "pointer",
    color: LightColors.primary[3],
  },
}));

interface FwStatusModalProps {
  camera: ICameraInfo;
  firmware: IFirmwareInfo;
  dmc200?: boolean;
}

export const FwStatusModal = ({
  camera,
  firmware,
  dmc200,
}: FwStatusModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fwUpdateInfo = useSelector(
    (state: RootState) => state[CAMERA].fwUpdateInfos[0]
  );

  const [open, setOpen] = useState(true);
  const [forceClose, setForceClose] = useState(false);
  const [errorDownload, setErrorDownload] = useState(false);

  useEffect(() => {
    if (_.includes(["fail"], fwUpdateInfo?.update_status)) {
      setErrorDownload(true);
    } else {
      setErrorDownload(false);
    }
  }, [camera.active, fwUpdateInfo]);

  useEffect(() => {
    function tick() {
      if (camera) {
        dispatch(loadFwUpdateInfo(camera.psn));
        dispatch(loadCameras());
      }
    }

    let id = setInterval(tick, 10 * 1000);
    tick();
    return () => clearInterval(id);
  }, [dispatch, camera]);
  const handleUpdate = useCallback(() => {
    dispatch(
      requestFota({
        psn: camera.psn,
        fw_model: camera.model,
        now_fw_version: camera.fw_ver,
        new_fw_version: firmware.version,
        fota_url: firmware.file,
        file_size: firmware.file_size,
        lang: camera.lang ?? "English",
        checksum: firmware.md5,
      })
    );
  }, [
    camera.fw_ver,
    camera.lang,
    camera.model,
    camera.psn,
    dispatch,
    firmware.file,
    firmware.file_size,
    firmware.md5,
    firmware.version,
  ]);

  const itemMarkup = useMemo(() => {
    if (fwUpdateInfo && camera) {
      const value = fwUpdateInfo.update_percentage;
      let btn = (
        <>
          <CircularProgress
            variant="determinate"
            className={classes.circleBottom}
            value={100}
            size={20}
            thickness={6}
          />
          <CircularProgress
            variant="determinate"
            className={classes.circleTop}
            value={value}
            size={20}
            thickness={6}
          />
        </>
      );
      if (fwUpdateInfo?.update_status === "success") {
        btn = <CheckCircleIcon htmlColor={LightColors.secondary["15"]} />;
      }
      return (
        <div className={classes.itemDiv} key={fwUpdateInfo.fota_id}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={errorDownload ? LightColors.secondary["12"] : undefined}
          >
            {dmc200 ? "DMC200" : camera.dev_name}
          </Typography>
          <div className={classes.circleRoot}>
            {errorDownload ? (
              <Typography
                category="Default"
                variant="Body"
                className={classes.retryBtn}
                onClick={handleUpdate}
              >
                {t("Retry")}
              </Typography>
            ) : (
              btn
            )}
          </div>
        </div>
      );
    }
  }, [
    camera,
    classes.circleBottom,
    classes.circleRoot,
    classes.circleTop,
    classes.itemDiv,
    classes.retryBtn,
    dmc200,
    errorDownload,
    fwUpdateInfo,
    handleUpdate,
    t,
  ]);

  const fwTitle = useMemo(() => {
    if (errorDownload) {
      if (fwUpdateInfo?.update_percentage === 100) {
        return t("Error updating firmware");
      } else {
        return t("Error downloading firmware");
      }
    } else if (fwUpdateInfo?.update_status === "success") {
      return t("Firmware updated");
    } else if (fwUpdateInfo?.update_status === "complete") {
      return `${t("Updating firmware")}...`;
    }
    return `${t("Downloading firmware")}...`;
  }, [
    errorDownload,
    fwUpdateInfo?.update_percentage,
    fwUpdateInfo?.update_status,
    t,
  ]);

  return fwUpdateInfo && fwUpdateInfo.visible && !forceClose ? (
    <div
      className={clsx(classes.root, {
        [classes.rootClose]: !open,
      })}
    >
      <div className={classes.header}>
        <Typography
          category="Default"
          variant="Body"
          htmlColor={
            errorDownload
              ? LightColors.secondary["12"]
              : LightColors.primary["0"]
          }
        >
          {fwTitle}
        </Typography>
        <div>
          <IconButton
            className={classes.iconBtn}
            onClick={() => setOpen((o) => !o)}
          >
            {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
          <IconButton
            className={classes.iconBtn}
            onClick={() => setForceClose(true)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.listDiv}>{itemMarkup}</div>
      <div className={classes.descriptionDiv}>
        <Typography category="Default" variant="Caption">
          {t("Once download is_")}
        </Typography>
      </div>
    </div>
  ) : (
    <></>
  );
};
