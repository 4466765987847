import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { CAMERA, renameCamera } from "../../features/Camera/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { useTheme } from "@material-ui/styles";
import { ICameraInfo, Webviewer, LightColors } from "@thingsw/pitta-modules";
import React from "react";

interface OnlyWifiModelRenameModalProps {
  open: boolean;
  loading?: boolean;
  camera?: ICameraInfo;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  titleDiv: {
    padding: "25px 44px 0 24px",
    minHeight: 10,
  },
}));

export const OnlyWifiModelRenameModal = ({
  open,
  camera,
  onClose,
}: OnlyWifiModelRenameModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { loading, type } = useSelector((state: RootState) => state[CAMERA]);

  const [devName, setDevName] = useState("");
  const [camNameError, setCamNameError] = useState(false);
  const [camNameLengthErr, setCamNameLengthErr] = useState(false);

  // mantis - 11425, 1. 이름수정 2. 저장하지 않고 x버튼 3. 다시 모달 open 4. 수정시도한 이름 남아있는 이슈 수정 (Leehj)
  useEffect(() => {
    if (open) {
      setDevName(camera?.dev_name ?? "");
    }
  }, [camera, open]);

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={() => {
        setCamNameError(false);
        setCamNameLengthErr(false);
        onClose();
      }}
      onClickNegative={() => {
        setCamNameError(false);
        setCamNameLengthErr(false);
        onClose();
      }}
      onClickPositive={() => {
        if (devName.indexOf("\\") > -1 || devName.indexOf('"') > -1) {
          setCamNameError(true);
          setCamNameLengthErr(false);
        } else if (devName.match(/\s/g)?.length === devName.length) {
          setCamNameError(false);
          setCamNameLengthErr(true);
        } else if (devName.trim().length < 5 || devName.trim().length > 32) {
          setCamNameError(false);
          setCamNameLengthErr(true);
        } else {
          setCamNameError(false);
          setCamNameLengthErr(false);
          camera &&
            dispatch(
              renameCamera({
                psn: camera.psn,
                devName: devName.trim(),
                // mantis - 11277, regCategory 추가 (Leehj)
                regCategory: camera.reg_category || "cloud",
              })
            );
        }
      }}
      heading="Rename"
      titleClassName={classes.titleDiv}
      close
      loading={loading && type === renameCamera.type}
      content={
        <div style={{ paddingTop: 8 }}>
          <Input
            name="camName"
            style={{ paddingBottom: 3 }}
            onChange={(e) => setDevName(e.target.value)}
            value={devName}
            autoFocus
            error={camNameError || camNameLengthErr}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "94%",
                display: "flex",
                justifyContent:
                  camNameError || camNameLengthErr
                    ? "space-between"
                    : "flex-end",
              }}
            >
              {camNameError && (
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.secondary["11"]}
                >
                  {t("Unallowed character detected")}
                </Typography>
              )}
              {camNameLengthErr && (
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.secondary["11"]}
                >
                  {t("Camera name must_")}
                </Typography>
              )}
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
              >
                {devName.length}/32
              </Typography>
            </div>
          </div>
        </div>
      }
      LButton="Cancel"
      RButton="Rename"
      RButtonDisabled={devName.length === 0}
    />
  );
};
