import React, { useCallback, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Typography } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import LanguageIcon from "@material-ui/icons/Language";
import { useTranslation } from "react-i18next";
import { PopperPlacementType } from "@material-ui/core/Popper";
import { Property } from "csstype";

import { LanguageMenu } from "./LanguageMenu";
import { useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { changeLang } from "../utils/ChangeLang";
import { changeDirection } from "../features/Theme/slice";
import { LightColors, Webviewer, Languages } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  langBtn: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    "&:active": {
      color: LightColors.primary["8"],
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      // ...(theme.direction === "rtl"
      //   ? { marginLeft: theme.spacing(3) }
      //   : { marginRight: theme.spacing(3) }),
    },
    minHeight: theme.spacing(3),
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    "user-select": "none",
    /* Non-prefixed version, currently
       supported by Chrome, Edge, Opera and Firefox */
  },
  langIconOnlyBtn: {
    [theme.breakpoints.up("md")]: {
      ...(theme.direction === "rtl" ? { marginLeft: 0 } : { marginRight: 0 }),
      justifyContent: "center",
    },
  },
  active: {
    color: LightColors.primary["8"],
  },
  icon:
    theme.direction === "rtl"
      ? { marginLeft: 4 }
      : {
          marginRight: 4,
        },
  vmiddle: {
    display: "flex",
    alignItems: "center",
  },
}));

export interface LangButtonProps {
  mode: "main" | "webviewer";

  /**
   * Popper placement.
   */
  placement?: PopperPlacementType;
  /**
   * The **`transform-origin`** CSS property sets the origin for an element's transformations.
   *
   * **Syntax**: `[ <length-percentage> | left | center | right | top | bottom ] | [ [ <length-percentage> | left | center | right ] && [ <length-percentage> | top | center | bottom ] ] <length>?`
   *
   * **Initial value**: `50% 50% 0`
   *
   * | Chrome  |  Firefox  | Safari  |  Edge  |   IE    |
   * | :-----: | :-------: | :-----: | :----: | :-----: |
   * | **36**  |  **16**   |  **9**  | **12** | **10**  |
   * | 1 _-x-_ | 3.5 _-x-_ | 2 _-x-_ |        | 9 _-x-_ |
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/transform-origin
   */
  transformOrigin?: Property.TransformOrigin;
  /**
   * Popper.js is based on a "plugin-like" architecture,
   * most of its features are fully encapsulated "modifiers".
   *
   * A modifier is a function that is called each time Popper.js needs to
   * compute the position of the popper.
   * For this reason, modifiers should be very performant to avoid bottlenecks.
   * To learn how to create a modifier, [read the modifiers documentation](https://popper.js.org/docs/v1/#modifiers).
   */
  modifiers?: object;
  iconOnly?: boolean;
  openMenu: boolean;
  className?: string;

  anchorRef?: React.RefObject<HTMLElement>;
  onClose: () => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseMove?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onTouchStart?: React.TouchEventHandler<HTMLDivElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
  onFocuse?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

export const LangButton = React.forwardRef<HTMLDivElement, LangButtonProps>(
  (
    {
      mode,
      placement,
      transformOrigin,
      iconOnly,
      className,
      modifiers,
      openMenu,
      anchorRef,
      onClose,
      ...restProps
    },
    ref
  ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const theme = useTheme() as Theme;

    const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

    // const anchorRef = React.useRef<HTMLDivElement>(null);
    const [openLanguage, setOpenLanguage] = useState(false);

    const handleLanguage = useCallback(
      (lang: string) => {
        changeLang(i18n, lang, dispatch);
        localStorage.setItem("pitta-webviewer-pref-lang", lang);
        // mantis : 8436 Kimsh
        // 언어가 아랍어일 때 rtl, 아니라면 ltr로 direction 지정
        if (lang === "ar") {
          dispatch(changeDirection("rtl"));
        } else {
          dispatch(changeDirection("ltr"));
        }
      },
      [dispatch, i18n]
    );

    return (
      <div
        ref={ref}
        {...restProps}
        className={clsx(
          classes.langBtn,
          {
            [classes.langIconOnlyBtn]: iconOnly,
          },
          className
        )}
      >
        <div
          dir={theme.direction}
          className={clsx(classes.vmiddle, {
            [classes.active]: openLanguage,
          })}
          // ref={anchorRef}
          onClick={() => setOpenLanguage((o) => !o)}
        >
          <LanguageIcon
            className={clsx({ [classes.icon]: !iconOnly })}
            fontSize="small"
          />
          {!iconOnly && (
            <Typography category="Default" variant="Body">
              {Languages[i18n.language]}
            </Typography>
          )}
        </div>

        <LanguageMenu
          mode={mode}
          anchorEl={anchorRef?.current}
          open={openMenu}
          mobile={mobile}
          placement={placement}
          transformOrigin={transformOrigin}
          modifiers={modifiers}
          onClickAway={onClose}
          onClick={handleLanguage}
        />
      </div>
    );
  }
);
