import { Card, RadioGroup, useMediaQuery, Divider } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  MotionIcon,
  IconButton,
  RadioButton,
  Switch,
  Typography,
  Button,
} from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import clsx from "clsx";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadFirmwareSettings, updateFirmwareSettings } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import TimelapseIcon from "@material-ui/icons/Timelapse";

import { ScreenDefaultProps } from "../../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../CameraPlaybackScreen";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { MotionDetectionSensitivityScreen } from "./MotionDetectionSensitivityScreen";
import { RecordingModal } from "../../components/modals/RecordingModal";
import { Prompt, useHistory } from "react-router-dom";
import { DiscardChangeUXUIModal } from "../../components/uxui/DiscardChangeUXUIModal";
import { FirmwareSettingModal } from "../../components/cameras/FirmwareSettingModal";
import {
  Webviewer,
  LightColors,
  TabNameInfo1,
  TabNameInfo2,
  TabNameInfo3,
  ICameraInfo,
  BATTERY_PROTECTION_WARNING_MODELS,
  TrueFalsetype,
  TabName,
  IFirmwareCloudSettings,
  OPTION_CAMERA_MODELS,
  PermissionProps,
  HIGHTEMPER_PROTECTION_MODALS,
  MODEL_KEY,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E8E8E8",
    minHeight: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: "calc(var(--vh, 1vh) * 100 - 104px)",
    },
  },

  flex: {
    display: "flex",
    flexDirection: "revert",
    alignItems: "center",
  },

  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },

  column: {
    display: "flex",
    flexDirection: "column",
  },

  middleColumn: {
    display: "flex",
    flexDirection: "column",
    minHeight: 24,
    justifyContent: "center",
    marginTop: 4,
  },

  titleDiv: {
    display: "flex",
    marginBottom: 15,
    alignItems: "center",
    marginLeft: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 20,
    },
  },

  card: {
    maxWidth: 671,
    padding: "8px 20px",
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  rearCamCard: {
    padding: "8px 19px 7px 20px",
  },

  cardNonPadding: {
    maxWidth: 671,
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  cardRecording: {
    padding: "12px 20px 10px",
  },

  topPadding: {
    padding: "12px 20px 12px",
  },

  bottomPadding: {
    padding: "12px 20px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  motionSensitivity: {
    paddingLeft: 62,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cursorDefault: {
    cursor: "default",
  },
  disabledMenu: {
    opacity: 0.35,
    cursor: "default",
  },

  cardDiv: {
    marginBottom: 16,
  },

  parkingModeWrap: {
    borderTop: `1px solid ${LightColors.primary["5"]}`,
    display: "flex",
    flexDirection: "column",
    padding: "16px 20px 22px",
  },
  parkingModeOptionDesc: {
    paddingLeft: 62,
    lineHeight: 1,
    marginBottom: 4,
  },

  switch: {
    marginLeft: 4,
    marginRight: 12,
    width: 48,
  },

  middleContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px 3px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "12px 0px 0px",
      margin: "0px 9px 12px",
    },
  },

  radioDiv: {
    width: 62,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 22,
    marginBottom: 15,
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "revert",
      marginTop: 0,
      marginBottom: 0,
    },
  },

  radioGroup: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  parkingModeIcon: {
    fill: LightColors.primary["2"],
    marginRight: 8,
  },

  batteryContent: {
    padding: "8px 24px 8px 0",
  },

  Swid116: {
    maxWidth: 116,
    // padding: "12px 0",
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },

  textFieldText: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 48,
    marginBottom: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingLeft: 64,
      maxWidth: 245,
      marginBottom: 0,
    },
  },

  button: {
    // mantis - 10987, 버튼 모양 피그마디자인 적용(Leehj)
    // borderRadius: 30,
    maxWidth: 96,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 14,
    },
  },

  sensitivityIcon: {
    color: LightColors.primary["7"],
  },

  viewPC: {
    display: "none",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "revert",
    },
  },

  viewMobile: {
    display: "revert",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "none",
    },
  },

  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },

  cutOffDiv: {
    width: "100%",
    height: 48,
    marginBottom: 16,
  },

  cutOffMargin: {
    // marginBottom: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 25,
    },
  },

  rootPanel: {
    overflow: "hidden",
    // overflowX: "hidden",
    // overflowY: "auto",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    transition: theme.transitions.create("transform"),
    position: "relative",
    padding: "24px 16px 24px",
    // padding: "24px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      //   width: 673,
      padding: 24,
    },
  },

  listPane: {
    flexDirection: "column",
    top: 16,
    bottom: 0,
    right: 0,
    left: 0,
    width: "inherit",
    transition: theme.transitions.create("transform"),
    transitionDuration: "0.5s",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: 23,
      width: 673,
      flex: 1,
      display: "flex",
    },
  },

  recordingHide: {
    transition: theme.transitions.create("transform"),
    transitionDuration: "0.5s",
    transform: "translateX(-100vw)",
    position: "absolute",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-695px)",
    },
  },

  rotationHide: {
    transitionDuration: "0.5s",
    transition: theme.transitions.create("transform"),
    transform: "translateX(100vw)",
    position: "absolute",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(100vw)",
    },
  },

  divider: {
    margin: "11px 0px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0px 0px 13px",
      maxWidth: 671,
    },
  },

  parkingModeDivider: {
    margin: "11px 0px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "16px 0px",
      maxWidth: 671,
    },
  },

  input: {},
  disabledText: {
    opacity: 0.35,
  },
}));

export const CameraRecordingModeSettingScreen = (
  props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps
) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const web = useMediaQuery(theme.breakpoints.up(Webviewer.mobile));
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState<TabNameInfo1>();
  const [currentOrgTab1, setCurrentOrgTab1] = useState<TabNameInfo1>();
  const [currentTab2, setCurrentTab2] = useState<TabNameInfo2>();
  const [currentTab3, setCurrentTab3] = useState<TabNameInfo3>();

  const [voltTimeValue, setVoltTimeValue] = useState<string>("3:00");
  const [lowVoltValue, setLowVoltValue] = useState<string>("12.0");
  const [lowVoltHeavyValue, setLowVoltHeavyValue] = useState<string>("23.2");
  const [batteryPRevChanged, setBatteryPRevChanged] = useState(false);
  const [batteryHRevChanged, setBatteryHRevChanged] = useState(false);
  const [cutoffTimerRevChanged, setCutoffTimerRevChanged] = useState(false);
  const [selectedField, setSelectedField] = useState<keyof TabNameInfo3>();
  const [selectedValue, setSelectedValue] = useState<any>();
  const [openBatteryModal, setOpenBatteryModal] = useState(false);
  const [disabledChange, setDisabledChange] = useState(true);
  const [camera, setCamera] = useState<ICameraInfo>();
  const [menu, setMenu] = useState<number>();

  const [targetLocation, setTargetLocation] = useState<Location>();
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);

  const {
    camera: cam,
    firmware,
    firmwareConfig,
    cameraList,
    loading,
    type,
  } = useSelector((state: RootState) => state[CAMERA]);

  const settings = useMemo(() => {
    return firmwareConfig?.System;
  }, [firmwareConfig?.System]);

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
        // 새로고침이나 창 종료시에는 해당 모달 출력 방지
        setOpenDiscardModal(false);
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    const parsed = parseFloat(
      currentTab3?.LowvoltageTime ||
        settings?.BatteryProtectionRev?.defaultTime ||
        "0300"
    );
    const rounded = _.round(parsed / 100, 0);
    setVoltTimeValue(`${rounded}:00`);
  }, [
    currentTab3?.LowvoltageTime,
    settings?.BatteryProtectionRev?.defaultTime,
  ]);

  useEffect(() => {
    if (currentTab3?.LowvoltageVolt === "0") {
      setLowVoltValue(
        settings?.BatteryProtectionRev?.defaultVolt.toFixed(1) || "12.0"
      );
      setLowVoltHeavyValue(
        settings?.BatteryProtectionRev?.defaultVoltHeavy.toFixed(1) || "23.2"
      );
    } else {
      const parsed = parseFloat(
        currentTab3?.LowvoltageVolt ||
          settings?.BatteryProtectionRev?.defaultVolt.toFixed(1) ||
          "12.0"
      );
      const roundedVolt = _.round(parsed / 100, 1);
      setLowVoltValue(roundedVolt.toFixed(1));
    }
  }, [
    currentTab3?.LowvoltageVolt,
    settings?.BatteryProtectionRev?.defaultVolt,
    settings?.BatteryProtectionRev?.defaultVoltHeavy,
  ]);

  useEffect(() => {
    if (currentTab3?.LowvoltageVoltHeavy === "0") {
      setLowVoltValue(
        settings?.BatteryProtectionRev?.defaultVolt.toFixed(1) || "12.0"
      );
      setLowVoltHeavyValue(
        settings?.BatteryProtectionRev?.defaultVoltHeavy.toFixed(1) || "23.2"
      );
    } else {
      const parsed = parseFloat(
        currentTab3?.LowvoltageVoltHeavy ||
          settings?.BatteryProtectionRev?.defaultVoltHeavy.toFixed(1) ||
          "23.2"
      );
      const roundedVolt = _.round(parsed / 100, 1);
      setLowVoltHeavyValue(roundedVolt.toFixed(1));
    }
  }, [
    currentTab3?.LowvoltageVoltHeavy,
    settings?.BatteryProtectionRev?.defaultVolt,
    settings?.BatteryProtectionRev?.defaultVoltHeavy,
  ]);

  useEffect(() => {
    const dev = _.find(
      cameraList?.deviceListInfo,
      (dev) => dev.device.psn === cam?.psn
    )?.device;
    setCamera((c) => {
      if (c?.psn === dev?.psn) {
        return c;
      } else {
        return dev;
      }
    });
  }, [cam?.psn, cameraList]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );

      if (currentTab) {
        const section = _.cloneDeep(currentTab.section_info) as TabNameInfo3;
        if (settings?.BatteryProtectionRev) {
          const battRev = settings.BatteryProtectionRev;
          switch (section.LowvoltageTime) {
            case "1":
              section.LowvoltageTime = "600";
              break;
            case "2":
              section.LowvoltageTime = "1200";
              break;
            case "3":
              section.LowvoltageTime = "2400";
              break;
            case "4":
              section.LowvoltageTime = "4800";
              break;
          }

          if (section.LowvoltageVolt !== "0") {
            if (_.includes(["1", "2", "3"], section.LowvoltageVolt)) {
              const volt = section.LowvoltageVolt;
              section.LowvoltageVolt = battRev.LowvoltageVolt[volt];
              section.LowvoltageVoltHeavy = battRev.LowvoltageVoltHeavy[volt];
            }

            let lowVal = parseInt(section.LowvoltageVolt) / 100;
            if (lowVal > battRev.maxVolt || lowVal < battRev.minVolt) {
              lowVal = battRev.defaultVolt;
            }
            section.LowvoltageVolt = (lowVal * 100).toString();
          }

          if (
            section.LowvoltageVoltHeavy !== "0" &&
            section.LowvoltageVoltHeavy
          ) {
            let lowHeavyVal = parseInt(section.LowvoltageVoltHeavy) / 100;

            if (
              lowHeavyVal > battRev.maxVoltHeavy ||
              lowHeavyVal < battRev.minVoltHeavy
            ) {
              lowHeavyVal = battRev.defaultVoltHeavy;
            }
            section.LowvoltageVoltHeavy = (lowHeavyVal * 100).toString();
          }
        }
        setCurrentTab3(section);
      }
    }
  }, [firmware, settings?.BatteryProtectionRev]);

  useEffect(() => {
    if (firmware) {
      const currentParkingTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      // mantis - 11441, Parking mode recording > 타입랩스나 모션으로 변경을 알기 위함. (Leehj)
      setCurrentOrgTab1(currentParkingTab?.section_info as TabNameInfo1);

      setCurrentTab((currentTab) =>
        !currentTab
          ? (currentParkingTab?.section_info as TabNameInfo1)
          : currentTab
      );
      const predicts = _.keys(
        currentParkingTab?.section_info as TabNameInfo1
      ).map((k) => {
        const key = k as keyof TabNameInfo1;
        return (
          (currentParkingTab?.section_info as TabNameInfo1)[key] ===
          currentTab?.[key]
        );
      });

      const currentOrgTab2 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab2"
      );

      // uxui 설정3차수정 - 3차수정하면서 Motion Sensitivity화면 설정 값들 정상적으로 출력안되고 있는 이슈 함께 수정. setCurrentTab2추가(Leehj)
      if (currentOrgTab2) {
        setCurrentTab2((current) =>
          !current ? (currentOrgTab2.section_info as TabNameInfo2) : current
        );
      }

      const predictsTab2 = _.keys(
        currentOrgTab2?.section_info as TabNameInfo2
      ).map((k) => {
        const key = k as keyof TabNameInfo2;
        return (
          (currentOrgTab2?.section_info as TabNameInfo2)[key] ===
          currentTab2?.[key]
        );
      });

      const currentOrgTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      const predictsTab3 = _.keys(
        currentOrgTab3?.section_info as TabNameInfo3
      ).map((k) => {
        const key = k as keyof TabNameInfo3;
        return (
          (currentOrgTab3?.section_info as TabNameInfo3)[key] ===
          currentTab3?.[key]
        );
      });

      // uxui 설정4차수정 - battery protection off된 상태에서 parking mode recording off하면 save버튼 활성화 안되는 이슈 수정(Leehj)
      setDisabledChange(
        _.every(predicts) &&
          _.every(predictsTab2) &&
          (_.every(predictsTab3) ||
            currentTab3?.kmLimit === "" ||
            currentTab3?.mileLimit === "" ||
            // 8739 - 이벤트 고도화 1차 - 속도경고 설정값 비어있는 경우 저장안되게 수정
            currentTab3?.AlertLimit === "")
      );
    }
  }, [firmware, currentTab, currentTab2, currentTab3]);

  //AutoParking 기본 값이 Motion & Impact detection(key:1)인 모델
  const AutoParkingKey1 = useMemo(
    () =>
      _.includes(
        [
          "DR750S-1CH",
          "DR750S-2CH",
          "DR750G-1CH",
          "DR750G-2CH",
          "DR750G-1CH Pro",
          "DR750G-2CH Pro",
          "DR750G Pro",
        ],
        camera?.model
      ),
    [camera?.model]
  );
  //AutoParking 기본 값이 Time lapse(key:2)인 모델
  const AutoParkingKey2 = useMemo(
    () =>
      _.includes(
        [
          "DR900S-1CH",
          "DR900S-2CH",
          "DR750-2CH LTE",
          "DR750X-1CH",
          "DR750X-2CH",
          "DR900X-1CH",
          "DR900X-2CH",
          "DR750X Plus",
          "BlackVue 7",
          "BlackVue 9",
          "BlackVue 7 Box",
          // mantis - 8814, BlackVue 7 LTE, BlackVue 9 LTE 기본값 Time lapse로 변경(Leehj)
          "BlackVue 7 LTE",
          "BlackVue 9 LTE",
        ],
        camera?.model
      ),
    [camera?.model]
  );

  const firmwareREV = useMemo(() => {
    if (
      firmware &&
      parseFloat(firmware?.firmware_version) >= parseFloat("1.003")
    ) {
      return true;
    }
  }, [firmware]);

  const REVModel = useMemo(
    () =>
      _.includes(
        ["DR750X Plus", "DR900X Plus", "DR750X LTE Plus", "DR750X-3CH Plus"],
        camera?.model
      ),
    [camera?.model]
  );

  //rear parkimg mode on:0 / off: 1인 모델
  const RearPKEY = useMemo(
    () => _.includes(["DR750-2CH LTE", "DR900S-2CH"], camera?.model),
    [camera?.model]
  );

  // 배터리 protection 변경 경고 모델 추가 (7434)
  const BATTERY_PROTECTION_MODAL = useMemo(
    () => _.includes(BATTERY_PROTECTION_WARNING_MODELS, camera?.model),
    [camera?.model]
  );

  // High temperature protection 모델 (FW v1.006) 추가
  const HIGHTEMPER_PROTECTION_MODAL = useMemo(() => {
    if (!camera) return;
    const fwVersion = HIGHTEMPER_PROTECTION_MODALS[camera.model as MODEL_KEY];
    return fwVersion && fwVersion === parseFloat(camera.fw_ver) ? true : false;
  }, [camera]);

  const handleChangeLowVoltRev = useCallback(
    (key: keyof TabNameInfo3, checked: boolean) => {
      if (BATTERY_PROTECTION_MODAL) {
        setOpenBatteryModal(true);
      }
      let dVolt: string;
      if (key === "LowvoltageVolt") {
        dVolt = (
          (settings?.BatteryProtectionRev?.defaultVolt ?? 12) * 100
        ).toString();
      } else if (key === "LowvoltageVoltHeavy") {
        dVolt = (
          (settings?.BatteryProtectionRev?.defaultVoltHeavy ?? 23.2) * 100
        ).toString();
      }
      setCurrentTab3(
        (c) =>
          ({
            ...c,
            [key]: checked ? dVolt : "0",
          } as TabNameInfo3)
      );
    },
    [BATTERY_PROTECTION_MODAL, settings]
  );

  const handleChangeLowVolt = useCallback(
    (key: keyof TabNameInfo3, checked: boolean) => {
      setCurrentTab3(
        (c) => ({ ...c, [key]: checked ? "2" : "0" } as TabNameInfo3)
      );
    },
    []
  );

  const handleChange = useCallback(
    (key: keyof TabNameInfo3, value: boolean | string) => {
      if (typeof value === "boolean") {
        setCurrentTab3(
          (c) => ({ ...c, [key]: value ? "1" : "0" } as TabNameInfo3)
        );
      } else if (typeof value === "string") {
        setCurrentTab3((c) => ({ ...c, [key]: value } as TabNameInfo3));
      }
    },
    []
  );

  /* REV 부터 적용되는 신규 Battery protection UI */
  const batteryRevMarkup = useMemo(() => {
    if (settings?.["BatteryProtectionRev"]) {
      const batteryProtectionRev = settings.BatteryProtectionRev;
      const parsedVolt = parseFloat(currentTab3?.LowvoltageVolt);
      const roundedVolt = _.round(parsedVolt / 100, 1);
      let volt = batteryProtectionRev.defaultVolt;
      if (!isNaN(parsedVolt)) {
        volt = roundedVolt;
      }

      const parsedHeavyVolt = parseFloat(currentTab3?.LowvoltageVoltHeavy);
      const roundedHeavyVolt = _.round(parsedHeavyVolt / 100, 1);
      let voltHeavy = batteryProtectionRev.defaultVoltHeavy;
      if (!isNaN(parsedHeavyVolt)) {
        voltHeavy = roundedHeavyVolt;
      }

      const voltTime = parseFloat(
        currentTab3?.LowvoltageTime || batteryProtectionRev.defaultTime
      );
      const minVoltTime = parseFloat(batteryProtectionRev.minTime);
      const maxVoltTime = parseFloat(batteryProtectionRev.maxTime);

      return (
        <>
          <div className={classes.batteryContent}>
            <div
              className={clsx(classes.flex, classes.cutOffMargin)}
              style={{ height: 48 }}
            >
              <div className={classes.switch}>
                <Switch
                  disabled={currentTab3?.Battery === "0"}
                  checked={currentTab3?.LowvoltageVolt !== "0"}
                  onChange={(e) => {
                    if (BATTERY_PROTECTION_MODAL) {
                      setOpenBatteryModal(true);
                      setSelectedField("LowvoltageVolt");
                      setSelectedValue(e.target.checked);
                    } else {
                      handleChangeLowVoltRev(
                        "LowvoltageVolt",
                        e.target.checked
                      );
                      handleChangeLowVoltRev(
                        "LowvoltageVoltHeavy",
                        e.target.checked
                      );
                    }
                  }}
                />
              </div>
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                  className={clsx(
                    currentTab3?.Battery === "0" && classes.disabledText
                  )}
                >
                  {t("Low voltage cut-off")}
                </Typography>
              </div>
            </div>
            <div
              className={clsx(classes.flex, {
                [classes.spaceBetween]: web,
                [classes.column]: !web,
              })}
              style={{ marginTop: !web ? 11 : 0 }}
            >
              <div className={classes.textFieldText}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                  className={clsx(
                    currentTab3?.Battery === "0" && classes.disabledText
                  )}
                >
                  {t("Passenger vehicle")}
                </Typography>
              </div>
              <div className={classes.flexEnd}>
                <div className={classes.Swid116} style={{ maxWidth: 297 }}>
                  <Input
                    // readOnly
                    count
                    outlinedInputClassName={classes.input}
                    disabled={
                      currentTab3?.LowvoltageVolt === "0" ||
                      currentTab3?.Battery !== "1"
                    }
                    PlusButtonDis={
                      currentTab3?.LowvoltageVolt === "0" ||
                      currentTab3?.Battery !== "1" ||
                      volt >= batteryProtectionRev.maxVolt
                    }
                    MinusButtonDis={
                      currentTab3?.LowvoltageVolt === "0" ||
                      currentTab3?.Battery !== "1" ||
                      volt <= batteryProtectionRev.minVolt
                    }
                    defaultText={"/12.5 V"}
                    onPlus={() => {
                      if (currentTab3?.LowvoltageVolt) {
                        if (BATTERY_PROTECTION_MODAL && !batteryPRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVolt");
                          setSelectedValue(undefined);
                        } else {
                          currentTab3.LowvoltageVolt = (
                            _.round(volt + 0.1, 1) * 100
                          ).toString();
                          setCurrentTab3({ ...currentTab3 });
                        }
                      }
                    }}
                    onMinus={() => {
                      if (currentTab3?.LowvoltageVolt) {
                        if (BATTERY_PROTECTION_MODAL && !batteryPRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVolt");
                          setSelectedValue(undefined);
                        } else {
                          currentTab3.LowvoltageVolt = (
                            _.round(volt - 0.1, 1) * 100
                          ).toString();
                          setCurrentTab3({ ...currentTab3 });
                        }
                      }
                    }}
                    onChange={(e) => {
                      if (currentTab3?.LowvoltageVolt !== undefined) {
                        if (BATTERY_PROTECTION_MODAL && !batteryPRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVolt");
                          setSelectedValue(undefined);
                        } else {
                          setLowVoltValue(e.target.value.substring(0, 4));
                        }
                      }
                    }}
                    onBlur={() => {
                      let parsed = parseFloat(lowVoltValue);
                      if (isNaN(parsed)) {
                        parsed = batteryProtectionRev.defaultVolt;
                      } else if (parsed > batteryProtectionRev.maxVolt) {
                        parsed = batteryProtectionRev.maxVolt;
                      } else if (parsed < batteryProtectionRev.minVolt) {
                        parsed = batteryProtectionRev.minVolt;
                      }

                      setLowVoltValue(parsed.toFixed(1));
                      if (currentTab3) {
                        currentTab3.LowvoltageVolt = (parsed * 100).toFixed(0);
                        setCurrentTab3({ ...currentTab3 });
                      }
                    }}
                    value={lowVoltValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.batteryContent}>
            <div
              className={clsx(classes.flex, {
                [classes.spaceBetween]: web,
                [classes.column]: !web,
              })}
            >
              <div className={classes.textFieldText}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                  className={clsx(
                    currentTab3?.Battery === "0" && classes.disabledText
                  )}
                >
                  {t("Heavy vehicle")}
                </Typography>
              </div>
              <div className={classes.flexEnd}>
                <div className={classes.Swid116} style={{ maxWidth: 297 }}>
                  <Input
                    count
                    // readOnly
                    disabled={
                      currentTab3?.LowvoltageVolt === "0" ||
                      currentTab3?.Battery !== "1"
                    }
                    PlusButtonDis={
                      currentTab3?.LowvoltageVolt === "0" ||
                      currentTab3?.Battery !== "1" ||
                      voltHeavy >= batteryProtectionRev.maxVoltHeavy
                    }
                    MinusButtonDis={
                      currentTab3?.LowvoltageVolt === "0" ||
                      currentTab3?.Battery !== "1" ||
                      voltHeavy <= batteryProtectionRev.minVoltHeavy
                    }
                    onPlus={() => {
                      if (currentTab3?.LowvoltageVoltHeavy) {
                        if (BATTERY_PROTECTION_MODAL && !batteryHRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVoltHeavy");
                          setSelectedValue(undefined);
                        } else {
                          currentTab3.LowvoltageVoltHeavy = (
                            _.round(voltHeavy + 0.1, 1) * 100
                          ).toString();
                          setCurrentTab3({ ...currentTab3 });
                        }
                      }
                    }}
                    onMinus={() => {
                      if (currentTab3?.LowvoltageVoltHeavy) {
                        if (BATTERY_PROTECTION_MODAL && !batteryHRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVoltHeavy");
                          setSelectedValue(undefined);
                        } else {
                          currentTab3.LowvoltageVoltHeavy = (
                            _.round(voltHeavy - 0.1, 1) * 100
                          ).toString();
                          setCurrentTab3({ ...currentTab3 });
                        }
                      }
                    }}
                    onChange={(e) => {
                      if (currentTab3?.LowvoltageVoltHeavy !== undefined) {
                        if (BATTERY_PROTECTION_MODAL && !batteryHRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVoltHeavy");
                          setSelectedValue(undefined);
                        } else {
                          setLowVoltHeavyValue(e.target.value.substring(0, 4));
                        }
                      }
                    }}
                    onBlur={() => {
                      let parsed = parseFloat(lowVoltHeavyValue);
                      if (isNaN(parsed)) {
                        parsed = batteryProtectionRev.defaultVoltHeavy;
                      } else if (parsed > batteryProtectionRev.maxVoltHeavy) {
                        parsed = batteryProtectionRev.maxVoltHeavy;
                      } else if (parsed < batteryProtectionRev.minVoltHeavy) {
                        parsed = batteryProtectionRev.minVoltHeavy;
                      }

                      setLowVoltHeavyValue(parsed.toFixed(1));
                      if (currentTab3) {
                        currentTab3.LowvoltageVoltHeavy = (
                          parsed * 100
                        ).toFixed(0);
                        setCurrentTab3({ ...currentTab3 });
                      }
                    }}
                    defaultText={"/24 V"}
                    value={lowVoltHeavyValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(classes.flex, classes.batteryContent, {
              [classes.spaceBetween]: web,
              [classes.column]: !web,
            })}
          >
            <div
              className={clsx(classes.flex, {
                [classes.cutOffDiv]: !web,
              })}
            >
              <div className={classes.switch}>
                <Switch
                  checked={currentTab3?.LowvoltageTime !== "0"}
                  disabled={currentTab3?.Battery === "0"}
                  onChange={(e) => {
                    if (BATTERY_PROTECTION_MODAL) {
                      setOpenBatteryModal(true);
                      setSelectedField("LowvoltageTime");
                      setSelectedValue(e.target.checked);
                    } else {
                      handleChange(
                        "LowvoltageTime",
                        e.target.checked
                          ? batteryProtectionRev.defaultTime
                          : "0"
                      );
                    }
                  }}
                />
              </div>
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                  className={clsx(
                    currentTab3?.Battery === "0" && classes.disabledText
                  )}
                >
                  {t("Cut-off timer")}
                </Typography>
              </div>
            </div>
            <div className={classes.flexEnd} style={{ maxWidth: 297 }}>
              <Input
                // readOnly
                count
                disabled={
                  currentTab3?.LowvoltageTime === "0" ||
                  currentTab3?.Battery !== "1"
                }
                PlusButtonDis={
                  currentTab3?.LowvoltageTime === "0" ||
                  currentTab3?.Battery !== "1" ||
                  voltTime >= maxVoltTime
                }
                MinusButtonDis={
                  currentTab3?.LowvoltageTime === "0" ||
                  currentTab3?.Battery !== "1" ||
                  voltTime <= minVoltTime
                }
                // dense
                // countDense
                onPlus={() => {
                  if (currentTab3?.LowvoltageTime) {
                    if (BATTERY_PROTECTION_MODAL && !cutoffTimerRevChanged) {
                      setOpenBatteryModal(true);
                      setSelectedField("LowvoltageTime");
                      setSelectedValue(undefined);
                    } else {
                      currentTab3.LowvoltageTime = (voltTime + 100).toString();
                      setCurrentTab3({ ...currentTab3 });
                    }
                  }
                }}
                onMinus={() => {
                  if (currentTab3?.LowvoltageTime) {
                    if (BATTERY_PROTECTION_MODAL && !cutoffTimerRevChanged) {
                      setOpenBatteryModal(true);
                      setSelectedField("LowvoltageTime");
                      setSelectedValue(undefined);
                    } else {
                      currentTab3.LowvoltageTime = (voltTime - 100).toString();
                      setCurrentTab3({ ...currentTab3 });
                    }
                  }
                }}
                onChange={(e) => {
                  setCutoffTimerRevChanged(true);
                  const splits = e.target.value.split(":");
                  if (splits.length > 1) {
                    splits[1] = "00";
                  }
                  setVoltTimeValue(_.join(splits, ":"));
                }}
                onBlur={() => {
                  let parsed = parseInt(voltTimeValue.replace(":", ""));
                  const parsedMin = parseInt(batteryProtectionRev.minTime);
                  const parsedMax = parseInt(batteryProtectionRev.maxTime);
                  if (isNaN(parsed)) {
                    parsed = parseInt(batteryProtectionRev.defaultTime);
                  } else if (parsedMin > parsed) {
                    parsed = parsedMin;
                  } else if (parsedMax < parsed) {
                    parsed = parsedMax;
                  }
                  const rounded = _.round(parsed / 100, 0);
                  setVoltTimeValue(`${rounded}:00`);
                  if (currentTab3) {
                    currentTab3.LowvoltageTime = parsed
                      .toString()
                      .padStart(4, "0");
                    setCurrentTab3({ ...currentTab3 });
                  }
                }}
                defaultText={"/48:00 h"}
                value={
                  currentTab3?.LowvoltageTime !== "0"
                    ? voltTimeValue
                    : `${_.round(parseInt(batteryProtectionRev.defaultTime) / 100, 0)}:00`
                }
              />
            </div>
          </div>
        </>
      );
    }
    return <></>;
  }, [
    BATTERY_PROTECTION_MODAL,
    batteryHRevChanged,
    batteryPRevChanged,
    classes.Swid116,
    classes.batteryContent,
    classes.column,
    classes.cutOffDiv,
    classes.cutOffMargin,
    classes.disabledText,
    classes.flex,
    classes.flexEnd,
    classes.input,
    classes.spaceBetween,
    classes.switch,
    classes.textFieldText,
    currentTab3,
    cutoffTimerRevChanged,
    handleChange,
    handleChangeLowVoltRev,
    lowVoltHeavyValue,
    lowVoltValue,
    settings,
    t,
    voltTimeValue,
    web,
  ]);

  const handleParkingChange = (key: keyof TabNameInfo1, checked: boolean) => {
    setCurrentTab(
      (c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo1)
    );
  };

  const handleAutoParkingChange = (
    key: keyof TabNameInfo1,
    checked: boolean
  ) => {
    setCurrentTab(
      (c) => ({ ...c, [key]: checked ? "2" : "0" } as TabNameInfo1)
    );
  };

  const handleChangeCutoffTimerRev = useCallback(
    (key: keyof TabNameInfo3, checked: boolean) => {
      if (BATTERY_PROTECTION_MODAL) {
        setOpenBatteryModal(true);
      }
      const val = settings?.BatteryProtectionRev?.defaultTime ?? "1200";
      setCurrentTab3(
        (c) =>
          ({
            ...c,
            [key]: checked ? val : "0",
          } as TabNameInfo3)
      );
    },
    [BATTERY_PROTECTION_MODAL, settings?.BatteryProtectionRev?.defaultTime]
  );

  const getBoolean = useCallback((TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  }, []);

  const handleUpdate = () => {
    if (camera && firmware) {
      const tabNames: TabName[] = [];
      const sections: IFirmwareCloudSettings[] = [];
      if (currentTab) {
        tabNames.push("Tab1");
        sections.push({ section_info: currentTab, section_name: "Tab1" });
      }
      if (currentTab2) {
        tabNames.push("Tab2");
        sections.push({ section_info: currentTab2, section_name: "Tab2" });
      }
      if (currentTab3) {
        tabNames.push("Tab3");
        sections.push({ section_info: currentTab3, section_name: "Tab3" });
      }
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => !_.includes(tabNames, c.section_name)
        ),
      };
      updated.cloud_settings = [...sections, ...updated.cloud_settings];

      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  return (
    <div className={classes.root}>
      <Prompt
        when={!disabledChange && !targetLocation}
        message={(location: any) => {
          setTargetLocation(location);
          // 세팅화면이 아닌 페이지로 이동하는 경우에만 discard모달 출력
          if (location.pathname.indexOf("settings") === -1) {
            setOpenDiscardModal(true);
            return false;
          } else {
            return true;
          }
        }}
      />
      <div className={classes.rootPanel}>
        <div
          className={clsx(classes.listPane, {
            [classes.recordingHide]: menu !== undefined,
          })}
        >
          <div className={classes.titleDiv}>
            <Typography category="Default" variant="H6">
              {t("Recording mode")}
            </Typography>
          </div>
          <div className={classes.cardDiv}>
            <Card className={clsx(classes.card, classes.cardRecording)}>
              <div className={classes.flex}>
                <div className={classes.switch}>
                  <Switch
                    // mantis - 11382, 잘못된 키값으로 되어있어서 변경 NORMALSTARTVOICE -> NormalRecord(Leehj)
                    checked={getBoolean(
                      currentTab?.NormalRecord as TrueFalsetype
                    )}
                    onChange={(e) => {
                      handleParkingChange("NormalRecord", e.target.checked);
                    }}
                  />
                </div>
                <div className={classes.column}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    style={{ paddingBottom: 2 }}
                  >
                    {t("Normal Mode recording")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["2"]}
                    className={classes.viewPC}
                  >
                    {t("ON : the camera_")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["2"]}
                    className={classes.viewPC}
                  >
                    {t("OFF : the camera_")}
                  </Typography>
                </div>
              </div>
            </Card>
          </div>
          <div className={classes.cardDiv}>
            <Card className={classes.cardNonPadding}>
              <div className={clsx(classes.flex, classes.topPadding)}>
                <div className={classes.switch}>
                  <Switch
                    checked={
                      currentTab?.AutoParking === "2" ||
                      currentTab?.AutoParking === "1"
                    }
                    onChange={(e: any) => {
                      if (AutoParkingKey1) {
                        handleParkingChange("AutoParking", e.target.checked);
                      } else if (AutoParkingKey2 || (firmwareREV && REVModel)) {
                        handleAutoParkingChange(
                          "AutoParking",
                          e.target.checked
                        );
                      } else {
                        // Parking mode OFF -> ON 변경하는  경우, 기본값인 TIMELAPSE 선택 (Leehj) 23.01.09
                        handleAutoParkingChange(
                          "AutoParking",
                          e.target.checked
                        );
                      }

                      // Parking mode recording을 OFF 하면 Rear camera recording Battery protection도 함께 OFF된다,
                      // uxui 설정 수정건 2차 적용 - Optional camera도 함께 OFF된다.(Leehj)
                      handleParkingChange("RearParkingMode", e.target.checked);
                      handleParkingChange(
                        "OptionRearParkingMode",
                        e.target.checked
                      );
                      if (BATTERY_PROTECTION_MODAL) {
                        setOpenBatteryModal(true);
                        setSelectedField("Battery");
                        setSelectedValue(e.target.checked);
                      } else {
                        handleChange("Battery", e.target.checked);
                      }
                    }}
                  />
                </div>
                <div>
                  <Typography category="Default" variant="BodyBold">
                    {t("Parking mode recording")}
                  </Typography>                  
                </div>
              </div>
              <div className={classes.parkingModeWrap}>
                <div className={classes.parkingModeOptionDesc}>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["7"]}
                    className={clsx(
                      currentTab?.AutoParking === "0" && classes.disabledText
                    )}
                  >
                    {t("In both modes_")}
                </Typography>
                </div>
                <div style={{ display: "flex", width: "100%" }}>                  
                  <RadioGroup
                    //  모바일에서 라디오버튼 동작안하는 이슈 수정(Leehj) 23.01.09
                    value={
                      AutoParkingKey1 && currentTab?.AutoParking === "0"
                        ? "1"
                        : ((AutoParkingKey2 || (firmwareREV && REVModel)) &&
                            currentTab?.AutoParking) === "0"
                        ? "2"
                        : currentTab?.AutoParking
                    }
                    onChange={(e) => {
                      setCurrentTab((c) => {
                        return c && { ...c, AutoParking: e.target.value };
                      });
                    }}
                    className={clsx(classes.radioGroup)}
                  >
                    <div
                      className={classes.radioDiv}
                    >
                      <RadioButton
                        // OFF로 설정하는 경우, 펌웨어값 0 설정하고 Timelapse와 motion detection 항목 비활성화 처리. (Leehj) 23.01.13
                        disabled={currentTab?.AutoParking === "0"}
                        checked={currentTab?.AutoParking === "2"}
                        value="2"
                      />
                    </div>
                    <div className={classes.radioDiv}>
                      <RadioButton
                        // OFF로 설정하는 경우, 펌웨어값 0 설정하고 Timelapse와 motion detection 항목 비활성화 처리. (Leehj) 23.01.13
                        disabled={currentTab?.AutoParking === "0"}
                        checked={currentTab?.AutoParking === "1"}
                        value="1"
                      />
                    </div>
                  </RadioGroup>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      flex: 1,
                      justifyContent: "space-around",
                    }}
                  >
                    <div className={classes.middleContent}>
                      <div
                        // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                        className={clsx(
                          currentTab?.AutoParking === "0" &&
                            classes.disabledText
                        )}
                        style={{ display: "flex" }}
                      >
                        <TimelapseIcon className={classes.parkingModeIcon} />
                        <Typography
                          category="Default"
                          variant="BodyBold"
                          // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                          className={clsx(
                            currentTab?.AutoParking === "0" &&
                              classes.disabledText
                          )}
                        >
                          {t("Time lapse")}
                        </Typography>
                      </div>
                      <div className={classes.middleColumn}>                          
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["2"]}
                          // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                          className={clsx(
                            currentTab?.AutoParking === "0" &&
                              classes.disabledText
                          )}
                        >
                          {t("Continuously records_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.middleContent}>
                      <div
                        // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                        className={clsx(
                          currentTab?.AutoParking === "0" &&
                            classes.disabledText
                        )}
                        style={{ display: "flex" }}
                      >
                        <MotionIcon className={classes.parkingModeIcon} />
                        <Typography
                          category="Default"
                          variant="BodyBold" // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                          className={clsx(
                            currentTab?.AutoParking === "0" &&
                              classes.disabledText
                          )}
                        >
                          {t("Motion Detection_2")}
                        </Typography>
                      </div>
                      <div className={classes.middleColumn}>                          
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["2"]}
                          // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                          className={clsx(
                            currentTab?.AutoParking === "0" &&
                              classes.disabledText
                          )}
                        >
                          {t("Parking recordings occur_")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div
                  // uxui 설정3차수정 - Motion detection이 선택된 경우에만 활성화 적용.(Leehj)
                  className={clsx(classes.motionSensitivity, classes.cursorPointer, {
                    [classes.disabledMenu]: currentTab?.AutoParking !== "1",
                  })}
                  onClick={() => {
                    // uxui 설정3차수정 - Motion detection이 선택된 경우에만 활성화 적용.(Leehj)
                    if (currentTab?.AutoParking === "1") {
                      setMenu(0);
                    } else {
                      setMenu(undefined);
                    }
                  }}
                >
                  <Typography 
                    category="Default" 
                    variant="Small"
                    htmlColor={LightColors.primary["7"]}
                  >
                    {t("Motion Sensitivity")}
                  </Typography>
                  <IconButton
                    onClick={() => {}}
                    // uxui 설정3차수정 - Motion detection이 선택된 경우에만 활성화 적용.(Leehj)
                    className={clsx({
                      [classes.cursorDefault]: currentTab?.AutoParking !== "1",
                    })}
                  >
                    <ChevronRightIcon className={classes.sensitivityIcon} />
                  </IconButton>
                </div>

                <Divider className={classes.parkingModeDivider} />

                { /* Real Camera Recording */ }
                <div className={classes.flex}>
                  <div className={classes.switch}>
                    <Switch
                      // mantis - 12656, Parking Mode Recording off 시 해당 항목 비활성화 적용(Leehj)
                      disabled={currentTab?.AutoParking === "0"}
                      checked={
                        RearPKEY
                          ? !getBoolean(currentTab?.RearParkingMode)
                          : getBoolean(currentTab?.RearParkingMode)
                      }
                      onChange={(e) => {
                        if (RearPKEY) {
                          handleParkingChange(
                            "RearParkingMode",
                            !e.target.checked
                          );
                        } else {
                          handleParkingChange(
                            "RearParkingMode",
                            e.target.checked
                          );
                        }
                      }}
                    />
                  </div>
                  <div
                    className={classes.column}
                    style={{ marginLeft: mobile ? 8 : 4 }}
                  >
                    <Typography
                      category="Default"
                      variant="Body"
                      style={{ marginBottom: 3 }}
                      // mantis - 12656, Parking Mode Recording off 시 해당 항목 비활성화 적용(Leehj)
                      className={clsx(
                        currentTab?.AutoParking === "0" && classes.disabledText
                      )}
                    >
                      {t("Rear camera recording")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["3"]}
                      // mantis - 12656, Parking Mode Recording off 시 해당 항목 비활성화 적용(Leehj)
                      className={clsx(
                        currentTab?.AutoParking === "0" && classes.disabledText
                      )}
                    >
                      {getBoolean(currentTab?.RearParkingMode) === true
                        ? t("Front and rear_")
                        : HIGHTEMPER_PROTECTION_MODAL
                          ? t("Rear camera will keep_")
                          : t("Rear camera stops_")}
                    </Typography>
                  </div>
                </div>

                <Divider className={classes.parkingModeDivider} />

                { /* Optional camera recording */ }
                {_.includes(OPTION_CAMERA_MODELS, camera?.model) && (
                  <>
                    <div className={classes.flex}>
                      <div className={classes.switch}>
                        <Switch
                          disabled={currentTab?.AutoParking === "0"}
                          checked={getBoolean(currentTab?.OptionRearParkingMode)}
                          onChange={(e: any) => {
                            handleParkingChange(
                              "OptionRearParkingMode",
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                      <div
                        className={classes.column}
                        style={{ marginLeft: mobile ? 8 : 4 }}
                      >
                        <Typography
                          category="Default"
                          variant="Body"
                          className={clsx(
                            currentTab?.AutoParking === "0" && classes.disabledText
                          )}
                          style={{ marginBottom: 3 }}
                        >
                          {t("Option camera recording_")}
                        </Typography>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["3"]}
                          className={clsx(
                            currentTab?.AutoParking === "0" && classes.disabledText
                          )}
                        >
                          {getBoolean(currentTab?.OptionRearParkingMode)
                            ? t("The optional camera records_")
                            : t("Option camera stops_")}
                        </Typography>
                      </div>
                    </div>

                    <Divider className={classes.parkingModeDivider} />
                  </>
                )}                

                { /* Battery Protection */ }
                <div className={clsx(classes.flex)}>
                <div className={classes.switch}>
                  <Switch
                    // mantis - 12656, Parking Mode Recording off 시 해당 항목 비활성화 적용(Leehj)
                    disabled={currentTab?.AutoParking === "0"}
                    checked={currentTab3?.Battery !== "0"}
                    onChange={(e) => {
                      if (BATTERY_PROTECTION_MODAL) {
                        setOpenBatteryModal(true);
                        setSelectedField("Battery");
                        setSelectedValue(e.target.checked);
                      } else {
                        handleChange("Battery", e.target.checked);
                      }
                    }}
                  />
                </div>
                <div>
                  <Typography
                    category="Default"
                    variant="Body"
                    // mantis - 12656, Parking Mode Recording off 시 해당 항목 비활성화 적용(Leehj)
                    className={clsx(
                      currentTab?.AutoParking === "0" && classes.disabledText
                    )}
                  >
                    {t("Battery protection")}
                  </Typography>
                </div>
              </div>
              {batteryRevMarkup}

              </div>
            </Card>
          </div>

          <Button
            className={classes.button}
            color="primary"
            onClick={() => {
              // mantis - 11441, Parking mode recording > 타입랩스나 모션으로 변경시 FirmwareSettingModal open(on/off는 해당 안됨) (Leehj)
              if (
                currentTab?.AutoParking !== "0" &&
                currentOrgTab1?.AutoParking !== "0" &&
                currentTab?.AutoParking !== currentOrgTab1?.AutoParking
              ) {
                setOpenFirmwareModal(true);
                // mantis - 11203, DR900S의 VCodecType은 레코딩화면으로 이동되면서 해당 코드 추가 (Leehj)
              } else {
                handleUpdate();
              }
            }}
            disabled={disabledChange}
            loading={loading && type === updateFirmwareSettings.type}
          >
            {t("Save")}
          </Button>
          <RecordingModal
            open={openBatteryModal}
            onClickNegative={() => setOpenBatteryModal(false)}
            onClickPositive={() => {
              if (selectedField === "Battery") {
                handleChange(selectedField, selectedValue);
              } else if (selectedField === "LowvoltageVolt") {
                if (selectedValue !== undefined) {
                  if (settings?.["BatteryProtectionRev"]) {
                    handleChangeLowVoltRev(selectedField, selectedValue);
                  } else {
                    handleChangeLowVolt(selectedField, selectedValue);
                  }
                } else {
                  setBatteryPRevChanged(true);
                }
              } else if (selectedField === "LowvoltageTime") {
                if (selectedValue !== undefined) {
                  if (settings?.["BatteryProtectionRev"]) {
                    handleChangeCutoffTimerRev(selectedField, selectedValue);
                  } else {
                    handleChange(selectedField, selectedValue);
                  }
                } else {
                  setCutoffTimerRevChanged(true);
                }
              } else if (selectedField === "LowvoltageVoltHeavy") {
                setBatteryHRevChanged(true);
              }
              setOpenBatteryModal(false);
            }}
          />
        </div>

        {/* Motion detection화면 //주석변경(Leehj)*/}
        <div
          className={clsx(classes.listPane, {
            [classes.rotationHide]: menu !== 0,
          })}
        >
          <MotionDetectionSensitivityScreen
            onClose={() => {
              setMenu(undefined);
            }}
            currentTab2={currentTab2}
            onSensitivityChange={(key: keyof TabNameInfo2, val: string) => {
              setCurrentTab2((c) => ({ ...c, [key]: val } as TabNameInfo2));
            }}
            {...props}
          />
        </div>
      </div>
      <FirmwareSettingModal
        open={openFirmwareModal}
        loading={loading}
        onClose={() => setOpenFirmwareModal(false)}
        onClickNegative={() => setOpenFirmwareModal(false)}
        onClickPositive={() => {
          handleUpdate();
          setOpenFirmwareModal(false);
        }}
      />
      {openDiscardModal && (
        <DiscardChangeUXUIModal
          open={openDiscardModal}
          onClose={() => {
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          // mantis - 11445, 디자인 변경되면서 버튼 기능도 변경 (Leehj)
          onClickNegative={() => {
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          onClickPositive={() => {
            handleUpdate();
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
        />
      )}
    </div>
  );
};
