import { useMediaQuery } from "@material-ui/core";
import {
  // Direction,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CAMERA } from "../features/Camera/slice";
import {
  PUSH_EVENT,
  clearEvent,
  removeEventAt,
  clearNormalEvent,
} from "../features/PushEvent/slice";
import { RootState } from "../features/store";
import { Notification } from "@thingsw/pitta-design-system";
import { finishLiveview, startLiveView } from "../features/LiveView/slice";
import {
  Webviewer,
  ICameraInfo,
  Front,
  DMS_EVENTS,
  MODELS_2CH,
  Rear,
  Interior,
  Direction,
  WebPushEvent,
} from "@thingsw/pitta-modules";
import { useLocation } from "react-router-dom";
import { LiveViewMapboxModal } from "./uxui/LiveViewMapboxModal";
import { VideoPlayerMapboxUXUIModal } from "./uxui/VideoPlayerMapboxUXUIModal";

const useStyles = makeStyles((theme: Theme) => ({
  notificationModal: {
    position: "fixed",
    bottom: theme.spacing(2),
    ...(theme.direction === "rtl"
      ? { left: theme.spacing(4) }
      : { right: theme.spacing(4) }),
    transition: theme.transitions.create("opacity"),
    zIndex: 999,
  },
  modalHide: {
    opacity: 0,
    display: "none",
  },
}));

const WebPushNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { webPushEvent, webPushEvents2 } = useSelector(
    (state: RootState) => state[PUSH_EVENT]
  );
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [currentPushEvent, setCurrentPushEvent] = useState<WebPushEvent>();
  //8868 - DMC 이벤트의 경우 Interior영상 노출되도록 수정
  const [dir, setDir] = useState<Direction>(Front);
  const location = useLocation();
  const pathnames = location.pathname;

  useEffect(() => {
    if (webPushEvent) {
      const timerId = setTimeout(() => dispatch(clearEvent()), 8 * 1000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [dispatch, webPushEvent]);

  useEffect(() => {
    const timerId = setInterval(() => dispatch(clearNormalEvent()), 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [dispatch]);

  // mantis - 12491, pathnames이 바뀌면 VideoModal,LiveModal close (Leehj)
  useEffect(() => {
    setCurrentCam(undefined);
    setCurrentPushEvent(undefined);
    setOpenVideoModal(false);
    setOpenLiveModal(false);
  }, [pathnames]);

  useEffect(() => {
    if (!openLiveModal) {
      setDir(Front);
    }
  }, [openLiveModal]);

  // const convertWebPushEventToLatestEvent = useCallback(
  //   (webpush: WebPushEvent) => {
  //     const filenames = webpush.event_filename?.split("_");
  //     const vdate = filenames
  //       ? moment(`${filenames[0]} ${filenames[1]}`, "YYYYMMDD HHmmss")
  //       : undefined;
  //     return {
  //       psn: webpush.psn,
  //       msg_code: webpush.msg_code,
  //       msg: webpush.alert,
  //       img_url: webpush.image_url,
  //       event_file: webpush.event_filename,
  //       vdate,
  //     };
  //   },
  //   []
  // );

  const convertWebPushEventToUXUILatestEvent = useCallback(
    (webpush: WebPushEvent) => {
      const filenames = webpush.event_filename?.split("_");
      const cdate = filenames
        ? moment(`${filenames[0]} ${filenames[1]}`, "YYYYMMDD HHmmss")
        : undefined;
      return {
        // IUXUILatestEvent 타입 적용을 위해 _id값 임의적용
        _id: "",
        psn: webpush.psn,
        alarm_type: webpush.msg_code,
        msg: webpush.alert,
        img_url: webpush.image_url,
        event_file: webpush.event_filename,
        cdate,
      };
    },
    []
  );

  const videoPlayerModal = useMemo(() => {
    if (currentCam && currentPushEvent && openVideoModal) {
      return (
        <VideoPlayerMapboxUXUIModal
          open={openVideoModal}
          camera={currentCam}
          mode={0}
          event={convertWebPushEventToUXUILatestEvent(currentPushEvent)}
          // event={convertWebPushEventToLatestEvent(currentPushEvent)}
          notification
          onClose={() => {
            setOpenVideoModal(false);
            setCurrentCam(undefined);
            setCurrentPushEvent(undefined);
          }}
        />

        // <VideoPlayerModal
        //   open={openVideoModal}
        //   camera={currentCam}
        //   mode={0}
        //   event={convertWebPushEventToLatestEvent(currentPushEvent)}
        //   onClose={() => {
        //     setOpenVideoModal(false);
        //     setCurrentCam(undefined);
        //     setCurrentPushEvent(undefined);
        //   }}
        // />
      );
    }
    return undefined;
  }, [
    convertWebPushEventToUXUILatestEvent,
    currentCam,
    currentPushEvent,
    openVideoModal,
  ]);

  // cameraList가 주기적으로 업데이트되기 때문에, notificationsMarkup도 자동으로 업데이트 됨...
  const notificationsMarkup = useMemo(() => {
    return _.chain(webPushEvents2)
      .slice(0, 8)
      .reverse()
      .map((event, i) => {
        const eventCam = _.find(
          cameraList?.deviceListInfo,
          (dev) => dev.device.psn === event.psn
        )?.device;

        let remaining = 90;
        let playable = false;
        let liveview = false;
        if (eventCam) {
          if (
            event.event_filename &&
            event.event_filename.indexOf("mp4") > -1
          ) {
            const time = event.date;
            const now = moment.tz();
            remaining -= now.diff(time, "s");
            if (remaining <= 0) {
              playable = true;
            }
          } else {
            playable = false;
          }

          // 7654 storage이벤트 liveview 버튼 disable
          //9951
          if (
            eventCam.active === "off" ||
            _.includes(
              [
                "ALARM_CLOUDSTORAGE",
                "DEVICE_DISCONNECT",
                "ALARM_SETTING_SAVED",
              ],
              event.msg_code
            )
          ) {
            liveview = false;
          } else {
            liveview = true;
          }

          // 7575
          // play할 수 없는 이벤트는 강제로 playable을 false로 처리
          // 7654 storage이벤트 playback버튼 disable
          //9951
          if (
            eventCam.active === "off" ||
            _.includes(
              [
                "DEVICE_CONNECT",
                "DEVICE_DISCONNECT",
                "ALARM_CLOUDSTORAGE",
                "ALARM_DETECTED",
                "ALARM_SETTING_SAVED",
              ],
              event.msg_code
            )
          ) {
            playable = false;
          }
        } else {
          playable = false;
        }

        return (
          <div style={{ marginTop: 4 }}>
            <Notification
              event={event}
              disablePlayback={!playable}
              disableLiveview={!liveview}
              onClose={() => {
                dispatch(removeEventAt(i));
              }}
              onLiveView={() => {
                if (eventCam) {
                  dispatch(finishLiveview());
                  setCurrentCam(eventCam);
                  setOpenLiveModal(true);
                  //8868 - DMC 이벤트의 경우 Interior영상 노출되도록 수정
                  if (_.includes(DMS_EVENTS, event?.msg_code)) {
                    if (_.includes(MODELS_2CH, eventCam.model)) {
                      setDir(Rear);
                    } else {
                      setDir(Interior);
                    }
                  }
                }
              }}
              onPlayback={() => {
                if (eventCam) {
                  setCurrentPushEvent(event);
                  setCurrentCam(eventCam);
                  setOpenVideoModal(true);
                }
              }}
            />
          </div>
        );
      })
      .value();
  }, [cameraList?.deviceListInfo, dispatch, webPushEvents2]);

  return (
    <>
      {!mobile && (
        <div className={clsx(classes.notificationModal)}>
          {notificationsMarkup}
        </div>
      )}

      {videoPlayerModal}
      {/* 변경된 디자인 적용 LiveViewModal -> LiveViewMapboxModal*/}
      <LiveViewMapboxModal
        open={openLiveModal}
        camera={currentCam}
        //8868 - DMC 이벤트의 경우 Interior영상 노출되도록 수정
        dir={dir}
        noMargin
        mobile={mobile}
        onClose={() => {
          setOpenLiveModal(false);
          setCurrentCam(undefined);
          dispatch(startLiveView());
        }}
      />
    </>
  );
};

export default WebPushNotification;
