import { PortalProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alerts, Modal } from "@thingsw/pitta-design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ERROR } from "../../features/Error/slice";
import { RootState } from "../../features/store";
import { Webviewer, ICameraInfo, Direction } from "@thingsw/pitta-modules";
import { LiveViewPanelWrapper } from "../cameras/LiveViewPanelWrapper";
import { PERMISSION } from "../../features/Permission/slice";

const useStyles = makeStyles((theme: Theme) => ({
  modalRoot: {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
  },
  modalContentDiv: {
    padding: theme.spacing(4, 0, 4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(4, 14.625, 4),
    },
  },
  modalTitle: {
    boxShadow: "border-box",
    minHeight: 48,
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(2, 2, 2, 1.625) }
      : { padding: theme.spacing(2, 1.625, 2, 2) }),
    textOverflow: "ellipsis",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(2, 4, 2, 3.625) }
        : { padding: theme.spacing(2, 3.625, 2, 4) }),
    },
  },
  closeStyle: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      right: 28,
    },
  },
}));

interface LiveViewModalProps {
  open: boolean;
  camera?: ICameraInfo;
  isPublic?: boolean;
  dir?: Direction;
  notification?: boolean;
  onClose: () => void;
  container?: PortalProps["container"];
}

export const LiveViewModal = ({
  open,
  camera,
  isPublic,
  dir,
  notification,
  onClose,
  container,
}: LiveViewModalProps) => {
  const { t } = useTranslation();
  const { error } = useSelector((state: RootState) => state[ERROR]);
  const { permission } = useSelector((state: RootState) => state[PERMISSION]);
  const classes = useStyles();
  if (open && camera) {
    console.log("open modal");
    return (
      <Modal
        className={classes.modalRoot}
        contentClassName={classes.modalContentDiv}
        open={open}
        onClose={onClose}
        content={
          <>
            {error && (
              <Alerts mode="web" severity="error">
                {t(error)}
              </Alerts>
            )}
            <LiveViewPanelWrapper
              isEvent
              camera={camera}
              isPublic={isPublic}
              //8868 - DMC 이벤트의 경우 Interior영상 노출되도록 수정
              dir={dir}
              notification={notification}
              ignoreRemove
              modal
              {...permission}
            />
          </>
        }
        titleClassName={classes.modalTitle}
        closeStyle={classes.closeStyle}
        container={container}
        close
      />
    );
  }
  return <></>;
};
