import React from "react";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";

const withSimpleBar = (WrappedComponent: React.FC<any>) => (props: any) => {
  // 모바일 스크롤 동작 않는 이슈 수정 (Hongcs) 23.09.06
  // const theme = useTheme() as Theme;
  // const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  // return mobile ? (
  //   <WrappedComponent {...props} />
  // ) : (
  return (
    <SimpleBarReact style={{ height: "calc(var(--vh, 1vh) * 100)" }}>
      <WrappedComponent {...props} />
    </SimpleBarReact>
  );
};

export default withSimpleBar;
