import React, { useEffect, useMemo, useState } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { PopperPlacementType } from "@material-ui/core/Popper";
import {
  CameraSettings,
  DashcamIcon,
  EditIcon,
  Menu,
  MicroSD,
  Trash,
  Typography,
  UnregisterBattery,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { CAMERA } from "../../features/Camera/slice";
import { useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import { RootState } from "../../features/store";
import { findNewFw } from "../../utils/Firmware";
import _ from "lodash";

import {
  LightColors,
  ICameraInfo,
  AvailableFw,
  ENABLED_CLOUD_OLD_MODELS,
  FORMAT_MICROSD_CARD_MODEL,
  SUPPORT_REBOOT_FW,
  MODEL_KEY,
  PermissionProps,
  OLD_MODELS,
} from "@thingsw/pitta-modules";
import moment from "moment";
import useAbleBatteryCloud from "../../hooks/useAbleBatteryCloud";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    // display: "block",
    // fill: LightColors.secondary["11"],
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  delete: {
    color: LightColors.secondary["11"],
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  newDiv: {
    backgroundColor: LightColors.secondary["11"],
    borderRadius: 12,
    width: 43,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
}));

export interface CameraMenuProps<T> {
  open: boolean;
  camera?: ICameraInfo;
  hideDivider?: boolean;
  anchorRef?: React.RefObject<T>;
  /**
   * Popper placement.
   */
  placement?: PopperPlacementType;

  onClickAway: React.MouseEventHandler<Document>;
  onEditProfile?: (() => void) | null;
  onSettings?: (() => void) | null;
  onUpdate?: ((fw?: AvailableFw) => void) | null;
  onDelete?: ((camera: ICameraInfo) => void) | null;
  onUnpairBattery?: ((camera: ICameraInfo) => void) | null;
  onRebootCamera?: (() => void) | null;
}

type CameraMenuComponent<T = HTMLElement> = React.FC<
  CameraMenuProps<T> & PermissionProps
>;

export const CameraMenu: CameraMenuComponent = ({
  open,
  camera,
  anchorRef,
  placement,
  onClickAway,
  onEditProfile,
  onSettings,
  onUpdate,
  onDelete,
  onRebootCamera,
  onUnpairBattery,
  rebootCamPerm,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const firmwares = useSelector((state: RootState) => state[CAMERA].firmwares);
  const [availableNewFw, setAvailableNewFw] = useState<AvailableFw>();

  const isAbleBatteryCloud = useAbleBatteryCloud(camera);
  const isNoneCloudModel = _.includes(
    _.filter(OLD_MODELS, (d) => !ENABLED_CLOUD_OLD_MODELS.includes(d)),
    camera?.model
  );

  useEffect(() => {
    if (camera) {
      const newFw = findNewFw(camera, firmwares);
      setAvailableNewFw(newFw);
      // setAvailableNewFw(!!(newFw.blackvue || newFw.dmc200));
    }
  }, [camera, firmwares]);

  // mantis - 12440, 메뉴 조건 변경- Format microSD card 지원모델중 590x모델 (Leehj)
  const FormatMicroSDCard590Model = useMemo(() => {
    return (
      (camera?.model === "DR590X-1CH" && Number(camera?.fw_ver) >= 1.005) ||
      (camera?.model === "DR590X-2CH" && Number(camera?.fw_ver) >= 1.005)
    );
  }, [camera?.fw_ver, camera?.model]);

  // mantis - 12676, 클라우드 등록되었고, 서버와 통신이력 X / 카메라알기위함 (Leehj)
  const loggined = useMemo(() => {
    if (camera) {
      return !camera.login_date
        ? false
        : !moment(camera.login_date).isBefore(moment.utc(0));
    }
  }, [camera]);

  // mantis - 11258, 구모델은 무조건 해당항목 노출 안되도록 수정 (Leehj)
  // const connectionSettingsOption = useMemo(() => {
  //   if (
  //     _.includes(NEW_MODELS, camera?.model) ||
  //     _.includes(SIGMASTAR_MODELS, camera?.model)
  //   ) {
  //     return true;
  //   } else if (camera?.reg_category === "wifi") {
  //     if (
  //       _.includes(NEW_MODELS, camera?.model) ||
  //       _.includes(SIGMASTAR_MODELS, camera?.model)
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }, [camera?.model, camera?.reg_category]);

  return (
    <Menu
      open={open}
      anchorEl={anchorRef?.current}
      onClickAway={onClickAway}
      placement={placement}
      modifiers={{ preventOverflow: { enabled: false } }}
      style={{ zIndex: 101, position: "absolute" }}
    >
      {onSettings !== undefined && (
        <WebMenuItem
          disabled={
            onSettings === null ||
            camera?.valid === "invalid" ||
            camera?.reg_category === "wifi" ||
            // mantis - 12676, 클라우드 등록되었고, 서버와 통신이력 없는 카메라 해당항목 비활성화 (Leehj)
            !loggined ||
            isNoneCloudModel
          }
          startIcon={<CameraSettings />}
          endIcon={
            camera?.require_time_sync && (
              <div
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: LightColors.secondary["11"],
                }}
              />
            )
          }
          onClick={onSettings === null ? undefined : onSettings}
        >
          {t("Camera settings")}
        </WebMenuItem>
      )}

      {/* mantis - 10628, Screen ID C0160기획 내용에 따라 wifi인 경우에도 해당항목 노출되도록 수정(Leehj)*/}
      {/* mantis - 10379,10492 Screen ID C0161기획 내용에 따라 wifi이거나 구모델이 아니면 해당항목 노출되도록 수정(Leehj)*/}
      {/* mantis - 11204, 문구 출력 조건 변경- wifi이거나 신모델, 시그마모델이면 Cloud connection settings 항목 노출(Leehj) */}
      {/* {connectionSettingsOption && (
        <WebMenuItem disabled startIcon={<CloudSync />}>
          {t("Cloud connection settings")}
        </WebMenuItem>
      )} */}

      <WebMenuItem
        disabled={onEditProfile === null || camera?.valid === "invalid"}
        startIcon={<EditIcon />}
        onClick={onEditProfile === null ? undefined : onEditProfile}
      >
        {/* mantis - 10379,10492 Screen ID C0161기획 내용에 따라 ONLY WIFI인 구모델인 경우 Rename camera 항목 노출되도록 수정(Leehj)*/}
        {/* mantis - 11204, 문구 출력 조건 변경- 클라우드 사용가능한 모델이 아니면 Rename camera 항목 노출(Leehj) */}
        {t(
          // _.includes(ENABLED_CLOUD_OLD_MODELS, camera?.model) ||
          //   _.includes(NEW_MODELS, camera?.model) ||
          //   _.includes(SIGMASTAR_MODELS, camera?.model)
          // "NEW_MODELS" 코드 제거, 클라우드가 가능하지 않은 구모델을 제외한 모든 모델로 수정 (hongcs 24.03.25)
          !isNoneCloudModel
            ? "Camera profile"
            : "Rename camera"
        )}
      </WebMenuItem>

      {onUpdate !== undefined && (
        <WebMenuItem
          startIcon={
            <div className={classes.icon}>
              <DashcamIcon />
            </div>
          }
          endIcon={
            availableNewFw && (
              <div className={classes.newDiv}>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["0"]}
                >
                  NEW
                </Typography>
              </div>
            )
          }
          disabled={
            onUpdate === null ||
            camera?.active === "off" ||
            // mantis - 11258, 구모델 wifi 연결 시 비활성화 노출로 수정 (Leehj)
            camera?.reg_category === "wifi" ||
            isNoneCloudModel
          }
          onClick={
            onUpdate === null ? undefined : () => onUpdate(availableNewFw)
          }
        >
          {t("Remote firmware update")}
        </WebMenuItem>
      )}

      {onRebootCamera !== undefined && (
        <WebMenuItem
          startIcon={<RefreshIcon />}
          disabled={
            onRebootCamera === null ||
            camera?.active === "off" ||
            camera?.reg_category === "wifi" ||
            // mantis - 10498,11399 Screen ID C0161기획 내용에 따라 모든 모델 해당항목 노출되어 rebootCamPerm권한이 없는 경우 비활성화 되도록 수정(Leehj)
            !rebootCamPerm ||
            // mantis - 10379, Screen ID C0161기획 내용에 따라 모든 모델 해당항목 노출되도록 수정하면서 아래 조건인 경우 비활성화 되도록 수정(Leehj)*/}
            SUPPORT_REBOOT_FW[camera?.model as MODEL_KEY] >
              parseFloat(camera?.fw_ver ?? "") ||
            isNoneCloudModel
          }
          onClick={onRebootCamera === null ? undefined : onRebootCamera}
        >
          {t("Reboot Camera")}
        </WebMenuItem>
      )}

      {/* mantis - 10379, Screen ID C0161기획 내용에 따라 wifi이거나 구모델이 아니면 해당항목 노출되도록 수정(Leehj)*/}
      {/* mantis - 11204, 문구 출력 조건 변경- wifi이거나 신모델,시그마모델이면 Format microSD card 항목 노출(Leehj) */}
      {/* mantis - 12440, 메뉴 조건 변경- wifi이면서 Format microSD card 지원모델이면 해당 항목 노출(Leehj) */}
      {camera?.reg_category === "wifi" &&
        ((camera && FORMAT_MICROSD_CARD_MODEL[camera.model]) ||
          FormatMicroSDCard590Model) && (
          <WebMenuItem disabled startIcon={<MicroSD />}>
            {t("Format microSD card")}
          </WebMenuItem>
        )}

      {onUnpairBattery && camera?.battery && isAbleBatteryCloud && (
        <WebMenuItem
          onClick={() => onUnpairBattery(camera)}
          startIcon={<UnregisterBattery />}
        >
          {t("Unpair Battery")}
        </WebMenuItem>
      )}

      {onDelete !== undefined && (
        <WebMenuItem
          disabled={onDelete === null}
          className={classes.delete}
          startIcon={<Trash htmlColor={LightColors.secondary["11"]} />}
          onClick={
            onDelete === null ? undefined : () => camera && onDelete(camera)
          }
        >
          {t(
            camera?.reg_category === "wifi"
              ? "Delete camera"
              : "Remove from cloud"
          )}
        </WebMenuItem>
      )}
    </Menu>
  );
};
