import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from "../../apis";
import { setError } from "../Error/slice";

import {
  failLoad,
  IGoogleUserInfo,
  loadGoogleUserInfo,
  successLoadGoogleUserInfo,
} from "./slice";

function* handleLoadGoogleUser({
  payload,
}: PayloadAction<{
  accessToken: string;
}>) {
  try {
    const res = yield call(Api.getGoogleUserInfo, payload.accessToken);
    const data = res.data as IGoogleUserInfo;
    yield put(successLoadGoogleUserInfo(data));
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

export function* watchSocialUser() {
  yield takeLatest(loadGoogleUserInfo, handleLoadGoogleUser);
}
