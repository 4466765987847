import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Typography } from "@thingsw/pitta-design-system";
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bodyText: {
    maxWidth: 906,
    textAlign: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  body6: {
    padding: `${theme.spacing(8)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(16)}px ${theme.spacing(4)}px`,
      alignItems: "center",
    },
    backgroundColor: LightColors.primary["1"],
    "&>button": {
      minWidth: 252,
    },
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
}));
const TryBlackvue = () => {
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const fullBtn = useMediaQuery(theme.breakpoints.up("sm"));
  const category = matches ? "Large" : "Default";

  return (
    <div className={clsx(classes.body6, classes.flexColumn)}>
      <Typography
        category={category}
        variant="H2"
        className={clsx(classes.bodyText, classes.marginB2)}
        htmlColor={LightColors.primary["0"]}
      >
        {t("Try BlackVue Cloud")}
      </Typography>

      <Typography
        category="Default"
        variant="Body"
        className={clsx(classes.bodyText, classes.marginB4)}
        htmlColor={LightColors.primary["0"]}
      >
        {t("Individual driver looking_")}
        <br />
        {t("Whoever you are_")}
      </Typography>

      <Button
        color="primary"
        size="large"
        fullWidth={!fullBtn}
        onClick={() => {
          history.push("/pricing");
        }}
      >
        {t("Pick a plan")}
      </Button>
    </div>
  );
};

export default TryBlackvue;
