import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, SimCard, Typography } from "@thingsw/pitta-design-system";

import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import SimCardIcon from "@material-ui/icons/SimCard";
import WifiIcon from "@material-ui/icons/Wifi";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { Webviewer, LightColors, SIMCARD_URI } from "@thingsw/pitta-modules";

export type ConnectType = "BlackvueSim" | "SimCard" | "Wifi";

const useStyles = makeStyles((theme: Theme) => ({
  connectFormWrap: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 502,
      padding: 0,
      marginTop: 97,
    },
  },

  marginB4: {
    marginBottom: theme.spacing(4) + 1,
  },
  contentDiv: {
    marginBottom: theme.spacing(3) + 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(5),
    },
  },
  marginB05: {
    marginBottom: theme.spacing(0.5),
  },

  icon: {
    fontSize: 32,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(3) - 1 }
      : { marginRight: theme.spacing(3) - 1 }),
  },
  chooseBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(2),
    borderColor: `${LightColors.primary["5"]}`,
    minHeight: 104,
    "&:hover": {
      "& *": {
        color: LightColors.primary["7"],
        fill: LightColors.primary["7"],
      },
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(1.875, 2.875),
      // minHeight: "auto",
      minHeight: 80,
    },
  },
  simCardPadding: {
    padding: theme.spacing(2, 2.875),
  },
  simCardHeight: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 104,
    },
  },
  select: {
    backgroundColor: `${LightColors.primary["10"]}`,
    "& *": {
      color: LightColors.primary["7"],
      fill: LightColors.primary["7"],
    },
    "&:hover": {
      backgroundColor: `${LightColors.primary["10"]}`,
    },
  },
  textLeft: {
    textAlign: "left",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  btnDiv: {
    marginTop: theme.spacing(5),
  },
  nextBtn: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(2) }
        : { marginRight: theme.spacing(2) }),
    },
  },
}));

const ChooseConnectForm = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);

  const [connectType, setConnectType] = useState<ConnectType>();

  const renderChooseBox = useCallback(
    (
      icon: React.ReactNode,
      type: string,
      modelInfo: string,
      chooseType: ConnectType
    ) => {
      return (
        <Button
          className={clsx(classes.chooseBox, {
            [classes.select]: connectType === chooseType,
            [classes.simCardHeight]: chooseType === "SimCard",
            [classes.simCardPadding]:
              chooseType === "SimCard" ||
              (chooseType === "BlackvueSim" && mobile),
          })}
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => setConnectType(chooseType)}
        >
          {icon}
          <div className={classes.flexColumn}>
            <Typography
              category="Default"
              variant="H5"
              className={clsx(classes.textLeft, classes.marginB05)}
            >
              {t(type)}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["3"]}
              className={classes.textLeft}
            >
              {t(modelInfo)}
            </Typography>
          </div>
        </Button>
      );
    },
    [
      classes.chooseBox,
      classes.flexColumn,
      classes.marginB05,
      classes.select,
      classes.simCardHeight,
      classes.simCardPadding,
      classes.textLeft,
      connectType,
      mobile,
      t,
    ]
  );

  return (
    <div className={classes.connectFormWrap}>
      <div className={classes.marginB4}>
        <Typography category="Default" variant={mobile ? "H2" : "H1"}>
          {t("Choose a cloud_")}
        </Typography>
      </div>
      <div className={classes.contentDiv}>
        <Typography category="Default" variant="Body">
          {t("You need an_")}
        </Typography>
      </div>
      {renderChooseBox(
        <SimCardIcon className={classes.icon} />,
        "BlackVue SIM card",
        "Support model_",
        "BlackvueSim"
      )}
      {renderChooseBox(
        <SimCard className={classes.icon} />,
        "3rd party SIM_",
        "Support model_SIM",
        "SimCard"
      )}
      {renderChooseBox(
        <WifiIcon className={classes.icon} />,
        "Wi-fi hotspot",
        "Support model_Wifi",
        "Wifi"
      )}

      <div className={classes.btnDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.nextBtn}
          disabled={connectType === undefined}
          fullWidth={mobile}
          onClick={() => {
            if (connectType !== "BlackvueSim") {
              history.push("/cameras/connect-to-cloud");
            } else {
              const lang = i18n.language;
              window.location.href = `${SIMCARD_URI}/webviewer/add-sim?email=${email}&user_token=${loginInfo?.user_token}&token_type=web&lang=${lang}`;
            }
          }}
        >
          {t("Next")}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth={mobile}
          onClick={() => {
            setConnectType(undefined);
            history.push("/cameras");
          }}
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
};
export default ChooseConnectForm;
