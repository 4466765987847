import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IPushEventState,
  WebPushEvent,
  DMS_EVENTS,
} from "@thingsw/pitta-modules";
import _ from "lodash";
import moment from "moment";

let initialState: IPushEventState = {
  loading: false,
  newEvent: false,
  webPushEvents: {},
  webPushEvents2: [
    // {
    //   event_filename: "",
    //   image_url: "",
    //   alert: "[BlackVue900X-E4023E] 주차모드에서 빠져나왔습니다.",
    //   msg_code: "ALARM_PARK_OUT",
    //   psn: "DR9XS1JAE04093",
    //   date: moment(),
    // },
    // {
    //   event_filename: "",
    //   image_url: "",
    //   alert: "[BlackVue970X-2CH-LTE] Connected battery capacity at 19%",
    //   msg_code: "ALARM_BATTERY_DISCHARGE",
    //   psn: "DR9XS1JAE04093",
    //   date: moment(),
    // },
    // {
    //   event_filename: "20220330_203250_IF.mp4",
    //   image_url:
    //     "https://s3-us-west-1.amazonaws.com/alarm-cal.blackvuecloud.com/gteZwRqBbTKe4ADw/schDydueKzgElAhR/20220330_203258.jpg",
    //   alert: "[BlackVue900X-E4023E] 주차모드 중에 충격이 발생하였습니다.",
    //   msg_code: "ALARM_PARK_EVENT",
    //   psn: "DR9XS1JAE04093",
    //   date: moment(`20220330 203250`, "YYYYMMDD HHmmss"),
    // },
    // {
    //   event_filename: "20220330_155304_DO.mp4",
    //   image_url:
    //     "https://s3-us-west-1.amazonaws.com/alarm-cal.blackvuecloud.com/6I0wUdp7XZRyiwnU/TUCnB38Hutr1OwCd/20220330_155317.jpg",
    //   alert: "[BlackVue7Box-E8156C] 졸음이 감지되었습니다.",
    //   msg_code: "ALARM_DROWSY",
    //   psn: "75LPGLGK801199",
    //   date: moment(`20220330 155304`, "YYYYMMDD HHmmss"),
    // },
    // {
    //   event_filename: "",
    //   image_url:
    //     "https://s3-us-west-1.amazonaws.com/alarm-cal.blackvuecloud.com/6I0wUdp7XZRyiwnU/TUCnB38Hutr1OwCd/20220330_114351.jpg",
    //   alert: "[BlackVue7Box-E8156C] Mask off has been detected.",
    //   msg_code: "ALARM_MASK_OFF",
    //   psn: "BK7BS3L3E00004",
    //   date: moment(),
    // },
    // {
    //   event_filename: "20220330_155304_DO.mp4",
    //   image_url:
    //     "https://s3-us-west-1.amazonaws.com/alarm-cal.blackvuecloud.com/6I0wUdp7XZRyiwnU/TUCnB38Hutr1OwCd/20220330_155317.jpg",
    //   alert: "[BlackVue7Box-E8156C] 졸음이 감지되었습니다.",
    //   msg_code: "ALARM_DROWSY_SECOND",
    //   psn: "BK7BS3L3E00004",
    //   date: moment(`20220330 155304`, "YYYYMMDD HHmmss"),
    // },
    // {
    //   event_filename: "",
    //   image_url:
    //     "https://s3-us-west-1.amazonaws.com/alarm-cal.blackvuecloud.com/6I0wUdp7XZRyiwnU/TUCnB38Hutr1OwCd/20220330_114351.jpg",
    //   alert: "[BlackVue7Box-E8156C] Mask off has been detected.",
    //   msg_code: "ALARM_MASK_OFF",
    //   psn: "BK7BS3L3E00004",
    //   date: moment(),
    // },
  ],
  openNotification: false,
};

const slice = createSlice({
  name: "push-event",
  initialState,
  reducers: {
    resetPushEvent: (state) => {
      state.loading = false;
      state.mobileToken = undefined;
      state.newEvent = false;
      state.webPushEvents = {};
      // 8742 - 로그아웃시, 기존 push event 초기화
      state.webPushEvents2 = [];
      state.webPushEvent = undefined;
    },
    clearPushEvent: (state) => {
      state.mobileToken = undefined;
    },
    loadPushToken: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    successLoadPushToken: (state, { payload }: PayloadAction<string>) => {
      state.mobileToken = payload;
    },
    connectWebPush: (state) => {},
    disconnectWebPush: (state) => {},
    setNewEvent: (state) => {
      state.newEvent = true;
    },
    clearNewEvent: (state) => {
      state.newEvent = false;
    },
    // 8776 - 로그인시, 저장되어있는 push event 초기화 (mckim)
    loadStoredEvent: (state, { payload }: PayloadAction<WebPushEvent[]>) => {
      // 8750 - 정책변경으로 DMS 이벤트 1개만 표시되도록 변경
      state.webPushEvents2 = _.slice(payload, 0, 1);
    },
    updateEvent: (state, { payload }: PayloadAction<WebPushEvent>) => {
      const isAI = _.includes(DMS_EVENTS, payload.msg_code);
      //10738 - Park in/out은 지도에 이벤트 표시하지 않음
      if (!_.includes(["ALARM_PARK_IN", "ALARM_PARK_OUT"], payload.msg_code)) {
        state.webPushEvent = payload;
      }

      state.webPushEvents = {
        ...state.webPushEvents,
        [payload.psn]: payload,
      };

      // 8786 - AI 이벤트외에는 기존 정책대로 덮어쓰기 방식
      if (!isAI) {
        state.webPushEvents2 = [
          { ...payload, date: moment() },

          // 8750 - 정책변경으로 DMS 이벤트 1개만 표시되도록 변경
          ..._.filter(state.webPushEvents2, (e) =>
            _.includes(DMS_EVENTS, e.msg_code)
          ).slice(0, 1),
        ];
      } else {
        state.webPushEvents2 = [
          ..._.filter(
            state.webPushEvents2,
            (e) => !_.includes(DMS_EVENTS, e.msg_code)
          ).slice(0, 1),
          // 8750 - 정책변경으로 DMS 이벤트 1개만 표시되도록 변경
          { ...payload, date: moment() },
        ];
      }

      // 8776 - 로그아웃시, 열려있는 push event 저장 초기화 (mckim)
      if (state.email) {
        const storedNotifications = JSON.parse(
          localStorage.getItem("pitta-webviewer:notification-history") ?? "{}"
        );
        localStorage.setItem(
          "pitta-webviewer:notification-history",
          JSON.stringify({
            ...storedNotifications,
            [state.email]: _.filter(state.webPushEvents2, (n) =>
              _.includes(DMS_EVENTS, n.msg_code)
            ),
          })
        );
      }
    },
    // 8786 - AI 이벤트외에는 기존 정책대로 덮어쓰기 방식
    clearNormalEvent: (state) => {
      const events = state.webPushEvents2;
      state.webPushEvents2 = _.filter(events, (e) => {
        if (_.includes(DMS_EVENTS, e.msg_code)) {
          return true;
        }
        if (moment().diff(e.date, "s") < 8) {
          return true;
        }
        return false;
      });
    },
    clearEvents: (state, { payload }: PayloadAction<string>) => {
      state.webPushEvents = _.omit(state.webPushEvents, payload);
    },
    removeEventAt: (state, { payload }: PayloadAction<number>) => {
      const candi = _.reverse(state.webPushEvents2);
      state.webPushEvents2 = _.reverse([
        ..._.slice(candi, 0, payload),
        ..._.slice(candi, payload + 1),
      ]);
      // 8776 - 로그아웃시, 열려있는 push event 저장 초기화 (mckim)
      if (state.email) {
        const storedNotifications = JSON.parse(
          localStorage.getItem("pitta-webviewer:notification-history") ?? "{}"
        );
        localStorage.setItem(
          "pitta-webviewer:notification-history",
          JSON.stringify({
            ...storedNotifications,
            [state.email]: _.filter(state.webPushEvents2, (n) =>
              _.includes(DMS_EVENTS, n.msg_code)
            ),
          })
        );
      }
    },
    clearEvent: (state) => {
      state.webPushEvent = undefined;
    },
    setOpenNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.openNotification = payload;
    },
  },
});

export const {
  resetPushEvent,
  connectWebPush,
  disconnectWebPush,
  clearPushEvent,
  loadPushToken,
  successLoadPushToken,
  setNewEvent,
  clearNewEvent,
  loadStoredEvent,
  updateEvent,
  clearEvent,
  setOpenNotification,
  removeEventAt,
  clearNormalEvent,
} = slice.actions;

export const PUSH_EVENT = slice.name;
export default slice.reducer;
