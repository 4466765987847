import { ICameraInfo } from "@thingsw/pitta-design-system";
import { END, eventChannel } from "redux-saga";
import {
  actionChannel,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from "redux-saga/effects";
import {
  successLoadCameraCluster,
  successLoadCameraLocation,
} from "../Camera/slice";
import { RootState } from "../store";
import { USER } from "../User/slice";
import {
  closeGPSWs,
  initGPSWs,
  setGPSWs,
  setGPSWsReadyState,
  setGpsPsnsMyDevices,
} from "./slice";
import { IUserLoginInfo, GPS_WS_SERVER_URI } from "@thingsw/pitta-modules";
function createSocketChannel(ws: WebSocket) {
  return eventChannel((emit) => {
    ws.onmessage = (ev: any) => {
      const data = JSON.parse(ev.data);
      // console.log("CloudNative3", "GPS_WS", "onmessage", data);
      emit(data);
    };

    ws.onopen = (ev: any) => {
      emit(WebSocket.OPEN);
    };

    ws.onclose = () => {
      console.log("CloudNative3", "GPS_WS", "createSocketChannel", "onclose");
      emit(WebSocket.CLOSED);
      emit(END);
    };

    return () => {
      console.log(
        "CloudNative3",
        "GPS_WS",
        "createSocketChannel",
        "unsubscribe"
      );
      ws.onmessage = null;
    };
  });
}

function* handleInitGPSWs() {
  let gpsWS;
  let gpsWSChannel;
  try {
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    gpsWS = new WebSocket(
      `${GPS_WS_SERVER_URI}?token=${loginInfo.jsonwebtoken}`
    );

    gpsWSChannel = yield call(createSocketChannel, gpsWS);

    while (true) {
      const data = yield take(gpsWSChannel);
      if (typeof data === "number") {
        if (data === WebSocket.OPEN) {
          yield put(setGPSWs(gpsWS));
          yield put(setGPSWsReadyState(data));
        } else if (data === WebSocket.CLOSED) {
          yield put(setGPSWs(gpsWS));
          yield put(setGPSWsReadyState(data));
          break;
        }
      } else {
        if (data.resultcode === "BC_ERR_OK") {
          const response = data.response;
          const camInfos: ICameraInfo[] | undefined =
            response.action === "gpsPsnsSpecificDevice"
              ? [data.response.device_list]
              : data.response.device_list;
          const { myzone, zone } = data.response;
          if (camInfos) {
            yield put(successLoadCameraCluster({}));
            yield put(
              successLoadCameraLocation({
                cams: camInfos,
                clearCam: response.action === "gpsZoneAllDevices",
              })
            );
          } else {
            yield put(successLoadCameraCluster({ myzone, zone }));
          }
          if (response.action === "gpsPsnsMyDevices") {
            yield put(setGpsPsnsMyDevices(true));
          }
        }
      }
      // console.log("CloudNative3", "GPS_WS", "subscribe", data);
    }
  } catch (err) {
    console.error(err);
  } finally {
    if (yield cancelled()) {
      console.log(
        "CloudNative3",
        "GPS_WS",
        "handleConnectWebPush",
        "cancelled"
      );
      gpsWS?.close();

      yield put(setGPSWsReadyState(WebSocket.CLOSED));
    }
  }
}

export function* watchLocationWS() {
  const requestChan = yield actionChannel([initGPSWs, closeGPSWs]);
  let lastTask;
  while (true) {
    const { type } = yield take(requestChan);
    switch (type) {
      case initGPSWs.type:
        if (lastTask) {
          yield cancel(lastTask);
        }
        lastTask = yield fork(handleInitGPSWs);
        break;
      case closeGPSWs.type:
        console.log("CloudNative3", "GPS_WS", "closeGPSWs", lastTask);
        if (lastTask) {
          yield cancel(lastTask);
        }
        break;
    }
  }
}
