import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import React, { forwardRef, useMemo } from "react";

// import { useTranslation } from "react-i18next";
import { Card, useMediaQuery } from "@material-ui/core";
import {
  Typography,
  Tooltip,
  IconButton,
  Avatars,
} from "@thingsw/pitta-design-system";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import GroupIcon from "@material-ui/icons/Group";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useSelector } from "react-redux";
import { CAMERA } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import AddIcon from "@material-ui/icons/Add";
import { DashCamList, LightColors } from "@thingsw/pitta-modules";

export interface CameraInfoProps {
  group: DashCamList;

  onMore?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    psn: string
  ) => void;
  onClick?: (group: DashCamList) => void;
  newFw?: boolean;
  disabled?: boolean;

  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  connectivityStyle?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: CameraInfoProps) => ({
    minHeight: "fit-content",
    cursor: "pointer",
    margin: "4px 16px",
    ...(props.disabled
      ? {
          cursor: "auto",
          opacity: 0.35,
          "&:hover div": {
            backgroundColor: LightColors.primary["0"],
          },
          "&:active div": {
            backgroundColor: LightColors.primary["0"],
          },
          "&:hover": {
            // color: LightColors.primary["3"],
          },
        }
      : {}),
    borderRadius: 16,
    boxShadow: `-2px 2px 12px -4px rgb(0 0 0 / 12%), 0px 2px 16px -4px rgb(0 0 0 / 16%)`,
  }),
  profile: {
    padding: "16px 16px 12px",
    // mantis 10483, 피그마 간격 적용하여 marginInlineEnd: 8->4로 변경 (Leehj)
    marginInlineEnd: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
  },
  camName: {
    display: "flex",
    flexDirection: "column",
    padding: "0 8px",
    justifyContent: "center",
  },
  icon: {
    display: "block",
  },
  moreBtn: {
    padding: 0,
  },
  status: {
    // mantis 10483, 피그마 간격 적용하여 padding변경 (Leehj)
    padding: "12px 16px 16px",
    // padding: "12px 21px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  noti: {
    display: "flex",
    alignItems: "center",
  },
  groupTitle: {
    ...(theme.direction === "rtl"
      ? {
          marginLeft: 6,
          marginRight: 8,
        }
      : { marginLeft: 8, marginRight: 6 }),
  },
  divider: {
    margin: "0 10px",
    height: 1,
    background: "rgba(233, 233, 234, 0.45)",
    borderRadius: 1,
  },
  avatarMulti: {
    width: 29,
    height: 29,
    position: "absolute",
    border: "1px solid #FFFFFF",
    borderRadius: "50%",
  },
  avatarMulti2: {
    width: 25,
    height: 25,
    position: "absolute",
    border: "1px solid #FFFFFF",
    borderRadius: "50%",
  },
  noCamGroup: {
    width: 44,
    height: 44,
    border: `2px solid ${LightColors.primary["9"]}`,
    borderRadius: "50%",
    backgroundColor: LightColors.primary["10"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noCamGroupIcon: {
    color: LightColors.primary["8"],
  },
}));

export const CameraGroupInfo = forwardRef<HTMLButtonElement, CameraInfoProps>(
  (props, ref) => {
    const { group, onClick, disabled, style } = props;
    const classes = useStyles(props);
    const theme = useTheme();
    const { t } = useTranslation();
    const mobile = useMediaQuery(theme.breakpoints.down(956));

    const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

    const groupCams = useMemo(() => {
      return _.filter(
        cameraList?.deviceListInfo,
        (dev) => dev.device.reg_category !== "wifi"
      );
    }, [cameraList?.deviceListInfo]);

    const cameraCountText = useMemo(() => {
      // mantis - 10483, 할당된 카메라 없는 경우 0 Cameras로 출력 (Leehj)
      if (!group.DashCamUser) return `0 ${t("Cameras")}`;
      const filteredCams = _.filter(
        groupCams,
        (d) => d.groupManagementID === group.GroupID
      );
      if (filteredCams.length === 1) {
        return `${filteredCams.length} ${t("Cameras")}`;
      }
      return `${filteredCams.length} ${t("Cameras")}`;
    }, [group.DashCamUser, group.GroupID, groupCams, t]);

    const avatarMarkup = useMemo(() => {
      // mantis - 10483, 할당된 카메라 없는 경우 아래 UI 출력 (Leehj)
      if (!group.DashCamUser)
        return (
          <div className={classes.noCamGroup}>
            <AddIcon className={classes.noCamGroupIcon} />
          </div>
        );
      const firstAvatar = _.find(
        groupCams,
        (dev) =>
          dev.device.psn === group.DashCamUser?.[0]?.PSN &&
          dev.device.reg_category !== "wifi"
      )?.device;

      // if (group.DashCamUser.length === 1) {
      //   return (
      //     <Avatars
      //       name={group.GroupName}
      //       style={{ width: 44, height: 44 }}
      //       imgSrc={firstAvatar?.profile_img || undefined}
      //     />
      //   );
      // }
      const secondsAvatar = _.find(
        groupCams,
        (dev) =>
          dev.device.psn === group.DashCamUser?.[1]?.PSN &&
          dev.device.reg_category !== "wifi"
      )?.device;
      const thirdAvatar = _.find(
        groupCams,
        (dev) =>
          dev.device.psn === group.DashCamUser?.[2]?.PSN &&
          dev.device.reg_category !== "wifi"
      )?.device;
      if (firstAvatar && secondsAvatar && thirdAvatar) {
        return (
          <>
            <Avatars
              name={firstAvatar?.dev_name}
              className={classes.avatarMulti2}
              style={{ bottom: 0, left: 0 }}
              imgSrc={firstAvatar?.profile_img || undefined}
            />
            <Avatars
              name={thirdAvatar?.dev_name}
              className={classes.avatarMulti2}
              style={{ bottom: 0, right: 0 }}
              imgSrc={thirdAvatar?.profile_img || undefined}
            />
            <Avatars
              name={secondsAvatar?.dev_name}
              className={classes.avatarMulti2}
              style={{ top: 0, right: 9.5 }}
              imgSrc={secondsAvatar?.profile_img || undefined}
            />
          </>
        );
      } else if (firstAvatar && secondsAvatar) {
        return (
          <>
            <Avatars
              name={secondsAvatar?.dev_name}
              className={classes.avatarMulti}
              style={{ bottom: 0, right: 0 }}
              imgSrc={secondsAvatar?.profile_img || undefined}
            />
            <Avatars
              name={firstAvatar?.dev_name}
              className={classes.avatarMulti}
              imgSrc={firstAvatar?.profile_img || undefined}
            />
          </>
        );
      } else {
        return (
          <Avatars
            name={group.GroupName}
            style={{ width: 44, height: 44 }}
            imgSrc={firstAvatar?.profile_img || undefined}
          />
        );
      }
    }, [
      classes.avatarMulti,
      classes.avatarMulti2,
      classes.noCamGroup,
      classes.noCamGroupIcon,
      group.DashCamUser,
      group.GroupName,
      groupCams,
    ]);

    return (
      <>
        <Card
          style={style}
          className={classes.root}
          onClick={() => !disabled && onClick?.(group)}
        >
          <div className={classes.profile}>
            <div className={classes.avatar}>
              <div style={{ position: "relative", width: 44, height: 44 }}>
                {avatarMarkup}
              </div>

              <div className={classes.camName}>
                <Typography
                  category="Default"
                  variant="SmallBold"
                  htmlColor={LightColors.primary["1"]}
                  style={{
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                    marginBottom: 4,
                  }}
                >
                  {group.GroupName}
                </Typography>
              </div>
            </div>
            <div>
              <Tooltip
                disableTouchListener={mobile}
                placement="bottom"
                title={"More"}
              >
                <IconButton
                  ref={ref}
                  className={classes.moreBtn}
                  onClick={(e) => onClick?.(group)}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.status}>
            <div className={classes.noti}>
              <GroupIcon style={{ margin: 4 }} htmlColor="#282830" />
              <Typography
                category="Default"
                variant="CaptionBold"
                className={classes.groupTitle}
                htmlColor={LightColors.primary["2"]}
              >
                {t("Group")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
              >
                {cameraCountText}
              </Typography>
            </div>
          </div>
        </Card>
      </>
    );
  }
);
