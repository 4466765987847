import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface ReportModalProps {
  open: boolean;
  onClickNegative?: () => void;
  onClickPositive?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: 15,
  },
  titleDiv: {
    padding: "26px 44px 0 24px",
    minHeight: 22,
  },
  // mantis - 10496, 모바일일 때 버튼 가운데로 정렬 (Leehj)
  btnDiv: {
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
}));

export const RecordingModal = ({
  open,
  onClickNegative,
  onClickPositive,
}: ReportModalProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Modal
      open={open}
      heading={`${t("Warning")} :`}
      content={
        <Typography
          category="Default"
          variant="Body"
          dangerouslySetInnerHTML={{
            __html: t("Adjusting the cut-off voltage_2").replaceAll(
              "\n",
              "<br/>"
            ),
          }}
        />
      }
      LButton={t("Cancel")}
      RButton={t("Continue")}
      Secondary
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      onClose={onClickNegative}
      className={classes.paper}
      titleClassName={classes.titleDiv}
      actionClassName={classes.btnDiv}
      close
    />
  );
};
