import { API_GATEWAY_URI } from "@thingsw/pitta-modules";
import axios from "axios";
import moment from "moment";

export const getLatestEvents = (
  email: string,
  userToken: string,
  psn: string[]
) => {
  const now = moment();
  return axios.post(`${API_GATEWAY_URI}/DashCam/AlarmLatestEvents`, {
    email,
    user_token: userToken,
    tokenType: "web",
    psn,
    msg_code: ["ALARM_EVENT", "ALARM_PARK_EVENT"],
    limit: "5",
    startDate: moment(now).startOf("d").utc(true).unix().toString(),
    endDate: moment(now).endOf("d").utc(true).unix().toString(),
  });
};

export const getEvents = (
  email: string,
  userToken: string,
  psn: string[],
  msg_code: string[],
  startDate: string,
  endDate: string
) => {
  return axios.post(`${API_GATEWAY_URI}/DashCam/AlarmLatestEvents`, {
    email,
    user_token: userToken,
    tokenType: "web",
    psn,
    msg_code,
    startDate,
    endDate,
  });
};
