import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";

export interface INoResultsProps {
  groupCamera?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    margin: theme.spacing(2, 0),
    flexGrow: 1,
  },
  content: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: theme.spacing(0.25),
      marginBottom: 0,
    },
  },
  groupCamera: {
    margin: theme.spacing(1, 0, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
    },
  },
}));

export const NoResults = (props: INoResultsProps) => {
  const { groupCamera } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography
        category="Default"
        variant="BodyBold"
        htmlColor={LightColors.primary["2"]}
      >
        {t("No results")}
      </Typography>
      <Typography
        category="Default"
        variant="Small"
        className={clsx(classes.content, groupCamera && classes.groupCamera)}
        htmlColor={LightColors.primary["2"]}
      >
        {t("Try different keywords")}
      </Typography>
    </div>
  );
};
