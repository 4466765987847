import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import React, { useEffect, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { ReportPanel } from "../components/cameras/ReportPanel";
import { loadCameras } from "../features/Camera/slice";
import { PermissionProps, ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: (props: ScreenDefaultProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px)"
        : "calc(var(--vh, 1vh) * 100 - 56px)",
    marginTop: (props: ScreenDefaultProps) => (props.error ? 127 : 56),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
    },
  },
  body: {
    // padding: theme.spacing(0, 4),
    marginBottom: 0,
    overflowY: "auto",
    height: "100%",
  },
}));

export const ReportScreen = (props: ScreenDefaultProps & PermissionProps) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  const bodyMarkup = useMemo(() => {
    return (
      <div
        className={classes.body}
        style={
          mobile
            ? {}
            : {
                overflowY: "visible",
                overflowX: "visible",
              }
        }
      >
        <ReportPanel selectCam {...props} />
      </div>
    );
  }, [classes.body, mobile, props]);

  return (
    <div className={classes.root} dir={theme.direction}>
      {mobile ? (
        bodyMarkup
      ) : (
        <AutoSizer>
          {({ width, height }) => (
            <Scrollbars style={{ height, width }} autoHide>
              {bodyMarkup}
            </Scrollbars>
          )}
        </AutoSizer>
      )}
    </div>
  );
};
