import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  error?: string;
  ignoreError: boolean;
}

let initialState: IState = {
  ignoreError: false,
};

const slice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      if (!state.ignoreError) {
        state.error = payload;
      }
      state.ignoreError = false;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    handleServer500: () => {},
    ignoreError: (state) => {
      state.ignoreError = true;
    },
  },
});

export const { setError, clearError, handleServer500, ignoreError } =
  slice.actions;
export const ERROR = slice.name;
export default slice.reducer;
