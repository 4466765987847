import { LiveViewPanel } from "@thingsw/pitta-liveview-module";
import {
  AppBackReason,
  Direction,
  ICameraInfo,
  PermissionProps,
} from "@thingsw/pitta-modules";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LIVEVIEW,
  finishLiveview,
  setMultiLiveview,
} from "../../features/LiveView/slice";
import { LOCATION_WS } from "../../features/LocationWS/slice";
import { PAYMENT } from "../../features/Payment/slice";
import { PERMISSION } from "../../features/Permission/slice";
import { RootState } from "../../features/store";
import { CAMERA, loadFirmwareSettings } from "../../features/Camera/slice";
import { USER, updateLiveviewUsage } from "../../features/User/slice";
import {
  THEME,
  setExitFullscreen,
  setFullscreen,
} from "../../features/Theme/slice";
import { openToast } from "../../features/Toast/slice";
import { setError } from "../../features/Error/slice";
import { PricingModal } from "../modals/PricingModal";
import { PUSH_EVENT } from "../../features/PushEvent/slice";
import { useTranslation } from "react-i18next";
import { GEOFENCE } from "../../features/Geofence/slice";
import { GROUP } from "../../features/Group/slice";

interface LiveViewPanelWrapperProps {
  isEvent?: boolean;
  isPublic?: boolean;
  dir?: Direction;
  notification?: boolean;
  ignoreRemove?: boolean;
  modal?: boolean;
  app?: boolean;
  camera: ICameraInfo;
  onBack?: (reason?: string) => void;
  speedUnit?: "0" | "1";
  noBottom?: boolean;
  noMargin?: boolean;
  mapboxModal?: boolean;
}

export const LiveViewPanelWrapper = ({
  isEvent,
  isPublic,
  dir,
  notification,
  ignoreRemove,
  modal,
  app,
  camera,
  onBack,
  speedUnit,
  noBottom,
  noMargin,
  mapboxModal,
  ...permissions
}: LiveViewPanelWrapperProps & PermissionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cameraState = useSelector((state: RootState) => state[CAMERA]);
  const userState = useSelector((state: RootState) => state[USER]);
  const locationState = useSelector((state: RootState) => state[LOCATION_WS]);
  const permissionState = useSelector((state: RootState) => state[PERMISSION]);
  const paymentState = useSelector((state: RootState) => state[PAYMENT]);
  const liveviewState = useSelector((state: RootState) => state[LIVEVIEW]);
  const themeState = useSelector((state: RootState) => state[THEME]);
  const pushEventState = useSelector((state: RootState) => state[PUSH_EVENT]);
  const geofenceState = useSelector((state: RootState) => state[GEOFENCE]);
  const groupState = useSelector((state: RootState) => state[GROUP]);

  const [openPricing, setOpenPricing] = useState(false);

  useEffect(() => {
    return () => {
      console.log("LiveViewPanelWrapper", "finish");
    };
  }, []);

  const onLoadFirmwareSettings = useCallback(
    (psn: string) => {
      dispatch(loadFirmwareSettings(psn));
    },
    [dispatch]
  );

  const onFinishLiveview = useCallback(
    (reason: AppBackReason | undefined) => {
      dispatch(finishLiveview(reason));
    },
    [dispatch]
  );

  const onUpdateLiveviewUsage = useCallback(
    (data: { psn: string; usage: number }) => {
      console.log("onUpdateLiveviewUsage", "data", data);
      dispatch(updateLiveviewUsage(data));
    },
    [dispatch]
  );

  const onOpenToast = useCallback(
    (data: { message: string; option?: any; notification?: boolean }) => {
      dispatch(openToast(data));
    },
    [dispatch]
  );

  const onSetFullScreen = useCallback(
    (fullscreen: boolean) => {
      dispatch(setFullscreen(fullscreen));
    },
    [dispatch]
  );

  const onExitFullScreen = useCallback(
    (fullscreen: object | undefined) => {
      dispatch(setExitFullscreen(fullscreen));
    },
    [dispatch]
  );

  const onSetError = useCallback(
    (error: string) => {
      dispatch(setError(error));
    },
    [dispatch]
  );
  const onOpenPricingModal = useCallback(() => {
    setOpenPricing(true);
  }, []);

  return (
    <>
      <LiveViewPanel
        isEvent={isEvent}
        isPublic={isPublic}
        dir={dir}
        notification={notification}
        noMargin={noMargin}
        pushEventState={pushEventState}
        camera={camera}
        ignoreRemove={ignoreRemove}
        modal={modal}
        app={app}
        onBack={onBack}
        speedUnit={speedUnit}
        noBottom={noBottom}
        {...permissions}
        cameraState={cameraState}
        userState={userState}
        locationState={locationState}
        permissionState={permissionState}
        paymentState={paymentState}
        liveviewState={liveviewState}
        themeState={themeState}
        geofenceState={geofenceState}
        groupState={groupState}
        onLoadFirmwareSettings={onLoadFirmwareSettings}
        onFinishLiveview={onFinishLiveview}
        onUpdateLiveviewUsage={onUpdateLiveviewUsage}
        onOpenToast={onOpenToast}
        onSetFullScreen={onSetFullScreen}
        onExitFullScreen={onExitFullScreen}
        onSetError={onSetError}
        onOpenPricingModal={onOpenPricingModal}
        onExceedLiveview={() => {}}
        onChangeMultiLiveview={(multi) => {
          dispatch(setMultiLiveview(multi));
        }}
        t={t}
        // paddingTop값을 변경하기 위함
        mapboxModal={mapboxModal}
      />
      <PricingModal open={openPricing} onClose={() => setOpenPricing(false)} />
    </>
  );
};
