import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Theme, useMediaQuery, useTheme, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IconButton, Switch, Typography } from "@thingsw/pitta-design-system";
import { Selector } from "@thingsw/pitta-design-system/dist/components/Selector";

import { CAMERA } from "../../features/Camera/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";

import { Card } from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import clsx from "clsx";
import {
  Webviewer,
  LightColors,
  TabNameInfo1,
  TrueFalsetype,
  TabNameInfo3,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 674,
      minWidth: 673,
    },
  },

  ArrowBack: {
    maxWidth: "100%",
    maxHeight: 21,
    margin: "1px 1px 14px 0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  ArrowBackPd: {
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 7 }
      : { paddingLeft: 0, paddingRight: 7 }),
  },

  card: {
    display: "flex",
    maxWidth: 674,
    alignItems: "center",
    padding: "12px 23px",
    borderRadius: 12,
    marginBottom: theme.spacing(2) - 2,
  },

  secondCard: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 674,
    minHeight: 48,
    padding: "20px 20px 37px ",
    borderRadius: 12,
    marginBottom: theme.spacing(2) - 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "12px 19px 17px",
    },
  },

  switch: {
    marginRight: 12,
    width: 48,
    display: "flex",
    alignItems: "center",
  },

  switchBox: {
    display: "flex",
    padding: "0px 4px",
    marginBottom: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      alignItems: "center",
    },
  },
  speedUnitDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: 28,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 27,
    },
  },
  select: {
    borderRadius: 12,
    padding: "8px 24px",
  },
  selectPaper: {
    width: 234,
  },
  wid267: {
    paddingTop: 16,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      minWidth: 267,
      padding: "0",
    },
  },
  textOverlayDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  textOverlayText: {
    minWidth: 120,
    display: "flex",
    flexDirection: "column",
    marginBottom: 14,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },
  inputDiv: {
    minwidth: 256,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 267,
      marginTop: 6,
    },
  },
  input: {
    borderRadius: 8.5,
    padding: "10.5px 13px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "8px 13px",
    },
  },

  // 툴팁 & 이미지부분 css
  imgDiv: {
    position: "relative",
    margin: "49px 0px 0px -1px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "64px 0px 0px 18px",
    },
  },
  tootipText: {
    marginTop: 2,
    whiteSpace: "nowrap",
  },
  textOverlayTootipWrap: {
    position: "absolute",
    top: -34,
    left: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: -32,
      left: -4,
    },
  },
  textOverlayTootip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    position: "relative",
    width: 105,
    height: 24,
    backgroundColor: LightColors.primary["1"],
    borderRadius: 6,
    "&::after": {
      position: "absolute",
      content: "''",
      borderTop: `10px solid ${LightColors.primary["1"]}`,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "0px solid transparent",
      bottom: -5,
      left: 2,
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      left: 4,
    },
  },
  speedUnitTootipWrap: {
    position: "absolute",
    bottom: 29,
    left: 86,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      bottom: 26,
      left: 90,
    },
  },
  speedUnitTootip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    position: "relative",
    width: 73,
    height: 24,
    backgroundColor: LightColors.primary["2"],
    borderRadius: 6,
    "&::after": {
      position: "absolute",
      content: "''",
      borderTop: `10px solid ${LightColors.primary["2"]}`,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "0px solid transparent",
      bottom: -5,
      left: 2,
    },
  },
  dateTooltipWrap: {
    position: "absolute",
    left: 2,
    bottom: -30,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      bottom: -28,
    },
  },
  dateTooltip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    position: "relative",
    width: 122,
    height: 24,
    backgroundColor: LightColors.primary["1"],
    borderRadius: 6,
    "&::after": {
      position: "absolute",
      content: "''",
      borderTop: "0px solid transparent",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: `10px solid ${LightColors.primary["1"]}`,
      top: -5,
      left: 3,
    },
  },
  inputHelperText: {
    display: "flex",
    justifyContent: "flex-end",
  },
  disabledText: {
    opacity: 0.35,
  },
  contentDivider: {
    margin: "3px 0 12px",
  },
}));

interface VideoFirmwarePanelProps {
  onClose: () => void;
  onSettingChange: (
    key: keyof TabNameInfo1,
    val: string | TrueFalsetype
  ) => void;
  onSettingTab3Change: (userString: string) => void;
  currentTab1?: TabNameInfo1;
  currentTab3?: TabNameInfo3;
}

export const VideoOverlayPanel = ({
  onClose,
  onSettingChange,
  onSettingTab3Change,
  currentTab1,
  currentTab3,
}: VideoFirmwarePanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { firmwareConfig } = useSelector((state: RootState) => state[CAMERA]);
  const [videoOverlay, setVideoOverlay] = useState<boolean>(false);

  const settings = useMemo(() => {
    return firmwareConfig?.Recording;
  }, [firmwareConfig?.Recording]);

  const userStringSettings = useMemo(() => {
    return firmwareConfig?.System;
  }, [firmwareConfig?.System]);

  const getBoolean = useCallback((TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  }, []);

  useEffect(() => {
    if (
      currentTab1?.DateDisplay === "0" &&
      currentTab1.SpeedUnit === "2" &&
      currentTab3?.userString === ""
    ) {
      setVideoOverlay(false);
    } else {
      setVideoOverlay(true);
    }
  }, [currentTab1, currentTab3]);

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (currentTab3) {
        const { value } = e.target;

        const regex = new RegExp(/((?![a-zA-Z0-9:;'/\\+-_()$#]).)/gm);
        // eslint-disable-next-line no-useless-escape
        const regex2 = /[\[\]\=?^,.<>@]/gi;

        const test = regex.test(value);
        const test2 = regex2.test(value);
        if (value.length <= 20) {
          if (!test && !test2) {
            onSettingTab3Change(value);
          }
        }
      }
    },
    [currentTab3, onSettingTab3Change]
  );

  return (
    <div>
      <div
        className={classes.ArrowBack}
        onClick={() => {
          onClose();
        }}
      >
        <IconButton className={classes.ArrowBackPd}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography category="Default" variant="H6" style={{ marginTop: 2 }}>
          {t("Video overlay")}
        </Typography>
      </div>

      <div className={classes.bigBox}>
        <Card variant="outlined" className={classes.card}>
          <div className={classes.switch}>
            <Switch
              checked={videoOverlay}
              onChange={(e) => {
                if (!e.target.checked) {
                  setVideoOverlay(e.target.checked);
                  onSettingChange("DateDisplay", "0");
                  onSettingChange("SpeedUnit", "2");
                  onSettingTab3Change("");
                } else {
                  setVideoOverlay(e.target.checked);
                  onSettingChange("DateDisplay", "1");
                  onSettingChange("SpeedUnit", "0");
                }
              }}
            />
          </div>
          <Typography category="Default" variant="Body">
            {t("Video overlay")}
          </Typography>
        </Card>

        <Card
          variant="outlined"
          className={classes.secondCard}
          style={{ flexDirection: "column" }}
        >
          <div className={classes.switchBox}>
            <div className={classes.switch}>
              <Switch
                // mantis - 12653, Video Overlay Off시 해당 항목 비활성화 적용(Leehj)
                disabled={!videoOverlay}
                checked={getBoolean(currentTab1?.DateDisplay ?? "1")}
                onChange={(e) => {
                  onSettingChange("DateDisplay", e.target.checked ? "1" : "0");
                  // video overlay가 off인 경우 -> Date & time display를 on으로 변경할 때 Video overlay도 on이 되니깐
                  // Speed unit에 km/h값으로 변경되도록 아래코드 추가
                  if (
                    e.target.checked &&
                    currentTab1?.SpeedUnit === "2" &&
                    currentTab3?.userString === ""
                  ) {
                    onSettingChange("SpeedUnit", "0");
                  }
                }}
              />
            </div>
            <Typography
              category="Default"
              variant="Body"
              // mantis - 12653, Video Overlay Off시 해당 항목 비활성화 적용(Leehj)
              className={clsx(!videoOverlay && classes.disabledText)}
            >
              {t("Date & time display")}
            </Typography>
          </div>

          <Divider className={classes.contentDivider} />

          {settings?.["SpeedUnit"] && (
            <div className={classes.speedUnitDiv}>
              <Typography
                category="Default"
                variant="Body"
                // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                className={clsx(!videoOverlay && classes.disabledText)}
              >
                {t("Speed unit")}
              </Typography>
              <div className={classes.wid267}>
                <Selector
                  outlinedInputClassName={classes.select}
                  menuItemClassName={classes.selectPaper}
                  t={t}
                  keyValue={currentTab1?.SpeedUnit ?? "0"}
                  disabled={!videoOverlay}
                  dense
                  mobile={mobile}
                  onChange={(e) => {
                    onSettingChange("SpeedUnit", e.key);
                  }}
                  items={[
                    {
                      key: "0",
                      value: "km/h",
                    },
                    {
                      key: "1",
                      value: "MPH",
                    },
                    {
                      key: "2",
                      value: "Display off",
                    },
                  ]}
                />
              </div>
            </div>
          )}
          {userStringSettings?.["UserTextOverlay"] && (
            <div className={classes.textOverlayDiv}>
              <div
                className={classes.textOverlayText}
                style={{
                  minWidth: 120,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  category="Default"
                  variant="Body"
                  style={{
                    marginBottom: 3,
                    [theme.breakpoints.up(Webviewer.mobile)]: {
                      marginBottom: 0,
                      width: 118,
                    },
                  }}
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                  className={clsx(!videoOverlay && classes.disabledText)}
                >
                  {t("User text overlay")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["3"]}
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                  className={clsx(!videoOverlay && classes.disabledText)}
                >
                  {t("A-Z, a-z_")}
                </Typography>
              </div>
              <div>
                <div className={classes.inputDiv}>
                  <Input
                    outlinedInputClassName={classes.input}
                    disabled={!videoOverlay}
                    dense
                    placeholder={t("Enter your text")}
                    onChange={handleInput}
                    value={currentTab3?.userString}
                    helperText={
                      <div className={classes.inputHelperText}>
                        {currentTab3?.userString.length ?? 0} / 20
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Card>

        <div className={classes.imgDiv}>
          <img
            srcSet="/images/video-overlay.png 1x"
            alt="videoOverlayImg"
            style={{
              width: mobile ? "98%" : 304,
            }}
          />
          {/* 툴팁 */}
          <div className={classes.textOverlayTootipWrap}>
            <div className={classes.textOverlayTootip}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
                className={classes.tootipText}
              >
                {t("User text overlay")}
              </Typography>
            </div>
          </div>
          <div className={classes.speedUnitTootipWrap}>
            <div className={classes.speedUnitTootip}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
                className={classes.tootipText}
              >
                {t("Speed unit")}
              </Typography>
            </div>
          </div>
          <div className={classes.dateTooltipWrap}>
            <div className={classes.dateTooltip}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
                className={classes.tootipText}
              >
                {t("Date & Time display")}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
