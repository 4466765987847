import { call, put, select, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failLoad,
  loadCameras,
  loadMyCemrasLocation,
  successLoadCamera,
  successLoadCameras,
  successLoadCameraLocation,
  loadCamera,
  addCamera,
  renameCamera,
  deleteCamera,
  updatePrivacy,
  successUpdatePrivacy,
  successFwUpdateInfo,
  loadFwUpdateInfo,
  successUpdateNotification,
  loadNotification,
  CAMERA,
  loadLiveEventSettings,
  successLoadLiveEventSettings,
  updateLiveEventSettings,
  successLoadFirmwareInfos,
  loadFirmwareInfos,
  updateNotification,
  successLoadFirmwareSettings,
  loadFirmwareSettings,
  updateFirmwareSettings,
  loadNotificationEmailList,
  successLoadNotificationEmailList,
  invite,
  successInvite,
  clearLoading,
  deleteEmail,
  successDelete,
  requestFota,
  updateCameras,
  successUpdateCameras,
  loadPublicCamera,
  successLoadPublicCamera,
  setFirmwareConfig,
  rebootCamera,
  setCameraError,
  fixMasterEmailNotification,
  loadFirmwareConfig,
  loadPrivacy,
  successLoadPrivacy,
  loadFreeCamLocation,
  setFotaFailed,
  setCameraTimeSync,
} from "./slice";
import * as Api from "../../apis";
import { RootState } from "../store";
import { logout, USER } from "../User/slice";
import { closeToast, openToast } from "../Toast/slice";
import { goBack, push } from "connected-react-router";
import { ignoreError, setError } from "../Error/slice";
import { loadSubscription } from "../Payment/slice";
import { getFirmwareConfig } from "../../utils/Firmware";
import moment from "moment";
import { loadGroups } from "../Group/slice";
import { detect } from "detect-browser";
import { finishLiveview } from "../LiveView/slice";
import {
  AddCameraRequest,
  IUserLoginInfo,
  RESULT_CODE,
  OnOffType,
  ICameraInfo,
  ICameraList,
  ILatLngBounds,
  IFwUpdateInfo,
  INotificationSettingsOrig,
  IEmailNotiSettings,
  INotificationSettings,
  ILiveEventSettings,
  IFirmwareSettings,
  INotificationEmailList,
  IEmailInvite,
  IMasterEmailNotification,
  IDeleteEmail,
  SIGMASTAR_MODELS,
  IFirmware,
  IFirmwareSettings2,
  IFotaIDPayload,
  IPrivacy,
} from "@thingsw/pitta-modules";

function* handleAddCamera({ payload }: PayloadAction<AddCameraRequest>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const tokenType = yield select((state: RootState) => state[USER].tokenType);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.addCamera,
      email,
      loginInfo.user_token,
      payload,
      tokenType
    );
    const { resultcode, message } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      // response: { "device info": ICameraInfo };
    };
    if (resultcode === "BC_ERR_OK") {
      const { data } = yield call(
        Api.loadPrivacy,
        email,
        payload.psn,
        loginInfo.user_token
      );
      const privacy = data.response;
      yield call(
        Api.updatePrivacy,
        email,
        loginInfo.user_token,
        payload.psn,
        privacy.agree_gps,
        privacy.share_gps,
        privacy.share_audio,
        privacy.share_video,
        privacy.share_dev_name,
        payload.agree_eventmap
      );
      yield put(loadSubscription());
      yield put(loadCameras());
      yield put(openToast({ message: "Camera added" }));
      yield put(push("/cameras/choose-connection"));
    } else if (
      resultcode === "BC_ERR_DUPLICATED" ||
      resultcode === "BC_ERR_INVALID_DATA"
    ) {
      yield put(failLoad());
      yield put(setCameraError(resultcode));
    } else if (resultcode === "BC_ERR_SERVER") {
      yield put(failLoad());
      yield put(setCameraError("An error occurred_again"));
      yield put(goBack());
    } else {
      yield put(failLoad());
      yield put(setCameraError(message));
    }
    // console.log(resp.data);
  } catch (err) {
    // console.error(err);
  }
}

function* handleRenameCamera({
  payload,
}: PayloadAction<{ psn: string; devName: string; regCategory: string }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    // mantis - 11277, api변경하면서 regCategory 추가, user_token 제거 (Leehj)
    const res = yield call(
      Api.renameCamera,
      email,
      payload.psn,
      payload.devName,
      payload.regCategory
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadCameras());
      yield put(loadCamera(payload.psn));
      yield put(openToast({ message: "Camera renamed" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleDeleteCamera({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.deleteCamera,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadCameras());
      yield put(loadGroups());
      yield put(openToast({ message: "Camera deleted" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadCamera({ payload }: PayloadAction<string>) {
  try {
    const cameraList = yield select(
      (state: RootState) => state[CAMERA].cameraList
    );
    const cam = _.find(
      cameraList?.deviceListInfo,
      (d) => d.device.psn === payload
    )?.device;
    if (cam) {
      yield put(successLoadCamera(cam));
      yield put(setCameraTimeSync(cam.require_time_sync));
    }

    const email = yield select((state: RootState) => state[USER].email);
    const tokenType = yield select((state: RootState) => state[USER].tokenType);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getCamInfo,
      email,
      loginInfo.user_token,
      payload,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { sharedEventMap?: OnOffType; "device info": ICameraInfo };
    };
    if (resultcode === "BC_ERR_OK") {
      // yield put(setSharedEventMap(response.sharedEventMap));
      // yield put(setSharedEventMap("on"));
      // yield put(setSharedEventMap(undefined));
      yield put(successLoadCamera(response["device info"]));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadPublicCamera({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const tokenType = yield select((state: RootState) => state[USER].tokenType);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getCamInfo,
      email,
      loginInfo.user_token,
      payload,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { "device info": ICameraInfo };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadPublicCamera(response["device info"]));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadCameras() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getCamList,
      email,
      loginInfo.user_token,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ICameraList;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadCameras(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleUpdateCameras() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getCamList,
      email,
      loginInfo.user_token,
      tokenType
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ICameraList;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successUpdateCameras(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

// function* handleLoadCamReg() {
//   try {
//     const email = yield select((state: RootState) => state[USER].email);
//     const tokenType = yield select((state: RootState) => state[USER].tokenType);
//     const loginInfo = (yield select(
//       (state: RootState) => state[USER].loginInfo
//     )) as IUserLoginInfo;
//     const res = yield call(
//       Api.checkCamReg,
//       email,
//       loginInfo.user_token,
//       tokenType
//     );
//     const { resultcode, response } = res.data as {
//       resultcode: RESULT_CODE;
//       message: string;
//       response: ICamRegInfo;
//     };
//     if (resultcode === "BC_ERR_OK") {
//       yield put(successLoadCamReg(response));
//     } else if (resultcode === "BC_ERR_AUTHENTICATION") {
//       yield put(logout());
//     }
//   } catch (err) {
//     yield put(failLoad());
//     yield put(setError(err.message));
//   }
// }

function* handleLoadMyCamerasLocation({
  payload,
}: PayloadAction<{ bounds?: ILatLngBounds; cancel: AbortController }>) {
  // gps.blackvuecloud.com이 deprecated 되어서 사용 불가
  // 관련 기능은 websocket gps를 사용할 것.
  // try {
  //   const { gps_server, gps_port, user_token } = (yield select(
  //     (state: RootState) => state[USER].loginInfo
  //   )) as IUserLoginInfo;
  //   const { tokenType } = yield select((state: RootState) => state[USER]);
  //   const email = yield select((state: RootState) => state[USER].email);
  //   if (email) {
  //     const resp = yield call(
  //       Api.getMyCameraLocations,
  //       gps_server,
  //       gps_port,
  //       email,
  //       user_token,
  //       tokenType,
  //       payload.cancel,
  //       payload.bounds
  //     );
  //     const reader = resp.body.getReader();
  //     let data: any;
  //     const decoder = new TextDecoder("utf-8");
  //     do {
  //       data = yield reader.read();
  //       const body = _.split(decoder.decode(data.value), "\r\n");
  //       try {
  //         if (body.length > 1) {
  //           const content = JSON.parse(body[1]) as {
  //             resultcode: RESULT_CODE;
  //             message: string;
  //             response: { "device list": { info: ICameraInfo[] } };
  //           };
  //           // console.log(content);
  //           const camInfos = content.response["device list"].info;
  //           yield put(successLoadCameraCluster({}));
  //           yield put(successLoadCameraLocation(camInfos));
  //         } else {
  //           const content = JSON.parse(body[0]) as {
  //             resultcode: RESULT_CODE;
  //             message: string;
  //             response: ICameraCluster;
  //           };
  //           console.log(content);
  //           yield put(successLoadCameraCluster(content.response));
  //         }
  //       } catch (err) {}
  //     } while (!data.done);
  //   }
  // } catch (error: any) {
  //   console.error(error.code, error.message, error.response?.status);
  //   const browserName = detect()?.name;
  //   if (
  //     (browserName === "ios-webview" || browserName === "chromium-webview") &&
  //     (error.code === "ECONNABORTED" ||
  //       error.message === "network error" ||
  //       error.response?.status === 408)
  //   ) {
  //     yield put(finishLiveview("ERR_NO_INTERNET"));
  //   }
  // }
}

function* handleLoadFreeCamLocation({
  payload,
}: PayloadAction<{ camera: ICameraInfo; cancel: AbortController }>) {
  try {
    const { camera, cancel } = payload;
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { tokenType } = yield select((state: RootState) => state[USER]);
    const email = yield select((state: RootState) => state[USER].email);
    if (email) {
      const resp = yield call(
        Api.getFreeCamLocation,
        camera.lb_server_name,
        camera.lb_http_port,
        email,
        camera.psn,
        user_token,
        tokenType,
        cancel
      );
      const reader = resp.body.getReader();
      let data: any;
      const decoder = new TextDecoder("utf-8");
      do {
        data = yield reader.read();
        const body = _.split(decoder.decode(data.value), "\r\n");
        if (body.length > 1) {
          const content = JSON.parse(body[1]) as {
            resultcode: RESULT_CODE;
            message: string;
            response: { "device list": { info: ICameraInfo[] } };
          };

          const camInfos = content.response["device list"].info;
          yield put(successLoadCameraLocation({ cams: camInfos }));
        }
      } while (!data.done);
    }
  } catch (error: any) {
    console.error(error);
    const browserName = detect()?.name;
    if (
      (browserName === "ios-webview" || browserName === "chromium-webview") &&
      (error.code === "ECONNABORTED" ||
        error.message === "network error" ||
        error.response?.status === 408)
    ) {
      yield put(finishLiveview("ERR_NO_INTERNET"));
    }
  }
}

function* handleUpdatePrivacy({
  payload,
}: PayloadAction<{
  psn: string;
  agree_gps: OnOffType;
  share_gps: OnOffType;
  share_video: OnOffType;
  share_audio: OnOffType;
  share_dev_name: OnOffType;
  share_leu_bigdata: OnOffType;
}>) {
  try {
    const {
      psn,
      agree_gps,
      share_gps,
      share_video,
      share_audio,
      share_dev_name,
      share_leu_bigdata,
    } = payload;
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.updatePrivacy,
      email,
      user_token,
      psn,
      agree_gps,
      share_gps,
      share_audio,
      share_video,
      share_dev_name,
      share_leu_bigdata
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successUpdatePrivacy(payload));
      yield put(openToast({ message: "Camera settings saved_" }));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadFwUpdateInfo({
  payload,
}: PayloadAction<string | undefined>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(Api.getFotaStatus, email, user_token, payload);

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { fota_info: IFwUpdateInfo[] };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successFwUpdateInfo(response.fota_info));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadNotification() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const cameraInfo = (yield select(
      (state: RootState) => state[CAMERA].camera
    )) as ICameraInfo;
    const res = yield call(
      Api.getNotification,
      email,
      loginInfo.user_token,
      cameraInfo.psn,
      tokenType
    );
    const { resultcode, message, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: INotificationSettingsOrig;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successUpdateNotification({
          ...response,
          EmailSettings: JSON.parse(response.EmailSettings),
          PhoneSettings: JSON.parse(response.PhoneSettings),
        })
      );
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(failLoad());
      yield put(setError(message));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleUpdateNotification({
  payload,
}: PayloadAction<{
  setType: "email" | "phone";
  settings: IEmailNotiSettings;
  send: OnOffType;
  fatigueSend?: OnOffType;
  fatigueTime?: number;
  positionCenter?: boolean;
}>) {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { psn } = (yield select(
      (state: RootState) => state[CAMERA].camera
    )) as ICameraInfo;
    const resp = yield call(
      Api.updateNotification,
      email,
      user_token,
      psn,
      payload.setType,
      payload.settings,
      payload.send,
      // mantis - 10254, push notifications/email notification 설정 저장 시 로그아웃되는 이슈 수정 (Leehj)
      tokenType
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: INotificationSettings;
    };

    if (resultcode === "BC_ERR_OK") {      
      yield put(loadNotification());
      yield put(
        openToast({
          message: "Camera settings saved_",
          // mantis - 11079, app과 모바일에서 notification설정 변경시 토스트팝업 가운데로 출력되도록 수정 (Leehj)
          notification: payload.positionCenter,
        })
      );
      if (payload.fatigueSend && payload.fatigueTime) {
        yield call(
          Api.setFatigueRiskAlertTime,
          email,
          user_token,
          psn,
          tokenType,
          payload.fatigueSend,
          payload.fatigueTime,
        );
      }
    } else {
      yield put(
        openToast({
          message: "Cannot save settings",
          notification: payload.positionCenter,
        })
      );
      yield put(failLoad());
      if (resultcode === "BC_ERR_AUTHENTICATION") {
        yield put(logout());
      }
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadLiveEventSettings({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getLiveEventSettings,
      email,
      user_token,
      payload
    );

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ILiveEventSettings;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadLiveEventSettings(response));
    }
  } catch (err) {
    console.error(err);
  }
}
function* handleLoadFirmwareSettings({ payload }: PayloadAction<string>) {
  try {
    const { cameraList } = yield select((state: RootState) => state[CAMERA]);
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const dev = _.find(
      cameraList?.deviceListInfo,
      (dev) => dev.device.psn === payload
    )?.device;

    if (dev) {
      yield put(loadFirmwareConfig(dev));

      const resp = yield call(
        Api.getFirmwareSettings,
        email,
        user_token,
        payload,
        tokenType
      );

      const { resultcode, response } = resp.data as {
        resultcode: RESULT_CODE;
        message: string;
        response: IFirmwareSettings;
      };

      if (resultcode === "BC_ERR_OK") {
        yield put(successLoadFirmwareSettings(response));
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadNotificationEmailList({ payload }: PayloadAction<string>) {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getNotificationEmail,
      email,
      user_token,
      payload,
      tokenType
    );

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: INotificationEmailList;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadNotificationEmailList(response));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleInviteEmail({ payload }: PayloadAction<IEmailInvite>) {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.inviteEmail,
      email,
      user_token,
      payload.psn,
      payload.guestEmail,
      payload.companyID,
      tokenType
    );
    const { resultcode, message } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successInvite());
      yield put(openToast({ message: "Invite sent" }));
      yield put(loadNotificationEmailList(payload.psn));
    } else if (
      resultcode === "BC_ERR_DUPLICATED" &&
      message === "email is already exist"
    ) {
      yield put(setError("An account is_"));
      yield put(clearLoading());
    } else if (
      resultcode === "BC_ERR_LIMIT_EXCEEDED" &&
      message === "limit exceeded"
    ) {
      yield put(setError("An account is_"));
      yield put(clearLoading());
    }
  } catch (err) {}
}

function* handleFixMasterEmailNotification({
  payload,
}: PayloadAction<IMasterEmailNotification>) {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    yield put(closeToast());
    const resp = yield call(
      Api.FixMasterEmailNotification,
      email,
      user_token,
      payload.psn,
      payload.guestEmail,
      payload.mailStatus,
      tokenType
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadNotificationEmailList(payload.psn));
      yield put(openToast({ message: "Camera settings saved_" }));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleDeleteEmail({ payload }: PayloadAction<IDeleteEmail>) {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.deleteEmail,
      email,
      user_token,
      payload.psn,
      payload.guestEmail,
      tokenType
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadNotificationEmailList(payload.psn));
      yield put(successDelete());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateLiveEventSettings({
  payload,
}: PayloadAction<{ camera: ICameraInfo; setting: ILiveEventSettings }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { camera, setting } = payload;
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const config = getFirmwareConfig(camera);

    yield put(closeToast());
    let resp = [];
    if (config.CloudNative4) {
      resp = yield call(
        Api.updateCNLiveEventSettings,
        email,
        camera.psn,
        setting
      );
    } else {
      resp = yield call(
        Api.updateLiveEventSettings,
        email,
        user_token,
        camera.psn,
        setting
      );
    }

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };

    // uxui 프로젝트 성공시 토스트 팝업 노출안되도록 적용(Leehj)
    // uxui 프로젝트 성공시 토스트 팝업 노출되도록 기획 변경(Leehj) 23.01.16
    // mantis - 10572 SIGMASTAR_MODELS import가 안되어있어서 load가 안되고 토스트팝업 안나왔던 이슈 수정 (Leehj)
    if (_.includes(SIGMASTAR_MODELS, camera?.model)) {
      if (resultcode === "BC_ERR_OK") {
        yield put(successLoadLiveEventSettings(payload.setting));
        yield put(openToast({ message: "Live Event Upload_success" }));
      } else {
        yield put(openToast({ message: "Failed to save_2" }));
      }
    } else {
      if (resultcode === "BC_ERR_OK") {
        yield put(successLoadLiveEventSettings(payload.setting));
        yield put(openToast({ message: "Camera settings saved_" }));
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadFirmwareInfos() {
  try {
    const resp = yield call(Api.getFwInfos);
    const { firmware } = resp.data as { firmware: IFirmware[] };
    yield put(successLoadFirmwareInfos(firmware));
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateFirmwareSettings({
  payload,
}: PayloadAction<{ firmware: IFirmwareSettings }>) {
  try {
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const camera = (yield select(
      (state: RootState) => state[CAMERA].camera
    )) as ICameraInfo;
    const {
      psn,
      email,
      firmware_language,
      firmware_version,
    } = payload.firmware;

    const cameraInfo = (yield select(
      (state: RootState) =>
        _.find(
          state[CAMERA].cameraList?.deviceListInfo,
          (cam) => cam.device.psn === psn
        )?.device
    )) as ICameraInfo;

    yield put(closeToast());
    const tab1 = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Tab1"
    );
    const tab2 = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Tab2"
    );
    const tab3 = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Tab3"
    );
    const wifi = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Wifi"
    );
    const cloud = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Cloud"
    );
    let resp = [];

    const config = getFirmwareConfig(camera);
    if (config.CloudNative4) {
      resp = yield call(Api.updateCNFirmwareSettings, {
        email: email,
        psn: psn,
        fwVer: firmware_version,
        settingVer: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        fwLang: firmware_language,
        cloudSettings: [tab1, tab2, tab3, wifi, cloud],
      } as IFirmwareSettings2);
    } else {
      resp = yield call(
        Api.updateFirmwareSettings,
        {
          ...payload.firmware,
          cloud_settings: [tab1, tab2, tab3, wifi, cloud],
          setting_version: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        } as IFirmwareSettings,
        user_token
      );
    }
    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };

    if (cameraInfo.active === "on" && resultcode === "BC_ERR_OK") {
      yield put(successLoadFirmwareSettings(payload.firmware));
      yield put(openToast({ message: "Camera settings saved_" }));
    } else if (cameraInfo.active === "off" && resultcode === "BC_ERR_OK") {
      yield put(successLoadFirmwareSettings(payload.firmware));
      yield put(openToast({ message: "After Blackvue is_" }));
    } else {
      yield put(openToast({ message: "Failed to save_" }));
      yield put(failLoad());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handlRequestFota({ payload }: PayloadAction<IFotaIDPayload>) {
  try {
    const { email, loginInfo } = yield select(
      (state: RootState) => state[USER]
    );
    const { cameraList } = yield select((state: RootState) => state[CAMERA]);
    const resp = yield call(
      Api.getFotaID,
      email,
      loginInfo.user_token,
      payload
    );
    const camera = _.find(
      cameraList?.deviceListInfo,
      (dev) => dev.device.psn === payload.psn
    )?.device;

    if (camera) {
      const {
        resultcode,
        message,
        response: { fotaId },
      } = resp.data as {
        resultcode: RESULT_CODE;
        message: string;
        response: { fotaId: string };
      };

      if (resultcode === "BC_ERR_OK") {
        const config = getFirmwareConfig(camera);
        if (config.CloudNative4) {
          const resp = yield call(
            Api.getCNFotaStart,
            email,
            loginInfo.user_token,
            fotaId,
            payload
          );
          const { resultcode } = resp.data;
          if (resultcode === "BC_ERR_WIFI_IN_USE") {
            yield put(
              openToast({ message: "Unable to initiate update because_" })
            );
            yield put(setFotaFailed(true));
          }
        } else {
          yield call(
            Api.getFotaStart,
            email,
            loginInfo.user_token,
            camera.lb_server_name,
            camera.lb_http_port,
            fotaId,
            payload
          );
        }
      } else {
        yield put(setError(message));
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleRebootCamera({ payload }: PayloadAction<ICameraInfo>) {
  try {
    const email = (yield select(
      (state: RootState) => state[USER].email
    )) as string;
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield put(ignoreError());
    let rebootSuccess: boolean = false;
    const config = getFirmwareConfig(payload);
    if (config.CloudNative4) {
      let res = yield call(
        Api.rebootCNCamera,
        email,
        loginInfo.user_token,
        payload.psn
      );
      if (res.data.resultcode === "BC_ERR_OK") {
        rebootSuccess = true;
      }
    } else {
      let res = yield call(
        Api.rebootCamera,
        payload.lb_server_name,
        payload.lb_http_port,
        email,
        loginInfo.user_token,
        payload.psn
      );
      if (res.data === "success") {
        rebootSuccess = true;
      }
    }
    if (rebootSuccess === true) {
      yield put(clearLoading());
      yield put(openToast({ message: "Restarting the BlackVue_" }));
    } else {
      yield put(clearLoading());
      yield put(openToast({ message: "Failed to reboot_" }));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(openToast({ message: "Failed to reboot_" }));
  }
}

function* handleLoadFirmwareConfig({ payload }: PayloadAction<ICameraInfo>) {
  try {
    const firmwareConfig = getFirmwareConfig(payload);
    yield put(setFirmwareConfig(firmwareConfig));
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadPrivacy({ payload }: PayloadAction<string>) {
  try {
    const email = (yield select(
      (state: RootState) => state[USER].email
    )) as string;
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.loadPrivacy,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      response: IPrivacy;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadPrivacy(response));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* watchCamera() {
  yield takeLatest(addCamera, handleAddCamera);
  yield takeLatest(renameCamera, handleRenameCamera);
  yield takeLatest(deleteCamera, handleDeleteCamera);
  yield takeLatest(loadCamera, handleLoadCamera);
  yield takeLatest(loadPublicCamera, handleLoadPublicCamera);
  yield takeLatest(loadCameras, handleLoadCameras);
  yield takeLatest(updateCameras, handleUpdateCameras);
  // yield takeLatest(loadCamReg, handleLoadCamReg);
  yield takeLatest(loadFirmwareConfig, handleLoadFirmwareConfig);
  yield takeLatest(loadMyCemrasLocation, handleLoadMyCamerasLocation);
  yield takeLatest(updatePrivacy, handleUpdatePrivacy);
  yield takeLatest(loadFwUpdateInfo, handleLoadFwUpdateInfo);
  yield takeLatest(loadNotification, handleLoadNotification);
  yield takeLatest(updateNotification, handleUpdateNotification);
  yield takeLatest(loadLiveEventSettings, handleLoadLiveEventSettings);
  yield takeLatest(updateLiveEventSettings, handleUpdateLiveEventSettings);
  yield takeLatest(loadFirmwareInfos, handleLoadFirmwareInfos);
  yield takeLatest(loadFirmwareSettings, handleLoadFirmwareSettings);
  yield takeLatest(updateFirmwareSettings, handleUpdateFirmwareSettings);
  yield takeLatest(loadNotificationEmailList, handleLoadNotificationEmailList);
  yield takeLatest(invite, handleInviteEmail);
  yield takeLatest(deleteEmail, handleDeleteEmail);
  yield takeLatest(requestFota, handlRequestFota);
  yield takeLatest(rebootCamera, handleRebootCamera);
  yield takeLatest(
    fixMasterEmailNotification,
    handleFixMasterEmailNotification
  );
  yield takeLatest(loadPrivacy, handleLoadPrivacy);
  yield takeLatest(loadFreeCamLocation, handleLoadFreeCamLocation);
}
