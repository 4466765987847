import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Route, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUserProfile, USER } from "../features/User/slice";
import { Toast } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { detect } from "detect-browser";
import { useQuery } from "../utils/Hooks";
import { changeDirection, setFontFamily } from "../features/Theme/slice";
import _ from "lodash";
import { RootState } from "../features/store";
import { closeToast, TOAST } from "../features/Toast/slice";
import { LiveviewScreen } from "../screens/app/LiveviewScreen";
import { setPermission } from "../features/Permission/slice";
import withAppViewerTemplate from "../hoc/withAppViewerTemplate";
import { CAMERA } from "../features/Camera/slice";
import { ICameraInfo, Languages } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(var(--vh, 1vh) * 100)",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,
    padding: theme.spacing(2),
  },
}));
interface AppProps {
  value?: number;
  onGetCamName?: (camName: string) => void;
  noBottom?: boolean;
}

const AppRouter = withAppViewerTemplate((props: AppProps) => {
  const { onGetCamName, noBottom } = props;
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const query = useQuery();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { loginInfo, email, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const { open, message, option, notification } = useSelector(
    (state: RootState) => state[TOAST]
  );
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const [psn, setPsn] = useState<string | null>(null);

  useEffect(() => {
    const browser = detect();
    //@ts-ignore
    if (browser?.name === "ios-webview" && webkit.messageHandlers.pageLoaded) {
      //@ts-ignore
      return webkit.messageHandlers.pageLoaded.postMessage({});
    }

    //@ts-ignore
    if (browser?.name === "chromium-webview" && window.Webviewer?.pageLoaded) {
      //@ts-ignore
      window.Webviewer.pageLoaded?.();
    }
  }, []);

  useEffect(() => {
    setPsn(query.get("psn"));
  }, [query]);

  useEffect(() => {
    if (cameraList) {
      const cam = _.chain(cameraList?.deviceListInfo)
        .map((c) => c.device)
        .find((c) => c.psn === psn)
        .value();
      if (cam) {
        setCurrentCam((c) => {
          if (c?.psn !== cam?.psn) {
            return cam;
          } else if (c?.active !== cam?.active) {
            return cam;
          }
          return c;
        });
      }
    }
  }, [psn, cameraList?.deviceListInfo, dispatch, cameraList]);

  useEffect(() => {
    if (currentCam?.dev_name) {
      onGetCamName?.(currentCam?.dev_name);
    }
  }, [currentCam?.dev_name, onGetCamName]);

  useEffect(() => {
    if (loginInfo && email && tokenType) {
      dispatch(
        //@ts-ignore
        setPermission({
          liveviewPerm: true,
        })
      );
      dispatch(loadUserProfile());
    }
  }, [dispatch, email, loginInfo, tokenType]);

  useEffect(() => {
    let lang = query.get("lang");
    if (!_.includes(_.keys(Languages), lang)) {
      lang = "en";
    }
    switch (lang) {
      case "ar":
        i18n.changeLanguage("ar");
        dispatch(changeDirection("rtl"));
        dispatch(setFontFamily("Noto Naskh Arabic UI"));
        break;
      case "de":
      case "es":
      case "fi":
      case "fr":
      case "id":
      case "it":
      case "nb":
      case "nl":
      case "pl":
      case "ru":
      case "vi":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Roboto"));
        break;
      case "th":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans Thai UI"));
        break;
      case "ja":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans JP"));
        break;
      case "ko":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans KR"));
        break;
      case "zh":
      case "sc":
        i18n.changeLanguage("sc");
        dispatch(setFontFamily("Noto Sans SC"));
        break;
      default:
        i18n.changeLanguage("en");
        dispatch(setFontFamily("Roboto"));
    }
  }, [dispatch, i18n, query]);

  // useEffect(() => {
  //   if (
  //     query.has("email") &&
  //     query.has("user_token") &&
  //     query.has("token_type")
  //   ) {
  //     dispatch(
  //       setLoginInfo({
  //         //@ts-ignore
  //         email: query.get("email"),
  //         //@ts-ignore
  //         user_token: query.get("user_token"),
  //         //@ts-ignore
  //         token_type: query.get("token_type"),
  //         //@ts-ignore
  //         gps_server: query.get("gps_server"),
  //         //@ts-ignore
  //         gps_port: query.get("gps_port"),
  //       })
  //     );
  //   }
  // }, [dispatch, query]);

  const handleBack = useCallback((reason?: string) => {
    const browser = detect();
    if (browser?.name === "ios-webview") {
      //@ts-ignore
      return webkit.messageHandlers.back.postMessage({ reason });
    }
    if (browser?.name === "chromium-webview") {
      //@ts-ignore
      if (window.Sim?.back) {
        //@ts-ignore
        window.Sim.back();
      }

      //@ts-ignore
      if (window.Webviewer?.back) {
        //@ts-ignore
        window.Webviewer.back(JSON.stringify({ reason }));
      }
    }
  }, []);

  const routes = useMemo(() => {
    let liveView: React.ReactElement | undefined = undefined;
    console.log("AppRouter", loginInfo);
    if (loginInfo && email && tokenType) {
      liveView =
        loginInfo.gps_server && loginInfo.gps_port ? (
          <Route path={`${path}`}>
            <div
              style={{
                paddingTop: 65,
                // height: "calc(var(--vh, 1vh) * 100)",
                height: "100vh",
                paddingBottom: noBottom ? 0 : 50,
              }}
            >
              <LiveviewScreen noBottom={noBottom} onBack={handleBack} />
            </div>
          </Route>
        ) : (
          <></>
        );
    }
    console.log("AppRouter", liveView);
    return <>{liveView}</>;
  }, [email, handleBack, loginInfo, path, noBottom, tokenType]);

  return (
    <div className={classes.root}>
      {routes}

      <Toast
        open={open}
        message={(message && t(message, option)) ?? ""}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          dispatch(closeToast());
        }}
        // mantis - 11079, app과 모바일에서 notification설정 변경시 토스트팝업 가운데로 출력되도록 수정 (Leehj)
        notification={notification}
      />
    </div>
  );
});

export default AppRouter;
