import React, { useMemo } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import {
  Container,
  IconButton,
  Typography,
  LiveView,
  GPSTracking,
  Fonts,
  AppBar,
} from "@thingsw/pitta-design-system";

import { useTranslation } from "react-i18next";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useDispatch, useSelector } from "react-redux";
import { finishLiveview } from "../features/LiveView/slice";
import { useQuery } from "../hooks/useQuery";
import _ from "lodash";
import clsx from "clsx";
import { THEME } from "../features/Theme/slice";
import { RootState } from "../features/store";
import { SemanticDarkColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hederDiv: {
      display: "flex",
      alignItems: "center",
      minHeight: 47,
      padding: theme.spacing(0, 1.5),
      backgroundColor: (props: any) => props.colors.primary["0"],
    },
    headerCamName: {
      // mantis - 12092, 카메라명 말줄임표 적용 (Leehj)
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    headerLine: {
      color: (props: any) => props.colors.primary["1"],
      borderBottom: "unset",
    },
    noBottomHeaderLine: {
      borderBottom: "unset",
    },
    hederIcon: {
      marginRight: theme.spacing(1.5),
    },
    actionRoot: {
      color: (props: any) => props.colors.primary["1"],
    },
    //@ts-ignore
    actionLabel: {
      ...Fonts.Default.Caption,
      whiteSpace: "nowrap",
      fontSize: "12px !important",
      marginTop: 1,
    },
    actionDisabled: {
      color: (props: any) => `${props.colors.primary["1"]}61`,
    },
    footerMenuDiv: {
      position: "fixed",
      width: "100%",
      bottom: 0,
      borderTop: (props: any) => `1px solid ${props.colors.primary["5"]}`,

      backgroundColor: (props: any) => props.colors.primary["0"],
    },
  })
);

const withAppViewerTemplate = (WrappedComponent: React.FC<any>) => (
  props: any
) => {
  const { color, colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ colors });

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const query = useQuery();
  const [value] = React.useState(1);
  const [camName, setCamName] = React.useState<string>("");

  const [
    disablePlayback,
    disableLiveview,
    disableGPS,
    disableReport,
  ] = useMemo(() => {
    const disabled = JSON.parse(query.get("disable_menu") ?? "[]");
    return [
      _.includes(disabled, "playback"),
      _.includes(disabled, "liveview"),
      _.includes(disabled, "gps-tracking"),
      _.includes(disabled, "report"),
    ];
  }, [query]);

  const noBottom = useMemo(() => {
    return JSON.parse(query.get("no_bottom") ?? "false");
  }, [query]);

  return (
    <div
      style={{
        backgroundColor:
          color === "dark"
            ? SemanticDarkColors.primary["1"]
            : colors.primary["0"],
      }}
    >
      <AppBar
        color="default"
        className={clsx({
          [classes.headerLine]: !noBottom,
          [classes.noBottomHeaderLine]: noBottom,
        })}
      >
        <Container>
          <div className={classes.hederDiv}>
            <IconButton
              className={classes.hederIcon}
              onClick={() => {
                dispatch(finishLiveview());
              }}
              darkColor={color === "dark"}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={colors.primary["1"]}
              className={classes.headerCamName}
            >
              {/* Dashcam 6 */}
              {/* mantis - 12318, 서버에서 디바이스 정보 호출하기전까지 default로 "BlackVue Live" 문구 제거 (Leehj) */}
              {camName ? camName : ""}
            </Typography>
          </div>
        </Container>
      </AppBar>
      <WrappedComponent
        value={value}
        noBottom={noBottom}
        onGetCamName={(camName: string) => {
          setCamName(camName);
        }}
        {...props}
      />
      {!noBottom && (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            // setValue(newValue);
            switch (newValue) {
              case 0:
                dispatch(finishLiveview("MENU_PLAYBACK"));
                break;
              case 2:
                dispatch(finishLiveview("MENU_GPS_TRACKING"));
                break;
              case 3:
                dispatch(finishLiveview("MENU_REPORT"));
                break;
            }
          }}
          showLabels
          className={classes.footerMenuDiv}
        >
          <BottomNavigationAction
            disableRipple
            classes={{
              root: clsx(classes.actionRoot, {
                [classes.actionDisabled]: disablePlayback,
              }),
              label: classes.actionLabel,
            }}
            label={t("Playback")}
            icon={<PlaylistPlayIcon />}
            disabled={disablePlayback}
          />
          <BottomNavigationAction
            disableRipple
            classes={{
              root: clsx(classes.actionRoot, {
                [classes.actionDisabled]: disableLiveview,
              }),
              label: classes.actionLabel,
            }}
            label={t("Live view")}
            icon={<LiveView />}
            disabled={disableLiveview}
          />
          <BottomNavigationAction
            disableRipple
            classes={{
              root: clsx(classes.actionRoot, {
                [classes.actionDisabled]: disableGPS,
              }),
              label: classes.actionLabel,
            }}
            label={t("GPS Tracking")}
            icon={<GPSTracking />}
            disabled={disableGPS}
          />
          <BottomNavigationAction
            disableRipple
            classes={{
              root: clsx(classes.actionRoot, {
                [classes.actionDisabled]: disableReport,
              }),
              label: classes.actionLabel,
            }}
            label={t("Report")}
            icon={<AssignmentIcon />}
            disabled={disableReport}
          />
        </BottomNavigation>
      )}
    </div>
  );
};

export default withAppViewerTemplate;
