import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface SaveSettingModalProps {
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // mantis - 10496, 모바일 일부 기종(Galaxy Z Flip3 5G)에서 모달 하단에 스크롤 생기는 이슈 수정 (Leehj)
    // maxWidth: 288,
    margin: 16,
    "&>.MuiDialogActions-root": {
      justifyContent: "center",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        justifyContent: "flex-end",
      },
    },
    "&>.buttonPadding": {
      padding: "0px 23px 24px",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 560,
    },
  },
  titleDiv: {
    padding: "24px 44px 0 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 54px 0 20px",
    },
  },
  contentDiv: {
    padding: "12px 24px 32px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "11px 24px 16px",
    },
  },
  closeStyle: {
    top: 13,
    right: 16,
  },
  btn: {
    minWidth: "151.5px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: "143.5px",
    },
  },
}));

export const FirmwareFormatModal = ({
  open,
  loading,
  onClose,
  onClickNegative,
  onClickPositive,
}: SaveSettingModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  return (
    <Modal
      btnCenterMobile={mobile}
      className={classes.root}
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      // mantis - 10748, 피그마 디자인 적용 (Leehj)
      // heading={t("Save settings")}
      heading={t("Please back up_")}
      close
      content={
        <Typography
          variant="Body"
          category="Default"
          // mantis - 10748, 피그마 디자인 적용 (Leehj)
          // dangerouslySetInnerHTML={{ __html: t("Back up all_") }}
        >
          {t("When you save_")}
        </Typography>
      }
      // mantis - 10748, 피그마 디자인 적용 (Leehj)
      LButton={t("OK")}
      RButton={t("Save settings")}
      // RButtonDisabled={disabledChange}
      Secondary={false}
      loading={loading}
      titleClassName={classes.titleDiv}
      contentClassName={classes.contentDiv}
      closeStyle={classes.closeStyle}
      RButtonClassName={classes.btn}
      LButtonClassName={classes.btn}
    />
  );
};
