import React, { useCallback } from "react";
import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { Link, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../features/store";
import { CAMERA } from "../features/Camera/slice";
import { GROUP } from "../features/Group/slice";
import { LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  ol: {
    flexFlow: "nowrap",
  },
  title: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

type BreadFn = (items: RegExpMatchArray) => string;

const breadcrumbNameMap: { [key: string]: string | BreadFn | undefined } = {
  "/gps-tracking": "GPS tracking",
  "^/cameras": "Cameras",
  "^/cameras/([A-za-z0-9]+)": (items) => items[1],
  "^/cameras/([A-za-z0-9]+)/playback": undefined,
  "^/cameras/([A-za-z0-9]+)/live-view": undefined,
  "^/cameras/([A-za-z0-9]+)/gps-tracking": undefined,
  "^/cameras/([A-za-z0-9]+)/settings": undefined,
  "^/cameras/([A-za-z0-9]+)/report": undefined,
  "^/cameras/([A-za-z0-9]+)/fota": "Remote firmware update",
  "^/cameras/add-camera": "Add camera",
  "/members": "Members",
  "/members/([A-za-z0-9@.-_]+)": (items) => items[1],
  "/members/invite-member": "Invite member",
  "/groups": "Groups",
  "/groups/add-group": undefined,
  "^/groups/([A-za-z0-9\\s]+)": (items) => items[1],
  "/camera/fota": "Remote firmware update",
  "/geofences": "Geofences",
  "/geofences/add-geofence": undefined,
  "/reports": "Reports",
};

const getLinkFromTitle = (title: string) => {
  switch (title) {
    case "Cameras":
      return "/cameras";
    case "Members":
      return "/members";
    case "Groups":
      return "/groups";
    case "Geofences":
      return "/geofences";
  }
};

export const Breadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  const camera = useSelector((state: RootState) => state[CAMERA].camera);
  const groupsList = useSelector(
    (state: RootState) => state[GROUP].groupsList?.DashCamList
  );

  const getPathnames = useCallback(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const titles = _.chain(pathnames)
      .map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const key = _.chain(breadcrumbNameMap)
          .keys()
          .findLast((b) => {
            const regex = new RegExp(b);
            // console.log("find", b, to.match(regex));
            return !!to.match(regex);
          })
          .value();
        const regex = new RegExp(key);
        const result = to.match(regex);
        const bread = breadcrumbNameMap[key];
        let ret: string | undefined = undefined;
        if (typeof bread === "string") {
          ret = bread;
        } else if (typeof bread === "function" && result) {
          ret = bread(result);
          if (camera?.psn === ret) {
            ret = camera.dev_name;
          }
          const group = _.find(groupsList, (g) => g.GroupID === ret);
          if (group) {
            ret = group.GroupName;
          }
        }

        return ret;
      })
      .compact()
      .value();
    return _.map(titles, (title, index) => {
      const link = getLinkFromTitle(title);
      if (titles.length > 1 && index === 0 && link) {
        return (
          <Link
            key={`breadccrumbs-${title}`}
            to={link}
            category="Default"
            variant="Body"
            color="secondary"
          >
            {t(title)}
          </Link>
        );
      }
      return (
        <div
          style={{ display: "grid", whiteSpace: "nowrap" }}
          key={`breadccrumbs-${title}`}
        >
          <Typography
            category="Default"
            variant="Body"
            htmlColor={
              titles.length === index + 1
                ? LightColors.primary["1"]
                : LightColors.primary["2"]
            }
            key={`breadccrumbs-${title}`}
            className={classes.title}
          >
            {t(title)}
          </Typography>
        </div>
      );
    });
  }, [
    location.pathname,
    camera?.psn,
    camera?.dev_name,
    groupsList,
    classes.title,
    t,
  ]);

  return <MuiBreadcrumbs classes={classes}>{getPathnames()}</MuiBreadcrumbs>;
};
