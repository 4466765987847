export function isMobileOSCheck() {
  const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf("android") > -1) {
    return "android";
  } else if (
    varUA.indexOf("iphone") > -1 ||
    varUA.indexOf("ipad") > -1 ||
    varUA.indexOf("ipod") > -1
  ) {
    return "ios";
  } else {
    return "web";
  }
}
