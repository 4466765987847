import React, { useState, useEffect } from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Lottie from "lottie-react";
import animationVoiceCall from "../../lottiefiles/modal-voiceCall.json";

export interface Status {
  openModal: boolean;
  onClose: () => void;
  onClickPositive: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    width: 560,
    minHeight: 180,
    [theme.breakpoints.up("sm")]: {},
    "& .modalTitle": {
      color: "#0095E0",
      marginTop: 0,
      marginBottom: 4,
    },
    "& .modalSubTitle": {
      color: "#0095E0",
      marginTop: 0,
      marginBottom: 8,
    },
    "& .modalPaging": {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      marginTop : 24,
    },
  },

  modalHeading: {
    padding: "16px 42px 0px 24px",
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
    [theme.breakpoints.up("sm")]: {},
  },
  headingTextIcon: {
    padding: 0,
  },

  modalContent: {
    padding: "14px 24px 32px",
    overflow: "hidden",
    "& .modalContImg": {
      width: "100%",
      aspectRatio: "1/0.56",
      marginTop: 24,
      "& svg": {
        display: "block",
      },
    },
    [theme.breakpoints.up("sm")]: {},
  },

  modalButton: {
    padding: "0px 24px auto",
    [theme.breakpoints.up("sm")]: {},
  },

  modalClose: {
    top: 12,
    
    // id - 166
    // 아랍어 시 direction 적용
    ...(theme.direction === "rtl" ? { left: 13 } : { right: 13 }),
    [theme.breakpoints.up("sm")]: {},
  },

  LBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
  },

  RBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
  },
}));

export const VoiceCallModal = (props: Status) => {
  const { openModal, onClose, onClickPositive } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if(openModal) setPage(1);
  },[openModal]);

  return (
    <Modal
      className={classes.modalBody}
      open={openModal}
      close
      closeStyle={classes.modalClose}
      onClose={onClose}
      titleClassName={classes.modalHeading}
      content={
        <>
          <HelpOutlineIcon style={{marginBottom: 26}}/>
          {page === 1 && (
            <>
              <p className="modalTitle">
                <Typography
                  category="Default"
                  variant="H4"
                >
                  {t("Voice Call")}
                </Typography>
              </p>
              <p className="modalSubTitle">
                <Typography
                  category="Default"
                  variant="Body"
                >
                  {t("Realtime Voice-Talk")}
                </Typography>
              </p>
              <Typography
                category="Default"
                variant="Small"
              >
                {t("Initiate a conversation_")}
              </Typography>
              <div style={{display: "flex", borderBottom: "1px solid #E9E9EA", marginTop: 12}}>
                <Lottie className="modalContImg" animationData={animationVoiceCall} />
              </div>
            </>
          )}
          {page === 2 && (
            <>
              <p className="modalTitle">
                <Typography
                  category="Default"
                  variant="H4"
                >
                  {t("Two-way Voice Communication")}
                </Typography>
              </p>
              <p className="modalSubTitle">
                <Typography
                  category="Default"
                  variant="Body"
                >
                  {t("Realtime Voice-Talk")}
                </Typography>
              </p>
              <Typography
                category="Default"
                variant="Small"
              >
                {t("Send voice messages to the driver with this push-to-talk feature, while hearing the driver at all times.")}
              </Typography>
              <div 
                className="modalContImg"
                style={{ marginTop: 12, borderTop: "1px solid #E9E9EA", borderBottom: "1px solid #E9E9EA", background: "url(/images/pricing-modal-voiceCall-02.png) no-repeat center/contain" }}
              >
              </div>
            </>
          )}
          <div className="modalPaging">              
            <Typography
              category="Default"
              variant="Small"
              style={{marginRight: 8}}
            >
              {"*" + t("Cloud-compatible BlackVue")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
            >
              {page} / 2
            </Typography>
          </div>
        </>
      }
      contentClassName={classes.modalContent}
      RButton={page === 1 ? t("Next") : t("OK")}
      onClickPositive={() => {
        page === 1 ? setPage(2) : onClickPositive()
      }}
      onClickNegative={onClose}
      actionClassName={classes.modalButton}
      Secondary={false}
      RButtonClassName={classes.RBtn}
    />
  );
};
