import React from "react";
import { Modal } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { CAMERA } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { useSelector } from "react-redux";
import { Webviewer, SIGMASTAR_MODELS } from "@thingsw/pitta-modules";

interface FirmwareSettingModalProps {
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&>.MuiDialogActions-root": {
      justifyContent: "center",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        justifyContent: "flex-end",
      },
    },
    "&>.buttonPadding": {
      padding: "0px 23px 24px",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 560,
    },
  },
  titleDiv: {
    padding: " 23px 22px 0 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "17px 44px 0 20px",
    },
  },
  contentDiv: {
    padding: "14px 24px",
    [theme.breakpoints.up("sm")]: {
      padding: "13px 24px",
    },
  },
}));

export const FirmwareSettingModal = ({
  open,
  loading,
  onClose,
  onClickNegative,
  onClickPositive,
}: FirmwareSettingModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { camera } = useSelector((state: RootState) => state[CAMERA]);

  return (
    <Modal
      btnCenterMobile={mobile}
      className={classes.root}
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      heading={
        // mantis - 11404, 모달창 피그마 디자인 적용 및 Please note that_문구 중 Video -> video로 수정 (Leehj)
        SIGMASTAR_MODELS
          ? t("Please note that_")
          : camera?.active === "on"
          ? t("Please recognize that_")
          : t("Please note that_")
      }
      longHeader
      titleClassName={classes.titleDiv}
      contentClassName={classes.contentDiv}
      close
      content={""}
      // content={
      //   camera?.active === "on" ? (
      //     <Typography variant="Body" category="Default">
      //       {t("Please recognize that_")}
      //     </Typography>
      //   ) : (
      //     <Typography variant="Body" category="Default">
      //       {t("Please note that_")}
      //     </Typography>
      //   )
      // }
      RButton={t("Save settings")}
      LButton={t("Cancel")}
      Secondary={false}
      loading={loading}
    />
  );
};
