import React, { useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  GPSTracking,
  IconButton,
  Typography,
} from "@thingsw/pitta-design-system";
import { Paper } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import SimpleBarReact from "simplebar-react";
import { LightColors } from "@thingsw/pitta-modules";

const useListItemStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      "& *": {
        color: LightColors.primary["7"],
        fill: LightColors.primary["7"],
      },
    },
    textAlign: theme.direction === "rtl" ? "right" : "left",
    "&.Mui-disabled": {
      "&:hover *": {
        color: LightColors.primary["1"],
        fill: LightColors.primary["1"],
      },
    },
  },
  selected: {
    backgroundColor: "transparent!important",
    "& *": {
      color: LightColors.primary["7"],
      fill: LightColors.primary["7"],
      fontWeight: "500!important",
    },
  },
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "#13131C73",
    zIndex: 10000,
  },
  hidden: {
    display: "none",
  },
  modal: {
    position: "absolute",
    top: 56,
    left: 250,
    width: 696,
    height: 577,
    "&::after": {
      position: "absolute",
      content: "''",
      top: 154,
      bottom: 0,
      left: -22,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderRight: "24px solid white",
      borderTop: "14px solid transparent",
      borderBottom: "14px solid transparent",
    },
  },
  paper: {
    width: "100%",
    border: `1px solid ${LightColors.primary["5"]}`,
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    position: "absolute",
    paddingLeft: 8,
    top: 205,
    left: 8,
    width: 218,
    height: 42,
    display: "flex",
    justifyContent: "center",
    background: "white",
    borderRadius: 10,
  },
  listItemRootOpen: {
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  listItemIcon: {
    ...(theme.direction === "rtl" ? { marginRight: 12 } : { marginLeft: 12 }),
    minWidth: 34,
  },
  listItemTextRoot: {
    overflow: "hidden",
  },
  menuIcon: {
    fontSize: "1.125rem",
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        }
      : {}),
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  webTitleDiv: {
    padding: theme.spacing(1.75, 3, 0, 3),
  },
  contentBtnWrap: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2.75),
    overflowY: "auto",
    padding: theme.spacing(0, 3, 2.625, 3),
  },
  webImgDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
  buttonWrap: {
    paddingTop: theme.spacing(6),
    alignItems: "center",
  },
  scrollbarStyle: {
    height: "100%",
    overflowX: "hidden",
    flex: 1,
    maxHeight: "calc(100vh - 150px)",
  },
  backBtn: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
}));

interface OnboardingStep3Props {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  onNext: () => void;
}

export const OnboardingStep3 = (props: OnboardingStep3Props) => {
  const { open, onClose, onNext, onBack } = props;
  const classes = useStyles();
  const listItemClasses = useListItemStyles();
  const { t, i18n } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div className={clsx(classes.root, !open && classes.hidden)}>
      <div ref={anchorRef} className={classes.btn}>
        <ListItem
          selected
          classes={{
            ...listItemClasses,
            root: clsx(listItemClasses.root, classes.listItemRootOpen),
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <GPSTracking className={classes.menuIcon} />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.listItemTextRoot }}
            primary={
              <Typography category="Default" variant="Body">
                {i18n.language === "ja" ? t("GPS tracking") : "GPS tracking"}
              </Typography>
            }
          />
        </ListItem>
      </div>
      <div className={classes.modal}>
        <Paper elevation={3} className={classes.paper}>
          <div
            className={clsx(classes.spaceBetween, classes.webTitleDiv)}
            style={{ alignItems: "center" }}
          >
            <Typography category="Default" variant="H6">
              {t("GPS tracking")}
            </Typography>
            <IconButton onClick={onClose} style={{ marginRight: -8 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <SimpleBarReact className={classes.scrollbarStyle}>
            <div className={classes.contentBtnWrap}>
              <Typography category="Default" variant="Body">
                {t("Visualize your vehicle’s_")}
              </Typography>
              {/* Smart 요금제 GPS Tracking 기간 변경에 따라 온보딩 가이드 내용 삭제 24.01.08 (hongcs) */}
              {/* <Typography category="Default" variant="Body">
                ({t("Smart plan_")})
              </Typography> */}
              <div className={classes.webImgDiv}>
                <img
                  srcSet="/images/onboarding3.png 1x"
                  alt="onboarding3"
                  style={{ width: 540, height: 336 }}
                />
              </div>
              <div className={clsx(classes.spaceBetween, classes.buttonWrap)}>
                <Typography category="Default" variant="Body">
                  3 / 5
                </Typography>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onBack}
                    className={classes.backBtn}
                  >
                    {t("Back")}
                  </Button>
                  <Button variant="contained" color="primary" onClick={onNext}>
                    {t("Got it")}
                  </Button>
                </div>
              </div>
            </div>
          </SimpleBarReact>
        </Paper>
      </div>
    </div>
  );
};
