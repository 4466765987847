import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { CameraInfoRev } from "@thingsw/pitta-design-system";
import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CAMERA } from "../features/Camera/slice";
import { RootState } from "../features/store";
import {
  ICameraInfo,
  LightColors,
  PSN650,
  ReferenceObject,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  cameraPaper: {
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 12,
    padding: "4px 0",
  },
  cameraListDiv: {
    width: 286,
    maxHeight: "50vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

export interface CameraListPopperProps {
  open: boolean;
  disabled?: boolean;
  /**
   * A HTML element, [referenceObject](https://popper.js.org/docs/v1/#referenceObject),
   * or a function that returns either.
   * It's used to set the position of the popper.
   * The return value will passed as the reference object of the Popper instance.
   */
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
  /**
   * Callback fired when a "click away" event is detected.
   */
  onClickAway: (event: React.MouseEvent<Document>) => void;
  cameraName: string;
  onClick: (cam: ICameraInfo) => void;
  width?: number;
}

export const CameraListPopper = ({
  open,
  anchorEl,
  cameraName,
  width,
  onClickAway,
  onClick,
  disabled,
}: CameraListPopperProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

  const filterCam = useMemo(() => {
    // mantis - 11836, Reports,GPS Tracking에서 wifi 카메라는 아예 미노출로 수정 (Leehj)
    if (cameraList?.deviceListInfo) {
      return _.filter(cameraList.deviceListInfo, (dev) => {
        const loggined = !dev.device.login_date
          ? false
          : !moment(dev.device.login_date).isBefore(moment.utc(0));

        if (
          !(
            _.includes(PSN650, dev.device.psn.substr(0, 4)) ||
            dev.device.reg_category === "wifi" ||
            (dev.device.reg_category === "cloud" && !loggined)
          )
        ) {
          return dev;
        }
      }) as any;
    }
  }, [cameraList]);

  return (
    <Popper
      dir={theme.direction}
      open={open}
      anchorEl={anchorEl}
      modifiers={{
        offset: {
          enabled: true,
          offset: "0, 4",
        },
      }}
      style={{
        zIndex: 99,
      }}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: "center top",
          }}
        >
          <Paper classes={{ root: classes.cameraPaper }} elevation={0}>
            <ClickAwayListener onClickAway={onClickAway}>
              <div className={classes.cameraListDiv} style={{ width }}>
                {_.chain(filterCam)
                  .filter(
                    (dev) =>
                      dev.device.dev_name
                        .toLowerCase()
                        .indexOf(cameraName.toLowerCase()) > -1 ||
                      dev.device.model
                        .toLowerCase()
                        .indexOf(cameraName.toLowerCase()) > -1
                  )
                  // mantis - 12376, report페이지 비활성화 Cloud camera 미노출 적용 (Leehj)
                  .filter((dev) => dev.device.valid !== "invalid")
                  .map((dev) => {
                    // const loggined = !dev.device.login_date
                    //   ? false
                    //   : !moment(dev.device.login_date).isBefore(moment.utc(0));
                    return (
                      <CameraInfoRev
                        // mantis - 10517 ,login_date가 없는 경우 비활성화되도록 수정 (Leehj)
                        // mantis - 11481,11482 ,클라우드 사용 불가한 카메라 비활성화되도록 조건 추가 (Leehj)
                        // mantis - 11836, Reports,GPS Tracking에서 wifi 카메라는 아예 미노출로 수정하여 하단 disabled코드는 주석처리 (Leehj)
                        // disabled={
                        //   _.includes(PSN650, dev.device.psn.substr(0, 4)) ||
                        //   dev.device.reg_category === "wifi" ||
                        //   (dev.device.reg_category === "cloud" && !loggined)
                        // }
                        key={dev.device.psn}
                        camera={dev.device}
                        onClick={() => {
                          onClick(dev.device);
                        }}
                        hover
                      />
                    );
                  })
                  .value()}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
