import React, { useEffect } from "react";
import {
  FormControlLabel,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import {
  Button,
  CheckBox,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  CAMERA,
  loadLiveEventSettings,
  updateLiveEventSettings,
} from "../../features/Camera/slice";
import _ from "lodash";
import {
  LightColors,
  Webviewer,
  ILiveEventSettings,
  OnOffType,
  MODELS_3CH,
  MODELS_2CH,
  OPTION_CAMERA_MODELS,
  DMC100_MODELS,
  DMC200_MODELS,
  BOXPRO_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 48,
    marginTop: 16,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    padding: "0 2px 0 10px",
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      minHeight: 48,
      marginBottom: 32,
    },
  },
  bigBox: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      marginBottom: 32,
      borderRadius: 4,
    },
  },
  boderBottomBox: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },

  boderNoneBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { padding: "0 10px 0 2px" }
      : { padding: "0 2px 0 10px" }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
    },
  },
  menuHoriz: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  mgNone: {
    margin: 0,
  },
  buttonMargin: {
    marginBottom: 16,
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
  },
  bigBoxNone: {
    display: "none",
  },
  title: {
    ...(theme.direction === "rtl" ? { paddingRight: 6 } : { paddingLeft: 6 }),
  },
}));

interface LiveEventSettingPanelProps {
  openMenu?: boolean;
}

export const LiveEventSettingPanel = ({
  openMenu,
}: LiveEventSettingPanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const {
    camera,
    firmwareConfig,
    liveEventSettings,
    loading,
    type,
  } = useSelector((state: RootState) => state[CAMERA]);
  // const { subscriptionInfo } = useSelector(
  //   (state: RootState) => state[PAYMENT]
  // );

  const [disabledChange, setDisabledChange] = useState(true);
  const [current, setCurrent] = useState<ILiveEventSettings>();
  const [openPleaseModal, setOpenPleaseModal] = useState(false);

  useEffect(() => {
    if (camera) {
      dispatch(loadLiveEventSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (liveEventSettings) {
      setCurrent(liveEventSettings);
    }
  }, [liveEventSettings]);

  useEffect(() => {
    if (liveEventSettings && current) {
      const diffs = _.keys(current).map((key) => {
        const k = key as keyof ILiveEventSettings;
        return (
          liveEventSettings[k] !== undefined &&
          liveEventSettings[k] === current[k]
        );
      });
      setDisabledChange(_.every(diffs));
    }
  }, [liveEventSettings, current]);

  // const isFree = useMemo(() => {
  //   if (subscriptionInfo) {
  //     return (
  //       getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Free plan"
  //     );
  //   }
  //   return false;
  // }, [subscriptionInfo]);

  const getBoolean = (onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  };

  const handleChange = (key: keyof ILiveEventSettings, checked: boolean) => {
    if (key === "Vs1Chan" && checked === false) {
      if (current?.Vs2Chan !== "on") {
        return setOpenPleaseModal(true);
      }
    } else if (key === "Vs2Chan" && checked === false) {
      if (current?.Vs1Chan !== "on") {
        return setOpenPleaseModal(true);
      }
    }

    setCurrent(
      (c) =>
        ({
          ...c,
          [key]: checked ? "on" : "off",
        } as ILiveEventSettings)
    );
  };

  const handleChangeVs3 = (key: keyof ILiveEventSettings, checked: boolean) => {
    if (key === "Vs1Chan" && checked === false) {
      if (current?.Vs2Chan !== "on" && current?.Vs3Chan !== "on") {
        return setOpenPleaseModal(true);
      }
    } else if (key === "Vs2Chan" && checked === false) {
      if (current?.Vs1Chan !== "on" && current?.Vs3Chan !== "on") {
        return setOpenPleaseModal(true);
      }
    }

    setCurrent(
      (c) =>
        ({
          ...c,
          [key]: checked ? "on" : "off",
        } as ILiveEventSettings)
    );
  };

  const handleUpdate = () => {
    if (current && camera) {
      dispatch(updateLiveEventSettings({ camera: camera, setting: current }));
      //@ts-ignore
      dataLayer.push({
        event: "livesettings.save",
        ...current,
      });
    }
  };

  // const handleCheck = () => {
  //   let a = current?.Vs1Chan;
  //   let b = current?.Vs2Chan;
  //   console.log(a, b, "안녕하세요");
  //   if (a === "off" && b === "off") {
  //     alert("Please select one of the video sources");
  //   } else {
  //   }
  // };

  return (
    <div className={classes.menuHoriz}>
      <div className={classes.box}>
        <FormControlLabel
          className={classes.mgNone}
          control={
            <CheckBox
              color="primary"
              checked={getBoolean(current?.LiveEventUpload)}
              onChange={(event) => {
                handleChange("LiveEventUpload", event.target.checked);
              }}
            />
          }
          label={
            <Typography category="Default" variant="Body">
              {t("Live event upload")}
            </Typography>
          }
        />
      </div>
      <div
        className={clsx(
          classes.bigBox,
          current?.LiveEventUpload !== "on" && classes.bigBoxNone
        )}
      >
        <div className={clsx(classes.boderNoneBox, classes.boderBottomBox)}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                color="primary"
                checked={getBoolean(current?.ManualRecording)}
                onChange={(event) => {
                  handleChange("ManualRecording", event.target.checked);
                }}
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Manual recording")}
              </Typography>
            }
          />
        </div>
        <div className={classes.boderBottomBox}>
          <div className={classes.boderNoneBox}>
            <Typography
              category="Default"
              variant="BodyBold"
              className={classes.title}
            >
              {t("Normal event recording")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.NerImpactDetection)}
                  onChange={(event) => {
                    handleChange("NerImpactDetection", event.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Impact detection")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.NerOverSpeed)}
                  onChange={(event) => {
                    handleChange("NerOverSpeed", event.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Overspeed")}
                </Typography>
              }
            />
          </div>

          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.NerAcceleration)}
                  onChange={(event) => {
                    handleChange("NerAcceleration", event.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {/* osd 키값 참고 */}
                  {t("Acceleration")}
                </Typography>
              }
            />
          </div>

          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.NerHarshBraking)}
                  onChange={(event) => {
                    handleChange("NerHarshBraking", event.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Hard braking")}
                </Typography>
              }
            />
          </div>

          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.NerSharpTurn)}
                  onChange={(event) => {
                    handleChange("NerSharpTurn", event.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {/* osd 키값 참고 */}
                  {t("Sharp turn")}
                </Typography>
              }
            />
          </div>
        </div>

        <div className={classes.boderBottomBox}>
          <div className={classes.boderNoneBox}>
            <Typography
              category="Default"
              variant="BodyBold"
              className={classes.title}
            >
              {t("Parking event recording")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.PerImpactDetection)}
                  onChange={(event) => {
                    handleChange("PerImpactDetection", event.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Impact detection")}
                </Typography>
              }
            />
          </div>
        </div>

        {/* {!isFree && (
          <div className={classes.boderBottomBox}>
            <div className={classes.boderNoneBox}>
              <Typography
                category="Default"
                variant="BodyBold"
                className={classes.title}
              >
                {t("Geo Fencing")}
              </Typography>
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.GEntering)}
                    onChange={(event) => {
                      handleChange("GEntering", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Entering")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.GExiting)}
                    onChange={(event) => {
                      handleChange("GExiting", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Exiting")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.GPassing)}
                    onChange={(event) => {
                      handleChange("GPassing", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Passing")}
                  </Typography>
                }
              />
            </div>
          </div>
        )} */}

        <div>
          <div className={classes.boderNoneBox}>
            <Typography
              category="Default"
              variant="BodyBold"
              className={classes.title}
            >
              {t("Video source")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.Vs1Chan)}
                  onChange={(event) => {
                    if (_.includes(MODELS_3CH, camera?.model)) {
                      handleChangeVs3("Vs1Chan", event.target.checked);
                    } else {
                      handleChange("Vs1Chan", event.target.checked);
                    }
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Front camera")}
                </Typography>
              }
            />
          </div>
          {(_.includes(MODELS_2CH, camera?.model) ||
            _.includes(MODELS_3CH, camera?.model)) && (
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.Vs2Chan)}
                    onChange={(event) => {
                      if (_.includes(MODELS_2CH, camera?.model)) {
                        handleChange("Vs2Chan", event.target.checked);
                      } else {
                        if (
                          current?.Vs1Chan === "off" &&
                          !event.target.checked &&
                          current?.Vs3Chan === "off"
                        ) {
                          return setOpenPleaseModal(true);
                        } else {
                          setCurrent((c) => {
                            return (
                              c && {
                                ...c,
                                Vs2Chan: event.target.checked ? "on" : "off",
                                // 10151 - DR770X Box는 option & rear 동시 체크 가능
                                Vs3Chan:
                                  // DR750X-3CH Plus도 설정옵션을 3가지모두 선택할 수 있도록 수정 (Leehj)
                                  _.includes(MODELS_3CH, camera?.model)
                                    ? current?.Vs3Chan
                                    : current?.Vs2Chan
                                    ? "off"
                                    : "on",
                              }
                            );
                          });
                        }
                      }
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Rear camera")}
                  </Typography>
                }
              />
            </div>
          )}

          {_.includes(MODELS_3CH, camera?.model) && (
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.Vs3Chan)}
                    onChange={(event) => {
                      // handleChange("Vs3Chan", event.target.checked);
                      if (
                        current?.Vs1Chan === "off" &&
                        current?.Vs2Chan === "off" &&
                        event.target.checked === false
                      ) {
                        return setOpenPleaseModal(true);
                      } else {
                        setCurrent((c) => {
                          return (
                            c && {
                              ...c,
                              Vs3Chan: event.target.checked ? "on" : "off",
                              // mantis - 8568, 초기 Vs3Chan 값이 undefined여서 해당 항목 체크시 rear이 함께 체크되는 이슈 수정 (Leehj)
                              // 10151 - DR770X Box는 option & rear 동시 체크 가능
                              // DR750X-3CH Plus도 설정옵션을 3가지모두 선택할 수 있도록 수정하여 아래 코드 주석처리(Leehj)
                              // Vs2Chan:
                              //   camera?.model === "DR770X Box"
                              //     ? current?.Vs2Chan
                              //     : "off",
                            }
                          );
                        });
                      }
                    }}
                  />
                }
                label={
                  // mantis : 8487
                  // BOX_MODELS 일 때 Option camera로 나타남
                  <Typography category="Default" variant="Body">
                    {_.includes(OPTION_CAMERA_MODELS, camera?.model)
                      ? t("Option camera")
                      : t("Interior camera")}
                  </Typography>
                }
              />
            </div>
          )}
        </div>

        {_.includes(DMC100_MODELS, camera?.model) &&
          firmwareConfig?.["System"]?.["DMS"] && (
            <div>
              <div
                className={classes.boderNoneBox}
                style={{ borderTop: `1px solid ${LightColors.primary["6"]}` }}
              >
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ paddingLeft: 6 }}
                >
                  {t("Driver monitoring system (DMS)")}
                </Typography>
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.DmsUndetected)}
                      onChange={(event) => {
                        handleChange("DmsUndetected", event.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Undetected")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.DmsDrowsy)}
                      onChange={(event) => {
                        handleChange("DmsDrowsy", event.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Drowsy")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.DmsDistracted)}
                      onChange={(event) => {
                        handleChange("DmsDistracted", event.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Distracted")}
                    </Typography>
                  }
                />
              </div>
            </div>
          )}

        {_.includes([...DMC200_MODELS, ...BOXPRO_MODELS], camera?.model) && firmwareConfig?.["DMS"] && (
          <div>
            <div
              className={classes.boderNoneBox}
              style={{ borderTop: `1px solid ${LightColors.primary["6"]}` }}
            >
              <Typography
                category="Default"
                variant="BodyBold"
                style={{ paddingLeft: 6 }}
              >
                {t("Driver monitoring system (DMS)")}
              </Typography>
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.DmsUndetected)}
                    onChange={(event) => {
                      handleChange("DmsUndetected", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Undetected")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.DmsDrowsy)}
                    onChange={(event) => {
                      handleChange("DmsDrowsy", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Drowsy")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.DmsDistracted)}
                    onChange={(event) => {
                      handleChange("DmsDistracted", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Distracted")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={
                      getBoolean(current?.DmsCalling) &&
                      getBoolean(current?.DmsSmoking)
                    }
                    onChange={(event) => {
                      handleChange("DmsCalling", event.target.checked);
                      handleChange("DmsSmoking", event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Hand distraction")}
                  </Typography>
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className={clsx({ [classes.buttonMargin]: !mobile })}>
        <Button
          variant="contained"
          color="primary"
          disabled={disabledChange}
          className={clsx({ [classes.buttonSize]: mobile })}
          onClick={handleUpdate}
          loading={loading && type === updateLiveEventSettings.type}
        >
          {t("Save")}
        </Button>
      </div>
      {/* pleaseModal */}
      <Modal
        open={openPleaseModal}
        mobile={mobile}
        onClose={() => setOpenPleaseModal(false)}
        onClickPositive={() => setOpenPleaseModal(false)}
        // heading={t("Please select one of the video sources")}
        close={true}
        content={
          <div style={{ paddingTop: 18 }}>
            <Typography variant="Body" category="Default">
              {t("Please select one of the video sources")}
            </Typography>
          </div>
        }
        RButton={t("OK")}
      />
    </div>
  );
};
