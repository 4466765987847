import React, { useCallback, useEffect, useState } from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import axios from "axios";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  Webviewer,
  LightColors,
  API_GATEWAY_URI,
  STRIPE_OS_TYPE,
} from "@thingsw/pitta-modules";

interface SubRestoreModalProps {
  open: boolean;
  subscription: number | undefined;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 448,
    },
  },
  mgB32: {
    marginBottom: 32,
  },
  inputH: {
    minHeight: 88,
  },
  textArea: {
    width: "100%",
    "&>.MuiOutlinedInput-multiline": {
      padding: "10px 16px",
      maxHeight: 88,
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: `${LightColors.primary["4"]}`,
      },
    },
  },

  textLength: {
    width: "94%",
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 3,
    paddingBottom: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 0,
    },
  },

  focused: {
    "& > fieldset.MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px!important",
      borderColor: `${LightColors.primary["7"]}!important`,
    },
  },
}));
export const SubRestoreModal = ({
  open,
  subscription,
  onClose,
  onClickNegative,
  onClickPositive,
}: SubRestoreModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const [loading, setLoading] = useState(false);
  const [modalHead, setModalHead] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const loadRestoreCode = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await axios.post(`${API_GATEWAY_URI}/Payment/WebSubscribe`, {
        email,
        user_token: loginInfo?.user_token,
        ostype: STRIPE_OS_TYPE,
        step: "restore",
        tokenType,
      });

      const { resultcode } = resp.data as {
        resultcode: string;
        message: string;
      };

      if (resultcode === "BC_ERR_OK") {
        setModalHead(t("Restore Purchase"));
        if (subscription === 1) {
          setModalContent(
            t(
              "This purchase has already been bought. It will be restored for free."
            )
          );
        } else {
          setModalContent(t("Your subscription information is updated."));
        }
      } else if (resultcode === "BC_ERR_NOT_FOUND_DATA") {
        setModalHead(t("Restore Purchase invalid"));
        setModalContent(t("Threre is no purchase to restore."));
      } else {
        setModalHead(
          <>
            <ErrorOutlineIcon
              style={{
                color: "#D81A26",
                verticalAlign: "middle",
                marginRight: "8px",
              }}
            />
            {t("Error")}
          </>
        );
        setModalContent(t("Cannot restore purchase. Try again."));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [email, loginInfo?.user_token, subscription, t, tokenType]);

  useEffect(() => {
    loadRestoreCode();
  }, [loadRestoreCode]);

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickPositive={onClickPositive}
      onClickNegative={onClickNegative}
      className={classes.root}
      heading={modalHead}
      close={true}
      fullSizeSub={mobile}
      content={
        <div>
          <div className={classes.mgB32}>
            <Typography category="Default" variant="Body">
              {modalContent}
            </Typography>
          </div>
        </div>
      }
      RButton={t("OK")}
      Secondary={false}
      loading={loading}
    />
  );
};
