import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { EventMap } from "../../components/maps/EventMap";
import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { useQuery } from "../../hooks/useQuery";
import mapboxgl from "mapbox-gl";
import { eventmapAxiosInst } from "../../utils";
import { Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    height: (props: ScreenDefaultProps) => {
      if (props.noHeader) {
        return "calc(var(--vh, 1vh) * 100)";
      }
      if (props.app) {
        return "calc(var(--vh, 1vh) * 100 - 56px)";
      }
      return props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px)"
        : "calc(var(--vh, 1vh) * 100 - 65px)";
    },
    marginTop: (props: ScreenDefaultProps) => {
      if (props.app) {
        return 0;
      }
      return props.error ? 127 : 65;
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) => {
        if (props.app) {
          return 0;
        }
        return props.error ? 85 : 56;
      },
    },
  },
}));

type EventmapScreenProps = ScreenDefaultProps & {
  hideTour: boolean;
};

export const EventmapExploreScreen = (
  props: EventmapScreenProps & PermissionProps
) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const query = useQuery();

  const [center, setCenter] = useState<mapboxgl.LngLatLike>();
  const [zoom, setZoom] = useState<number>();

  useEffect(() => {
    const getBounds = async (code: string) => {
      const resp = await eventmapAxiosInst.get(
        `/explore-events/region/${code}`
      );
      console.log("center", resp.data.center, "zoom", resp.data.zoom);
      setCenter(resp.data.center.coordinates);
      setZoom(resp.data.zoom);
    };
    const code = query.get("code");
    if (code) {
      getBounds(code);
    }
  }, [query]);

  return (
    <div className={classes.root} dir={theme.direction}>
      {center && zoom && (
        <EventMap explore initCenter={center} initZoom={zoom} />
      )}
    </div>
  );
};
