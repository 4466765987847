"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VolumeSlider = void 0;
var _styles = require("@material-ui/core/styles");
var _ProgressSlider = require("./ProgressSlider");
var _pittaModules = require("@thingsw/pitta-modules");
var VolumeSlider = (0, _styles.withStyles)(function (theme) {
  return {
    track: {
      color: _pittaModules.LightColors.primary["0"],
      height: 4
    },
    thumb: {
      color: _pittaModules.LightColors.primary["0"],
      marginTop: -4
    }
  };
})(_ProgressSlider.ProgressSlider);
exports.VolumeSlider = VolumeSlider;