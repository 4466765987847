import { Theme, withStyles } from "@material-ui/core/styles";
import { ProgressSlider } from "../ProgressSlider";
import { LightColors } from "@thingsw/pitta-modules";

export const VolumeSlider = withStyles((theme: Theme) => ({
  track: {
    color: LightColors.primary["0"],
    height: 4,
  },
  thumb: {
    color: LightColors.primary["0"],
    marginTop: -4,
  },
}))(ProgressSlider);
