import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Theme,
  useMediaQuery,
  useTheme,
  withStyles,
  Divider,
} from "@material-ui/core";
import {
  Button,
  Container,
  Modal,
  Sliders,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import _ from "lodash";
import { USER } from "../features/User/slice";

import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AddIcon from "@material-ui/icons/Add";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import LockIcon from "@material-ui/icons/Lock";
// import { ReactSVG } from "react-svg";

import { useHistory } from "react-router-dom";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { getFreetrialState, PAYMENT } from "../features/Payment/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { getPlanFromServiceID } from "../utils/Service";
import { MaintenanceHandler } from "./MaintenanceHandler";
import { LightColors, APPSTORE, GOOGLEPLAY } from "@thingsw/pitta-modules";
import * as Price from "@thingsw/pitta-modules";
import { LiveGPSModal } from "./modals/LiveGPSModal";
import { PushNotificationModal } from "./modals/PushNotificationModal";
import { VoiceCallModal } from "./modals/VoiceCallModal";
import { DrivingReportModal } from "./modals/DrivingReportModal";
import { GPSTrackingModal } from "./modals/GPSTrackingModal";
import { LiveEventUploadModal } from "./modals/LiveEventUploadModal";
import { SimDataPlanModal } from "./modals/SimDataPlanModal";
import { PriceDownModal } from "./modals/PriceDownModal";

import Lottie from "lottie-react";
import animationFreePlan from "../lottiefiles/pricing-freePlan.json";
import animationLitePlan from "../lottiefiles/pricing-litePlan.json";
import animationSmartPlan from "../lottiefiles/pricing-smartPlan.json";
import animationFleetPlan from "../lottiefiles/pricing-fleetPlan.json";
// import animationOnePlan from "../lottiefiles/pricing-onePlan.json";

// const breakpoint = 834;
const breakpoint = 944;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 56,
    [theme.breakpoints.up(breakpoint)]: {
      marginTop: 58,
    },
    backgroundColor: LightColors.primary["0"],
    background:
      "url(/images/pricing-bg-ellipse01.svg) no-repeat top -30vw center/contain, url(/images/pricing-bg-ellipse02.svg) no-repeat top 38% right, url(/images/pricing-bg-ellipse03.svg) no-repeat top 54% left,url(/images/pricing-bg-ellipse04.svg) no-repeat bottom -20vw center/contain",
    [theme.breakpoints.down("md")]: {
      background:
        "url(/images/pricing-bg-ellipse01.svg) no-repeat top -20vw center/contain, url(/images/pricing-bg-ellipse02.svg) no-repeat top 48% right, url(/images/pricing-bg-ellipse03.svg) no-repeat top 34% left,url(/images/pricing-bg-ellipse04.svg) no-repeat bottom -20vw center/contain",
    },
    [theme.breakpoints.down("sm")]: {
      background: "none",
    },
  },
  rootModal: {
    marginTop: 0,
    paddingTop: 54,
    width: "100%",
    height: "max-content",
  },
  rootModalMobile: {
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    marginTop: 54,
  },
  body: {
    padding: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(7.625)}px ${theme.spacing(4)}px`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 1354,
    "& .mobile-only": {
      display: "none",
      padding: "24px 16px 0",
      [theme.breakpoints.down(breakpoint)]: {
        display: "block",
      },
    },
    "& .pc-only": {
      display: "block",
      [theme.breakpoints.down(breakpoint)]: {
        display: "none",
      },
    },
    "& .helpBtn": {
      cursor: "pointer",
    },
  },
  modalBody: {
    padding: `${theme.spacing(3)}px 0`,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      padding: `0px 9px`,
    },
  },
  bodyText: {},
  mobileTitle: {
    color: LightColors.primary["1"],
  },
  marginT2: {
    marginTop: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginB26: {
    marginBottom: theme.spacing(3) + 2,
  },
  marginB5: {
    marginBottom: theme.spacing(5),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  pricingAcc: {
    display: "flex",
    width: "100%",
    marginTop: theme.spacing(5),
    flexDirection: "row",
    [theme.breakpoints.down(breakpoint)]: {
      flexDirection: "column",
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  modalPricingAcc: {
    marginTop: theme.spacing(4),
  },
  accordionRoot: {
    margin: 0,
    borderRadius: "16px !important",
    overflow: "hidden",
    "&.Mui-expanded": {
      marginTop: 0,
      "&:last-child": {
        marginRight: 0,
        marginBottom: 16,
        ...(theme.direction === "rtl" && { marginLeft: 0 }),
      },
    },
    "&:before": {
      height: 0,
    },
    [theme.breakpoints.down(breakpoint)]: {
      marginBottom: 8,
      border: `1px solid ${LightColors.primary["6"]}`,
      "&:last-child": {
        marginRight: 0,
        marginBottom: "8px !important",
        borderBottom: `1px solid ${LightColors.primary["6"]}`,
      },
    },
    [theme.breakpoints.up(breakpoint)]: {
      width: "33.33%",
      marginRight: theme.spacing(4),
      ...(theme.direction === "rtl" && {
        marginRight: 0,
        marginLeft: theme.spacing(4),
      }),
      boxShadow:
        "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
      display: "flex",
      flexDirection: "column",
      "& .MuiCollapse-container": {
        flex: 1,
        "& .MuiCollapse-wrapper": {
          height: "100%",
          "& .MuiCollapse-wrapperInner > div": {
            height: "100%",
          },
        },
      },
      "&.Mui-expanded": {
        marginRight: theme.spacing(4),
        ...(theme.direction === "rtl" && {
          marginRight: 0,
          marginLeft: theme.spacing(4),
        }),
      },
    },
    "& .MuiAccordionSummary-root": {
      alignItems: "start",
    },
    "& .MuiAccordionSummary-content": {
      "& .summaryTitle": {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 32,
        [theme.breakpoints.down(breakpoint)]: {
          paddingTop: 0,
        },
      },
      "& .bannerImg": {
        display: "none",
        position: "absolute",
        right: 32,
        width: 100,
        ...(theme.direction === "rtl" && {
          left: 32,
          right: "auto",
          transform: "scaleX(-1)",
        }),
      },
      "& .extra": {
        display: "none",
      },
      "& .mon": {
        display: "none",
      },
      "&.Mui-expanded": {
        "& .summaryTitle": {
          flexDirection: "column",
          alignItems: "start",
        },
        "& .bannerImg": {
          display: "flex",
        },
        "& .usd": {
          marginTop: 10,
        },
        "& .extra": {
          display: "block",
        },
        "& .mon": {
          display: "inline-block",
        },
      },
    },
    "&.litePlan": {
      "& .MuiAccordionSummary-root": {
        color: "#DF434D",
        "&.Mui-expanded": {
          borderLeft: "2px solid #DF434D",
          borderRight: "2px solid #DF434D",
          borderTop: "2px solid #DF434D",
        },
      },
      "&.Mui-expanded": {
        boxShadow:
          "0 0 0 1px #DF434D inset, 0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
        "& .summaryContent": {
          borderLeft: "2px solid #DF434D",
          borderRight: "2px solid #DF434D",
          borderBottom: "2px solid #DF434D",
        },
      },
      "& .primary": {
        color: "#DF434D",
      },
    },
    "&.smartPlan": {
      "& .MuiAccordionSummary-root": {
        color: "#ED9600",
        "&.Mui-expanded": {
          borderLeft: "2px solid #ED9600",
          borderRight: "2px solid #ED9600",
          borderTop: "2px solid #ED9600",
        },
      },
      "&.Mui-expanded": {
        boxShadow:
          "0 0 0 1px #ED9600 inset, 0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
        "& .summaryContent": {
          borderLeft: "2px solid #ED9600",
          borderRight: "2px solid #ED9600",
          borderBottom: "2px solid #ED9600",
        },
      },
      "& .primary": {
        color: "#ED9600",
      },
    },
    "&.fleetPlan": {
      "& .MuiAccordionSummary-root": {
        color: "#0095E0",
        backgroundColor: "#E7F5FC",
        "&.Mui-expanded": {
          borderLeft: "2px solid #0095E0",
          borderRight: "2px solid #0095E0",
          borderTop: "2px solid #0095E0",
        },
      },
      "&.Mui-expanded": {
        boxShadow:
          "0 0 0 1px #0095E0 inset, 0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
        "& .summaryContent": {
          borderLeft: "2px solid #0095E0",
          borderRight: "2px solid #0095E0",
          borderBottom: "2px solid #0095E0",
          backgroundColor: "#E7F5FC",
        },
      },
      "& .primary": {
        color: "#0095E0",
      },
    },
    "&.onePlan": {
      border: `1px solid ${LightColors.primary["7"]}`,
      "& .MuiAccordionSummary-root": {
        "&.Mui-expanded": {
          padding: "24px 16px",
          [theme.breakpoints.down(breakpoint)]: {
            border: `2px solid ${LightColors.primary["7"]}`,
            borderRadius: 16,
          },
        },
      },
      "& .MuiAccordionSummary-content .bannerImg": {
        right: 0,
        width: 230,
        backgroundColor: "#FFFFFF",
        paddingLeft: 30,
        [theme.breakpoints.down(breakpoint)]: {
          right: 50,
          width: 120,
        },
      },
    },
    "&.freePlan": {
      "& .MuiAccordionSummary-root": {
        "&.Mui-expanded": {
          padding: "24px 16px",
          [theme.breakpoints.down(breakpoint)]: {
            border: "2px solid #BEBEC1",
            borderRadius: 16,
          },
        },
      },
    },
    "&.onePlan, &.freePlan": {
      display: "flex",
      flexDirection: "column",
      width: "calc(100% - 32px)",
      backgroundPosition: "top 32px right 32px",
      backgroundSize: 82,
      padding: "24px 16px",
      "& .summaryDetail": {
        display: "none",
      },
      "&.Mui-expanded": {
        backgroundColor: LightColors.primary["0"],
        "& .summaryDetail": {
          display: "flex",
        },
        [theme.breakpoints.down(breakpoint)]: {
          padding: 0,
        },
      },
      [theme.breakpoints.up(breakpoint)]: {
        width: "100%",
        margin: "48px 0 0",
        padding: 32,
      },
      "& .MuiAccordionSummary-root": {
        display: "flex",
        padding: 0,
        "& .MuiAccordionSummary-content": {
          flexDirection: "column",
          "& .summary": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          },
          "&.Mui-expanded .summary": {
            flexDirection: "column",
            "& .headerTitle": {
              marginBottom: 16,
              lineHeight: "2.5rem !important",
            },
          },
        },
      },
      "& .MuiAccordionDetails-root": {
        "& .summaryDetail": {
          display: "flex",
          marginBottom: 8,
        },
        "& .features": {
          display: "flex",
          flexDirection: "row",
          [theme.breakpoints.down(breakpoint)]: {
            flexDirection: "column",
          },
        },
      },
    },
  },
  accordionSummaryRoot: {
    padding: "24px 16px",
    minHeight: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    "&.Mui-expanded": {
      padding: "23px 16px 16px 16px",
      minHeight: 0,
    },
    [theme.breakpoints.up(breakpoint)]: {
      padding: "8px 24px",
      "&.Mui-expanded": {
        padding: "8px 24px",
      },
    },
  },
  accordionSummaryContent: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryexpandIcon: {
    padding: 3,
    paddingRight: 12,
    "&.Mui-expanded": {
      padding: 3,
      paddingLeft: 12,
    },
  },
  accordionDetailsRoot: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    height: "100%",
    "& .summaryContent": {
      padding: "0 16px 16px 16px",
      display: "flex",
      flexDirection: "column",
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      [theme.breakpoints.up(breakpoint)]: {
        padding: "0 24px 24px 24px",
      },
    },
  },
  featureDiv: {
    marginTop: 4,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.up(breakpoint)]: {
      padding: "12px 24px",
    },
    "& .featureHead": {
      textIndent: 8,
      marginBottom: 4,
    },
    "& .featureWrap": {
      padding: 16,
      border: "1px solid #E9E9EA",
      borderRadius: 10,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(breakpoint)]: {
        padding: 8,
      },
      "& p": {
        margin: 0,
        flex: 1,
      },
    },
  },
  feature: {
    display: "flex",
    marginBottom: 16,
    "& svg": {
      verticalAlign: "middle",
      marginRight: theme.spacing(1),
      ...(theme.direction === "rtl" && { marginLeft: theme.spacing(1) }),
    },
  },
  featureTline: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  startBtn: {
    textAlign: "center",
    [theme.breakpoints.down(breakpoint)]: {
      padding: "0 16px",
      marginBottom: 16,
    },
  },
  gotoShotBtn: {
    minWidth: 200,
    maxWidth: "100%",
    color: "#0095E0",
    backgroundColor: "#D0EBF9",
    minHeight: 48,
    lineHeight: "1.2",
    wordBreak: "keep-all",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  featureIcon: {
    marginRight: theme.spacing(1),
  },
  appAnchor: { display: "flex" },
  appStoreDiv: {
    display: "flex",
    justifyContent: "center",
    "&.flexCol": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  compareDiv: {
    width: "100%",
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up(breakpoint)]: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(0),
    },
  },
  tableRoot: {
    borderRadius: 16,
    border: `1px solid ${LightColors.primary["6"]}`,
    borderCollapse: "separate",
    backgroundColor: LightColors.primary["0"],
  },
  compareTline: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  compareTitleTline: {
    verticalAlign: "top",
  },
  compareFeatureTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  compareCheckDiv: {
    display: "flex",
    justifyContent: "center",
  },
  compareCheckIcon: {
    display: "block",
  },
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down(breakpoint)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    color: LightColors.primary["7"],
  },
  infoIcon: {
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },
  calcDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up(breakpoint)]: {
      flexDirection: "row",
    },
  },
  calculator: {
    flex: 1.43,
    padding: theme.spacing(3, 2),
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: theme.spacing(2, 2, 0, 0),
    backgroundColor: LightColors.primary["0"],
    [theme.breakpoints.up(breakpoint)]: {
      padding: theme.spacing(13.25, 8),
      borderRadius: theme.spacing(2, 0, 0, 2),
    },
  },
  camNumDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(breakpoint)]: {
      flexDirection: "row",
      marginBottom: theme.spacing(4),
    },
  },
  camNumTitle: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(breakpoint)]: {
      marginBottom: theme.spacing(0),
    },
  },
  camNumInput: {
    [theme.breakpoints.up(breakpoint)]: {
      minWidth: 173,
      maxWidth: 173,
    },
  },
  calcResultDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: LightColors.primary["10"],
    border: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(3, 2),
    borderRadius: theme.spacing(0, 0, 2, 2),
    [theme.breakpoints.up(breakpoint)]: {
      padding: theme.spacing(4, 8),
      borderRadius: theme.spacing(0, 2, 2, 0),
    },
  },
  calcArrowDiv: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    justifyContent: "flex-start",
    border: `1px solid ${LightColors.primary["7"]}`,
    borderRadius: 8,
    backgroundColor: LightColors.primary["10"],
    color: LightColors.primary["7"],
    padding: "4px 16px",
    marginTop: theme.spacing(3),
    "&:hover": {
      color: LightColors.primary["8"],
    },
    cursor: "pointer",
  },
  modal: {
    marginTop: theme.spacing(4),
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
}));

const TableCell = withStyles((theme: Theme) => ({
  body: {
    border: 0,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    borderRight: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      backgroundColor: LightColors.primary["10"],
    },
  },
  head: {
    padding: theme.spacing(2),
    paddingBottom: 13,
    border: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    "&:first-child > td": {
      paddingTop: theme.spacing(3),
    },
    "&:last-child > td": {
      paddingBottom: theme.spacing(3),
    },
  },
}))(MuiTableRow);

interface PricingProps {
  modal?: boolean;
  onClose: () => void;
}

export const Pricing = ({ modal, onClose }: PricingProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state: RootState) => state[USER]);
  const calcDivRef = useRef<HTMLDivElement>(null);

  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const mobile = useMediaQuery(theme.breakpoints.down(breakpoint));
  const category = matches ? "Large" : "Default";

  const [expanded, setExpanded] = React.useState<string | false>("all");
  const [camCnt, setCamCnt] = useState<number>(1);
  const [planName, setPlanName] = useState<string>();
  const [maintenancing, setMaintenancing] = useState(false);
  const [showMaintModal, setShowMaintModal] = useState(false);

  const { subscriptionInfo, freetrial } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);
  const [openExceptionModal, setOpenExceptionModal] = useState(false);
  const [openLiveGPSModal, setOpenLiveGPSModal] = useState(false);
  const [openPushNotificationModal, setOpenPushNotificationModal] = useState(
    false
  );
  const [openVoiceCallModal, setOpenVoiceCallModal] = useState(false);
  const [openDrivingReportModal, setOpenDrivingReportModal] = useState(false);
  const [openGPSTrackingModal, setOpenGPSTrackingModal] = useState(false);
  const [openLiveEventUploadModal, setOpenLiveEventUploadModal] = useState(
    false
  );
  const [openSimDataPlanModal, setOpenSimDataPlanModal] = useState(false);
  const [openPriceDownModal, setOpenPriceDownModal] = useState(false);

  useEffect(() => {
    if (mobile) {
      setExpanded("free");
    } else {
      setExpanded("all");
    }
  }, [mobile]);

  useEffect(() => {
    if (loginInfo) dispatch(getFreetrialState());
  }, [dispatch, loginInfo]);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFleetBtn = useCallback(() => {
    if (maintenancing) {
      setShowMaintModal(true);
      return;
    }
    if (
      modal &&
      (planName === "Smart plan" ||
        planName === "Lite plan" ||
        planName === "Basic plan")
    ) {
      setOpenExceptionModal(true);
    } else if (modal) {
      onClose();
      history.push("/billing");
    } else {
      history.push("/signup", { mode: "fleet" });
    }
  }, [history, maintenancing, modal, onClose, planName]);

  const handleMaintenance = useCallback(() => {
    setMaintenancing(true);
  }, []);

  const maintHandler = useMemo(() => {
    return (
      <MaintenanceHandler
        disableModal
        forceShow={showMaintModal}
        onCloseModal={() => {
          console.log("onCloseModal");
          setShowMaintModal(false);
        }}
        onMaintenance={handleMaintenance}
      />
    );
  }, [handleMaintenance, showMaintModal]);

  const handleTrial = useCallback(
    (mode: string, cameraCnt?: number) => {
      if (maintenancing) {
        setShowMaintModal(true);
      } else {
        history.push("/signup", { mode, cameraCnt });
      }
    },
    [history, maintenancing]
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootModal]: modal,
        [classes.rootModalMobile]: modal && mobile,
      })}
      dir={theme.direction}
    >
      <Container className={clsx(classes.body, modal && classes.modalBody)}>
        <div className={classes.titleDiv}>
          <Typography
            category={modal ? "Default" : category}
            variant="H1"
            className={clsx(
              classes.bodyText,
              modal && classes.marginB2,
              !mobile && classes.marginB26,
              mobile && classes.marginB2,
              mobile && classes.mobileTitle
            )}
            style={modal || mobile ? { textAlign: "center" } : {}}
          >
            {t("Pricing")}
          </Typography>

          {modal ? (
            <>
              <Typography
                category="Default"
                variant="BodyBold"
                className={classes.bodyText}
                style={modal ? { textAlign: "center" } : {}}
              >
                {/* {subscriptionInfo &&
                  getPlanFromServiceID(subscriptionInfo?.servicePlanID) ===
                  "Smart plan" &&
                  t("You are subscribed_free_Smart")} */}
                {subscriptionInfo &&
                  getPlanFromServiceID(subscriptionInfo?.servicePlanID) ===
                    "Free plan" &&
                  t("You are subscribed_free")}
                {/* {t("You are subscribed_free")} */}
              </Typography>
              <Typography
                category="Default"
                variant="Body"
                className={classes.bodyText}
                style={modal ? { textAlign: "center" } : {}}
              >
                {t("With Alert Push_")}
              </Typography>
            </>
          ) : (
            <Typography
              category="Default"
              variant="Body"
              className={classes.bodyText}
              style={mobile ? { display: "none" } : {}}
              dangerouslySetInnerHTML={{
                __html: t("Get started now_"),
              }}
            />
          )}
          {!modal && (
            <div
              className={classes.calcArrowDiv}
              style={
                mobile
                  ? {
                      border: "none",
                      backgroundColor: "initial",
                      margin: "24px auto 0",
                    }
                  : {}
              }
              onClick={() => {
                //@ts-ignore
                window.location = "/pricing#calculator";
                if (calcDivRef.current) {
                  window.scrollTo(0, calcDivRef.current.offsetTop - 70);
                }
              }}
            >
              <Typography category="Default" variant="BodyBold">
                {t("Calculate your Fleet_")}
              </Typography>
              <ArrowDownwardIcon />
            </div>
          )}
        </div>

        <div className={clsx(classes.pricingAcc, { [classes.modal]: modal })}>
          {/* Lite Plan start */}
          <Accordion
            classes={{
              root: clsx(classes.accordionRoot, "litePlan"),
            }}
            expanded={_.includes(["all", "lite"], expanded)}
            elevation={0}
            square={mobile}
            onChange={mobile ? handleChange("lite") : undefined}
          >
            <AccordionSummary
              classes={{
                root: clsx(classes.accordionSummaryRoot),
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryexpandIcon,
              }}
              expandIcon={mobile && <ExpandMoreIcon />}
              aria-controls="lite-content"
              id="lite-header"
            >
              <div className="summaryTitle">
                <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                  {t("Lite")}
                </Typography>
                <div className="usd">
                  <Typography
                    category="Default"
                    variant={mobile ? "H2" : "H1"}
                    htmlColor="#DF434D"
                  >
                    {Price.LITE} USD
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["1"]}
                    className="mon"
                  >
                    /mo
                  </Typography>
                </div>
                <Typography
                  category="Default"
                  variant="Small"
                  className="extra"
                >
                  {t("+N USD for extra camera_", { a: 3, b: 15 })}
                </Typography>
              </div>
              <Lottie className="bannerImg" animationData={animationLitePlan} />
            </AccordionSummary>

            <AccordionDetails
              classes={{
                root: clsx(classes.accordionDetailsRoot),
              }}
            >
              <div className="summaryContent">
                <Typography
                  category="Default"
                  variant="Small"
                  style={{ marginTop: 24 }}
                >
                  {t("Get Unlimited Cloud_", { a: 15 })}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  style={{ marginTop: 12, textIndent: 8 }}
                  htmlColor="#DF434D"
                  dangerouslySetInnerHTML={{
                    __html: `*${t("P subscription only", { a: "In-app" })}`,
                  }}
                />
              </div>

              <div className={classes.featureDiv}>
                <div className="pc-only">
                  <div className={classes.feature}>
                    <Typography
                      category="Default"
                      variant="Body"
                      dangerouslySetInnerHTML={{
                        __html: t("All the P Plan_", { a: "Free" }),
                      }}
                    ></Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Unlimited remote video_")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("More Cloud Storage")} ({t("GB", { a: 5 })}{" "}
                      {t("per Camera")})
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Live View")} ({t("N Minutes per Day", { a: 30 })})
                    </Typography>
                  </div>
                </div>
                <div className="mobile-only">
                  <div className="featureHead">
                    <Typography category="Default" variant="Small">
                      {t("Essentials features")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Camera Registration")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="SmallBold">
                          {t("Up to N Cameras_", { a: 15, b: 3 })}
                        </Typography>
                      </p>
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live view")}
                        </Typography>
                        <br />
                        <Typography
                          category="Default"
                          variant="Small"
                          htmlColor="#68686E"
                        >
                          {t("N Minutes per Day", { a: 30 })}
                        </Typography>
                      </p>
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AllInclusiveIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Remote Video Playback_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("Unlimited")}
                        </Typography>
                      </p>
                    </div>
                  </div>
                  <div className="featureHead">
                    <Typography category="Default" variant="Small">
                      {t("BlackVue Cloud")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("More Cloud Storage")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("GB", { a: 5 })} {t("per Camera")}
                        </Typography>
                      </p>
                    </div>
                    <div className={classes.feature}>
                      <LockIcon color="disabled" />
                      <p style={{ color: "#68686E" }}>
                        <Typography category="Default" variant="Body">
                          {t("GPS tracking")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("Not available with this plan")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenGPSTrackingModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live event upload")}
                        </Typography>
                        <br />
                        <Typography
                          category="Default"
                          variant="Small"
                          htmlColor="#68686E"
                        >
                          {t("N days", { a: 7 })}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveEventUploadModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live GPS")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveGPSModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Push Notifications")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenPushNotificationModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Driving Reports")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenDrivingReportModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Voice Call")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenVoiceCallModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  classes.appStoreDiv,
                  classes.marginT2,
                  classes.marginB2
                )}
              >
                <a
                  href={APPSTORE}
                  className={classes.appAnchor}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: theme.spacing(2) }}
                >
                  <img
                    srcSet="/images/appstore-badge.png 1x, /images/appstore-badge@2x.png 2x"
                    alt="download on the app store"
                  />
                </a>
                <a
                  href={GOOGLEPLAY}
                  className={classes.appAnchor}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    srcSet="/images/google-play-badge.png 1x, /images/google-play-badge@2x.png 2x"
                    alt="get it on google play"
                  />
                </a>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Smart Plan start */}
          <Accordion
            classes={{
              root: clsx(classes.accordionRoot, "smartPlan"),
            }}
            elevation={0}
            expanded={_.includes(["all", "smart"], expanded)}
            square={mobile}
            onChange={mobile ? handleChange("smart") : undefined}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryexpandIcon,
              }}
              expandIcon={mobile && <ExpandMoreIcon />}
              aria-controls="smart-content"
              id="smart-header"
            >
              <div className="summaryTitle">
                <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                  {t("Smart")}
                </Typography>
                <div className="usd">
                  <Typography
                    category="Default"
                    variant={mobile ? "H2" : "H1"}
                    htmlColor="#ED9600"
                  >
                    {Price.SMART} USD
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["1"]}
                    className="mon"
                  >
                    /mo
                  </Typography>
                </div>
                <Typography
                  category="Default"
                  variant="Small"
                  className="extra"
                >
                  {/* +{Price.SMART_EXTRA} USD for extra camera */}
                  {t("+N USD for extra camera_", { a: 6, b: 15 })}
                </Typography>
              </div>
              <Lottie
                className="bannerImg"
                animationData={animationSmartPlan}
              />
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
              <div className="summaryContent">
                <Typography
                  category="Default"
                  variant="Small"
                  style={{ marginTop: 24 }}
                >
                  {t("Get Unlimited Remote_", { a: 15 })}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  style={{ marginTop: 12, textIndent: 8 }}
                  htmlColor="#ED9600"
                  dangerouslySetInnerHTML={{
                    __html: `*${t("P subscription only", { a: "In-app" })}`,
                  }}
                />
              </div>

              <div className={classes.featureDiv}>
                <div className="pc-only">
                  <div className={classes.feature}>
                    <Typography
                      category="Default"
                      variant="Body"
                      dangerouslySetInnerHTML={{
                        __html: t("All the P Plan_", { a: "Lite" }),
                      }}
                    ></Typography>
                  </div>

                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Unlimited Live view")}
                    </Typography>
                  </div>

                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("More Cloud Storage")} ({t("GB", { a: 10 })}{" "}
                      {t("per Camera")})
                    </Typography>
                  </div>

                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("GPS tracking")}
                    </Typography>
                  </div>

                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Video On Map")}
                    </Typography>
                  </div>
                </div>
                <div className="mobile-only">
                  <div className="featureHead">
                    <Typography category="Default" variant="Small">
                      {t("Essentials features")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Camera Registration")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="SmallBold">
                          {t("Up to N Cameras_", { a: 15, b: 6 })}
                        </Typography>
                      </p>
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AllInclusiveIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live view")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("Unlimited")}
                        </Typography>
                      </p>
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AllInclusiveIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Remote Video Playback_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("Unlimited")}
                        </Typography>
                      </p>
                    </div>
                  </div>
                  <div className="featureHead">
                    <Typography category="Default" variant="Small">
                      {t("BlackVue Cloud")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("More Cloud Storage")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("GB", { a: 10 })} {t("per Camera")}
                        </Typography>
                      </p>
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("GPS tracking")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("N days", { a: 90 })}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenGPSTrackingModal(true);
                        }}
                      />
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live event upload")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("N days", { a: 30 })}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveEventUploadModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live GPS")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveGPSModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Push Notifications")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenPushNotificationModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Driving Reports")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenDrivingReportModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Voice Call")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenVoiceCallModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.feature}>
                    <Typography category="Default" variant="Small">
                      {t("Pro Features")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div
                      className={classes.feature}
                      style={{ marginBottom: 0 }}
                    >
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Video On Map")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenGPSTrackingModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  classes.appStoreDiv,
                  classes.marginT2,
                  classes.marginB2
                )}
              >
                <a
                  href={APPSTORE}
                  className={classes.appAnchor}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: theme.spacing(2) }}
                >
                  <img
                    srcSet="/images/appstore-badge.png 1x, /images/appstore-badge@2x.png 2x"
                    alt="download on the app store"
                  />
                </a>
                <a
                  href={GOOGLEPLAY}
                  className={classes.appAnchor}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    srcSet="/images/google-play-badge.png 1x, /images/google-play-badge@2x.png 2x"
                    alt="get it on google play"
                  />
                </a>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Fleet Plan start */}
          <Accordion
            classes={{
              root: clsx(classes.accordionRoot, "fleetPlan"),
            }}
            expanded={_.includes(["all", "fleet"], expanded)}
            elevation={0}
            square={mobile}
            onChange={mobile ? handleChange("fleet") : undefined}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryexpandIcon,
              }}
              expandIcon={mobile && <ExpandMoreIcon />}
              aria-controls="fleet-content"
              id="fleet-header"
            >
              <div className="summaryTitle">
                <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                  {t("Fleet")}
                </Typography>
                <div className="usd">
                  <Typography
                    category="Default"
                    variant={mobile ? "H2" : "H1"}
                    htmlColor="#0095E0"
                  >
                    {Price.FLEET_PRICE} USD
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["1"]}
                    className="mon"
                  >
                    /mo
                  </Typography>
                </div>
                <Typography
                  category="Default"
                  variant="Small"
                  className="extra"
                >
                  {/* +{Price.FLEET_EXTRA} USD for extra camera */}
                  {t("+N USD for extra camera_", { a: 12, b: 999 })}
                </Typography>
              </div>
              <Lottie
                className="bannerImg"
                animationData={animationFleetPlan}
              />
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
              <div className="summaryContent">
                <Typography
                  category="Default"
                  variant="Small"
                  style={{ marginTop: 24 }}
                >
                  {t("BlackVue Fleet Tracking_")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  style={{ marginTop: 12, textIndent: 8 }}
                  htmlColor="#0095E0"
                  dangerouslySetInnerHTML={{
                    __html: `*${t("P subscription only", { a: "Web" })}`,
                  }}
                />
              </div>

              <div
                className={classes.featureDiv}
                style={{ justifyContent: "space-between" }}
              >
                <div className="pc-only">
                  <div className={classes.feature}>
                    <Typography
                      category="Default"
                      variant="Body"
                      dangerouslySetInnerHTML={{
                        __html: t("All the P Plan_", { a: "Smart" }),
                      }}
                    ></Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("User Roles and Permissions")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Geofence Setup (Web)_")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Multi Live view_")}
                    </Typography>
                  </div>
                </div>
                <div className="mobile-only">
                  <div className="featureHead">
                    <Typography category="Default" variant="Small">
                      {t("Essentials features")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Camera Registration")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="SmallBold">
                          {t("Up to N Cameras_", { a: 999, b: 12 })}
                        </Typography>
                      </p>
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AllInclusiveIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live view")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("Unlimited")}
                        </Typography>
                      </p>
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AllInclusiveIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Remote Video Playback_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("Unlimited")}
                        </Typography>
                      </p>
                    </div>
                  </div>
                  <div className="featureHead">
                    <Typography category="Default" variant="Small">
                      {t("BlackVue Cloud")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("More Cloud Storage")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("GB", { a: 15 })} {t("per Camera")}
                        </Typography>
                      </p>
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("GPS tracking")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("N days", { a: 90 })}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenGPSTrackingModal(true);
                        }}
                      />
                    </div>
                    <div className={clsx(classes.feature, "primary")}>
                      <AddIcon />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live event upload")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="Small">
                          {t("N days", { a: 30 })}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveEventUploadModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Live GPS")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveGPSModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Push Notifications")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenPushNotificationModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Driving Reports")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenDrivingReportModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Voice Call")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenVoiceCallModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.feature}>
                    <Typography category="Default" variant="Small">
                      {t("Pro Features")}
                    </Typography>
                  </div>
                  <div className="featureWrap">
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("User Roles and Permissions")}
                        </Typography>
                      </p>
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Geofence Setup (Web)_")}
                        </Typography>
                      </p>
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Video On Map")}
                        </Typography>
                      </p>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenGPSTrackingModal(true);
                        }}
                      />
                    </div>
                    <div className={classes.feature}>
                      <CheckIcon
                        color="primary"
                        className={classes.featureIcon}
                      />
                      <p>
                        <Typography category="Default" variant="Body">
                          {t("Multi Live view_")}
                        </Typography>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.startBtn}>
                  <Button
                    fullWidth
                    color="primary"
                    style={{ padding: 16 }}
                    onClick={handleFleetBtn}
                  >
                    {(subscriptionInfo?.servicePlanID === 1 ||
                      planName === "Smart plan" ||
                      planName === "Lite plan" ||
                      planName === "Basic plan") &&
                    freetrial?.trialUsed
                      ? t("subscribe")
                      : t("Try 1 Month Free")}
                  </Button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {/* One Plan 내용 삭제 (hongcs, 24.08.02) */}
        {/* One Plan start */}
        {/* <Accordion
          classes={{
            root: clsx(classes.accordionRoot, "onePlan"),
          }}
          elevation={0}
          square={mobile}
          expanded={_.includes(["all", "one"], expanded)}
          onChange={mobile ? handleChange("one") : undefined}
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummaryRoot,
              content: classes.accordionSummaryContent,
              expandIcon: classes.accordionSummaryexpandIcon,
            }}
            expandIcon={mobile && <ExpandMoreIcon />}
            aria-controls="one-content"
            id="one-header"
            style={mobile ? {} : { padding: 0 }}
          >
            <Lottie
              className="bannerImg"
              animationData={animationOnePlan}
            />
            <div
              className="summary"
              style={mobile ? {} : { flexDirection: "column" }}
            >
              <Typography
                category="Default"
                variant={!(_.includes(["all", "one"], expanded)) ? "H3" : "H1"}
                className="headerTitle"
                htmlColor={LightColors.primary["7"]}
                dangerouslySetInnerHTML={{
                  __html:
                    mobile
                      ? !(_.includes(["all", "one"], expanded))
                        ? t("One")
                        : ("BlackVue<br/>One")
                      : t("BlackVue One")
                }}
              />
              <div
                className="summaryDetail"
              >
                <Typography
                  category="Default"
                  variant="Small"
                >
                  {t("Purchase the BlackVue One Package_")}
                </Typography>              
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>            
            <div className="pc-only">
              <Divider className={classes.divider} />
              <Typography
                category="Default"
                variant="Body"
                dangerouslySetInnerHTML={{ __html: t("All the P Plan_", { a: "Lite" }) }}
              ></Typography>
              <div className="features">
                <div
                  className={classes.featureDiv}
                  style={mobile ? { padding: 0 } : { flex: 1 }}
                >
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <Typography category="Default" variant="Body">
                      {t("3GB SIM Data Plan")}
                    </Typography>
                    <HelpOutlineIcon
                      className="helpBtn"
                      style={{ marginLeft: 8 }}
                      onClick={() => {
                        setOpenSimDataPlanModal(true);
                      }}
                    />
                  </div>                  
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <Typography category="Default" variant="Body">
                      {t("Up to N Cameras", { a: 3 })}
                    </Typography>                    
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <Typography category="Default" variant="Body">
                      {t("Unlimited Live View, Playback and Download")} *
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <Typography category="Default" variant="Body">
                      {t("More Cloud Storage")} ({t("GB", { a: 10 })} {t("per Camera")})
                    </Typography>
                  </div>
                </div>
                <div
                  className={classes.featureDiv}
                  style={mobile ? { padding: 0 } : { flex: 1 }}
                >
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Live event upload")}
                      </Typography>                      
                    </div>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <Typography category="Default" variant="Body">
                      {t("GPS Tracking")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <Typography category="Default" variant="Body">
                      {t("Video On Map")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <Typography category="Default" variant="Small" htmlColor="#68686E">
                      (*) {t("within data availability")}
                    </Typography>
                  </div>
                </div>
              </div>
              <Divider className={classes.divider} />
              <div style={{ display: "flex", }}>
                <ul style={{ flex: 3 }}>
                  <li>
                    <Typography
                      category="Default"
                      variant="Small"
                    >
                      {t("The BlackVue One Plan is a 1-year_")}
                      <br />
                      {t("The BlackVue One Package is available_")}
                    </Typography>
                  </li>
                </ul>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <Button 
                    className={classes.gotoShotBtn}
                    onClick={() => window.open("https://shop.blackvue.com/product/blackvue-one/", "_blank")}
                    endIcon={<ReactSVG src="/images/link-blank.svg" />}
                  >
                    {t("Go to BlackVue Shop")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="mobile-only">
              <div
                className={classes.featureDiv}
                style={mobile ? { padding: 0 } : { flex: 1 }}
              >
                <div className="featureHead">
                  <Typography category="Default" variant="Small">
                    {t("One Plan Exclusive")}
                  </Typography>
                </div>
                <div className="featureWrap">
                  <div className={classes.feature} style={{ marginBottom: 0 }}>
                    <AddIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body" htmlColor={LightColors.primary["7"]}>
                        {t("3GB SIM Data Plan")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="SmallBold" htmlColor={LightColors.primary["7"]}>
                        {t("Balanced Plan")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      style={{ marginLeft: 8 }}
                      onClick={() => {
                        setOpenSimDataPlanModal(true);
                      }}
                    />
                  </div>
                </div>
                <div className="featureHead">
                  <Typography category="Default" variant="Small">
                    {t("Essentials features")}
                  </Typography>
                </div>
                <div className="featureWrap">
                  <div className={classes.feature}>
                    <AddIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body" htmlColor={LightColors.primary["7"]}>
                        {t("Camera Registration")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="SmallBold" htmlColor={LightColors.primary["7"]}>
                        {t("1 camera")}
                      </Typography>
                    </p>
                  </div>
                  <div className={classes.feature}>
                    <AllInclusiveIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body" htmlColor={LightColors.primary["7"]}>
                        {t("Live view")} *
                      </Typography>
                      <br />
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["7"]}
                      >
                        {t("Unlimited")}
                      </Typography>
                    </p>
                  </div>
                  <div className={classes.feature}>
                    <AllInclusiveIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body" htmlColor={LightColors.primary["7"]}>
                        {t("Unlimited remote video_")} *
                      </Typography>
                      <br />
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["7"]}
                      >
                        {t("Unlimited")}
                      </Typography>
                    </p>
                  </div>
                  <div>
                    <Typography category="Default" variant="Body" htmlColor={LightColors.primary["3"]}>
                      (*) {t("within data availability")}
                    </Typography>
                  </div>
                </div>

                <div className="featureHead">
                  <Typography category="Default" variant="Small">
                    {t("BlackVue Cloud")}
                  </Typography>
                </div>
                <div className="featureWrap">
                  <div className={classes.feature}>
                    <AddIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body" htmlColor={LightColors.primary["7"]}>
                        {t("More Cloud Storage")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="SmallBold" htmlColor={LightColors.primary["7"]}>
                        {t("GB", { a: 10 })} {t("per Camera")}
                      </Typography>
                    </p>
                  </div>
                  <div className={classes.feature}>
                    <AddIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body" htmlColor={LightColors.primary["7"]}>
                        {t("GPS tracking")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="SmallBold" htmlColor={LightColors.primary["7"]}>
                        {t("N days", { a: 90 })}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenGPSTrackingModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <AddIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body" htmlColor={LightColors.primary["7"]}>
                        {t("Live event upload")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="SmallBold" htmlColor={LightColors.primary["7"]}>
                        {t("N days", { a: 30 })}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenLiveEventUploadModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Live GPS")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenLiveGPSModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Push Notifications")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenPushNotificationModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Driving Reports")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenDrivingReportModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon color="primary" className={classes.featureIcon} />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Voice Call")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenVoiceCallModal(true);
                      }}
                    />
                  </div>
                </div>
                <div className="featureHead">
                  <Typography category="Default" variant="Small">
                    {t("Pro Features")}
                  </Typography>
                </div>
                <div className="featureWrap">
                  <div className={classes.feature} style={{ marginBottom: 0 }}>
                    <p>
                      <CheckIcon color="primary" className={classes.featureIcon} />
                      <Typography category="Default" variant="Body">
                        {t("Video On Map")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenGPSTrackingModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>              
            </div>
          </AccordionDetails>
        </Accordion> */}

        {/* Free Plan start */}
        <Accordion
          classes={{
            root: clsx(classes.accordionRoot, "freePlan"),
          }}
          expanded={_.includes(["all", "free"], expanded)}
          elevation={0}
          square={mobile}
          onChange={mobile ? handleChange("free") : undefined}
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummaryRoot,
              content: classes.accordionSummaryContent,
              expandIcon: classes.accordionSummaryexpandIcon,
            }}
            expandIcon={mobile && <ExpandMoreIcon />}
            aria-controls="free-content"
            id="free-header"
            style={mobile ? {} : { padding: 0 }}
          >
            <div
              className="summary"
              style={mobile ? {} : { flexDirection: "row" }}
            >
              <Typography
                category="Default"
                variant="H2"
                className="headerTitle"
                style={mobile ? { color: "#68686E" } : { flex: 1 }}
              >
                {t("Free")}
              </Typography>
              <Typography
                category="Default"
                variant="H2"
                style={mobile ? { color: "#68686E" } : { flex: 1.8 }}
              >
                0 USD
              </Typography>
            </div>
            <div
              className="summaryDetail"
              style={
                mobile ? { flexDirection: "column-reverse" } : { width: "100%" }
              }
            >
              <Typography
                category="Default"
                variant="Small"
                style={{ flex: 1 }}
              >
                {t("Get started for_")}
              </Typography>
              <Typography
                category="Default"
                variant="Small"
                style={{ flex: 1.8, marginBottom: 16 }}
              >
                {t("Free forever")}
              </Typography>
            </div>
            <Lottie className="bannerImg" animationData={animationFreePlan} />
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <div className="pc-only">
              <Divider className={classes.divider} />
              <Typography category="Default" variant="Body">
                {t("Features included:")}
              </Typography>
              <div className="features">
                <div
                  className={classes.featureDiv}
                  style={mobile ? { padding: 0 } : { flex: 1 }}
                >
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Camera Registration")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Live view")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Remote Video Playback_")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Cloud storage")}
                    </Typography>
                  </div>
                </div>
                <div
                  className={classes.featureDiv}
                  style={mobile ? { padding: 0 } : { flex: 1 }}
                >
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Push Notifications")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Voice Call")}
                    </Typography>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Live GPS")}
                    </Typography>
                  </div>
                </div>
                <div
                  className={classes.featureDiv}
                  style={mobile ? { padding: 0 } : { flex: 1 }}
                >
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Live event upload") + " *"}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                          setOpenLiveEventUploadModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <Typography category="Default" variant="Body">
                      {t("Driving Reports") + " *"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <Typography category="Default" variant="Caption">
                      <em>
                        {"* : " + t("Not supported by DR650GW and DR650S_")}
                      </em>
                      <br />
                      <em>{"** : " + t("DR650S Series only_")}</em>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-only">
              <div
                className={classes.featureDiv}
                style={mobile ? { padding: 0 } : { flex: 1 }}
              >
                <div className="featureHead">
                  <Typography category="Default" variant="Small">
                    {t("Essentials features")}
                  </Typography>
                </div>
                <div className="featureWrap">
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Camera Registration")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="SmallBold">
                        {t("1 camera")}
                      </Typography>
                    </p>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Live view")}
                      </Typography>
                      <br />
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor="#68686E"
                      >
                        {t("N Minutes per Day", { a: 10 })}
                      </Typography>
                    </p>
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Remote Video Playback_2")}
                      </Typography>
                      <br />
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor="#68686E"
                      >
                        {t("100 times per month")}
                      </Typography>
                    </p>
                  </div>
                </div>
                <div className="featureHead">
                  <Typography category="Default" variant="Small">
                    {t("BlackVue Cloud ")}
                  </Typography>
                </div>
                <div className="featureWrap">
                  <div className={clsx(classes.feature, "primary")}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Cloud Storage")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="Small">
                        {t("GB", { a: 5 })}
                      </Typography>
                    </p>
                  </div>
                  <div className={classes.feature}>
                    <LockIcon color="disabled" />
                    <p style={{ color: "#68686E" }}>
                      <Typography category="Default" variant="Body">
                        {t("GPS tracking")}
                      </Typography>
                      <br />
                      <Typography category="Default" variant="Small">
                        {t("Not available with this plan")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenGPSTrackingModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Live event upload")}
                      </Typography>
                      <br />
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor="#68686E"
                      >
                        {t("N days", { a: 2 })}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenLiveEventUploadModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Live GPS")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenLiveGPSModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Push Notifications")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenPushNotificationModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Driving Reports")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenDrivingReportModal(true);
                      }}
                    />
                  </div>
                  <div className={classes.feature}>
                    <CheckIcon
                      color="primary"
                      className={classes.featureIcon}
                    />
                    <p>
                      <Typography category="Default" variant="Body">
                        {t("Voice Call")}
                      </Typography>
                    </p>
                    <HelpOutlineIcon
                      className="helpBtn"
                      onClick={() => {
                        setOpenVoiceCallModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.startBtn}>
              {modal ? (
                subscriptionInfo &&
                getPlanFromServiceID(subscriptionInfo?.servicePlanID) ===
                  "Free plan" && (
                  <Button
                    color="primary"
                    style={{ width: 389, maxWidth: "100%", minHeight: 48 }}
                    disabled={modal}
                  >
                    {t("Subscribed")}
                  </Button>
                )
              ) : (
                <Button
                  color="primary"
                  onClick={() => handleTrial("free")}
                  style={{ width: 389, maxWidth: "100%", minHeight: 48 }}
                >
                  {t("Get started")}
                </Button>
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        {!mobile && (
          <div
            className={classes.compareDiv}
            style={!modal ? { marginBottom: 24 } : {}}
          >
            <Typography
              category="Default"
              variant="H1"
              className={clsx(classes.bodyText, classes.marginB3)}
            >
              {t("How our plans compare")}
            </Typography>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20.2%" }}></TableCell>
                  <TableCell align="center" style={{ width: "19.2%" }}>
                    <Typography category="Default" variant="H6">
                      {t("Free")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "19.2%" }}>
                    <Typography category="Default" variant="H6">
                      {t("Lite")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "19.2%" }}>
                    <Typography category="Default" variant="H6">
                      {t("Smart")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "19.2%" }}>
                    <Typography category="Default" variant="H6">
                      {t("Fleet")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table classes={{ root: classes.tableRoot }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: "20.2%" }}>
                    <Typography category="Default" variant="Body">
                      {t("Cameras per Account")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "19.2%" }}>
                    <Typography category="Default" variant="Body">
                      1
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "19.2%" }}>
                    <Typography category="Default" variant="Body">
                      15
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "19.2%" }}>
                    <Typography category="Default" variant="Body">
                      15
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ width: "19.2%", borderTopRightRadius: 16 }}
                  >
                    <Typography category="Default" variant="Body">
                      999
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Users per Account")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      1
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      1
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      1
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("Owner")}: 1
                    </Typography>
                    <br />
                    <Typography category="Default" variant="Body">
                      {t("Admin")}: 10
                    </Typography>
                    <br />
                    <Typography category="Default" variant="Body">
                      {t("Driver")}: 3 {t("per Camera")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Live View per Day")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("N Minutes", { a: 10 })}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("N Minutes", { a: 30 })}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Remote Video Playback_2")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <Typography category="Default" variant="Body">
                      {t("N Times", { a: 100 })}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Cloud storage")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("GB", { a: 5 })}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("GB", { a: 5 })} {t("per Camera")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("GB", { a: 10 })} {t("per Camera")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("GB", { a: 15 })} {t("per Camera")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Push Notifications")}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenPushNotificationModal(true);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Voice Call")}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenVoiceCallModal(true);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Live GPS")}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveGPSModal(true);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Email Notifications")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Multi Live view_")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("GPS tracking")}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenGPSTrackingModal(true);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("N days", { a: 90 })}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography category="Default" variant="Body">
                        {t("N days", { a: 90 })}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Geofence Setup (Web)_")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Live event upload")}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenLiveEventUploadModal(true);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("N days", { a: 2 })}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("N days", { a: 7 })}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("N days", { a: 30 })}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("N days", { a: 30 })}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.compareTitleTline}>
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Driving Reports")}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenDrivingReportModal(true);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <div className={classes.compareCheckDiv}>
                        <CheckIcon
                          color="primary"
                          className={classes.compareCheckIcon}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <div className={classes.compareCheckDiv}>
                        <CheckIcon
                          color="primary"
                          className={classes.compareCheckIcon}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <div className={classes.compareCheckDiv}>
                        <CheckIcon
                          color="primary"
                          className={classes.compareCheckIcon}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <div className={classes.compareCheckDiv}>
                        <CheckIcon
                          color="primary"
                          className={classes.compareCheckIcon}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div className={classes.featureTline}>
                      <Typography category="Default" variant="Body">
                        {t("Video On Map")}
                      </Typography>
                      <HelpOutlineIcon
                        className="helpBtn"
                        onClick={() => {
                          setOpenGPSTrackingModal(true);
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    {modal ? (
                      subscriptionInfo &&
                      getPlanFromServiceID(subscriptionInfo?.servicePlanID) ===
                        "Free plan" && (
                        <Button
                          fullWidth
                          style={{ minHeight: 48 }}
                          color="primary"
                          disabled={modal}
                        >
                          {t("Subscribed")}
                        </Button>
                      )
                    ) : (
                      <Button
                        fullWidth
                        style={{ minHeight: 48 }}
                        color="primary"
                        onClick={() => handleTrial("free")}
                        disabled={modal}
                      >
                        {t("Get started")}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      className={clsx(
                        classes.appStoreDiv,
                        classes.compareCheckDiv,
                        "flexCol"
                      )}
                    >
                      <a
                        href={APPSTORE}
                        className={classes.appAnchor}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginBottom: theme.spacing(2) }}
                      >
                        <img
                          srcSet="/images/appstore-badge.png 1x, /images/appstore-badge@2x.png 2x"
                          alt="download on the app store"
                        />
                      </a>
                      <a
                        href={GOOGLEPLAY}
                        className={classes.appAnchor}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          srcSet="/images/google-play-badge.png 1x, /images/google-play-badge@2x.png 2x"
                          alt="get it on google play"
                        />
                      </a>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div
                      className={clsx(
                        classes.appStoreDiv,
                        classes.compareCheckDiv,
                        "flexCol"
                      )}
                    >
                      <a
                        href={APPSTORE}
                        className={classes.appAnchor}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginBottom: theme.spacing(2) }}
                      >
                        <img
                          srcSet="/images/appstore-badge.png 1x, /images/appstore-badge@2x.png 2x"
                          alt="download on the app store"
                        />
                      </a>
                      <a
                        href={GOOGLEPLAY}
                        className={classes.appAnchor}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          srcSet="/images/google-play-badge.png 1x, /images/google-play-badge@2x.png 2x"
                          alt="get it on google play"
                        />
                      </a>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottomRightRadius: 16 }}
                  >
                    <Button
                      fullWidth
                      color="primary"
                      onClick={handleFleetBtn}
                      style={{ padding: 16 }}
                    >
                      {(subscriptionInfo?.servicePlanID === 1 ||
                        planName === "Smart plan" ||
                        planName === "Basic plan") &&
                      freetrial?.trialUsed
                        ? t("subscribe")
                        : t("Try 1 Month Free")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
        {!modal && (
          <div className={classes.compareDiv} ref={calcDivRef} id="calculator">
            <Typography
              category="Default"
              variant={mobile ? "H3" : "H1"}
              className={clsx(classes.bodyText, {
                [classes.marginB5]: !mobile,
                [classes.marginB2]: mobile,
              })}
            >
              {t("Calculate your Fleet_")}
            </Typography>
            <div className={classes.calcDiv}>
              <div className={classes.calculator}>
                <div className={classes.camNumDiv}>
                  <Typography
                    category="Default"
                    variant={mobile ? "H3" : "H1"}
                    className={classes.camNumTitle}
                  >
                    {t("Number of cameras")}
                  </Typography>
                  <div className={classes.camNumInput}>
                    <Input
                      type="numeric"
                      count
                      pattern="[0-9]*"
                      value={camCnt}
                      inputmode="numeric"
                      onPlus={() =>
                        setCamCnt((c) => Math.min(c + 1, Price.MAX_CAM_COUNT))
                      }
                      onMinus={() => setCamCnt((c) => Math.max(c - 1, 1))}
                      onChange={(event) => {
                        if (event.target.value === "") {
                          setCamCnt(1);
                        } else {
                          const value = Number.parseInt(event.target.value);
                          if (value <= Price.MAX_CAM_COUNT && value > 0) {
                            setCamCnt(value);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Sliders
                    value={camCnt}
                    variant="large"
                    step={1}
                    min={1}
                    max={Price.MAX_CAM_COUNT}
                    onChange={(event, value) => setCamCnt(value as number)}
                    marks={[
                      {
                        value: 1,
                        label: "1",
                      },
                      {
                        value: 999,
                        label: "999",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={classes.calcResultDiv}>
                <Typography
                  category="Default"
                  variant={mobile ? "H2" : "H1"}
                  htmlColor={LightColors.primary["7"]}
                  style={{
                    marginBottom: mobile ? theme.spacing(3) : theme.spacing(4),
                  }}
                >
                  {t("Fleet")}
                </Typography>
                <Typography
                  category="Default"
                  variant="H6"
                  style={{ marginBottom: theme.spacing(0.5) }}
                >
                  {t("Monthly price")}
                </Typography>
                <div
                  style={{
                    marginBottom: mobile ? theme.spacing(3) : theme.spacing(4),
                  }}
                >
                  <Typography
                    category="Default"
                    variant="H1"
                    htmlColor={LightColors.primary["7"]}
                  >
                    {(
                      Price.FLEET_PRICE +
                      (camCnt - 1) * Price.FLEET_EXTRA
                    ).toFixed(2)}{" "}
                    USD
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["7"]}
                  >
                    /mo
                  </Typography>
                </div>

                <Typography
                  category="Default"
                  variant="H6"
                  style={{
                    marginBottom: mobile ? theme.spacing(3) : theme.spacing(4),
                  }}
                >
                  {t("Available cloud storage: 15GB per Camera")}
                </Typography>

                <Button
                  fullWidth
                  color="primary"
                  style={{ marginBottom: theme.spacing(2), padding: 16 }}
                  onClick={() => handleTrial("fleet", camCnt)}
                >
                  {t("Try 1 Month Free")}
                </Button>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["2"]}
                  style={{ textAlign: "center" }}
                >
                  {t(
                    "Includes unlimited Live View, Remote video playback, and download."
                  )}
                </Typography>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={openExceptionModal}
          close
          onClose={() => setOpenExceptionModal(false)}
          onClickPositive={() => {
            setOpenExceptionModal(false);
          }}
          heading={t("Cancel your inapp_")}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: t("You have cancel_").replace(
                  // eslint-disable-next-line no-control-regex
                  new RegExp("\n", "g"),
                  "<br/>"
                ),
              }}
            />
          }
          RButton={t("OK")}
          actionClassName={classes.modalBottom}
        />
        <LiveGPSModal
          openModal={openLiveGPSModal}
          onClose={() => setOpenLiveGPSModal(false)}
          onClickPositive={() => setOpenLiveGPSModal(false)}
        />
        <PushNotificationModal
          openModal={openPushNotificationModal}
          onClose={() => setOpenPushNotificationModal(false)}
          onClickPositive={() => setOpenPushNotificationModal(false)}
        />
        <VoiceCallModal
          openModal={openVoiceCallModal}
          onClose={() => setOpenVoiceCallModal(false)}
          onClickPositive={() => setOpenVoiceCallModal(false)}
        />
        <DrivingReportModal
          openModal={openDrivingReportModal}
          onClose={() => setOpenDrivingReportModal(false)}
          onClickPositive={() => setOpenDrivingReportModal(false)}
        />
        <GPSTrackingModal
          openModal={openGPSTrackingModal}
          onClose={() => setOpenGPSTrackingModal(false)}
          onClickPositive={() => setOpenGPSTrackingModal(false)}
        />
        <LiveEventUploadModal
          openModal={openLiveEventUploadModal}
          onClose={() => setOpenLiveEventUploadModal(false)}
          onClickPositive={() => setOpenLiveEventUploadModal(false)}
        />
        <SimDataPlanModal
          openModal={openSimDataPlanModal}
          onClose={() => setOpenSimDataPlanModal(false)}
          onClickPositive={() => setOpenSimDataPlanModal(false)}
        />
        <PriceDownModal
          openModal={openPriceDownModal}
          onClose={() => setOpenPriceDownModal(false)}
          onClickPositive={() => setOpenPriceDownModal(false)}
        />
      </Container>
      {maintHandler}
    </div>
  );
};
