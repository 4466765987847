import { LocationType } from "@thingsw/pitta-design-system";
import { detect } from "detect-browser";
import _ from "lodash";
import moment from "moment";
import { DMStoDegree } from "./GoogleMap";
import {
  jwtAxiosInst,
  IVOD,
  IVODToken,
  Direction,
  ICameraInfo,
  IGPSLocation,
  MCODE_TO_TEXT,
  VideoQualityType,
  getLbURI,
} from "@thingsw/pitta-modules";

const download = async (url: string) => {
  const browser = detect();
  const a = document.createElement("a");
  a.download = "";
  a.href = url;
  if (browser?.name !== "ios") {
    a.target = "_blank";
  }
  a.style.display = "none";
  document.body.append(a);
  a.click();

  // Chrome requires the timeout
  await delay(100);
  a.remove();
};

export const delay = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

export const multiDownload = async (urls: string[]) => {
  const browser = detect();
  let timeout = 1000;
  if (browser?.name === "ios") {
    timeout = 3000;
  }
  _.forEach(urls, async (url, index) => {
    await delay(index * timeout);
    download(url);
  });
};

export const doDownload = async (linkUrl: string, filename?: string) => {
  console.log("doDownload", linkUrl);

  return new Promise<void>((resolve, reject) => {
    const browser = detect();
    if (browser?.name === "ios" || browser?.name === "crios") {
      console.log("safari");
      window.location.href = linkUrl;
    } else {
      const link = document.createElement("a");
      link.href = linkUrl;
      if (filename) {
        link.download = filename;
      }

      link.target = "_blank";
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        // document.body.removeChild(link);
        resolve();
      }, 0);
    }
  });

  // await axios({
  //   url: linkUrl,
  //   method: "GET",
  //   responseType: "blob",
  //   onDownloadProgress: (progressEvent) => {
  //     let percentCompleted = Math.round(
  //       (progressEvent.loaded * 100) / progressEvent.total
  //     );
  //     // you can use this to show user percentage of file downloaded
  //     console.log("onDownloadProgress", percentCompleted);
  //   },
  // });
};

export const getDirectionString = (
  dir: LocationType,
  model?: string
): LocationType => {
  // if (_.includes(MODELS_2CH, model) && dir === "Interior") {
  //   return "Rear";
  // } else {
  //   return dir;
  // }
  return dir;
};

export const getDirection = (
  dir: Direction,
  isOptionModel: boolean
): LocationType => {
  if (dir === 2) {
    return "Rear";
  } else if (dir === 3) {
    if (isOptionModel) {
      return "Option";
    }
    return "Interior";
  }
  return "Front";
};

export const getFileRes = (filename: string) => {
  const fs = filename.split("_")[2];
  const fs2 = fs.split(".")[0];

  return fs2[2] === "S" ? "S" : "M";
};

export const getOriginalFilename = (filename: string) => {
  const fs = filename.split("_");
  const fs2 = fs[2].split(".");

  return fs2[0][2] === "S"
    ? `${fs[0]}_${fs[1]}_${fs2[0][0]}${fs2[0][1]}.${fs2[1]}`
    : filename;
};

// export const getDirection = (dir: Direction, isOptionModel: boolean) => {
//   if (dir === 2) {
//     return "Rear";
//   } else if (dir === 3) {
//     if (isOptionModel) {
//       return "Option";
//     }
//     return "Interior";
//   }
//   return "Front";
// };

export const getEventLink = async (
  email: string,
  userToken: string,
  psn: string,
  rid: string
) => {
  return jwtAxiosInst.get(
    `/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
  );
};

export const getCloudLink = async (
  email: string,
  userToken: string,
  psn: string,
  fname: string
) => {
  return jwtAxiosInst.get(
    `/BCS/userS3PresignedUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&filename=${fname}&tokenType=web`
  );
};

export const getLink = (
  camera: ICameraInfo,
  email: string,
  userToken: string,
  vod: IVOD,
  token: IVODToken,
  vodQuality: VideoQualityType
) => {
  const { lb_server_name, lb_http_port, psn } = camera;
  const { filename, lowFilename } = vod;
  const { vod_token } = token;
  let fname = filename;
  if (vodQuality === "low" && lowFilename) {
    fname = lowFilename;
  }
  return `${getLbURI(
    lb_server_name,
    lb_http_port
  )}/proc/vod_file?email=${email}&user_token=${userToken}&psn=${psn}&filename=${fname}&vod_token=${vod_token}&tokenType=web`;
};

export const downloadBlob = function (
  data: BlobPart,
  fileName: string,
  mimeType: string
) {
  const blob = new Blob([data], {
    type: mimeType,
  });
  const url = window.URL.createObjectURL(blob);
  downloadURL(url, fileName);
  setTimeout(function () {
    return window.URL.revokeObjectURL(url);
  }, 1000);
};

const downloadURL = function (data: string, fileName: string) {
  const a = document.createElement("a");
  a.href = data;
  a.download = fileName;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  a.remove();
};

// 해시태그가 없는 Parking, Normal 공유영상인 경우 Parking, Normal로 출력(Leehj)
export const eventToText = (mode: string, type: number | string) => {
  const cType = typeof type === "string" ? parseInt(type) : type;
  console.log("event");
  if (mode === "M") {
    return "Manual";
  }
  if (mode === "P" && cType === 0) {
    return "Parking";
  }
  if (mode === "P") {
    return "Parking impact";
  }
  if (mode === "N") {
    return "Normal";
  }

  return MCODE_TO_TEXT[cType];
};

export const parseNMEA = (
  nmea: string,
  playback?: boolean,
  camera?: ICameraInfo,
  startTime?: moment.Moment
) => {
  let locations: IGPSLocation[] = [];
  const nmeaStrings: string[] = [];

  const matches = nmea.matchAll(
    /(\[[0-9]{13}\]\$(GNRMC|GPRMC){1}[A-Z0-9,.*]+)/g
  );
  let next: any;
  while ((next = matches.next().value)) {
    // console.log("next", next);
    nmeaStrings.push(next[0]);
  }

  //console.log("nmeaStrings", decoder.decode(data), nmeaStrings);

  // let cnt = 0;
  locations = _.chain(nmeaStrings)
    .filter((s) => s.indexOf("$GNRMC") !== -1 || s.indexOf("$GPRMC") !== -1)
    .map((s) => {
      const nmeas = s.split(",");
      const regex = /[0-9]+/;

      if (nmeas.length === 13 || nmeas.length === 14) {
        const unixTime = parseInt(nmeas[0].match(regex)?.[0] ?? "0");
        const uTime = moment.unix(unixTime / 1000);
        // cnt++;
        // console.log(
        //   "locations",
        //   "uTime",
        //   unixTime,
        //   startTime?.toISOString(),
        //   uTime.toISOString(),
        //   uTime.diff(startTime, "second"),
        //   cnt,
        //   nmeas
        // );

        const dur = moment.duration(
          `${nmeas[1].slice(0, 2)}:${nmeas[1].slice(2, 4)}:${nmeas[1].slice(
            4
          )}`,
          "millisecond"
        );
        const time = dur.asSeconds();
        // 미국/중국 gps location 잘못 나오는 문제 수정
        const lat =
          (nmeas[4] === "N" ? 1 : -1) * DMStoDegree(parseFloat(nmeas[3]));
        const lng =
          (nmeas[6] === "E" ? 1 : -1) * DMStoDegree(parseFloat(nmeas[5]));
        const speed = nmeas[7];
        const heading = parseFloat(nmeas[8]);
        if (!(isNaN(time) || isNaN(lat) || isNaN(lng))) {
          return {
            time: startTime ? uTime.diff(startTime, "second") : time,
            lat,
            lng,
            heading,
            name: camera?.dev_name,
            mode: "0",
            speed,
            // mantis - 8561, playback에서 비디오 실행하는 경우 active 무조건 on (속도출력위함)
            active: playback && "on",
          } as IGPSLocation;
        }
      }
      return undefined;
    })
    .compact()
    .sortBy("time")
    .value();

  // console.log("locations", locations);

  if (!startTime) {
    const baseTime = _.first(locations)?.time ?? 0;
    locations = _.map(locations, (loc) => ({
      ...loc,
      time: (loc.time ?? baseTime) - baseTime,
    }));
  }

  return locations;
};

export const convertLowFilenameToOrignal = (filename: string) => {
  const f1 = filename.split(".");
  const fnames = f1[0].split("_");

  return `${fnames[0]}_${fnames[1]}_${fnames[2].substring(0, 2)}.${f1[1]}`;
};
