import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Fab,
  CameraTable,
  Typography,
  Modal,
  Tooltip,
  IconButton,
} from "@thingsw/pitta-design-system";

import HelpIcon from "@material-ui/icons/Help";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
import SearchIcon from "@material-ui/icons/Search";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import Input from "@thingsw/pitta-design-system/dist/components/Input";

import { EmptyItems } from "../components/EmptyItems";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  clearCamera,
  clearPublicCamera,
  loadCameras,
  loadMyCemrasLocation,
  loadPublicCamera,
  renameCamera,
  resetLocationLoaded,
  setSharedEventMap,
} from "../features/Camera/slice";
import { RootState } from "../features/store";
import { DRAWER_MODE, MobileDrawer } from "../components/MobileDrawer";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { GROUP, loadGroups } from "../features/Group/slice";
import { SubMgrModal } from "../components/accounts/SubMgrModal";
import { USER } from "../features/User/slice";
import { loadWebSubscription, PAYMENT } from "../features/Payment/slice";
import { NoResults } from "../components/NoResults";
import { CameraMenu } from "../components/uxui/CameraMenu";
import { MobileCameraMenu } from "../components/uxui/MobileCameraMenu";
import { GEOFENCE, loadGeofences } from "../features/Geofence/slice";
import { Onboarding } from "../components/onboarding/Onboarding";
import { OnboardingStep1 } from "../components/onboarding/OnboardingStep1";
import { OnboardingStep2 } from "../components/onboarding/OnboardingStep2";
import { OnboardingStep3 } from "../components/onboarding/OnboardingStep3";
import { OnboardingStep4 } from "../components/onboarding/OnboardingStep4";
import { OnboardingStep5 } from "../components/onboarding/OnboardingStep5";
import { OnboardingStep6 } from "../components/onboarding/OnboardingStep6";
import { OnboardingStep7 } from "../components/onboarding/OnboardingStep7";
import { openToast } from "../features/Toast/slice";
import { OnboardingMobile } from "../components/onboarding/OnboardingMobile";
import { findNewFw } from "../utils/Firmware";
import { PricingModal } from "../components/modals/PricingModal";
import { getCamInfo } from "../apis";
import { AutoSizer } from "react-virtualized";
import Scrollbars from "react-custom-scrollbars";

import { CameraListPanelUXUI } from "../components/uxui/CameraListPanelUXUI";
import { BatteryStatusPanel } from "../components/uxui/BatteryStatusPanel";
import { BatterySettingPanel } from "../components/uxui/BatterySettingPanel";

import { AddCameraGuideModal } from "../components/modals/AddCameraGuideModal";
import { NotificationSettingPanel } from "../components/uxui/NotificationSettingPanel";
import moment from "moment";
import { getPlanFromServiceID } from "../utils/Service";
import {
  ICameraInfo,
  Webviewer,
  LightColors,
  IGPSLocation,
  AvailableFws,
  AvailableFw,
  PSN650,
  PermissionProps,
} from "@thingsw/pitta-modules";
// import { LOCATION_WS } from "../features/LocationWS/slice";
// import { PUSH_EVENT } from "../features/PushEvent/slice";
import { MapboxLocationMapWrapper } from "../components/maps/MapboxLocationMapWrapper";
import { LiveViewMapboxModal } from "../components/uxui/LiveViewMapboxModal";
import {
  batteryManualEnd,
  resetBatteryHistory,
  resetBatteryInfo,
  setBatteryInfo,
  setSettingInfo,
  upateCurrentCam,
} from "../features/Battery/slice";
import UnpairBatteryModal from "../components/modals/UnpairBatteryModal";
import {
  setGpsPsnsMyDevices,
  setMultipleMap,
} from "../features/LocationWS/slice";

interface CameraScreenProps {
  openMenu: boolean;
  error?: string;
  onRename?: (cam: ICameraInfo) => void;
  onDelete?: (cam: ICameraInfo) => void;
  onRebootCamera?: (cam: ICameraInfo) => void;
  webSearchKey: string;
  webMode: "map" | "list";
  onSearchKey?: (search?: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // height: "calc(var(--vh, 1vh) * 100 - 56px - 165px)",
    height: (props: CameraScreenProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 56px - 165px + 25px)"
        : "calc(var(--vh, 1vh) * 100 - 56px - 165px)",

    marginTop: (props: CameraScreenProps) =>
      props.error ? 125 + 127 - 34 : 181,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: CameraScreenProps) =>
        props.error
          ? // ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
            // : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
            "calc(var(--vh, 1vh) * 100 - 85px - 16px)"
          : "calc(var(--vh, 1vh) * 100 - 56px)",
      marginTop: (props: CameraScreenProps) =>
        // props.error ? 68 + 85 : 68 + 56,
        props.error ? 56 + 85 - 37 : 56,
    },
  },
  driverRoot: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginTop: (props: CameraScreenProps) => (props.error ? 167 : 133),
      height: (props: CameraScreenProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 56px)"
          : "calc(var(--vh, 1vh) * 100 - 56px)",
    },
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 116,
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      height: 68,
      padding: theme.spacing(0, 4),
    },
  },
  driverSubHeader: {
    height: 68,
    justifyContent: "flex-start",
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "fit-content",
    },
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 21 } : { marginRight: 21 }),
    },
  },
  searchBtnOpen: {
    width: "100%",
    marginRight: 0,
  },
  camCntText: {
    // margin: theme.spacing(2.5, 0),
    // [theme.breakpoints.up(Webviewer.mobile)]: {
    //   margin: theme.spacing(0, 0, 0, 0),
    // },
  },
  dotText: {
    margin: "0 3px",
  },
  body: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    // marginBottom: 56,
    // 8930 - iOS 12 safari에서 overflow:auto가 동작 안하는 이슈 수정
    overflow: "auto",
    zIndex: 100,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },
  alignStart: {
    alignItems: "flex-start",
  },
  directionRow: {
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  hide: {
    display: "none",
  },
  fabMargin: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.5) }
      : { marginLeft: theme.spacing(0.5) }),
  },
  fabUXUI: {
    borderRadius: 12,
    border: `1px solid ${LightColors.primary["6"]}`,
    boxShadow: "none",
    marginTop: 20,
  },
  tableDiv: {
    width: "100%",
    minWidth: 1141,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 0,
      padding: theme.spacing(2, 4),
    },
  },
  listPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
    // height: "calc(100% - 32px)",
    margin: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    // mantis 10506, uxui 카메라 리스트 높이 피그마디자인 높이로 적용 (Leehj)
    marginBottom: 88,
    zIndex: 100,
    height: "calc(100% - 104px)",
  },
  subHeaderDiv: {
    position: "fixed",
    top: (props: CameraScreenProps) => (props.error ? 85 : 56),
    ...(theme.direction === "rtl"
      ? {
          left: 0,
          right: 73,
        }
      : {
          right: 0,
          left: 73,
        }),
    zIndex: 90,
    backgroundColor: LightColors.primary["0"],
  },
  subHeaderDivOpen: {
    ...(theme.direction === "rtl" ? { right: 235 } : { left: 235 }),
  },
  subHeaderMobile: {
    top: (props: CameraScreenProps) => (props.error ? 127 - 25 : 65),
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
  },
  subHeaderDriverMobile: {
    top: (props: CameraScreenProps) => (props.error ? 99 : 65),
  },
  mapDiv: {
    width: "100%",
    // height: "calc(var(--vh, 1vh) * 100 - 56px - 165px - 56px)",
    // 모바일에서 카메라 리스트 닫으면 지도 부분 하단에 흰색으로 표시되는 이슈 수정(Leehj) 23.01.12
    height: (props: CameraScreenProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 56px - 165px - 54px)"
        : "calc(var(--vh, 1vh) * 100 - 56px - 165px - 16px)",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      // mantis - 10280 , 맥북 사파리에서 camfilter 아이콘 클릭시 스크롤 생겼다 사라지는 이슈 수정 (Leehj)
      overflow: "hidden",
      height: (props: CameraScreenProps) =>
        props.error
          ? // ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
            // : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
            "calc(var(--vh, 1vh) * 100 - 85px - 16px)"
          : "calc(var(--vh, 1vh) * 100 - 56px)",
    },
  },
  driverMapDiv: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      height: (props: CameraScreenProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 56px - 165px - 16px + 13px )"
          : "calc(var(--vh, 1vh) * 100 - 56px - 165px - 16px + 48px )",
    },
  },
  modalRoot: {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
  },
  modalContentDiv: {
    padding: theme.spacing(4, 0),
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
  modalTitle: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  modalContent: {
    padding: theme.spacing(1.5, 3),
  },
  btnGroup: {
    width: "fit-content",
  },
}));

export const CameraScreen = (props: CameraScreenProps & PermissionProps) => {
  console.log("Render CamreaScreen");
  const {
    openMenu,
    onRename,
    onDelete,
    onRebootCamera,
    error,
    webMode,
    webSearchKey,
    ...permissions
  } = props;
  const {
    settingsCamPerm,
    renameCamPerm,
    deleteCamPerm,
    fotaCamPerm,
    rebootCamPerm,
    shareToEventmapPerm,
  } = permissions;
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ mode?: "map" | "list" }>();

  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const mapRef = useRef<HTMLDivElement>(null);

  const { geofences } = useSelector((state: RootState) => state[GEOFENCE]);

  const userState = useSelector((state: RootState) => state[USER]);
  // const locationState = useSelector((state: RootState) => state[LOCATION_WS]);
  // const pushEventState = useSelector((state: RootState) => state[PUSH_EVENT]);
  const cameraState = useSelector((state: RootState) => state[CAMERA]);

  const { userProfile, email, loginInfo, tokenType } = userState;

  const {
    cameraList,
    firmwares,
    // publicCams,
    isLocationLoaded,
    publicCamera,
    sharedEventMap,
    loading,
    type,
  } = cameraState;
  const { groupsList } = useSelector((state: RootState) => state[GROUP]);
  const { subscriptionInfo, webSubscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  // const [loadingShareEvent, setLoadingShareEvent] = useState(false);
  const [, /*openEventmapModal*/ setOpenEventmapModal] = useState(false);
  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [moreCam, setMoreCam] = useState<ICameraInfo>();
  const [anchorRef, setAnchorRef] = useState<
    React.RefObject<HTMLElement> | undefined
  >();

  const [openGuideModal, setOpenGuideModal] = useState(false);
  // mantis - 11477 Wi-fi만 연결 된 카메라 선택 시 Blackvue App 설치 팝업 노출 관련 state(Leehj)
  const [openGuideWIFIModal, setOpenGuideWIFIModal] = useState(false);
  const [openPricing, setOpenPricing] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [mode, setMode] = useState<"map" | "list">("map");
  const [openSearch, setOpenSearch] = useState(false);
  const [openPanel, setOpenPanel] = useState(true);
  const [nSlot, setNSlot] = useState(0);
  const [nReal, setNReal] = useState(0);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [openCancelFleetModal, setOpenCancelFleetModal] = useState(false);
  const [map /*setMap*/] = useState<any>();
  const [, /*locations*/ setLocations] = useState<IGPSLocation[]>([]);
  // const [, /*bounds*/ setBounds] = useState<ILatLngBounds>();
  const [searchKey, setSearchKey] = useState("");
  const [filteredCams, setFilteredCams] = useState<ICameraInfo[]>();
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const [liveCam, setLiveCam] = useState<ICameraInfo>();
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [notificationCam, setNotificationCam] = useState<ICameraInfo>();
  const [publicCam, setPublicCam] = useState(false);
  const [step, setStep] = useState(0);
  const [openInvalidModal, setOpenInvalidModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openUnpairBattery, setOpenUnpairBattery] = useState(false);
  const [unpairBatteryCam, setUnpairBatteryCam] = useState<ICameraInfo>();

  // 라이브뷰용 카메라 목록, 백그라운드에서 cameraList가 계속 업데이트 되서, handleLiveView가 변경되어 LocationMap이 반복적으로 업데이트되는 문제 수정
  // 위 이슈로 인해서 지도상의 아이콘 동작이 이상함
  const [liveCams, setLiveCams] = useState<ICameraInfo[]>([]);

  const [drawerMode, setDrawerMode] = useState<DRAWER_MODE>("close");
  const [requestZoneAllDevice, setRequestZoneAllDevice] = useState<object>();

  // const showLocation = useMemo(() => {
  //   return (cameraList?.deviceListInfo?.length ?? 0) === 0;
  // }, [cameraList?.deviceListInfo?.length]);
  const [panelState, setPanelState] = useState<"list" | "battery" | "setting">(
    "list"
  );

  useEffect(() => {
    return () => {
      dispatch(setGpsPsnsMyDevices(false));
    };
  }, [dispatch]);

  // 배터리 수동 연결 중 페이지 이동 상태에 따른 처리
  useEffect(() => {
    if (window) {
      const currentCam = window.localStorage.getItem(
        "pitta-webviewer:battery-currentCam"
      );

      // 배터리 연결 캠 확인
      if (currentCam) {
        const cam = JSON.parse(currentCam);
        console.log(cam);

        dispatch(upateCurrentCam(cam));
      }

      const status = window.localStorage.getItem(
        "pitta-webviewer:battery-status"
      );

      const setting = window.localStorage.getItem(
        "pitta-webviewer:battery-setting"
      );

      // 배터리 세팅 화면 새로고침 정보 가져오기
      if (setting && status) {
        const settingInfo = JSON.parse(setting);
        const batteryInfo = JSON.parse(status);

        dispatch(setSettingInfo(settingInfo));
        dispatch(setBatteryInfo(batteryInfo));

        setPanelState("setting");
        window.localStorage.removeItem("pitta-webviewer:battery-status");
        window.localStorage.removeItem("pitta-webviewer:battery-setting");
        return;
      }

      // 배터리 메인 화면 새로고침 정보 가져오기
      if (status) {
        const batteryInfo = JSON.parse(status);

        dispatch(setBatteryInfo(batteryInfo));
        setPanelState("battery");
        window.localStorage.removeItem("pitta-webviewer:battery-status");
        return;
      }

      // 배터리 연결 상태가 남아 있는지 체크 후 연결 종료 요청 전송
      const batteryConnect = window.localStorage.getItem(
        "pitta-webviewer:battery-connect-info"
      );

      if (batteryConnect) {
        const cameraInfo = JSON.parse(batteryConnect);

        dispatch(
          batteryManualEnd({
            psn: cameraInfo.psn,
            battery: {
              ssid: cameraInfo.ssid,
            },
          })
        );

        window.localStorage.removeItem("pitta-webviewer:battery-connect-info");
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      cameraList?.deviceListInfo &&
      liveCams.length !== cameraList.deviceListInfo.length
    ) {
      setLiveCams(_.map(cameraList.deviceListInfo, (dev) => dev.device));
    }
  }, [cameraList?.deviceListInfo, liveCams.length]);

  useEffect(() => {
    if (!mobile) {
      setMode(webMode);
    }
  }, [mobile, webMode]);

  // mantis - 11425, rename후 카메라 서브메뉴 닫히도록 수정(Leehj)
  useEffect(() => {
    if (type !== renameCamera.type && !loading) {
      setOpenCamMenu(false);
    }
  }, [loading, type]);

  useEffect(() => {
    setMode(location.state?.mode ?? "map");
  }, [location]);

  useEffect(() => {
    if (!mobile) {
      setSearchKey(webSearchKey);
    }
  }, [mobile, webSearchKey]);

  const B2BFleetOrPlus = useMemo(() => {
    return (
      subscriptionInfo &&
      (subscriptionInfo?.servicePlanID === 1001 ||
        subscriptionInfo?.servicePlanID === 1998 ||
        subscriptionInfo?.servicePlanID === 1999 ||
        (subscriptionInfo?.servicePlanID >= 4001 &&
          subscriptionInfo?.servicePlanID <= 4029) ||
        subscriptionInfo?.servicePlanID === 1999 ||
        (subscriptionInfo?.servicePlanID >= 30001 &&
          subscriptionInfo?.servicePlanID <= 30999) ||
        (subscriptionInfo.servicePlanID >= 50001 &&
          subscriptionInfo.servicePlanID <= 50999) ||
        (subscriptionInfo.servicePlanID >= 20001 &&
          subscriptionInfo.servicePlanID <= 20999))
    );
  }, [subscriptionInfo]);

  const [availableNewFws, setAvailableNewFws] = useState<AvailableFws>({});

  useEffect(() => {
    const loadSharedEventMap = async (psn: string) => {
      if (!email || !loginInfo?.user_token) return;
      const resp = await getCamInfo(
        email,
        loginInfo.user_token,
        psn,
        tokenType
      );
      if (!resp?.data?.response) return;
      const { sharedEventMap } = resp.data.response;
      if (sharedEventMap === "" || sharedEventMap === undefined) {
        dispatch(setSharedEventMap(null));
      } else {
        dispatch(setSharedEventMap(sharedEventMap));
      }
    };
    const psn = _.first(cameraList?.deviceListInfo)?.device?.psn;
    if (sharedEventMap === undefined && psn) {
      loadSharedEventMap(psn);
    }
  }, [
    cameraList?.deviceListInfo,
    dispatch,
    email,
    loginInfo?.user_token,
    sharedEventMap,
    tokenType,
  ]);

  useEffect(() => {
    const tourString = localStorage.getItem("pitta-webviewer-tour");
    const tour = JSON.parse(tourString ?? "[]");
    if (!_.includes(tour, email)) {
      let link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding.png";
      link.imageSrcset = "/images/onboarding.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding1.png";
      link.imageSrcset = "/images/onboarding1.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding2.png";
      link.imageSrcset = "/images/onboarding2.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding3.png";
      link.imageSrcset = "/images/onboarding3.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding4.png";
      link.imageSrcset = "/images/onboarding4.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding5.png";
      link.imageSrcset = "/images/onboarding5.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding6.png";
      link.imageSrcset = "/images/onboarding6.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding7.png";
      link.imageSrcset = "/images/onboarding7.png 1x";
      setStep(0);
    } else {
      setStep(-1);
    }
  }, [email]);

  // mantis - 7704, Live view Off 음성 확인 후 새로고침 시 잠시 Live view 페이지가 보여지는 이슈 수정 (Leehj)
  useEffect(() => {
    window.onbeforeunload = (e: any) => {
      console.log("App", "onbeforeunload", location.pathname);
      history.replace(location.pathname);
    };
  }, [history, location.pathname]);

  useEffect(() => {
    return () => {
      setOpenLiveModal(false);
      setLiveCam(undefined);
    };
  }, []);

  useEffect(() => {
    if (publicCamera) {
      setLiveCam(publicCamera);
      setOpenLiveModal(true);
    }
  }, [publicCamera]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (
      params.get("from") === "sim" &&
      subscriptionInfo?.servicePlanName === "free"
    ) {
      setOpenUpgrade(true);
    }
  }, [location, subscriptionInfo]);

  useEffect(() => {
    if (permissions.geofencePerm) {
      dispatch(loadGeofences());
    }
  }, [dispatch, permissions.geofencePerm]);

  // useEffect(() => {
  //   if (cameraList) {
  //     const cam = _.first(cameraList.DeviceListInfo);
  //     if (cam && cam.device.psn !== camera?.psn) {
  //       dispatch(loadCamera(cam.device.psn));
  //     }
  //   }
  // }, [camera?.psn, cameraList, dispatch]);

  useEffect(() => {
    console.log("Eventmap", "sharedEventMap", sharedEventMap);
    if (sharedEventMap === null && shareToEventmapPerm) {
      setOpenEventmapModal(true);
    }
  }, [shareToEventmapPerm, sharedEventMap]);

  useEffect(() => {
    if (cameraList) {
      setAvailableNewFws(
        _.reduce(
          cameraList.deviceListInfo,
          (result, dev) => {
            return {
              ...result,
              [dev.device.psn]: findNewFw(dev.device, firmwares),
            };
          },
          {}
        )
      );
    }
  }, [cameraList, firmwares]);

  useEffect(() => {
    setLocations([]);
  }, []);

  useEffect(() => {
    if (mobile) {
      if (mode === "map") {
        const body = document.getElementById("body-container");
        body?.setAttribute("style", "overflow:hidden;");

        return () => {
          body?.setAttribute("style", "overflow:auto;");
        };
      }
    }
  }, [mode, mobile]);

  useEffect(() => {
    dispatch(loadGroups());
    dispatch(clearCamera());
    dispatch(loadWebSubscription());
    return () => {
      dispatch(resetLocationLoaded());
      dispatch(clearPublicCamera());
    };
  }, [dispatch]);

  useEffect(() => {
    if (map && cameraList) {
      const loc = _.chain(cameraList.deviceListInfo)
        .map((cam) => {
          if (
            cam.device.latitude === "undefined" ||
            cam.device.longitude === "undefined"
          ) {
            return undefined;
          }
          if (cam.device.latitude && cam.device.longitude) {
            const lat = parseFloat(cam.device.latitude);
            const lng = parseFloat(cam.device.longitude);
            return {
              lat,
              lng,
              heading: parseFloat(cam.device.dir_angle ?? "0"),
              psn: cam.device.psn,
              name: cam.device.dev_name,
              speed: cam.device.speed,
              mode: cam.device.mode,
              model: cam.device.model,
              active: cam.device.active,
              agree_gps: cam.device.agree_gps,
              profile_img: cam.device.profile_img,
              isPublic: false,
            } as IGPSLocation;
          }
          return undefined;
        })
        .compact()
        .value();

      console.log("CameraScreen", "locations", loc);
      setLocations(loc);
    }
  }, [cameraList, map]);

  // mantis - 10203, 계정에 등록한 gps연결된 카메라가 있는데, 미국 지도가 노출되는 이슈 수정. (Leehj)
  useEffect(() => {
    if (!isLocationLoaded) {
      const cancel = new AbortController();
      dispatch(loadMyCemrasLocation({ cancel }));
      return () => {
        // setInitLoadLocations(false);
        cancel.abort();
      };
    }
    // else if (bounds) {
    //   const cancel = new AbortController();
    //   dispatch(loadMyCemrasLocation({ bounds, cancel }));
    //   return () => {
    //     cancel.abort();
    //   };
    // }
  }, [dispatch, isLocationLoaded]);

  useEffect(() => {
    if (subscriptionInfo) {
      setNSlot(subscriptionInfo.cameraLimit);
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (cameraList) {
      setNReal(cameraList.deviceCount ?? 0);
    }
  }, [cameraList]);

  const handleScreenTypeChange = useCallback((value: number) => {
    if (value === 0) {
      setMode("map");
    } else if (value === 1) {
      setMode("list");
    }
  }, []);

  // const handleShareEventmap = useCallback(
  //   async (share: boolean) => {
  //     try {
  //       if (email && loginInfo) {
  //         setLoadingShareEvent(true);
  //         await setShareingEventamp(
  //           email,
  //           loginInfo.user_token,
  //           share ? "on" : "off"
  //         );
  //         setOpenEventmapModal(false);
  //         setLoadingShareEvent(false);
  //         // 10016 수정
  //         dispatch(setSharedEventMap(share ? "on" : "off"));
  //       }
  //     } catch (err) {
  //       console.error(err.message);
  //       setOpenEventmapModal(false);
  //       setLoadingShareEvent(false);
  //     }
  //   },
  //   [dispatch, email, loginInfo]
  // );

  // 카메라 페이지를 이탈할 때 배터리 정보를 초기화
  useEffect(() => {
    return () => {
      dispatch(resetBatteryHistory());
      dispatch(resetBatteryInfo());
    };
  }, [dispatch]);

  const listMarkup = useMemo(() => {
    const cams = _.map(cameraList?.deviceListInfo, (camera) => {
      const group = _.find(groupsList?.DashCamList, (g) => {
        return !!_.find(g.DashCamUser, (cam) => cam.PSN === camera.device.psn);
      });
      const users = _.find(
        group?.DashCamUser,
        (cam) => cam.PSN === camera.device.psn
      )?.Users;
      const user = _.first(users);

      const ExistName = _.find(users, (user) => {
        if (user.FirstName !== undefined) {
          return true;
        } else {
          return false;
        }
      });

      const driverName =
        user?.Email && user?.Status === "A"
          ? `${user.LastName} ${user.FirstName}${
              users && users.length > 1 ? ` +${users.length - 1}` : ``
            }`
          : user?.Email && ExistName
          ? `${ExistName.LastName} ${ExistName.FirstName}${
              users && users.length > 1 ? ` +${users.length - 1}` : ``
            }`
          : `–`;
      return { ...camera.device, groupName: group?.GroupName, driverName };
    });

    const filteredCams = _.chain(cams)
      .filter(
        (cam) =>
          cam.dev_name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
          cam.model.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
          //mantis - 8448 groupName으로 검색 가능하도록 추가
          (cam.groupName !== undefined &&
            cam.groupName.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
      )
      .map((cam) => {
        const newFw = _.find(availableNewFws, (v, k) => k === cam.psn);
        return { ...cam, newFw: !!(newFw?.blackvue || newFw?.dmc200) };
      })
      .value();
    if (cams.length > 0) {
      if (filteredCams.length > 0) {
        const tableMarkup = (
          <CameraTable
            cameraTableIcon
            onClick={(cam) => {
              const loggined = !cam.login_date
                ? false
                : !moment(cam.login_date).isBefore(moment.utc(0));

              // mantis - 11477 Wi-fi만 연결 된 카메라 선택 시 Blackvue App 설치 팝업 노출(Leehj)
              if (
                cam.reg_category === "wifi" ||
                (cam.reg_category === "cloud" && !loggined)
              ) {
                setOpenGuideWIFIModal(true);
              } else if (cam.valid === "invalid") {
                setOpenInvalidModal(true);
              } else {
                history.push(`/cameras/${cam.psn}/playback`);
              }
            }}
            onClickMore={(cam, anchor) => {
              // mantis - 12390, 비활성화 Cloud camera 서브메뉴 안열리도록 적용 (Leehj)
              if (cam.valid !== "invalid") {
                setAnchorRef(anchor);
                setMoreCam(cam);
                setOpenCamMenu((o) => !o);
              }
            }}
            cameras={filteredCams}
            t={t}
          />
        );
        return (
          <div className={classes.tableDiv}>
            {mobile ? (
              tableMarkup
            ) : (
              <AutoSizer>
                {({ width, height }) => (
                  <Scrollbars
                    style={{ height, width }}
                    autoHide
                    // mantis - 12513, 아랍어인경우 화면 우측 잘려보이는 이슈 수정 (Leehj)
                    renderView={(props) => (
                      <div
                        {...props}
                        style={{
                          marginLeft: theme.direction === "rtl" ? -17 : 0,
                          marginRight: theme.direction === "rtl" ? 0 : -17,
                          inset: 0,
                          position: "absolute",
                          overflowY: "scroll",
                        }}
                      />
                    )}
                  >
                    {tableMarkup}
                  </Scrollbars>
                )}
              </AutoSizer>
            )}
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoResults />
          </div>
        );
      }
    }

    return <EmptyItems variant={mobile ? "small" : "default"} />;
  }, [
    cameraList?.deviceListInfo,
    mobile,
    groupsList?.DashCamList,
    searchKey,
    availableNewFws,
    t,
    classes.tableDiv,
    history,
    theme.direction,
  ]);

  const handleFOTA = useCallback(
    (cam: ICameraInfo, fw?: AvailableFw) => {
      if (fw) {
        if (cam && cam.active === "on") {
          if (fw.dmc200) {
            history.push(`/cameras/${cam.psn}/fota-dmc200`);
          } else if (fw.blackvue) {
            history.push(`/cameras/${cam.psn}/fota`);
          }
        }
      } else {
        dispatch(openToast({ message: "Firmware is up_" }));
      }
    },
    [dispatch, history]
  );

  const cameraPanelUXUIMarkup = useMemo(() => {
    return (
      <div className={classes.listPane}>
        {panelState === "list" && (
          <CameraListPanelUXUI
            {...permissions}
            availableNewFws={availableNewFws}
            open={openPanel}
            onUpdateFilteredCams={setFilteredCams}
            onRename={onRename}
            onDelete={onDelete}
            onRebootCamera={onRebootCamera}
            onClick={(cam) =>
              setCurrentCam((c) => (c?.psn === cam?.psn ? c : cam))
            }
            onUpdate={handleFOTA}
            onNotification={(cam) => {
              console.log("onNotification", cam);
              setNotificationCam((c) =>
                c?.psn === cam?.psn && c?.interval === cam?.interval ? c : cam
              );
              setOpenNotification(true);
            }}
            setPanelState={setPanelState}
          />
        )}
        {panelState === "battery" && (
          <BatteryStatusPanel
            open={openPanel}
            setPanelState={setPanelState}
            currentCam={currentCam}
          />
        )}
        {panelState === "setting" && (
          <BatterySettingPanel
            open={openPanel}
            setPanelState={setPanelState}
            currentCam={currentCam}
          />
        )}

        {panelState !== "battery" && panelState !== "setting" && (
          <Fab
            size="small"
            variant="rounded"
            className={clsx(classes.fabUXUI, {
              [classes.fabMargin]: openPanel,
            })}
            onClick={() => setOpenPanel((o) => !o)}
          >
            {(theme.direction === "rtl") !== openPanel ? (
              <ArrowLeftIcon />
            ) : (
              <ArrowRightIcon />
            )}
          </Fab>
        )}
      </div>
    );
  }, [
    classes.listPane,
    classes.fabUXUI,
    classes.fabMargin,
    panelState,
    permissions,
    availableNewFws,
    openPanel,
    onRename,
    onDelete,
    onRebootCamera,
    handleFOTA,
    currentCam,
    theme.direction,
  ]);

  const mobileCameraListPanelMarkup = useMemo(() => {
    return (
      <MobileDrawer
        initMode="camera"
        mode="camera"
        onDrawerMode={(mode) => setDrawerMode(mode)}
      >
        <>
          {panelState === "list" && (
            <CameraListPanelUXUI
              {...permissions}
              availableNewFws={availableNewFws}
              open
              mobile
              onUpdateFilteredCams={setFilteredCams}
              onRename={onRename}
              onDelete={onDelete}
              onRebootCamera={onRebootCamera}
              onClick={(cam) => {
                setCurrentCam((c) => (c?.psn === cam?.psn ? c : cam));
                if (cam) {
                  const openEvent = new CustomEvent("drawer:open");
                  document.dispatchEvent(openEvent);
                }
              }}
              onUpdate={handleFOTA}
              onNotification={(cam) => {
                setNotificationCam((c) =>
                  c?.psn === cam?.psn && c?.interval === cam?.interval ? c : cam
                );
                setOpenNotification(true);
              }}
              drawerMode={drawerMode}
              setPanelState={setPanelState}
            />
          )}
          {panelState === "battery" && (
            <BatteryStatusPanel
              mobile
              open={openPanel}
              setPanelState={setPanelState}
              currentCam={currentCam}
            />
          )}
          {panelState === "setting" && (
            <BatterySettingPanel
              mobile
              open={openPanel}
              setPanelState={setPanelState}
              currentCam={currentCam}
            />
          )}
        </>
      </MobileDrawer>
    );
  }, [
    availableNewFws,
    currentCam,
    drawerMode,
    handleFOTA,
    onDelete,
    onRebootCamera,
    onRename,
    openPanel,
    panelState,
    permissions,
  ]);

  // const handleGoogleApiLoaded = useCallback(({ map }: any) => {
  //   setMap(map);
  // }, []);

  const manageBtnMarkup = useMemo(() => {
    if (
      userProfile?.userType === "Master" ||
      // mantis - 10435 모바일에서 admin계정도 관리항목 나오도록 추가 수정 (Leehj) 23.01.30
      userProfile?.userType === "SubMaster" ||
      // mantis - 12187, lite plan 카메라수 출력안되는 이슈 수정 / lite기획서대로 free, smart계정도 출력되도록 수정(Leehj)
      (subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Lite plan") ||
      // free, Smart plan도 The number of Cloud cameras 출력되도록 수정 (Leehj)
      userProfile?.userType === "User" ||
      (subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
          "Smart plan") ||
      (subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Free plan")
    ) {
      const needToCancel =
        subscriptionInfo &&
        subscriptionInfo.servicePlanID >= 4001 &&
        subscriptionInfo.servicePlanID <= 4029;
      return (
        <div className={classes.camCntText}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            {t("n cameras", { n: nReal })} / {t("n slots", { n: nSlot })}
          </Typography>
          {B2BFleetOrPlus &&
            webSubscriptionInfo &&
            !webSubscriptionInfo.upcomming.canceled &&
            // owner에서만 출력되도록 수정 (Leehj)
            userProfile?.userType === "Master" && (
              <>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                  className={classes.dotText}
                >
                  ·
                </Typography>

                <Typography
                  category="Default"
                  variant="Body"
                  onClick={() => {
                    if (needToCancel) {
                      setOpenCancelFleetModal(true);
                    } else {
                      setOpenManageModal(true);
                    }
                  }}
                  color="primary"
                  style={{ cursor: "pointer" }}
                  htmlColor={LightColors.primary["7"]}
                >
                  {t("Manage")}
                </Typography>
              </>
            )}
        </div>
      );
    }
  }, [
    B2BFleetOrPlus,
    classes.camCntText,
    classes.dotText,
    nReal,
    nSlot,
    subscriptionInfo,
    t,
    userProfile?.userType,
    webSubscriptionInfo,
  ]);

  const handleCloseTour = useCallback(() => {
    setStep(-1);

    const tourString = localStorage.getItem("pitta-webviewer-tour");
    const tour = JSON.parse(tourString ?? "[]");
    localStorage.setItem(
      "pitta-webviewer-tour",
      JSON.stringify([...tour, email])
    );
  }, [email]);

  const handleNext = useCallback(() => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
      handleCloseTour();
    }
  }, [handleCloseTour, step]);

  const handleBack = useCallback(() => {
    if (step === 1) {
      setStep(0);
    } else if (step === 2) {
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    } else if (step === 4) {
      setStep(3);
    } else if (step === 5) {
      setStep(4);
    } else if (step === 6) {
      setStep(5);
    } else if (step === 7) {
      setStep(6);
    }
  }, [step]);

  const handleLiveView = useCallback(
    (psn: string, isPublic: boolean) => {
      console.log("onLiveView", psn, isPublic);
      setPublicCam(isPublic);
      if (isPublic) {
        dispatch(loadPublicCamera(psn));
      } else {
        const cam = _.find(liveCams, (dev) => dev.psn === psn);
        if (cam) {
          setLiveCam(cam);
          setOpenLiveModal(true);
          dispatch(setMultipleMap(true));
        }
      }
    },
    [dispatch, liveCams]
  );

  const mapviewMarkup = useMemo(() => {
    return (
      // mantis - 10507 모바일이면서 driver인 경우 여백 조정 (Leehj)
      <div
        className={clsx(classes.mapDiv, {
          [classes.driverMapDiv]: mobile && userProfile?.userType === "User",
        })}
      >
        <MapboxLocationMapWrapper
          mapRef={mapRef}
          geofences={geofences}
          filteredCams={filteredCams}
          onLiveView={handleLiveView}
          focusCam={currentCam}
          requestZoneAllDevice={requestZoneAllDevice}
        />
      </div>
    );
  }, [
    classes.driverMapDiv,
    classes.mapDiv,
    currentCam,
    filteredCams,
    geofences,
    handleLiveView,
    mobile,
    requestZoneAllDevice,
    userProfile?.userType,
  ]);

  const handleUnpairBattery = (camera: ICameraInfo) => {
    setOpenUnpairBattery(true);
    setUnpairBatteryCam(camera);
    setOpenCamMenu(false);
  };

  return (
    //  mantis - 10507 모바일이면서 driver인 경우 간격조정 (Leehj)
    <div
      className={clsx(classes.root, {
        [classes.driverRoot]: mobile && userProfile?.userType === "User",
      })}
      dir={theme.direction}
    >
      {mobile && (
        <div
          className={clsx(classes.subHeaderDiv, {
            [classes.subHeaderMobile]: mobile,
            [classes.subHeaderDriverMobile]:
              mobile && userProfile?.userType === "User",
            [classes.subHeaderDivOpen]: openMenu,
          })}
        >
          {/* mantis - 10507 모바일이면서 driver인 경우 상단 흰 여백 제거 (Leehj) */}
          <div
            className={clsx(classes.subHeader, {
              [classes.driverSubHeader]:
                mobile && userProfile?.userType === "User",
            })}
          >
            <div className={clsx(classes.btnDiv)}>
              {manageBtnMarkup}
              {/* mantis - 10507 모바일일 때 물음표버튼 미노출로 변경 (Leehj) */}
              {!mobile && (
                <Tooltip
                  blueTooltip
                  disableTouchListener={mobile}
                  title={
                    <Typography category="Default" variant="Caption">
                      {t("How to add camera")}
                    </Typography>
                  }
                  placement="bottom"
                >
                  <IconButton
                    style={{ marginInlineStart: 9 }}
                    onClick={() => setOpenGuideModal(true)}
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div
              className={clsx(classes.btnDiv, {
                [classes.directionRow]: mobile,
                [classes.justifyBetween]: mobile && mode === "list",
              })}
            >
              {mode === "list" && (
                <Input
                  placeholder={t("search")}
                  startIcon={<SearchIcon />}
                  className={clsx(classes.searchBtn, {
                    [classes.searchBtnOpen]: mobile && openSearch,
                  })}
                  search
                  dense
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  onOpenSearch={() => setOpenSearch(true)}
                  onCloseSearch={() => {
                    setOpenSearch(false);
                    // mantis - 12714, x 클릭시 검색내용 초기화 적용 (Leehj)
                    setSearchKey("");
                  }}
                  inputID="cameraSearch"
                />
              )}
              <ButtonGroup
                value={mode === "map" ? 0 : 1}
                onChange={handleScreenTypeChange}
                className={clsx(classes.btnGroup, {
                  [classes.hide]: mobile && openSearch,
                })}
              >
                <Button startIcon={<MapIcon />}>{t("Map")}</Button>
                <Button startIcon={<ListIcon />}>{t("List")}</Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      )}
      <div className={classes.body}>
        {mode === "list" && listMarkup}
        {mode === "map" && mapviewMarkup}
        {mode === "map" && !mobile && cameraPanelUXUIMarkup}
        {mode === "map" && mobile && mobileCameraListPanelMarkup}
      </div>

      {openManageModal && (
        <SubMgrModal
          open={openManageModal}
          onClose={() => setOpenManageModal(false)}
          onClickNegative={() => setOpenManageModal(false)}
          onClickPositive={() => {
            setOpenManageModal(false);
          }}
        />
      )}
      <Modal
        open={openCancelFleetModal}
        close
        onClose={() => setOpenCancelFleetModal(false)}
        heading={t("Cancel your inapp_")}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: t("You have cancel_").replace(
                // eslint-disable-next-line no-control-regex
                new RegExp("\n", "g"),
                "<br/>"
              ),
            }}
          />
        }
        RButton={t("OK")}
        actionClassName={classes.modalBottom}
        className={classes.modalWrap}
      />

      {!mobile && (
        <CameraMenu
          {...props}
          open={openCamMenu}
          camera={moreCam}
          anchorRef={anchorRef}
          placement="bottom-end"
          onClickAway={() => setOpenCamMenu(false)}
          // mantis - 10241, driver 계정은 이름 변경 불가하도록 수정 (Leehj)
          onEditProfile={
            renameCamPerm
              ? () => {
                  moreCam && onRename?.(moreCam);
                }
              : null
          }
          onSettings={
            settingsCamPerm
              ? () => {
                  moreCam && history.push(`/cameras/${moreCam.psn}/settings`);
                }
              : null
          }
          onUpdate={
            fotaCamPerm && !_.includes(PSN650, moreCam?.psn.substr(0, 4))
              ? () => {
                  if (moreCam && moreCam.active === "on") {
                    const isNew = !!_.find(
                      availableNewFws,
                      (v, k) => k === moreCam.psn
                    );
                    if (isNew) {
                      history.push(`/cameras/${moreCam.psn}/fota`);
                    } else {
                      dispatch(openToast({ message: "Firmware is up_" }));
                      setOpenCamMenu(false);
                    }
                  }
                }
              : null
          }
          onRebootCamera={
            rebootCamPerm
              ? () => {
                  moreCam && onRebootCamera?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
          onDelete={
            deleteCamPerm
              ? () => {
                  moreCam && onDelete?.(moreCam);
                }
              : null
          }
          onUnpairBattery={handleUnpairBattery}
        />
      )}
      {mobile && (
        <MobileCameraMenu
          {...props}
          open={openCamMenu}
          // mantis - 11300, camera에 해당 카메라를 전달하도록 추가 (Leehj)
          camera={moreCam}
          onClose={() => setOpenCamMenu(false)}
          // mantis - 10241, driver 계정은 이름 변경 불가하도록 수정 (Leehj)
          onEditProfile={
            renameCamPerm
              ? () => {
                  moreCam && onRename?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
          onSettings={
            settingsCamPerm
              ? () => {
                  moreCam && history.push(`/cameras/${moreCam.psn}/settings`);
                }
              : null
          }
          onUpdate={
            fotaCamPerm && !_.includes(PSN650, moreCam?.psn.substr(0, 4))
              ? () => {
                  if (moreCam && moreCam.active === "on") {
                    const isNew = !!_.find(
                      availableNewFws,
                      (v, k) => k === moreCam.psn
                    );
                    if (isNew) {
                      history.push(`/cameras/${moreCam.psn}/fota`);
                    } else {
                      dispatch(openToast({ message: "Firmware is up_" }));
                      setOpenCamMenu(false);
                    }
                  }
                }
              : null
          }
          onRebootCamera={
            rebootCamPerm
              ? () => {
                  moreCam && onRebootCamera?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
          onDelete={
            deleteCamPerm
              ? () => {
                  moreCam && onDelete?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
          onUnpairBattery={handleUnpairBattery}
        />
      )}
      {/* 변경된 디자인 적용 LiveViewModal -> LiveViewMapboxModal*/}
      <LiveViewMapboxModal
        open={openLiveModal}
        camera={liveCam}
        isPublic={publicCam}
        noMargin
        mobile={mobile}
        onClose={() => {
          setOpenLiveModal(false);
          setLiveCam(undefined);
          dispatch(clearPublicCamera());
          dispatch(setMultipleMap(false));
          setRequestZoneAllDevice({});
        }}
        container={mapRef.current}
      />

      {step === 0 && !mobile && (
        <Onboarding
          open={step === 0}
          onStartTour={handleNext}
          onClose={handleCloseTour}
        />
      )}

      {step === 1 && !mobile && (
        <OnboardingStep1
          open={step === 1}
          onNext={handleNext}
          onClose={handleCloseTour}
        />
      )}
      {step === 2 && !mobile && (
        <OnboardingStep2
          open={step === 2}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      {step === 3 && !mobile && (
        <OnboardingStep3
          open={step === 3}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      {step === 4 && !mobile && (
        <OnboardingStep4
          open={step === 4}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      {step === 5 && !mobile && (
        <OnboardingStep5
          open={step === 5}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      {step === 6 && !mobile && (
        <OnboardingStep6
          open={step === 6}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      {step === 7 && !mobile && (
        <OnboardingStep7
          open={step === 7}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      {mobile && step !== -1 && (
        <OnboardingMobile
          step={step}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      <Modal
        open={openInvalidModal}
        onClickPositive={() => {
          setOpenInvalidModal(false);
        }}
        heading={t("To access the_")}
        content={<></>}
        RButton={t("OK")}
        titleClassName={classes.modalTitle}
        contentClassName={classes.modalContent}
        className={classes.modalWrap}
      />
      {/* {step === -1 && (
        <CameraAllowModal
          open={openEventmapModal}
          onClose={handleShareEventmap}
          loading={loadingShareEvent}
        />
      )} */}
      <Modal
        open={openUpgrade}
        close
        mobile={mobile}
        heading={t("Upgrade for more_")}
        content={t("Upgrading the cloud_")}
        RButton={t("Explore Cloud plans")}
        LButton={t("Later")}
        onClickNegative={() => setOpenUpgrade(false)}
        onClickPositive={() => {
          setOpenUpgrade(false);
          setOpenPricing(true);
        }}
        onClose={() => setOpenUpgrade(false)}
      />
      <PricingModal open={openPricing} onClose={() => setOpenPricing(false)} />
      <AddCameraGuideModal
        open={openGuideModal}
        fullsize={mobile}
        onClose={() => setOpenGuideModal(false)}
        mode="cloud"
      />
      <AddCameraGuideModal
        open={openGuideWIFIModal}
        fullsize={mobile}
        onClose={() => setOpenGuideWIFIModal(false)}
        mode="wifi"
      />
      {/* uxui 프로젝트 셋팅  */}
      <NotificationSettingPanel
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        currentCam={notificationCam}
      />
      <UnpairBatteryModal
        open={openUnpairBattery}
        camera={unpairBatteryCam}
        onClose={() => setOpenUnpairBattery(false)}
        onSuccess={() => {
          setOpenUnpairBattery(false);
          dispatch(loadCameras());
        }}
      />
    </div>
  );
};
