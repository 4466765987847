import List from "@material-ui/core/List";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  DashcamEmptyIcon,
  Geofence,
  GPSTracking,
  MapOutLine,
  ScrollBar,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import SimCardIcon from "@material-ui/icons/SimCard";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { genTooltip } from "../utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { USER } from "../features/User/slice";
import { RootState } from "../features/store";
import _ from "lodash";
import { PAYMENT } from "../features/Payment/slice";
import { getPlanFromServiceID } from "../utils/Service";
import { PERMISSION } from "../features/Permission/slice";
import { useMediaQuery } from "@material-ui/core";

import { THEME } from "../features/Theme/slice";
import { LightColors, Webviewer, SIMCARD_URI } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: { flexGrow: 1, overflowY: "auto", overflowX: "hidden" },
  toolbar: {
    display: "flex",
    alignItems: "center",
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    minHeight: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
  toolbarClose: {
    padding: theme.spacing(0, 2.25),
  },
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: (props: any) => props.colors.primary["1"],
  },
  list: {
    width: 233,
  },
  listItemRootClose: {
    margin: theme.spacing(4, 0),
    "&:first-child": {
      marginTop: 0,
    },
  },
  listItemRootOpen: {
    marginBottom: theme.spacing(1),
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  listItemIcon: {
    ...(theme.direction === "rtl" ? { marginRight: 12 } : { marginLeft: 12 }),
    minWidth: 34,
  },
  listItemEventMapIcon: {
    ...(theme.direction === "rtl" ? { marginRight: 8 } : { marginLeft: 8 }),
    minWidth: 37,
  },
  listItemTextRoot: {
    overflow: "hidden",
  },
  menuDashcamIcon: {
    fontSize: 24,
    color: (props: any) => props.colors.primary["1"],
    ...(theme.direction === "rtl"
      ? {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
          marginRight: -3,
        }
      : { marginLeft: -3 }),
  },
  menuIcon: {
    fontSize: "1.125rem",
    color: (props: any) => props.colors.primary["1"],
    ...(theme.direction === "rtl"
      ? {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        }
      : {}),
  },
  menuIcon2: {
    fontSize: "1.5rem",
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
          marginRight: -4,
        }
      : { marginLeft: -4 }),
  },
  listItemDivider: {
    minHeight: 36,
    paddingBottom: 0,
    marginBottom: theme.spacing(1),

    ...(theme.direction === "rtl" ? { paddingRight: 24 } : { paddingLeft: 24 }),
    alignItems: "flex-end",
  },
  eventmapRoot: {
    paddingTop: 2,
    paddingBottom: 2,
    cursor: "pointer",
    "&:hover": {
      "& > * > svg": {
        fill: "transparent!important",
      },
      "& > * > svg> g > path:not(:nth-of-type(4))": {
        stroke: LightColors.primary["7"],
      },
      "& > * > svg > g > path:nth-of-type(4)": {
        fill: LightColors.primary["7"],
      },
    },
    textAlign: theme.direction === "rtl" ? "right" : "left",
  },
  eventmapRootSelected: {
    backgroundColor: "transparent!important",
    "& > * > svg": {
      fill: "transparent!important",
      fontWeight: "500!important",
    },
    "& > * > svg> g > path:not(:nth-of-type(4))": {
      stroke: LightColors.primary["7"],
    },
    "& > * > svg > g > path:nth-of-type(4)": {
      fill: LightColors.primary["7"],
    },
  },
}));

const useListItemStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 2,
    paddingBottom: 2,
    cursor: "pointer",
    "&:hover": {
      "& > *, & > * > svg": {
        color: LightColors.primary["7"],
        fill: LightColors.primary["7"],
      },
    },
    textAlign: theme.direction === "rtl" ? "right" : "left",
    "&.Mui-disabled": {
      "&:hover *": {
        color: LightColors.primary["1"],
        fill: LightColors.primary["1"],
      },
    },
  },
  selected: {
    backgroundColor: "transparent!important",
    "& > * > span *, & > * > svg": {
      color: LightColors.primary["7"],
      fill: LightColors.primary["7"],
      fontWeight: "500!important",
    },
  },
}));

export interface WebviewerMenuListProps {
  open: boolean;
  onClose: () => void;
}

export const WebviewerMenuList = ({
  open,
  onClose,
}: WebviewerMenuListProps) => {
  const listItemClasses = useListItemStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme() as Theme;
  const { pathname } = useLocation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { colors } = useSelector((state: RootState) => state[THEME]);

  const classes = useStyles({ colors });

  const { userProfile, email, loginInfo } = useSelector(
    (state: RootState) => state[USER]
  );
  const { permission } = useSelector((state: RootState) => state[PERMISSION]);
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const getListItem = useCallback(
    ({
      icon,
      title,
      selected,
      disabled,
      subtitle,
      eventMap,
    }: {
      icon: React.ReactNode;
      title: string;
      subtitle?: string;
      selected?: boolean;
      disabled?: boolean;
      eventMap?: boolean;
    }) => {
      const listItem = (
        <ListItem
          dir={theme.direction}
          selected={selected}
          disabled={disabled}
          classes={{
            ...listItemClasses,
            root: clsx(listItemClasses.root, {
              [classes.listItemRootClose]: !open,
              [classes.listItemRootOpen]: open,
              [classes.eventmapRoot]: eventMap,
            }),

            // eventmap 아이콘 hover, selected 스타일 적용 (Leehj) 23.01.11
            selected: clsx(listItemClasses.selected, {
              [classes.eventmapRootSelected]: eventMap,
            }),
          }}
        >
          <ListItemIcon
            classes={{
              root: clsx(classes.listItemIcon, {
                // eventmap 아이콘 hover, selected 스타일 적용 (Leehj) 23.01.11
                [classes.listItemEventMapIcon]: eventMap,
              }),
            }}
          >
            {icon}
          </ListItemIcon>
          {open && (
            <ListItemText
              classes={{ root: classes.listItemTextRoot }}
              primary={
                <>
                  <Typography category="Default" variant="Body">
                    {/* mantis - 10796, 웹뷰어 왼쪽 메뉴 일본어가 아니여도 osd적용 (Leehj) */}
                    {i18n.language === "ja" ? t(title) : t(title)}
                  </Typography>
                  {subtitle && (
                    <>
                      {" "}
                      <span
                        style={{
                          fontFamily: "Georgia",
                          fontSize: 10,
                          fontStyle: "italic",
                          fontWeight: 400,
                          lineHeight: "16px",
                          letterSpacing: 0,
                          textAlign: "left",
                        }}
                      >
                        {i18n.language === "ja" ? t(subtitle) : subtitle}
                      </span>
                    </>
                  )}
                </>
              }
            />
          )}
        </ListItem>
      );
      return open
        ? listItem
        : genTooltip(
            listItem,
            title,
            open,
            open
              ? undefined
              : {
                  offset: { enabled: true, offset: "0px, -170px" },
                  preventOverflow: { enabled: false },
                  flip: { enabled: false },
                }
          );
    },
    [
      theme.direction,
      listItemClasses,
      classes.listItemRootClose,
      classes.listItemRootOpen,
      classes.eventmapRoot,
      classes.eventmapRootSelected,
      classes.listItemIcon,
      classes.listItemEventMapIcon,
      classes.listItemTextRoot,
      open,
      i18n.language,
      t,
    ]
  );

  const renderLink = useCallback(
    (
      title: string,
      path: string,
      icon: React.ReactNode,
      external?: boolean
    ) => {
      let visible = false;
      if (userProfile?.userType === "Master") {
        visible = true;
      } else if (userProfile?.userType === "SubMaster") {
        if (permission) {
          if (path === "/gps-tracking" && permission.gpsTrackingPerm) {
            visible = true;
          }
          if (path === "/geofences" && permission.geofencePerm) {
            visible = true;
          }
          if (path === "/reports" && permission.reportPerm) {
            visible = true;
          }
          if (_.includes(["/members", "/groups"], path)) {
            visible = true;
          }
          // if (_.startsWith(path, SIMCARD_URI)) {
          //   visible = true;
          // }
        }
      } else if (subscriptionInfo?.servicePlanID === 1) {
        visible = true;
      } else if (
        subscriptionInfo &&
        (getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
          "Smart plan" ||
          getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
            "Basic plan" ||
          getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Lite plan")
      ) {
        visible = true;
      }
      if (visible) {
        if (external) {
          return (
            <a
              href={path}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              {getListItem({
                icon,
                title,
                selected: pathname.startsWith(path),
              })}
            </a>
          );
        }
        return (
          <Link
            to={{ pathname: path }}
            className={classes.link}
            onClick={() => mobile && onClose()}
          >
            {getListItem({ icon, title, selected: pathname.startsWith(path) })}
          </Link>
        );
      } else {
        return (
          <Tooltip
            disableTouchListener={mobile}
            title={t("No permission") ?? "No permission"}
          >
            {getListItem({
              icon,
              title,
              selected: pathname.startsWith(path),
              disabled: true,
            })}
          </Tooltip>
        );
      }
    },
    [
      classes.link,
      getListItem,
      mobile,
      onClose,
      pathname,
      permission,
      subscriptionInfo,
      t,
      userProfile?.userType,
    ]
  );

  return (
    <div className={classes.root}>
      <ScrollBar>
        <List className={classes.list}>
          <Link
            to={{ pathname: "/cameras" }}
            className={classes.link}
            onClick={() => mobile && onClose()}
          >
            {getListItem({
              icon: <DashcamEmptyIcon className={classes.menuDashcamIcon} />,
              title: "Cameras",
              selected: pathname.startsWith("/cameras"),
            })}
          </Link>

          <Link
            to={{ pathname: "/eventmap" }}
            className={classes.link}
            onClick={() => mobile && onClose()}
          >
            {getListItem({
              // eventmap 아이콘 수정 (Leehj) 23.01.11
              icon: <MapOutLine className={clsx(classes.menuIcon2)} />,
              title: "Event map",
              selected: pathname === "/eventmap",
              subtitle: "Beta",
              // eventmap 아이콘 hover, selected 스타일 적용 (Leehj) 23.01.11
              eventMap: true,
            })}
          </Link>

          {open && (
            <ListItem
              dir={theme.direction}
              classes={{ ...listItemClasses, root: classes.listItemDivider }}
            >
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Fleet")}
              </Typography>
            </ListItem>
          )}
          {renderLink(
            "GPS tracking",
            "/gps-tracking",
            <GPSTracking className={classes.menuIcon} />
          )}
          {renderLink(
            "Geofences",
            "/geofences",
            <Geofence className={classes.menuIcon} />
          )}

          {renderLink(
            "Reports",
            "/reports",
            <AssignmentIcon className={classes.menuIcon} />
          )}
          {open && (
            <ListItem
              dir={theme.direction}
              classes={{ ...listItemClasses, root: classes.listItemDivider }}
            >
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Management")}
              </Typography>
            </ListItem>
          )}

          {renderLink(
            "Members",
            "/members",
            <PersonIcon className={classes.menuIcon} />
          )}

          {renderLink(
            "Groups",
            "/groups",
            <GroupIcon className={classes.menuIcon} />
          )}
          {renderLink(
            "BlackVue SIM card",
            `${SIMCARD_URI}?email=${email}&user_token=${loginInfo?.user_token}&token_type=web&lang=${i18n.language}`,
            <SimCardIcon className={classes.menuIcon} />,
            true
          )}
        </List>
      </ScrollBar>
    </div>
  );
};
