import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  clearLoading,
  IDriveInfo,
  IDriveInfoOrig,
  IGeofenceAlert,
  IGeofenceAlertList,
  IGeofenceAlertOrig,
  ITrackInfo,
  ITrackInfoOrig,
  // loadCluster,
  loadGeofenceAlert,
  loadGPSDriveData,
  loadGPSTrackingData,
  successLoadGeofenceAlert,
  successLoadGPSDriveData,
  successLoadGPSTrackingData,
} from "./slice";
import { RootState } from "../store";
import { logout, USER } from "../User/slice";
import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import _ from "lodash";
import { getCloudVODFile } from "../../apis";
import { CAMERA } from "../Camera/slice";
import {
  API,
  ILatLngBounds,
  ITrackData,
  IUserLoginInfo,
  RESULT_CODE,
} from "@thingsw/pitta-modules";

// const sample: ICameraCluster = {
//   "zone count": {
//     info: [
//       {
//         lat: "37.288157",
//         lon: "127.005378",
//         count: "100",
//         share_video: "off",
//       },
//       {
//         lat: "37.404511",
//         lon: "127.099034",
//         count: "20",
//         share_video: "on",
//       },
//     ],
//   },
//   myzone: {
//     info: [
//       {
//         lat: "37.741349",
//         lon: "127.064630",
//         count: "100",
//         share_video: "on",
//       },
//       {
//         lat: "37.470197",
//         lon: "126.624838",
//         count: "10",
//         share_video: "off",
//       },
//     ],
//   },
// };

// function* handleLoadCluster() {
//   try {
//     // yield put(successLoadCluster(sample));
//   } catch (err) {
//     console.error(err);
//   }
// }

function* handleLoadGPSDriveData({ payload }: PayloadAction<string>) {
  try {
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { cameraList } = yield select((state: RootState) => state[CAMERA]);
    const email = yield select((state: RootState) => state[USER].email);
    const resp = yield call(API.getGPSDriveData, email, user_token, payload);
    const interval =
      _.find(cameraList.deviceListInfo, (dev) => dev.device.psn === payload)
        ?.device.interval ?? 0;

    const {
      resultcode,
      response: { drive_list },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: { drive_list: IDriveInfoOrig[] };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadGPSDriveData(
          _.chain(drive_list)
            .filter((track) => !!track.cnt)
            .map((track) => {
              let sdate = moment.unix(track.sdate).utc(false);
              let edate: moment.Moment | undefined = undefined;

              if (track.edate !== undefined) {
                edate =
                  track.edate === 0
                    ? moment().add(interval, "minute").utc(false)
                    : moment.unix(track.edate).utc(false);
                if (sdate.isAfter(edate)) {
                  let tmp = edate;
                  edate = sdate;
                  sdate = tmp;
                }
              }
              return {
                ...track,
                sdate,
                edate,
                present: track.edate === 0,
              } as IDriveInfo;
            })
            .sortBy(["edate", "sdate"])
            .reverse()
            .value()
        )
      );
    }
  } catch (err) {
    console.error(err);
    yield put(clearLoading());
  }
}

function* handleLoadGPSTrackingData({
  payload,
}: PayloadAction<{
  psn: string;
  drive_no_list: number[];
  cancel: AbortController;
  bounds?: ILatLngBounds;
}>) {
  try {
    const { tokenType } = yield select((state: RootState) => state[USER]);
    const { user_token, jsonwebtoken } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const email = yield select((state: RootState) => state[USER].email);
    const resp = yield call(
      API.getGPSTrackingData,
      email,
      user_token,
      jsonwebtoken,
      payload.psn,
      payload.drive_no_list,
      payload.cancel,
      payload.bounds
    );

    const data = yield resp.json();
    const { resultcode, response } = data as {
      resultcode: RESULT_CODE;
      response?: { interval: number; count: number; coords: ITrackInfoOrig[] };
    };
    if (resultcode === "BC_ERR_OK" && response) {
      const { interval, coords } = response;
      const tracks = yield Promise.all(
        _.chain(coords)
          .map(async (coord) => {
            const sdate = _.minBy(coord.data, (d) => d.vdate)?.vdate ?? 0;
            const edate = _.maxBy(coord.data, (d) => d.vdate)?.vdate ?? 0;
            const data = await Promise.all(
              _.chain(coord.data)
                .map(async (d, i) => {
                  if (d.mode === "E" || d.mode === "M") {
                    const exists = _.chain(coord.data)
                      .slice(0, i)
                      .find(
                        (item) =>
                          item.loc[0] === d.loc[0] &&
                          item.loc[1] === d.loc[1] &&
                          item.mode === "P"
                      )
                      .value();
                    if (exists) {
                      return undefined;
                    }
                  }

                  let pevents: ITrackData[] | undefined = undefined;

                  if (d.mode === "P") {
                    const nextP =
                      i +
                      1 +
                      _.chain(coord.data)
                        .slice(i + 1)
                        .findIndex((item) => item.mode === "P")
                        .value();

                    // console.log(
                    //   "GPS",
                    //   "d",
                    //   d,
                    //   i + 1,
                    //   nextP,
                    //   _.chain(coord.data)
                    //     .slice(i + 1, nextP)
                    //     .value()
                    // );

                    pevents = await Promise.all(
                      _.chain(coord.data)
                        .slice(i + 1, nextP)
                        .filter(
                          (item) =>
                            // P이후에 발생한 같은 위치에서 발생한 event
                            item.loc[0] === d.loc[0] &&
                            item.loc[1] === d.loc[1] &&
                            (item.mode === "E" || item.mode === "M") &&
                            moment
                              .unix(d.vdate)
                              .isSameOrBefore(moment.unix(item.vdate))
                        )
                        .map(async (item) => {
                          item.hide = true;
                          // console.log("GPS", "\titem", item);
                          // 8584 각 채널별 파일이 제생가능한지 확인
                          let front_playable = !!item.rid_front;
                          if (!front_playable && item.sub_front) {
                            const resp = await getCloudVODFile(
                              email,
                              user_token,
                              payload.psn,
                              item.sub_front,
                              tokenType
                            );
                            front_playable =
                              resp.data.resultcode === "BC_ERR_OK";
                          }

                          let rear_playable = !!item.rid_rear;
                          if (!rear_playable && item.sub_rear) {
                            const resp = await getCloudVODFile(
                              email,
                              user_token,
                              payload.psn,
                              item.sub_rear,
                              tokenType
                            );
                            rear_playable =
                              resp.data.resultcode === "BC_ERR_OK";
                          }

                          let sub3_playable = !!item.rid_3;
                          if (!sub3_playable && item.sub_3) {
                            const resp = await getCloudVODFile(
                              email,
                              user_token,
                              payload.psn,
                              item.sub_3,
                              tokenType
                            );
                            sub3_playable =
                              resp.data.resultcode === "BC_ERR_OK";
                          }
                          return {
                            ...item,
                            front_playable,
                            rear_playable,
                            sub3_playable,
                            sub_front: front_playable
                              ? item.sub_front
                              : undefined,
                            sub_rear: rear_playable ? item.sub_rear : undefined,
                            sub_3: sub3_playable ? item.sub_3 : undefined,
                            vdate: moment.unix(item.vdate).utc(false),
                          };
                        })
                        .value()
                    );
                  }

                  // 8584 각 채널별 파일이 제생가능한지 확인
                  let front_playable = !!d.rid_front;
                  if (!front_playable && d.sub_front) {
                    const resp = await getCloudVODFile(
                      email,
                      user_token,
                      payload.psn,
                      d.sub_front,
                      tokenType
                    );
                    front_playable = resp.data.resultcode === "BC_ERR_OK";
                  }

                  let rear_playable = !!d.rid_rear;
                  if (!rear_playable && d.sub_rear) {
                    const resp = await getCloudVODFile(
                      email,
                      user_token,
                      payload.psn,
                      d.sub_rear,
                      tokenType
                    );
                    rear_playable = resp.data.resultcode === "BC_ERR_OK";
                  }

                  let sub3_playable = !!d.rid_3;
                  if (!sub3_playable && d.sub_3) {
                    const resp = await getCloudVODFile(
                      email,
                      user_token,
                      payload.psn,
                      d.sub_3,
                      tokenType
                    );
                    sub3_playable = resp.data.resultcode === "BC_ERR_OK";
                  }

                  return {
                    ...d,
                    front_playable,
                    rear_playable,
                    sub3_playable,
                    sub_front: front_playable ? d.sub_front : undefined,
                    sub_rear: rear_playable ? d.sub_rear : undefined,
                    sub_3: sub3_playable ? d.sub_3 : undefined,
                    pevents,
                    vdate: moment.unix(d.vdate).utc(false),
                  } as ITrackData;
                })
                .value()
            );

            // 8779 - 위에 map함수가 async로 바뀌면서, compact 함수가 적용안되어,
            // data에 undefined가 포함되어 있는 이슈 수정 (mckim)

            return {
              drive_no: coord.drive_no,
              sdate: moment.unix(sdate).utc(false),
              edate: moment.unix(edate).utc(false),
              data: _.compact(data),
            } as ITrackInfo;
          })
          .value()
      );

      console.log("GPSTracking", interval, tracks);

      yield put(
        successLoadGPSTrackingData({
          interval,
          tracks,
        })
      );
    } else if (resultcode === "401") {
      yield put(logout());
    }
  } catch (err) {
    yield put(clearLoading());
    // console.error(err);
  }
}

function* handleLoadGeofenceAlert({
  payload,
}: PayloadAction<{ psn: string; drive_no_list: number[] }>) {
  try {
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const email = yield select((state: RootState) => state[USER].email);
    const resp = yield call(
      API.getGeofenceAlerts,
      email,
      user_token,
      payload.psn,
      payload.drive_no_list
    );

    const { resultcode, coords } = resp.data as {
      resultcode: RESULT_CODE;
      coords: { drive_no: number; data: IGeofenceAlertOrig[] }[];
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadGeofenceAlert(
          _.chain(coords)
            .map((coord) => {
              return {
                drive_no: coord.drive_no,
                data: _.chain(coord.data)
                  .map((d) => {
                    const latLng = d.Lastloc.split(",");
                    return {
                      latLng: {
                        lat: parseFloat(latLng[0]),
                        lng: parseFloat(latLng[1]),
                      },
                      fenceName: d.fenceName,
                      sendStatus: d.sendStatus,
                      seq: d.seq,
                      time: moment(d.time),
                      speed: d.speed,
                    } as IGeofenceAlert;
                  })
                  .sortBy((d) => d.time)
                  .reverse()
                  .value(),
              } as IGeofenceAlertList;
            })
            .filter((g) => g.data.length > 0)
            .value()
        )
      );
    }
  } catch (err) {
    console.error(err);
  }
}

export function* watchGPS() {
  // yield takeLatest(loadCluster, handleLoadCluster);
  yield takeLatest(loadGPSDriveData, handleLoadGPSDriveData);
  yield takeLatest(loadGPSTrackingData, handleLoadGPSTrackingData);
  yield takeLatest(loadGeofenceAlert, handleLoadGeofenceAlert);
}
