import { Dispatch } from "@reduxjs/toolkit";
import { Tooltip, Typography } from "@thingsw/pitta-design-system";
import axios from "axios";
import React from "react";
import {
  successLoadCameraCluster,
  successLoadCameraLocation,
} from "../features/Camera/slice";
import {
  EVENTMAP_REV_API_SERVER_URI,
  GPS_WS_SERVER_URI,
  ICameraInfo,
  IUserLoginInfo,
  LightColors,
} from "@thingsw/pitta-modules";

export const eventmapAxiosInst = axios.create({
  baseURL: EVENTMAP_REV_API_SERVER_URI,
  // baseURL: "http://localhost:3000/dev",
  timeout: 30 * 1000,
});

export const eventmapJwtAxiosInst = axios.create({
  baseURL: EVENTMAP_REV_API_SERVER_URI,
  // baseURL: "http://localhost:3000/dev",
  timeout: 30 * 1000,
});

export let gpsWS: WebSocket;
export const initGPSWS = (
  email: string,
  loginInfo: IUserLoginInfo,
  dispatch: Dispatch
) => {
  console.log("withViewerTemplate", "create GPS websocket");
  gpsWS = new WebSocket(`${GPS_WS_SERVER_URI}?token=${loginInfo.jsonwebtoken}`);

  gpsWS.onmessage = (ev: any) => {
    const data = JSON.parse(ev.data);
    console.log("withViewerTemplate", "GPS_WS", "onmessage", data);
    if (data.resultcode === "BC_ERR_OK") {
      const camInfos: ICameraInfo[] = data.response.device_list;
      dispatch(successLoadCameraCluster(undefined));
      dispatch(successLoadCameraLocation(camInfos));
    }
  };
  gpsWS.onopen = (ev) => {
    console.log("withViewerTemplate", "GPS_WS", "onopen");
    // GPS WS 생성 시, 내 디바이스 위치가 있는지 확인
    // const msg: GPSMessage = {
    //   action: "gpsPsnsMyDevices",
    //   email,
    //   sequenceId: new Date().getTime(),
    // };
    // gpsWS.send(JSON.stringify(msg));
  };
};

export const genTooltip = (
  element: React.ReactElement,
  title: string,
  open?: boolean,
  modifiers?: object,
  open2?: boolean
) => {
  return open || open2 ? (
    element
  ) : (
    <Tooltip
      title={
        <Typography
          category="Default"
          variant="Caption"
          htmlColor={LightColors.primary["5"]}
        >
          {title}
        </Typography>
      }
      aria-label={title}
      placement="right"
      PopperProps={{ modifiers }}
    >
      {element}
    </Tooltip>
  );
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
