import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";
import clsx from "clsx";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface RemoveCloudSuccessModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  mobileRoot: {
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    width: 303,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container2: {
    width: "fit-content",
    position: "relative",
  },
  before: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 12,
  },
  planBefore: {
    position: "relative",
    width: "100%",
    height: 50,

    backgroundColor: LightColors.primary["0"],

    border: `1px dashed ${LightColors.primary["4"]}`,
    borderRadius: 12,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  planBeforeAbsolute: {
    position: "absolute",
    bottom: 28,
    left: 0,
    right: 0,
  },
  arrow: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  highlight: {
    position: "absolute",
    top: -10,
    left: -6,
  },
  number: {
    width: 35,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: LightColors.primary["8"],
    borderRadius: 12,
    marginInlineEnd: 10,
  },
  title: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    left: 50,
    top: 54,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      left: 53,
      top: 25,
    },
  },
  title2: {
    top: 314,
    flexDirection: "column",
    alignItems: "unset",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: 252,
    },
  },
  titleDiv: {
    padding: "25px 44px 0 24px",
    minHeight: 37,
  },
  step2Title: {
    minHeight: 34,
  },
  contentDiv: {
    padding: "18px 24px 48px",
  },
  step2ContentDiv: {
    padding: "18px 24px 0px",
  },
  mobileContentDiv: {
    borderTop: "none",
    flex: "1 1 auto",
    padding: "14px 24px 0px",
  },
  scrollHidden: {
    overflow: "hidden",
  },
  mobileCloseIcon: {
    left: 9,
  },
  webStep2BtnDiv: {
    marginTop: -6,
  },
  mobileBtnDiv: {
    marginTop: 0,
    marginBottom: 8,
  },
}));

export const RemoveCloudSuccessModal = ({
  open,
  onClose,
}: RemoveCloudSuccessModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  // const { subscriptionInfo } = useSelector(
  //   (state: RootState) => state[PAYMENT]
  // );

  const [step, setStep] = useState(0);

  // useEffect(() => {
  //   if (subscriptionInfo) {
  //     setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
  //   }
  // }, [subscriptionInfo]);

  useEffect(() => {
    if (!open) {
      setStep(0);
    }
  }, [open]);

  // const upgradeBtnMarkup = useMemo(() => {
  //   return (
  //     <Button
  //       disabled
  //       color="primary"
  //       style={{
  //         padding: "4px 10px",
  //         minHeight: 24,
  //         minWidth: 66,
  //         marginInlineStart: 16,
  //         backgroundColor: LightColors.primary["6"],
  //       }}
  //     >
  //       <span
  //         style={{
  //           fontSize: 12,
  //           fontWeight: 500,
  //           color: LightColors.primary["4"],
  //         }}
  //       >
  //         {t("Upgrade")}
  //       </span>
  //     </Button>
  //   );
  // }, [t]);

  return (
    <>
      <Modal
        open={open && step === 0}
        onClose={onClose}
        close={!mobile}
        closeLeft={mobile}
        closeStyle={clsx(mobile && classes.mobileCloseIcon)}
        heading={mobile ? "" : t("Now the camera is removed from cloud slot")}
        titleClassName={classes.titleDiv}
        contentClassName={clsx(classes.contentDiv, {
          [classes.mobileContentDiv]: mobile,
        })}
        actionClassName={clsx({
          [classes.mobileBtnDiv]: mobile,
        })}
        fullSizeSub={mobile}
        content={
          <div className={clsx(classes.root, { [classes.mobileRoot]: mobile })}>
            {mobile && (
              <Typography
                category="Default"
                variant="H4"
                style={{ marginLeft: 4 }}
              >
                {t("Now the camera is removed from cloud slot")}
              </Typography>
            )}

            <img
              src={
                mobile
                  ? "/images/remove-camera-mo.png"
                  : "/images/remove-camera.png"
              }
              style={{ width: "fit-content", marginTop: mobile ? 68 : 0 }}
              alt="remove-camera"
            />

            {/* <div className={classes.container}>
              <div className={classes.before}>
                <RemoveCloudBG style={{ fontSize: 196 }} />
                <div className={classes.arrow}>
                  <img
                    src="/images/remove-cloud-arrow.svg"
                    style={{ display: "block" }}
                    alt="down arrow"
                  />
                </div>
                <div
                  className={clsx(
                    classes.planBefore,
                    classes.planBeforeAbsolute
                  )}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      category="Default"
                      variant="H5"
                      htmlColor={LightColors.primary["2"]}
                      style={{ marginInlineEnd: 8 }}
                    >
                      {planName}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="H4"
                      htmlColor={LightColors.primary["7"]}
                    >
                      {subscriptionInfo?.deviceCnt}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="H4"
                      htmlColor={LightColors.primary["1"]}
                    >
                      /{subscriptionInfo?.cameraLimit}
                    </Typography>
                    {upgradeBtnMarkup}
                  </div>
                </div>
              </div>

              <div className={classes.planBefore}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    category="Default"
                    variant="H5"
                    htmlColor={LightColors.primary["2"]}
                    style={{ marginInlineEnd: 8 }}
                  >
                    {planName}
                  </Typography>
                  <div style={{ position: "relative" }}>
                    <Typography
                      category="Default"
                      variant="H4"
                      htmlColor={LightColors.primary["7"]}
                    >
                      {Math.max(0, (subscriptionInfo?.deviceCnt ?? 0) - 1)}
                    </Typography>
                    <div className={classes.highlight}>
                      <CameraCountHighlight
                        style={{ width: 25, height: 45, display: "block" }}
                      />
                    </div>
                  </div>

                  <Typography
                    category="Default"
                    variant="H4"
                    htmlColor={LightColors.primary["1"]}
                  >
                    /{subscriptionInfo?.cameraLimit}
                  </Typography>
                  {upgradeBtnMarkup}
                </div>
              </div>
            </div> */}
          </div>
        }
        RButton={t("Next")}
        onClickPositive={() => {
          setStep(1);
        }}
      />
      <Modal
        open={open && step === 1}
        onClose={onClose}
        close={!mobile}
        closeLeft={mobile}
        fullSizeSub={mobile}
        closeStyle={clsx(mobile && classes.mobileCloseIcon)}
        titleClassName={clsx(classes.titleDiv, classes.step2Title)}
        contentClassName={clsx(classes.step2ContentDiv, {
          [classes.mobileContentDiv]: mobile,
          [classes.scrollHidden]: mobile,
        })}
        actionClassName={clsx(classes.webStep2BtnDiv, {
          [classes.mobileBtnDiv]: mobile,
        })}
        heading={mobile ? "" : t("How to delete camera list")}
        content={
          <div className={classes.root}>
            <div className={classes.container2}>
              {mobile && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    category="Default"
                    variant="H4"
                    style={{ marginLeft: 4 }}
                  >
                    {t("How to delete camera list")}
                  </Typography>
                </div>
              )}
              <img
                src="/images/how-to-delete-camera-web.jpg"
                style={{
                  marginTop: mobile ? 39 : 0,
                }}
                alt="how to delete camera"
              />
              <div className={classes.title}>
                <div className={classes.number}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    htmlColor={LightColors.primary["0"]}
                  >
                    01
                  </Typography>
                </div>

                <Typography
                  category="Default"
                  variant="BodyBold"
                  htmlColor={LightColors.primary["7"]}
                >
                  {t("Camera quick menu")}
                </Typography>
              </div>
              <div className={clsx(classes.title, classes.title2)}>
                <div className={classes.number}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    htmlColor={LightColors.primary["0"]}
                  >
                    02
                  </Typography>
                </div>

                <Typography
                  category="Default"
                  variant="BodyBold"
                  htmlColor={LightColors.primary["7"]}
                  style={{ marginTop: 4 }}
                >
                  {t("Delete camera")}
                </Typography>
              </div>
            </div>
          </div>
        }
        RButton={t("OK")}
        onClickPositive={onClose}
      />
    </>
  );
};
