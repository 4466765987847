////////////////////////////////////////////////////////////////////////////////////////
// 리액트 기능 임포트
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
// -- 다국어 지원 텍스트 컴포넌트
import { useTranslation } from "react-i18next";
// -- 버튼
import { Button } from "@thingsw/pitta-design-system";
// -- 타이포
import { Typography } from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  requestFota,
  loadCameras,
  clearFwUpdateInfo,
} from "../features/Camera/slice";
import { FwStatusModal } from "../components/FwStatusModal";
import axios from "axios";
import { RootState } from "../features/store";
import _ from "lodash";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import clsx from "clsx";
import { AutoSizer } from "react-virtualized";
import Scrollbars from "react-custom-scrollbars";
import {
  Webviewer,
  IFirmwareInfo,
  ICameraInfo,
  FwAbbr,
} from "@thingsw/pitta-modules";

////////////////////////////////////////////////////////////////////////////////////////
// CSS 스타일
const useStyles = makeStyles((theme: Theme) => ({
  // 1. 루트
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 56px)",
    marginTop: 56,
    overflowY: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflowY: "unset",
    },
  },
  // 모바일 전용 카메라이름 DIV
  camName: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "none",
    },
  },
  // 1-1. 폼
  formDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxWidth: 672,
    marginTop: 24,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      marginLeft: theme.spacing(4),
      padding: 0,
    },
    "& #update": {
      background: "rgba(233, 233, 234, 0.45)",
      marginBottom: theme.spacing(3),
    },
  },
  errorFormDiv: {
    marginTop: theme.spacing(16.875),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: theme.spacing(12.625),
    },
  },
  // 1-1-1. 리스트 DIV
  listDiv: {
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
  listDiv2: {
    boxSizing: "border-box",
    padding: "16px 0px 8px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 16px 8px 16px",
    },
  },
  // 1-1-1-1. ul / li 스타일
  ul: {
    margin: 0,
    padding: "0px 1px",
    "& li": {
      listStylePosition: "outside",
      listStyleType: "none",
      marginTop: theme.spacing(0.625),
      marginBottom: theme.spacing(1),
      textIndent: "4px",
      marginLeft: theme.spacing(1.125),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        textIndent: "2px",
        marginLeft: theme.spacing(1.375),
      },
    },
  },
  // 1-1-1-1-1. li > span 마커 스타일 (들여쓰기)
  liSpan: {
    margin: "0 0 0 -20px",
  },
  // 1-1-1-1-2. li 논마커 스타일 (들여쓰기)
  emptyMark: {
    textIndent: "0px !important",
    marginLeft: "0px !important",
  },
  // 1-1-2.업데이트 버튼
  updateBtn: {
    whiteSpace: "nowrap",
    width: "100%",
    height: "36px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      width: "151px",
    },
  },
  // 1-1-2-1.버튼 텍스트 사이즈 (font 규격 외)
  btnTxt: {
    height: "20px",
  },
}));

interface CameraFirmwareUpdateScreenProps {
  psn: string;
  updateType?: "firmwareAndDMS" | "onlyDMS" | "onlyFirmware";
}

////////////////////////////////////////////////////////////////////////////////////////
// 실제 컴포넌트
export const CameraFirmwareUpdateScreen = (
  props: CameraFirmwareUpdateScreenProps & ScreenDefaultProps
) => {
  const { psn, error } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const cameras = useSelector((state: RootState) => state[CAMERA].cameraList);
  const { firmwares, fotaFailed } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const [isNew, setIsNew] = useState<boolean>();
  const [firmware, setFirmware] = useState<IFirmwareInfo>();
  const [camera, setCamera] = useState<ICameraInfo>();
  const [releaseNote, setReleaseNote] = useState<string>();
  const [requested, setRequested] = useState(false);
  const [fwStatus, setFwStatus] = useState<string>("init");
  const fwUpdateInfo = useSelector(
    (state: RootState) => state[CAMERA].fwUpdateInfos[0]
  );

  useEffect(() => {
    if (fotaFailed) {
      setRequested(false);
      dispatch(clearFwUpdateInfo());
    }
  }, [dispatch, fotaFailed]);

  useEffect(() => {
    return () => {
      dispatch(clearFwUpdateInfo());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fwUpdateInfo) {
      setFwStatus(fwUpdateInfo.update_status);
    }
  }, [dispatch, fwUpdateInfo]);

  useEffect(() => {
    if (fwStatus === "success") {
      dispatch(loadCameras());
    }
  }, [dispatch, fwStatus]);

  useEffect(() => {
    const getReleaseNote = async (fw: IFirmwareInfo) => {
      const url =
        process.env.NODE_ENV === "development"
          ? fw.release_note.replace("http://fota.blackvuecloud.com", "/fota")
          : fw.release_note.replace("http://", "https://");

      const note = await axios.get(url);
      // eslint-disable-next-line no-control-regex
      setReleaseNote(note.data.replace(new RegExp("\n", "g"), "<br/>"));
    };

    if (firmware) {
      getReleaseNote(firmware);
    }
  }, [firmware]);

  useEffect(() => {
    if (cameras) {
      const found = _.find(
        cameras.deviceListInfo,
        (dev) => dev.device.psn === psn
      )?.device;
      setCamera((c) => {
        if (c?.psn !== found?.psn) {
          return found;
        } else if (c?.active !== found?.active || c?.fw_ver !== found?.fw_ver) {
          return found;
        }
        return c;
      });
    }
  }, [psn, cameras]);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    if (camera) {
      const model = FwAbbr[camera.model];
      const fws = _.find(firmwares, (f) => f.model === model);
      if (fws) {
        const info = _.find(fws.info, (info) =>
          _.includes(info.lang, camera.lang)
        );

        if (info) {
          setFirmware(info);
          if (parseFloat(info.version) <= parseFloat(camera.fw_ver)) {
            setIsNew(false);
          } else {
            setIsNew(true);
          }
        }
      }
    }
  }, [camera, firmwares]);

  // 가독성 개선용 - li 태그 함수
  const listTypo = useCallback(
    (_text: string, _value?: string, _mark?: boolean) => {
      // 유형1. (텍스트)
      if (_value === undefined) {
        // 마커 무
        if (_mark === undefined || _mark === false) {
          return (
            <li key={_text} className={classes.emptyMark}>
              {t(_text)}
            </li>
          );
        }
        // 마커 유
        else {
          return (
            <li key={`${_text}-marker`}>
              <span className={classes.liSpan}>ㆍ</span>
              {t(_text)}
            </li>
          );
        }
      }
      // 유형2. (텍스트 : 값)
      else {
        // 마커 무
        if (_mark === undefined || _mark === false) {
          return (
            <li key={`${_text}-${_value}`} className={classes.emptyMark}>
              {t(_text)}: {t(_value)}
            </li>
          );
        }
        // 마커 유
        else {
          return (
            <li key={`${_text}-${_value}-marker`}>
              <span className={classes.liSpan}>ㆍ</span>
              {t(_text)}: {t(_value)}
            </li>
          );
        }
      }
    },
    [classes.emptyMark, classes.liSpan, t]
  );

  // 가독성 개선용 - 리스트 생성 함수
  const listCreate = useCallback(
    (_title: string, ...list: any[]) => {
      return [
        <Typography
          key={`list-title-${_title}`}
          category="Default"
          variant="H6"
        >
          {t(_title)}
        </Typography>,
        <Typography
          key={`list-item-${_title}`}
          category="Default"
          variant="Body"
        >
          <ul className={classes.ul}>{list}</ul>
        </Typography>,
      ];
    },
    [classes.ul, t]
  );

  const handleUpdate = useCallback(() => {
    if (camera && firmware) {
      setRequested(true);
      dispatch(
        requestFota({
          psn: camera.psn,
          fw_model: camera.model,
          now_fw_version: camera.fw_ver,
          new_fw_version: firmware.version,
          fota_url: firmware.file,
          file_size: firmware.file_size,
          lang: camera.lang ?? "English",
          checksum: firmware.md5,
        })
      );
    }
  }, [camera, dispatch, firmware]);

  const fwMarkup = useMemo(() => {
    return (
      <div className={clsx(classes.formDiv, error && classes.errorFormDiv)}>
        <div className={classes.listDiv} id="update">
          {isNew !== undefined && (
            <>
              {listCreate(
                isNew ? "New firmware available" : "Firmware is up_",
                listTypo("Version", firmware?.version, true),
                listTypo("Release date", firmware?.release_date, true),
                listTypo("Language", camera?.lang, true),
                listTypo(
                  "File size",
                  `${_.round((firmware?.file_size ?? 0) / 1024 / 1024, 3)} KB`,
                  true
                )
              )}
              {isNew && (
                <Button
                  className={classes.updateBtn}
                  size="medium"
                  color="primary"
                  onClick={handleUpdate}
                  disabled={
                    fwUpdateInfo?.update_status === "init" ||
                    fwUpdateInfo?.update_status === "ready" ||
                    fwUpdateInfo?.update_status === "progress" ||
                    fwUpdateInfo?.update_status === "complete" ||
                    fwUpdateInfo?.update_status === "success"
                  }
                >
                  <Typography category="Default" variant="Body">
                    {t("Update firmware")}
                  </Typography>
                </Button>
              )}
            </>
          )}
        </div>
        {/* 2. 일반 정보*/}
        <div className={classes.listDiv2}>
          {listCreate(
            "Note",
            listTypo("Updating may take several minutes", undefined, true),
            listTypo("The update process cannot be canceled", undefined, true),
            listTypo(
              "Do not disconnect the power cable until normal recording restarts",
              undefined,
              true
            )
          )}
        </div>
        {/* 3. 일반 정보*/}
        <div className={classes.listDiv2}>
          <Typography category="Default" variant="H6">
            {t("What's new")}
          </Typography>
          <br />
          <Typography
            category="Default"
            variant={"Body"}
            dangerouslySetInnerHTML={{ __html: releaseNote ?? "" }}
          ></Typography>
        </div>
      </div>
    );
  }, [
    camera?.lang,
    classes.errorFormDiv,
    classes.formDiv,
    classes.listDiv,
    classes.listDiv2,
    classes.updateBtn,
    error,
    firmware?.file_size,
    firmware?.release_date,
    firmware?.version,
    fwUpdateInfo?.update_status,
    handleUpdate,
    isNew,
    listCreate,
    listTypo,
    releaseNote,
    t,
  ]);

  return (
    <div className={classes.root} dir={theme.direction}>
      {mobile ? (
        fwMarkup
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <Scrollbars
              style={{ width, height }}
              autoHide
              renderView={(props) => (
                <div
                  {...props}
                  style={{
                    marginLeft: theme.direction === "rtl" ? -17 : 0,
                    marginRight: theme.direction === "rtl" ? 0 : -17,
                    inset: 0,
                    position: "absolute",
                    overflow: "scroll",
                    marginBottom: -15,
                  }}
                />
              )}
            >
              {fwMarkup}
            </Scrollbars>
          )}
        </AutoSizer>
      )}

      {camera && requested && firmware && (
        <FwStatusModal camera={camera} firmware={firmware} />
      )}
    </div>
  );
};
