import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alerts,
  Button,
  Container,
  Typography,
} from "@thingsw/pitta-design-system";

import { useDispatch, useSelector } from "react-redux";
import { submit } from "redux-form";
import { makeStyles, Theme } from "@material-ui/core";

import SendMailForm from "../forms/SendMailForm";
import { MinimalFooter } from "../components/MinimalFooter";
import { ResetHeader } from "../components/ResetHeader";
import { USER, sendResetMail } from "../features/User/slice";
import { RootState } from "../features/store";
import { useLocation } from "react-router-dom";
import { clearError, ERROR } from "../features/Error/slice";
import {
  LightColors,
  Webviewer,
  ERROR_TIMEOUT,
  ISendMailForm,
  MobileLang,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 58px)",
    // paddingTop: 114,
    alignItems: "center",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 438,
    flexGrow: 1,
    // marginTop: 90,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "288px !important",
      padding: 0,
      marginTop: 0,
    },
    // padding: theme.spacing(3, 1, 0, 1),
    padding: theme.spacing(3, 2, 0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.25, 2, 0, 2),
    },
  },
  marginB3: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
      fontSize: "28px !important",
    },
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
  errorDiv: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
}));

export interface IPasswordResetScreenState {
  sendMail?: boolean;
}
// const schema = yup.object().shape({
//   email: yup.string().email("Enter a valid email").required("Enter email"),
// });

export const PasswordResetScreen = () => {
  const [mainHeader, setMainHeader] = useState("Reset password");
  const [sendMail, setSendMail] = useState(false);
  const [mailAddress, setMailAddress] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const location = useLocation<IPasswordResetScreenState>();

  const { loading } = useSelector((state: RootState) => state[USER]);
  const { error } = useSelector((state: RootState) => state[ERROR]);

  useEffect(() => {
    setMainHeader(
      location.state?.sendMail ? "Check your email" : "Reset password"
    );
    setSendMail(location.state?.sendMail ?? false);
  }, [location.state]);

  const errorMessage = useMemo(
    () => error === "There isn’t an_" || error === "Please return to_",
    [error]
  );

  useEffect(() => {
    if (error && !errorMessage) {
      const timerId = setTimeout(() => {
        dispatch(clearError());
      }, ERROR_TIMEOUT);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [dispatch, error, errorMessage]);

  const handleSendMail = () => {
    dispatch(submit("MailForm"));
  };

  const handleResetMailSubmit = async (reset: ISendMailForm) => {
    setMailAddress(reset.email);
    const osLang = navigator.language.toLowerCase().substring(0, 2);
    dispatch(
      sendResetMail({
        email: reset.email,
        lang: MobileLang[osLang],
      })
    );
  };

  return (
    <React.Fragment>
      <ResetHeader />
      {/* Body */}
      <div className={classes.root}>
        <Container className={classes.body}>
          {error && !errorMessage && (
            <div className={classes.errorDiv}>
              <Alerts mode="web" severity="error">
                {t(error)}
              </Alerts>
            </div>
          )}
          <div
            className={classes.formDiv}
            style={{
              maxWidth: sendMail ? 555 : 438,
            }}
          >
            <Typography
              category="Default"
              variant="H1"
              className={classes.marginB3}
            >
              {t(mainHeader)}
            </Typography>
            {sendMail ? (
              <div className={classes.marginB4}>
                <Typography category="Default" variant="Body">
                  {t("Password Reset_", { a: `${mailAddress}` })}
                  {/* We've sent an email to {mailAddress} with password reset
                  instructions. */}
                </Typography>
              </div>
            ) : (
              <div>
                <div className={classes.marginB4}>
                  <Typography category="Default" variant="Body">
                    {t("Enter email address_")}
                  </Typography>
                </div>
                <div>
                  <SendMailForm onSubmit={handleResetMailSubmit} />
                </div>
                <div>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={handleSendMail}
                    loading={loading}
                  >
                    {t("Send reset password email")}
                  </Button>
                </div>
              </div>
            )}
          </div>
          <MinimalFooter />
        </Container>
      </div>
    </React.Fragment>
  );
};
