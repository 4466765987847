import React, { useState, useEffect } from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

export interface Status {
  openModal: boolean;
  onClose: () => void;
  onClickPositive: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    width: 560,
    minHeight: 180,
    [theme.breakpoints.up("sm")]: {},
    "& .modalTitle": {
      color: "#0095E0",
      marginTop: 0,
      marginBottom: 4,
    },
    "& .modalPaging": {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      marginTop : 24,
    },
    "& a": {
      color: "#0095E0",
      fontSize: "1rem",
      fontWeight: "600",
    },
  },
  modalHeading: {
    padding: "16px 42px 0px 24px",
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
    minHeight: 24,
    [theme.breakpoints.up("sm")]: {},
  },
  headingTextIcon: {
    padding: 0,
  },
  modalContent: {
    padding: "14px 24px 32px",
    // overflow: "hidden",
    whiteSpace: "pre-line",
    "& .modalContImg": {
      width: "100%",
      aspectRatio: "1/0.56",
      marginTop: 24,
      "& svg": {
        display: "block",
      },
    },
    [theme.breakpoints.up("sm")]: {},
  },

  modalButton: {
    padding: "0px 24px auto",
    [theme.breakpoints.down("sm")]: {
      "& :first-child": {
        width: "100%",
      },
    },    
  },

  modalClose: {
    top: 12,
    
    // id - 166
    // 아랍어 시 direction 적용
    ...(theme.direction === "rtl" ? { left: 13 } : { right: 13 }),
    [theme.breakpoints.up("sm")]: {},
  },

  LBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
  },

  RBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
    width: "100%",
  },
}));

export const PriceDownModal = (props: Status) => {
  const { openModal, onClose, onClickPositive } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if(openModal) setPage(1);
  },[openModal]);

  return (
    <Modal
      className={classes.modalBody}
      open={openModal}
      close
      closeStyle={classes.modalClose}
      onClose={onClose}
      titleClassName={classes.modalHeading}
      heading={""}
      headingIcon={<ReactSVG src="/images/iconPriorityHighOutline.svg" />}
      content={
        <>
          {page === 1 && (
            <>
              <p className="modalTitle">
                <Typography
                  category="Default"
                  variant="H4"
                >
                  {t("Smart Plan Subscriber?")}
                </Typography>
              </p>
              <Typography
                category="Default"
                variant="Small"
              >
                {t("The new reduced price_")}
              </Typography>
              <div 
                className="modalContImg"
                style={{ marginTop: 12, background: "url(/images/pricing-modal-dwonSmart.png) no-repeat center/contain" }}
              >
              </div>
              <Typography
                category="Default"
                variant="Small"
                dangerouslySetInnerHTML={{
                  __html: t("No action needed_")
                }}
              />
            </>
          )}
          {page === 2 && (
            <>
              <p className="modalTitle">
                <Typography
                  category="Default"
                  variant="H4"
                >
                  {t("Fleet Plan Subscriber?")}
                </Typography>
              </p>
              <Typography
                category="Default"
                variant="Small"
              >
                {t("Please take a moment to_")}
              </Typography>
              <div 
                className="modalContImg"
                style={{ marginTop: 12, background: "url(/images/pricing-modal-downFleet.png) no-repeat center/contain" }}
              >
              </div>
              <Typography
                category="Default"
                variant="Small"
                dangerouslySetInnerHTML={{
                  __html: t("Starting from now and_")
                }}
              />
            </>
          )}
          <div className="modalPaging">
            <Typography
              category="Default"
              variant="Body"
            >
              {page} / 2
            </Typography>
          </div>
        </>
      }
      contentClassName={classes.modalContent}
      RButton={page === 1 ? t("Next") : t("OK")}
      onClickPositive={() => {
        page === 1 ? setPage(2) : onClickPositive()
      }}
      onClickNegative={onClose}
      actionClassName={classes.modalButton}
      Secondary={false}
      RButtonClassName={classes.RBtn}
    />
  );
};
