import { MODEL_KEY, BOXPRO_EXTEND_MODELS } from "@thingsw/pitta-modules";

const ableExtendBoxPro = (model?: string, fw_ver?: string) => {
  const isAbleExtendBoxPro = () => {
    if (!model || !fw_ver) {
      return false;
    }

    if (BOXPRO_EXTEND_MODELS.hasOwnProperty(model)) {
      const supportedVersion = BOXPRO_EXTEND_MODELS[model as MODEL_KEY];

      if (supportedVersion && fw_ver >= supportedVersion) {
        return true;
      }
    }

    return false;
  };

  return isAbleExtendBoxPro();
};

export default ableExtendBoxPro;
