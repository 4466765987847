"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProgressSlider = void 0;
var _styles = require("@material-ui/core/styles");
var _Slider = _interopRequireDefault(require("@material-ui/core/Slider"));
var _pittaModules = require("@thingsw/pitta-modules");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ProgressSlider = (0, _styles.withStyles)(function (theme) {
  return {
    root: {
      padding: 0,
      height: 4
    },
    rail: {
      color: "rgba(255, 255,255, 0.35)",
      height: 4
    },
    track: {
      color: _pittaModules.LightColors.primary["8"],
      height: 4
    },
    thumb: {
      color: _pittaModules.LightColors.primary["8"],
      marginTop: -4
    }
  };
})(_Slider.default);
exports.ProgressSlider = ProgressSlider;