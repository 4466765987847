import { detect } from "detect-browser";
import React, { useCallback } from "react";
import { SubscriptionPanel } from "../../components/accounts/SubscriptionPanel";

export const SubscriptionScreen = () => {
  //@ts-ignore
  window.backPressed = useCallback(() => {
    const browser = detect();
    if (browser?.name === "ios-webview") {
      //@ts-ignore
      return webkit.messageHandlers.back.postMessage({});
    }
    if (browser?.name === "chromium-webview") {
      //@ts-ignore
      window.Webviewer?.back();
    }
  }, []);

  return <SubscriptionPanel />;
};
