import {
  Divider,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import withCameraTemplate from "../hoc/withCameraTemplate";

import SecurityIcon from "@material-ui/icons/Security";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { PermissionProps, ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../screens/CameraPlaybackScreen";
import { CameraPrivacySettingScreen } from "../screens/settings/CameraPrivacySettingScreen";
import {
  WebMenuItem,
  MobileMenu,
  Typography,
  SettingsIcon,
  DMSIcon,
  VideoIcon,
  WifiConnected,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import { useSelector } from "react-redux";
import { CAMERA } from "../features/Camera/slice";
import { RootState } from "../features/store";
import { CameraVideoSettingScreen } from "../screens/settings/CameraVideoSettingScreen";
import { CameraRecordingModeSettingScreen } from "../screens/settings/CameraRecordingModeSettingScreen";
import { CameraSystemSettingScreen } from "../screens/settings/CameraSystemSettingScreen";
import { CameraEventTriggersSettingScreen } from "../screens/settings/CameraEventTriggersSettingScreen";
import { CameraDMSSettingScreen } from "../screens/settings/CameraDMSSettingScreen";
import { CameraCameraConnectivitySettingScreen } from "../screens/settings/CameraCameraConnectivitySettingScreen";

import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";
import Scrollbars from "react-custom-scrollbars";
import { AutoSizer } from "react-virtualized";
import {
  Webviewer,
  LightColors,
  ICameraInfo,
  MODEL_KEY,
  CAMERA_SETTING_UNSUPPORTED,
  PSN650,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0",
    // 하단의 배경 회색이 아닌 흰 공간 생기는 이슈로 height: "100%" 추가
    height: "100%",
    visible: "hidden",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      padding: "0 0 0 32px",
    },
  },
  appDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 21,
    paddingTop: 3,

    "&:last-child": {
      marginBottom: 0,
    },
  },
  anchor: {
    color: "inherit",
    "& a": {
      color: "inherit",
    },
    "&:hover": {
      cursor: "pointer",
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      "& svg>svg>path": {
        stroke: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
    "&:active": {
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
  },
  anchorNonStrocke: {
    color: "inherit",
    "& a": {
      color: "inherit",
    },
    "&:hover": {
      cursor: "pointer",
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      "& svg>svg>path": {
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
    "&:active": {
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
  },
  anchorNonFill: {
    color: "inherit",
    "& a": {
      color: "inherit",
    },
    "&:hover": {
      cursor: "pointer",
      "& svg": {
        color: LightColors.primary["8"],
      },
      "& svg>svg>g>path": {
        stroke: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
    "&:active": {
      "& svg": {
        color: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
  },
  anchorNonHover: {
    color: "inherit",
    "& a": {
      color: "inherit",
    },
    "&:hover": {
      cursor: "pointer",
      "& svg": {
        color: LightColors.primary["8"],
      },
      "& svg>svg>g>path": {
        stroke: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
    "&:active": {
      "& svg": {
        color: LightColors.primary["8"],
        stroke: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
  },
  mobmenu: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    margin: "16px 0 0 0",
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 10,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
      margin: "19px 0 24px 0",
    },
  },
  bgColor: {
    backgroundColor: LightColors.primary["0"],
  },
  Pdnone: {
    padding: 0,
    marginBottom: 1,
    minHeight: 36,
    alignItems: "center",
  },
  menuIcon: {
    fontSize: 24,
    color: LightColors.primary["2"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      fontSize: 24,
      color: LightColors.primary["2"],
      // padding: 3,
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(1) }
        : { marginRight: theme.spacing(1) }),
    },
  },

  disabled: {
    color: LightColors.primary["1"],
    opacity: 0.5,
    "&:hover": {
      color: LightColors.primary["1"],
      opacity: 0.5,
      cursor: "default",
      "& svg": {
        color: LightColors.primary["1"],
        fill: LightColors.primary["1"],
        opacity: 0.5,
      },
    },
  },

  check7: {
    color: LightColors.primary["7"],
  },
  iconMenu: {
    width: 202,
    marginTop: 24,
    ...(theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }),
  },

  menuActive: {
    color: LightColors.primary["7"],
    "&>svg": {
      color: LightColors.primary["7"],
    },
    "&>svg>svg>path": {
      stroke: `${LightColors.primary["7"]} !important`,
    },
    "& svg>svg>path": {
      stroke: `${LightColors.primary["7"]} !important`,
    },
    "&>svg>svg>g>path": {
      stroke: `${LightColors.primary["7"]} !important`,
    },
    "& svg>svg>g>path": {
      stroke: `${LightColors.primary["7"]} !important`,
    },
  },

  menuActiveNonFill: {
    color: LightColors.primary["7"],
    "&>svg": {
      color: LightColors.primary["7"],
    },
    "&>svg>svg>g>path": {
      stroke: `${LightColors.primary["7"]} !important`,
    },
    "& svg>svg>g>path": {
      stroke: `${LightColors.primary["7"]} !important`,
    },
  },
  menuActiveFill: {
    color: LightColors.primary["7"],
    "&>svg": {
      color: LightColors.primary["7"],
    },
    "&>svg>svg>path": {
      fill: `${LightColors.primary["7"]} !important`,
    },
    "&>svg>svg>g>path": {
      fill: `${LightColors.primary["7"]} !important`,
    },
    "& svg>svg>path": {
      fill: `${LightColors.primary["7"]} !important`,
    },
    "& svg>svg>g>path": {
      fill: `${LightColors.primary["7"]} !important`,
    },
  },

  privacyMenu: {
    marginBottom: 34,
    paddingTop: 3,
  },

  mobilePaper: {
    borderRadius: "16px 16px 0px 0px",
  },
  mobileMenu: {
    padding: "26px 16px 22px",
  },
  mobileMenuTitle: {
    padding: "2px 16px",
  },
  mobileTitle: {
    color: "#68686E !important",
  },
  mobileMenuDivider: {
    margin: "11px -16px 24px",
  },
  mobileMenuItem: {
    paddingBottom: "18px !important",
  },
  mobileMenuIcon: {
    marginRight: 8,
  },
}));

export const CameraSettingRouter = withCameraTemplate(
  (props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps) => {
    const {
      privacyPerm,
      pushNotificationsPerm,
      eventAutoUploadPerm,
      firmwareSettingPerm,
      shareToEventmapPerm,
      ...permissions
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const theme = useTheme() as Theme;
    const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
    const match = useRouteMatch();
    const anchorRef = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState(false);
    // const { settingsCamPerm } = permissions;

    const [cameraInfo, setCameraInfo] = useState<ICameraInfo>();

    const { camera, cameraList, firmwareConfig } = useSelector(
      (state: RootState) => state[CAMERA]
    );

    const DMSsettings = useMemo(() => {
      return firmwareConfig?.DMS;
    }, [firmwareConfig?.DMS]);

    const menu = useMemo(() => {
      if (location.pathname.endsWith("/video")) {
        return 1;
      }
      if (location.pathname.endsWith("/recording-mode")) {
        return 2;
      }
      if (location.pathname.endsWith("/system")) {
        return 3;
      }
      if (location.pathname.endsWith("/event-triggers")) {
        return 4;
      }
      if (location.pathname.endsWith("/dms")) {
        return 5;
      }
      if (location.pathname.endsWith("/camera-connectivity")) {
        return 6;
      }
      return 0;
    }, [location.pathname]);

    useEffect(() => {
      const cm = _.chain(cameraList?.deviceListInfo)
        .map((c) => c.device)
        .find((c) => c.psn === camera?.psn)
        .value();
      setCameraInfo((c) => {
        if (c?.psn !== cm?.psn) {
          return cm;
        } else if (c?.active !== cm?.active) {
          return cm;
        } else if (
          (!c?.latitude && cm?.latitude) ||
          (!c?.longitude && cm?.latitude)
        ) {
          return cm;
        }
        return c;
      });
    }, [camera?.psn, cameraList?.deviceListInfo]);

    const handleToggle = useCallback(() => {
      setOpen((prevOpen) => !prevOpen);
    }, []);

    const newLocal = useMemo(() => {
      switch (menu) {
        case 0:
          return (
            <SecurityIcon
              style={{
                padding: 3,
                marginRight: 4,
                fill: LightColors.primary["2"],
              }}
            />
          );
        case 1:
          return <VideoIcon style={{ marginRight: 4 }} />;
        case 2:
          return (
            <RadioButtonCheckedRoundedIcon
              style={{
                padding: 3,
                marginRight: 4,
                fill: LightColors.primary["2"],
              }}
            />
          );
        case 4:
          return (
            <StarOutlineRoundedIcon
              style={{ marginRight: 4, fill: LightColors.primary["2"] }}
            />
          );
        case 5:
          return (
            <>
              {DMSsettings && (
                <DMSIcon
                  style={{
                    marginRight: 4,
                    fill: LightColors.primary["2"],
                  }}
                />
              )}
            </>
          );
        case 6:
          return (
            <WifiConnected
              style={{ marginRight: 4, fill: LightColors.primary["2"] }}
            />
          );
        default:
          return <SettingsIcon style={{ marginRight: 4 }} />;
      }
    }, [DMSsettings, menu]);

    // 미지원 모델 Live Event Upload / Push notifications / Firmware 메뉴 disable 처리 (7103)
    const menuDisabled = useMemo(() => {
      let disable = false;
      if (cameraInfo) {
        const model = cameraInfo.model as MODEL_KEY;
        const version = CAMERA_SETTING_UNSUPPORTED[model];
        if (version) {
          disable = version >= parseFloat(cameraInfo.fw_ver);
        }
      }
      return disable || _.includes(PSN650, cameraInfo?.psn.substr(0, 4));
    }, [cameraInfo]);

    const routerMarkup = useMemo(() => {
      return (
        <Switch>
          <Route
            exact
            path={match.path}
            render={() => (
              <CameraPrivacySettingScreen
                {...props}
                {...permissions}
                mode="settings"
                shareToEventmapPerm={shareToEventmapPerm}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/video`}
            render={() => (
              <CameraVideoSettingScreen
                {...props}
                mode="settings"
                // discard모달에서 Yes버튼 클릭시 privacy setting페이지로 이동
                onClose={() => history.push(`${match.url}`)}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/recording-mode`}
            render={() => (
              <CameraRecordingModeSettingScreen {...props} mode="settings" />
            )}
          />
          <Route
            exact
            path={`${match.path}/system`}
            render={() => (
              <CameraSystemSettingScreen
                {...props}
                mode="settings"
                // discard모달에서 Yes버튼 클릭시 privacy setting페이지로 이동
                onClose={() => history.push(`${match.url}`)}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/event-triggers`}
            render={() => (
              <CameraEventTriggersSettingScreen {...props} mode="settings" />
            )}
          />
          {DMSsettings && (
            <Route
              exact
              path={`${match.path}/dms`}
              render={() => (
                <CameraDMSSettingScreen {...props} mode="settings" />
              )}
            />
          )}

          <Route
            exact
            path={`${match.path}/camera-connectivity`}
            render={() => (
              <CameraCameraConnectivitySettingScreen
                {...props}
                mode="settings"
              />
            )}
          />
        </Switch>
      );
    }, [
      DMSsettings,
      history,
      match.path,
      match.url,
      permissions,
      props,
      shareToEventmapPerm,
    ]);

    return (
      // mantis :  8831 - KimSeongHyun
      // 탭 이동시 스크롤 최상위로 이동
      <div className={classes.root}>
        <div>
          {mobile && (
            <div ref={anchorRef} className={classes.mobmenu}>
              <WebMenuItem
                setting
                classes={{ root: clsx(classes.bgColor, classes.Pdnone) }}
                onClick={handleToggle}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                startIcon={newLocal}
                endIcon={<ExpandMoreIcon />}
              >
                {menu === 0 && t("Privacy")}
                {menu === 1 && t("Video")}
                {menu === 2 && t("Recording mode")}
                {menu === 3 && t("System")}
                {menu === 4 && t("Event triggers")}
                {/* mantis - 11125, Driver Monitoring System (DMS)-> DMS 로 문구 수정 (Leehj) */}
                {menu === 5 && DMSsettings && "DMS"}
                {menu === 6 && t("Camera connectivity")}
              </WebMenuItem>
              <MobileMenu
                open={open}
                onClose={() => setOpen(false)}
                classes={{
                  root: clsx(classes.mobileMenu),
                }}
                paperClasses={classes.mobilePaper}
              >
                <div className={classes.mobileMenuTitle}>
                  <Typography
                    category="Default"
                    variant="H6"
                    className={classes.mobileTitle}
                  >
                    {t("Setting menu")}
                  </Typography>
                </div>
                <Divider className={classes.mobileMenuDivider} />
                <WebMenuItem
                  onClick={() => {
                    history.push(`${match.url}`);
                    setOpen(false);
                  }}
                  classes={{
                    root: clsx(classes.bgColor, classes.mobileMenuIcon),
                  }}
                  className={clsx(classes.mobileMenuItem, {
                    [classes.menuActive]: menu === 0,
                    [classes.disabled]: !privacyPerm,
                  })}
                  startIcon={
                    <SecurityIcon
                      className={clsx(classes.menuIcon, {
                        [classes.menuActive]: menu === 0,
                      })}
                      style={{ marginRight: 8 }}
                    />
                  }
                >
                  {t("Privacy")}
                </WebMenuItem>
                <WebMenuItem
                  disabled={
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !eventAutoUploadPerm ||
                    menuDisabled
                  }
                  onClick={() => {
                    history.push(`${match.url}/video`);
                    setOpen(false);
                  }}
                  classes={{
                    root: clsx(classes.bgColor, classes.mobileMenuIcon),
                  }}
                  className={clsx({
                    [classes.menuActive]: menu === 1,
                  })}
                  startIcon={
                    <VideoIcon
                      className={clsx(classes.menuIcon, {
                        [classes.menuActive]: menu === 1,
                      })}
                      style={{ marginRight: 8 }}
                    />
                  }
                >
                  {t("Video")}
                </WebMenuItem>
                <WebMenuItem
                  // mantis - 11379, 클라우드 연결한적 없는 경우 접근 불가하도록 비활성화. 주석 해제(Leehj)
                  disabled={
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !firmwareSettingPerm ||
                    menuDisabled
                  }
                  onClick={() => {
                    history.push(`${match.url}/recording-mode`);
                    setOpen(false);
                  }}
                  classes={{
                    root: clsx(classes.bgColor, classes.mobileMenuIcon),
                  }}
                  className={clsx({
                    [classes.menuActive]: menu === 2,
                  })}
                  startIcon={
                    <RadioButtonCheckedRoundedIcon
                      className={clsx(classes.menuIcon, {
                        [classes.menuActive]: menu === 2,
                      })}
                      style={{ marginRight: 8 }}
                    />
                  }
                >
                  {t("Recording mode")}
                </WebMenuItem>
                <WebMenuItem
                  disabled={
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !firmwareSettingPerm ||
                    menuDisabled
                  }
                  onClick={() => {
                    history.push(`${match.url}/system`);
                    setOpen(false);
                  }}
                  classes={{
                    root: clsx(classes.bgColor, classes.mobileMenuIcon),
                  }}
                  className={clsx({
                    [classes.menuActiveNonFill]: menu === 3,
                  })}
                  startIcon={
                    <SettingsIcon
                      className={clsx(classes.menuIcon, {
                        [classes.menuActiveNonFill]: menu === 3,
                      })}
                      style={{ marginRight: 8 }}
                    />
                  }
                  endIcon={
                    cameraInfo?.require_time_sync && (
                      <div
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: 4,
                          backgroundColor: LightColors.secondary["11"],
                        }}
                      />
                    )
                  }
                >
                  {t("System")}
                </WebMenuItem>
                <WebMenuItem
                  disabled={
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !firmwareSettingPerm ||
                    menuDisabled
                  }
                  onClick={() => {
                    history.push(`${match.url}/event-triggers`);
                    setOpen(false);
                  }}
                  classes={{
                    root: clsx(classes.bgColor, classes.mobileMenuIcon),
                  }}
                  className={clsx({
                    [classes.menuActive]: menu === 4,
                  })}
                  startIcon={
                    <StarOutlineRoundedIcon
                      className={clsx(classes.menuIcon, {
                        [classes.menuActive]: menu === 4,
                      })}
                      style={{ marginRight: 8 }}
                    />
                  }
                >
                  {t("Event triggers")}
                </WebMenuItem>
                {DMSsettings && (
                  <WebMenuItem
                    disabled={
                      (cameraInfo && cameraInfo.fw_ver === "") ||
                      !firmwareSettingPerm ||
                      menuDisabled
                    }
                    onClick={() => {
                      history.push(`${match.url}/dms`);
                      setOpen(false);
                    }}
                    classes={{
                      root: clsx(classes.bgColor, classes.mobileMenuIcon),
                    }}
                    className={clsx({
                      [classes.menuActiveFill]: menu === 5,
                    })}
                    startIcon={
                      <DMSIcon
                        className={clsx(classes.menuIcon, {
                          [classes.menuActiveFill]: menu === 5,
                        })}
                        style={{ marginRight: 8 }}
                      />
                    }
                  >
                    {/* mantis - 11125, Driver Monitoring System (DMS)-> DMS 로 문구 수정 (Leehj) */}
                    DMS
                  </WebMenuItem>
                )}

                <WebMenuItem
                  disabled={
                    (cameraInfo && cameraInfo.fw_ver === "") ||
                    !firmwareSettingPerm ||
                    menuDisabled
                  }
                  onClick={() => {
                    history.push(`${match.url}/camera-connectivity`);
                    setOpen(false);
                  }}
                  classes={{
                    root: clsx(classes.bgColor, classes.mobileMenuIcon),
                  }}
                  className={clsx({
                    [classes.menuActive]: menu === 6,
                  })}
                  startIcon={
                    <WifiConnected
                      className={clsx(classes.menuIcon, {
                        [classes.menuActive]: menu === 6,
                      })}
                      style={{ marginRight: 8 }}
                    />
                  }
                >
                  {t("Camera connectivity")}
                </WebMenuItem>
              </MobileMenu>
            </div>
          )}

          {!mobile && (
            <div className={classes.iconMenu}>
              <div
                className={clsx(
                  classes.appDiv,
                  classes.anchor,
                  classes.privacyMenu,
                  {
                    [classes.menuActive]: menu === 0,
                    [classes.disabled]: !privacyPerm,
                  }
                )}
                onClick={() => {
                  if (privacyPerm) {
                    history.push(`${match.url}`);
                  }
                }}
              >
                <SecurityIcon
                  className={clsx(classes.menuIcon, {
                    [classes.disabled]: !privacyPerm,
                  })}
                />
                <Typography
                  category="Default"
                  variant={menu === 0 ? "BodyBold" : "Body"}
                >
                  {t("Privacy")}
                </Typography>
              </div>
              <>
                <div
                  className={clsx(classes.appDiv, classes.anchorNonFill, {
                    [classes.menuActive]: menu === 1,
                    [classes.disabled]:
                      (cameraInfo && cameraInfo.fw_ver === "") ||
                      !eventAutoUploadPerm ||
                      menuDisabled,
                  })}
                  onClick={() => {
                    if (
                      cameraInfo &&
                      cameraInfo.fw_ver &&
                      eventAutoUploadPerm &&
                      !menuDisabled
                    ) {
                      history.push(`${match.url}/video`);
                    }
                  }}
                >
                  <VideoIcon
                    className={clsx(classes.menuIcon, {
                      [classes.disabled]:
                        (cameraInfo && cameraInfo.fw_ver === "") ||
                        !eventAutoUploadPerm ||
                        menuDisabled,
                    })}
                  />

                  <Typography
                    category="Default"
                    variant={menu === 1 ? "BodyBold" : "Body"}
                  >
                    {t("Video")}
                  </Typography>
                </div>
                <div
                  className={clsx(classes.appDiv, classes.anchor, {
                    [classes.menuActive]: menu === 2,
                    // mantis - 11379, 클라우드 연결한적 없는 경우 접근 불가하도록 비활성화. 주석 해제(Leehj)
                    [classes.disabled]:
                      (cameraInfo && cameraInfo.fw_ver === "") ||
                      !firmwareSettingPerm ||
                      menuDisabled,
                  })}
                  // mantis - 11379, 클라우드 연결한적 없는 경우 접근 불가하도록 주석 해제(Leehj)
                  onClick={() => {
                    if (
                      cameraInfo &&
                      cameraInfo.fw_ver &&
                      firmwareSettingPerm &&
                      !menuDisabled
                    ) {
                      history.push(`${match.url}/recording-mode`);
                    }
                  }}
                >
                  <RadioButtonCheckedRoundedIcon
                    className={clsx(classes.menuIcon, {
                      [classes.disabled]:
                        (cameraInfo && cameraInfo.fw_ver === "") ||
                        // mantis - 11379, firmwareSettingPerm권한이 없는 경우 비활성화 css 적용(Leehj)
                        !firmwareSettingPerm ||
                        menuDisabled,
                    })}
                  />
                  <Typography
                    category="Default"
                    variant={menu === 2 ? "BodyBold" : "Body"}
                  >
                    {t("Recording mode")}
                  </Typography>
                </div>
                <div
                  className={clsx(classes.appDiv, classes.anchorNonHover, {
                    [classes.menuActiveNonFill]: menu === 3,
                    [classes.disabled]:
                      (cameraInfo && cameraInfo.fw_ver === "") ||
                      !firmwareSettingPerm ||
                      menuDisabled,
                  })}
                  onClick={() => {
                    if (
                      cameraInfo &&
                      cameraInfo.fw_ver &&
                      firmwareSettingPerm &&
                      !menuDisabled
                    ) {
                      history.push(`${match.url}/system`);
                    }
                  }}
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SettingsIcon
                      className={clsx(classes.menuIcon, {
                        [classes.disabled]:
                          (cameraInfo && cameraInfo.fw_ver === "") ||
                          !firmwareSettingPerm ||
                          menuDisabled,
                      })}
                    />
                    <Typography
                      category="Default"
                      variant={menu === 3 ? "BodyBold" : "Body"}
                    >
                      {t("System")}
                    </Typography>
                  </div>
                  {cameraInfo?.require_time_sync && (
                      <div
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: 4,
                          marginRight: 16,
                          backgroundColor: LightColors.secondary["11"],

                        }}
                      />
                    )
                  }
                </div>
                <div
                  className={clsx(classes.appDiv, classes.anchor, {
                    [classes.menuActive]: menu === 4,
                    [classes.disabled]:
                      (cameraInfo && cameraInfo.fw_ver === "") ||
                      !firmwareSettingPerm ||
                      menuDisabled,
                  })}
                  onClick={() => {
                    if (
                      cameraInfo &&
                      cameraInfo.fw_ver &&
                      firmwareSettingPerm &&
                      !menuDisabled
                    ) {
                      history.push(`${match.url}/event-triggers`);
                    }
                  }}
                >
                  <StarOutlineRoundedIcon
                    className={clsx(classes.menuIcon, {
                      [classes.disabled]:
                        (cameraInfo && cameraInfo.fw_ver === "") ||
                        !firmwareSettingPerm ||
                        menuDisabled,
                    })}
                  />
                  <Typography
                    category="Default"
                    variant={menu === 4 ? "BodyBold" : "Body"}
                  >
                    {t("Event triggers")}
                  </Typography>
                </div>

                {DMSsettings && (
                  <div
                    className={clsx(classes.appDiv, classes.anchorNonStrocke, {
                      [classes.menuActiveFill]: menu === 5,
                      [classes.disabled]:
                        (cameraInfo && cameraInfo.fw_ver === "") ||
                        !firmwareSettingPerm ||
                        menuDisabled,
                    })}
                    onClick={() => {
                      if (
                        cameraInfo &&
                        cameraInfo.fw_ver &&
                        firmwareSettingPerm &&
                        !menuDisabled
                      ) {
                        history.push(`${match.url}/dms`);
                      }
                    }}
                  >
                    <DMSIcon
                      className={clsx(classes.menuIcon, {
                        [classes.disabled]:
                          (cameraInfo && cameraInfo.fw_ver === "") ||
                          !firmwareSettingPerm ||
                          menuDisabled,
                      })}
                    />
                    <Typography
                      category="Default"
                      variant={menu === 5 ? "BodyBold" : "Body"}
                    >
                      {/* mantis - 11125, Driver Monitoring System (DMS)-> DMS 로 문구 수정 (Leehj) */}
                      DMS
                    </Typography>
                  </div>
                )}

                <div
                  className={clsx(classes.appDiv, classes.anchor, {
                    [classes.menuActive]: menu === 6,
                    [classes.disabled]:
                      (cameraInfo && cameraInfo.fw_ver === "") ||
                      !firmwareSettingPerm ||
                      menuDisabled,
                  })}
                  onClick={() => {
                    if (
                      cameraInfo &&
                      cameraInfo.fw_ver &&
                      firmwareSettingPerm &&
                      !menuDisabled
                    ) {
                      history.push(`${match.url}/camera-connectivity`);
                    }
                  }}
                >
                  <WifiConnected
                    className={clsx(classes.menuIcon, {
                      [classes.disabled]:
                        (cameraInfo && cameraInfo.fw_ver === "") ||
                        !firmwareSettingPerm ||
                        menuDisabled,
                    })}
                  />
                  <Typography
                    category="Default"
                    variant={menu === 6 ? "BodyBold" : "Body"}
                  >
                    {t("Camera connectivity")}
                  </Typography>
                </div>
              </>
            </div>
          )}
        </div>
        <div
          style={
            mobile
              ? { height: "100%", overflowY: "auto" }
              : { height: "100%", flex: 1 }
          }
        >
          {mobile ? (
            routerMarkup
          ) : (
            <AutoSizer>
              {({ width, height }) => (
                <Scrollbars style={{ height, width }} autoHide>
                  {routerMarkup}
                </Scrollbars>
              )}
            </AutoSizer>
          )}
        </div>
      </div>
    );
  }
);
