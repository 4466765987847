export const calcRemainingTime = (time: number) => {
  if (time === 0) {
    return "--h : --m";
  }

  let hour = Math.floor(time / 60).toString();
  let minute = (time % 60).toString();

  hour = hour.length === 1 ? "0" + hour : hour;
  minute = minute.length === 1 ? "0" + minute : minute;

  return `${hour}h : ${minute}m`;
};
