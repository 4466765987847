import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PeriodRequest {
  psn: string;
  date: string;
  interval: number;
  period: string;
}

export interface MonthRequest {
  psn: string;
  month: number;
}

export type EventType =
  | "Drowsy"
  | "Distracted"
  | "Calling"
  | "Smoking"
  | "Overspeed"
  | "SharpTurn"
  | "HarshBraking"
  | "Acceleration";

export interface IDMSEventStat {
  event: EventType;
  value: number;
}

export interface IDrivingDailyReport {
  total?: ITotalLog;
  hours: IReportHour[];  
}

export interface IDrivingMonthlyReport {
  total?: ITotalLog;
  days: IReportDay[];  
  score: IReportScore[];
}

export interface IReportHour {
  hour: number;
  traveled: number;
  totalEvents: number;
  eventsPerDrivingHour: IEventsPerDriving;
}

export interface IReportDay {
  day: number;
  traveled: number;
  totalEvents: number;
  eventsPerDrivingDay: IEventsPerDriving;
}

export interface IEventsPerDriving {
  Drowsy: number;
  Distracted: number;
  Calling: number;
  Smoking: number;
  Overspeed: number;
  SharpTurn: number;
  HarshBraking: number;
  Acceleration: number;
}

export interface IReportScore {
  month: number;
  value: number;
}

export interface IGeofenceDuration {
  name: string;
  duration: number;
}

export interface ITotalLog {
  psn: string;
  duration: number;
  driving_distance: number;
  driving_duration: number;
  stop_count: number;
  stop_duration: number;
  no_gps_count: number;
  no_gps_duration: number;
  speed_over_count: number;
  max_speed: number;
  avg_speed: number;
  normal_event_count: number;
  parking_event_count: number;
  parking_mode_count: number;
  parking_mode_duration: number;
  drowsy_count: number;
  distracted_count: number;
  undetected_count: number;
  detected_count: number;
  calling_count: number;
  mask_off_count: number;
  smoking_count: number;
  sharp_turn_count: number;
  harsh_braking_count: number;
  acceleration_count: number;
  geofencing_enter_count: number;
  geofencing_exit_count: number;
  geofencing_in_duration: IGeofenceDuration[];
}

interface IState {
  loading: boolean;
  monthly: IReportDay[];
  daily: IReportHour[];
  monthlyScores: IReportScore[];
  totalLog?: ITotalLog;
  shownAlert: boolean;
}

let initialState: IState = {
  loading: false,
  monthly: [],
  daily: [],
  monthlyScores: [],
  shownAlert: false,
};

const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setShownAlert: (state, action: PayloadAction<boolean>) => {
      state.shownAlert = action.payload;
    },
    clearReport: (state) => {
      state.loading = false;
      state.monthly = [];
      state.daily = [];
      state.monthlyScores = [];
      state.totalLog = undefined;
    },
    loadMonthlyReport: (state, _action: PayloadAction<PeriodRequest>) => {
      state.loading = true;
    },
    successLoadMonthlyReport: (
      state,
      { payload }: PayloadAction<IDrivingMonthlyReport>
    ) => {
      const { days, total, score } = payload;
      state.loading = false;
      state.monthly = days;
      state.totalLog = total;
      state.monthlyScores = score;
    },
    loadDailyReport: (state, _action: PayloadAction<PeriodRequest>) => {
      state.loading = true;
    },
    successLoadDailyReport: (
      state,
      { payload }: PayloadAction<IDrivingDailyReport>
    ) => {
      const { total, hours } = payload;
      state.loading = false;
      state.daily = hours;
      state.totalLog = total;
    },
  },
});

export const {
  setShownAlert,
  clearReport,
  loadMonthlyReport,
  successLoadMonthlyReport,
  loadDailyReport,
  successLoadDailyReport,
} = slice.actions;
export const REPORT = slice.name;
export default slice.reducer;
