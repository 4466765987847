import _ from "lodash";
import {
  API_GATEWAY_URI,
  ILatLngBounds,
  jwtAxiosInst,
} from "@thingsw/pitta-modules";

export const getMyCameraLocations = (
  gpsServer: string,
  gpsPort: number,
  email: string,
  userToken: string,
  tokenType: string,
  cancel: AbortController,
  bounds?: ILatLngBounds
) => {
  let url = `https://${gpsServer}:${gpsPort}/BCS/gps_zone.php?email=${email}&user_token=${userToken}&tokenType=${tokenType}`;
  if (bounds) {
    url += `&sw=${bounds.south}_${bounds.west}5&ne=${bounds.north}_${bounds.east}`;
  }
  return fetch(url, { signal: cancel.signal });
};

export const getPublicCameraLocations = (
  gpsServer: string,
  gpsPort: number,
  bounds: ILatLngBounds,
  cancel: AbortController
) => {
  return fetch(
    `https://${gpsServer}:${gpsPort}/BCS/free_gps_zone.php?sw=${bounds.south}_${bounds.west}5&ne=${bounds.north}_${bounds.east}`,
    { signal: cancel.signal }
  );
};

export const getPublicCameraLocation = (
  gpsServer: string,
  gpsPort: number,
  email: string,
  psn: string,
  userToken: string,
  tokenType: string,
  cancel: AbortController
) => {
  return fetch(
    `https://${gpsServer}:${gpsPort}/BCS/gps_zone.php?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=${tokenType}`,
    { signal: cancel.signal }
  );
};

export const getFreeCamLocation = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  psn: string,
  userToken: string,
  tokenType: string,
  cancel: AbortController
) => {
  return fetch(
    `https://${lb_server_name}:${lb_http_port}/proc/gps_data?email=${email}&user_token=${userToken}&psn=${psn}&interval=5&tokenType=${tokenType}`,
    { signal: cancel.signal }
  );
};

export const getMultiCamLocations = (
  gpsServer: string,
  gpsPort: number,
  email: string,
  psns: string[],
  userToken: string,
  cancel: AbortController
) => {
  return fetch(`https://${gpsServer}:${gpsPort}/BCS/gps_psns.php`, {
    method: "POST",
    body: JSON.stringify({
      email,
      user_token: userToken,
      tokenType: "web",
      psns,
    }),
    signal: cancel.signal,
  });
};

export const getGPSDriveData = (
  email: string,
  userToken: string,
  psn: string
) => {
  return jwtAxiosInst.get(
    `/BCS/getDriveData.php?email=${email}&user_token=${userToken}&psn=${psn}&token_type=web`
  );
};

export const getGPSTrackingData = (
  email: string,
  userToken: string,
  jwt: string,
  psn: string,
  drive_no_list: number[],
  cancel: AbortController,
  bounds?: ILatLngBounds
) => {
  const sw = bounds ? `${bounds.west}_${bounds.south}` : undefined;
  const ne = bounds ? `${bounds.east}_${bounds.north}` : undefined;

  const params = new URLSearchParams();
  params.append("email", email);
  params.append("user_token", userToken);
  params.append("psn", psn);
  params.append("token_type", "web");
  if (sw) {
    params.append("sw", sw);
  }

  if (ne) {
    params.append("ne", ne);
  }
  params.append("drive_no_list", _.join(drive_no_list, ","));

  return fetch(`${API_GATEWAY_URI}/BCS/getTrackData.php`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    signal: cancel.signal,
    body: params.toString(),
  });
};

export const getGeofenceAlerts = (
  email: string,
  userToken: string,
  psn: string,
  drive_no_list: number[]
) => {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("user_token", userToken);
  params.append("psn", psn);
  params.append("token_type", "web");
  params.append("drive_no_list", _.join(drive_no_list, ","));
  return jwtAxiosInst.post(`/BCS/getFenceLogData.php`, params.toString(), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
