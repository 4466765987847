import { push } from "connected-react-router";
import { put, takeLatest } from "redux-saga/effects";
import { handleServer500 } from "./slice";

function* handleErrorServer500() {
  try {
    yield put(push("/Err500"));
  } catch (err) {
    console.error(err);
  }
}

export function* watchError() {
  yield takeLatest(handleServer500, handleErrorServer500);
}
