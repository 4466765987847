import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface SaveSettingModalProps {
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 16,
    "&>.MuiDialogActions-root": {
      justifyContent: "center",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        justifyContent: "flex-end",
      },
    },
    "&>.buttonPadding": {
      padding: "0px 23px 24px",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 560,
    },
  },
  titleDiv: {
    padding: "24px 16px 0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "18px 93px 0 20px",
    },
  },
  contentDiv: {
    padding: "12px 16px 32px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px 16px",
    },
  },
  closeStyle: {
    top: 13,
    right: 16,
  },
  btn: {
    minWidth: "151.5px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: "143.5px",
    },
  },
}));

export const FirmwareFormatModalUXUI = ({
  open,
  loading,
  onClose,
  onClickNegative,
  onClickPositive,
}: SaveSettingModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  return (
    <Modal
      btnCenterMobile={mobile}
      className={classes.root}
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      heading={t("Important All saved_")}
      close
      content={
        <Typography variant="Small" category="Default">
          {t("Whenever saving settings_")}
        </Typography>
      }
      LButton={t("Cancel")}
      RButton={t("Save")}
      Secondary={false}
      loading={loading}
      titleClassName={classes.titleDiv}
      contentClassName={classes.contentDiv}
      closeStyle={classes.closeStyle}
      RButtonClassName={classes.btn}
      LButtonClassName={classes.btn}
    />
  );
};
