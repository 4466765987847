import React, { useRef } from "react";

import {
  Link,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AppStoreBadge,
  Container,
  GooglePlayBadge,
  MacOS,
  Typography,
  Windows,
} from "@thingsw/pitta-design-system";
import { Trans, useTranslation } from "react-i18next";

import clsx from "clsx";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  LightColors,
  APPSTORE,
  GOOGLEPLAY,
  MACAPP,
  WINDOWAPP,
  Firmware,
} from "@thingsw/pitta-modules";

//galaxy fold
const fold = 281;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 56,
    backgroundColor: LightColors.primary["0"],
    [theme.breakpoints.down("sm")]: {
      marginTop: 58,
    },
  },
  body: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  titleDiv: {
    display: "flex",
    flexDirection: "column",
  },
  bodyText: {
    maxWidth: 906,
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      maxWidth: 1225,
    },
  },
  marginR2: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  marginB25: {
    marginBottom: 25.58,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  featureImgDiv: {
    display: "flex",
    flexGrow: 1,
    width: "100vw",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(7.5),

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.up("sm")]: {
      width: "54.25%",
      alignItems: "center",
    },
    [theme.breakpoints.up("xl")]: {
      width: "58%",
      alignItems: "center",
      paddingBottom: theme.spacing(7) + 1,
    },
    "&>img": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        height: "90%",
      },
    },
  },
  contentWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(8, 0),
    "&:nth-child(3)": {
      borderTop: `1px solid ${LightColors.primary["6"]}`,
      [theme.breakpoints.down("md")]: {
        border: "none",
      },
    },
    "&:last-child": {
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(5),
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
  },

  appAnchor: {
    display: "flex",
    justifyContent: "center",
    // flexGrow: 1,
    "&>img": {
      width: "100%",
    },
  },
  webAnchor: {
    display: "flex",
    justifyContent: "center",
    "&>img": {
      width: "100%",
    },
  },
  webAnchorDiv: {
    [theme.breakpoints.down(fold)]: {
      width: "52%",
    },
    [theme.breakpoints.down("md")]: {
      width: "54.25%",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  webMac: {
    [theme.breakpoints.down("xs")]: {
      width: "94%",
    },
    [theme.breakpoints.down(fold)]: {
      width: "45%",
    },
  },
  appStoreDiv: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      width: "54.25%",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  webStoreDiv: {
    display: "flex",
    justifyContent: "center",
  },

  version: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: `${LightColors.primary["7"]} !important`,
    "&:hover": {
      color: `${LightColors.primary["8"]} !important`,
      textDecoration: "none",
      cursor: "pointer",
    },
    "&:active": {
      color: `${LightColors.primary["8"]} !important`,
    },
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  linkApp: {
    cursor: "pointer",
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:visited": {
      color: LightColors.primary["7"],
    },
  },
}));

export const Downloads = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const small = useMediaQuery(theme.breakpoints.down(660));

  const firmwareDivRef = useRef<HTMLDivElement>(null);
  const mobileDivRef = useRef<HTMLDivElement>(null);
  const desktopDivRef = useRef<HTMLDivElement>(null);

  const category = matches ? "Default" : "Large";

  return (
    <div className={classes.root} dir={theme.direction}>
      <Container className={classes.body}>
        <div className={classes.titleDiv}>
          <Typography
            category={category}
            variant="H1"
            className={clsx(classes.bodyText, classes.marginB3)}
          >
            {t("Downloads")}
          </Typography>
          <Typography
            category="Default"
            variant="Body"
            className={classes.bodyText}
          >
            <Trans
              t={t}
              components={{
                a1: (
                  <span
                    className={classes.linkApp}
                    onClick={() => {
                      //@ts-ignore
                      window.location = "/downloads#firmware";
                      if (firmwareDivRef.current) {
                        window.scrollTo(
                          0,
                          firmwareDivRef.current.offsetTop - 64
                        );
                      }
                    }}
                  />
                ),
                a2: (
                  <span
                    className={classes.linkApp}
                    onClick={() => {
                      //@ts-ignore
                      window.location = "/downloads#mobile";
                      if (mobileDivRef.current) {
                        window.scrollTo(0, mobileDivRef.current.offsetTop - 64);
                      }
                    }}
                  />
                ),
                a3: (
                  <span
                    className={classes.linkApp}
                    onClick={() => {
                      //@ts-ignore
                      window.location = "/downloads#desktop";
                      if (desktopDivRef.current) {
                        window.scrollTo(
                          0,
                          desktopDivRef.current.offsetTop - 64
                        );
                      }
                    }}
                  />
                ),
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a4: <a href="/login" className={classes.linkApp} />,
              }}
            >
              Whether on mobile_
            </Trans>
          </Typography>
        </div>
        <div className={classes.featureImgDiv}>
          <img
            srcSet="/images/header-img.png 320w, /images/header-img@2x.png 1440w  "
            sizes="(min-width: 1440px) 1440px"
            alt="headerImg"
          />
        </div>

        <div className={classes.contentWrap} id="mobile" ref={mobileDivRef}>
          <div className={classes.contentDiv}>
            <Typography
              category={category}
              variant="H4"
              className={clsx(classes.bodyText, classes.marginB3)}
            >
              {t("BlackVue app for_")}
            </Typography>
            <Typography
              category="Default"
              variant="Body"
              className={clsx(classes.bodyText, classes.marginB3)}
              dangerouslySetInnerHTML={{ __html: t("Compatible with most_") }}
            />
          </div>
          <div className={classes.webStoreDiv}>
            <a
              href={APPSTORE}
              className={clsx(classes.appAnchor, classes.marginR2)}
              target="_blank"
              rel="noreferrer"
            >
              <AppStoreBadge
                style={{
                  minWidth: small ? 127 : 212,
                  height: small ? 42 : 72,
                }}
              ></AppStoreBadge>
            </a>
            <a
              href={GOOGLEPLAY}
              className={classes.appAnchor}
              target="_blank"
              rel="noreferrer"
            >
              <GooglePlayBadge
                style={{
                  minWidth: small ? 145 : 246,
                  height: small ? 42 : 72,
                }}
              ></GooglePlayBadge>
            </a>
          </div>
        </div>

        <div className={classes.contentWrap} id="desktop" ref={desktopDivRef}>
          <div className={classes.contentDiv}>
            <Typography
              category={category}
              variant="H4"
              className={clsx(classes.bodyText, classes.marginB3)}
            >
              {t("BlackVue Viewer for MacOS and Windows")}
            </Typography>
            <Typography
              category="Default"
              variant="Body"
              className={clsx(classes.bodyText, classes.marginB3)}
              dangerouslySetInnerHTML={{
                __html: t(`Supports most recent BlackVue_`),
              }}
            />
          </div>
          <div className={classes.webStoreDiv}>
            <div
              className={clsx(
                classes.marginR2,
                classes.webAnchorDiv,
                classes.webMac
              )}
            >
              <a
                href={MACAPP}
                className={classes.webAnchor}
                target="_blank"
                rel="noreferrer"
              >
                <MacOS
                  style={{
                    minWidth: small ? 119 : 207,
                    height: small ? 42 : 72,
                  }}
                ></MacOS>
              </a>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                className={clsx(classes.version, classes.noWrap)}
              >
                {t("MacOS X 10.12 and up")}
              </Typography>
            </div>
            <div className={classes.webAnchorDiv}>
              <a
                href={WINDOWAPP}
                className={classes.webAnchor}
                target="_blank"
                rel="noreferrer"
              >
                <Windows
                  style={{
                    minWidth: small ? 125 : 212,
                    height: small ? 42 : 72,
                  }}
                ></Windows>
                {/* <Windows style={{ minWidth: 213, height: 72 }}></Windows> */}
              </a>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                className={clsx(classes.version, classes.noWrap)}
              >
                {t("Windows 7 and up")}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.contentWrap} id="firmware" ref={firmwareDivRef}>
          <div className={classes.contentDiv}>
            <Typography
              category={category}
              variant="H4"
              className={clsx(classes.bodyText, classes.marginB3)}
            >
              {t("Firmware")}
            </Typography>
            <Typography
              category="Default"
              variant="Body"
              className={clsx(classes.bodyText, classes.marginB3)}
            >
              {t(`Firmware upgrades may add new functionality to your dashcam and/or
              improve its performance.`)}
            </Typography>
            <Link
              href={Firmware}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                category="Default"
                variant="Body"
                className={classes.bodyText}
              >
                {t("Firmware download")}
              </Typography>
              <OpenInNewIcon style={{ marginLeft: 4, fontSize: "1.2rem" }} />
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};
