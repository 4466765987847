import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import {
  Alerts,
  Avatars,
  ConnectToCloud,
  IconButton,
  LogoOnly,
  Menu,
  Modal,
  ScrollBar,
  Tooltip,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";

import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/Help";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { Breadcrumbs } from "../components/Breadcrumbs";
import { loadUserProfile, USER } from "../features/User/slice";
import { RootState } from "../features/store";
import { ProfileMenu } from "./ProfileMenu";
import { loadSubscription, PAYMENT } from "../features/Payment/slice";
import { NotificationPanel } from "./NotificationPanel";
import {
  clearNewEvent,
  PUSH_EVENT,
  setOpenNotification,
} from "../features/PushEvent/slice";
import { Link, useLocation } from "react-router-dom";
import { CAMERA } from "../features/Camera/slice";
import _ from "lodash";
import { getPlanFromServiceID } from "../utils/Service";
import moment from "moment";
import { THEME } from "../features/Theme/slice";
import { CameraSubHeader } from "./uxui/CameraSubHeader";
import { GeofenceSubHeader } from "./uxui/GeofenceSubHeader";
import {
  SemanticDarkColors,
  Webviewer,
  ICameraInfo,
  UserNames,
  HELPCENTER,
  TUTORIAL,
  SIMCARD_URI,
  CloudConnectionSettings,
} from "@thingsw/pitta-modules";
import { PERMISSION } from "../features/Permission/slice";

interface WebviewerHeaderProps {
  openMenu: boolean;
  mobile: boolean;
  error?: string;
  onToggleMenu: () => void;
  webSearchKey: string;
  onSearchKey: (search?: string) => void;
  onModeChange: (mode: "map" | "list") => void;
  mode: "map" | "list";
}

const useMenuItemStyles = makeStyles((theme: Theme) => ({
  root: {
    color: (props: any) => props.colors.primary["1"],
  },
}));

const useMenuStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: (props: any) =>
      props.color === "dark"
        ? SemanticDarkColors.primary["1"]
        : props.colors.primary["0"],
    // mantis - 10251, Help팝업 모서리 둥글게 (Leehj)
    borderRadius: 12,
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // height: (props: WebviewerHeaderProps) => (props.error ? 127 : 56),
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // height: (props: WebviewerHeaderProps) => (props.error ? 85 : 56),
      padding: theme.spacing(0, 4),
      borderBottom: "none",
    },
    //small mobile
    [theme.breakpoints.down(350)]: {
      height: (props: WebviewerHeaderProps) => (props.error ? 145 : 56),
    },
    zIndex: 97,

    ...(theme.direction === "rtl"
      ? {
          right: 235,
          left: 0,
        }
      : {
          left: 235,
          right: 0,
        }),
    top: 0,
    position: "fixed",
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  headerMobile: {
    left: 0,
    right: 0,
    justifyContent: "center",
    borderBottom: (props: any) => `1px solid ${props.colors.primary["6"]}`,
  },
  headerMenuClosed: {
    ...(theme.direction === "rtl" ? { right: 73 } : { left: 73 }),
    // 반응형 (모바일)
    [theme.breakpoints.down(Webviewer.mobile)]: {
      left: 0,
    },
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    "& button": {
      padding: theme.spacing(1.5),
    },
    "& button:last-child": {
      ...(theme.direction === "rtl" ? { paddingLeft: 0 } : { paddingRight: 0 }),
      // paddingRight: 0,
    },
  },
  notiIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.5) }
      : { marginRight: theme.spacing(1.5) }),
    // marginRight: theme.spacing(1.5),
  },
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  error: {
    overflow: "hidden",
  },
  alert: {
    transition: theme.transitions.create("height"),
    // margin: theme.spacing(1, 0),
  },
  errorClose: {
    height: 0,
  },
  tootip: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    cursor: "pointer",
    color: (props: any) => props.colors.primary["7"],
    "&:hover": {
      color: (props: any) => props.colors.primary["8"],
    },
    "&:visited": {
      color: (props: any) => props.colors.primary["7"],
    },
  },
  cloudConnectModalDiv: {
    margin: 0,
  },
  cloudConnectAppDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 2,
      marginTop: 21,
    },
  },
  appDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },
  appStoreDiv: {
    margin: theme.spacing(3, 0, 3.5, 1.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
      marginBottom: theme.spacing(6.5),
    },
  },
  imgCSSDiv: {
    margin: theme.spacing(5.375, 0, 6, 1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(6.125, 0, 6, 0),
    },
  },
  connectModalContent: {
    padding: theme.spacing(2, 2.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.375, 0, 3.25, 3),
    },
  },
  breadcrumbsDiv: {
    display: "flex",
    alignItems: "center",
  },
  cameraBreadcrumbs: {
    minWidth: 85,
  },
  geofencesBreadcrumbs: {
    minWidth: 102,
  },
}));

const regex1 = new RegExp("^/cameras/([A-za-z0-9]+)/playback");
const regex2 = new RegExp("^/cameras/([A-za-z0-9]+)/live-view");

export const WebviewerHeader = forwardRef<HTMLDivElement, WebviewerHeaderProps>(
  (props, ref) => {
    const {
      openMenu,
      error,
      mobile,
      onToggleMenu,
      onModeChange,
      mode,
      webSearchKey,
      onSearchKey,
    } = props;
    const dispatch = useDispatch();

    const { cameraList, camera: cam } = useSelector(
      (state: RootState) => state[CAMERA]
    );
    const { openNotification } = useSelector(
      (state: RootState) => state[PUSH_EVENT]
    );
    const [camera, setCamera] = useState<ICameraInfo>();

    const location = useLocation();
    const pathnames = location.pathname;

    const { email, loginInfo } = useSelector((state: RootState) => state[USER]);

    const { permission } = useSelector((state: RootState) => state[PERMISSION]);

    useEffect(() => {
      setCamera(
        _.find(cameraList?.deviceListInfo, (dev) => dev.device.psn === cam?.psn)
          ?.device
      );
    }, [cameraList, cam]);

    const showAlert = useMemo(() => {
      return (
        error &&
        pathnames !== "/members/invite-member" &&
        ((pathnames === "/cameras/add-camera" &&
          error === "Internal server error") ||
          pathnames !== "/cameras/add-camera")
      );
    }, [error, pathnames]);

    const { colors, color } = useSelector((state: RootState) => state[THEME]);
    const menuItemClasses = useMenuItemStyles({ colors, color });
    const menuClasses = useMenuStyles({
      colors,
      color,
    });
    const classes = useStyles({
      openMenu,
      mobile,
      error:
        ((!!location.pathname.match(regex1) ||
          !!location.pathname.match(regex2)) &&
          camera?.active === "off") ||
        showAlert
          ? "e"
          : undefined,
      onToggleMenu,
      colors,
    });
    const theme = useTheme();
    const { t } = useTranslation();
    const profileAnchorRef = useRef<HTMLDivElement>(null);
    const helpAnchorRef = useRef<HTMLButtonElement>(null);

    const { userProfile } = useSelector((state: RootState) => state[USER]);
    const subscriptionInfo = useSelector(
      (state: RootState) => state[PAYMENT].subscriptionInfo
    );

    const [openHelpTooltip, setOpenHelpTooltip] = useState(false);
    const [openProfileMenu, setOpenProfileMenu] = useState(false);
    const [openCloudConnectionModal, setOpenCloudConnectionModal] = useState(
      false
    );
    const [openHelp, setOpenHelp] = useState(false);
    const [imgSrc, setImgSrc] = useState<string>();
    const [planName, setPlanName] = useState<string>();

    const [openAvatarTooltip, setOpenAvatarTooltip] = useState(false);

    // mantis - 8826, pathnames이 바뀌면 help, notification, profile팝업 close (Leehj)
    useEffect(() => {
      setOpenHelp(false);
      dispatch(clearNewEvent());
      dispatch(setOpenNotification(false));
      setOpenProfileMenu(false);
    }, [dispatch, pathnames]);

    useEffect(() => {
      if (subscriptionInfo) {
        setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
      }
    }, [subscriptionInfo]);

    useEffect(() => {
      dispatch(loadUserProfile());
      dispatch(loadSubscription());
    }, [dispatch]);

    useEffect(() => {
      setImgSrc((s) => {
        if (userProfile?.profilePath) {
          return s === userProfile.profilePath ? s : userProfile.profilePath;
        }
        return s;
      });
    }, [userProfile?.lastName, userProfile?.profilePath]);

    const AvatarMarkup = useMemo(() => {
      return (
        <Tooltip
          disableTouchListener={mobile}
          placement="bottom"
          open={openAvatarTooltip && !openProfileMenu}
          onOpen={() => setOpenAvatarTooltip(true)}
          onClose={() => setOpenAvatarTooltip(false)}
          title={
            <div className={classes.tootip}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={colors.primary["0"]}
              >
                {userProfile?.firstName} {userProfile?.lastName}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={colors.primary["0"]}
              >
                {userProfile?.email}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={colors.primary["0"]}
              >
                {
                  // Mantis - 12886, Payment method : oneplan
                  subscriptionInfo?.paymentMethod === "oneplan"
                    ? t("One Plan")
                    : t(planName ?? "")
                }
                {planName === "Fleet plan" &&
                  userProfile?.userType &&
                  ` · ${t(UserNames[userProfile.userType])}`}
              </Typography>
            </div>
          }
        >
          <Avatars
            style={{ cursor: "pointer", marginLeft: 12 }}
            ref={profileAnchorRef}
            imgSrc={imgSrc}
            // Mantis-8566
            // name={lastName} >> {userProfile?.email}로 수정함.
            name={userProfile?.email}
            onClick={() => {
              setOpenProfileMenu(true);
            }}
          />
        </Tooltip>
      );
    }, [
      classes.tootip,
      colors.primary,
      imgSrc,
      mobile,
      openAvatarTooltip,
      openProfileMenu,
      planName,
      t,
      userProfile?.email,
      userProfile?.firstName,
      userProfile?.lastName,
      userProfile?.userType,
      subscriptionInfo?.paymentMethod,
    ]);

    const alertMessageMarkup = useMemo(() => {
      if (showAlert) {
        return (
          <Alerts
            mode="web"
            severity="error"
            className={clsx(classes.alert, {
              [classes.errorClose]: !error,
            })}
          >
            {pathnames === "/cameras/add-camera" &&
            error === "Internal server error"
              ? t("An error occurred_again")
              : error && t(error)}
          </Alerts>
        );
      } else if (
        (!!location.pathname.match(regex1) ||
          !!location.pathname.match(regex2)) &&
        camera?.active === "off"
      ) {
        const reg_date = moment(camera?.reg_date);
        const login_date = moment(camera?.login_date);

        if (reg_date.isSame(login_date)) {
          //클라우드 연결된 적 없음
          return (
            <Alerts mode="web" severity="warning">
              <Trans
                t={t}
                components={{
                  a: (
                    <span
                      className={classes.link}
                      onClick={() => setOpenCloudConnectionModal(true)}
                    ></span>
                  ),
                }}
              >
                Some functionality is disabled_
              </Trans>
            </Alerts>
          );
        } else {
          //연결된 적은 있으나 클라우드 연결 끊어진 상태
          return (
            <Alerts mode="web" severity="warning">
              {t("Not connected to_2")}
            </Alerts>
          );
        }
      }
    }, [
      camera?.active,
      camera?.login_date,
      camera?.reg_date,
      classes.alert,
      classes.errorClose,
      classes.link,
      error,
      location.pathname,
      pathnames,
      showAlert,
      t,
    ]);

    return (
      <>
        <div
          className={clsx(classes.header, {
            [classes.headerMobile]: mobile,
            [classes.headerMenuClosed]: !openMenu && !mobile,
          })}
          dir={theme.direction}
          ref={ref}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!mobile && (
              <div
                // 말줄임표로 출력되는 이슈로 최소width값 설정(Leehj)
                className={clsx(classes.breadcrumbsDiv, {
                  [classes.cameraBreadcrumbs]: pathnames === "/cameras",
                  [classes.geofencesBreadcrumbs]:
                    pathnames === "/geofences" ||
                    pathnames === "/geofences/add-geofence",
                })}
              >
                <Breadcrumbs />
              </div>
            )}

            {/* 웹, 카메라 스크린일 때 추가되는 서브헤더 */}
            {!mobile && pathnames === "/cameras" && (
              <CameraSubHeader
                openMenu={openMenu}
                webSearchKey={webSearchKey}
                onSearchKey={(search) => onSearchKey(search)}
                onModeChange={(mode) => onModeChange(mode)}
                mode={mode}
              />
            )}
            {/* 웹, 지오펜스 스크린일 때 추가되는 서브헤더 */}
            {((!mobile && pathnames === "/geofences") ||
              (!mobile && pathnames === "/geofences/add-geofence")) && (
              <GeofenceSubHeader
                openMenu={openMenu}
                // mantis - 12275, geofence권한 없는 경우에도 버튼 비활성화 (Leehj)
                disabled={
                  pathnames === "/geofences/add-geofence" ||
                  !permission.geofencePerm
                }
              />
            )}

            {mobile && (
              <Link to="/cameras" className={classes.vcenter}>
                <LogoOnly width={36} height={36} />
              </Link>
            )}
            <div className={classes.btnDiv}>
              <Tooltip
                disableTouchListener={mobile}
                placement="bottom"
                open={openHelpTooltip && !openHelp}
                onOpen={() => setOpenHelpTooltip(true)}
                onClose={() => setOpenHelpTooltip(false)}
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -6px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Help")}
                  </Typography>
                }
              >
                <IconButton
                  ref={helpAnchorRef}
                  onClick={() => setOpenHelp(true)}
                  darkColor={color === "dark"}
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>

              {!mobile && AvatarMarkup}
              {mobile && (
                <IconButton onClick={onToggleMenu} darkColor={color === "dark"}>
                  <MenuIcon />
                </IconButton>
              )}
            </div>
          </div>
          {/* addCamera화면의 경우 서버에러를 제외하고 Alert 경고창 안나오게 */}
          <div className={clsx(classes.error)}>{alertMessageMarkup}</div>

          <NotificationPanel
            open={openNotification}
            onClose={() => dispatch(setOpenNotification(false))}
          />

          {/* uxui 프로젝트 셋팅 */}
          {/* <NotificationSettingPanel
            open={openNotification}
            onClose={() => dispatch(setOpenNotification(false))}
          /> */}
        </div>

        <Menu
          open={openHelp}
          classes={menuClasses}
          anchorEl={helpAnchorRef.current}
          placement="bottom-end"
          onClickAway={() => {
            setOpenHelp(false);
          }}
          style={{
            zIndex: 101,
            backgroundColor:
              color === "dark"
                ? SemanticDarkColors.primary["1"]
                : // mantis - 10251, Help팝업 모서리 둥글게 (Leehj)
                  "transparent",
          }}
        >
          <WebMenuItem
            endIcon={<OpenInNewIcon fontSize="inherit" />}
            onClick={() => {
              setOpenHelp(false);
              window.open(HELPCENTER, "_blank");
            }}
            darkMode={color === "dark"}
            classes={menuItemClasses}
          >
            {t("Help center")}
          </WebMenuItem>
          <WebMenuItem
            endIcon={<OpenInNewIcon fontSize="inherit" />}
            onClick={() => {
              setOpenHelp(false);
              window.open(TUTORIAL, "_blank");
            }}
            darkMode={color === "dark"}
            classes={menuItemClasses}
          >
            {t("Tutorials")}
          </WebMenuItem>
        </Menu>

        <ProfileMenu
          open={openProfileMenu}
          anchorEl={profileAnchorRef.current}
          placement="bottom-end"
          onClickAway={() => setOpenProfileMenu(false)}
          onCloseMenu={() => setOpenProfileMenu(false)}
        />
        <Modal
          open={openCloudConnectionModal}
          contentClassName={classes.connectModalContent}
          RButton={mobile ? t("OK") : ""}
          close
          onClose={() => {
            setOpenCloudConnectionModal(false);
          }}
          onClickPositive={() => {
            setOpenCloudConnectionModal(false);
          }}
          fullSize={mobile}
          heading={t("How to connect_")}
          content={
            <ScrollBar
              style={{
                height: "100%",
                overflowX: "hidden",
                flex: 1,
                maxHeight: "calc(100vh - 250px)",
              }}
            >
              <ConnectToCloud
                bodyClassName={classes.cloudConnectModalDiv}
                firstTitle={t("Download BlackVue application")}
                firstContent={t("*If you are_", {
                  a: `<a
        href=${`${SIMCARD_URI}?email=${email}&user_token=${loginInfo?.user_token}&token_type=web`}
        target="_blank"
        rel="noreferrer"
        style=color:${colors.primary["7"]}; > BlackVue SIM</a>`,
                  b: "and add SIM card instead",
                })}
                secondTitle={t("Place your phone_", {
                  a: `<a
        href=${CloudConnectionSettings}
        target="_blank"
        rel="noreferrer"
        style=color:${colors.primary["7"]}; > connecting camera</a>`,
                })}
                mobile={mobile}
                appCSS={classes.appDiv}
                appStoreCSS={classes.appStoreDiv}
                imgCSS={classes.imgCSSDiv}
              />
            </ScrollBar>
          }
        />
      </>
    );
  }
);
