import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckIcon from "@material-ui/icons/Check";
import Lottie from "lottie-react";
import animationSimData from "../../lottiefiles/pricing-simData.json";

export interface Status {
  openModal: boolean;
  onClose: () => void;
  onClickPositive: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    width: 560,
    minHeight: 180,
    [theme.breakpoints.up("sm")]: {},
    "& .modalTitle": {
      color: "#0095E0",
      marginTop: 0,
      marginBottom: 4,
    },
    "& .modalSubTitle": {
      color: "#0095E0",
      marginTop: 0,
      marginBottom: 8,
    },
  },

  modalHeading: {
    padding: "16px 42px 0px 24px",
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
    [theme.breakpoints.up("sm")]: {},
  },
  headingTextIcon: {
    padding: 0,
  },
  modalContent: {
    padding: "14px 24px 32px",
    overflow: "hidden",
    "& .modalContImg": {
      dispaly: "block",
      width: 300,
      aspectRatio: "1/0.3",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {},
  },
  content: {
    "& p": {
      display: "flex",
      alignItems: "center",
    },
    "& .bannerImg": {
      maxWidth: 360,
      margin: "24px auto 0",
    },
  },
  modalButton: {
    padding: "0px 24px auto",
    [theme.breakpoints.up("sm")]: {},
  },
  modalClose: {
    top: 12,
    
    // id - 166
    // 아랍어 시 direction 적용
    ...(theme.direction === "rtl" ? { left: 13 } : { right: 13 }),
    [theme.breakpoints.up("sm")]: {},
  },

  LBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
  },

  RBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
  },
}));

export const SimDataPlanModal = (props: Status) => {
  const { openModal, onClose, onClickPositive } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      className={classes.modalBody}
      open={openModal}
      close
      closeStyle={classes.modalClose}
      onClose={onClose}
      titleClassName={classes.modalHeading}
      content={
        <>
          <HelpOutlineIcon style={{marginBottom: 26}}/>
          <div className={classes.content}>
            <p>
              <Typography
                category="Default"
                variant="H2"
                htmlColor="#0095E0"
              >
                  {t("3GB SIM Data Plan")}
              </Typography>
            </p>
            <p>
              <Typography
                category="Default"
                variant="Body"
                htmlColor="#0095E0"
              >
                  {t("Balanced Plan")}
              </Typography>
            </p>
            <p>
              <CheckIcon color="primary" style={{ marginRight: 8 }} />
              <Typography
                category="Default"
                variant="Body"
              >
                  {t("8+ hours Live View")}
              </Typography>
            </p>
            <p>
              <CheckIcon color="primary" style={{ marginRight: 8 }} />         
              <Typography
                category="Default"
                variant="Body"
              >
                  {t("Live Event Upload or_")}
              </Typography>
            </p>
            <Lottie className="bannerImg" animationData={animationSimData} />
          </div>
        </>
      }
      contentClassName={classes.modalContent}
      RButton={t("OK")}
      onClickPositive={() => {
        onClickPositive();
      }}
      onClickNegative={onClose}
      actionClassName={classes.modalButton}
      Secondary={false}
      RButtonClassName={classes.RBtn}
    />
  );
};
