import { PayloadAction } from "@reduxjs/toolkit";
import {
  actionChannel,
  call,
  flush,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { LocationType } from "@thingsw/pitta-design-system";
import _ from "lodash";
import moment from "moment";

import { RootState } from "../store";
import { logout, USER } from "../User/slice";
import {
  clearBuffer,
  clearLoading,
  deleteCloudVODFile,
  deleteEventVODFile,
  deleteVODUploadList,
  doneDeleteEventVODFiles,
  failLoadVODUploadList,
  setCloudLimit,
  loadCloudVODFile,
  loadCloudVODList,
  loadCloudVODThm,
  loadEventThumbnail,
  loadEventVODList,
  loadHashtags,
  loadThumbnail,
  loadUrgentVODToken,
  loadVODList,
  loadVODToken,
  loadVODUploadList,
  moveEventVODFiles,
  resetThmRequest,
  resetTokenRequest,
  setCancelDelete,
  successDeleteCloudVODFile,
  successDeleteEventVODFile,
  successDelUploadList,
  successloadCloudVODList,
  successLoadEventVODList,
  successLoadHashtags,
  successLoadThumbnail,
  successloadVODList,
  successLoadVODToken,
  successLoadVODUploadList,
  uploadVODFile,
  VOD,
} from "./slice";
import * as Api from "../../apis";
import { PriorityBuffer } from "../../utils/PriorityBuffer";
import { setError } from "../Error/slice";
import { openToast } from "../Toast/slice";
import { push } from "connected-react-router";
import { getFirmwareConfig } from "../../utils/Firmware";
import {
  VODTokenRequest,
  IUserLoginInfo,
  RESULT_CODE,
  FileTypeName,
  EventCode,
  getEvent,
  IVOD,
  VODListRequest,
  IVODToken,
  ICameraVOD,
  ICameraInfo,
  IFileUploadInfo,
  IDelUploadList,
  getDirectionVOD,
} from "@thingsw/pitta-modules";

function* handleLoadCloudVodFile({ payload }: PayloadAction<VODTokenRequest>) {
  try {
    const { psn, filename } = payload;
    const email = yield select((state: RootState) => state[USER].email);
    const { tokenType } = yield select((state: RootState) => state[USER]);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      Api.getCloudVODFile,
      email,
      loginInfo.user_token,
      psn,
      filename,
      tokenType
    );
    // console.log(resp.data);
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadCloudVodThm({
  payload,
}: PayloadAction<{ psn: string; filename: string; thmName: string }>) {
  try {
    const { psn, filename, thmName } = payload;
    const email = yield select((state: RootState) => state[USER].email);
    const { tokenType, loginInfo } = yield select(
      (state: RootState) => state[USER]
    );
    const resp = yield call(
      Api.getCloudVODFile,
      email,
      loginInfo.user_token,
      psn,
      thmName,
      tokenType
    );

    const {
      resultcode,
      response: { presignedURL },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: { presignedURL: string };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadThumbnail({
          filename,
          thm: presignedURL,
        })
      );
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadCloudVODList({ payload }: PayloadAction<string>) {
  try {
    priorityBuffer.flush();
    yield put(resetThmRequest());
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getCloudVODList,
      email,
      loginInfo.user_token,
      payload
    );
    const {
      resultcode,
      response: { s3_usage, s3_usage_limit, filelist },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: {
        s3_usage: string;
        s3_usage_limit: string;
        filelist: { filename: string; exp: string }[];
      };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successloadCloudVODList({
          s3_usage: parseFloat(s3_usage),
          s3_usage_limit: parseFloat(s3_usage_limit),
          filelist: _.chain(filelist)
            .groupBy((f) => {
              const { filename: vod } = f;
              const vodNames = vod.split(".");
              const filename = vodNames[0];
              const names = filename.split("_");
              const ev = names[2].split("");
              return `${names[0]}_${names[1]}_${ev[0]}${ev[1]}`;
            })
            .map((vods, key) => {
              // console.log(key, vods);
              let filename: string | undefined;
              let lowFilename: string | undefined;
              let thmName: string | undefined;
              let time: moment.Moment | undefined;
              let exp: moment.Moment | undefined;
              let direction: LocationType | undefined;
              let event: FileTypeName | undefined;
              let eventCode: EventCode | undefined;
              let hasFront = false;
              let hasRear = false;
              let hasInterior = false;
              for (const vod of vods) {
                const { filename: fname, exp: vodExp } = vod;
                const vodNames = fname.split(".");
                const fileExt = vodNames[1];
                if (fileExt === "thm") {
                  thmName = fname;
                  continue;
                }

                const fname2 = vodNames[0];
                const names = fname2.split("_");
                const t = moment(`${names[0]} ${names[1]}`);
                const ev = names[2].split("");
                time = t;
                exp = vodExp.length === 0 ? undefined : moment(vodExp);
                // console.log("vodExp", vodExp, exp);
                direction = getDirectionVOD(ev[1]);
                if (ev[0] !== "N") {
                  eventCode = ev[0] as EventCode;
                }
                event = getEvent(ev[0]);
                if (ev[2] === "S" || ev[2] === "L") {
                  lowFilename = fname;
                } else {
                  filename = fname;
                }

                const frontFname = `${names[0]}_${names[1]}_${ev[0]}F`;
                const rearFname = `${names[0]}_${names[1]}_${ev[0]}R`;
                const interiorFname = `${names[0]}_${names[1]}_${ev[0]}I`;
                hasFront =
                  hasFront ||
                  _.findIndex(
                    filelist,
                    (f) => f.filename.indexOf(frontFname) > -1
                  ) > -1;
                hasRear =
                  hasRear ||
                  _.findIndex(
                    filelist,
                    (f) => f.filename.indexOf(rearFname) > -1
                  ) > -1;
                hasInterior =
                  hasInterior ||
                  _.findIndex(
                    filelist,
                    (f) => f.filename.indexOf(interiorFname) > -1
                  ) > -1;
              }
              return {
                filename: filename || lowFilename,
                lowFilename: lowFilename,
                hasOriginal: !!filename,
                hasLow: !!lowFilename,
                time,
                exp,
                direction,
                event,
                eventCode,
                thmName,
                hasFront,
                hasInterior,
                hasRear,
              } as IVOD;
            })
            .filter((vod) => !!vod.filename)
            .sortBy((v) => {
              if (v.direction === "Front") {
                return 0;
              }
              if (v.direction === "Rear") {
                return 1;
              }
              if (v.direction === "Interior") {
                return 2;
              }
            })
            .reverse()
            .sortBy("time")
            .value(),
        })
      );
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
    // console.log(resp.data);
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadVODList({ payload }: PayloadAction<VODListRequest>) {
  try {
    priorityBuffer.flush();
    yield put(resetThmRequest());
    yield put(resetTokenRequest());
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { tokenType } = yield select((state: RootState) => state[USER]);
    const { lb_server_name, lb_http_port, psn } = payload;
    const res = yield call(
      Api.getVODList,
      lb_server_name,
      lb_http_port,
      email,
      loginInfo.user_token,
      psn,
      tokenType
    );
    yield put(
      successloadVODList(
        _.map(res.data["filelist"], (filename) => ({ filename }))
      )
    );
  } catch (err) {
    yield put(clearLoading());
    console.error("error", err);
  }
}

function* handleLoadToken({ payload }: PayloadAction<VODTokenRequest>) {
  try {
    const { psn, filename } = payload;
    const tokens = yield select((state: RootState) => state[VOD].tokens);
    if (tokens[filename]) {
      return;
    }

    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const res = yield call(
      Api.getVODToken,
      email,
      loginInfo.user_token,
      psn,
      filename
    );

    const response = res.data as {
      resultcode: RESULT_CODE;
    } & IVODToken;
    const { resultcode } = response;

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadVODToken({ filename, token: response }));
    }

    // yield put(successloadVODList(res.data["filelist"]));
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadThumbnail({
  payload,
}: PayloadAction<VODTokenRequest & VODListRequest>) {
  const requestThumb = yield select(
    (state: RootState) => state[VOD].requestThumb
  );
  if (!requestThumb) return;
  try {
    const {
      lb_server_name,
      lb_http_port,
      psn,
      filename,
      cloudNative,
    } = payload;
    const email = yield select((state: RootState) => state[USER].email);
    const { tokenType } = yield select((state: RootState) => state[USER]);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const thmFilename = filename.split(".")[0] + ".thm";
    if (cloudNative) {
      try {
        const resp = yield call(
          Api.getCNThumbnail,
          email,
          loginInfo.user_token,
          psn,
          thmFilename.replace(".thm", ".mp4"),
          ["t"],
          tokenType
        );

        // console.log("VOD", "saga", "handleLoadThumbnail", resp.data);
        const { data } = resp;

        yield put(
          successLoadThumbnail({
            filename,
            thm: `data:image/jpeg;base64,${data.thumbnail}`,
            // "data:image/jpg;base64," +
            // Buffer.from(res.data, "binary").toString("base64"),
          })
        );
      } catch (e) {
        console.log(
          "CloudNative3",
          "get thumbnail from cloud native",
          e.message
        );
      }
    } else {
      const res = yield call(
        Api.getThumbnail,
        lb_server_name,
        lb_http_port,
        email,
        loginInfo.user_token,
        psn,
        thmFilename
      );
      const image = btoa(
        new Uint8Array(res.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      yield put(
        successLoadThumbnail({
          filename,
          thm: `data:image/jpeg;base64,${image}`,
          // "data:image/jpg;base64," +
          // Buffer.from(res.data, "binary").toString("base64"),
        })
      );
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadEventVODList({ payload }: PayloadAction<string>) {
  try {
    priorityBuffer.flush();
    yield put(resetThmRequest());
    yield put(resetTokenRequest());
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getEventVODList, email, user_token, payload);
    const {
      resultcode,
      message,
      // mantis - 11259, playback > liveEventUpload 탭 에서도 사용량을 보여주기 위해 s3_usage, s3_usage_limit 추가 (Leehj)
      response: { s3_usage, s3_usage_limit, filelist },
    } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: {
        s3_usage: string;
        s3_usage_limit: string;
        filelist: {
          rid: string;
          filename: string;
          exp: string;
        }[];
      };
    };
    // console.log("handleLoadEventVODList", filelist);
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadEventVODList({
          // mantis - 11259, playback > liveEventUpload 탭 에서도 사용량을 보여주기 위해 s3_usage, s3_usage_limit 추가 (Leehj)
          s3_usage: parseFloat(s3_usage),
          s3_usage_limit: parseFloat(s3_usage_limit),
          filelist: _.chain(filelist)
            .groupBy((f) => {
              const { filename: vod } = f;
              const vodNames = vod.split(".");
              const filename = vodNames[0];
              const names = filename.split("_");
              const ev = names[2].split("");
              return `${names[0]}_${names[1]}_${ev[0]}${ev[1]}`;
            })
            .map((vods, key) => {
              // console.log(key, vods);
              let filename: string | undefined;
              let frid: string | undefined;
              let lowFilename: string | undefined;
              let lowRid: string | undefined;
              let thmName: string | undefined;
              let thmRid: string | undefined;
              let time: moment.Moment | undefined;
              let exp: moment.Moment | undefined;
              let direction: LocationType | undefined;
              let event: FileTypeName | undefined;
              let eventCode: EventCode | undefined;
              let hasFront = false;
              let hasRear = false;
              let hasInterior = false;
              // 7 BOX option카메라 관련 추가 (8489)
              let hasOption = false;

              for (const vod of vods) {
                const { filename: fname, exp: vodExp, rid } = vod;
                const vodNames = fname.split(".");
                const fileExt = vodNames[1];
                if (fileExt === "thm") {
                  thmName = fname;
                  thmRid = rid;
                  continue;
                }

                const fname2 = vodNames[0];
                const names = fname2.split("_");
                const t = moment(`${names[0]} ${names[1]}`);
                const ev = names[2].split("");
                time = t;
                exp = moment.utc(vodExp).local();
                // mantis - 12548, 모든 파일이 Front로 표시 되고 이슈 수정 (Leehj)
                direction = getDirectionVOD(ev[1]);
                if (ev[0] !== "N") {
                  eventCode = ev[0] as EventCode;
                }
                event = getEvent(ev[0]);
                if (ev[2] === "S" || ev[2] === "L") {
                  lowFilename = fname;
                  lowRid = rid;
                } else {
                  filename = fname;
                  frid = rid;
                }

                const frontFname = `${names[0]}_${names[1]}_${ev[0]}F`;
                const rearFname = `${names[0]}_${names[1]}_${ev[0]}R`;
                const interiorFname = `${names[0]}_${names[1]}_${ev[0]}I`;
                // 7 BOX option카메라 관련 추가 (8489)
                const optionFname = `${names[0]}_${names[1]}_${ev[0]}O`;
                hasFront =
                  hasFront ||
                  _.findIndex(
                    filelist,
                    (f) => f.filename.indexOf(frontFname) > -1
                  ) > -1;
                hasRear =
                  hasRear ||
                  _.findIndex(
                    filelist,
                    (f) => f.filename.indexOf(rearFname) > -1
                  ) > -1;
                hasInterior =
                  hasInterior ||
                  _.findIndex(
                    filelist,
                    (f) => f.filename.indexOf(interiorFname) > -1
                  ) > -1;
                // 7 BOX option카메라 관련 추가 (8489)
                hasOption =
                  hasOption ||
                  _.findIndex(
                    filelist,
                    (f) => f.filename.indexOf(optionFname) > -1
                  ) > -1;
              }
              return {
                filename: filename || lowFilename,
                rid: frid || lowRid,
                lowFilename: lowFilename,
                lowRid,
                hasOriginal: !!filename,
                hasLow: !!lowFilename,
                time,
                exp,
                direction,
                event,
                eventCode,
                thmName,
                thmRid,
                hasFront,
                hasInterior,
                hasRear,
                // 7 BOX option카메라 관련 추가 (8489)
                hasOption,
              } as IVOD;
            })
            .filter((vod) => !!vod.filename)
            .sortBy((v) => {
              if (v.direction === "Front") {
                return 0;
              }
              if (v.direction === "Rear") {
                return 1;
              }
              if (v.direction === "Interior") {
                return 2;
              }
            })
            .reverse()
            .sortBy("time")
            .value(),
        })
      );
    } else {
      yield put(setError(message));
      yield put(clearLoading());
    }
  } catch (err) {
    console.error(err);
    if (err.response?.status === 500) {
      yield put(push("/Err500"));
    }
  } finally {
    yield put(clearLoading());
  }
}

function* handleLoadEventThumbnail({
  payload: { psn, rid, filename },
}: PayloadAction<{ psn: string; rid: string; filename: string }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { tokenType } = yield select((state: RootState) => state[USER]);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getEventVODFile,
      email,
      user_token,
      psn,
      rid,
      tokenType
    );
    yield put(
      successLoadThumbnail({
        filename,
        thm: resp.data["fileInfo"]["url"],
        // "data:image/jpg;base64," +
        // Buffer.from(res.data, "binary").toString("base64"),
      })
    );
  } catch (err) {
    console.error(err);
  }
}

function* handleDeleteEventVODFile({
  payload: { psn, vods, fileLength },
}: PayloadAction<{ psn: string; vods: ICameraVOD[]; fileLength: number }>) {
  try {
    const nDeleteAtOnce = 50;
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const vodChunks = _.chunk(vods, nDeleteAtOnce);
    let deleteFileLength = 0;
    const successRids: string[] = [];
    for (let vods of vodChunks) {
      const { cancelDelete } = yield select((state: RootState) => state[VOD]);
      if (cancelDelete) {
        yield put(setCancelDelete(false));
        break;
      }
      const rids = _.chain(vods)
        .map((v) => [v.rid, v.thmRid])
        .flattenDeep()
        .compact()
        .value();
      const resp = yield call(
        Api.deleteEventVODFile,
        email,
        user_token,
        psn,
        rids
      );

      deleteFileLength += vods.length;

      const { resultcode } = resp.data as {
        resultcode: RESULT_CODE;
      };

      if (resultcode === "BC_ERR_OK") {
        successRids.push(...rids);
      }
    }

    console.log(
      "handleDeleteEventVODFile",
      "fileLength",
      fileLength,
      "deleteFileLength",
      deleteFileLength
    );
    yield put(successDeleteEventVODFile(successRids));
    if (deleteFileLength === 1) {
      yield put(openToast({ message: "Video deleted" }));
    } else if (deleteFileLength > 1) {
      yield put(
        openToast({
          message: "n Videos deleted",
          option: { count: deleteFileLength },
        })
      );
    }
    yield put(doneDeleteEventVODFiles());
  } catch (err: any) {
    yield put(doneDeleteEventVODFiles());
    yield put(loadEventVODList(psn));
    console.error("handleDeleteEventVODFile", err.message);
  }
}

function* handleDeleteCloudVODFile({
  payload: { camera, filenames, vodCount },
}: PayloadAction<{
  camera: ICameraInfo;
  filenames: string[];
  vodCount?: number;
}>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const uploadList = yield select(
      (state: RootState) => state[VOD].uploadList
    );

    const config = getFirmwareConfig(camera);

    if (config.CloudNative4 && uploadList) {
      let removeList: any[] = [];
      let newUploadList: any[] = [];
      _.map(uploadList, (item, index) => {
        if (
          filenames.some((fn) => fn.slice(0, 18) === item.filename.slice(0, 18))
        ) {
          removeList.push({
            filename: item.filename,
            type: item.type,
          });
        } else {
          newUploadList.push(item);
        }
      });
      console.log("removeList", removeList);
      console.log("newUploadList", newUploadList);
      if (removeList) {
        const resp = yield call(
          Api.deleteCNVODUploadList,
          email,
          camera.psn,
          removeList
        );
        const { resultcode } = resp.data as {
          resultcode: RESULT_CODE;
        };
        if (resultcode === "BC_ERR_OK") {
          yield put(successLoadVODUploadList(newUploadList ?? []));
        }
      }
    }
    const resp = yield call(
      Api.deleteCloudVODFile,
      email,
      user_token,
      camera.psn,
      filenames,
      vodCount
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
    };
    if (resultcode === "BC_ERR_OK") {
      if (vodCount === 1) {
        yield put(openToast({ message: "Video deleted" }));
      } else {
        yield put(
          openToast({
            message: "n Videos deleted",
            option: { count: vodCount },
          })
        );
      }
      yield put(successDeleteCloudVODFile(filenames));
    } else if (resultcode === "BC_ERR_INTERNAL_ERROR") {
      yield put(push("/Err500"));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleMoveEventVODFiles({
  payload: { psn, rids },
}: PayloadAction<{ psn: string; rids: string[] }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const resp = yield call(Api.moveEventVODFile, email, user_token, psn, rids);
    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadEventVODList(psn));
      yield put(openToast({ message: "Selected file(s) moved_" }));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUploadVODFile({
  payload: { camera, filename },
}: PayloadAction<{ camera: ICameraInfo; filename: string }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { upload_server, upload_port, psn } = camera;

    let resp = [];
    const config = getFirmwareConfig(camera);
    if (config.CloudNative4) {
      resp = yield call(Api.uploadCNVODFile, email, psn, filename);
    } else {
      resp = yield call(
        Api.uploadVODFile,
        upload_server,
        upload_port,
        email,
        user_token,
        psn,
        filename
      );
    }
    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadVODUploadList(camera));
      yield put(openToast({ message: "File(s) queued for_" }));
    } else if (
      resultcode === "BC_ERR_ALREADY_EXIST_FILE" ||
      resultcode === "BC_ERR_ALREADY_EXIST_S3"
    ) {
      yield put(setError("The video was_"));
      // mantis - 12508, BC_ERR_DEVICE_S3_OVER 에러 코드 발생하는 경우 앱과 동일하게 팝업노출 (Leehj)
    } else if (resultcode === "BC_ERR_DEVICE_S3_OVER") {
      yield put(setCloudLimit(true));
    }

    // console.log("handleUploadVODFile", resp.data);
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadVODUploadList({ payload }: PayloadAction<ICameraInfo>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { upload_server, upload_port, psn } = payload;

    const now = new Date();
    let resp = [];
    let filelist = [];
    const config = getFirmwareConfig(payload);
    if (config.CloudNative4) {
      resp = yield call(Api.getCNVODUploadList, email, psn);
      let filelistA = resp.data.response.filelist as IFileUploadInfo[];
      filelist = _.filter(filelistA, (file) => {
        let fileDot = file.filename.lastIndexOf(".");
        let fileLastname = file.filename.substring(
          fileDot + 1,
          file.filename.length
        );
        let fileSize = file.filename.substring(fileDot - 1, fileDot);
        return fileSize !== "S" && fileLastname !== "thm";
      });
    } else {
      resp = yield call(
        Api.getVODUploadList,
        upload_server,
        upload_port,
        email,
        user_token,
        psn
      );
      filelist = resp.data.filelist as IFileUploadInfo[];
    }
    const { resultcode, message } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };

    console.log(now, "handleLoadVODUploadList", filelist, filelist ?? []);
    if (resultcode === "BC_ERR_OK") {
      // 7660 filelist가 undefined일때 (upload중인 파일이 하나일때) 업데이트 안되는 문제 수정
      yield put(successLoadVODUploadList(filelist ?? []));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      if (message === "offline") {
        yield put(failLoadVODUploadList());
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleDeleteVODUploadList({
  payload: { camera, removelist },
}: PayloadAction<{ camera: ICameraInfo; removelist: IDelUploadList[] }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { upload_server, upload_port, psn } = camera;

    let resp = [];
    const config = getFirmwareConfig(camera);
    if (config.CloudNative4) {
      resp = yield call(Api.deleteCNVODUploadList, email, psn, removelist);
    } else {
      resp = yield call(
        Api.deleteVODUploadList,
        upload_server,
        upload_port,
        email,
        user_token,
        psn,
        removelist
      );
    }
    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successDelUploadList());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadHashtags() {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const resp = yield call(Api.getHashtags, email, user_token, tokenType);
    yield put(successLoadHashtags(resp.data));
  } catch (err) {
    console.error(err);
  }
}
const priorityBuffer = PriorityBuffer(
  100,
  [clearBuffer, loadUrgentVODToken],
  [loadThumbnail, loadCloudVODFile, loadEventThumbnail, loadCloudVODThm]
);

export function* watchVOD() {
  yield takeLatest(loadCloudVODList, handleLoadCloudVODList);
  yield takeLatest(loadVODList, handleLoadVODList);
  yield takeLatest(loadEventVODList, handleLoadEventVODList);
  yield takeLatest(deleteEventVODFile, handleDeleteEventVODFile);
  yield takeLatest(deleteCloudVODFile, handleDeleteCloudVODFile);
  yield takeLatest(moveEventVODFiles, handleMoveEventVODFiles);
  yield takeLatest(uploadVODFile, handleUploadVODFile);
  // 10초마다 업로드 상태 요청하는데 결과가 너무 오래 걸리면 takeLatest로 하면,
  // 이전 요청이 취소되고 새 요청이 생성되면서 결과를 못받아오는 문제 수정(7124)
  yield takeLeading(loadVODUploadList, handleLoadVODUploadList);
  yield takeLatest(deleteVODUploadList, handleDeleteVODUploadList);
  yield takeLatest(loadHashtags, handleLoadHashtags);
  const requestChan = yield actionChannel(
    [
      clearBuffer,
      loadUrgentVODToken,
      loadThumbnail,
      loadVODToken,
      loadCloudVODFile,
      loadEventThumbnail,
      loadCloudVODThm,
    ],
    priorityBuffer
  );
  while (true) {
    const { payload, type } = yield take(requestChan);
    console.log("watchVOD", "type", type);
    switch (type) {
      case loadCloudVODFile.type:
        yield call(handleLoadCloudVodFile, { type, payload });
        break;
      case loadEventThumbnail.type:
        yield call(handleLoadEventThumbnail, { type, payload });
        break;
      case loadThumbnail.type:
        yield call(handleLoadThumbnail, { type, payload });
        break;
      case loadCloudVODThm.type:
        yield call(handleLoadCloudVodThm, { type, payload });
        break;
      case loadUrgentVODToken.type:
      case loadVODToken.type:
        yield call(handleLoadToken, { type, payload });
        break;
      case clearBuffer.type:
        yield flush(requestChan);
        break;
    }
  }
}
