import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Card,
  Divider,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import {
  IconButton,
  ScrollBar,
  Switch,
  Typography,
} from "@thingsw/pitta-design-system";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import _ from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import {
  LightColors,
  Webviewer,
  TabNameInfo1,
  TrueFalsetype,
  TimeZone,
  TimeZoneCountries,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 673,
    minHeight: 107,
    borderRadius: 12,
    boxShadow: "0px 2px 2px 0px rgb(0 0 0 / 6%)",
  },

  timeZoneSelectWrap: {
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    padding: "10px 20px 9px",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      padding: "10px 20px",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  contentDivider: {
    margin: "0 20px",
  },

  selectDiv: {
    paddingTop: 16,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      minWidth: 267,
      padding: "0",
    },
  },

  switchWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "12px 24px",
  },

  switch: {
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 20,
    },
  },

  inputGPStime: {
    "& .MuiInputBase-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
    },

    "& .MuiOutlinedInput-input": {
      height: "24px",
      padding: "8px 14px 8px 24px",
    },
    "& .MuiSvgIcon-root": {
      color: LightColors.primary["2"],
    },

    [theme.breakpoints.up(Webviewer.mobile)]: {
      flex: "1",
      maxWidth: 360,
    },
  },
  outlinedInputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${LightColors.primary["4"]} !important`,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${LightColors.primary["7"]} !important`,
      borderWidth: 1,
    },
    "&.MuiOutlinedInput-adornedEnd": {
      paddingRight: 24,
    },
  },
  timeListPaper: {
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 12,
  },
  timeListDiv: {
    width: 360,
    minHeight: 36,
    height: "50vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    "& ul": {
      margin: 0,
      listStyle: "none",
      padding: "16px 16px",
    },
    "& ul > li": {
      padding: "12px 16px 12px 16px",
      //   textOverflow: "ellipsis",
      //   overflow: "hidden",
      //   whiteSpace: "nowrap",
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#E7F5FC",
      },
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 511,
    },
  },
  timezoneTextDiv: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  checkIcon: {
    fontSize: 24,
    color: LightColors.primary["7"],
  },
}));

interface SystemLEDPanelProps {
  currentTab1?: TabNameInfo1;
  onSettingChange: (
    key: keyof TabNameInfo1,
    val: string | TrueFalsetype
  ) => void;
}

export const SystemTimeZonePanel = ({
  currentTab1,
  onSettingChange,
}: SystemLEDPanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { t, i18n } = useTranslation();

  const [timeZoneValue, setTimeZoneValue] = useState<string>();
  const [preTimeZoneValue, setPreTimeZoneValue] = useState<string>();
  const [onChangeTimeValue, setOnChangeTimeValue] = useState("");
  const [openTimelist, setOpenTimelist] = useState(false);
  const timeListAnchorRef = useRef<HTMLDivElement>(null);

  const [language, setLanguage] = useState<string>();

  const getBoolean = (TrueFalse: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };

  const setPreTimeZone = useCallback(() => {
    _.chain(TimeZone)
      .keys()
      .map((k) => {
        if (k === currentTab1?.TimeZone) {
          setPreTimeZoneValue(TimeZone[k].valueOf());
          setTimeZoneValue(TimeZone[k].valueOf());
        }
        return false;
      })
      .value();
  }, [currentTab1?.TimeZone]);

  const onClickOutsideListener = useCallback(() => {
    document.removeEventListener("click", onClickOutsideListener);
    if (openTimelist) {
      setOpenTimelist(false);
    }
    setPreTimeZone();
  }, [openTimelist, setPreTimeZone]);

  const onChangeTimeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeZoneValue(e.currentTarget.value);
    setOnChangeTimeValue(e.currentTarget.value);
    setOpenTimelist(true);
  };

  const timeZoneList = useMemo(() => {
    setLanguage(i18n.language);
    const countryTimeList: any[] = [];
    _.filter(TimeZoneCountries, (obj) =>
      _.forEach(obj, (item: any, key) => {
        if (key === language)
          countryTimeList.push({
            key: String(obj.value),
            time: obj.prefix,
            country: item,
          });
      })
    );
    return countryTimeList;
  }, [i18n.language, language]);

  const getTimelist = useCallback(() => {
    let searchWords = onChangeTimeValue.toLowerCase().split(" ");

    return _.chain(timeZoneList)
      .filter((item) =>
        searchWords.every((word: string) =>
          (item.time + item.country).toLowerCase().includes(word)
        )
      )
      .sortBy((item) => item.value)
      .map((item, index) => {
        return (
          <li
            onClick={() => {
              onSettingChange("TimeZone", item.key);
              onSettingChange("SetTime", "");

              setTimeZoneValue(item.time + "  " + item.country);
              setOpenTimelist(false);
            }}
          >
            <div className={classes.timezoneTextDiv}>
              <Typography category="Default" variant="Body">
                {item.time + "  " + item.country}
              </Typography>
            </div>
            {/* 일단은 저장한 타임존의 첫번째나라로 체크표시 */}
            {_.includes([currentTab1?.TimeZone], item.key) &&
              (index !== 0
                ? timeZoneList[index].key !== timeZoneList[index - 1].key
                : timeZoneList[index].key) && (
                <CheckIcon className={classes.checkIcon} />
              )}
          </li>
        );
      })
      .value();
  }, [classes, onChangeTimeValue, onSettingChange, timeZoneList, currentTab1]);

  const timeListMarkup = useMemo(() => {
    const timelist = getTimelist();
    if (timelist) {
      return <ul>{timelist}</ul>;
    }
  }, [getTimelist]);

  useEffect(() => {
    if (timeZoneValue === undefined && currentTab1?.TimeZone) {
      setPreTimeZone();
    }
  }, [currentTab1?.TimeZone, timeZoneValue, setPreTimeZone]);

  return (
    <Card variant="outlined" className={classes.card}>
      <div className={classes.timeZoneSelectWrap}>
        <Typography category="Default" variant="Body">
          {t("Time Zone")}
        </Typography>
        <div className={classes.selectDiv}>
          <div
            className={classes.inputGPStime}
            ref={timeListAnchorRef}
            onMouseLeave={() => {
              if (openTimelist) {
                document.addEventListener("click", onClickOutsideListener);
              }
            }}
          >
            <OutlinedInput
              classes={{
                root: classes.outlinedInputRoot,
              }}
              placeholder={preTimeZoneValue}
              value={timeZoneValue}
              onChange={onChangeTimeInput}
              onFocus={() => {
                setPreTimeZone();
                setTimeZoneValue("");
                setOnChangeTimeValue("");
                // setOpenTimelist(true);
              }}
              // mantis - 10716, 화살표버튼 누르면 리스트출력되도록 수정 (Leehj)
              endAdornment={
                <IconButton
                  onClick={() => {
                    setOpenTimelist((o) => !o);
                  }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              }
            />
            <Popper
              dir={theme.direction}
              open={openTimelist}
              anchorEl={timeListAnchorRef.current}
              modifiers={{
                offset: {
                  enabled: false,
                  offset: "0px, 4px",
                },
                // mantis - 10716, 리스트 아래로 열리도록 수정 (Leehj)
                flip: { enabled: false },
              }}
              onClick={() => {
                document.removeEventListener("click", onClickOutsideListener);
              }}
              style={{
                zIndex: 99,
                position: "absolute",
              }}
              // mantis - 10716, 모바일일 때 리스트 가운데에서 열리도록 수정 (Leehj)
              placement={mobile ? "bottom" : "bottom-end"}
              transition
            >
              <Paper classes={{ root: classes.timeListPaper }}>
                <div className={classes.timeListDiv}>
                  <ScrollBar>{timeListMarkup}</ScrollBar>
                </div>
              </Paper>
            </Popper>
          </div>
        </div>
      </div>

      <Divider className={classes.contentDivider} />

      <div className={classes.switchWrap}>
        <div className={classes.switch}>
          <Switch
            checked={getBoolean(currentTab1?.["Daylight"] ?? "0")}
            onChange={(e) => {
              onSettingChange("Daylight", e.target.checked ? "1" : "0");
            }}
          />
        </div>
        <div>
          <Typography
            category="Default"
            variant="Body"
            style={{ marginBottom: mobile ? 3 : 2 }}
          >
            {t("Daylight saving time")}
          </Typography>
        </div>
      </div>
    </Card>
  );
};
