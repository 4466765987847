import { makeStyles, Theme } from "@material-ui/core/styles";
import { WebMenuItem } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import React from "react";

import CheckIcon from "@material-ui/icons/Check";
import { useSelector } from "react-redux";
import { RootState } from "../features/store";
import { THEME } from "../features/Theme/slice";
import { Languages } from "@thingsw/pitta-modules";

export interface LangMenuItemsProps {
  mode: "main" | "webviewer";
  language: string;
  mobile?: boolean;
  onChangeLanguage: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    language: string
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobile: {
    minHeight: 48,
  },
  menu: {
    color: (props: any) => props.colors.primary["1"],
    [theme.breakpoints.up("sm")]: {
      minWidth: (props: LangMenuItemsProps) =>
        props.mode === "main" ? 142 : 185,
    },
    ...(theme.direction === "rtl"
      ? {
          paddingRight: 41,
        }
      : {
          paddingLeft: 41,
        }),
  },
  menuSelected:
    theme.direction === "rtl"
      ? {
          paddingRight: 13,
        }
      : {
          paddingLeft: 13,
        },
  menuJA: {
    "& > span": {
      fontFamily: "'Noto Sans JP'!important",
    },
  },
  menuKR: {
    "& > span": {
      fontFamily: "'Noto Sans KR'!important",
    },
  },
  menuSC: {
    "& > span": {
      fontFamily: "'Noto Sans SC'!important",
      direction: "ltr",
    },
  },
  menuThai: {
    "& > span": {
      fontFamily: "'Noto Sans Thai UI'!important",
    },
  },
  menuArabic: {
    "& > span": {
      fontFamily: "'Noto Naskh Arabic UI'!important",
    },
  },
}));

export const LangMenuItems = ({
  mode,
  mobile,
  language,
  onChangeLanguage,
}: LangMenuItemsProps) => {
  const { color, colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ colors });

  return (
    <>
      {mode === "main"
        ? _.chain(Languages)
            .pick(["en", "ja", "ko"])
            .map((value, key) => (
              <WebMenuItem
                darkMode={color === "dark"}
                key={key}
                className={clsx(
                  classes.menu,
                  mobile && classes.mobile,
                  key === language && classes.menuSelected,
                  key === "ja" && classes.menuJA,
                  key === "th" && classes.menuThai,
                  key === "ar" && classes.menuArabic,
                  key === "kr" && classes.menuKR,
                  key === "sc" && classes.menuSC
                )}
                startIcon={key === language && <CheckIcon fontSize="small" />}
                onClick={(evt) => onChangeLanguage(evt, key)}
              >
                {value}
              </WebMenuItem>
            ))
            .value()
        : _.map(Languages, (value, key) => (
            <WebMenuItem
              darkMode={color === "dark"}
              key={key}
              className={clsx(
                classes.menu,
                mobile && classes.mobile,
                key === language && classes.menuSelected,
                key === "ja" && classes.menuJA,
                key === "th" && classes.menuThai,
                key === "ar" && classes.menuArabic,
                key === "kr" && classes.menuKR,
                key === "sc" && classes.menuSC
              )}
              startIcon={key === language && <CheckIcon fontSize="small" />}
              onClick={(evt) => onChangeLanguage(evt, key)}
            >
              {value}
            </WebMenuItem>
          ))}
    </>
  );
};
