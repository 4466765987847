import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICameraCluster,
  ICameraInfo,
  ILatLng,
  ILatLngBounds,
  ITrackData,
} from "@thingsw/pitta-modules";
import _ from "lodash";
import moment from "moment-timezone";

export interface ISpeed {
  Knot: number;
  MPH: number;
  Km: number;
}

export interface IGeofenceAlertOrig {
  Lastloc: string;
  fenceName: string;
  sendStatus: string;
  seq: number;
  time: string;
  speed: ISpeed;
}

export interface IGeofenceAlert {
  latLng: ILatLng;
  fenceName: string;
  sendStatus: "Enter" | "Exit" | "Pass" | "Speed";
  seq: number;
  time: moment.Moment;
  speed: ISpeed;
}

export interface IGeofenceAlertList {
  drive_no: number;
  data: IGeofenceAlert[];
}

export interface ITrackDataOrig {
  sid: number;
  vdate: number;
  loc: number[];
  speed: number;
  alt: number;
  mode: "N" | "E" | "P" | "M";
  hide?: boolean;
  mtype: number;
  rid_front?: string;
  rid_rear?: string;
  rid_3?: string;
  sub_front?: string;
  sub_rear?: string;
  sub_3?: string;
  thm?: string;
  avg_speed: number;
  mp4_playable: 0 | 1;
  t30s: number;
  t1m: number;
  t2m: number;
  t4m: number;
  t8m: number;
  t16m: number;
}

export interface IDriveInfoOrig {
  drive_no: number;
  sdate: number;
  edate?: number;
  cnt?: number;
}

export interface IDriveInfo {
  drive_no: number;
  sdate: moment.Moment;
  edate: moment.Moment;
  cnt: number;
  present: boolean;
}

export interface ITrackInfo {
  drive_no: number;
  sdate: moment.Moment;
  edate: moment.Moment;
  data: ITrackData[];
}

export interface ITrackInfoOrig {
  drive_no: number;
  data: ITrackDataOrig[];
}

interface IState {
  loading: boolean;
  clusters?: ICameraCluster;
  driveList: IDriveInfo[];
  dates: string[];
  interval?: number;
  tracks: ITrackInfo[];
  type?: string;
  geofenceAlerts: IGeofenceAlertList[];
  selectedDrive?: ITrackInfo;
  updateLoc?: "map" | "panel";
  selectedTrack?: ITrackData;
  selectedDrives: IDriveInfo[];
  camera?: ICameraInfo;
  updateMapBounds: boolean;
  showTracks: boolean;
  selectedDates: moment.Moment[];
  showedDates: moment.Moment[];
  candiDrives: IDriveInfo[];
  firstFitBounds: boolean;
  pickerType: "range" | "multi";
}

let initialState: IState = {
  loading: false,
  clusters: undefined,
  driveList: [],
  dates: [],
  tracks: [],
  geofenceAlerts: [],
  selectedDrives: [],
  updateMapBounds: true,
  showTracks: false,
  selectedDates: [],
  candiDrives: [],
  showedDates: [],
  firstFitBounds: true,
  pickerType: "range",
};

const slice = createSlice({
  name: "gps",
  initialState,
  reducers: {
    clearGPS: (state) => {
      state.loading = false;
      state.clusters = undefined;
      state.driveList = [];
      state.dates = [];
      state.interval = undefined;
      state.tracks = [];
      state.type = undefined;
      state.geofenceAlerts = [];
    },
    clearGPSDriveData: (state) => {
      state.driveList = [];
      state.dates = [];
    },
    loadCluster: (state) => {
      state.loading = true;
    },
    successLoadCluster: (state, { payload }: PayloadAction<ICameraCluster>) => {
      state.loading = false;
      state.clusters = payload;
    },
    loadGPSDriveData: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGPSDriveData: (
      state,
      { payload }: PayloadAction<IDriveInfo[]>
    ) => {
      state.loading = false;
      state.driveList = payload;
      state.dates = _.chain(state.driveList)
        .map((track) => [
          track.sdate.format("YYYYMMDD"),
          track.edate?.format("YYYYMMDD"),
        ])
        .flattenDeep()
        .compact()
        .uniq()
        .value();
    },
    loadGPSTrackingData: (
      state,
      action: PayloadAction<{
        psn: string;
        drive_no_list: number[];
        cancel: AbortController;
        bounds?: ILatLngBounds;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGPSTrackingData: (
      state,
      { payload }: PayloadAction<{ interval: number; tracks: ITrackInfo[] }>
    ) => {
      state.loading = false;
      state.interval = payload.interval;
      // state.tracks = payload.tracks;
      const drive_no = _.union(
        _.map(state.tracks, (t) => t.drive_no),
        _.map(payload.tracks, (t) => t.drive_no)
      );
      state.tracks = _.chain(drive_no)
        .map((dn) => {
          const track = _.find(state.tracks, (t) => t.drive_no === dn);
          const aTrack = _.find(payload.tracks, (t) => t.drive_no === dn);
          if (aTrack && track) {
            return {
              drive_no: track.drive_no,
              sdate: track.sdate.isBefore(aTrack.sdate)
                ? track.sdate
                : aTrack.sdate,
              edate: track.edate.isAfter(aTrack.edate)
                ? track.edate
                : aTrack.edate,
              data: _.unionWith(
                track.data,
                aTrack.data,
                (a, b) => a.sid === b.sid
              ).sort((a, b) => a.sid - b.sid),
            };
          }
          if (track) {
            return track;
          }

          return aTrack;
        })
        .compact()
        .value();
    },
    clearGPSTrackingData: (state) => {
      state.tracks = [];
      state.interval = undefined;
    },
    loadGeofenceAlert: (
      state,
      action: PayloadAction<{ psn: string; drive_no_list: number[] }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGeofenceAlert: (
      state,
      { payload }: PayloadAction<IGeofenceAlertList[]>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.geofenceAlerts = payload;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    setSelectedDrive: (
      state,
      { payload }: PayloadAction<ITrackInfo | undefined>
    ) => {
      state.selectedDrive = payload;
    },
    setSelectedTrack: (
      state,
      {
        payload,
      }: PayloadAction<
        { updateLoc: "map" | "panel"; track: ITrackData } | undefined
      >
    ) => {
      state.updateLoc = payload?.updateLoc;
      state.selectedTrack = payload?.track;
    },
    setCameraInfo: (
      state,
      { payload }: PayloadAction<ICameraInfo | undefined>
    ) => {
      if (state.camera === undefined || state.camera?.psn !== payload?.psn) {
        state.camera = payload;
      }
    },
    setSelectedDrives: (state, { payload }: PayloadAction<IDriveInfo[]>) => {
      state.selectedDrives = payload;
    },
    setUpdateMapBounds: (state, { payload }: PayloadAction<boolean>) => {
      state.updateMapBounds = payload;
    },
    setShowTracks: (state, { payload }: PayloadAction<boolean>) => {
      state.showTracks = payload;
    },
    setSelectedDates: (state, { payload }: PayloadAction<moment.Moment[]>) => {
      state.selectedDates = payload;
    },
    setShowedDates: (state, { payload }: PayloadAction<moment.Moment[]>) => {
      state.showedDates = payload;
    },
    setCandiDrives: (state, { payload }: PayloadAction<IDriveInfo[]>) => {
      state.candiDrives = payload;
    },
    setFirstFitBounds: (state, { payload }: PayloadAction<boolean>) => {
      state.firstFitBounds = payload;
    },
    setPickerType: (state, { payload }: PayloadAction<"range" | "multi">) => {
      state.pickerType = payload;
    },
  },
});

export const {
  clearGPSDriveData,
  clearGPS,
  loadCluster,
  successLoadCluster,
  loadGPSDriveData,
  successLoadGPSDriveData,
  loadGPSTrackingData,
  successLoadGPSTrackingData,
  clearGPSTrackingData,
  loadGeofenceAlert,
  successLoadGeofenceAlert,
  clearLoading,
  setSelectedDrive,
  setSelectedTrack,
  setCameraInfo,
  setSelectedDrives,
  setUpdateMapBounds,
  setShowTracks,
  setSelectedDates,
  setShowedDates,
  setCandiDrives,
  setFirstFitBounds,
  setPickerType,
} = slice.actions;

export const GPS = slice.name;
export default slice.reducer;
