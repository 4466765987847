import { BatteryHistoryItem } from "@thingsw/pitta-modules";

export const getDayData = (
  batteryHistory: BatteryHistoryItem[],
  datasetCalcCallback: (n: BatteryHistoryItem) => number
) => {
  const dateLabels: any[] = []; // 날짜 라벨 (변경되는 시점에 날짜 표시)

  const labels: Date[] = [];

  const dataset = new Array(24).fill(null);

  const now = new Date();

  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  labels.push(now);

  while (labels.length < 24) {
    // 배열의 첫 번째 요소에서 1을 뺀 값(0 다음은 23)

    const newDate = new Date(labels[0].getTime());
    newDate.setHours(newDate.getHours() - 1);

    // 배열의 맨 앞에 새로운 값을 추가
    labels.unshift(newDate);
  }

  batteryHistory.forEach((history: BatteryHistoryItem) => {
    const date = new Date(history.date);

    const index = labels.findIndex(
      (label) => label.getTime() === date.getTime()
    );

    if (index >= 0) {
      dataset[index] = datasetCalcCallback(history);
    }
  });

  labels.forEach((label, i) => {
    if (label.getHours() === 0 && batteryHistory[0]) {
      const date = new Date(batteryHistory[0].date);

      date.setDate(date.getDate() + 1);

      dateLabels[i] = date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    } else if (label.getHours() === 0 && !batteryHistory[0]) {
      dateLabels[i] = label.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    } else {
      dateLabels[i] = "";
    }
  });

  const parsedLabels: any[] = [];

  for (let i = 0; i < labels.length; i++) {
    if (i === labels.length - 4) parsedLabels.push(labels[i].getHours());
    else if (i === labels.length - 8) parsedLabels.push(labels[i].getHours());
    else if (i === labels.length - 12) parsedLabels.push(labels[i].getHours());
    else if (i === labels.length - 16) parsedLabels.push(labels[i].getHours());
    else if (i === labels.length - 20) parsedLabels.push(labels[i].getHours());
    else if (i === labels.length - 24) parsedLabels.push(labels[i].getHours());
    else parsedLabels.push("");
  }

  return {
    labels: parsedLabels,
    dataset,
    dateLabels,
  };
};

export const getWeekData = (
  batteryHistory: BatteryHistoryItem[],
  datasetCalcCallback: (n: BatteryHistoryItem) => number
) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const dateLabels = new Array(42).fill(""); // 날짜 라벨 (변경되는 시점에 날짜 표시)

  const labels: Date[] = [];

  const dataset = new Array(42).fill(null);

  const now = new Date();

  now.setHours(20);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  labels.push(now);

  while (labels.length < 42) {
    const newDate = new Date(labels[0].getTime());
    newDate.setHours(newDate.getHours() - 4);

    // 배열의 맨 앞에 새로운 값을 추가
    labels.unshift(newDate);
  }

  batteryHistory.forEach((history: BatteryHistoryItem, i) => {
    const date = new Date(history.date);

    date.setHours(get4Hour(date));

    const index = labels.findIndex(
      (label) => label.getTime() === date.getTime()
    );

    if (index >= 0) {
      dataset[index] = datasetCalcCallback(history);
    }
  });

  dateLabels[0] = labels[0].toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const parsedLabels = labels.map((label) => {
    if (label.getHours() === 0) {
      return daysOfWeek[label.getDay()];
    } else {
      return "";
    }
  });

  return {
    labels: parsedLabels,
    dataset,
    dateLabels,
  };
};

const get4Hour = (now: Date) => {
  const newDate = new Date(now.getTime());

  if (newDate.getHours() >= 0 && newDate.getHours() <= 4) {
    newDate.setHours(0);
  } else if (newDate.getHours() >= 5 && newDate.getHours() <= 8) {
    newDate.setHours(4);
  } else if (newDate.getHours() >= 9 && newDate.getHours() <= 12) {
    newDate.setHours(8);
  } else if (newDate.getHours() >= 13 && newDate.getHours() <= 16) {
    newDate.setHours(12);
  } else if (newDate.getHours() >= 17 && newDate.getHours() <= 20) {
    newDate.setHours(16);
  } else if (newDate.getHours() >= 21 && newDate.getHours() <= 23) {
    newDate.setHours(20);
  }

  return newDate.getHours();
};
