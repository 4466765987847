import React from "react";
import { Modal, Switch, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  TabNameInfo3,
  Webviewer,
  LightColors,
  TrueFalsetype,
} from "@thingsw/pitta-modules";

interface FirmwareSettingModalProps {
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
  currentTab3?: TabNameInfo3;
  onChange: (val: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 343,
    margin: 0,
    "&>.MuiDialogActions-root": {
      justifyContent: "center",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        justifyContent: "flex-end",
      },
    },
    "&>.buttonPadding": {
      padding: "0px 23px 24px",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 560,
    },
  },

  switch: {
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 20,
    },
  },

  optionDiv: {
    display: "flex",
    padding: "22px 23px 20px",
    border: `1px solid ${LightColors.primary["4"]}`,
    borderRadius: 12,
    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.05);",
    margin: "24px 20px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "24px 42.5px 0px",
    },
  },

  modalTitleDiv: {
    minHeight: 28,
  },

  headingText: {
    fontSize: "25px !important",
  },
}));

export const SOSModal = ({
  open,
  onChange,
  onClose,
  onClickPositive,
  currentTab3,
}: FirmwareSettingModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const getBoolean = (TrueFalse: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };

  return (
    <Modal
      btnCenterMobile={mobile}
      className={classes.root}
      headingTextClassName={classes.headingText}
      titleClassName={classes.modalTitleDiv}
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickPositive={onClickPositive}
      heading={t("SOS button on/off")}
      close
      content={
        <>
          <div>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {currentTab3?.EBRFID}
            </Typography>
            <div className={classes.optionDiv}>
              <div className={classes.switch}>
                <Switch
                  checked={getBoolean(
                    currentTab3?.["PSENSOR"] as TrueFalsetype
                  )}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              </div>
              <Typography category="Default" variant="Body">
                {t("Manual recording")}
              </Typography>
            </div>
          </div>
        </>
      }
      RButton={t("OK")}
      Secondary={false}
    />
  );
};
