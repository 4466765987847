import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  loadCameras,
  loadFirmwareConfig,
  loadPublicCamera,
  updateCameras,
} from "../../features/Camera/slice";
import { initGPSWs } from "../../features/LocationWS/slice";
import { loadSubscription } from "../../features/Payment/slice";
import {
  clearEvent,
  PUSH_EVENT,
  updateEvent,
} from "../../features/PushEvent/slice";
// import {
//   disconnectWebPush,
//   loadPushToken,
// } from "../../features/PushEvent/slice";
import { RootState } from "../../features/store";
import { loadUsageInfo, USER } from "../../features/User/slice";
import { useQuery } from "../../hooks/useQuery";
import { Direction, ICameraInfo } from "@thingsw/pitta-modules";
import React from "react";
import { LiveViewPanelWrapper } from "../../components/cameras/LiveViewPanelWrapper";
import { PERMISSION } from "../../features/Permission/slice";

export interface LiveviewScreenProps {
  onBack?: (reason?: string) => void;
  noBottom?: boolean;
}

export const LiveviewScreen = ({ onBack, noBottom }: LiveviewScreenProps) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);
  const { permission } = useSelector((state: RootState) => state[PERMISSION]);
  const { cameraList, publicCamera } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const { webPushEvent } = useSelector((state: RootState) => state[PUSH_EVENT]);
  const [psn, setPsn] = useState<string | null>(null);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [isPublic, setIsPublic] = useState(false);
  const [notFoundCam, setNotFoundCam] = useState(false);

  useEffect(() => {
    if (loginInfo && email) {
      dispatch(initGPSWs());
    }
  }, [dispatch, email, loginInfo]);
  // useEffect(() => {
  //   if (email && !isPublic) {
  //     dispatch(loadPushToken(email));

  //     return () => {
  //       console.log("disconnectWebPush");
  //       dispatch(disconnectWebPush());
  //     };
  //   }
  // }, [dispatch, email, isPublic]);
  //@ts-ignore
  window.setPushNotification = useCallback(
    (msg: any) => {
      console.log("CloudNative", "setPushNotification", msg);
      const payload = atob(msg);
      dispatch(updateEvent(JSON.parse(payload)));
    },
    [dispatch]
  );

  useEffect(() => {
    if (webPushEvent) {
      const timer = setTimeout(() => {
        dispatch(clearEvent());
      }, 5 * 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, webPushEvent]);

  useEffect(() => {
    dispatch(loadCameras());
    dispatch(loadUsageInfo());
    dispatch(loadSubscription());
  }, [dispatch]);

  useEffect(() => {
    function tick() {
      dispatch(updateCameras());
    }

    let timer: number | null = null;
    let id = setInterval(tick, 30 * 1000);

    return () => {
      clearInterval(id);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    setPsn(query.get("psn"));
  }, [query]);

  useEffect(() => {
    console.log("CloudNative", "cameraList", cameraList);
    if (cameraList) {
      const cam = _.chain(cameraList?.deviceListInfo)
        .map((c) => c.device)
        .find((c) => c.psn === psn)
        .value();
      if (cam) {
        setCurrentCam((c) => {
          if (c?.psn !== cam?.psn) {
            return cam;
          } else if (c?.active !== cam?.active) {
            return cam;
          }
          return c;
        });
      } else {
        setNotFoundCam(true);
      }
    }
  }, [psn, cameraList?.deviceListInfo, dispatch, cameraList]);

  useEffect(() => {
    if (notFoundCam && psn) {
      setIsPublic(true);
      dispatch(loadPublicCamera(psn));
    }
  }, [dispatch, notFoundCam, psn]);

  useEffect(() => {
    if (publicCamera) {
      setCurrentCam(publicCamera);
    }
  }, [publicCamera]);

  useEffect(() => {
    if (currentCam) {
      dispatch(loadFirmwareConfig(currentCam));
    }
  }, [currentCam, dispatch]);

  const direction = useMemo(() => {
    const dir = query.get("direction");
    return dir ? (parseInt(dir) as Direction) : undefined;
  }, [query]);

  const speedUnit = useMemo(() => {
    const unit = query.get("speed_unit");
    return unit ? (unit as "0" | "1") : undefined;
  }, [query]);

  console.log("currentCam", currentCam);

  return currentCam ? (
    <LiveViewPanelWrapper
      camera={currentCam}
      app
      onBack={onBack}
      isPublic={isPublic}
      dir={direction}
      speedUnit={speedUnit}
      noBottom={noBottom}
      {...permission}
    />
  ) : (
    <></>
  );
};
