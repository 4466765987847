import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";

import Footer from "../components/Footer";
import { MainHeader } from "../components/MainHeader";
import { NavigationDrawer } from "../components/NavigationDrawer";
import { Pricing } from "../components/Pricing";
import withSimpleBar from "../hoc/withSimpleBar";

export const PricingScreen = withSimpleBar(() => {
  const [value] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();

  const handleClickMenu = () => {
    setOpenDrawer((open) => !open);
  };

  const handleClickPricing = () => {
    history.push("/pricing");
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <MainHeader
        value={value}
        color="default"
        onClickMenu={handleClickMenu}
        pricing
      />
      <Pricing onClose={() => {}}/>
      <Footer />
      <Hidden mdUp>
        <NavigationDrawer
          open={openDrawer}
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
          onClickCloud={() => setOpenDrawer(false)}
          onClickFleet={() => setOpenDrawer(false)}
          onClickPricing={handleClickPricing}
        />
      </Hidden>
    </React.Fragment>
  );
});
