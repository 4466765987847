import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Card,
  // CardContent,
  CardHeader,
  useMediaQuery,
} from "@material-ui/core";
import { Avatars, Typography, Fonts } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";

const breakpoint = 834;

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    boxShadow: "none",
    border: `1px solid ${LightColors.primary["5"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 672,
    },
  },
  root: {
    position: "relative",
    padding: 22.5,
    textAlign: "center",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      textAlign: "inherit",
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(0, 0, 2.125, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { margin: "0px 0 0px 31px" }
        : { margin: "0px 31px 0px 0px" }),
    },
  },
  title: {
    marginBottom: theme.spacing(0.5) + 1,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: theme.spacing(0.25),
      marginBottom: 0,
    },
  },
}));

export const GroupAdminCard = () => {
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(breakpoint));
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.cardRoot}>
      <CardHeader
        classes={classes}
        avatar={
          <Avatars
            imgSrc={""}
            name=""
            mode="admin"
            size="large"
            style={{
              ...Fonts.Default.H1,
            }}
          ></Avatars>
        }
        title={
          <Typography
            category="Default"
            variant={mobile ? "Body" : "H6"}
            htmlColor={
              mobile ? LightColors.primary["3"] : LightColors.primary["2"]
            }
          >
            {t("No admin yet")}
          </Typography>
        }
        subheader={
          <div>
            <Typography
              category="Default"
              variant={mobile ? "Caption" : "Body"}
              htmlColor={
                mobile ? LightColors.primary["3"] : LightColors.primary["2"]
              }
            >
              {t("You can add_admin")}
            </Typography>
          </div>
        }
      />
    </Card>
  );
};
