import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export interface Status {
  openModal: boolean;
  onClose: () => void;
  onClickPositive: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    width: 560,
    minHeight: 180,
    [theme.breakpoints.up("sm")]: {},
    "& .modalTitle": {
      color: "#0095E0",
      marginTop: 0,
      marginBottom: 4,
    },
    "& .modalSubTitle": {
      color: "#0095E0",
      marginTop: 0,
      marginBottom: 8,
    },
    "& .modalPaging": {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      marginTop : 24,
    },
  },

  modalHeading: {
    padding: "16px 42px 0px 24px",
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
    [theme.breakpoints.up("sm")]: {},
  },
  headingTextIcon: {
    padding: 0,
  },

  modalContent: {
    padding: "14px 24px 32px",
    overflow: "hidden",
    whiteSpace: "pre-line",
    "& .modalContImg": {
      width: "100%",
      aspectRatio: "1/0.56",
      marginTop: 24,
    },
    [theme.breakpoints.up("sm")]: {},
  },

  modalButton: {
    padding: "0px 24px auto",
    [theme.breakpoints.up("sm")]: {},
  },

  modalClose: {
    top: 12,
    
    // id - 166
    // 아랍어 시 direction 적용
    ...(theme.direction === "rtl" ? { left: 13 } : { right: 13 }),
    [theme.breakpoints.up("sm")]: {},
  },

  LBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
  },

  RBtn: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Noto Sans KR",
  },
}));

export const GPSTrackingModal = (props: Status) => {
  const { openModal, onClose, onClickPositive } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      className={classes.modalBody}
      open={openModal}
      close
      closeStyle={classes.modalClose}
      onClose={onClose}
      titleClassName={classes.modalHeading}
      content={
        <>
          <HelpOutlineIcon style={{marginBottom: 26}}/>
          <p className="modalTitle">
            <Typography
              category="Default"
              variant="H4"
            >
              {t("GPS Tracking &_")}
            </Typography>
          </p>
          <p className="modalSubTitle">
          <Typography
            category="Default"
            variant="Body"
          >
            {t("Visualize your cars_")}
          </Typography>
          </p>
          <Typography
            category="Default"
            variant="Small"
            style={{ fontFamily: "Noto Sans KR", fontSize: "16px" }}
          >
            {t("GPS Tracking: visualize_")}
          </Typography>
          <div 
            className="modalContImg"
            style={{ background: "url(/images/pricing-modal-gps-tracking.png) no-repeat center/contain" }}
          >
          </div>
          <div className="modalPaging">              
            <Typography
              category="Default"
              variant="Small"
              style={{marginRight: 8}}
            >
              {"*" + t("Cloud-compatible BlackVue")}
            </Typography>
          </div>
        </>
      }
      contentClassName={classes.modalContent}
      RButton={t("OK")}
      onClickPositive={() => {
        onClickPositive();
      }}
      onClickNegative={onClose}
      actionClassName={classes.modalButton}
      Secondary={false}
      RButtonClassName={classes.RBtn}
    />
  );
};
