import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { UpgradePlanPanel } from "../components/UpgradePlanPanel";
import { PermissionProps, ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { ReportScreen } from "../screens/ReportScreen";

export const ReportRouter = (props: ScreenDefaultProps & PermissionProps) => {
  const { plan } = props;
  const { path } = useRouteMatch();

  if (
    plan === "Fleet plan" ||
    plan === "Free plan" ||
    plan === "Smart plan" ||
    plan === "Lite plan"
  ) {
    return (
      <Switch>
        <Route exact path={path} render={() => <ReportScreen {...props} />} />
      </Switch>
    );
  }

  if (plan === "Basic plan") {
    return <UpgradePlanPanel plan={plan} />;
  }
  return <div></div>;
};
