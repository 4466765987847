import React, { useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  IconButton,
  Typography,
} from "@thingsw/pitta-design-system";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
import { Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import SimpleBarReact from "simplebar-react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "#13131C73",
    zIndex: 10000,
  },
  hidden: {
    display: "none",
  },
  modal: {
    position: "absolute",
    top: 100,
    right: 130,
    width: 696,
    height: 577,
    "&::after": {
      position: "absolute",
      content: "''",
      top: -24,
      bottom: 0,
      right: 160,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderBottom: "24px solid white",
      borderLeft: "14px solid transparent",
      borderRight: "14px solid transparent",
    },
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    position: "absolute",
    padding: "10px 12px",
    top: 0,
    right: 128,
    background: "white",
    borderRadius: 10,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  webTitleDiv: {
    padding: theme.spacing(2, 3, 0, 3),
  },
  buttonWrap: {
    paddingTop: theme.spacing(6),
    alignItems: "center",
  },

  contentBtnWrap: {
    marginTop: theme.spacing(2.5),
    overflowY: "auto",
    padding: theme.spacing(0, 3, 3, 3),
  },
  webImgDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 48,
  },
  modalBottom: {
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  modalContentWrap: {
    border: "none",
    padding: theme.spacing(2),
    paddingTop: 9,
    flexDirection: "column",
  },
  scrollbarStyle: {
    height: "100%",
    overflowX: "hidden",
    flex: 1,
    maxHeight: "calc(100vh - 120px)",
  },
  backBtn: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
}));

interface OnboardingStep1Props {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
}

export const OnboardingStep1 = ({
  open,
  onNext,
  onClose,
}: OnboardingStep1Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={clsx(classes.root, !open && classes.hidden)}>
        <div ref={anchorRef} className={classes.btn}>
          <ButtonGroup value={0} onChange={() => {}}>
            <Button startIcon={<MapIcon />}>{t("Map")}</Button>
            <Button startIcon={<ListIcon />}>{t("List")}</Button>
          </ButtonGroup>
        </div>
        <div className={classes.modal}>
          <Paper elevation={3} className={classes.paper}>
            <div className={clsx(classes.spaceBetween, classes.webTitleDiv)}>
              <Typography category="Default" variant="H6">
                {t("Map view")}
              </Typography>
              <IconButton onClick={onClose} style={{ marginRight: -8 }}>
                <CloseIcon />
              </IconButton>
            </div>
            <SimpleBarReact className={classes.scrollbarStyle}>
              <div className={classes.contentBtnWrap}>
                <Typography category="Default" variant="Body">
                  {t("Check the current_")}
                </Typography>
                <div className={classes.webImgDiv}>
                  <img
                    srcSet="/images/onboarding1.png 1x"
                    alt="onboarding1"
                    style={{
                      width: 555,
                      height: 336,
                    }}
                  />
                </div>
                <div className={clsx(classes.spaceBetween, classes.buttonWrap)}>
                  <Typography category="Default" variant="Body">
                    1 / 5
                  </Typography>
                  <div>
                    {/* 모바일과 동일하게 버튼 비활성화 */}
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled
                      className={classes.backBtn}
                    >
                      {t("Back")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onNext}
                    >
                      {t("Got it")}
                    </Button>
                  </div>
                </div>
              </div>
            </SimpleBarReact>
          </Paper>
        </div>
      </div>
    </>
  );
};
