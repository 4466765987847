import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Container, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: 76,
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
  },

  body: {
    display: "flex",
    justifyContent: "center",
    padding: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.up("md")]: {
      padding: "31px 24px 24px 24px",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "45px",
    },
  },
  anchor: {
    marginRight: theme.spacing(3),
    color: LightColors.primary["3"],
    "&:hover": {
      "&>span": {
        color: LightColors.primary["7"],
      },
    },
    "&:visited": {
      color: LightColors.primary["3"],
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

export const MinimalFooter = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <div className={classes.root}>
      <Container className={classes.body}>
        <a
          className={classes.anchor}
          href={
            // 한국어 / 일본어 일시 해당 국가의 Terms & Policy로 이동 (KimSH)
            i18n.language === "ko"
              ? "https://blackvue.com/ko/terms-conditions-korean/"
              : i18n.language === "ja"
              ? "https://blackvue.com/ja/terms-conditions-japan/"
              : "https://blackvue.com/warranty-terms-conditions/"
          }
          target="_blank"
          rel="noreferrer"
        >
          <Typography category="Default" variant="Small">
            {t("Terms & Policies")}
          </Typography>
        </a>
        <a
          className={classes.anchor}
          href="https://helpcenter.blackvue.com/hc/en-us"
          target="_blank"
          rel="noreferrer"
        >
          <Typography category="Default" variant="Small">
            {t("Help")}
          </Typography>
        </a>
      </Container>
    </div>
  );
};
