import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography } from "@thingsw/pitta-design-system";

import {
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  FormHelperText,
} from "@material-ui/core";

import { getFormValues } from "redux-form";

import { useSelector } from "react-redux";
import { USER } from "../features/User/slice";
import { RootState } from "../features/store";
import _ from "lodash";
import clsx from "clsx";
import { LightColors, IConfirmForm } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 58px)",
    alignItems: "center",
    padding: theme.spacing(3, 2, 0, 2),
  },
  rootDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 438,
    flexGrow: 1,
    marginTop: 90,

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },

  marginB4: {
    marginBottom: theme.spacing(4),
  },
  inputDiv: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    maxWidth: 64,
    minHeight: 44,
    textAlign: "center",
    borderRadius: 4,
    border: `1px solid ${LightColors.primary[4]}`,
    outline: 0,
    "&:focus": {
      borderColor: LightColors.primary[7],
    },
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
    "&:last-child ,&:nth-child(3)": {
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(0.5) }
        : { marginRight: theme.spacing(0.5) }),
    },
  },
  inputError: {
    borderColor: `${LightColors.secondary[11]}!important`,
  },
  inputMargin: {
    margin: "0 11px",
  },
}));

const EmailConfirmForm = (props: {
  initialValues?: Partial<IConfirmForm>;
  onSubmit: (data: IConfirmForm) => void;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const reduxError = useSelector((state: RootState) => state[USER].error);
  const email = useSelector((state: RootState) => state[USER].email);
  const state = useSelector((state: RootState) => state);
  const [error, setError] = useState<string>();
  const [inputs, setInputs] = useState<string[]>(
    props.initialValues?.inputs ?? []
  );

  let input2: any[] = useMemo(() => [], []);

  useEffect(() => {
    return () => {
      setError(undefined);
    };
  }, []);

  useEffect(() => {
    const value = getFormValues("EmailConfirmForm")(state) as IConfirmForm;
    if (value) {
      setInputs(value.inputs);
    }
  }, [state]);

  useEffect(() => {
    if (reduxError) {
      setError(reduxError);
    }
  }, [reduxError]);

  // const handleInput = useCallback(
  //   (indx: number, key: string) => {
  //     const re = new RegExp(/[0-9]/);

  //     if (re.test(key)) {
  //       inputs[indx] = key;
  //       setInputs([...inputs]);
  //       if (indx < 5) {
  //         input2[indx + 1]?.focus();
  //       }
  //     } else if (key === "Backspace") {
  //       inputs[indx] = "";
  //       setInputs([...inputs]);
  //       if (indx > 0) {
  //         input2[indx - 1]?.focus();
  //       }
  //     }
  //     return inputs;
  //   },
  //   [input2, inputs]
  // );

  const handleKeyBoardInput = useCallback(
    (indx: number, key: string) => {
      if (key === "Backspace") {
        inputs[indx] = "";
        setInputs([...inputs]);
        if (indx > 0) {
          input2[indx - 1]?.focus();
        }
      }
      return inputs;
    },
    [input2, inputs]
  );

  const handleInput = useCallback(
    (indx: number, key: string) => {
      const re = new RegExp(/[0-9]/);

      if (re.test(key)) {
        inputs[indx] = key;
        setInputs([...inputs]);
        if (indx < 5) {
          input2[indx + 1]?.focus();
        }
      }
      return inputs;
    },
    [input2, inputs]
  );

  const handleSubmit = useCallback(
    (indx: number, key: string) => {
      handleInput(indx, key);
      if (
        inputs.length === 6 &&
        _.filter(inputs, (i) => i !== "").length === 6
      ) {
        props.onSubmit({ inputs });
      } else {
        setError("Code you entered_");
      }
    },
    [handleInput, inputs, props]
  );

  return (
    <Container className={clsx(classes.body)}>
      <div className={classes.rootDiv}>
        <Typography
          category="Default"
          variant={matches ? "H2" : "H1"}
          className={matches ? classes.marginB3 : classes.marginB4}
        >
          {t("Check your email")}
        </Typography>
        <Typography
          category="Default"
          variant="Body"
          className={classes.marginB4}
        >
          {t("We’ve sent a 6-digit confirmation code to")}{" "}
          <Typography category="Default" variant="BodyBold">
            {email}
          </Typography>
          .
        </Typography>
        <form method="post" style={{ marginBottom: 35 }}>
          <div className={classes.inputDiv}>
            <input
              name="inputs.0"
              ref={(ref: any) => (input2[0] = ref)}
              // error={!!error}
              // component={renderEmailField}
              className={clsx(classes.input, {
                [classes.inputError]: error,
              })}
              inputMode="numeric"
              type="text"
              pattern="[0-9]*"
              // maxLength={1}
              value={inputs[0]}
              // inputTextCenter
              // mantis - 10452, 안드로이드 네이버키보드에서 onKeyUp 사용시 숫자키 터치를 인식하지 못하여 숫자 입력은 onChange를 사용 (Leehj)
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyBoardInput(0, e.key);
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // mantis - 10452, 이미 입력된 인풋에 또 숫자 입력 시 나중에 입력된 숫자가 출력되도록 적용 (Leehj)
                e.target.value = e.target.value.replace(inputs[0], "");
                handleInput(0, e.target.value);
              }}
              // format={isNumber}
              autoFocus
            />
            <input
              name="inputs.1"
              ref={(ref: any) => (input2[1] = ref)}
              // error={!!error}
              // component={renderEmailField}
              className={clsx(classes.input, {
                [classes.inputError]: error,
              })}
              inputMode="numeric"
              type="text"
              pattern="[0-9]*"
              value={inputs[1]}
              // inputTextCenter
              // mantis - 10452, 안드로이드 네이버키보드에서 onKeyUp 사용시 숫자키 터치를 인식하지 못하여 숫자 입력은 onChange를 사용 (Leehj)
              // maxLength={1}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyBoardInput(1, e.key)
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // mantis - 10452, 이미 입력된 인풋에 또 숫자 입력 시 나중에 입력된 숫자가 출력되도록 적용 (Leehj)
                e.target.value = e.target.value.replace(inputs[1], "");
                handleInput(1, e.target.value);
              }}
              // format={isNumber}
            />
            <input
              name="inputs.2"
              ref={(ref: any) => (input2[2] = ref)}
              // error={!!error}
              // component={renderEmailField}
              className={clsx(classes.input, {
                [classes.inputError]: error,
              })}
              inputMode="numeric"
              type="text"
              pattern="[0-9]*"
              value={inputs[2]}
              // inputTextCenter
              // mantis - 10452, 안드로이드 네이버키보드에서 onKeyUp 사용시 숫자키 터치를 인식하지 못하여 숫자 입력은 onChange를 사용 (Leehj)
              // maxLength={1}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyBoardInput(2, e.key)
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // mantis - 10452, 이미 입력된 인풋에 또 숫자 입력 시 나중에 입력된 숫자가 출력되도록 적용 (Leehj)
                e.target.value = e.target.value.replace(inputs[2], "");
                handleInput(2, e.target.value);
              }}
              // format={isNumber}
            />
            <div>
              <Typography
                category="Default"
                variant="Body"
                style={{ margin: "0 4px" }}
              >
                –
              </Typography>
            </div>
            <input
              name="inputs.3"
              ref={(ref: any) => (input2[3] = ref)}
              // error={!!error}
              // component={renderEmailField}
              className={clsx(classes.input, {
                [classes.inputError]: error,
              })}
              inputMode="numeric"
              type="text"
              pattern="[0-9]*"
              value={inputs[3]}
              // inputTextCenter
              // mantis - 10452, 안드로이드 네이버키보드에서 onKeyUp 사용시 숫자키 터치를 인식하지 못하여 숫자 입력은 onChange를 사용 (Leehj)
              // maxLength={1}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyBoardInput(3, e.key)
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // mantis - 10452, 이미 입력된 인풋에 또 숫자 입력 시 나중에 입력된 숫자가 출력되도록 적용 (Leehj)
                e.target.value = e.target.value.replace(inputs[3], "");
                handleInput(3, e.target.value);
              }}
              // format={isNumber}
            />
            <input
              name="inputs.4"
              ref={(ref: any) => (input2[4] = ref)}
              // error={!!error}
              // component={renderEmailField}
              className={clsx(classes.input, {
                [classes.inputError]: error,
              })}
              inputMode="numeric"
              type="text"
              pattern="[0-9]*"
              value={inputs[4]}
              // inputTextCenter
              // mantis - 10452, 안드로이드 네이버키보드에서 onKeyUp 사용시 숫자키 터치를 인식하지 못하여 숫자 입력은 onChange를 사용 (Leehj)
              // maxLength={1}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyBoardInput(4, e.key)
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // mantis - 10452, 이미 입력된 인풋에 또 숫자 입력 시 나중에 입력된 숫자가 출력되도록 적용 (Leehj)
                e.target.value = e.target.value.replace(inputs[4], "");
                handleInput(4, e.target.value);
              }}
              // format={isNumber}
            />
            <input
              name="inputs.5"
              ref={(ref: any) => (input2[5] = ref)}
              // error={!!error}
              // component={renderEmailField}
              className={clsx(classes.input, {
                [classes.inputError]: error,
              })}
              inputMode="numeric"
              type="text"
              pattern="[0-9]*"
              value={inputs[5]}
              // inputTextCenter
              // mantis - 10452, 안드로이드 네이버키보드에서 onKeyUp 사용시 숫자키 터치를 인식하지 못하여 숫자 입력은 onChange를 사용 (Leehj)
              // maxLength={1}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyBoardInput(5, e.key)
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // mantis - 10452, 이미 입력된 인풋에 또 숫자 입력 시 나중에 입력된 숫자가 출력되도록 적용 (Leehj)
                e.target.value = e.target.value.replace(inputs[5], "");
                handleSubmit(5, e.target.value);
              }}
              // format={isNumber}
              // onChange={handleSubmit}
            />
          </div>
          {error && (
            <FormHelperText style={{ color: LightColors.secondary["11"] }}>
              {t(error)}
            </FormHelperText>
          )}
        </form>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {t("Keep this page_")}
        </Typography>
      </div>
    </Container>
  );
};
export default EmailConfirmForm;
