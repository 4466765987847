import { Direction } from "@material-ui/core";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { List } from "react-virtualized";
const handleScroll = (ref: React.RefObject<List>) => (e: any) => {
  if (ref.current) {
    const { scrollTop, scrollLeft } = e.target;
    const { Grid } = ref.current;
    Grid?.handleScrollEvent({ scrollTop, scrollLeft });
  }
};

export const renderListHelper = (
  width: number,
  height: number,
  elem: React.ReactElement,
  mobile: boolean,
  direction: Direction,
  ref: React.RefObject<List>,
  scrollRef?: React.RefObject<Scrollbars> | null
) => {
  return mobile ? (
    elem
  ) : (
    <Scrollbars
      ref={scrollRef}
      onScroll={handleScroll(ref)}
      style={{ height, width }}
      autoHide
      renderView={(props) => (
        <div
          {...props}
          style={{
            marginLeft: direction === "rtl" ? -17 : 0,
            marginRight: direction === "rtl" ? 0 : -17,
            overflowY: "auto",
          }}
        />
      )}
    >
      {elem}
    </Scrollbars>
  );
};
