import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import { makeStyles, PropTypes, Theme } from "@material-ui/core";
import { LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  colorDefault: {
    backgroundColor: LightColors.primary["0"],
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  colorTransparent: {
    background: `${LightColors.primary["0"]}CD`,
  },
}));

interface AppBarProps {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: PropTypes.Color | "transparent";
  className?: string;
}

export const AppBar = React.forwardRef((props: AppBarProps, ref) => {
  const classes = useStyles();
  return (
    <MuiAppBar
      position="fixed"
      elevation={0}
      ref={ref}
      classes={classes}
      {...props}
    />
  );
});
