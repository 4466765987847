import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Link,
  Typography,
} from "@thingsw/pitta-design-system";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { ResetHeader } from "../components/ResetHeader";
import { USER, deleteAccountMail } from "../features/User/slice";
import { RootState } from "../features/store";
import { useHistory } from "react-router-dom";
import { PAYMENT } from "../features/Payment/slice";
import { getPlanFromServiceID } from "../utils/Service";
import { LightColors, Webviewer, SIMCARD_URI } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 58px)",
    // paddingTop: 114,
    alignItems: "center",
    padding: theme.spacing(7, 4, 2, 4),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: 320,
      padding: theme.spacing(0, 2, 3, 2),
    },
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 555,
    flexGrow: 1,
    margin: theme.spacing(5, 4, 0),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      maxWidth: "288px !important",
      padding: 0,
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  marginB3: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
      fontSize: "28px !important",
    },
  },
  marginB4: {
    marginBottom: theme.spacing(3),
  },
  marginB5: {
    marginBottom: theme.spacing(1),
    display: "flex",
  },
  btn: {
    "&:first-child": {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(2) }
        : { marginRight: theme.spacing(2) }),
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      "&:first-child": {
        margin: theme.spacing(0, 0, 2, 0),
      },
      width: 288,
    },
  },
}));

export const DeleteAccountScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const theme = useTheme() as Theme;
  const [planName, setPlanName] = useState<string>();

  const { loading, email, loginInfo, userProfile } = useSelector(
    (state: RootState) => state[USER]
  );

  const subscriptionInfo = useSelector(
    (state: RootState) => state[PAYMENT].subscriptionInfo
  );

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const handleDeleteAccount = () => {
    dispatch(deleteAccountMail());
  };

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  const simTextShow = useMemo(() => {
    if (
      userProfile?.userType === "Master" ||
      subscriptionInfo?.servicePlanID === 1 ||
      planName === "Smart plan"
    ) {
      return true;
    } else {
      return false;
    }
  }, [planName, subscriptionInfo?.servicePlanID, userProfile?.userType]);

  return (
    <React.Fragment>
      <ResetHeader />
      {/* Body */}
      <div className={classes.root} dir={theme.direction}>
        <Container className={classes.body}>
          <div className={classes.formDiv}>
            <Typography
              category="Default"
              variant="H2"
              className={classes.marginB3}
            >
              {t("Delete account")}
            </Typography>

            <div>
              <div className={classes.marginB4}>
                <Typography
                  category="Default"
                  variant={mobile ? "Small" : "Body"}
                >
                  {t(`If you delete your account, we will delete all information
                    associated with your account, including any video recordings
                    and location data that may be stored on our servers. `)}
                </Typography>
                <Typography
                  category="Default"
                  variant={mobile ? "SmallBold" : "BodyBold"}
                >
                  {t(`Once
                    deleted this information cannot be recovered.`)}
                  <br />
                  <br />
                </Typography>
                <Typography
                  category="Default"
                  variant={mobile ? "Small" : "Body"}
                  style={{ display: "flex" }}
                >
                  {t(`Deleting your
                    account does not cancel any plan or subscription you may
                    have purchased, as payments are handled by third-party
                    processors.`)}
                </Typography>
              </div>
              <div className={classes.marginB4}>
                <div className={classes.marginB5}>
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                    style={{ marginRight: 4 }}
                  >
                    {t("•")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                  >
                    {t(`In-app purchases can be canceled through the Apple App Store
                    or Google Play Store.`)}
                  </Typography>
                </div>
                <div className={classes.marginB5}>
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                    style={{ marginRight: 4 }}
                  >
                    {t("•")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                  >
                    {t(
                      `Fleet plan subscription can be canceled at blackvuecloud.com “Subscription” section.`
                    )}
                  </Typography>
                </div>
                <div className={classes.marginB5}>
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                    style={{ marginRight: 4 }}
                  >
                    {t("•")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                  >
                    {t(
                      `BlackVue Fleet Tracking subscription can be canceled in your PayPal account.`
                    )}
                  </Typography>
                </div>

                {simTextShow && (
                  <div className={classes.marginB5}>
                    <Typography
                      category="Default"
                      variant={mobile ? "Small" : "Body"}
                      style={{ marginRight: 4 }}
                    >
                      {t("•")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant={mobile ? "Small" : "Body"}
                    >
                      <Trans
                        t={t}
                        components={{
                          a: (
                            /* 스타일 수정하기 */
                            <Link
                              category="Default"
                              variant="Body"
                              mode="anchor"
                              style={{ color: LightColors.primary["7"] }}
                              onClick={() => {
                                window.open(
                                  `${SIMCARD_URI}?email=${email}&user_token=${loginInfo?.user_token}&token_type=web&lang=${i18n.language}`
                                );
                              }}
                            >
                              sim.blackvuecloud.com
                            </Link>
                          ),
                        }}
                      >
                        SIM card data_
                      </Trans>
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.marginB4}>
                <Typography
                  category="Default"
                  variant={mobile ? "SmallBold" : "BodyBold"}
                >
                  {t(
                    `We will send you an email to confirm your account deletion request. Please follow the instructions in that email to delete your account.`
                  )}
                </Typography>
              </div>
              <div>
                <Button
                  className={classes.btn}
                  color="secondary"
                  onClick={handleDeleteAccount}
                  loading={loading}
                >
                  {t("Delete account")}
                </Button>
                <Button
                  className={classes.btn}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.push("/account");
                  }}
                  disabled={loading}
                >
                  {t("Back to Profile")}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
