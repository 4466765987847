import React, { useCallback, useMemo } from "react";
import {
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Card,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { IconButton, Switch, Typography } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { CAMERA } from "../../features/Camera/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import _ from "lodash";
import {
  TabNameInfo3,
  Webviewer,
  TrueFalsetype,
  OPTION_CAMERA_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
  },

  card: {
    display: "flex",
    maxWidth: 673,
    minHeight: 49,
    alignItems: "center",
    borderRadius: 12,
    marginBottom: theme.spacing(1) - 1,
    boxShadow: "0px 2px 2px 0px rgb(0 0 0 / 6%)",
    padding: "11px 23px 12px",
  },

  switch: {
    marginRight: 20,
    display: "flex",
    alignItems: "center",
  },
}));

interface SystemLEDPanelProps {
  onClose: () => void;
  currentTab3?: TabNameInfo3;
  onChange: (key: keyof TabNameInfo3, val: boolean) => void;
}

export const SystemLEDPanel = ({
  onClose,
  currentTab3,
  onChange,
}: SystemLEDPanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { t } = useTranslation();

  const { firmware, firmwareConfig, camera } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const getBoolean = (TrueFalse: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };

  const settings = useMemo(() => {
    return firmwareConfig?.System;
  }, [firmwareConfig?.System]);

  const OPTION_MODEL = useMemo(() => {
    return _.includes(OPTION_CAMERA_MODELS, camera?.model);
  }, [camera?.model]);

  const renderSimpleSwitch = useCallback(
    (label: string, key: keyof TabNameInfo3) => {
      return (
        <Card variant="outlined" className={clsx(classes.card)}>
          <div className={classes.switch}>
            <Switch
              checked={getBoolean((currentTab3?.[key] as TrueFalsetype) ?? "1")}
              onChange={(e) => {
                onChange(key, e.target.checked);
              }}
            />
          </div>
          <div>
            <Typography
              category="Default"
              variant="Body"
              style={{ marginBottom: mobile ? 3 : 2 }}
            >
              {t(label)}
            </Typography>
          </div>
        </Card>
      );
    },
    [classes.card, classes.switch, currentTab3, mobile, onChange, t]
  );

  // 일본어 FW이고 키값이 NORMALLED이거나 PARKINGLED이면 : default값은 off
  const renderJPSimpleSwitch = useCallback(
    (label: string, key: keyof TabNameInfo3) => {
      return (
        <Card variant="outlined" className={clsx(classes.card)}>
          <div className={classes.switch}>
            <Switch
              checked={getBoolean((currentTab3?.[key] as TrueFalsetype) ?? "0")}
              onChange={(e) => {
                onChange(key, e.target.checked);
              }}
            />
          </div>
          <div>
            <Typography
              category="Default"
              variant="Body"
              style={{ marginBottom: mobile ? 3 : 2 }}
            >
              {t(label)}
            </Typography>
          </div>
        </Card>
      );
    },
    [classes.card, classes.switch, currentTab3, mobile, onChange, t]
  );

  return (
    <>
      <div className={classes.flex} style={{ marginBottom: 14 }}>
        <div>
          <IconButton
            style={{ marginRight: 7 }}
            onClick={() => {
              onClose();
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </div>
        <Typography category="Default" variant="H6">
          {t("LED")}
        </Typography>
      </div>
      {settings?.["LED"] && (
        <>
          {renderSimpleSwitch("Recording status", "RECLED")}
          {firmware?.firmware_language === "Japan"
            ? renderJPSimpleSwitch("Front security (Normal mode)", "NORMALLED")
            : renderSimpleSwitch("Front security (Normal mode)", "NORMALLED")}
          {firmware?.firmware_language === "Japan"
            ? renderJPSimpleSwitch(
                "Front security (Parking mode)",
                "PARKINGLED"
              )
            : renderSimpleSwitch("Front security (Parking mode)", "PARKINGLED")}
          {renderSimpleSwitch("Rear security_2", "RearLED")}
          {renderSimpleSwitch("Bluetooth (Parking mode)_2", "BTLED")}
          {/* uxui 설정3차수정 - Interior security -> Optional Camera Security 항목명 변경 및 출력조건 추가(Leehj) */}
          {/* mantis 13508 - InteriorLED -> OptionRearLED (hongcs) */}
          {OPTION_MODEL && (
            firmware?.firmware_language === "Japan"
              ? renderJPSimpleSwitch ("Optional Camera Security", "OptionRearLED")
              : renderSimpleSwitch("Optional Camera Security", "OptionRearLED")
          )}
          {renderSimpleSwitch("LTE (Parking mode)", "LTELED")}
          {renderSimpleSwitch("Wi-Fi (Parking mode)", "WifiLED")}
        </>
      )}
    </>
  );
};
