import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  RadioButton,
  Typography,
} from "@thingsw/pitta-design-system";
import { Selector } from "@thingsw/pitta-design-system/dist/components/Selector";

import clsx from "clsx";
import {
  CAMERA,
  loadFirmwareSettings,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { FirmwareSettingModal } from "./FirmwareSettingModal";
import { FirmwareFormatModal } from "./FirmwareFormatModal";
import {
  LightColors,
  Webviewer,
  TabNameInfo1,
  TrueFalsetype,
  IRecordingConfig,
  MODELS_3CH,
  OPTION_CAMERA_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 660,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: { maxWidth: 672 },
  },

  boderBottomBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 48,
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
  boderNoneBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 48,
    borderRadius: 4,
    ...(theme.direction === "rtl"
      ? { padding: "0 10px 0 2px" }
      : { padding: "0 2px 0 10px" }),
    borderBottom: "none",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
    },
  },
  overBoderBottomBox: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,

    display: "flex",
    padding: "12px 16px 12px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
      maxHeight: 48,
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },

  boderNoneBox69: {
    width: "100%",
    maxWidth: 672,
    minHeight: 69,
    borderRadius: 4,
    ...(theme.direction === "rtl"
      ? { padding: "11px 10px 12px 16px" }
      : { padding: "11px 16px 12px 10px" }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      alignItems: "center",
      ...(theme.direction === "rtl"
        ? { padding: "0 10px 0 2px" }
        : { padding: "0 2px 0 10px" }),
    },
  },
  selectorBox: {
    padding: "9px 10px 0",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      padding: " 0 16px 0 10px",
      justifyContent: "space-between",
    },
  },
  selectorBottomBox: {
    padding: "8px 11px 12px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",

      ...(theme.direction === "rtl"
        ? { padding: " 0 10px 0 16px" }
        : { padding: " 0 16px 0 10px" }),
      justifyContent: "space-between",
    },
  },
  mgNone: {
    margin: 0,
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  mgL37: {
    marginLeft: 37,
  },
  flexStart: {
    display: "flex",
    alignItems: "start",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  selectorWidth: {
    width: "100%",
    paddingTop: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
      maxWidth: 265,
      ...(theme.direction === "rtl" ? { paddingRight: 5 } : { paddingLeft: 5 }),
    },
  },
  pdB12: {
    paddingBottom: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 0,
    },
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  maxH111: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxHeight: 111,
    },
  },
  RearCameraPadding: {
    ...(theme.direction === "rtl"
      ? { padding: "9px 10px 12px 16px" }
      : { padding: "9px 16px 12px 10px" }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: "12px 10px 12px 16px" }
        : { padding: "12px 16px 12px 10px" }),
    },
  },
  SelectPd: {
    paddingRight: 5,
  },
  selectorWidthin: {
    maxWidth: 265,
  },
  borderTop: {
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  rearParkingCheck: {
    ...(theme.direction === "rtl" ? { marginRight: 27 } : { marginLeft: 27 }),
  },
  rearParkingText: {
    ...(theme.direction === "rtl" ? { marginRight: 59 } : { marginLeft: 59 }),
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 90,
    padding: 10,
    paddingTop: 0,
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 71,
    },
  },
}));

interface RecordingFirmwarePanelProps {
  openMenu?: boolean;
}

export const RecordingFirmwarePanel = ({
  openMenu,
}: RecordingFirmwarePanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera, firmware, firmwareConfig, loading } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [current, setCurrent] = useState<TabNameInfo1>();
  const [currentTab, setCurrentTab] = useState<TabNameInfo1>();
  const [disabledChange, setDisabledChange] = useState(true);
  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);
  const [openSaveSettingModal, setOpenSaveSettingModal] = useState(false);

  const settings = useMemo(() => {
    return firmwareConfig?.Recording;
  }, [firmwareConfig?.Recording]);

  const videoSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const DR750G2CH = useMemo(() => camera && camera.model === "DR750G-2CH", [
    camera,
  ]);
  const DR750G1CH = useMemo(() => camera && camera.model === "DR750G-1CH", [
    camera,
  ]);

  const DR750S1CH = useMemo(() => camera && camera.model === "DR750S-1CH", [
    camera,
  ]);
  const DR750S2CH = useMemo(() => camera && camera.model === "DR750S-2CH", [
    camera,
  ]);

  const DR900S = useMemo(
    () => _.includes(["DR900S-1CH", "DR900S-2CH"], camera?.model),
    [camera?.model]
  );
  //rear parkimg mode on:0 / off: 1인 모델
  const RearPKEY = useMemo(
    () => _.includes(["DR750-2CH LTE", "DR900S-2CH"], camera?.model),
    [camera?.model]
  );
  //AutoParking 기본 값이 Motion & Impact detection(key:1)인 모델
  const AutoParkingKey1 = useMemo(
    () =>
      _.includes(
        [
          "DR750S-1CH",
          "DR750S-2CH",
          "DR750G-1CH",
          "DR750G-2CH",
          "DR750G-1CH Pro",
          "DR750G-2CH Pro",
          "DR750G Pro",
        ],
        camera?.model
      ),
    [camera?.model]
  );
  //AutoParking 기본 값이 Time lapse(key:2)인 모델
  const AutoParkingKey2 = useMemo(
    () =>
      _.includes(
        [
          "DR900S-1CH",
          "DR900S-2CH",
          "DR750-2CH LTE",
          "DR750X-1CH",
          "DR750X-2CH",
          "DR900X-1CH",
          "DR900X-2CH",
          "DR750X Plus",
          "DR770X",
          "DR970X",
          "DR770X Box",
          // mantis - 8814, BlackVue 7 LTE, BlackVue 9 LTE 기본값 Time lapse로 변경(Leehj)
          "DR770X LTE",
          "DR970X LTE",
        ],
        camera?.model
      ),
    [camera?.model]
  );

  const firmwareREV = useMemo(() => {
    if (
      firmware &&
      parseFloat(firmware?.firmware_version) >= parseFloat("1.003")
    ) {
      return true;
    }
  }, [firmware]);

  const REVModel = useMemo(
    () =>
      _.includes(
        ["DR750X Plus", "DR900X Plus", "DR750X LTE Plus", "DR750X-3CH Plus"],
        camera?.model
      ),
    [camera?.model]
  );

  const FormatModel = useMemo(
    () =>
      _.includes(
        [
          "DR750G-1CH",
          "DR750G-2CH",
          "DR900S-1CH",
          "DR900S-2CH",
          "DR750S-1CH",
          "DR750S-2CH",
        ],
        camera?.model
      ),
    [camera?.model]
  );

  useEffect(() => {
    if (!loading) {
      setOpenSaveSettingModal(false);
    }
  }, [loading]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [camera, dispatch]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );

      if (currentTab) {
        setCurrent(currentTab.section_info as TabNameInfo1);
      }
    }
  }, [firmware]);

  const getBoolean = (TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };
  const handleChange = (key: keyof TabNameInfo1, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo1));
  };
  const handleChange2 = (key: keyof TabNameInfo1, checked: boolean) => {
    setCurrent(
      (c) => ({ ...c, [key]: checked ? "0" || "1" : "2" } as TabNameInfo1)
    );
  };

  const handleAutoParkingChange = (
    key: keyof TabNameInfo1,
    checked: boolean
  ) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "2" : "0" } as TabNameInfo1));
  };

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      setCurrentTab(currentTab?.section_info as TabNameInfo1);
      const predicts = _.keys(currentTab?.section_info as TabNameInfo1).map(
        (k) => {
          const key = k as keyof TabNameInfo1;
          return (
            (currentTab?.section_info as TabNameInfo1)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [firmware, current]);

  const handleUpdate = () => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab1"
        ),
      };
      updated.cloud_settings = [
        { section_info: current, section_name: "Tab1" },
        ...updated.cloud_settings,
      ];

      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  const renderSimpleCheckBox = useCallback(
    (
      label: string,
      key: keyof IRecordingConfig,
      options?: { className?: string; checkFn?: () => boolean }
    ) => {
      if (settings?.[key]) {
        return (
          <div className={clsx(classes.boderBottomBox, options?.className)}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(current?.[key] as TrueFalsetype)}
                  onChange={(e) => {
                    handleChange(key, e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t(label)}
                </Typography>
              }
            />
          </div>
        );
      }
      return undefined;
    },
    [settings, classes.boderBottomBox, classes.mgNone, current, t]
  );
  // Mantis-7428
  // Lock Event Files 해제하는 경우 하위 체크박스도 해제 되도록 별도로 useCallback Hook 추가함.
  const renderSimpleLockEventCheckBox = useCallback(
    (
      label: string,
      key: keyof IRecordingConfig,
      options?: { className?: string; checkFn?: () => boolean }
    ) => {
      if (settings?.[key]) {
        return (
          <div className={clsx(classes.boderBottomBox, options?.className)}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(current?.[key] as TrueFalsetype)}
                  onChange={(e) => {
                    if (current?.LockEvent === "1") {
                      setCurrent((c) => {
                        return (
                          c && { ...c, LockEvent: "0", OverwriteLock: "0" }
                        );
                      });
                    } else {
                      handleChange(key, e.target.checked);
                    }
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t(label)}
                </Typography>
              }
            />
          </div>
        );
      }
      return undefined;
    },
    [settings, classes.boderBottomBox, classes.mgNone, current, t]
  );
  const ImageSetting = useMemo(() => {
    ///video-ImageSetting이 (Extreme) 이면
    if (
      (DR750S1CH && videoSettings?.["ImageSetting"] === "3") ||
      (DR750S2CH && videoSettings?.["ImageSetting"] === "4") ||
      (DR900S && videoSettings?.["ImageSetting"] === "0") ||
      (DR750G2CH && videoSettings?.["ImageSetting"] === "4") ||
      (DR750G1CH && videoSettings?.["ImageSetting"] === "3")
    ) {
      const option = settings?.["RecordTime"].options4k?.[0];
      return option ? [option] : [];
      ///video-ImageSetting이 (Sports) 이면
    } else if (
      (DR750S1CH && videoSettings?.["ImageSetting"] === "0") ||
      (DR750S2CH && videoSettings?.["ImageSetting"] === "0") ||
      (DR750G2CH && videoSettings?.["ImageSetting"] === "0") ||
      (DR750G1CH && videoSettings?.["ImageSetting"] === "0")
    ) {
      const option = settings?.["RecordTime"].options4k?.[1];
      return option ? [option] : [];
    } else {
      return settings?.["RecordTime"].options;
    }
  }, [
    DR750S1CH,
    DR750S2CH,
    DR900S,
    DR750G2CH,
    DR750G1CH,
    videoSettings,
    settings,
  ]);

  return (
    <>
      <div>
        <div className={classes.bigBox}>
          {/* mantis - 11035, Normal -> Normal Recording 으로 문구수정/ 단어 첫시작은 대문자로 (Leehj) */}
          {renderSimpleCheckBox("Normal recording", "NormalRecord")}
          {settings?.["AutoParking"] && (
            <div className={classes.borderBottom}>
              <div
                className={clsx(
                  classes.boderNoneBox,
                  classes.selectorBox,
                  classes.pdB12
                )}
              >
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      checked={
                        current?.AutoParking === "2" ||
                        current?.AutoParking === "1"
                      }
                      onChange={(e) => {
                        if (AutoParkingKey1) {
                          handleChange("AutoParking", e.target.checked);
                        } else if (
                          AutoParkingKey2 ||
                          (firmwareREV && REVModel)
                        ) {
                          handleAutoParkingChange(
                            "AutoParking",
                            e.target.checked
                          );
                        } else {
                          handleChange("AutoParking", e.target.checked);
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Parking mode recording")}
                    </Typography>
                  }
                />
                <div className={classes.selectorWidth}>
                  <div className={classes.selectorWidth}>
                    <Selector
                      t={t}
                      keyValue={
                        AutoParkingKey1 && current?.AutoParking === "0"
                          ? "1"
                          : ((AutoParkingKey2 || (firmwareREV && REVModel)) &&
                              current?.AutoParking) === "0"
                          ? "2"
                          : current?.AutoParking
                      }
                      disabled={current?.AutoParking === "0"}
                      mobile={mobile}
                      dense
                      onChange={(e) => {
                        setCurrent((c) => {
                          return c && { ...c, AutoParking: e.key };
                        });
                      }}
                      items={[
                        {
                          key: "1",
                          value: "Motion and impact detection",
                        },
                        {
                          key: "2",
                          value: "Time lapse",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              {settings?.["RearParkingMode"] && (
                <div
                  className={clsx(
                    classes.boderNoneBox,
                    classes.maxH111,
                    classes.RearCameraPadding
                  )}
                  style={{ paddingTop: 0 }}
                >
                  <div className={classes.flexColumn}>
                    <div className={classes.rearParkingCheck}>
                      <FormControlLabel
                        className={classes.mgNone}
                        control={
                          <CheckBox
                            disabled={current?.AutoParking === "0"}
                            checked={
                              RearPKEY
                                ? !getBoolean(current?.RearParkingMode)
                                : getBoolean(current?.RearParkingMode)
                            }
                            onChange={(e) => {
                              if (RearPKEY) {
                                handleChange(
                                  "RearParkingMode",
                                  !e.target.checked
                                );
                              } else {
                                handleChange(
                                  "RearParkingMode",
                                  e.target.checked
                                );
                              }
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <Typography category="Default" variant="Body">
                            {t("Rear camera recording_")}
                          </Typography>
                        }
                      />
                    </div>
                    {settings?.["RearParkingMode"].explain && (
                      // <div style={{ marginLeft: 59 }}>
                      <div className={classes.rearParkingText}>
                        <Typography
                          category="Default"
                          variant="Small"
                          htmlColor={LightColors.primary["2"]}
                        >
                          {t("ON")}: {t("Front and rear_")}
                        </Typography>
                        <br />
                        <Typography
                          category="Default"
                          variant="Small"
                          htmlColor={LightColors.primary["2"]}
                        >
                          {t("OFF")}: {t("Rear camera stops_")}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {settings?.["InteriorParkingMode"] && (
                <div
                  className={clsx(
                    classes.boderNoneBox,
                    classes.maxH111,
                    classes.RearCameraPadding
                  )}
                  style={{
                    borderTop: `1px solid ${LightColors.primary["6"]}`,
                  }}
                >
                  <div className={classes.flexColumn}>
                    <div style={{ marginLeft: 27 }}>
                      <FormControlLabel
                        className={classes.mgNone}
                        control={
                          <CheckBox
                            disabled={current?.AutoParking === "0"}
                            checked={getBoolean(current?.InteriorParkingMode)}
                            onChange={(e) => {
                              handleChange(
                                "InteriorParkingMode",
                                e.target.checked
                              );
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <Typography category="Default" variant="Body">
                            {t("Interior camera recording_")}
                          </Typography>
                        }
                      />
                    </div>

                    <div style={{ marginLeft: 59 }}>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("ON")}: {t("Front and interior camera_")}
                      </Typography>
                      <br />
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("OFF")}: {t("Interior camera stops_")}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
              {settings?.["OptionRearParkingMode"] && (
                <div
                  className={clsx(
                    classes.boderNoneBox,
                    classes.maxH111,
                    classes.RearCameraPadding
                  )}
                  style={{
                    borderTop: `1px solid ${LightColors.primary["6"]}`,
                  }}
                >
                  <div className={classes.flexColumn}>
                    <div style={{ marginLeft: 27 }}>
                      <FormControlLabel
                        className={classes.mgNone}
                        control={
                          <CheckBox
                            disabled={current?.AutoParking === "0"}
                            checked={getBoolean(current?.OptionRearParkingMode)}
                            onChange={(e) => {
                              handleChange(
                                "OptionRearParkingMode",
                                e.target.checked
                              );
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <Typography category="Default" variant="Body">
                            {t("Option camera recording_")}
                          </Typography>
                        }
                      />
                    </div>

                    <div style={{ marginLeft: 59 }}>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("ON")}: {t("The optional camera records_")}
                      </Typography>
                      <br />
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("OFF")}: {t("Option camera stops_")}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {renderSimpleCheckBox("Voice recording", "VoiceRecord")}

          {settings?.["UseGpsInfo"] && (
            <div className={clsx(classes.boderNoneBox69, classes.borderBottom)}>
              <div className={classes.flexColumn}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      checked={getBoolean(current?.UseGpsInfo)}
                      onChange={(e) => {
                        handleChange("UseGpsInfo", e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("GPS location recording")}
                    </Typography>
                  }
                />

                <div style={{ marginLeft: 32 }}>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {t("When OFF, GPS_")}
                  </Typography>
                </div>
              </div>
            </div>
          )}
          {/* mantis - 11121, Show date and time 을 Date & time display 로 변경 (Leehj) */}
          {renderSimpleCheckBox("Date & time display", "DateDisplay")}

          {settings?.["SpeedUnit"] && (
            <div
              className={clsx(
                classes.boderBottomBox,
                classes.selectorBottomBox
              )}
            >
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={
                      current?.SpeedUnit === "0" || current?.SpeedUnit === "1"
                    }
                    onChange={(e) => {
                      handleChange2("SpeedUnit", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {/* mantis - 11121, Show speed을 Speed unit으로 변경 (Leehj) */}
                    {t("Speed unit")}
                  </Typography>
                }
              />
              <div className={clsx(classes.flexEnd, classes.SelectPd)}>
                <div
                  style={{
                    maxWidth: 98,
                  }}
                >
                  <Selector
                    t={t}
                    keyValue={current?.SpeedUnit}
                    disabled={current?.SpeedUnit === "2"}
                    dense
                    mobile={mobile}
                    onChange={(e) => {
                      setCurrent((c) => {
                        return c && { ...c, SpeedUnit: e.key };
                      });
                    }}
                    items={[
                      {
                        key: "0",
                        value: "Km/h",
                      },
                      {
                        key: "1",
                        value: "MPH",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}

          {settings?.["RecordTime"] && (
            <div
              className={clsx(
                classes.boderBottomBox,
                classes.selectorBottomBox
              )}
              style={{
                paddingLeft: 16,
              }}
            >
              <Typography category="Default" variant="Body">
                {t("Video segment length")}
              </Typography>
              <div className={clsx(classes.flexEnd, classes.SelectPd)}>
                <div style={{ maxWidth: 170 }}>
                  {DR750S1CH ||
                  DR750S2CH ||
                  DR900S ||
                  DR750G2CH ||
                  DR750G1CH ? (
                    <Selector
                      t={t}
                      disabled={
                        (DR750S2CH &&
                          (videoSettings?.["ImageSetting"] === "0" ||
                            videoSettings?.["ImageSetting"] === "4")) ||
                        (DR750S1CH &&
                          (videoSettings?.["ImageSetting"] === "0" ||
                            videoSettings?.["ImageSetting"] === "3")) ||
                        (DR900S && videoSettings?.["ImageSetting"] === "0") ||
                        (DR750G2CH &&
                          (videoSettings?.["ImageSetting"] === "0" ||
                            videoSettings?.["ImageSetting"] === "4")) ||
                        (DR750G1CH &&
                          (videoSettings?.["ImageSetting"] === "0" ||
                            videoSettings?.["ImageSetting"] === "3"))
                      }
                      keyValue={current?.RecordTime}
                      mobile={mobile}
                      dense
                      onChange={(e) => {
                        setCurrent((c) => {
                          return c && { ...c, RecordTime: e.key };
                        });
                      }}
                      items={ImageSetting ?? []}
                    />
                  ) : (
                    <Selector
                      t={t}
                      keyValue={current?.RecordTime}
                      mobile={mobile}
                      dense
                      onChange={(e) => {
                        setCurrent((c) => {
                          return c && { ...c, RecordTime: e.key };
                        });
                      }}
                      items={settings?.["RecordTime"].options}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {settings?.["LockEvent"] && (
            <div className={classes.borderBottom}>
              {/* Mantis - 7428 */}
              {/* Lock Event Files 해제하는 경우 하위 체크박스 해제되도록 추가한 Hook 변수 명으로 수정함. */}
              {renderSimpleLockEventCheckBox("Lock event files", "LockEvent", {
                className: classes.boderNoneBox,
              })}
              {mobile && settings["LockEvent"] && (
                <div
                  className={classes.overBoderBottomBox}
                  style={{ border: "none" }}
                >
                  <div className={clsx(classes.mgL37, classes.flexStart)}>
                    <CheckBox
                      disabled={!getBoolean(current?.LockEvent)}
                      color="primary"
                      checked={getBoolean(current?.OverwriteLock)}
                      onChange={(e) => {
                        handleChange("OverwriteLock", e.target.checked);
                      }}
                      style={{ marginTop: -3 }}
                    />{" "}
                    <div>
                      <Typography category="Default" variant="Body">
                        {/* Mantis-7428 Overwrite locked event2_ 를 Overwrite locked event3_ 로 수정 */}
                        {t("Overwrite locked event3_")}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
              {!mobile && settings?.["LockEvent"] && (
                <div className={classes.overBoderBottomBox}>
                  <div className={classes.mgL37}>
                    <FormControlLabel
                      className={classes.mgNone}
                      control={
                        <CheckBox
                          disabled={!getBoolean(current?.LockEvent)}
                          checked={getBoolean(current?.OverwriteLock)}
                          onChange={(e) => {
                            handleChange("OverwriteLock", e.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography category="Default" variant="Body">
                          {/* Mantis-7428 Overwrite locked event2_ 를 Overwrite locked event3_ 로 수정 */}
                          {t("Overwrite locked event3_")}
                        </Typography>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* mantis - 11085, 900s는 레코딩 화면에서 해당항목 노출 (Leehj) */}
          {videoSettings?.["VCodecType"] && DR900S && (
            <>
              <div
                className={classes.boderNoneBox}
                style={{
                  // borderBottom: `1px solid ${LightColors.primary["6"]}`,
                  marginBottom: 9,
                }}
              >
                <Typography category="Default" variant="BodyBold">
                  {t("Video codec")}
                </Typography>
              </div>
              <div
                className={classes.column}
                style={{
                  borderBottom: `1px solid ${LightColors.primary["6"]}`,
                }}
              >
                <FormControl>
                  <RadioGroup
                    value={current?.VCodecType ?? "0"}
                    onChange={(e) =>
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            VCodecType: e.target.value as TrueFalsetype,
                          }
                        );
                      })
                    }
                  >
                    <RadioButton
                      style={{ padding: 3 }}
                      value="0"
                      label={
                        <Typography category="Default" variant="Body">
                          {t("H.265 (HEVC)")}
                        </Typography>
                      }
                    />
                    <div
                      style={{
                        marginTop: -10,
                        marginBottom: 21,
                      }}
                    >
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Recommended for better_")}
                      </Typography>
                    </div>
                    <RadioButton
                      style={{ padding: 3 }}
                      value="1"
                      label={
                        <Typography category="Default" variant="Body">
                          {t("H.264 (AVC)")}
                        </Typography>
                      }
                    />{" "}
                    <div style={{ marginTop: -10 }}>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("For full compatibility_")}
                      </Typography>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </>
          )}

          {renderSimpleCheckBox("Front camera rotation", "FrontRotate", {
            className: classes.boderNoneBox,
          })}
          {settings?.["RearRotate"] && (
            <div
              className={clsx(
                classes.boderNoneBox,
                classes.selectorBox,
                classes.pdB12
              )}
              style={{
                paddingLeft: 16,
                borderTop: `1px solid ${LightColors.primary["6"]}`,
              }}
            >
              <Typography category="Default" variant="Body">
                {t("Rear camera orientation")}
              </Typography>
              <div className={classes.flexEnd}>
                <div>
                  <Selector
                    t={t}
                    keyValue={current?.RearRotate}
                    dense
                    mobile={mobile}
                    onChange={(e) => {
                      setCurrent((c) => {
                        return c && { ...c, RearRotate: e.key };
                      });
                    }}
                    items={settings?.["RearRotate"].options}
                  />
                </div>
              </div>
            </div>
          )}
          {/* 
          mantis id : 7555
          * 이슈 상용서버에서 발생 함. *
          FW설정표(Sigmastar&DMC200)_20220315 기준 Interior camera rotation은
          MODELS_3CH이면서 OPTION_CAMERA_MODELS가 아닌 모델에서만 나타나도록 설정 되어 수정함.
          */}
          {_.includes(MODELS_3CH, camera?.model) &&
            !_.includes(OPTION_CAMERA_MODELS, camera?.model) &&
            renderSimpleCheckBox("Interior camera rotation", "InteriorRotate", {
              className: clsx(classes.boderNoneBox, classes.borderTop),
            })}
          {/* 
          mantis id : 7555
          * 이슈 상용서버에서 발생 함. *
          Option camera rotation은 OPTION_CAMERA_MODELS에서만 나타나도록 설정 되어 수정함.
          */}
          {settings?.["OptionRearRotate"] && (
            <div
              className={clsx(
                classes.boderNoneBox,
                classes.selectorBox,
                classes.pdB12
              )}
              style={{
                paddingLeft: 16,
                borderTop: `1px solid ${LightColors.primary["6"]}`,
              }}
            >
              <Typography category="Default" variant="Body">
                {t("Option camera orientation")}
              </Typography>
              <div className={classes.flexEnd}>
                <div>
                  <Selector
                    t={t}
                    keyValue={current?.OptionRearRotate}
                    dense
                    mobile={mobile}
                    onChange={(e) => {
                      setCurrent((c) => {
                        return c && { ...c, RearRotate: e.key };
                      });
                    }}
                    items={settings?.["OptionRearRotate"]?.options}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <Button
            disabled={disabledChange}
            variant="contained"
            color="primary"
            className={classes.buttonSize}
            loading={loading}
            onClick={() => {
              if (
                // mantis - 11816, Parking mode recording > 타입랩스나 모션으로 변경시 FirmwareSettingModal open(on/off는 해당 안됨) (Leehj)
                currentTab?.AutoParking !== "0" &&
                current?.AutoParking !== "0" &&
                currentTab?.AutoParking !== current?.AutoParking
              ) {
                setOpenFirmwareModal(true);
                // mantis - 11203, DR900S의 VCodecType은 레코딩화면으로 이동되면서 해당 코드 추가 (Leehj)
              } else if (
                DR900S &&
                currentTab?.VCodecType !== current?.VCodecType
              ) {
                setOpenFirmwareModal(true);
              } else if (
                FormatModel &&
                currentTab?.RecordTime !== current?.RecordTime
              ) {
                setOpenSaveSettingModal(true);
              } else {
                handleUpdate();
              }
            }}
          >
            {t("Save")}
          </Button>
        </div>
        <FirmwareSettingModal
          open={openFirmwareModal}
          loading={loading}
          onClose={() => setOpenFirmwareModal(false)}
          onClickNegative={() => setOpenFirmwareModal(false)}
          onClickPositive={() => {
            handleUpdate();
            setOpenFirmwareModal(false);
          }}
        />
        {openSaveSettingModal && (
          <FirmwareFormatModal
            loading={loading}
            open={openSaveSettingModal}
            onClose={() => setOpenSaveSettingModal(false)}
            onClickNegative={() => setOpenSaveSettingModal(false)}
            onClickPositive={() => {
              handleUpdate();
            }}
          />
        )}
      </div>
    </>
  );
};
