import { IGeofence, jwtAxiosInst } from "@thingsw/pitta-modules";

export const getGeofences = (
  server: string,
  port: number,
  email: string,
  userToken: string,
  tokenType: string,
  adjustPriority?: boolean,
) => {
  return jwtAxiosInst.post(`/geofence/getFenceList.php`, {
    email,
    user_token: userToken,
    tokenType,
    adjustPriority,
  });
};

export const addGeofence = (
  server: string,
  port: number,
  email: string,
  userToken: string,
  body: IGeofence
) => {
  return jwtAxiosInst.post(`/geofence/updateGeoFence.php`, {
    email,
    user_token: userToken,
    tokenType: "web",
    ...body,
  });
};

export const deleteGeofence = (
  server: string,
  port: number,
  email: string,
  userToken: string,
  fenceId: string,
  fenceName: string
) => {
  return jwtAxiosInst.post(`/geofence/deleteGeoFence.php`, {
    email,
    user_token: userToken,
    tokenType: "web",
    fenceId,
    fenceName,
  });
};

export const saveSpeedGeofences = (
  email: string,
  userToken: string,
  data: IGeofence[]
) => {
  return jwtAxiosInst.post(`/geofence/updateGeoFence.php`, {
    email,
    user_token: userToken,
    tokenType: "web",
    adjustPriority: true,
    adjustPriorityData: data,
  });
};