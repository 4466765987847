import { makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import React from "react";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
} from "redux-form";
import { TFunction, useTranslation } from "react-i18next";
import clsx from "clsx";
import * as yup from "yup";
import validator from "./validator";
import { useSelector } from "react-redux";
import { RootState } from "../features/store";
import { ERROR } from "../features/Error/slice";
import { ISendMailForm } from "@thingsw/pitta-modules";

interface FieldProps {
  label?: string;
  t: TFunction;
  error?: string;
}

const schema = yup.object().shape({
  email: yup.string().email("Enter a valid email").required("Enter email"),
});

const renderEmailField = ({
  label,
  input,
  error,
  meta: { touched, invalid, error: metaError },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      // error={touched && invalid}
      error={touched && (!!error || !!metaError)}
      // helperText={touched && error}
      helperText={touched ? t(error ?? metaError) : undefined}
      {...input}
      {...custom}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  email: {
    marginBottom: theme.spacing(3) + 3,
    // "& p": {
    //   margin: 0,
    // },
  },
}));

const SendMail = (props: InjectedFormProps<ISendMailForm, {}>) => {
  const classes = useStyles();
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const { error } = useSelector((state: RootState) => state[ERROR]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={clsx(classes.email)}>
        <Field
          name="email"
          label={t("Email")}
          component={renderEmailField}
          autoFocus
          error={error && t(error)}
          t={t}
        />
      </div>
    </form>
  );
};

const SnedMailForm = reduxForm<ISendMailForm, {}>({
  form: "MailForm",
  asyncValidate: validator(schema),
  touchOnBlur: false,
})(SendMail);

export default SnedMailForm;
