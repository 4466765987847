import { Card, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Button,
  DetectionIcon,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useSelector } from "react-redux";
import { CAMERA } from "../../features/Camera/slice";
import { RootState } from "../../features/store";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { SettingSlider } from "../../components/SettingSlider";
import {
  TabNameInfo2,
  Webviewer,
  LightColors,
  OPTION_CAMERA_MODELS,
} from "@thingsw/pitta-modules";
import React from "react";

export interface IRecordingProps {
  onClose: () => void;
  onSensitivityChange: (key: keyof TabNameInfo2, val: string) => void;
  currentTab2?: TabNameInfo2;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E8E8E8",
    padding: "24px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "calc(var(--vw, 1vw) * 100 - 476px)",
      height: "calc(var(--vh, 1vh) * 100 - 104px)",
      padding: 24,
    },
  },

  flex: {
    display: "flex",
    flexDirection: "revert",
    alignItems: "center",
  },

  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },

  column: {
    display: "flex",
    flexDirection: "column",
  },

  titleDiv: {
    marginBottom: 13,
    display: "flex",
    alignItems: "center",
  },

  card: {
    maxWidth: 671,
    padding: "21px 8px 8px",
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px 25px",
    },
  },

  cardNonPadding: {
    maxWidth: 671,
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  cardRecording: {
    padding: "12px 20px 10px",
  },

  divPadding: {
    padding: "31px 7px 37px",
  },

  topPadding: {
    padding: "12px 20px 12px",
  },

  bottomPadding: {
    padding: "12px 20px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  cardDiv: {
    marginBottom: 16,
  },

  bottomBorder: {
    padding: "15px 20px 8px",
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "12px 20px 15px",
      flexDirection: "row",
    },
  },

  sensitivityIcon: {
    color: LightColors.primary["3"],
  },

  boderNoneBox258: {
    width: "100%",
    maxWidth: 672,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "20px 2px 0 15px",
      minHeight: 258,
    },
  },

  width309: {
    width: "100%",
    maxWidth: 369,
    height: "100%",
    maxHeight: 147,
    marginTop: 13,
    position: "relative",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 15,
      marginRight: 14,
    },
  },

  motionDiv: {
    position: "absolute",
    border: `2px solid ${LightColors.primary["7"]}`,
    borderRadius: 10,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },

  motionRow: {
    width: "100%",
    height: "25%",
    borderBottom: `2px solid ${LightColors.primary["7"]}`,
    display: "flex",
    "&:last-child": {
      borderBottom: 0,
    },
  },

  motionCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "25%",
    borderRight: `2px solid ${LightColors.primary["7"]}`,
    cursor: "pointer",
    "&:last-child": {
      borderRight: 0,
    },
    "&:hover": {
      backgroundColor: `${LightColors.primary["8"]}5A`,
    },
  },

  motionCheckDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28,
    borderRadius: "50%",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    marginRight: 0,
    width: 162,
    marginTop: 5,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 4,
      marginTop: 0,
      width: 170,
    },
  },

  button: {
    backgroundColor: "#fff",
    color: LightColors.primary["4"],
    border: "none",
    borderRadius: 0,
    // mantis - 10411, 크기가 지정되어있어서 텍스트 두줄로 바뀌는 이슈 수정 (Leehj)
    minWidth: "fit-content",
    // minWidth: 56,
    padding: "8px 10px",
    borderBottom: "2px solid #fff",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#fff",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // minWidth: 60,
      padding: "8px 16px",
    },
  },

  buttonActive: {
    color: LightColors.primary["1"],
    borderBottom: `2px solid ${LightColors.primary["7"]}`,
    fontWeight: 500,
  },

  buttonDot: {
    backgroundColor: LightColors.primary["7"],
    width: 6,
    height: 6,
    borderRadius: 20,
    marginBottom: 6,
  },

  buttonNonDot: {
    paddingTop: 20,
  },

  iconDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
  },

  infoIcon: {
    color: LightColors.primary["3"],
    marginLeft: 13,
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },

  img: {
    width: "100%",
    height: "100%",
    maxWidth: 369,
    maxHeight: 147,
    minWidth: 327,
    minHeight: 130,
  },

  bottomMotionDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "50%",
    },
  },
  sliderStyle: {
    padding: "0 0px 0 8px",
    minHeight: 42,
    marginTop: 6,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0 0px 0 13px",
      marginTop: 0,
    },
  },
}));

export const MotionDetectionSensitivityScreen = ({
  onClose,
  onSensitivityChange,
  currentTab2,
}: IRecordingProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up(Webviewer.mobile));

  const [mode, setMode] = useState<number>(0);
  const { camera } = useSelector((state: RootState) => state[CAMERA]);
  const [frontMotionDetection, setFrontMotionDetection] = useState<boolean[]>(
    _.range(0, 16).map((t) => false)
  );
  const [rearMotionDetection, setRearMotionDetection] = useState<boolean[]>(
    _.range(0, 16).map((t) => false)
  );
  const [interiorMotionDetection, setInteriorMotionDetection] = useState<
    boolean[]
  >(_.range(0, 16).map((t) => false));
  const [optionMotionDetection, setOptionMotionDetection] = useState<boolean[]>(
    _.range(0, 16).map((t) => false)
  );

  const optionModel = useMemo(() => {
    return _.includes(OPTION_CAMERA_MODELS, camera?.model);
  }, [camera?.model]);

  const directionBtnMarkup = useMemo(() => {
    return (
      <div className={classes.buttonDiv}>
        <Button
          className={clsx(classes.button, {
            [classes.buttonActive]: mode === 0,
            [classes.buttonNonDot]: mode !== 0,
          })}
          onClick={() => {
            setMode(0);
          }}
        >
          <div className={classes.column} style={{ alignItems: "center" }}>
            {mode === 0 && <div className={classes.buttonDot}></div>}
            {t("Front")}
          </div>
        </Button>
        <Button
          className={clsx(classes.button, {
            [classes.buttonActive]: mode === 1,
            [classes.buttonNonDot]: mode !== 1,
          })}
          onClick={() => {
            setMode(1);
          }}
        >
          <div className={classes.column} style={{ alignItems: "center" }}>
            {mode === 1 && <div className={classes.buttonDot}></div>}
            {t("Rear")}
          </div>
        </Button>
        {/* uxui 설정3차수정 - BOX 모델인 경우 Motion sensitivity 설정에서 optional camera  모션영역 설정추가.(Leehj) */}
        {optionModel && (
          <Button
            className={clsx(classes.button, {
              [classes.buttonActive]: mode === 2,
              [classes.buttonNonDot]: mode !== 2,
            })}
            onClick={() => {
              setMode(2);
            }}
          >
            <div className={classes.column} style={{ alignItems: "center" }}>
              {mode === 2 && <div className={classes.buttonDot}></div>}
              {t("Option")}
            </div>
          </Button>
        )}
      </div>
    );
  }, [
    classes.button,
    classes.buttonActive,
    classes.buttonDiv,
    classes.buttonDot,
    classes.buttonNonDot,
    classes.column,
    mode,
    optionModel,
    t,
  ]);

  const firmToBoolean = useCallback((setting: string) => {
    const fInt = parseInt(setting);
    const fMotion: boolean[] = [];
    for (let i = 15; i >= 0; i--) {
      fMotion.push(!!((fInt >> i) & 1));
    }
    return fMotion;
  }, []);

  const booleanToFirm = useCallback((bools: boolean[]) => {
    let firm = 0;
    for (let i = 0; i < bools.length; i++) {
      if (bools[i]) {
        firm = firm | (1 << (15 - i));
      }
    }
    return firm.toString();
  }, []);

  useEffect(() => {
    // uxui 설정3차수정 - 3차수정하면서 Motion Sensitivity화면 설정 값들 정상적으로 출력안되고 있는 이슈 함께 수정. (Leehj)
    if (currentTab2) {
      const info = currentTab2 as TabNameInfo2;

      const front = info.FrontMotionRegion;
      setFrontMotionDetection(firmToBoolean(front));
      const rear = info.RearMotionRegion;
      rear && setRearMotionDetection(firmToBoolean(rear));
      const interior = info.InteriorMotionRegion;
      interior && setInteriorMotionDetection(firmToBoolean(interior));
      const option = info.OptionRearMotionRegion;
      option && setOptionMotionDetection(firmToBoolean(option));
    }
  }, [firmToBoolean, currentTab2]);

  const renderMotionCol = useCallback(
    (indx: number) => {
      let visible = frontMotionDetection[indx];
      if (mode === 1) {
        visible = rearMotionDetection[indx];
      } else if (mode === 2) {
        if (optionModel) {
          visible = optionMotionDetection[indx];
        } else {
          visible = interiorMotionDetection[indx];
        }
      }
      return (
        <div
          className={classes.motionCol}
          onClick={() => {
            if (mode === 0) {
              frontMotionDetection[indx] = !frontMotionDetection[indx];
              setFrontMotionDetection([...frontMotionDetection]);
              onSensitivityChange(
                "FrontMotionRegion",
                booleanToFirm(frontMotionDetection)
              );
            } else if (mode === 1) {
              rearMotionDetection[indx] = !rearMotionDetection[indx];
              setRearMotionDetection([...rearMotionDetection]);
              // uxui 설정3차수정 - 3차수정하면서 RearMotionRegion 정상적으로 저장되지 않고 있는 이슈 함께 수정. key값,value값 front -> rear로 변경(Leehj)
              onSensitivityChange(
                "RearMotionRegion",
                booleanToFirm(rearMotionDetection)
              );
            } else if (mode === 2) {
              // uxui 설정3차수정 - BOX 모델인 경우 Motion sensitivity 설정에서 optional camera 모션영역 설정추가.(Leehj)
              optionMotionDetection[indx] = !optionMotionDetection[indx];
              setOptionMotionDetection([...optionMotionDetection]);
              onSensitivityChange(
                "OptionRearMotionRegion",
                booleanToFirm(optionMotionDetection)
              );
            }
          }}
        >
          {visible && (
            <div className={classes.motionCheckDiv}>
              <CheckCircleOutlinedIcon
                htmlColor={LightColors.primary["7"]}
                style={{ display: "block" }}
              />
            </div>
          )}
        </div>
      );
    },
    [
      booleanToFirm,
      classes.motionCheckDiv,
      classes.motionCol,
      frontMotionDetection,
      interiorMotionDetection,
      mode,
      onSensitivityChange,
      optionModel,
      optionMotionDetection,
      rearMotionDetection,
    ]
  );

  return (
    <>
      <div className={classes.titleDiv}>
        <IconButton style={{ marginRight: 7 }} onClick={onClose}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography category="Default" variant="H6">
          {/* 단어시작 대문자로 수정 및 오타 수정 (Leehj) 23.01.09 */}
          {t("Motion Detection Sensitivity")}
        </Typography>
      </div>

      <div className={classes.cardDiv}>
        <Card className={classes.card}>
          <div
            className={classes.flex}
            style={{ padding: web ? 0 : "0px 12px" }}
          >
            <div className={classes.column}>
              <div className={classes.flex} style={{ marginBottom: 4 }}>
                <div className={classes.iconDiv}>
                  <DetectionIcon />
                </div>

                <Typography
                  category="Default"
                  variant="SmallBold"
                  htmlColor={LightColors.primary["7"]}
                >
                  {t("Select Detection Region")}
                </Typography>
                {/* mantis - 10697, info아이콘 삭제 요청으로 하단의 info아이콘 코드 제거 / info 아이콘 삭제 23.02.07 / Screen ID D0065 (Leehj) */}
              </div>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["3"]}
              >
                {t("Choose detection regions_")}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className={classes.boderNoneBox258}>
              {directionBtnMarkup}
              <div className={classes.width309}>
                <img
                  className={classes.img}
                  srcSet="/images/uxui-motion-detection.jpg"
                  alt="motion detection"
                />
                <div className={classes.motionDiv}>
                  <div className={classes.motionRow}>
                    {renderMotionCol(0)}
                    {renderMotionCol(1)}
                    {renderMotionCol(2)}
                    {renderMotionCol(3)}
                  </div>
                  <div className={classes.motionRow}>
                    {renderMotionCol(4)}
                    {renderMotionCol(5)}
                    {renderMotionCol(6)}
                    {renderMotionCol(7)}
                  </div>
                  <div className={classes.motionRow}>
                    {renderMotionCol(8)}
                    {renderMotionCol(9)}
                    {renderMotionCol(10)}
                    {renderMotionCol(11)}
                  </div>
                  <div className={classes.motionRow}>
                    {renderMotionCol(12)}
                    {renderMotionCol(13)}
                    {renderMotionCol(14)}
                    {renderMotionCol(15)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className={classes.cardDiv}>
        <Card className={classes.cardNonPadding}>
          <div
            className={clsx(classes.motionRow, classes.bottomBorder)}
            style={{ alignItems: "center" }}
          >
            <div className={classes.bottomMotionDiv}>
              <Typography category="Default" variant="Body">
                {t("Motion detection (Parking mode)")}
              </Typography>
              <Typography
                category="Default"
                variant="SmallBold"
                style={{ marginRight: web ? 12 : 8, marginTop: web ? 1 : -2 }}
                htmlColor={LightColors.primary["7"]}
              >
                {parseInt(currentTab2?.MOTIONSENSOR ?? "0")}
              </Typography>
            </div>
            <div style={{ flex: 1, marginTop: -1, width: "100%" }}>
              <SettingSlider
                min={0}
                max={5}
                value={parseInt(currentTab2?.MOTIONSENSOR ?? "0")}
                onChange={(e, value) => {
                  const v = value as number;
                  onSensitivityChange("MOTIONSENSOR", v.toString());
                }}
                sliderClasses={classes.sliderStyle}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
