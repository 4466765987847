import React, { useMemo } from "react";
import {
  Divider,
  FormControl,
  makeStyles,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  RadioButton,
  Typography,
  RotationIcon,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { CAMERA } from "../../features/Camera/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";

import { Card } from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FlipIcon from "@material-ui/icons/Flip";
import _ from "lodash";
import {
  Webviewer,
  TabNameInfo1,
  TrueFalsetype,
  LightColors,
  OPTION_CAMERA_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 673,
    },
  },

  ArrowBack: {
    maxWidth: "100%",
    maxHeight: 21,
    margin: "0px 9px 15px 1px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0px 9px 14px 0px",
    },
  },
  ArrowBackPd: {
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 3 }
      : { paddingLeft: 0, paddingRight: 3 }),
  },
  cardColumn: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 20px 10px",
    borderRadius: 12,
    marginBottom: theme.spacing(2) - 2,
  },
  rearCardColumn: {
    padding: "16px 0px 20px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 0px 10px 0px",
    },
  },
  interiorCardColumn: {
    padding: "16px 0px 18px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 0px 14px",
    },
  },
  optionTitleDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  rearOptionTitleDiv: {
    margin: "0px 20px 8px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0px 0px 8px 20px",
    },
  },
  interiorOptionTitleDiv: {
    margin: "0px 20px 8px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  optionWrap: {
    marginTop: 31,
  },
  rearOptionWrap: {
    marginTop: 31,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxHeight: 134,
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  radioBtn: {
    padding: 3,
    marginRight: 18,
    marginTop: 1,
  },
  rearFirstRadio: {
    margin: "0px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0px 20px 9px",
    },
  },
  rearSecondRadio: {
    margin: "9px 19px 7px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0px 20px 9px",
    },
  },
  rearThirdRadio: {
    margin: "12px 0px 14px 5px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },
  interiorRadio: {
    marginLeft: 20,
    marginBottom: 8,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 10,
    },
  },
  interiorSecondRadio: {
    marginTop: 3,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },

  img: {
    marginTop: 16,
    marginLeft: 4,
    marginBottom: 10,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      marginBottom: 0,
    },
  },
  frontSecondImg: {
    marginTop: 14,
    marginBottom: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },
  rearFirstImg: {
    maxHeight: 94,
    marginTop: 9,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },
  rearSecondImg: {
    maxHeight: 94,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxHeight: "auto",
    },
  },
  interiorFirstImg: {
    marginBottom: 10,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },
  interiorSecondDiv: {
    marginTop: 7,
    marginBottom: 8,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  interiorSecondImg: {
    marginLeft: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
    },
  },
  rearThirdContent: {
    display: "flex",
    alignItems: "center",
    marginTop: 2,
    marginLeft: -8,
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
    },
  },
  optionalThirdContent: {
    display: "flex",
    alignItems: "center",
    marginTop: 2,
    marginLeft: -8,
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
      marginTop: 34,
      marginLeft: -4,
    },
  },
  divider: {
    width: 303,
    height: 1,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 1,
      height: 121,
      marginTop: -7,
    },
  },
  rearDivider: {
    width: 303,
    height: 1,
    margin: "0px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 1,
      height: 121,
      margin: "3px 0px 0px",
    },
  },
  interiorDivider: {
    width: 303,
    height: 1,
    margin: "0 auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 1,
      height: 166,
      margin: "-8px 0px 0px",
    },
  },
  imgWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
    "& > div": {
      flex: 1,
    },
  },
  firstImgDiv: {
    marginRight: 24,
  },
  secondImgDiv: {
    marginTop: 8,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
      marginLeft: 20,
    },
  },
  rearThirdImgDiv: {
    marginLeft: 12,
  },
  interiorContent: {
    margin: "0px 20px",
  },
}));

interface VideoFirmwarePanelProps {
  onClose: () => void;
  onSettingChange: (
    key: keyof TabNameInfo1,
    val: string | TrueFalsetype
  ) => void;
  currentTab1?: TabNameInfo1;
}

export const CameraRotationPanel = ({
  onClose,
  onSettingChange,
  currentTab1,
}: VideoFirmwarePanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera, firmwareConfig } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const settings = useMemo(() => {
    return firmwareConfig?.Recording;
  }, [firmwareConfig?.Recording]);

  return (
    <div>
      <div
        className={classes.ArrowBack}
        onClick={() => {
          onClose();
        }}
      >
        <IconButton className={classes.ArrowBackPd}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography category="Default" variant="H6">
          {/* uxui 설정4차수정 - 스펠링오류수정(Leehj)  */}
          {t("Camera rotation")}
        </Typography>
      </div>
      <div className={classes.bigBox}>
        {/* front */}
        <Card variant="outlined" className={classes.cardColumn}>
          <div className={classes.optionTitleDiv}>
            <RotationIcon className={classes.icon} />
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={LightColors.primary["7"]}
            >
              {/* osd 키값 참고 */}
              {t("Front camera rotation")}
            </Typography>
          </div>

          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("Check the orientation_")}
          </Typography>

          <div className={classes.optionWrap}>
            <div>
              <FormControl>
                <RadioGroup
                  className={classes.radioGroup}
                  value={currentTab1?.FrontRotate ?? "0"}
                  onChange={(e) => {
                    onSettingChange("FrontRotate", e.target.value);
                  }}
                >
                  <div className={classes.imgWrap}>
                    <div className={classes.firstImgDiv}>
                      <RadioButton
                        className={classes.radioBtn}
                        value="0"
                        label={
                          <Typography category="Default" variant="BodyBold">
                            {t("Default")}
                          </Typography>
                        }
                      />
                      <div className={classes.img}>
                        <img
                          srcSet={
                            mobile
                              ? "/images/car-front-mobile01.png 1x"
                              : "/images/car-front-01.png 1x"
                          }
                          alt="carfront01"
                        />
                      </div>
                    </div>

                    <Divider className={classes.divider} />
                    <div className={classes.secondImgDiv}>
                      <RadioButton
                        className={classes.radioBtn}
                        value="1"
                        label={
                          <Typography category="Default" variant="BodyBold">
                            {/* mantis - 11128, Rotate 180º -> Rotate Video 180º 문구 변경 (Leehj) */}
                            {t("Rotate Video 180º")}
                          </Typography>
                        }
                      />
                      <div
                        className={clsx(classes.img, classes.frontSecondImg)}
                      >
                        <img
                          srcSet={
                            mobile
                              ? "/images/car-front-mobile02.png 1x"
                              : "/images/car-front-02.png 1x"
                          }
                          alt="carfront02"
                        />
                      </div>
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </Card>

        {/* rear */}
        {settings?.["RearRotate"] && (
          <Card
            variant="outlined"
            className={clsx(classes.cardColumn, classes.rearCardColumn)}
          >
            <div
              className={clsx(
                classes.optionTitleDiv,
                classes.rearOptionTitleDiv
              )}
            >
              <RotationIcon className={classes.icon} />
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["7"]}
              >
                {/* mantis - 11128, Rear Camera Rotation -> Rear Camera Orientation 문구 변경 (Leehj) */}
                {t("Rear camera orientation")}
              </Typography>
            </div>

            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ margin: "0px 20px" }}
            >
              {t("Check the orientation_rear")}
            </Typography>

            <div className={classes.rearOptionWrap}>
              <div>
                <FormControl>
                  <RadioGroup
                    className={classes.radioGroup}
                    value={currentTab1?.RearRotate ?? "0"}
                    onChange={(e) => {
                      onSettingChange("RearRotate", e.target.value);
                    }}
                  >
                    <div className={classes.imgWrap}>
                      <div>
                        <RadioButton
                          className={classes.radioBtn}
                          labelClassName={classes.rearFirstRadio}
                          value="0"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {t("Default")}
                            </Typography>
                          }
                        />
                        <div className={classes.rearFirstImg}>
                          <img
                            srcSet={
                              mobile
                                ? "/images/car-rear-mobile01.png 1x"
                                : "/images/car-rear-01.png 1x"
                            }
                            alt="carrear01"
                          />
                        </div>
                      </div>

                      <Divider className={classes.rearDivider} />
                      <div>
                        <RadioButton
                          className={classes.radioBtn}
                          labelClassName={classes.rearSecondRadio}
                          value="1"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {/* mantis - 11128, Rotate 180º -> Rotate Video 180º 문구 변경 (Leehj) */}
                              {t("Rotate Video 180º")}
                            </Typography>
                          }
                        />
                        <div className={classes.rearSecondImg}>
                          <img
                            srcSet={
                              mobile
                                ? "/images/car-rear-mobile02.png 1x"
                                : "/images/car-rear-02.png 1x"
                            }
                            alt="carrear02"
                          />
                        </div>
                      </div>
                      <Divider className={classes.rearDivider} />
                      <div className={classes.rearThirdImgDiv}>
                        <RadioButton
                          className={classes.radioBtn}
                          labelClassName={classes.rearThirdRadio}
                          value="2"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {t("Mirror View")}
                            </Typography>
                          }
                        />
                        <div className={classes.rearThirdContent}>
                          <FlipIcon
                            style={{
                              marginRight: 7,
                            }}
                          />
                          <Typography
                            category="Default"
                            variant="Small"
                            htmlColor={LightColors.primary["2"]}
                            style={{
                              textAlign: "center",
                              width: mobile ? 174 : 171,
                              marginTop: mobile ? 1 : 0,
                            }}
                          >
                            {t("Select Mirror View_")}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </Card>
        )}

        {/* Interior*/}
        {/* Box모델 노출 항목 */}
        {_.includes(OPTION_CAMERA_MODELS, camera?.model) && (
          <Card
            variant="outlined"
            className={clsx(classes.cardColumn, classes.interiorCardColumn)}
          >
            <div
              className={clsx(
                classes.optionTitleDiv,
                classes.interiorOptionTitleDiv
              )}
            >
              <RotationIcon className={classes.icon} />
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["7"]}
              >
                {/* uxui 설정 수정건 2차 적용 - 항목명 변경 (Leehj) */}
                {t("Optional Camera Rotation")}
              </Typography>
            </div>

            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              className={classes.interiorContent}
            >
              {/* uxui 설정 수정건 2차 적용 - 문구 변경 (Leehj) */}
              {t("Check the orientation_interior")}
            </Typography>
            <div className={classes.optionWrap}>
              <div>
                <FormControl>
                  {/* maints 13491 InteriorRotate -> OptionRearRotate (hongcs) */}
                  <RadioGroup
                    className={classes.radioGroup}                    
                    value={currentTab1?.OptionRearRotate ?? "0"}
                    onChange={(e) => {                      
                      onSettingChange("OptionRearRotate", e.target.value);
                    }}
                  >
                    <div className={classes.imgWrap}>
                      <div className={classes.interiorFirstImg}>
                        <RadioButton
                          className={classes.radioBtn}
                          labelClassName={classes.interiorRadio}
                          value="0"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {t("Default")}
                            </Typography>
                          }
                        />
                        <div>
                          <img
                            srcSet={
                              mobile
                                ? "/images/car-optional-mobile01.png 1x"
                                : "/images/car-optional-01.png 1x"
                            }
                            alt="caroptional01"
                          />
                        </div>
                      </div>

                      <Divider className={classes.interiorDivider} />
                      <div className={classes.interiorSecondDiv}>
                        <RadioButton
                          className={classes.radioBtn}
                          labelClassName={clsx(
                            classes.interiorRadio,
                            classes.interiorSecondRadio
                          )}
                          value="1"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {/* mantis - 11128, Rotate 180º -> Rotate Video 180º 문구 변경 (Leehj) */}
                              {t("Rotate Video 180º")}
                            </Typography>
                          }
                        />
                        <div className={classes.interiorSecondImg}>
                          <img
                            srcSet={
                              mobile
                                ? "/images/car-optional-mobile02.png 1x"
                                : "/images/car-optional-02.png 1x"
                            }
                            alt="caroptional02"
                          />
                        </div>
                      </div>
                      {/* uxui 설정3차수정 - BOX 모델의 경우 OPTIONAL CAMERA에서 Mirror View항목 추가.(Leehj) */}
                      <Divider className={classes.interiorDivider} />
                      <div className={classes.rearThirdImgDiv}>
                        <RadioButton
                          className={classes.radioBtn}
                          labelClassName={classes.rearThirdRadio}
                          value="2"
                          label={
                            <Typography category="Default" variant="BodyBold">
                              {t("Mirror View")}
                            </Typography>
                          }
                        />
                        <div
                          className={clsx(
                            classes.rearThirdContent,
                            classes.optionalThirdContent
                          )}
                        >
                          <FlipIcon
                            style={{
                              marginRight: mobile ? 3 : 10,
                            }}
                          />
                          <Typography
                            category="Default"
                            variant="Small"
                            htmlColor={LightColors.primary["2"]}
                            style={{
                              textAlign: "center",
                              width: mobile ? 174 : 171,
                              marginTop: mobile ? 1 : 0,
                            }}
                          >
                            {t("Select Mirror View_")}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};
