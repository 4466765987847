import { jwtAxiosInst } from "@thingsw/pitta-modules";
import parser from "ua-parser-js";

export const getPushToken = (
  email: string,
  userToken: string,
  mobile_uuid: string,
  mobile_lang: string
) => {
  const ua = parser(navigator.userAgent);
  return jwtAxiosInst.get(
    `/BCS/alarmRegister.php?email=${email}&user_token=${userToken}&tokenType=web&mobile_os_type=web&mobile_uuid=${mobile_uuid}&mobile_lang=${mobile_lang}&mobile_os_ver=1.00&mobile_name=${ua.browser.name}&mobile_model=blackvueweb`
  );
};

export const deregisterAlarm = (
  email: string,
  userToken: string,
  mobile_uuid: string,
  tokenType: string
) => {
  return jwtAxiosInst.get(
    `/BCS/alarmDeregister.php?user_token=${userToken}&email=${email}&mobile_uuid=${mobile_uuid}&tokenType=${tokenType}`
  );
};
