import React, { useMemo } from "react";
import {
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Card,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Selector, Typography } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { CAMERA } from "../../features/Camera/slice";

import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { Webviewer, FirmwareLanguages } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    maxWidth: 673,
    minHeight: 62,
    alignItems: "center",
    borderRadius: 12,
    boxShadow: "0px 2px 2px 0px rgb(0 0 0 / 6%)",
  },
  colum: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  selectOptionWrap: {
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      alignItems: "center",
      flexDirection: "row",
    },
  },
  spaceBetween: {
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
    },
  },
  selectDiv: {
    paddingTop: 16,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      minWidth: 267,
      padding: "0",
    },
  },
  select: {
    borderRadius: 12,
    padding: "8px 24px",
  },
  selectPaper: {
    width: 234,
  },
}));

interface SystemLEDPanelProps {
  language: string;
  onLanguageChange: (lang: string) => void;
}

export const SystemLanguagePanel = ({
  language,
  onLanguageChange,
}: SystemLEDPanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { t } = useTranslation();

  const { firmwareConfig } = useSelector((state: RootState) => state[CAMERA]);

  const languageSettings = useMemo(() => {
    return firmwareConfig?.FirmwareLanguage;
  }, [firmwareConfig]);

  return (
    <>
      {languageSettings && (
        <Card variant="outlined" className={clsx(classes.card, classes.colum)}>
          <div className={clsx(classes.selectOptionWrap, classes.spaceBetween)}>
            <Typography category="Default" variant="Body">
              {t("FW language")}
            </Typography>
            <div className={classes.selectDiv}>
              <Selector
                setting
                settingScroll
                outlinedInputClassName={classes.select}
                menuItemClassName={classes.selectPaper}
                t={t}
                keyValue={language}
                dense
                mobile={mobile}
                onChange={(e) => {
                  onLanguageChange(e.key);
                }}
                items={_.chain(languageSettings)
                  .map((lang) => {
                    return {
                      key: lang,
                      value: FirmwareLanguages[lang],
                    };
                  })
                  .value()}
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
