import React, {
  createRef,
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  CheckBox,
  Button,
  Modal,
  Tooltip,
  ConnectToCloud,
  Avatars,
  PlaybackRev,
  LiveviewRev,
  GpsTrackingRev,
  ReportRev,
  ScrollBar,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import GroupIcon from "@material-ui/icons/Group";
import WarningIcon from "@material-ui/icons/Warning";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { EmptyItems } from "../EmptyItems";
import { RootState } from "../../features/store";
import { CAMERA, loadCameras, setCameraTimeSync } from "../../features/Camera/slice";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { MobileCameraMenu } from "./MobileCameraMenu";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { CameraMenu } from "./CameraMenu";
import { GROUP, loadGroups } from "../../features/Group/slice";
import { IUXUILatestEvent } from "../../features/Event/slice";
import { LatestEvent } from "../cameras/LatestEvent";
import { VideoPlayerModal } from "../cameras/VideoPlayerModal";
import { NoResults } from "../NoResults";
import { PAYMENT } from "../../features/Payment/slice";
import { getPlanFromServiceID } from "../../utils/Service";
// import { MODEL_KEY, SUPPORT_REBOOT_FW } from "../../contants/Firmwares";
import "simplebar/src/simplebar.css";
import moment from "moment";
import { loadUsageInfo, USER } from "../../features/User/slice";
import { ExceedModal } from "../modals/ExceedModal";
import { isFree100Check } from "../../utils/isFree100Check";
import { CameraInfo } from "./CameraInfo";
import { CameraGroupInfo } from "./CameraGroupInfo";
import { EditProfileModal } from "./EditProfileModal";
import { getCloudConnectivityIcon } from "../../utils/Camera";
import { RemoveCloudModal } from "../modals/RemoveCloudModal";
import { RemoveCloudSuccessModal } from "../modals/RemoveCloudSuccessModal";
import { AddCameraGuideModal } from "../modals/AddCameraGuideModal";
import { NotConnectedCameraModal } from "../modals/NotConnectedCameraModal";
import { ReactSVG } from "react-svg";
import { DRAWER_MODE } from "../MobileDrawer";
import { OnlyWifiModelRenameModal } from "./OnlyWifiModelRenameModal";
import {
  AvailableFw,
  AvailableFws,
  CloudConnectionSettings,
  DashCamList,
  ENABLED_CLOUD_OLD_MODELS,
  ICameraInfo,
  jwtAxiosInst,
  LightColors,
  MobileLang,
  OLD_MODELS,
  PermissionProps,
  PSN650,
  SIMCARD_URI,
  Webviewer,
} from "@thingsw/pitta-modules";
import UnpairBatteryModal from "../modals/UnpairBatteryModal";
// import { PUSH_EVENT } from "../../features/PushEvent/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // overflow: "hidden",
    transition: theme.transitions.create(["width", "height"]),
    backgroundColor: LightColors.primary["0"],
    borderRadius: 12,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
  },
  rootOpen: {
    width: 375,
    height: "100%",
  },
  rootClose: {
    width: 0,
  },
  rootMobile: {
    width: "100%",
  },
  header: {
    display: "flex",
    minHeight: 56,
    padding: theme.spacing(0, 1.5, 0, 0.75),
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
  },
  headerOpen: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  headerSearch: {
    zIndex: 100,
  },
  iconBtn: {
    padding: 0,
    color: LightColors.primary["3"],
    marginRight: theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
  },
  noCameraDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 165,
    },
  },
  noCameraAdminDriverDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundColor: "#E8E8E8",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 165,
    },
  },
  noCameraAdminDriver: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  noCameraContentHidden: {
    display: "none",
  },
  noCameraMobileHalfDiv: {
    flexDirection: "row",
    padding: "0px 18px",
  },
  noCamHalfImg: {
    marginRight: 16,
  },
  noCameraAdminDriverTextDiv: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 250,
    textAlign: "center",
    marginTop: -7,
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },
  noCamHalfTextDiv: {
    textAlign: "start",
  },
  noCameraInfoFirstText: {
    marginBottom: 21,
  },
  noCameraInfoSecondText: {
    marginBottom: 16,
  },
  camDiv: {
    height: "calc(100% - 56px)",
    // padding: "6px 0",
    overflowY: "auto",
    backgroundColor: "#E8E8E8",
    position: "relative",
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  listPane: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    transition: theme.transitions.create(["transform", "visibility"]),
  },
  rootPanel: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    transition: theme.transitions.create("transform"),
    position: "relative",
    overflow: "hidden",
    // 13350 이슈로 주석 처리
    // [theme.breakpoints.up(Webviewer.mobile)]: {
    //   width: 375,
    // },
  },
  listDivHide: {
    transform: "translateX(-100vw)",
    visibility: "hidden",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-375px)",
    },
  },
  detailDivHide: {
    transform: "translateX(100vw)",
    visibility: "hidden",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(375px)",
    },
  },
  icon: {
    fontSize: 18,
    display: "block",
  },
  smallIcon: {
    fontSize: 12,
    display: "block",
    padding: theme.spacing(0.25, 0.375),
    width: 18,
    height: 16,
    marginRight: theme.spacing(1),
  },
  iconActive: {
    fill: LightColors.primary["7"],
  },
  iconInactive: {
    fill: LightColors.primary["3"],
  },
  liveviewIconActive: {
    fill: LightColors.primary["0"],
  },
  liveviewIcon: {
    "&:hover rect": {
      fill: LightColors.primary["10"],
    },
  },
  liveviewIconDisabled: {
    "& rect, & circle": {
      stroke: LightColors.primary["4"],
    },
    "& circle:nth-of-type(2)": {
      stroke: LightColors.primary["0"],
      fill: LightColors.primary["4"],
    },
  },
  gpsIconDisabled: {
    "& g>path": {
      stroke: LightColors.primary["4"],
    },
    "& g>g>path": {
      stroke: "none",
    },
    "& g>g:nth-of-type(1)>path": {
      fill: LightColors.primary["3"],
      stroke: "none",
    },
  },
  reportIconDisabled: {
    "& path": {
      stroke: LightColors.primary["4"],
    },
  },
  lteIcon: {
    display: "block",
    fontSize: 18,
  },
  detailDiv: {
    margin: theme.spacing(2, 0),
  },
  detailLineDiv: {
    display: "flex",
    alignItems: "center",
    margin:
      theme.direction === "rtl"
        ? theme.spacing(0, 0, 1, 2)
        : theme.spacing(0, 2, 1, 0),
    "&:last-child": {
      marginBottom: 0,
    },
    overflowWrap: "anywhere",
  },
  detailIconDiv: {
    display: "flex",
    justifyContent: "center",
    minWidth: theme.spacing(7),
  },
  link: {
    cursor: "pointer",
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:visited": {
      color: LightColors.primary["7"],
    },
  },
  disableLink: {
    "&:hover": {
      color: LightColors.primary["7"],
    },
  },
  menuTitleDiv: {
    minHeight: 56,
    flex: 1,
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  menuLineDiv: {
    display: "flex",
    alignItems: "center",
    minHeight: theme.spacing(7),
    cursor: "pointer",
  },
  disableMenuLineDiv: {
    opacity: 0.35,
    cursor: "not-allowed",
    "& span": {
      color: LightColors.primary["3"],
    },
    "&:hover span": {
      color: LightColors.primary["3"],
    },
  },
  menuDiv: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  backBtn: {
    padding: 0,
    marginRight: theme.spacing(2),
    marginLeft: 10,
  },
  screenIcon: {
    display: "block",
  },
  screenBtnDiv: {
    width: 83,
    // height: 72,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    "user-select": "none",
    /* Non-prefixed version, currently
       supported by Chrome, Edge, Opera and Firefox */
    "&:hover div": {
      backgroundColor: LightColors.primary["10"],
    },
    "&:active div": {
      backgroundColor: LightColors.primary["9"],
    },
    // mantis - 10769, 색상 LightColors.primary["3"] -> LightColors.primary["1"]로 변경(Leehj)
    color: LightColors.primary["1"],
    "&:hover": {
      // mantis - 10769, 색상 LightColors.primary["8"] -> LightColors.primary["7"]로 변경(Leehj)
      color: LightColors.primary["7"],
    },
  },
  disableScreenBtnDiv: {
    cursor: "auto",
    opacity: 0.35,
    "&:hover div": {
      backgroundColor: LightColors.primary["0"],
    },
    "&:active div": {
      backgroundColor: LightColors.primary["0"],
    },
    "&:hover": {
      color: LightColors.primary["3"],
    },
  },
  btnIconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 48,
    height: 48,
    borderRadius: 10,
    border: `1px solid ${LightColors.primary["9"]}`,
    marginBottom: theme.spacing(1),
    boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.08), 0px 6px 6px rgba(0, 0, 0, 0.15), 0px 6px 40px rgba(0, 0, 0, 0.05)`,
  },
  disabledBtnIconDiv: {
    border: `1px solid ${LightColors.primary["4"]}59`,
  },
  screenMenuDiv: {
    display: "flex",
    padding: "16px 4px",
    margin: "0 16px",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    justifyContent: "space-between",
  },
  eventListDiv: {
    padding:
      theme.direction === "rtl"
        ? theme.spacing(2, 2, 2, 0)
        : theme.spacing(2, 0, 2, 2),
  },
  newDiv: {
    backgroundColor: LightColors.secondary["11"],
    borderRadius: 12,
    width: 43,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
  delete: {
    color: LightColors.secondary["11"],
  },
  deleteIcon: {
    display: "block",
    fill: LightColors.primary["1"],
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    justifyContent: "flex-start",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      justifyContent: "flex-end",
    },
    backgroundColor: "white",
  },
  searchBtnOpen: {
    width: "100%",
    marginRight: 0,
  },
  groupDiv: {
    display: "flex",
    padding: theme.spacing(0.75, 0, 0.25, 0),
    margin: theme.spacing(0.5, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  filterGroupDiv: {
    padding: theme.spacing(0.75, 2),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: LightColors.primary["10"],
    },
  },
  filterCamCntDiv: {
    margin: theme.spacing(0, 3.25),
  },
  formDiv: {
    margin: theme.spacing(0, -0.5),
  },
  cameraTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  cameraTitleWrap: {
    zIndex: 1,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    transition: theme.transitions.create("width"),
    ...(theme.direction === "rtl"
      ? { paddingRight: theme.spacing(1.25) }
      : { paddingLeft: theme.spacing(1.25) }),

    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(5.5) }
      : { marginRight: theme.spacing(5.5) }),
  },
  onePlanInfoDiv: {
    padding: "0 12px",
    height: 24,
    lineHeight: "24px",
    "& span": {
      display: "inline-flex",
      color: `${LightColors.primary["7"]}`,
      direction: "ltr",
    },
  },
  enter: {
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  icon30px: {
    fontSize: 30,
  },
  eventBorderDiv: {
    marginLeft: theme.spacing(5),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  noResultDiv: {
    marginTop: "36vh",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: "29.5vh",
    },
  },
  filterBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    "& button:first-child": {
      margin: theme.spacing(0, 2),
    },
  },
  activeOffDiv: {
    margin: theme.spacing(2, 2, 0),
    backgroundColor: LightColors.secondary["18"],
    borderRadius: 4,
    padding: theme.spacing(1),
    display: "flex",
  },
  modalTitle: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  modalContent: {
    padding: theme.spacing(1.5, 3),
  },
  modalWrap: {
    margin: theme.spacing(2),
  },
  errorModalWrap: {
    margin: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 429,
    },
  },
  errorModalAction: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 2.625, 3),
    },
  },
  searchDiv: {
    position: "absolute",
    ...(theme.direction === "rtl" ? { left: 16 } : { right: 16 }),
    backgroundColor: LightColors.primary["0"],
  },
  searchDivOpen: {
    zIndex: 2,
    width: "calc(100% - 29px)",
    height: "100%",
    display: "flex",
    alignContent: "center",
  },
  cloudConnectModalDiv: {
    margin: 0,
  },
  cloudConnectAppDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 2,
      marginTop: 21,
    },
  },
  appDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },
  appStoreDiv: {
    margin: theme.spacing(3, 0, 3.5, 1.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
      marginBottom: theme.spacing(6.5),
    },
  },
  imgCSSDiv: {
    margin: theme.spacing(5.375, 0, 6, 1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(6.125, 0, 6, 0),
    },
  },
  bodyHeight: {
    flex: 1,
  },
  connectModalContent: {
    padding: theme.spacing(2, 2.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.375, 0, 3.25, 3),
    },
  },
  expired: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(4px)",
    borderRadius: 12,
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 160,
  },
}));

interface CameraListPanelProps {
  open: boolean;
  mobile?: boolean;
  className?: string;
  onRename?: (camera: ICameraInfo) => void;
  onSettings?: (camera: ICameraInfo) => void;
  onUpdate?: (camera: ICameraInfo, fw?: AvailableFw) => void;
  onDelete?: (camera: ICameraInfo) => void;
  onRebootCamera?: (camera: ICameraInfo) => void;
  onClick?: (camera: ICameraInfo | undefined) => void;
  onNotification?: (camera: ICameraInfo | undefined) => void;
  onUpdateFilteredCams?: (cameras: ICameraInfo[]) => void;
  availableNewFws: AvailableFws;
  drawerMode?: DRAWER_MODE;
  setPanelState: Dispatch<SetStateAction<"battery" | "list" | "setting">>;
}

export const CameraListPanelUXUI = (
  props: CameraListPanelProps & PermissionProps
) => {
  const {
    open,
    className,
    mobile,
    onSettings,
    onUpdate,
    onRebootCamera,
    onClick,
    onNotification,
    onUpdateFilteredCams,
    settingsCamPerm,
    fotaCamPerm,
    // rebootCamPerm,
    renameCamPerm,
    deleteCamPerm,
    gpsTrackingPerm,
    liveviewPerm,
    reportPerm,
    availableNewFws,
    drawerMode,
    setPanelState,
  } = props;
  // useTraceUpdate(props);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  // const { latestEvents } = useSelector((state: RootState) => state[EVENT]);
  // const { webPushEvents } = useSelector(
  //   (state: RootState) => state[PUSH_EVENT]
  // );

  const { loading, cameraList, type } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const { groupsList } = useSelector((state: RootState) => state[GROUP]);
  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );
  const buttonsRef = useRef<{ [key: string]: RefObject<HTMLButtonElement> }>(
    {}
  );

  const [anchorRef, setAnchorRef] = useState<
    RefObject<HTMLButtonElement> | undefined
  >();
  const [openSearch, setOpenSearch] = useState(false);
  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [openRemoveCloud, setOpenRemoveCloud] = useState(false);
  const [removeMode, setRemoveMode] = useState<"remove" | "delete">("remove");
  const [renameMode, setRenameMode] = useState<"profile" | "rename">("profile");
  const [openRemoveCloudSuccess, setOpenRemoveCloudSuccess] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [openNotConnectedModal, setOpenNotConnectedModal] = useState(false);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  // const [currentEvent, setCurrentEvent] = useState<ILatestEvent>();
  const [currentEvent, setCurrentEvent] = useState<IUXUILatestEvent>();
  const [currentGroup, setCurrentGroup] = useState<DashCamList>();
  // const [currentPSN, setCurrentPSN] = useState<string>();
  const [searchKey, setSearchKey] = useState("");
  const [moreCam, setMoreCam] = useState<ICameraInfo>();
  const [removeCam, setRemoveCam] = useState<ICameraInfo>();
  const [unpairBatteryCam, setUnpairBatteryCam] = useState<ICameraInfo>();
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [openInvalidModal, setOpenInvalidModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [totalGroups, setTotalGroups] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [filterSelectedGroups, setFilterSelectedGroup] = useState<string[]>([]);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openCloudConnectionModal, setOpenCloudConnectionModal] = useState(
    false
  );
  const [openExceedModal, setOpenExceedModal] = useState(false);
  const [openUnpairBattery, setOpenUnpairBattery] = useState(false);

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);
  const userUsage = useSelector((state: RootState) => state[USER].usageInfo);

  const [latestEvents, setLatestEvents] = useState<IUXUILatestEvent[]>();
  const [eventsListLoading, setEventsListLoading] = useState(true);
  const [remainTime, setRemainTime] = useState<number>(0);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadUsageInfo());
  }, [dispatch]);

  useEffect(() => {
    if (!subscriptionInfo?.endOfMonthDT) return;
    setRemainTime(
      moment
        .duration(
          moment(subscriptionInfo.endOfMonthDT)
            .startOf("day")
            .diff(moment().startOf("day"))
        )
        .asDays()
    );
  }, [subscriptionInfo?.endOfMonthDT]);

  // mantis - 11277, 리네임 성공하면 모달창 close (Leehj)
  useEffect(() => {
    if (!loading) {
      setOpenEditProfile(false);
    }
  }, [loading, type]);

  const free100Check = useMemo(() => {
    if (subscriptionInfo && userUsage) {
      return isFree100Check(subscriptionInfo, userUsage);
    }
  }, [subscriptionInfo, userUsage]);

  // 카메라 검색아이콘 출력 plan확인 (Leehj)
  const isSearchPlan = useMemo(() => {
    if (
      subscriptionInfo &&
      // 카메라 1대이상일 경우만 노출
      cameraList &&
      cameraList.deviceListInfo &&
      cameraList.deviceListInfo.length >= 1
    ) {
      switch (getPlanFromServiceID(subscriptionInfo.servicePlanID)) {
        case "Fleet plan":
          if (_.includes(["Master"], userProfile?.userType)) {
            return true;
          }
          break;
        //  Free, Lite, smart계정 Camera list 검색 버튼 노출 (Leehj)
        case "Free plan":
        case "Lite plan":
        case "Smart plan":
          return true;
        default:
          return false;
      }
    }
  }, [cameraList, subscriptionInfo, userProfile?.userType]);

  useEffect(() => {
    const handleRefresh = () => {
      if (currentCam) {
        localStorage.setItem("currentCamPsn", currentCam.psn);
      }
    };
    window.addEventListener("beforeunload", handleRefresh);
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [currentCam]);

  useEffect(() => {
    const psn = localStorage.getItem("currentCamPsn");
    if (psn) {
      const found = _.find(
        cameraList?.deviceListInfo,
        (dev) => dev.device.psn === psn
      )?.device;
      if (found) {
        setCurrentCam(found);

        localStorage.removeItem("currentCamPsn");
      }
    }
  }, [cameraList?.deviceListInfo]);

  useEffect(() => {
    const grps = [
      "notInGroup",
      ..._.map(groupsList?.DashCamList, (g) => g.GroupID),
    ];
    setTotalGroups(grps);
    setSelectedGroups(grps);
    setFilterSelectedGroup(grps);
  }, [groupsList]);

  // useEffect(() => {
  //   if (currentCam) {
  //     dispatch(loadLatestEvent([currentCam.psn]));
  //   }
  // }, [dispatch, currentCam]);

  // mantis - 11871,11872, Latest events API -> /Noti/AlarmInfo API로 변경 (Leehj)
  useEffect(() => {
    const getAPINotification = async () => {
      if (currentCam && open) {
        try {
          setEventsListLoading(true);
          const lang = i18n.language;
          const res = await jwtAxiosInst.get(
            `/Noti/AlarmInfo?email=${email}&psn=${currentCam.psn}&interval=${
              currentCam.interval
            }&startDT=${moment()
              .startOf("d")
              .add(currentCam?.interval ?? 0, "minutes")
              .format("YYYYMMDD")}&endDT=${moment()
              .endOf("d")
              .add(currentCam?.interval ?? 0, "minutes")
              .format("YYYYMMDD")}&lang=${MobileLang[lang]}&opt=unread`
          );

          setLatestEvents(
            _.chain(res.data.alarmList as IUXUILatestEvent[])
              // mantis - 11872, 11871, 가장 최근에 발생된 이벤트(주행중&주차중 충격 이벤트) 최대 5개(Leehj)
              .filter((f: IUXUILatestEvent) =>
                _.includes(["ALARM_EVENT", "ALARM_PARK_EVENT"], f.alarm_type)
              )
              .map((v) => ({
                ...v,
                cdate: moment(v.cdate).add(
                  currentCam?.interval ?? 0,
                  "minutes"
                ),
              }))
              .value()
              .slice(0, 5)
          );
        } catch {
        } finally {
          setEventsListLoading(false);
        }
      }
    };
    getAPINotification();
  }, [currentCam, email, i18n.language, open]);

  useEffect(() => {
    if (cameraList) {
      buttonsRef.current = _.reduce(
        cameraList.deviceListInfo,
        (result, cam) => {
          return {
            ...result,
            [cam.device.psn]: createRef<HTMLButtonElement>(),
          };
        },
        {}
      );
    }
  }, [cameraList]);

  const camDetailMarkup = useMemo(() => {
    const group = _.find(
      groupsList?.DashCamList,
      (gr) => !!_.find(gr.DashCamUser, (dc) => dc.PSN === currentCam?.psn)
    );
    return currentCam ? (
      <div className={classes.detailDiv}>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}>
            <Avatars
              imgSrc={currentCam?.profile_img || "/images/profile-default.jpg"}
              style={{ width: 20, height: 20 }}
            />
          </div>
          <div>
            <Typography category="Default" variant="H5">
              {currentCam?.dev_name}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}>
            {getCloudConnectivityIcon(currentCam)}
          </div>
          <div style={{ flex: 1 }}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {currentCam.model}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}></div>
          <div style={{ flex: 1 }}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {`S/N: ${currentCam.psn}`}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}></div>
          <div style={{ flex: 1 }}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {`Firmware v.${currentCam.fw_ver}`}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}>
            <GroupIcon className={clsx(classes.icon, classes.iconInactive)} />
          </div>
          <div className={classes.enter}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {`${t("Group")}: ${group ? group.GroupName : "none"}`}
            </Typography>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.detailDiv}></div>
    );
  }, [t, classes, currentCam, groupsList]);

  const filterDivMarkup = useMemo(() => {
    const notInGroup = _.filter(cameraList?.deviceListInfo, (camera) => {
      return !_.find(groupsList?.DashCamList, (g) => {
        return _.find(g.DashCamUser, (d) => d.PSN === camera.device.psn);
      });
    });

    return (
      <div
        className={clsx(classes.listPane, {
          [classes.detailDivHide]: !openFilter,
        })}
      >
        <div
          className={clsx(classes.header, {
            [classes.headerOpen]: open,
          })}
        >
          <IconButton
            onClick={() => setOpenFilter(false)}
            className={classes.backBtn}
          >
            {/* mantis - 10483, 변경된 화살표 디자인으로 변경 (Leehj) */}
            <ArrowBackIosIcon />
          </IconButton>
          <Typography category="Default" variant="H6" style={{ marginTop: 2 }}>
            {t("Filter")}
          </Typography>
        </div>
        <div className={classes.camDiv} style={{ backgroundColor: "#FFFFFF" }}>
          <div
            className={classes.filterGroupDiv}
            onClick={() => {
              const exists = _.includes(filterSelectedGroups, "notInGroup");
              if (exists) {
                setFilterSelectedGroup((groups) => [
                  ..._.filter(groups, (gr) => gr !== "notInGroup"),
                ]);
              } else {
                setFilterSelectedGroup((groups) => [...groups, "notInGroup"]);
              }
            }}
          >
            <div className={classes.formDiv}>
              <CheckBox
                color="primary"
                checked={_.includes(filterSelectedGroups, "notInGroup")}
              />

              <Typography category="Default" variant="Body">
                {t("Not in a group")}
              </Typography>
            </div>
            <div className={classes.filterCamCntDiv}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("n cameras", { n: notInGroup.length })}
              </Typography>
            </div>
          </div>
          {_.chain(groupsList?.DashCamList)
            .filter((g) => !!g.DashCamUser && g.DashCamUser.length > 0)
            .map((g, indx) => {
              return (
                <div
                  key={`filter-group-${indx}`}
                  className={classes.filterGroupDiv}
                  onClick={() => {
                    const exists = _.includes(filterSelectedGroups, g.GroupID);
                    if (exists) {
                      setFilterSelectedGroup((groups) => [
                        ..._.filter(groups, (gr) => gr !== g.GroupID),
                      ]);
                    } else {
                      setFilterSelectedGroup((groups) => [
                        ...groups,
                        g.GroupID,
                      ]);
                    }
                  }}
                >
                  <div className={classes.formDiv}>
                    <CheckBox
                      color="primary"
                      checked={_.includes(filterSelectedGroups, g.GroupID)}
                    />
                    <Typography category="Default" variant="Body">
                      {g.GroupName}
                    </Typography>
                  </div>
                  <div className={classes.filterCamCntDiv}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("n cameras", { n: g.DashCamUser?.length ?? 0 })}
                    </Typography>
                  </div>
                </div>
              );
            })
            .value()}
        </div>
        <div className={classes.filterBtnDiv}>
          <Button
            variant="outlined"
            color="primary"
            disabled={filterSelectedGroups.length === totalGroups.length}
            onClick={() => setFilterSelectedGroup(totalGroups)}
          >
            {t("Clear")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              filterSelectedGroups.length === 0 ||
              _.isEqual(filterSelectedGroups, selectedGroups)
            }
            onClick={() => {
              setSelectedGroups(filterSelectedGroups);
              setOpenFilter(false);
            }}
          >
            {t("OK")}
          </Button>
        </div>
      </div>
    );
  }, [
    cameraList?.deviceListInfo,
    classes.listPane,
    classes.detailDivHide,
    classes.header,
    classes.headerOpen,
    classes.backBtn,
    classes.camDiv,
    classes.filterGroupDiv,
    classes.formDiv,
    classes.filterCamCntDiv,
    classes.filterBtnDiv,
    openFilter,
    open,
    t,
    filterSelectedGroups,
    groupsList?.DashCamList,
    selectedGroups,
    totalGroups,
  ]);

  const menuMarkup = useMemo(() => {
    return (
      <div>
        <div className={classes.screenMenuDiv}>
          <div
            className={classes.screenBtnDiv}
            onClick={() =>
              currentCam && history.push(`/cameras/${currentCam.psn}/playback`)
            }
          >
            <div className={classes.btnIconDiv}>
              <PlaybackRev
                className={clsx(classes.screenIcon, classes.iconActive)}
                style={{ fontSize: 32 }}
              />
            </div>
            <Typography category="Default" variant="Caption">
              {i18n.language === "ja" ? t("Playback") : "Playback"}
            </Typography>
          </div>
          <div
            className={clsx(classes.screenBtnDiv, {
              [classes.disableScreenBtnDiv]:
                !liveviewPerm || !currentCam || currentCam.active === "off",
              [classes.liveviewIcon]:
                liveviewPerm && currentCam && currentCam.active === "on",
            })}
            onClick={
              liveviewPerm && currentCam && currentCam.active === "on"
                ? () =>
                    history.push(`/cameras/${currentCam.psn}/live-view`, {
                      clicked: true,
                    })
                : undefined
            }
          >
            {/* 디자인 수정요청건 반영 - 비활성화시 컬러값은 그레이로 처리(Leehj) */}
            <div
              className={clsx(classes.btnIconDiv, {
                [classes.disabledBtnIconDiv]:
                  !liveviewPerm || !currentCam || currentCam.active === "off",
              })}
            >
              <LiveviewRev
                className={clsx(
                  classes.screenIcon,
                  classes.liveviewIconActive,
                  {
                    [classes.liveviewIconDisabled]:
                      !liveviewPerm ||
                      !currentCam ||
                      currentCam.active === "off",
                  }
                )}
                style={{ fontSize: 32 }}
              />
            </div>
            <Typography category="Default" variant="Caption">
              {/* mantis - 10769, Live view -> Live View로 출력되도록 수정 (Leehj) */}
              {i18n.language === "ja" ? t("Live view") : t("Live view")}
            </Typography>
          </div>
          <div
            className={clsx(classes.screenBtnDiv, {
              [classes.disableScreenBtnDiv]: !gpsTrackingPerm,
            })}
            onClick={
              gpsTrackingPerm
                ? () =>
                    currentCam &&
                    history.push(`/cameras/${currentCam.psn}/gps-tracking`)
                : undefined
            }
          >
            {gpsTrackingPerm ? (
              <div className={classes.btnIconDiv}>
                <GpsTrackingRev
                  className={clsx(classes.screenIcon, classes.iconActive)}
                  style={{ fontSize: 30 }}
                />
              </div>
            ) : (
              <Tooltip
                disableTouchListener={mobile}
                // mantis - 12369, 툴팁문구 가운데 정렬 (Leehj)
                textCenter={
                  !(
                    userProfile?.userType === "User" ||
                    userProfile?.userType === "SubMaster"
                  )
                }
                title={
                  userProfile?.userType === "User" ||
                  userProfile?.userType === "SubMaster" ? (
                    <Typography category="Default" variant="Caption">
                      {t("No permission")}
                    </Typography>
                  ) : (
                    // mantis - 12369, 문구변경 (Leehj)
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: t("Smart and Fleet_"),
                      }}
                    />
                  )
                }
                placement="bottom"
              >
                {/* 디자인 수정요청건 반영 - 비활성화시 컬러값은 그레이로 처리(Leehj) */}
                <div
                  className={clsx(
                    classes.btnIconDiv,
                    classes.disabledBtnIconDiv
                  )}
                >
                  <GpsTrackingRev
                    className={clsx(classes.screenIcon, classes.iconActive, {
                      [classes.gpsIconDisabled]: !gpsTrackingPerm,
                    })}
                    style={{ fontSize: 30 }}
                  />
                </div>
              </Tooltip>
            )}
            <Typography
              category="Default"
              variant="Caption"
              style={{ textAlign: "center" }}
            >
              {/* mantis - 10769, GPS tracking -> GPS tracking로 출력되도록 수정 (Leehj) */}
              {i18n.language === "ja" ? t("GPS tracking") : t("GPS tracking")}
            </Typography>
          </div>
          <div
            className={clsx(classes.screenBtnDiv, {
              [classes.disableScreenBtnDiv]:
                !reportPerm || _.includes(PSN650, currentCam?.psn.substr(0, 4)),
            })}
            onClick={
              reportPerm && !_.includes(PSN650, currentCam?.psn.substr(0, 4))
                ? () =>
                    currentCam &&
                    history.push(`/cameras/${currentCam.psn}/report`)
                : undefined
            }
          >
            {/* 디자인 수정요청건 반영 - 비활성화시 컬러값은 그레이로 처리(Leehj) */}
            <div
              className={clsx(classes.btnIconDiv, {
                [classes.disabledBtnIconDiv]:
                  !reportPerm ||
                  _.includes(PSN650, currentCam?.psn.substr(0, 4)),
              })}
            >
              <ReportRev
                className={clsx(classes.screenIcon, classes.iconActive, {
                  [classes.reportIconDisabled]:
                    !reportPerm ||
                    _.includes(PSN650, currentCam?.psn.substr(0, 4)),
                })}
              />
            </div>
            <Typography category="Default" variant="Caption">
              {i18n.language === "ja" ? t("Report") : "Report"}
            </Typography>
          </div>
        </div>
      </div>
    );
  }, [
    classes.screenMenuDiv,
    classes.screenBtnDiv,
    classes.btnIconDiv,
    classes.screenIcon,
    classes.iconActive,
    classes.disableScreenBtnDiv,
    classes.liveviewIcon,
    classes.liveviewIconActive,
    classes.liveviewIconDisabled,
    classes.gpsIconDisabled,
    classes.disabledBtnIconDiv,
    classes.reportIconDisabled,
    i18n.language,
    t,
    liveviewPerm,
    currentCam,
    gpsTrackingPerm,
    mobile,
    userProfile?.userType,
    reportPerm,
    history,
  ]);

  const renderEventList = useMemo(() => {
    return (
      <div className={classes.eventListDiv}>
        <Typography category="Default" variant="BodyBold">
          {t("Latest events")}
        </Typography>
        <div>
          {eventsListLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "40vh",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={48} thickness={6} color="primary" />
            </div>
          ) : (
            _.map(latestEvents, (l, i) => {
              return (
                <React.Fragment key={`latest-event-${i}`}>
                  <LatestEvent
                    event={l}
                    onClick={(evt) => {
                      if (currentCam && currentCam.active === "off") {
                        setOpenErrorModal(true);
                      } else if (free100Check) {
                        setOpenExceedModal(true);
                      } else {
                        setCurrentEvent(evt);
                        setOpenPlayerModal(true);
                      }
                    }}
                  />
                  {latestEvents && i < latestEvents.length - 1 && (
                    <div className={classes.eventBorderDiv} />
                  )}
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>
    );
  }, [
    classes.eventListDiv,
    classes.eventBorderDiv,
    t,
    eventsListLoading,
    latestEvents,
    currentCam,
    free100Check,
  ]);

  const videoModal = useMemo(() => {
    return (
      currentCam && (
        <VideoPlayerModal
          mode={0}
          open={openPlayerModal}
          camera={currentCam}
          event={currentEvent}
          onClose={() => setOpenPlayerModal(false)}
        />
      )
    );
  }, [currentCam, currentEvent, openPlayerModal]);

  const handleRename: (() => void) | null = useMemo(() => {
    return renameCamPerm
      ? () => {
          if (moreCam) {
            // mantis - 10379, ONLY Wi-Fi모델은 rename 모달창 출력 (Leehj)
            // mantis - 11204, 문구 출력 조건 변경- 클라우드 사용가능한 모델은 profile모달창 출력(Leehj)
            if (
              // _.includes(ENABLED_CLOUD_OLD_MODELS, moreCam?.model) ||
              // _.includes(NEW_MODELS, moreCam?.model) ||
              // _.includes(SIGMASTAR_MODELS, moreCam?.model)
              // "NEW_MODELS" 코드 제거, 클라우드가 가능하지 않은 구모델을 제외한 모든 모델로 수정 (hongcs 24.03.25)
              !_.includes(
                _.filter(
                  OLD_MODELS,
                  (d) => !ENABLED_CLOUD_OLD_MODELS.includes(d)
                ),
                moreCam?.model
              )
            ) {
              setRenameMode("profile");
            } else {
              setRenameMode("rename");
            }
            setOpenEditProfile(true);
            // console.log("moreCam", moreCam);
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [moreCam, renameCamPerm]);

  const handleSettings: (() => void) | null = useMemo(() => {
    return settingsCamPerm
      ? () => {
          console.log("moreCam", moreCam);
          if (moreCam) {
            if (moreCam.require_time_sync) dispatch(setCameraTimeSync(moreCam.require_time_sync));
            onSettings?.(moreCam);
            history.push(`/cameras/${moreCam.psn}/settings`);
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [history, moreCam, onSettings, settingsCamPerm, dispatch]);

  const handleFOTA: (() => void) | null = useMemo(() => {
    return fotaCamPerm
      ? () => {
          if (moreCam) {
            const isNew = _.find(availableNewFws, (v, k) => k === moreCam.psn);
            onUpdate?.(moreCam, isNew);
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [availableNewFws, fotaCamPerm, moreCam, onUpdate]);

  const handleRebbotCam: (() => void) | undefined = useMemo(() => {
    if (moreCam) {
      // const valid =
      //   SUPPORT_REBOOT_FW[moreCam.model as MODEL_KEY] <=
      //     parseFloat(moreCam.fw_ver) || moreCam?.reg_category === "wifi";

      // mantis - 10241, driver 계정은 reboot 불가하도록 수정 (Leehj)
      // mantis - 10379, Screen ID C0161기획 내용에 따라 모든 모델 해당항목 노출되도록 수정(Leehj)*/}
      // valid && rebootCamPerm
      return () => {
        if (moreCam) {
          onRebootCamera?.(moreCam);
        }
        setOpenCamMenu(false);
      };
    }
  }, [moreCam, onRebootCamera]);

  useEffect(() => {
    if (
      cameraList &&
      cameraList.deviceListInfo &&
      cameraList.deviceListInfo.length > 0 &&
      open &&
      !(loading && type === loadCameras.type)
    ) {
      const shownCams: ICameraInfo[] = [];
      _.includes(selectedGroups, "notInGroup") &&
        _.chain(cameraList.deviceListInfo)
          .forEach((camera, indx) => {
            let show = false;
            if (searchKey) {
              show =
                camera.device.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                camera.device.model
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1;
            } else {
              show = true;
            }
            if (show) {
              const group = _.find(groupsList?.DashCamList, (g) => {
                return _.find(
                  g.DashCamUser,
                  (d) => d.PSN === camera.device.psn
                );
              });
              show = !group;
            }
            if (show) {
              shownCams.push(camera.device);
            }
          })
          .value();
      _.chain(groupsList?.DashCamList)
        .filter((g) => _.includes(selectedGroups, g.GroupID))
        .filter((g) => {
          if (searchKey) {
            return !!_.find(g.DashCamUser, (d) => {
              const cam = _.find(
                cameraList.deviceListInfo,
                (dev) => dev.device.psn === d.PSN
              );
              return (
                cam &&
                (cam.device.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                  cam.device.model
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1)
              );
            });
          } else {
            return true;
          }
        })
        .forEach((group, indx) => {
          _.map(group.DashCamUser, (cam) => {
            const camInfo = _.find(
              cameraList.deviceListInfo,
              (dev) => dev.device.psn === cam.PSN
            )?.device;
            let show = false;
            if (searchKey && camInfo) {
              show =
                camInfo.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                camInfo.model
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1;
            } else {
              show = true;
            }
            if (camInfo && show) {
              shownCams.push(camInfo);
            }
          });
        })
        .value();

      onUpdateFilteredCams?.(shownCams);
    }
  }, [
    cameraList,
    groupsList?.DashCamList,
    loading,
    onUpdateFilteredCams,
    open,
    searchKey,
    selectedGroups,
    type,
  ]);

  const handleRemove = useMemo(() => {
    console.log("handleRemove");
    return deleteCamPerm
      ? (camera: ICameraInfo) => {
          if (camera.reg_category === "wifi") {
            setRemoveMode("delete");
          } else {
            setRemoveMode("remove");
          }

          setOpenRemoveCloud(true);
          setRemoveCam(camera);
          setOpenCamMenu(false);
        }
      : null;
  }, [deleteCamPerm]);

  const handleUnpairBattery = (camera: ICameraInfo) => {
    setOpenUnpairBattery(true);
    setUnpairBatteryCam(camera);
    setOpenCamMenu(false);
  };

  const handleUpgrade = useCallback(() => {
    history.push("/account/subscription");
  }, [history]);

  const handleClickCamera = useCallback(
    (camera: ICameraInfo) => {
      console.log("onClick", camera);
      // mantis - 10467 camera.login_date가 없는 경우 return false(Leehj)
      const loggined = !camera.login_date
        ? false
        : !moment(camera.login_date).isBefore(moment.utc(0));
      // mantis - 10467 클라우드 등록은 했지만 서버에 연결된 이력이 없는 경우 GuideModal 출력(Leehj)
      if (
        camera.reg_category === "wifi" ||
        (camera.reg_category === "cloud" && !loggined)
      ) {
        setOpenGuideModal(true);
      } else if (camera.valid === "invalid") {
        // setOpenInvalidModal(true);
      } else if (!loggined) {
        setOpenNotConnectedModal(true);
        // setCurrentCam(camera);
      } else {
        onClick?.(camera);
        // setCurrentPSN(camera.device.psn);
        setCurrentCam(camera);
      }
    },
    [onClick]
  );

  const handleMore = useCallback(
    (e, psn, camera: ICameraInfo) => {
      e.stopPropagation();
      e.preventDefault();
      console.log("setMoreCam1");
      setMoreCam(camera);
      setAnchorRef(buttonsRef.current[camera.psn]);
      if (moreCam === camera) {
        setOpenCamMenu((o) => !o);
      } else {
        setOpenCamMenu(true);
      }
    },
    [moreCam]
  );

  const noCameraListMarkup = useMemo(() => {
    if (
      userProfile?.userType === "User" ||
      userProfile?.userType === "SubMaster"
    ) {
      // mantis - 10235 userType이 admin이거나 driver이면서 할당된 카메라가 없는 경우 출력되는 마크업 (Leehj)
      return (
        <div
          className={clsx(
            classes.noCameraDiv,
            classes.noCameraAdminDriverDiv,
            {}
          )}
        >
          {/* mantis - 10439 모바일 처음 진입시 이미지 겹치는 이슈 수정 (Leehj) */}
          <div
            className={clsx(classes.noCameraAdminDriver, {
              [classes.noCameraMobileHalfDiv]: mobile && drawerMode === "half",
              [classes.noCameraContentHidden]:
                mobile && drawerMode === "camera",
            })}
          >
            <ReactSVG
              src={
                mobile && drawerMode === "half"
                  ? "/images/noCameraMobileHalf.svg"
                  : "/images/noCamera.svg"
              }
              className={clsx(
                mobile && drawerMode === "half" && classes.noCamHalfImg
              )}
            />

            <div
              className={clsx(classes.noCameraAdminDriverTextDiv, {
                [classes.noCamHalfTextDiv]: mobile && drawerMode === "half",
              })}
            >
              <Typography
                category="Default"
                variant="SmallBold"
                className={classes.noCameraInfoFirstText}
              >
                {/* mantis - 11228 en.json누락된 문구 추가 (Leehj) */}
                {t("There is no_camera")}
              </Typography>
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["2"]}
                className={clsx(
                  !(mobile && drawerMode === "half") &&
                    classes.noCameraInfoSecondText
                )}
              >
                {t("Notify the Owner")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Owner")}: {subscriptionInfo?.masterEmail}
              </Typography>
            </div>
          </div>
        </div>
      );
      // mantis - 11480, 등록된 카메라 없는 경우 Group 더미 출력 (Leehj)
    } else if (groupsList?.DashCamList && groupsList?.DashCamList?.length > 0) {
      return (
        <div className={classes.camDiv}>
          <ScrollBar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "6px 0",
              }}
            >
              {_.chain(groupsList?.DashCamList)
                .map((group, indx) => {
                  return (
                    group && (
                      <CameraGroupInfo
                        key={`group-item-${indx}`}
                        group={group}
                        onClick={(group) => setCurrentGroup(group)}
                      />
                    )
                  );
                })
                .value()}
            </div>
          </ScrollBar>
        </div>
      );
    } else {
      return (
        <div className={classes.noCameraDiv}>
          {/* mantis - 10439 모바일 처음 진입시 이미지 겹치는 이슈 수정 (Leehj) */}
          {!(mobile && drawerMode === "camera") && <EmptyItems mode="camera" />}
        </div>
      );
    }
  }, [
    classes.camDiv,
    classes.noCamHalfImg,
    classes.noCamHalfTextDiv,
    classes.noCameraAdminDriver,
    classes.noCameraAdminDriverDiv,
    classes.noCameraAdminDriverTextDiv,
    classes.noCameraContentHidden,
    classes.noCameraDiv,
    classes.noCameraInfoFirstText,
    classes.noCameraInfoSecondText,
    classes.noCameraMobileHalfDiv,
    drawerMode,
    groupsList?.DashCamList,
    mobile,
    subscriptionInfo?.masterEmail,
    t,
    userProfile?.userType,
  ]);

  const planExpiredMarkup = useMemo(() => {
    if (
      _.includes(["SubMaster", "User"], userProfile?.userType) &&
      subscriptionInfo?.servicePlanName === "free"
    ) {
      return (
        <div className={classes.expired}>
          <CloudOffIcon
            htmlColor={LightColors.primary["0"]}
            style={{ fontSize: 80, marginBottom: 32 }}
          />

          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["0"]}
            style={{ marginBottom: 8 }}
          >
            {t("Your previous plan is expired.")}
          </Typography>
          <Typography
            category="Default"
            variant="SmallBold"
            htmlColor={LightColors.primary["0"]}
          >
            {t("Upgrade the plan to restore the camera")}
          </Typography>
          <Typography
            category="Default"
            variant="SmallBold"
            htmlColor={LightColors.primary["0"]}
          >
            ({_.first(cameraList?.deviceListInfo)?.device.dev_name})
          </Typography>
          <br />
          <br />
          <Typography
            category="Default"
            variant="SmallBold"
            htmlColor={LightColors.primary["0"]}
            style={{ marginBottom: 20 }}
          >
            {t("Try to tell this to Your Owner & Admin")}
          </Typography>
          <Typography
            category="Default"
            variant="SmallBold"
            htmlColor={LightColors.primary["0"]}
          >
            {t("Owner")}: {subscriptionInfo?.masterEmail}
          </Typography>
        </div>
      );
    }
  }, [
    cameraList?.deviceListInfo,
    classes.expired,
    subscriptionInfo?.masterEmail,
    subscriptionInfo?.servicePlanName,
    t,
    userProfile?.userType,
  ]);

  const cameraListMarkup = useMemo(() => {
    if (
      cameraList &&
      cameraList.deviceListInfo &&
      cameraList.deviceListInfo.length > 0 &&
      open &&
      !(loading && type === loadCameras.type)
    ) {
      // console.log("cameraListMarkup", cameraList.deviceListInfo);
      const notInGroup = _.includes(selectedGroups, "notInGroup")
        ? _.chain(cameraList.deviceListInfo)
            .map((camera, indx) => {
              let show = false;
              if (searchKey) {
                show =
                  camera.device.dev_name
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                  camera.device.model
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1;
              } else {
                show = true;
              }

              // mantis - 11501, Admin 계정은 그룹 내 캠리스트 출력되도록 수정 (Leehj)
              if (show && userProfile?.userType !== "SubMaster") {
                const group = _.find(groupsList?.DashCamList, (g) => {
                  return _.find(
                    g.DashCamUser,
                    (d) => d.PSN === camera.device.psn
                  );
                });
                show = !group;
              }
              // console.log("cameraListMarkup", "camera", camera);
              if (camera.device.reg_category === "wifi") {
                show = false;
              }

              const fw = _.find(
                availableNewFws,
                (v, k) => k === camera.device.psn
              );
              // const push = _.find(
              //   webPushEvents,
              //   (p) => p.psn === camera.device.psn
              // );
              return show ? (
                <CameraInfo
                  key={camera.device.psn}
                  newFw={!!(fw?.blackvue || fw?.dmc200)}
                  camera={camera.device}
                  ref={buttonsRef.current[camera.device.psn]}
                  // event={push?.time}
                  onClick={() => handleClickCamera(camera.device)}
                  onMore={(e, psn) => handleMore(e, psn, camera.device)}
                  onRemove={handleRemove}
                  onUpgrade={handleUpgrade}
                  onNotification={onNotification}
                  setPanelState={setPanelState}
                />
              ) : undefined;
            })
            .compact()
            .value()
        : [];
      const inGroup = _.chain(groupsList?.DashCamList)
        .filter((g) => _.includes(selectedGroups, g.GroupID))
        .filter((g) => {
          if (searchKey) {
            if (
              g.GroupName.toLocaleLowerCase().indexOf(
                searchKey.trim().toLowerCase()
              ) > -1
            ) {
              return true;
            }

            return !!_.find(g.DashCamUser, (d) => {
              const cam = _.find(
                cameraList.deviceListInfo,
                (dev) => dev.device.psn === d.PSN
              );
              return (
                cam &&
                (cam.device.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                  cam.device.model
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1)
              );
            });
          } else {
            return true;
          }
        })
        .map((group, indx) => {
          return (
            // group.DashCamUser &&
            // group.DashCamUser.length > 0 &&
            // mantis 10483, 할당 카메라가 없는 그룹도 출력되도록 수정 (Leehj)
            group && (
              <CameraGroupInfo
                key={`group-item-${indx}`}
                group={group}
                onClick={(group) => setCurrentGroup(group)}
              />
            )
          );
        })
        .value();

      // mantis - 11773, Not in a group 필터 해제시 Wi-Fi로만 등록된 카메라 미노출 되도록 수정(Leehj)
      const wifiCams = _.includes(selectedGroups, "notInGroup")
        ? _.chain(cameraList.deviceListInfo)
            .map((camera, indx) => {
              let show = false;
              if (searchKey) {
                show =
                  camera.device.dev_name
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                  camera.device.model
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1;
              } else {
                show = true;
              }
              if (show) {
                const group = _.find(groupsList?.DashCamList, (g) => {
                  return _.find(
                    g.DashCamUser,
                    (d) => d.PSN === camera.device.psn
                  );
                });
                show = !group;
              }

              if (camera.device.reg_category !== "wifi") {
                show = false;
              }

              const fw = _.find(
                availableNewFws,
                (v, k) => k === camera.device.psn
              );

              return show ? (
                <CameraInfo
                  key={camera.device.psn}
                  newFw={!!(fw?.blackvue || fw?.dmc200)}
                  camera={camera.device}
                  ref={buttonsRef.current[camera.device.psn]}
                  // event={push?.time}
                  onClick={() => handleClickCamera(camera.device)}
                  onMore={(e, psn) => handleMore(e, psn, camera.device)}
                  onRemove={handleRemove}
                  onUpgrade={handleUpgrade}
                  onNotification={onNotification}
                  setPanelState={setPanelState}
                />
              ) : undefined;
            })
            .compact()
            .value()
        : [];

      return notInGroup.length > 0 ||
        inGroup.length > 0 ||
        wifiCams.length > 0 ? (
        <div className={classes.camDiv}>
          <ScrollBar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "6px 0",
              }}
            >
              {notInGroup}

              {/* mantis - 11501, Admin계정은 inGroup이 출력되지 않도록 조건 추가 (Leehj) */}
              {userProfile?.userType !== "SubMaster" && inGroup}
              {wifiCams}
            </div>
          </ScrollBar>
        </div>
      ) : (
        <div className={classes.noResultDiv}>
          <NoResults />
        </div>
      );
    }
  }, [
    availableNewFws,
    cameraList,
    classes.camDiv,
    classes.noResultDiv,
    groupsList?.DashCamList,
    handleClickCamera,
    handleMore,
    handleRemove,
    handleUpgrade,
    loading,
    onNotification,
    open,
    searchKey,
    selectedGroups,
    setPanelState,
    type,
    userProfile?.userType,
  ]);

  const cameraMenuMarkup = useMemo(() => {
    return (
      <CameraMenu
        {...props}
        placement={"bottom-start"}
        open={openCamMenu}
        camera={moreCam}
        anchorRef={anchorRef}
        onClickAway={() => setOpenCamMenu(false)}
        onEditProfile={handleRename}
        onSettings={handleSettings}
        onUpdate={
          fotaCamPerm &&
          moreCam?.active === "on" &&
          !_.includes(PSN650, moreCam?.psn.substr(0, 4))
            ? handleFOTA
            : null
        }
        onDelete={handleRemove}
        onUnpairBattery={handleUnpairBattery}
        onRebootCamera={handleRebbotCam}
      />
    );
  }, [
    anchorRef,
    fotaCamPerm,
    handleFOTA,
    handleRebbotCam,
    handleRemove,
    handleRename,
    handleSettings,
    moreCam,
    openCamMenu,
    props,
  ]);

  const groupCameraListMarkup = useMemo(() => {
    if (currentGroup) {
      const camList = _.map(currentGroup.DashCamUser, (d) => {
        const camera = _.find(
          cameraList?.deviceListInfo,
          (dev) =>
            dev.device.psn === d.PSN && dev.device.reg_category !== "wifi"
        );
        if (camera) {
          const fw = _.find(availableNewFws, (v, k) => k === camera.device.psn);
          return (
            <CameraInfo
              key={camera.device.psn}
              newFw={!!(fw?.blackvue || fw?.dmc200)}
              camera={camera.device}
              ref={buttonsRef.current[camera.device.psn]}
              // event={push?.time}
              onClick={() => handleClickCamera(camera.device)}
              onMore={(e, psn) => handleMore(e, psn, camera.device)}
              onRemove={handleRemove}
              onUpgrade={handleUpgrade}
              onNotification={onNotification}
              setPanelState={setPanelState}
            />
          );
        }
      });

      if (currentGroup.DashCamUser) {
        return (
          <div
            // mantis - 10232, 카메라리스트 상단 공간 여백 동일하게 padding수정 (Leehj)
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "6px 0",
            }}
          >
            {camList}
          </div>
        );
      } else {
        // mantis 10483, 할당 카메라가 없는 그룹클릭시 노출되는 UI (Leehj)
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              marginTop: mobile ? 0 : 237,
            }}
          >
            <EmptyItems mode="groupCam" />
          </div>
        );
      }
    }
  }, [
    availableNewFws,
    cameraList?.deviceListInfo,
    currentGroup,
    handleClickCamera,
    handleMore,
    handleRemove,
    handleUpgrade,
    mobile,
    onNotification,
    setPanelState,
  ]);

  const mobileCameraMenuMarkup = useMemo(() => {
    const fw = _.find(availableNewFws, (v, k) => k === moreCam?.psn);
    return (
      <MobileCameraMenu
        {...props}
        camera={moreCam}
        open={openCamMenu}
        onUnpairBattery={handleUnpairBattery}
        newFw={!!(fw?.blackvue || fw?.dmc200)}
        onClose={() => setOpenCamMenu(false)}
        // mantis - 10241, driver 계정은 이름 변경 불가하도록 수정 (Leehj)
        onEditProfile={handleRename}
        onSettings={handleSettings}
        onUpdate={
          fotaCamPerm &&
          moreCam?.active === "on" &&
          !_.includes(PSN650, moreCam?.psn.substr(0, 4))
            ? handleFOTA
            : null
        }
        onDelete={handleRemove}
        onRebootCamera={handleRebbotCam}
      />
    );
  }, [
    availableNewFws,
    fotaCamPerm,
    handleFOTA,
    handleRebbotCam,
    handleRemove,
    handleRename,
    handleSettings,
    moreCam,
    openCamMenu,
    props,
  ]);

  const alertMessageMarkup = useMemo(() => {
    const reg_date = moment(currentCam?.reg_date);
    const login_date = moment(currentCam?.login_date);
    return (
      <div className={classes.activeOffDiv}>
        <WarningIcon htmlColor={LightColors.secondary["17"]} />
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.secondary["17"]}
          style={{ margin: "0 8px" }}
        >
          {reg_date.isSame(login_date) ? (
            <Trans
              t={t}
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <span
                    className={classes.link}
                    onClick={() => setOpenCloudConnectionModal(true)}
                  ></span>
                ),
              }}
            >
              Some functionality is disabled_
            </Trans>
          ) : (
            t("Not connected to_2")
          )}
        </Typography>
      </div>
    );
  }, [classes.activeOffDiv, classes.link, currentCam, t]);

  return (
    <>
      <div
        className={clsx(
          classes.root,
          {
            [classes.rootOpen]: open,
            [classes.rootClose]: !open,
            [classes.rootMobile]: mobile,
          },
          className
        )}
      >
        <div className={clsx(classes.rootPanel)}>
          <div
            className={clsx(classes.listPane, {
              [classes.listDivHide]: currentCam || openFilter || currentGroup,
            })}
          >
            <div
              className={clsx(classes.header, {
                [classes.headerOpen]: open,
                [classes.headerSearch]: openSearch,
              })}
              style={{
                justifyContent: "space-between",
              }}
            >
              <div
                className={clsx(classes.cameraTitleWrap, {
                  // [classes.cameraTitleWrapOpen]: openSearch,
                })}
              >
                <div className={classes.cameraTitleDiv}>
                  <Typography category="Default" variant="H6">
                    {t("Cameras")}
                  </Typography>
                  {/*mantis - 8451  admin, driver 계정일 때 출력되지 않도록 조건 추가*/}
                  {/* mantis - 10510, 모바일도 필터아이콘 노출되도록 수정 (Leehj) */}
                  {subscriptionInfo &&
                    getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
                      "Fleet plan" &&
                    _.includes(["Master"], userProfile?.userType) &&
                    // mantis - 10510  카메라 그룹이 없을 때에는 필터 버튼 미노출 (Leehj)
                    groupsList?.DashCamList &&
                    groupsList?.DashCamList?.length > 0 && (
                      <Tooltip
                        open={openTooltip}
                        onOpen={() => setOpenTooltip(true)}
                        onClose={() => setOpenTooltip(false)}
                        disableTouchListener={mobile}
                        placement="top"
                        PopperProps={{
                          modifiers: {
                            offset: {
                              enabled: true,
                              offset: "0, -15px",
                            },
                            flip: {
                              enabled: false,
                            },
                          },
                        }}
                        title={
                          <Typography
                            category="Default"
                            variant="Caption"
                            htmlColor={LightColors.primary["0"]}
                          >
                            {t("Filter")}
                          </Typography>
                        }
                      >
                        <IconButton
                          className={classes.iconBtn}
                          onClick={() => {
                            setOpenTooltip(false);
                            setOpenFilter(true);
                            // mantis - 10510 모바일에서 필터 버튼 누르면 리스트창 열리도록 수정(Leehj)
                            const openEvent = new CustomEvent("drawer:open");
                            document.dispatchEvent(openEvent);
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <FilterListIcon style={{ display: "block" }} />
                            {selectedGroups.length !==
                              (groupsList?.DashCamList?.length ?? 0) + 1 && (
                              <div
                                style={{
                                  width: 6,
                                  height: 6,
                                  borderRadius: 3,
                                  // mantis - 10283, 필터변경시 아이콘 색상 변경 빨간색->파란색 (Leehj)
                                  backgroundColor: LightColors.primary["7"],
                                  position: "absolute",
                                  top: -3,
                                  right: -3,
                                }}
                              />
                            )}
                          </div>
                        </IconButton>
                      </Tooltip>
                    )}
                </div>
              </div>
              {/* mantis - 11492, Admin/Driver 계정 Camera list 검색 버튼 미노출 (Leehj) */}
              {isSearchPlan && (
                <div
                  className={clsx(classes.searchDiv, {
                    [classes.searchDivOpen]: openSearch,
                  })}
                >
                  <Input
                    inputID="cameraSearch"
                    SecondaryColor
                    placeholder={t("Search cameras")}
                    startIcon={
                      <Tooltip
                        disableTouchListener={mobile}
                        placement="top"
                        PopperProps={{
                          modifiers: {
                            offset: {
                              enabled: true,
                              offset: "0, -12px",
                            },
                            flip: {
                              enabled: false,
                            },
                          },
                        }}
                        title={
                          <Typography
                            category="Default"
                            variant="Caption"
                            htmlColor={LightColors.primary["0"]}
                          >
                            {t("search")}
                          </Typography>
                        }
                      >
                        <SearchIcon />
                      </Tooltip>
                    }
                    className={clsx(classes.searchBtn, {
                      [classes.searchBtnOpen]: openSearch,
                    })}
                    onChange={(e) => setSearchKey(e.target.value)}
                    value={searchKey}
                    search
                    dense
                    variant="standard"
                    onOpenSearch={() => {
                      const openEvent = new CustomEvent("drawer:open");
                      document.dispatchEvent(openEvent);
                      setOpenSearch(true);
                    }}
                    onCloseSearch={() => {
                      setSearchKey("");
                      setOpenSearch(false);
                    }}
                  />
                </div>
              )}
            </div>
            {loading && type === loadCameras.type && (
              <div className={classes.loadingDiv}>
                <CircularProgress size={48} thickness={6} color="primary" />
              </div>
            )}

            {/* Payment method : oneplan */}
            {(subscriptionInfo && subscriptionInfo.paymentMethod) ===
              "oneplan" && (
              <div className={classes.onePlanInfoDiv}>
                <Typography category="Default" variant="Caption">
                  {remainTime === 0
                    ? "D - DAY"
                    : remainTime === 1
                    ? "D - 1"
                    : remainTime + " days remaining"}
                  &nbsp;/ Expires :&nbsp;
                  {moment
                    .utc(subscriptionInfo?.endOfMonthDT)
                    .format("MMMM")}{" "}
                  {moment.utc(subscriptionInfo?.endOfMonthDT).format("D, YYYY")}
                </Typography>
              </div>
            )}

            {!(
              cameraList &&
              cameraList.deviceListInfo &&
              cameraList.deviceListInfo?.length > 0
            ) &&
              open &&
              !(loading && type === loadCameras.type) && (
                // mantis - 10235 카메라리스트에 카메라가 없는 경우 userType에 따라 markup 다르게나오도록 수정 (Leehj)
                <>{noCameraListMarkup}</>
              )}

            {cameraListMarkup}
            {planExpiredMarkup}
          </div>

          <div
            className={clsx(classes.listPane, {
              [classes.detailDivHide]: !currentGroup,
              [classes.listDivHide]: currentCam,
            })}
          >
            <div
              className={clsx(classes.header, {
                [classes.headerOpen]: open,
              })}
            >
              <IconButton
                onClick={() => {
                  setCurrentGroup(undefined);
                }}
                className={classes.backBtn}
              >
                {/* mantis - 10483, 변경된 화살표 디자인으로 변경 (Leehj) */}
                <ArrowBackIosIcon />
              </IconButton>
              <Typography
                category="Default"
                variant="H6"
                style={{ marginTop: 2 }}
              >
                {currentGroup?.GroupName}
              </Typography>
            </div>
            <div className={classes.camDiv} style={{ overflow: "auto" }}>
              <ScrollBar>{groupCameraListMarkup}</ScrollBar>
            </div>
          </div>
          <div
            className={clsx(classes.listPane, {
              [classes.detailDivHide]: !currentCam,
            })}
          >
            <div
              className={clsx(classes.header, {
                [classes.headerOpen]: open,
              })}
            >
              <IconButton
                onClick={() => {
                  setCurrentCam(undefined);
                  onClick?.(undefined);
                }}
                className={classes.backBtn}
              >
                {/* mantis - 10483, 변경된 화살표 디자인으로 변경 (Leehj) */}
                <ArrowBackIosIcon />
              </IconButton>
              <Typography
                category="Default"
                variant="H6"
                style={{ marginTop: 2 }}
              >
                {t("Cameras")}
              </Typography>
            </div>
            <div style={{ overflow: "auto" }}>
              <ScrollBar>
                {currentCam?.active === "off" && alertMessageMarkup}
                {camDetailMarkup}
                {menuMarkup}
                {renderEventList}
              </ScrollBar>
            </div>
          </div>
          {filterDivMarkup}
        </div>
      </div>
      {!mobile && cameraMenuMarkup}
      {mobile && mobileCameraMenuMarkup}
      {openPlayerModal && currentEvent && videoModal}
      <Modal
        open={openInvalidModal}
        onClickPositive={() => {
          setOpenInvalidModal(false);
        }}
        heading={t("To access the_")}
        content={<></>}
        RButton={t("OK")}
        titleClassName={classes.modalTitle}
        contentClassName={classes.modalContent}
        className={classes.modalWrap}
      />
      <Modal
        open={openErrorModal}
        content={t("Cloud disconnected. Failed_")}
        onClose={() => setOpenErrorModal(false)}
        RButton={t("OK")}
        onClickPositive={() => {
          setOpenErrorModal(false);
        }}
        className={classes.errorModalWrap}
        actionClassName={classes.errorModalAction}
      />
      <ExceedModal
        open={openExceedModal}
        onClose={() => setOpenExceedModal(false)}
        onClickPositive={() => setOpenExceedModal(false)}
      />
      <Modal
        open={openCloudConnectionModal}
        contentClassName={classes.connectModalContent}
        onClose={() => {
          setOpenCloudConnectionModal(false);
        }}
        onClickPositive={() => {
          setOpenCloudConnectionModal(false);
        }}
        close
        RButton={mobile ? t("OK") : ""}
        fullSize={mobile}
        heading={t("How to connect_")}
        content={
          <ScrollBar
            style={{
              height: "100%",
              overflowX: "hidden",
              flex: 1,
              maxHeight: "calc(100vh - 250px)",
            }}
          >
            <ConnectToCloud
              bodyClassName={classes.cloudConnectModalDiv}
              firstTitle={t("Download BlackVue application")}
              firstContent={t("*If you are_", {
                a: `<a
        href=${`${SIMCARD_URI}?email=${email}&user_token=${loginInfo?.user_token}&token_type=web`}
        target="_blank"
        rel="noreferrer"
        style=color:${LightColors.primary["7"]}; > BlackVue SIM</a>`,
                b: "and add SIM card instead",
              })}
              secondTitle={t("Place your phone_", {
                a: `<a
        href=${CloudConnectionSettings}
        target="_blank"
        rel="noreferrer"
        style=color:${LightColors.primary["7"]}; > connecting camera</a>`,
              })}
              mobile={mobile}
              appCSS={classes.appDiv}
              appStoreCSS={classes.appStoreDiv}
              imgCSS={classes.imgCSSDiv}
            />
          </ScrollBar>
        }
      />
      <EditProfileModal
        open={openEditProfile && renameMode === "profile"}
        camera={moreCam}
        onClose={() => setOpenEditProfile(false)}
        onSuccess={() => {
          setOpenEditProfile(false);
          dispatch(loadCameras());
        }}
      />
      {/* mantis - 10379, ONLY Wi-Fi모델은 rename 모달창 출력 (Leehj) */}
      <OnlyWifiModelRenameModal
        open={openEditProfile && renameMode === "rename"}
        camera={moreCam}
        onClose={() => setOpenEditProfile(false)}
      />
      <RemoveCloudModal
        open={openRemoveCloud}
        camera={removeCam}
        onClose={() => setOpenRemoveCloud(false)}
        onSuccess={() => {
          if (removeCam?.reg_category !== "wifi") {
            setOpenRemoveCloudSuccess(true);
          }
          setOpenRemoveCloud(false);
          dispatch(loadCameras());
          // mantis - 11006, RemoveCloud 성공시 loadGroups실행하여 RemoveCloud한 캠이 캠리스트에 출력되도록 수정 (Leehj)
          dispatch(loadGroups());
        }}
        mode={removeMode}
      />
      <UnpairBatteryModal
        open={openUnpairBattery}
        camera={unpairBatteryCam}
        onClose={() => setOpenUnpairBattery(false)}
        onSuccess={() => {
          setOpenUnpairBattery(false);
          dispatch(loadCameras());
        }}
      />
      <RemoveCloudSuccessModal
        open={openRemoveCloudSuccess}
        onClose={() => setOpenRemoveCloudSuccess(false)}
      />
      <AddCameraGuideModal
        open={openGuideModal}
        onClose={() => setOpenGuideModal(false)}
        mode="wifi"
      />
      <NotConnectedCameraModal
        open={openNotConnectedModal}
        onClose={() => {
          setOpenNotConnectedModal(false);
        }}
      />
    </>
  );
};
