import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../store";
import { IUserLoginInfo, USER } from "../User/slice";
import {
  IEventRequest,
  ILatestEvent,
  LatestEventOrig,
  loadEvent,
  loadLatestEvent,
  successLoadEvent,
  successLoadLatestEvent,
} from "./slice";
import * as Api from "../../apis";
import { RESULT_CODE } from "../../types";
import _ from "lodash";
import moment from "moment";

function* handleLoadEvent({ payload }: PayloadAction<IEventRequest>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getEvents,
      email,
      loginInfo.user_token,
      payload.psn,
      payload.msg_code,
      payload.startDate,
      payload.endDate
    );
    const {
      resultcode,
      response: { AlarmData },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: {
        AlarmData: LatestEventOrig[];
      };
    };
    if (resultcode === "BC_ERR_OK") {
      const item: ILatestEvent[] = _.chain(AlarmData)
        .groupBy("psn")
        .map((values) => {
          return _.map(values, (v) => {
            const vdate = moment(v.vdate);
            return {
              ...v,
              vdate,
            };
          });
        })
        .flattenDeep()
        .sortBy((ev) => moment(ev.vdate))
        .reverse()
        .value();
      yield put(successLoadEvent(item));
    }
  } catch (err) {}
}

function* handleLoadLatestEvent({ payload }: PayloadAction<string[]>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getLatestEvents,
      email,
      loginInfo.user_token,
      payload
    );
    const {
      resultcode,
      response: { AlarmData },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: {
        AlarmData: LatestEventOrig[];
      };
    };
    if (resultcode === "BC_ERR_OK") {
      const item: ILatestEvent[] = _.chain(AlarmData)
        .groupBy("psn")
        .map((values) => {
          return _.map(values, (v) => {
            const vdate = moment(v.vdate);
            return {
              ...v,
              vdate,
            };
          });
        })
        .flattenDeep()
        .sortBy((ev) => moment(ev.vdate))
        .reverse()
        .value();
      yield put(successLoadLatestEvent(item));
    }
  } catch (err) {}
}

export function* watchEvent() {
  yield takeLatest(loadEvent, handleLoadEvent);
  yield takeLatest(loadLatestEvent, handleLoadLatestEvent);
}
