import { makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import LayersIcon from "@material-ui/icons/Layers";
import { Fab } from "@thingsw/pitta-design-system";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MapboxGuideBackIcon from "../../icons/MapboxGuideBackIcon";
import MapboxGuideFilterIcon from "../../icons/MapboxGuideFilterIcon";
import MapboxGuideMapTypeIcon from "../../icons/MapboxGuideMapTypeIcon";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 100,
    padding: 16,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "space-between",
  },
  circle: {
    borderRadius: "50%",
    width: 40,
    height: 40,
  },
  backButtonGuide: {
    position: "absolute",
    top: 30,
    left: 67,
  },
  topRightControl: {
    display: "flex",
    gap: 8,
  },
  mapTypeGuide: {
    position: "absolute",
    top: 30,
    right: 120,
  },
  filterGuide: {
    position: "absolute",
    top: 70,
    right: 30,
  },
}));

interface MapboxOnboardingProps {
  isFirstDevice?: boolean;
}

const MapboxOnboarding = ({ isFirstDevice }: MapboxOnboardingProps) => {
  const [isShow, setIsShow] = useState(isFirstDevice);
  const classes = useStyles();

  if (!isShow) {
    return <></>;
  }

  return (
    <div onClick={() => setIsShow(false)} className={classes.root}>
      <div>
        <Fab size="small" variant="rounded" className={classes.circle}>
          <ArrowBackIosIcon />
        </Fab>
        <div className={classes.backButtonGuide}>
          <MapboxGuideBackIcon />
        </div>
      </div>
      <div className={classes.topRightControl}>
        <Fab size="small" variant="rounded" className={classes.circle}>
          <LayersIcon />
        </Fab>
        <Fab size="small" variant="rounded" className={classes.circle}>
          <FilterListIcon />
        </Fab>
        <div className={classes.mapTypeGuide}>
          <MapboxGuideMapTypeIcon />
        </div>
        <div className={classes.filterGuide}>
          <MapboxGuideFilterIcon />
        </div>
      </div>
    </div>
  );
};

export default MapboxOnboarding;
