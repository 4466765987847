import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../CameraPlaybackScreen";

import { Card, useMediaQuery } from "@material-ui/core";
import {
  Button,
  IconButton,
  Selector,
  Switch,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next/";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  loadFirmwareSettings,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { SystemLEDPanel } from "../../components/cameras/SystemLEDPanel";
import { SettingSlider } from "../../components/SettingSlider";

import Divider from "@material-ui/core/Divider";
import { SystemTimeZonePanel } from "../../components/cameras/SystemTimeZonePanel";
import { SystemLanguagePanel } from "../../components/cameras/SystemLanguagePanel";

import { FirmwareSettingModal } from "../../components/cameras/FirmwareSettingModal";
import InfoIcon from "@material-ui/icons/Info";
import { Prompt, useHistory } from "react-router-dom";
import { Location } from "history";
import { SOSModal } from "../../components/cameras/SOSModal";
import { DiscardChangeUXUIModal } from "../../components/uxui/DiscardChangeUXUIModal";
import { VoiceGuidanceModal } from "../../components/uxui/VoiceGuidanceModal";
import { FirmwareFormatModalUXUI } from "../../components/uxui/FirmwareFormatModalUXUI";
import { TimeSyncModal } from "../../components/cameras/TimeSyncModal";
import {
  Webviewer,
  LightColors,
  TabNameInfo1,
  TabNameInfo3,
  TrueFalsetype,
  IFirmwareCloudSettings,
  SOS_BUTTON_MODELS,
  ScheduledRebootTime,
  SystemADASLDWS,
  OPTION_CAMERA_MODELS,
  RESULT_CODE,
  RCT_TIMESYNC_MODALS,
  MODEL_KEY,
} from "@thingsw/pitta-modules";
import React from "react";
import moment from "moment";
import { updateTimeSync } from "../../apis";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E8E8E8",
    minHeight: "100%",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // mantis - 10680, 언어 드롭박스 잘리던 이슈 height 지정해주어 수정 (Leehj)
      // mantis - 10766, 작은 화면에서 스크롤 안 되는 이슈 수정 / height를 지정해주면 커스텀 스크롤이 작동하지 않는다.(Leehj)
      // height: "calc(var(--vh, 1vh) * 100 - 103px)",
      minHeight: "calc(var(--vh, 1vh) * 100 - 103px)",
    },
  },

  rootPanel: {
    // mantis - 10533, 스크린 하단 스크롤 생기는 이슈 수정 (Leehj)
    overflow: "hidden",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    transition: theme.transitions.create("transform"),
    position: "relative",
    padding: "24px 16px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 24,
      // mantis - 10680, rootPanel에 minHeight를 지정해주는 것으로 2차수정 (Leehj)
      minHeight: "calc(var(--vh, 1vh) * 100 - 103px)",
    },
  },

  listPane: {
    flexDirection: "column",
    top: 16,
    bottom: 0,
    right: 0,
    left: 0,
    width: "inherit",
    transition: theme.transitions.create("transform"),
    transitionDuration: "0.5s",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: 23,
      width: 673,
      flex: 1,
      display: "flex",
    },
  },
  systemHide: {
    transition: theme.transitions.create("transform"),
    transitionDuration: "0.5s",
    transform: "translateX(-100vw)",
    position: "absolute",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-675px)",
    },
  },
  LEDHide: {
    transitionDuration: "0.5s",
    transition: theme.transitions.create("transform"),
    transform: "translateX(100vw)",
    position: "absolute",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(100vw)",
    },
  },

  flex: {
    display: "flex",
    alignItems: "center",
  },

  card: {
    display: "flex",
    maxWidth: 673,
    minHeight: 46,
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  thirdCard: {
    padding: "13px 24px 12px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "11px 24px 10px",
      minHeight: 66,
    },
  },

  moveCard: {
    padding: "12px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px",
      minHeight: 58,
    },
  },

  selectOptionWrap: {
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      alignItems: "center",
      flexDirection: "row",
    },
  },

  divider: {
    margin: "15px 0 15px 1px",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },
  contentDivider: {
    margin: "3px 20px",
  },
  volumeWrap: {
    display: "flex",
    flexDirection: "column",
    padding: "14px 20px 11px",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      flexDirection: "row",
    },
  },
  volumeInfoDiv: {
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    marginBottom: 9,
    minHeight: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },
  volumeText: {
    marginRight: 7,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 9,
    },
  },
  borderBottomBox: {
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },

  spaceBetween: {
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
    },
  },

  colum: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  switchWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "12px 24px",
  },
  scheduledWrap: {
    padding: "12px 24px 10px",
  },
  switchADASWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "20px 24px",
    minHeight: 48,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  switchLDWSWrap: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      cursor: "pointer",
    },
  },
  switchFVSAWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "19px 24px 20px",
    maxWidth: 672,
  },

  switch: {
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 20,
    },
  },

  boxMenu: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      cursor: "pointer",
    },
  },

  arrowIcon: {
    color: LightColors.primary["3"],
  },

  speedUnitDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  select: {
    borderRadius: 12,
    padding: "8px 24px",
  },

  selectPaper: {
    width: 234,
  },

  selectDiv: {
    paddingTop: 16,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      minWidth: 267,
      padding: "0",
    },
  },

  sliderDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: -4,
    marginLeft: -4,
    minHeight: 42,
  },
  saveBtn: {
    width: 96,
    marginTop: 15,
    maxWidth: 673,
  },
  infoIcon: {
    color: LightColors.primary["2"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },

  titleDive: {
    display: "flex",
    marginBottom: 15,
    alignItems: "center",
    marginLeft: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 20,
    },
  },
  disabledText: {
    opacity: 0.35,
  },
  voiceGuidanceIconBtn: {
    marginLeft: 9,
  },
}));

interface SystemFirmwareSettingProps {
  onClose: () => void;
}

export const CameraSystemSettingScreen = (
  props: CameraPlaybackScreenProps &
    PermissionProps &
    ScreenDefaultProps &
    SystemFirmwareSettingProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera, firmware, firmwareConfig, loading, type, cameraTimeSync } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const [menu, setMenu] = useState<number>();
  const [currentTab1Obj, setCurrentTab1Obj] = useState<TabNameInfo1>();
  const [currentTab3Obj, setCurrentTab3Obj] = useState<TabNameInfo3>();
  const [preCurrentTab, setPreCurrentTab] = useState<TabNameInfo1>();
  const [disabledChange, setDisabledChange] = useState(true);

  const [language, setLanguage] = useState<string>("English");

  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openFirmwareFormat, setOpenFirmwareFormat] = useState(false);
  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);
  const [openSOSModal, setOpenSOSModal] = useState(false);
  const [openVoiceGuidanceModal, setOpenVoiceGuidanceModal] = useState(false);

  const [targetLocation, setTargetLocation] = useState<Location>();
  const [openTimeSyncModal, setOpenTimeSyncModal] = useState(false);
  const [timeSyncLoading, setOpenTimeSyncLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      setOpenFirmwareFormat(false);
    }
  }, [loading]);

  const timeSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const systemSettings = useMemo(() => {
    return firmwareConfig?.System;
  }, [firmwareConfig?.System]);

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
        // 새로고침이나 창 종료시에는 해당 모달 출력 방지
        setOpenDiscardModal(false);
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );

      if (currentTab) {
        // 1. video화면에서 저장 누르지않고 설정 변경 2.overlay, rotation페이지이동 다시 video페이지로 이동
        // 3.이전에 변경한 설정이 리셋되는부분 수정
        setCurrentTab1Obj((current) =>
          !current ? (currentTab.section_info as TabNameInfo1) : current
        );
      }
    }
  }, [firmware]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      setPreCurrentTab(currentTab?.section_info as TabNameInfo1);
      const predictsTab1 = _.keys(currentTab?.section_info as TabNameInfo1)
        // mantis - 12644, 세팅 값 일치 검증에서 TimeSet 값 제외 (Hongcs) 23.10.23
        .filter((key) => key !== "TimeSet")
        .map((k) => {
          const key = k as keyof TabNameInfo1;
          return (
            (currentTab?.section_info as TabNameInfo1)[key] ===
            currentTab1Obj?.[key]
          );
        });

      const predictsTab3 = _.keys(
        currentTab3?.section_info as TabNameInfo3
      ).map((k) => {
        const key = k as keyof TabNameInfo3;
        return (
          (currentTab3?.section_info as TabNameInfo3)[key] ===
          currentTab3Obj?.[key]
        );
      });
      setDisabledChange(
        _.every(predictsTab1) &&
          _.every(predictsTab3) &&
          firmware.firmware_language === language
      );
    }
  }, [firmware, currentTab1Obj, currentTab3Obj, language]);

  useEffect(() => {
    if (firmware) {
      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      if (currentTab3) {
        setCurrentTab3Obj(currentTab3.section_info as TabNameInfo3);
      }
    }
  }, [firmware]);

  // 언어
  useEffect(() => {
    if (firmware) {
      setLanguage(firmware.firmware_language);
    }
  }, [firmware]);

  const getBoolean = (TrueFalse: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };

  const handleChangeTab3 = (key: keyof TabNameInfo3, checked: boolean) => {
    setCurrentTab3Obj(
      (c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo3)
    );
  };

  const RctTimeSyncModel = useMemo(() => {
    if (!camera) return false;
    const model = camera.model as MODEL_KEY;
    const version = Number(RCT_TIMESYNC_MODALS[model]);
    return version && (parseFloat(camera.fw_ver) >= version)
      ? true
      : false;
  }, [camera]);

  const LockEventModel = useMemo(
    () =>
      _.includes(
        [
          "DR750X-3CH Plus",
          "DR750X LTE Plus",
          "DR750-2CH LTE",
          "DR900X Plus",
          "DR750X Plus",
          "BlackVue 7",
          "BlackVue 9",
          // mantis - 8655, 일본어 변경시 LockEvent, OverwriteLock항목 값1로 변경
          "BlackVue 7 Box",
          // mantis - 8824, 일본어 변경시 LockEvent, OverwriteLock항목 값1로 변경(Leehj)
          "BlackVue 7 LTE",
          "BlackVue 9 LTE",
        ],
        camera?.model
      ),
    [camera?.model]
  );
  // save버튼 업데이트
  const handleUpdate = () => {
    if (currentTab1Obj && camera && firmware && currentTab3Obj) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab1" && c.section_name !== "Tab3"
        ),
      };
      updated.cloud_settings = [
        { section_info: currentTab1Obj, section_name: "Tab1" },
        { section_info: currentTab3Obj, section_name: "Tab3" },
        ...updated.cloud_settings,
      ];
      // 언어 일본어로 변경하는 경우
      if (language === "Japan" /*|| current === "Korea"*/) {
        let basic = {
          section_info: currentTab1Obj,
          section_name: "Tab1",
        } as IFirmwareCloudSettings;

        let system = {
          section_info: currentTab3Obj,
          section_name: "Tab3",
        } as IFirmwareCloudSettings;

        system = {
          ...system,
          section_info: {
            ...system?.section_info,
            // 일본어의 경우 NORALLED, PARKINGLED가 무조건 0으로 저장되는 이슈 수정 (0013680)
            // NORMALLED: "0" as TrueFalsetype,
            // PARKINGLED: "0" as TrueFalsetype,
          },
        };
        if (OPTION_CAMERA_MODELS) {
          system = {
            ...system,
            section_info: {
              ...system?.section_info,
              OptionRearLED: "0" as TrueFalsetype,
            },
          };
        }
        if (LockEventModel) {
          basic = {
            ...basic,
            section_info: {
              ...basic?.section_info,
              LockEvent: "1" as TrueFalsetype,
              OverwriteLock: "1" as TrueFalsetype,
            },
          };
        }
        // timezone과 daylight의 변경이 있는 경우
        if (
          preCurrentTab?.Daylight !== currentTab1Obj?.Daylight ||
          preCurrentTab.TimeZone !== currentTab1Obj.TimeZone
        ) {
          basic = {
            ...basic,
            section_info: {
              ...basic?.section_info,
              GpsSync: "1" as TrueFalsetype,
              SetTime: "",
              TimeSet: "1",
            },
          };
        }

        if (
          timeSettings?.["TimeZone"] === "900" &&
          timeSettings?.GpsSync === "1"
        ) {
          // updated.cloud_settings = [...updated.cloud_settings, basic, system ];
          updated.cloud_settings = [
            // mantis - 11099, tab3이 두번들어가는 것을 막기위함. (Leehj)
            ..._.filter(
              updated.cloud_settings,
              (c) => c.section_name !== "Tab3"
            ),
            basic,
            system,
          ];
          dispatch(
            updateFirmwareSettings({
              firmware: {
                ...updated,
                firmware_language: language,
                update_date: undefined,
              },
            })
          );
        } else {
          updated.cloud_settings = [
            {
              ...basic,
              section_info: {
                ...basic.section_info,
                TimeSet: "1",
                TimeZone: "900",
                GpsSync: "1",
              },
            },
            // tab1이 두 번 들어가는 것을 막기 위함
            ..._.filter(
              updated.cloud_settings,
              (c) => c.section_name !== "Tab1"
            ),
          ];

          dispatch(
            updateFirmwareSettings({
              firmware: {
                ...updated,
                firmware_language: language,
                update_date: undefined,
              },
            })
          );
        }

        // 언어를 일본어로 변경하지 않고 timezone과 daylight의 변경이 있는 경우
      } else if (
        preCurrentTab?.Daylight !== currentTab1Obj?.Daylight ||
        preCurrentTab.TimeZone !== currentTab1Obj.TimeZone
      ) {
        updated.cloud_settings = [
          {
            section_info: {
              ...currentTab1Obj,
              TimeSet: "1",
              GpsSync: "1" as TrueFalsetype,
              SetTime: "",
            },
            section_name: "Tab1",
          },
          // tab1이 두 번 들어가는 것을 막기 위함
          ..._.filter(updated.cloud_settings, (c) => c.section_name !== "Tab1"),
        ];
        dispatch(
          updateFirmwareSettings({
            firmware: {
              ...updated,
              firmware_language: language,
              update_date: undefined,
            },
          })
        );
      }
      // 언어도 일본어로 변경하지 않고 timezone과 daylight도 변경하지 않은 경우
      else {
        dispatch(
          updateFirmwareSettings({
            firmware: { ...updated, firmware_language: language },
          })
        );
      }
    }
  };

  const is800GoldSeries = useMemo(() => {
    return _.includes(["DR800 GOLD", "DR800Gold Pro"], camera?.model);
  }, [camera?.model]);
  
  const handleTimeSync = async () => {
    if (!camera) return;
    try {
      setOpenTimeSyncLoading(true);
      const timeStamp = moment().utc().unix();
      const res = await updateTimeSync(camera.psn, timeStamp);
      const { resultcode } = res.data as {
        resultcode: RESULT_CODE;
        message: string;
      };
      if (resultcode === "BC_ERR_OK" || resultcode === "BC_ERR_TIMEOUT") { // 에러 코드에 상관 없이 카메라 리스트 이동
        setDisabledChange(true);
        setOpenDiscardModal(false);
        setOpenTimeSyncLoading(false);
        setOpenTimeSyncModal(false);
        history.push(`/cameras`);
      }
    } catch (err) {
      console.error(err);
      setOpenTimeSyncLoading(false);
    }
  }

  return (
    <div className={classes.root}>
      {/* timezone과 daylight가 변경이 있을 때 Prompt open*/}
      <Prompt
        when={
          !disabledChange && !targetLocation
          // mantis - 11462, 시스템 셋팅 설정이 변경되면 Prompt open되도록 수정 (Leehj)
          // &&
          // (preCurrentTab?.Daylight !== currentTab1Obj?.Daylight ||
          //   preCurrentTab?.TimeZone !== currentTab1Obj?.TimeZone)
        }
        message={(location) => {
          setTargetLocation(location);
          // 세팅화면이 아닌 페이지로 이동하는 경우에만 discard모달 출력
          if (location.pathname.indexOf("settings") === -1) {
            setOpenDiscardModal(true);
            return false;
          } else {
            return true;
          }
        }}
      />

      <div className={classes.rootPanel}>
        <div
          className={clsx(classes.listPane, {
            [classes.systemHide]: menu !== undefined,
          })}
        >
          <div className={clsx(classes.flex, classes.titleDive)}>
            <Typography category="Default" variant="H6">
              {t("System")}
            </Typography>
          </div>

          {/* firstBox */}
          <SystemTimeZonePanel
            currentTab1={currentTab1Obj}
            onSettingChange={(
              key: keyof TabNameInfo1,
              val: string | TrueFalsetype
            ) => {
              setCurrentTab1Obj((c) => ({ ...c, [key]: val } as TabNameInfo1));
            }}
          />

          {RctTimeSyncModel && (
            <>
              <Divider className={classes.divider} />

              <Card className={clsx(
                classes.card,
                classes.moveCard,
                (camera?.active === "off") && classes.disabledText,
              )}>
                <div 
                  className={classes.boxMenu} 
                  onClick={() => {
                    if (camera?.active === "off") return;
                    setOpenTimeSyncModal(true);
                  }}
                >
                  <Typography category="Default" variant="Body">
                    {t("Time Sync")}
                  </Typography>

                  {cameraTimeSync && (
                    <div 
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: 4,
                          marginRight: 12,
                          backgroundColor: LightColors.secondary["11"],
                        }}
                      />
                      <IconButton>
                        <ChevronRightIcon className={classes.arrowIcon} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Card>
            </>
          )}

          <Divider className={classes.divider} />
          {/* secondbox */}
          <Card className={clsx(classes.card, classes.moveCard)}>
            <div className={classes.boxMenu} onClick={() => setMenu(0)}>
              <Typography category="Default" variant="Body">
                {t("LED")}
              </Typography>

              <IconButton>
                <ChevronRightIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
          </Card>
          <Divider className={classes.divider} />
          {/* tirdbox */}

          {_.includes(SOS_BUTTON_MODELS, camera?.model) ? (
            <Card className={clsx(classes.card, classes.thirdCard)}>
              <div className={classes.colum}>
                <div style={{ display: "flex" }}>
                  <Typography
                    category="Default"
                    variant="Body"
                    style={{
                      marginBottom: 3,
                    }}
                  >
                    {t("SOS button")}
                  </Typography>
                  <div style={{ marginLeft: 16, display: "flex" }}>
                    <Tooltip
                      // mantis - 11000, 모바일에서도 툴팁나오도록 수정(Leehj)
                      // disableTouchListener={mobile}
                      setting={mobile}
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("To register your_")}
                        </Typography>
                      }
                      // mantis - 11000, 모바일에서도 툴팁나오도록 수정(Leehj)
                      placement={mobile ? "left" : "right"}
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                </div>

                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["4"]}
                >
                  {t(currentTab3Obj?.EBRFID ?? "No registered device")}
                </Typography>
              </div>
              <IconButton onClick={() => setOpenSOSModal(true)}>
                <ChevronRightIcon className={classes.arrowIcon} />
              </IconButton>
            </Card>
          ) : (
            <Card className={clsx(classes.card, classes.thirdCard)}>
              <div className={classes.switch}>
                <Switch
                  checked={getBoolean(
                    currentTab3Obj?.["PSENSOR"] as TrueFalsetype
                  )}
                  onChange={(e) => {
                    handleChangeTab3("PSENSOR", e.target.checked);
                  }}
                />
              </div>
              <div className={classes.colum}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{
                    marginBottom: 3,
                  }}
                >
                  {t("Touch sensor")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={
                    currentTab3Obj?.["PSENSOR"] === "1"
                      ? LightColors.primary["7"]
                      : LightColors.primary["3"]
                  }
                >
                  {t("Manual recording")}
                </Typography>
              </div>
            </Card>
          )}

          <Divider className={classes.divider} />
          {/* fourth box */}
          <Card className={clsx(classes.card, classes.colum)}>
            {systemSettings?.["VoiceGuidance"] && (
              <div
                className={clsx(classes.selectOptionWrap, classes.switchWrap)}
              >
                <div className={classes.switch}>
                  <Switch
                    checked={getBoolean(currentTab3Obj?.["STARTVOICE"] ?? "1")}
                    onChange={(e) => {
                      handleChangeTab3("STARTVOICE", e.target.checked);
                      handleChangeTab3("NORMALSTARTVOICE", e.target.checked);
                      handleChangeTab3(
                        "CHANGERECORDMODEVOICE",
                        e.target.checked
                      );
                      handleChangeTab3("ENDVOICE", e.target.checked);
                      handleChangeTab3("CLOUDVOICE", e.target.checked);
                      // mantis - 12619, setting have been changed 무조건 on (Hongcs)
                      handleChangeTab3("CHANGECONFIGVOICE", true);
                    }}
                  />
                </div>
                <div>
                  <Typography
                    category="Default"
                    variant="Body"
                    style={{ marginBottom: mobile ? 3 : 2 }}
                  >
                    {t("Voice guidance")}
                  </Typography>
                  <IconButton
                    className={classes.voiceGuidanceIconBtn}
                    onClick={() => setOpenVoiceGuidanceModal(true)}
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton>
                </div>
              </div>
            )}
            <Divider className={classes.contentDivider} />
            {systemSettings?.["VOLUME"] && (
              <div className={clsx(classes.spaceBetween, classes.volumeWrap)}>
                <div className={classes.volumeInfoDiv}>
                  <Typography
                    category="Default"
                    variant="Body"
                    // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.13
                    // mantis - 12640, Volume 비활성화 취소 (Hongcs) 23.10.19
                    // className={clsx(
                    //   currentTab3Obj?.STARTVOICE === "0" && classes.disabledText
                    // )}
                  >
                    {t("Volume")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    htmlColor={LightColors.primary["7"]}
                    className={classes.volumeText}
                  >
                    {/* 0인 경우, 우측 상단에 OFF로 노출 */}
                    {currentTab3Obj &&
                      (currentTab3Obj?.VOLUME === "0"
                        ? t("OFF")
                        : currentTab3Obj?.VOLUME)}
                  </Typography>
                </div>

                <div className={classes.sliderDiv}>
                  <SettingSlider
                    min={0}
                    max={5}
                    value={parseInt(currentTab3Obj?.VOLUME ?? "0")}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrentTab3Obj((c) => {
                        return (
                          c && {
                            ...c,
                            VOLUME: v.toString(),
                          }
                        );
                      });
                    }}
                    // 항목이 비활성화인 경우 슬라이더도 비활성화 (Leehj) 23.01.13
                    // mantis - 12640, Volume 비활성화 취소 (Hongcs) 23.10.19
                    // disabled={currentTab3Obj?.STARTVOICE === "0"}
                  />
                </div>
              </div>
            )}
          </Card>
          <Divider className={classes.divider} />

          {/* fifth box */}
          {systemSettings?.["ScheduledReboot"] && (
            <>
              <Card
                className={clsx(classes.card, classes.colum)}
                style={{ minHeight: mobile ? "auto" : 111 }}
              >
                <div
                  className={clsx(
                    classes.selectOptionWrap,
                    classes.switchWrap,
                    classes.scheduledWrap
                  )}
                >
                  <div className={classes.switch}>
                    <Switch
                      checked={getBoolean(
                        currentTab3Obj?.["ScheduledReboot"] ?? "1"
                      )}
                      onChange={(e) => {
                        handleChangeTab3("ScheduledReboot", e.target.checked);
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      category="Default"
                      variant="Body"
                      style={{ marginBottom: mobile ? 3 : 2 }}
                    >
                      {t("Scheduled reboot")}
                    </Typography>
                    <div style={{ marginLeft: 16, display: "flex" }}>
                      <Tooltip
                        setting
                        // mantis - 10757, 모바일에서도 툴팁나오도록 수정(Leehj)
                        // disableTouchListener={mobile}
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("Reboots once-a-day_")}.
                          </Typography>
                        }
                        // mantis - 10757, 모바일에서도 툴팁나오도록 수정(Leehj)
                        placement={mobile ? "left" : "right"}
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <Divider className={classes.contentDivider} />

                <div
                  className={clsx(
                    classes.spaceBetween,
                    classes.selectOptionWrap
                  )}
                >
                  <Typography
                    category="Default"
                    variant="Body"
                    // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                    className={clsx(
                      currentTab3Obj?.ScheduledReboot !== "1" &&
                        classes.disabledText
                    )}
                  >
                    {t("Set time")}
                  </Typography>
                  <div className={classes.selectDiv}>
                    <Selector
                      setting
                      settingScroll
                      outlinedInputClassName={classes.select}
                      menuItemClassName={classes.selectPaper}
                      t={t}
                      disabled={currentTab3Obj?.ScheduledReboot !== "1"}
                      dense
                      mobile={mobile}
                      keyValue={currentTab3Obj?.ScheduledRebootTime ?? "3"}
                      onChange={(e) => {
                        setCurrentTab3Obj((c) => {
                          return c && { ...c, ScheduledRebootTime: e.key };
                        });
                      }}
                      items={_.map(ScheduledRebootTime, (val, key) => {
                        return {
                          key: key,
                          value: val,
                        };
                      })}
                    />
                  </div>
                </div>
              </Card>
            </>
          )}

          {/* sixth box */}
          {systemSettings?.["ADAS"] && (
            <>
              <Divider className={classes.divider} />
              
              <Card
                className={clsx(classes.card, classes.colum)}
                style={{ minHeight: mobile ? "auto" : 183 }}
              >
                <div className={clsx(classes.switchADASWrap)}>
                  <div className={classes.switch}>
                    <Switch
                      checked={getBoolean(
                        currentTab3Obj?.["ADASSetting"] ?? "0"
                      )}
                      onChange={(e) => {
                        handleChangeTab3("ADASSetting", e.target.checked);
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      category="Default"
                      variant="Body"
                      style={{ marginBottom: mobile ? 3 : 2 }}
                    >
                      {t("ADAS")}
                    </Typography>
                  </div>
                </div>

                <Divider className={classes.contentDivider} />

                <div className={clsx(classes.switchLDWSWrap)}>
                  <Typography
                    category="Default"
                    variant="Body"
                    style={{ marginBottom: 3 }}
                    // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                    className={clsx(
                      currentTab3Obj?.ADASSetting === "0" &&
                        classes.disabledText
                    )}
                  >
                    {t("LDWS")}
                  </Typography>
                  <div style={{ maxWidth: 213 }}>
                    <Selector
                      outlinedInputClassName={classes.select}
                      menuItemClassName={classes.selectPaper}
                      t={t}
                      disabled={currentTab3Obj?.ADASSetting === "0"}
                      keyValue={currentTab3Obj?.ADASLDWS ?? "0"}
                      onChange={(e) => {
                        setCurrentTab3Obj((c) => {
                          return c && { ...c, ADASLDWS: e.key };
                        });
                      }}
                      dense
                      mobile={mobile}
                      items={_.map(SystemADASLDWS, (val, key) => {
                        return {
                          key: key,
                          value: val,
                        };
                      })}
                    />
                  </div>
                </div>

                <Divider className={classes.contentDivider} />

                <div className={classes.switchFVSAWrap}>
                  <div className={classes.switch}>
                    <Switch
                      disabled={currentTab3Obj?.ADASSetting === "0"}
                      checked={getBoolean(currentTab3Obj?.["ADASFVSA"] ?? "0")}
                      onChange={(e) => {
                        handleChangeTab3("ADASFVSA", e.target.checked);
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      category="Default"
                      variant="Body"
                      style={{ marginBottom: mobile ? 3 : 2 }}
                      // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                      className={clsx(
                        currentTab3Obj?.ADASSetting === "0" &&
                          classes.disabledText
                      )}
                    >
                      {t("FVSA")}
                    </Typography>
                  </div>
                </div>
              </Card>
            </>
          )}

          {/* seventh box */}
          {/* mantis - 11265, 러시아 펌웨어의 경우, DR800 GOLD 캠인 경우 FW Language 항목을 노출 시키지 않는다. (Leehj) */}
          {(firmwareConfig?.["FirmwareLanguage"] && 
            firmware?.firmware_language !== "Russian") &&
            !is800GoldSeries && (
              <>
                <Divider className={classes.divider} />

                <SystemLanguagePanel
                  language={language}
                  onLanguageChange={(lang) => setLanguage(lang)}
                />
              </>
            )}

          <Button
            disabled={disabledChange}
            variant="contained"
            color="primary"
            className={classes.saveBtn}
            loading={loading && type === updateFirmwareSettings.type}
            onClick={() => {
              // Daylight saving time 혹은 timezone 변경 시
              if (
                preCurrentTab?.Daylight !== currentTab1Obj?.Daylight ||
                preCurrentTab?.TimeZone !== currentTab1Obj?.TimeZone
              ) {
                setOpenFirmwareFormat(true);
              }
              // 언어 일본어로 변경 시
              else if (
                firmware?.firmware_language !== language &&
                language === "Japan"
              ) {
                if (
                  timeSettings?.["TimeZone"] === "900" &&
                  timeSettings?.GpsSync === "1"
                ) {
                  setOpenFirmwareModal(true);
                } else {
                  setOpenFirmwareFormat(true);
                }
              } else {
                handleUpdate();
              }
            }}
          >
            {t("Save")}
          </Button>
        </div>

        {/* LED 화면 */}
        <div
          className={clsx(classes.listPane, {
            [classes.LEDHide]: menu !== 0,
          })}
        >
          <SystemLEDPanel
            onClose={() => setMenu(undefined)}
            currentTab3={currentTab3Obj}
            onChange={(key: keyof TabNameInfo3, val: boolean) => {
              setCurrentTab3Obj(
                (c) => ({ ...c, [key]: val ? "1" : "0" } as TabNameInfo3)
              );
            }}
          />
        </div>
      </div>

      {/* save Button 누르면 나오는 경고창 모달*/}
      {openFirmwareFormat && (
        // mantis - 11443, 펌웨어 포맷 경고 모달 디자인 변경 (Leehj)
        <FirmwareFormatModalUXUI
          loading={loading}
          open={openFirmwareFormat}
          onClose={() => setOpenFirmwareFormat(false)}
          onClickNegative={() => {
            setOpenFirmwareFormat(false);
            if (targetLocation) {
              history.push(targetLocation.pathname);
              setTargetLocation(undefined);
            }
          }}
          onClickPositive={() => {
            handleUpdate();
            setOpenFirmwareFormat(false);
            if (targetLocation) {
              history.push(targetLocation.pathname);
              setTargetLocation(undefined);
            }
          }}
        />
      )}
      {openDiscardModal && (
        <DiscardChangeUXUIModal
          open={openDiscardModal}
          onClose={() => {
            setOpenDiscardModal(false);
            setTargetLocation(undefined);
          }}
          // mantis - 11445, 디자인 변경되면서 버튼 기능도 변경 (Leehj)
          onClickNegative={() => {
            if (targetLocation) {
              history.push(targetLocation.pathname);
            } else {
              props.onClose();
            }
            setOpenDiscardModal(false);
            setTargetLocation(undefined);
          }}
          onClickPositive={() => {
            setOpenDiscardModal(false);
            // Daylight saving time 혹은 timezone 변경 시
            if (
              preCurrentTab?.Daylight !== currentTab1Obj?.Daylight ||
              preCurrentTab?.TimeZone !== currentTab1Obj?.TimeZone
            ) {
              setOpenFirmwareFormat(true);
            }
            // 언어 일본어로 변경 시
            else if (
              firmware?.firmware_language !== language &&
              language === "Japan"
            ) {
              if (
                timeSettings?.["TimeZone"] === "900" &&
                timeSettings?.GpsSync === "1"
              ) {
                setOpenFirmwareModal(true);
              } else {
                setOpenFirmwareFormat(true);
              }
            } else {
              handleUpdate();
              if (targetLocation) {
                history.push(targetLocation.pathname);
              } else {
                props.onClose();
              }
              setTargetLocation(undefined);
            }
          }}
        />
      )}
      <FirmwareSettingModal
        open={openFirmwareModal}
        onClose={() => setOpenFirmwareModal(false)}
        onClickNegative={() => setOpenFirmwareModal(false)}
        onClickPositive={() => {
          handleUpdate();
          setOpenFirmwareModal(false);
        }}
      />
      <SOSModal
        open={openSOSModal}
        onClose={() => setOpenSOSModal(false)}
        onClickPositive={() => {
          setOpenSOSModal(false);
        }}
        currentTab3={currentTab3Obj}
        onChange={(val: boolean) => {
          setCurrentTab3Obj(
            (c) => ({ ...c, PSENSOR: val ? "1" : "0" } as TabNameInfo3)
          );
        }}
      />
      <VoiceGuidanceModal
        open={openVoiceGuidanceModal}
        onClose={() => setOpenVoiceGuidanceModal(false)}
        onClickPositive={() => {
          setOpenVoiceGuidanceModal(false);
        }}
      />
      <TimeSyncModal
        open={openTimeSyncModal}
        loading={timeSyncLoading}
        onClose={() => setOpenTimeSyncModal(false)}
        onClickPositive={() => handleTimeSync()}
        guideType="format"
      />
    </div>
  );
};
