import React, { useState } from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { ICameraInfo, LightColors, Webviewer } from "@thingsw/pitta-modules";
import { useDispatch, useSelector } from "react-redux";
import { USER } from "../../features/User/slice";
import { RootState } from "../../features/store";
import { unpairBattery } from "../../apis";
import { openToast } from "../../features/Toast/slice";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 480,
  },
  container: {
    padding: "24px 32px",
  },
  titleDiv: {
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  mobileBtnDiv: {
    padding: "0px 16px 24px",
    // mantis 10496 모바일 일 때 버튼 가운데로 이동(Leehj)
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-end",
    },
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  btnStyle: {
    minWidth: 120,
  },
}));

interface UnpairBatteryModalProps {
  open: boolean;
  onClose: () => void;
  camera: ICameraInfo | undefined;
  onSuccess: () => void;
}

const UnpairBatteryModal = (props: UnpairBatteryModalProps) => {
  const { t } = useTranslation();
  const { open, onClose, camera, onSuccess } = props;
  const { userProfile } = useSelector((state: RootState) => state[USER]);
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const [loading, setLoading] = useState(false);

  const handleUnpairBattery = async () => {
    if (!camera) return;

    try {
      setLoading(true);
      const resp = await unpairBattery(
        userProfile?.email || "",
        camera.psn,
        camera.battery.ssid
      );

      if (resp.data.resultcode === "BC_ERR_OK") {
        onSuccess();
      } else {
        dispatch(openToast({ message: t("Battery unpairing failed") }));
      }
    } catch (err) {
      dispatch(openToast({ message: t("Battery unpairing failed") }));
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles(props);

  return (
    <Modal
      open={open}
      onClose={onClose}
      noPadding
      RButton={t("Unpair")}
      LButton={t("No")}
      loading={loading}
      className={classes.root}
      actionClassName={clsx({
        [classes.mobileBtnDiv]: mobile,
      })}
      LButtonClassName={clsx(mobile && classes.btnStyle)}
      RButtonClassName={clsx(mobile && classes.btnStyle)}
      onClickNegative={onClose}
      onClickPositive={handleUnpairBattery}
      content={
        <div className={classes.container}>
          <div className={classes.titleDiv}>
            <Typography variant="H6" htmlColor={LightColors.secondary["1"]}>
              {t("Unpair battery")}
            </Typography>
          </div>
          <div className={classes.contentDiv}>
            <Typography variant="Body" htmlColor={LightColors.primary["1"]}>
              {t("Are you sure you want to unpair this battery (N)_", {
                a: camera?.battery?.ssid,
              })}
            </Typography>
            <Typography variant="Body" htmlColor={LightColors.primary["7"]}>
              {t("Unpair: It_")}
            </Typography>
          </div>
        </div>
      }
    />
  );
};

export default UnpairBatteryModal;
