import CloudIcon from "@material-ui/icons/Cloud";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import { ICameraInfo, LightColors } from "@thingsw/pitta-modules";

import moment from "moment";
import React from "react";

export const getCloudConnectivityIcon = (camera: ICameraInfo) => {
  let loggined = true;

  if (!camera.login_date) {
    loggined = false;
  } else {
    loggined = !moment(camera.login_date).isBefore(moment.utc(0));
  }

  if (camera.reg_category === "wifi" || !loggined) {
    return (
      <CloudOffIcon
        style={{ margin: 4 }}
        htmlColor={LightColors.primary["4"]}
      />
    );
  }

  return (
    <CloudIcon
      style={{ margin: 4 }}
      htmlColor={
        camera.active === "on"
          ? LightColors.primary["8"]
          : LightColors.primary["2"]
      }
    />
  );
};

export const getCloudConnectivityText = (camera: ICameraInfo) => {
  let loggined = true;

  if (!camera.login_date) {
    loggined = false;
  } else {
    loggined = !moment(camera.login_date).isBefore(moment.utc(0));
  }

  if (camera.reg_category === "wifi" || !loggined) {
    return "";
  }

  return camera.active === "on" ? "Cloud connected" : "Cloud disconnected";
};
