import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../store";
import { logout, USER } from "../User/slice";
import {
  addGeofence,
  loadGeofences,
  successRequestGeofence,
  successLoadGeofences,
  successLoadSpeedGeofences,
  deleteGeofence,
  updateGeofence,
  clearLoading,
  saveSpeedPriority,
  setOpenAdjustPriority,
} from "./slice";
import * as API from "../../apis";
import { goBack } from "connected-react-router";
import { openToast } from "../Toast/slice";
import { setError } from "../Error/slice";
import { IGeofence, IUserLoginInfo } from "@thingsw/pitta-modules";

function* handleLoadGeofences({
  payload,
}: PayloadAction<{ priority: boolean } | undefined>) {
  try {
    const { email, tokenType } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const priority = payload?.priority;
    const resp = yield call(
      API.getGeofences,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token,
      tokenType,
      priority,
    );

    const { fenceData } = resp.data as { fenceData: IGeofence[] };
    // console.log("fenceData:", fenceData);
    if (fenceData !== undefined) {
      if (priority !== undefined && priority === true) {        
        yield put(successLoadSpeedGeofences(fenceData));  
        yield put(setOpenAdjustPriority(true));
      } else {
        yield put(successLoadGeofences(fenceData));
        yield put(setOpenAdjustPriority(false));
      }
    } else {
      yield put(successLoadGeofences([]));
    }
  } catch (err) {
    const { response } = err;
    console.error("handleLoadGeofences", err);
    if (response?.status === 401) {
      yield put(logout());
    } else if (response?.status === 500) {
      // yield put(push("/Err500"));
    } else {
      yield put(setError(err.message));
    }
  }
}

function* handleAddGeofence({ payload }: PayloadAction<IGeofence>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      API.addGeofence,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token,
      payload
    );
    yield put(successRequestGeofence());
    yield put(loadGeofences());
    yield put(openToast({ message: "Zone added" }));
    yield put(goBack());
  } catch (err) {
    yield put(clearLoading());
    console.error(err);
  }
}

function* handleUpdateGeofence({ payload }: PayloadAction<IGeofence>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      API.addGeofence,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token,
      payload
    );
    yield put(successRequestGeofence());
    yield put(loadGeofences());
    yield put(openToast({ message: "Changes saved" }));
  } catch (err) {
    yield put(clearLoading());
    console.error(err);
  }
}

function* handleDeleteGeofence({
  payload,
}: PayloadAction<{ fenceId: string; fenceName: string }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      API.deleteGeofence,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token,
      payload.fenceId,
      payload.fenceName
    );
    yield put(successRequestGeofence());
    yield put(openToast({ message: "Zone deleted" }));
    yield put(loadGeofences());
  } catch (err) {
    yield put(clearLoading());
    console.error(err);
  }
}

function* handleSaveSpeedPriority({
  payload,
}: PayloadAction<IGeofence[]>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      API.saveSpeedGeofences,
      email,
      user_token,
      payload,
    );
    // yield put(openToast({ message: "Priorities saved" }));
    yield put(loadGeofences());
  } catch (err) {
    yield put(clearLoading());
    console.error(err);
  }
}

export function* watchGeofence() {
  yield takeLatest(addGeofence, handleAddGeofence);
  yield takeLatest(updateGeofence, handleUpdateGeofence);
  yield takeLatest(loadGeofences, handleLoadGeofences);
  yield takeLatest(deleteGeofence, handleDeleteGeofence);
  yield takeLatest(saveSpeedPriority, handleSaveSpeedPriority);
}
