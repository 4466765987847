import React from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      alignItems: "stretch",
    },
  },
  regular: {
    minHeight: 56,
  },
  gutters: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}));

interface ToolbarProps {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
}

export const Toolbar = (props: ToolbarProps) => {
  const theme = useTheme() as Theme;
  const classes = useStyles();
  return <MuiToolbar classes={classes} dir={theme.direction} {...props} />;
};
