import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  makeStyles,
  Theme,
  Popper,
  PopperPlacementType,
  Paper,
  FormControlLabel,
} from "@material-ui/core";
import { ReferenceObject, LightColors } from "@thingsw/pitta-modules";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Button,
  CheckBox,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: 346,
    borderRadius: 12,
  },
  header: {
    padding: theme.spacing(3, 3, 1, 3),
  },
  container: {
    padding: theme.spacing(1, 3, 1, 3),
  },
  title: {
    marginBottom: 8,
  },
  discription: {
    marginBottom: 8,
  },
  menuTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  menuIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
    color: LightColors.primary["3"],
    fontSize: 20,
  },
  settingCheckBoxDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: -3,
    ...(theme.direction === "rtl"
      ? {
        padding: theme.spacing(0, 3, 1.5, 2),
      }
      : {
        padding: theme.spacing(0, 2, 1.5, 3),
      }),
  },
  checkBoxLabel: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(-0.75) }
      : { marginLeft: theme.spacing(-0.75) }),
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  checkBoxLabelText: {
    ...(theme.direction === "rtl" ? { marginRight: 3 } : { marginLeft: 3 }),
  },
  speedInputDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 4,
    "& label": {
      marginBottom: 0,
    },
  },
  speedInput: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-root": {
      padding: "6px 13px 6px 16px",
      borderRadius: 8,
    },
    "& input": {
      height: 16,
      width: 32,
    },
    "& > span": {
      marginLeft: 8,
    },
  },
  btnDiv: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "&>button": {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(2) }
        : { marginLeft: theme.spacing(2) }),
    },
  },
  checkBoxDiv: {
    marginBottom: 8,
  },
  liveEventDiv: {
    marginBottom: 16,
    paddingLeft: 16,
  },
}));

export interface ICameraSettings {
  checkLiveEventEnter?: boolean;
  checkLiveEventExit?: boolean;
  checkLiveEventPass?: boolean;
}

interface GeofenceCamerasSettingPopprProps {
  open: boolean;
  mobile?: boolean;
  onClickAway: (event: React.MouseEvent<Document>) => void;
  onClose: () => void;
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
  placement?: PopperPlacementType;
  modifiers?: object;
  drawingMode: string;
  velocityUnit?: string;
  preSettings?: ICameraSettings;
  onSettings: (settings?: ICameraSettings) => void;
}

export const GeofenceCamerasSettingPopper = (props: GeofenceCamerasSettingPopprProps) => {
  const {
    open,
    onClickAway,
    anchorEl,
    onClose,
    onSettings,
    placement,
    modifiers,
    drawingMode,
    preSettings,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [cameraSettings, setCameraSettings] = useState<ICameraSettings>();

  useEffect(() => {
    if (!open) return;
    setCameraSettings(preSettings);
  }, [open, preSettings]);

  const setPreSettings = useCallback(() => {
    setCameraSettings(preSettings);
  }, [preSettings]);

  const camerasMarkup = useMemo(() => {
    return (
      <>
        <div className={classes.menuTitle}>
          <CloudUploadIcon className={classes.menuIcon} />
          <Typography category="Default" variant="BodyBold">
            {t("Live event upload")}
          </Typography>
        </div>
        <div className={classes.settingCheckBoxDiv}>
          {drawingMode !== "polyline" && (
            <div className={classes.checkBoxDiv}>
              <FormControlLabel
                className={classes.checkBoxLabel}
                control={
                  <CheckBox
                    color="primary"
                    checked={cameraSettings?.checkLiveEventEnter}
                    onChange={(e, checked) => setCameraSettings({...cameraSettings, checkLiveEventEnter: checked})}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.checkBoxLabelText}
                  >
                    {t("Entry")}
                  </Typography>
                }
              />
            </div>
          )}
          {drawingMode !== "polyline" && (
            <div>
              <FormControlLabel
                className={classes.checkBoxLabel}
                control={
                  <CheckBox
                    color="primary"
                    checked={cameraSettings?.checkLiveEventExit}
                    onChange={(e, checked) => setCameraSettings({...cameraSettings, checkLiveEventExit: checked})}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.checkBoxLabelText}
                  >
                    {t("Exit")}
                  </Typography>
                }
              />
            </div>
          )}
          {drawingMode === "polyline" && (
            <div>
              <FormControlLabel
                className={classes.checkBoxLabel}
                control={
                  <CheckBox
                    color="primary"
                    checked={cameraSettings?.checkLiveEventPass}
                    onChange={(e, checked) => setCameraSettings({...cameraSettings, checkLiveEventPass: checked})}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.checkBoxLabelText}
                  >
                    {t("Pass")}
                  </Typography>
                }
              />
            </div>
          )}
        </div>
      </>
    );
  }, [
    classes,
    drawingMode,
    cameraSettings,
    t,
  ]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      modifiers={modifiers}
      transition
    >
      <Paper
        classes={{ root: classes.root }}
        elevation={3}
      >
        <ClickAwayListener
          onClickAway={onClickAway}
        >
          <div>
            <div className={classes.header}>
              <div className={classes.title}>
                <Typography category="Default" variant="H6">
                  {t("Cloud Video Cameras Settings")}
                </Typography>
              </div> 
              <div className={classes.discription}>
                <Typography category="Default" variant="Body">
                  {t("Push notifications can be configured_")}
                </Typography>
              </div>
            </div>
            <div className={classes.container}>
              {camerasMarkup}
            </div>
            <div className={classes.btnDiv}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setPreSettings();
                  onClose();
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                // mantis - 14181, 그룹에 선택된 카메라가 있을 경우 활성화 (Hongcs, 24.07.30)
                // disabled={
                //   (_.isEqual(cameraSettings, preSettings))
                // }
                onClick={() => onSettings(cameraSettings)}
              >
                {t("Apply")}
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};
