import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Sliders,
  Typography,
} from "@thingsw/pitta-design-system";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  CAMERA,
  loadFirmwareSettings,
  updateFirmwareSettings,
} from "../../features/Camera/slice";

import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import {
  LightColors,
  Webviewer,
  TabNameInfo2,
  OPTION_CAMERA_MODELS,
  MODELS_2CH,
  MODELS_3CH,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 402,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  boderBottomBox: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    justifyContent: "space-between",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  boderNoneBox: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { padding: "0 15px 0 2px" }
      : { padding: "0 2px 0 15px" }),
  },

  boderNoneBox258: {
    width: "100%",
    maxWidth: 672,
    minHeight: 258,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 17px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0 2px 0 15px",
    },
  },
  boderNoneBoxSpace: {
    width: "100%",
    minWidth: 288,
    minHeight: 82,
    display: "flex",
    padding: "0 16px",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 48,
      maxWidth: 672,
    },
  },
  mgNone: {
    margin: 0,
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  width309: {
    width: 309,
    maxWidth: "100%",
    height: 174,
    marginTop: 24,
    position: "relative",
  },
  wid224: {
    minWidth: 250,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 280,
      ...(theme.direction === "rtl"
        ? { paddingRight: 22 }
        : { paddingLeft: 22 }),
    },
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  motionDiv: {
    position: "absolute",
    border: `1px solid ${LightColors.primary["7"]}`,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  motionRow: {
    width: "100%",
    height: "25%",
    borderBottom: `1px solid ${LightColors.primary["7"]}`,
    display: "flex",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  motionCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "25%",
    borderRight: `1px solid ${LightColors.primary["7"]}`,
    cursor: "pointer",
    "&:last-child": {
      borderRight: 0,
    },
    "&:hover": {
      backgroundColor: `${LightColors.primary["8"]}5A`,
    },
  },
  motionCheckDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${LightColors.primary["0"]}A6`,
    width: 28,
    height: 28,
    borderRadius: "50%",
  },
  sliderText: {
    ...(theme.direction === "rtl" ? { marginRight: 25 } : { marginLeft: 25 }),
  },
}));

interface MotionFirmwarePanelProps {
  openMenu?: boolean;
}

export const MotionFirmwarePanel = ({ openMenu }: MotionFirmwarePanelProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [mode, setMode] = useState<number>(0);
  const { camera, loading } = useSelector((state: RootState) => state[CAMERA]);
  const firmware = useSelector((state: RootState) => state[CAMERA].firmware);
  const [current, setCurrent] = useState<TabNameInfo2>();
  const [disabledChange, setDisabledChange] = useState(true);
  const [frontMotionDetection, setFrontMotionDetection] = useState<boolean[]>(
    _.range(0, 16).map((t) => false)
  );
  const [rearMotionDetection, setRearMotionDetection] = useState<boolean[]>(
    _.range(0, 16).map((t) => false)
  );
  const [interiorMotionDetection, setInteriorMotionDetection] = useState<
    boolean[]
  >(_.range(0, 16).map((t) => false));
  const [optionMotionDetection, setOptionMotionDetection] = useState<boolean[]>(
    _.range(0, 16).map((t) => false)
  );
  const handleScreenTypeChange = (value: number) => {
    setMode(value);
  };

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [dispatch, camera]);

  // Blackvue 7 BOX 모델 옵션 카메라 관련 기능 추가
  const optionModel = useMemo(() => {
    return _.includes(OPTION_CAMERA_MODELS, camera?.model);
  }, [camera?.model]);

  const firmToBoolean = useCallback((setting: string) => {
    const fInt = parseInt(setting);
    const fMotion: boolean[] = [];
    for (let i = 15; i >= 0; i--) {
      fMotion.push(!!((fInt >> i) & 1));
    }
    return fMotion;
  }, []);

  const booleanToFirm = useCallback((bools: boolean[]) => {
    let firm = 0;
    for (let i = 0; i < bools.length; i++) {
      if (bools[i]) {
        firm = firm | (1 << (15 - i));
      }
    }
    return firm.toString();
  }, []);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab2"
      );

      if (currentTab) {
        setCurrent(currentTab.section_info as TabNameInfo2);

        const info = currentTab.section_info as TabNameInfo2;
        const front = info.FrontMotionRegion;
        setFrontMotionDetection(firmToBoolean(front));
        const rear = info.RearMotionRegion;
        rear && setRearMotionDetection(firmToBoolean(rear));
        const interior = info.InteriorMotionRegion;
        interior && setInteriorMotionDetection(firmToBoolean(interior));
        const option = info.OptionRearMotionRegion;
        option && setOptionMotionDetection(firmToBoolean(option));
      }
    }
  }, [firmware, firmToBoolean]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab2"
      );

      const predicts = _.keys(currentTab?.section_info as TabNameInfo2).map(
        (k) => {
          const key = k as keyof TabNameInfo2;
          return (
            (currentTab?.section_info as TabNameInfo2)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [current, firmware]);

  const renderMotionCol = useCallback(
    (indx: number) => {
      let visible = frontMotionDetection[indx];
      if (mode === 1) {
        visible = rearMotionDetection[indx];
      } else if (mode === 2) {
        // Blackvue 7 BOX 모델 옵션 카메라 관련 기능 추가
        if (optionModel) {
          visible = optionMotionDetection[indx];
        } else {
          visible = interiorMotionDetection[indx];
        }
      }
      return (
        <div
          className={classes.motionCol}
          onClick={() => {
            console.log("click", mode, optionModel);
            if (mode === 0) {
              frontMotionDetection[indx] = !frontMotionDetection[indx];
              setFrontMotionDetection([...frontMotionDetection]);
              setCurrent((c) =>
                c
                  ? {
                      ...c,
                      FrontMotionRegion: booleanToFirm(frontMotionDetection),
                    }
                  : c
              );
            } else if (mode === 1) {
              rearMotionDetection[indx] = !rearMotionDetection[indx];
              setRearMotionDetection([...rearMotionDetection]);
              setCurrent((c) =>
                c
                  ? {
                      ...c,
                      RearMotionRegion: booleanToFirm(rearMotionDetection),
                    }
                  : c
              );
            } else if (mode === 2) {
              // Blackvue 7 BOX 모델 옵션 카메라 관련 기능 추가
              if (optionModel) {
                optionMotionDetection[indx] = !optionMotionDetection[indx];
                setOptionMotionDetection([...optionMotionDetection]);
                setCurrent((c) =>
                  c
                    ? {
                        ...c,
                        OptionRearMotionRegion: booleanToFirm(
                          optionMotionDetection
                        ),
                      }
                    : c
                );
              } else {
                interiorMotionDetection[indx] = !interiorMotionDetection[indx];
                setInteriorMotionDetection([...interiorMotionDetection]);
                setCurrent((c) =>
                  c
                    ? {
                        ...c,
                        InteriorMotionRegion: booleanToFirm(
                          interiorMotionDetection
                        ),
                      }
                    : c
                );
              }
            }
          }}
        >
          {visible && (
            <div className={classes.motionCheckDiv}>
              <CheckCircleOutlinedIcon
                htmlColor={LightColors.primary["7"]}
                style={{ display: "block" }}
              />
            </div>
          )}
        </div>
      );
    },
    [
      booleanToFirm,
      classes.motionCheckDiv,
      classes.motionCol,
      frontMotionDetection,
      interiorMotionDetection,
      mode,
      optionModel,
      optionMotionDetection,
      rearMotionDetection,
    ]
  );

  const handleUpdate = () => {
    if (current && camera && firmware) {
      console.log(firmware);
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab2"
        ),
      };
      updated.cloud_settings = [
        ...updated.cloud_settings,
        { section_info: current, section_name: "Tab2" },
      ];
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  const directionBtnMarkup = useMemo(() => {
    let interiorBtnTitle = "Interior camera";
    if (optionModel) {
      interiorBtnTitle = "Option camera";
    }
    return (
      <ButtonGroup value={mode} onChange={handleScreenTypeChange}>
        <Button>{t("Front camera")}</Button>
        {(_.includes(MODELS_2CH, camera?.model) ||
          _.includes(MODELS_3CH, camera?.model)) && (
          <Button>{t("Rear camera")}</Button>
        )}
        {_.includes(MODELS_3CH, camera?.model) && (
          <Button>{t(interiorBtnTitle)}</Button>
        )}
      </ButtonGroup>
    );
  }, [camera?.model, mode, optionModel, t]);

  return (
    <>
      <div>
        <div className={classes.bigBox}>
          <div className={classes.boderNoneBox}>
            <Typography category="Default" variant="BodyBold">
              {t("Parking")}
            </Typography>
          </div>
          <div className={classes.boderNoneBoxSpace}>
            <div>
              <Typography category="Default" variant="Body">
                {t("Sensitivity")}
              </Typography>
            </div>
            <div className={classes.wid224}>
              <Sliders
                max={5}
                value={parseInt(current?.MOTIONSENSOR ?? "0")}
                onChange={(e, value) => {
                  const v = value as number;
                  setCurrent((c) => {
                    return (
                      c && {
                        ...c,
                        MOTIONSENSOR: v.toString(),
                      }
                    );
                  });
                }}
              ></Sliders>
              <Typography
                category="Default"
                variant="Small"
                className={classes.sliderText}
              >
                {current && parseInt(current.MOTIONSENSOR)}
              </Typography>
            </div>
          </div>
          <div className={classes.boderNoneBox}>
            <Typography category="Default" variant="BodyBold">
              {t("Detection region")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox258}>
            {directionBtnMarkup}
            <div
              className={classes.width309}
              // mantis - 8581 , Rear /Option(Interior)는 확대된 이미지로 적용
              style={{ overflow: "hidden", paddingTop: mode === 0 ? 0 : 4 }}
            >
              <img
                // mantis - 8581 , Rear /Option(Interior)는 확대된 이미지로 적용
                style={{
                  transform: mode === 0 ? "scale(1)" : "scale(1.12, 1.12)",
                }}
                srcSet="/images/motion-detection.jpg"
                alt="motion detection"
              />
              <div className={classes.motionDiv}>
                <div className={classes.motionRow}>
                  {renderMotionCol(0)}
                  {renderMotionCol(1)}
                  {renderMotionCol(2)}
                  {renderMotionCol(3)}
                </div>
                <div className={classes.motionRow}>
                  {renderMotionCol(4)}
                  {renderMotionCol(5)}
                  {renderMotionCol(6)}
                  {renderMotionCol(7)}
                </div>
                <div className={classes.motionRow}>
                  {renderMotionCol(8)}
                  {renderMotionCol(9)}
                  {renderMotionCol(10)}
                  {renderMotionCol(11)}
                </div>
                <div className={classes.motionRow}>
                  {renderMotionCol(12)}
                  {renderMotionCol(13)}
                  {renderMotionCol(14)}
                  {renderMotionCol(15)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={disabledChange}
            onClick={handleUpdate}
            className={classes.buttonSize}
            loading={loading}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </>
  );
};
