import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import {
  IconButton,
  Tab,
  Tabs,
  Typography,
  Tooltip,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { PlaybackPanel } from "../components/cameras/PlaybackPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  loadCamera,
  loadCameras,
  loadFirmwareConfig,
  loadFirmwareSettings,
} from "../features/Camera/slice";
import { RootState } from "../features/store";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { CameraSettingPanel } from "../components/cameras/CameraSettingPanel";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ReportPanel } from "../components/cameras/ReportPanel";
import { GPSTrackingPanel } from "../components/gps-tracking/GPSTrackingPanel";

import { FileUploadModal } from "../components/FileUploadModal";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { loadVODUploadList, VOD } from "../features/VOD/slice";
import _ from "lodash";
import { USER } from "../features/User/slice";
import { openToast } from "../features/Toast/slice";
import { AutoSizer } from "react-virtualized";
import Scrollbars from "react-custom-scrollbars";
import { THEME } from "../features/Theme/slice";
import { FileDownloadModal } from "../components/modals/FileDownloadModal";
import { CameraMenu } from "../components/uxui/CameraMenu";
import { MobileCameraMenu } from "../components/uxui/MobileCameraMenu";
import {
  ICameraInfo,
  Webviewer,
  SemanticDarkColors,
  UPLOADING_QUEUE_UPDATE_INTERVAL,
  PSN650,
  AvailableFw,
  PermissionProps,
} from "@thingsw/pitta-modules";
import { LiveViewPanelWrapper } from "../components/cameras/LiveViewPanelWrapper";
import { LIVEVIEW } from "../features/LiveView/slice";
import { setCameraInfo } from "../features/GPS/slice";

export type ScreenMode =
  | "playback"
  | "live-view"
  | "settings"
  | "gps-tracking"
  | "report";

export interface CameraPlaybackScreenProps {
  mode: ScreenMode;
  psn: string;
  onRename?: (camera: ICameraInfo) => void;
  onDelete?: (camera: ICameraInfo) => void;
  onUpdate?: (camera: ICameraInfo) => void;
  onRebootCamera?: (camera: ICameraInfo) => void;
}

export const ScreenModeToIndex: { [key in ScreenMode]: number } = {
  playback: 0,
  "live-view": 1,
  "gps-tracking": 2,
  report: 3,
  settings: 4,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "calc(var(--vh, 1vh) * 100 - 56px - 165px)",
      // mantis :  8831 - KimSeongHyun
      // Scroll Top을 위해 margin을 padding으로 수정
      paddingTop: (props: ScreenDefaultProps) =>
        48 + (props.headerDivRef?.offsetHeight ?? 56),
      // marginTop: (props: ScreenDefaultProps) =>
      //   48 + (props.headerDivRef?.offsetHeight ?? 56),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        height: (props: ScreenDefaultProps) =>
          props.error
            ? "calc(var(--vh, 1vh) * 100 - 85px - 52px)"
            : "calc(var(--vh, 1vh) * 100 - 56px - 52px)",
        // margin: theme.spacing(5, 4, 2, 4),
        // mantis :  8831 - KimSeongHyun
        // Scroll Top을 위해 margin을 padding으로 수정
        paddingTop: (props: ScreenDefaultProps) =>
          props.error ? 48 + 85 : 48 + 56,
        // marginTop: (props: ScreenDefaultProps) =>
        //   props.error ? 48 + 85 : 48 + 56,
      },
    },
    tabDiv: {
      backgroundColor: (props: ScreenDefaultProps) =>
        props.color === "dark"
          ? SemanticDarkColors.primary["1"]
          : props.colors.primary["0"],
      top: (props: ScreenDefaultProps) =>
        props.headerDivRef?.offsetHeight ?? 56,
      ...(theme.direction === "rtl"
        ? { left: 0, right: 0 }
        : { left: 235, right: 0 }),
      position: "fixed",
      zIndex: 90,
      height: 53 + 29,
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: theme.spacing(0, 4),
        height: 53,
        ...(theme.direction === "rtl"
          ? { left: 0, right: 235 }
          : { left: 235, right: 0 }),
      },
    },
    //@ts-ignore
    tabScroll: {
      borderBottom: (props: ScreenDefaultProps) =>
        `1px solid ${props.colors.primary["5"]}`,
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      [theme.breakpoints.up(Webviewer.mobile)]: {
        overflowX: "unset",
        width: "100%",
      },
    },
    tab: {
      display: "flex",
      // justifyContent: "space-between",
      minWidth: 570,
      width: "100%",
      height: 52,
      alignItems: "flex-end",
    },
    tabDivClosed: {
      left: 73,
    },
    tabDivMobile: {
      left: 0,
      flexDirection: "column",
      alignItems: "unset",
    },
    tabItem: {
      display: "flex",
      alignItems: "flex-end",
      paddingBottom: 6,
      whiteSpace: "nowrap",
      color: (props: ScreenDefaultProps) => props.colors.primary["1"],
    },
    mobileCamNameDiv: {
      display: "flex",
      paddingTop: theme.spacing(1),
      justifyContent: "center",
    },
    downUploadModal: {
      position: "fixed",
      right: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      zIndex: 99,
      [theme.breakpoints.up(Webviewer.mobile)]:
        theme.direction === "rtl"
          ? {
              width: 437,
              left: 32,
            }
          : {
              width: 437,
              right: 32,
            },
    },
  })
);

export const CameraPlaybackScreen = (
  props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps
) => {
  const {
    mode,
    psn,
    openMenu,
    onRename,
    onDelete,
    onUpdate,
    onRebootCamera,
    ...permissions
  } = props;

  const {
    gpsTrackingPerm,
    reportPerm,
    settingsCamPerm,
    renameCamPerm,
    fotaCamPerm,
    deleteCamPerm,
    liveviewPerm,
    rebootCamPerm,
    moreTabPerm,
  } = permissions;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const mobileMedia = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { fullscreen } = useSelector((state: RootState) => state[THEME]);
  const [mobile, setMobile] = useState(mobileMedia);

  useEffect(() => {
    if (!fullscreen) {
      setMobile(mobileMedia);
    }
  }, [fullscreen, mobileMedia]);

  const match = useRouteMatch<{ psn: string }>("/cameras/:psn");

  const cameraState = useSelector((state: RootState) => state[CAMERA]);
  const { cameraList, firmware } = cameraState;

  const userState = useSelector((state: RootState) => state[USER]);
  const { userProfile } = userState;

  const { progressDisplayOrder } = useSelector(
    (state: RootState) => state[VOD]
  );
  const { multiliveview } = useSelector((state: RootState) => state[LIVEVIEW]);

  const [value, setValue] = useState(ScreenModeToIndex[mode]);
  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const [openLiveviewTooltip, setOpenLiveviewTooltip] = React.useState(false);
  const [openGpsTooltip, setOpenGpsTooltip] = React.useState(false);
  const [openReportTooltip, setOpenReportTooltip] = React.useState(false);
  const [openSettingsTooltip, setOpenSettingsTooltip] = React.useState(false);
  const [openMoreTabTooltip, setOpenMoreTabTooltip] = React.useState(false);
  const [backScroll /*, setBackScroll*/] = React.useState(false);

  useEffect(() => {
    const body = document.getElementById("body-container");
    body?.setAttribute("style", "");
  });

  // useEffect(() => {
  //   if (!camera && cameraList) {
  //     dispatch(loadCamera(psn));
  //   }
  // }, [camera, cameraList, dispatch, psn]);

  useEffect(() => {
    const cam = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === psn)
      .value();
    setCurrentCam((c) => {
      if (c?.psn !== cam?.psn) {
        return cam;
      } else if (c?.active !== cam?.active) {
        return cam;
        // mantis - 11504, cam rename하면 모바일 상단 카메라명 변경되도록 수정 (Leehj)
      } else if (c?.dev_name !== cam?.dev_name) {
        return cam;
      }
      return c;
    });
    dispatch(setCameraInfo(cam));
  }, [psn, cameraList?.deviceListInfo, dispatch]);

  useEffect(() => {
    if (currentCam) {
      dispatch(loadFirmwareConfig(currentCam));
    }
  }, [currentCam, dispatch]);

  useEffect(() => {
    if (currentCam && firmware?.psn !== currentCam.psn) {
      const load = () => {
        dispatch(loadFirmwareSettings(currentCam.psn));
      };

      const timer = setTimeout(load, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentCam, dispatch, firmware?.psn]);

  useEffect(() => {
    function tick() {
      if (currentCam) {
        dispatch(loadVODUploadList(currentCam));
      }
    }
    if (currentCam && currentCam.active === "on") {
      let timer: NodeJS.Timeout | null = null;
      let id = setInterval(tick, UPLOADING_QUEUE_UPDATE_INTERVAL);
      if (location.pathname.indexOf("live-view") > -1) {
        timer = setTimeout(tick, 500);
      } else {
        tick();
      }
      return () => {
        clearInterval(id);
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [dispatch, currentCam, location]);

  useEffect(() => {
    if (currentCam?.psn !== psn) {
      dispatch(loadCamera(psn));
    }
  }, [currentCam?.psn, dispatch, psn]);

  useEffect(() => {
    if (!cameraList) {
      dispatch(loadCameras());
    }
  }, [cameraList, dispatch]);

  useEffect(() => {
    setValue(ScreenModeToIndex[mode]);
  }, [mode]);

  // mantis : 8831 - KimSeongHyun
  // 탭 이동시 스크롤 최상위로 이동
  const scrollToTop = useCallback(() => {
    document.getElementById("scrollTop")?.scrollIntoView(true);
  }, []);

  const handleRename = useMemo(() => {
    return renameCamPerm
      ? () => {
          setOpenCamMenu(false);
          currentCam && onRename?.(currentCam);
          // setCurrentCam(camera?.dev_name);
        }
      : null;
  }, [currentCam, onRename, renameCamPerm]);

  const handleRebbotCam = useMemo(() => {
    return rebootCamPerm
      ? () => {
          currentCam && onRebootCamera?.(currentCam);
          setOpenCamMenu(false);
        }
      : null;
  }, [currentCam, onRebootCamera, rebootCamPerm]);

  const handleFOTA: (() => void) | null = useMemo(() => {
    return fotaCamPerm && !_.includes(PSN650, currentCam?.psn.substr(0, 4))
      ? (fw?: AvailableFw) => {
          if (fw) {
            if (currentCam && currentCam.active === "on") {
              if (fw.dmc200) {
                history.push(`/cameras/${currentCam.psn}/fota-dmc200`);
              } else if (fw.blackvue) {
                history.push(`/cameras/${currentCam.psn}/fota`);
              }
            }
          } else {
            dispatch(openToast({ message: "Firmware is up_" }));
            setOpenCamMenu(false);
          }
        }
      : null;
  }, [currentCam, dispatch, fotaCamPerm, history]);

  const handleDelete = useMemo(() => {
    return deleteCamPerm
      ? () => {
          setOpenCamMenu(false);
          currentCam && onDelete?.(currentCam);
        }
      : null;
  }, [currentCam, deleteCamPerm, onDelete]);

  const handleLiveTooltipHover = () => {
    if (!liveviewPerm) {
      setOpenLiveviewTooltip(true);
    }
  };

  const handleGpsTooltipHover = () => {
    if (!gpsTrackingPerm && !mobile) {
      setOpenGpsTooltip(true);
    }
  };
  const handleReportTooltipHover = () => {
    if (!reportPerm) {
      setOpenReportTooltip(true);
    }
  };
  const handleSettingsTooltipHover = () => {
    if (!settingsCamPerm) {
      setOpenSettingsTooltip(true);
    }
  };
  const handleMoreTabTooltipHover = () => {
    if (!moreTabPerm) {
      setOpenMoreTabTooltip(true);
    } else {
      return openMoreTabTooltip;
    }
  };

  const bodyMarkup = useMemo(() => {
    return (
      <>
        {value === 0 && <PlaybackPanel openMenu={openMenu} {...permissions} />}
        {value === 1 && currentCam && (
          <LiveViewPanelWrapper camera={currentCam} {...permissions} />
        )}
        {value === 2 && gpsTrackingPerm && (
          <GPSTrackingPanel
            plan={props.plan}
            openMenu={props.openMenu}
            {...permissions}
          />
        )}
        {value === 3 && reportPerm && (
          <ReportPanel openMenu={openMenu} {...permissions} />
        )}
        {value === 4 && settingsCamPerm && (
          <CameraSettingPanel {...permissions} />
        )}
      </>
    );
  }, [
    currentCam,
    gpsTrackingPerm,
    openMenu,
    permissions,
    props.openMenu,
    props.plan,
    reportPerm,
    settingsCamPerm,
    value,
  ]);

  return (
    // mantis :  8831 - KimSeongHyun
    // 탭 이동시 스크롤 최상위로 이동
    <div className={classes.root} dir={theme.direction} id="scrollTop">
      <div
        className={clsx(classes.tabDiv, {
          [classes.tabDivClosed]: !openMenu,
          [classes.tabDivMobile]: mobile,
        })}
      >
        {mobile && (
          <div className={classes.mobileCamNameDiv}>
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={props.colors.primary["2"]}
            >
              {currentCam?.dev_name}
            </Typography>
          </div>
        )}

        <div className={classes.tabScroll}>
          <div className={clsx(classes.tab)}>
            <Tabs value={value}>
              <Tab
                className={classes.tabItem}
                label={t("Playback")}
                onClick={() => {
                  history.push(`/cameras/${match?.params.psn}/playback`);
                  // mantis :  8831 - KimSeongHyun
                  // 탭 이동시 스크롤 최상위로 이동
                  scrollToTop();
                }}
              />

              <Tab
                className={classes.tabItem}
                label={
                  <Tooltip
                    open={openLiveviewTooltip}
                    title={t("No permission") ?? "No permission"}
                    placement="bottom"
                    PopperProps={{
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: "0, -12px",
                        },
                        flip: {
                          enabled: false,
                        },
                      },
                    }}
                  >
                    <div
                      onMouseOver={handleLiveTooltipHover}
                      onMouseOut={() => {
                        setOpenLiveviewTooltip(false);
                      }}
                    >
                      {t("Live view")}
                    </div>
                  </Tooltip>
                }
                disabled={
                  (currentCam?.active === "off" && !multiliveview) ||
                  !liveviewPerm
                }
                onClick={() => {
                  history.push(`/cameras/${match?.params.psn}/live-view`, {
                    clicked: true,
                  });
                  // mantis :  8831 - KimSeongHyun
                  // 탭 이동시 스크롤 최상위로 이동
                  scrollToTop();
                }}
              />

              <Tooltip
                open={openGpsTooltip}
                textCenter={
                  !(
                    userProfile?.userType === "User" ||
                    userProfile?.userType === "SubMaster"
                  )
                }
                title={
                  userProfile?.userType === "User" ||
                  userProfile?.userType === "SubMaster" ? (
                    <Typography category="Default" variant="Caption">
                      {t("No permission")}
                    </Typography>
                  ) : (
                    // mantis - 12369, 문구변경 (Leehj)
                    <Typography
                      category="Default"
                      variant="Caption"
                      dangerouslySetInnerHTML={{
                        __html: t("Smart and Fleet_"),
                      }}
                    />
                  )
                }
                placement="bottom"
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
              >
                <div
                  onMouseOver={handleGpsTooltipHover}
                  onMouseOut={() => {
                    setOpenGpsTooltip(false);
                  }}
                >
                  <Tab
                    className={classes.tabItem}
                    disabled={!gpsTrackingPerm}
                    label={t("GPS tracking")}
                    onClick={() => {
                      history.push(
                        `/cameras/${match?.params.psn}/gps-tracking`
                      );
                      // mantis :  8831 - KimSeongHyun
                      // 탭 이동시 스크롤 최상위로 이동
                      scrollToTop();
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip
                open={openReportTooltip}
                title={t("No permission") ?? "No permission"}
                placement="bottom"
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
              >
                <div
                  onMouseOver={handleReportTooltipHover}
                  onMouseOut={() => {
                    setOpenReportTooltip(false);
                  }}
                >
                  <Tab
                    className={classes.tabItem}
                    disabled={
                      !reportPerm ||
                      _.includes(PSN650, currentCam?.psn.substr(0, 4))
                    }
                    label={t("Report")}
                    onClick={() => {
                      history.push(`/cameras/${match?.params.psn}/report`);
                      // mantis :  8831 - KimSeongHyun
                      // 탭 이동시 스크롤 최상위로 이동
                      scrollToTop();
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip
                open={openSettingsTooltip}
                title={t("No permission") ?? "No permission"}
                placement="bottom"
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
              >
                <div
                  onMouseOver={handleSettingsTooltipHover}
                  onMouseOut={() => {
                    setOpenSettingsTooltip(false);
                  }}
                >
                  <Tab
                    className={classes.tabItem}
                    disabled={!settingsCamPerm}
                    label={t("Settings")}
                    onClick={() => {
                      history.push(`/cameras/${match?.params.psn}/settings`);
                      scrollToTop();
                    }}
                  />
                </div>
              </Tooltip>
            </Tabs>

            <Tooltip
              disableTouchListener={mobile}
              placement={moreTabPerm ? "top" : "bottom"}
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: moreTabPerm ? "0, -20px" : "0, -12px",
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }}
              title={
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={props.colors.primary["0"]}
                >
                  {moreTabPerm ? t("More") : t("No permission")}
                </Typography>
              }
            >
              <div
                onMouseOver={handleMoreTabTooltipHover}
                onMouseOut={() => {
                  setOpenMoreTabTooltip(false);
                }}
              >
                <IconButton
                  ref={anchorRef}
                  onClick={() => setOpenCamMenu(true)}
                  disabled={!moreTabPerm}
                  style={{ padding: 6 }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

      <div
        style={
          // 모바일에서 이벤트공유 모달 open시 background가 스크롤 되는현상 방지(HJ)
          mobile && backScroll
            ? { height: "100%", paddingTop: 30, overflow: "hidden" }
            : mobile
            ? { height: "100%", paddingTop: 30, overflow: "auto" }
            : { height: "100%" }
        }
      >
        {mobile ? (
          bodyMarkup
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <Scrollbars style={{ height, width }} autoHide>
                {bodyMarkup}
              </Scrollbars>
            )}
          </AutoSizer>
        )}
      </div>
      {!mobile && (
        // mantis - 11504, uxui CameraMenu로 컴포넌트 변경 (Leehj)
        <CameraMenu
          {...props}
          open={openCamMenu}
          camera={currentCam}
          anchorRef={anchorRef}
          placement="bottom-start"
          onClickAway={() => setOpenCamMenu(false)}
          onEditProfile={handleRename}
          onUpdate={handleFOTA}
          onDelete={handleDelete}
          onRebootCamera={handleRebbotCam}
        />
      )}
      {mobile && (
        // mantis - 11504, uxui CameraMenu로 컴포넌트 변경 (Leehj)
        <MobileCameraMenu
          {...props}
          camera={currentCam}
          open={openCamMenu}
          onClose={() => setOpenCamMenu(false)}
          onEditProfile={handleRename}
          onUpdate={handleFOTA}
          onDelete={handleDelete}
          onRebootCamera={handleRebbotCam}
        />
      )}
      <div
        className={classes.downUploadModal}
        style={{ flexDirection: progressDisplayOrder }}
      >
        <FileUploadModal />
        {/* mantis - 12598, 다운로드 취소하는 경우 진행중인 업로드 취소하기위해 psn 필요 (Leehj) */}
        <FileDownloadModal camera={currentCam} />
      </div>
    </div>
  );
};
