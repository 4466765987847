import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import {
  AppStoreBadge,
  GooglePlayBadge,
  IconButton,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { Webviewer, APPSTORE, GOOGLEPLAY } from "@thingsw/pitta-modules";
import React from "react";

interface AddCameraGuideModalProps {
  open: boolean;
  onClose: () => void;
  mode: "cloud" | "wifi";
  fullsize?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  qr: {
    width: 120,
    height: 120,
  },
  // mantis - 10500, 모바일에서 가로스크롤 생기는 이슈 수정 및 피그마 디자인 적용 (Leehj)
  wifiTitle: {
    padding: "24px 24px 0 24px",
    width: 248,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 300,
      padding: "25px 44px 0 24px",
    },
    [theme.breakpoints.down(361)]: {
      width: "auto",
      padding: "24px 70px 0 24px",
    },
  },
  bg: {
    margin: 16,
  },
  storeDiv: {
    display: "flex",
    width: "100%",
    // justifyContent: "space-evenly",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    [theme.breakpoints.down(361)]: {
      flexDirection: "column",
    },
  },
  store: {
    display: "flex",
    flexDirection: "column",
    minHeight: 152,
    alignItems: "start",
    justifyContent: "center",
    gap: 10,
  },
  appStore: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(5) }
      : { marginRight: theme.spacing(5) }),
    [theme.breakpoints.down(361)]: {
      margin: 0,
      marginBottom: 32,
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "12px 24px 32px",
    },
  },
}));

export const AddCameraGuideModal = ({
  open,
  onClose,
  mode,
  fullsize,
}: AddCameraGuideModalProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const xsPhoneSize = useMediaQuery(theme.breakpoints.down(361));

  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onClose}
      close={!(mode === "wifi" && xsPhoneSize)}
      fullSize={fullsize}
      titleClassName={clsx({
        [classes.wifiTitle]: mode === "wifi" && !xsPhoneSize,
      })}
      contentClassName={clsx(classes.root)}
      className={classes.bg}
      heading={
        mode === "wifi" && xsPhoneSize
          ? undefined
          : mode === "wifi"
          ? t("Download the BlackVue app to see a Wi-Fi connected camera")
          : t("Download the BlackVue app to add a camera")
      }
      content={
        <>
          {/* mantis - 11041, 아이폰 se는 스크롤링 가능하도록 수정/ 피그마디자인 적용 (Leehj) */}
          {mode === "wifi" && xsPhoneSize && (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: 6,
              }}
            >
              <Typography
                category="Default"
                variant="H6"
                style={{ whiteSpace: "pre-line", marginBottom: 16 }}
              >
                {t("Download the BlackVue app to see a Wi-Fi connected camera")}
              </Typography>
              <div style={{ height: 10, width: 20 }}></div>
              <IconButton onClick={onClose} style={{ marginTop: -1 }}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <Typography category="Default" variant="Body">
            {t(
              mode === "wifi"
                ? "BlackVue Cloud web viewer only supports_"
                : "To see your camera in the BlackVue cloud viewer_"
            )}
          </Typography>
          {/* mantis - 10500, 모바일에서 가로스크롤 생기는 이슈 수정 및 피그마 디자인 적용 (Leehj) */}
          <div className={classes.storeDiv}>
            <div className={clsx(classes.store, classes.appStore)}>
              <img
                className={classes.qr}
                src="/images/QR_Code.png"
                alt="qr code for BlackVue App"
              />
            </div>
            <div className={classes.store}>
              <a href={APPSTORE} target="_blank" rel="noreferrer">
                <AppStoreBadge
                  style={{
                    minWidth: 120,
                    height: 40,
                  }}
                />
              </a>
              <a href={GOOGLEPLAY} target="_blank" rel="noreferrer">
                <GooglePlayBadge
                  style={{
                    minWidth: 135,
                    height: 40,
                  }}
                />
              </a>
            </div>
          </div>
        </>
      }
    />
  );
};
