import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Container,
  Facebook,
  GooglePlay,
  LogoFull,
  LogoOnly,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppleIcon from "@material-ui/icons/Apple";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import { LangButton } from "./LangButton";
import { LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  body7: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
      paddingBottom: theme.spacing(2),
      flexWrap: "wrap",
    },
  },
  logoDiv: {
    width: "100%",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: "20%",
      marginBottom: theme.spacing(8),
    },
  },
  anchor: {
    color: "inherit",
    "& a": {
      color: "inherit",
    },
    "&:hover": {
      cursor: "pointer",
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
    "&:active": {
      "& svg": {
        color: LightColors.primary["8"],
        fill: LightColors.primary["8"],
      },
      color: LightColors.primary["8"],
    },
  },
  appDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  appIcon: {
    fontSize: 15,
    color: LightColors.primary["1"],
    marginRight: theme.spacing(1),
  },
  snsDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: "20%",
      justifyContent: "flex-end",
      marginBottom: "auto",
    },
  },
  snsIcon: {
    fontSize: 24,
    marginRight: theme.spacing(3),
    "&:last-child": {
      marginRight: 0,
    },
  },
  middleDiv: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
  div1: {
    width: "calc(50% - 8px)",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  langDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  langBtn: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    "&:last-child": {
      marginBottom: 0,
    },
    "&:active": {
      color: LightColors.primary["8"],
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginRight: theme.spacing(3),
    },
    "&:hover, &:active": {
      color: LightColors.primary["8"],
    },
  },
  active: {
    color: LightColors.primary["8"],
  },
  licenseDivAlpha: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 3),
    },
  },
  licenseDiv: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(3) }
        : { marginRight: theme.spacing(3) }),
    },
  },
  link: {
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["8"],
    },
  },
}));
const Footer = () => {
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const [langOpenMenu, setLangOpenMenu] = useState(false);

  return (
    <div style={{ backgroundColor: `${LightColors.primary["1"]}07` }}>
      <Container className={classes.body7}>
        <div className={classes.logoDiv}>
          <Hidden mdUp>
            <LogoOnly width={36} height={36} />
          </Hidden>
          <Hidden smDown>
            <LogoFull width={166} height={29} />
          </Hidden>
        </div>
        <div className={classes.middleDiv}>
          <div className={classes.div1}>
            <div style={{ marginBottom: theme.spacing(2) }}>
              <Typography
                category="Default"
                variant={matches ? "H6" : "BodyBold"}
              >
                {t("BlackVue apps")}
              </Typography>
            </div>
            <div className={clsx(classes.appDiv, classes.anchor)}>
              <AppleIcon className={classes.appIcon} />
              <Typography
                category="Default"
                variant={matches ? "Body" : "Small"}
              >
                <a
                  href="https://apps.apple.com/kr/app/blackvue/id1049209637"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("iOS")}
                </a>
              </Typography>
            </div>
            <div className={clsx(classes.appDiv, classes.anchor)}>
              <GooglePlay className={classes.appIcon} />
              <Typography
                category="Default"
                variant={matches ? "Body" : "Small"}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=comb.blackvuec"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Android")}
                </a>
              </Typography>
            </div>
            <Hidden smDown>
              <div className={clsx(classes.appDiv, classes.anchor)}>
                <DesktopMacIcon className={classes.appIcon} />
                <Typography
                  category="Default"
                  variant={matches ? "Body" : "Small"}
                >
                  <a
                    href="https://blackvue.com/downloads/#app-viewer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Mac")}
                  </a>
                </Typography>
              </div>
            </Hidden>
            <Hidden smDown>
              <div className={clsx(classes.appDiv, classes.anchor)}>
                <DesktopWindowsIcon className={classes.appIcon} />
                <Typography
                  category="Default"
                  variant={matches ? "Body" : "Small"}
                >
                  <a
                    href="https://blackvue.com/downloads/#app-viewer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Windows")}
                  </a>
                </Typography>
              </div>
            </Hidden>
          </div>
          <div className={classes.div1}>
            <div style={{ marginBottom: theme.spacing(2) }}>
              <Typography
                category="Default"
                variant={matches ? "H6" : "BodyBold"}
              >
                {t("Developers")}
              </Typography>
            </div>

            <div className={clsx(classes.appDiv, classes.anchor)}>
              <Typography
                category="Default"
                variant={matches ? "Body" : "Small"}
              >
                <a
                  href="https://www.blackvue.com/b2b-sdk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Fleet SDK")}
                </a>
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.logoDiv}>
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Typography
              category="Default"
              variant={matches ? "H6" : "BodyBold"}
            >
              {/* Contact us */}
              {t(" Contact us")}
            </Typography>
          </div>
          <div
            style={{ marginBottom: theme.spacing(0.5) }}
            className={classes.anchor}
          >
            <Typography category="Default" variant={matches ? "Body" : "Small"}>
              <a href="tel:+18446944496">+1 844 694 4469</a>
            </Typography>
          </div>
          <div style={{ marginBottom: theme.spacing(1) }}>
            <Typography
              category="Default"
              variant={"Caption"}
              htmlColor={LightColors.primary["2"]}
            >
              {/* USA/Canada Toll Free */}
              {t("USA/Canada Toll Free")}
              <br />
              {t("Monday–Saturday_")}
            </Typography>
          </div>
          <div
            style={{ marginBottom: theme.spacing(1) }}
            className={classes.anchor}
          >
            <Typography category="Default" variant={matches ? "Body" : "Small"}>
              <a href="mailto:cs@pittasoft.com">cs@pittasoft.com</a>
            </Typography>
          </div>
          <div className={classes.anchor}>
            <Typography category="Default" variant={matches ? "Body" : "Small"}>
              <a href="https://blackvue.com/" target="_blank" rel="noreferrer">
                blackvue.com
              </a>
            </Typography>
          </div>
        </div>
        <div className={classes.snsDiv}>
          <a
            href="https://www.facebook.com/BlackVueOfficial"
            target="_blank"
            rel="noreferrer"
            className={clsx(classes.snsIcon, classes.anchor)}
          >
            <Facebook />
          </a>
          <a
            href="https://twitter.com/BlackVue"
            target="_blank"
            rel="noreferrer"
            className={clsx(classes.snsIcon, classes.anchor)}
          >
            <TwitterIcon />
          </a>

          <a
            href="https://www.instagram.com/blackvueofficial/"
            target="_blank"
            rel="noreferrer"
            className={clsx(classes.snsIcon, classes.anchor)}
          >
            <InstagramIcon />
          </a>

          <a
            href="https://www.youtube.com/BlackVueOfficial"
            target="_blank"
            rel="noreferrer"
            className={clsx(classes.snsIcon, classes.anchor)}
          >
            <YouTubeIcon />
          </a>
        </div>
        <div
          className={classes.langDiv}
          ref={anchorRef}
          onClick={() => setLangOpenMenu((o) => !o)}
        >
          {process.env.REACT_APP_ENV === "qa" && (
            <LangButton
              mode="main"
              anchorRef={anchorRef}
              placement="top-start"
              openMenu={langOpenMenu}
              onClose={() => setLangOpenMenu(false)}
            />
          )}
          <div
            className={clsx({
              [classes.licenseDiv]: process.env.REACT_APP_ENV !== "qa",
              [classes.licenseDivAlpha]: process.env.REACT_APP_ENV === "qa",
            })}
          >
            <Typography
              category="Default"
              variant={matches ? "Body" : "Small"}
              htmlColor={LightColors.primary["3"]}
            >
              ⓒ 2020 Pittasoft Co. Ltd
            </Typography>
          </div>
          <div>
            <Typography
              category="Default"
              variant={matches ? "Body" : "Small"}
              htmlColor={LightColors.primary["3"]}
            >
              <a
                className={classes.link}
                href={
                  // 한국어 / 일본어 일시 해당 국가의 Terms & Policy로 이동 (KimSH)
                  i18n.language === "ko"
                    ? "https://blackvue.com/ko/terms-conditions-korean/"
                    : i18n.language === "ja"
                    ? "https://blackvue.com/ja/terms-conditions-japan/"
                    : "https://blackvue.com/warranty-terms-conditions/"
                }
                target="_blank"
                rel="noreferrer"
              >
                {t("Terms & Policies")}
              </a>
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
