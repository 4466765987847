import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { SubChangeModal } from "./SubChangeModal";
import { SubCancelModal } from "./SubCancelModal";
import { SubRestoreModal } from "./SubRestoreModal";
import { HistoryTable } from "@thingsw/pitta-design-system/dist/components/HistoryTable";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { SubMgrModal } from "./SubMgrModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  loadTransactions,
  loadWebSubscription,
  PAYMENT,
  cancelSubscription,
  loadSubscription,
} from "../../features/Payment/slice";
import { getPlanFromServiceID } from "../../utils/Service";
import { CAMERA, loadCameras } from "../../features/Camera/slice";
import { USER } from "../../features/User/slice";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import HelpIcon from "@material-ui/icons/Help";
import { Webviewer, LightColors } from "@thingsw/pitta-modules";
import CheckIcon from "@material-ui/icons/Check"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Accordion";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  pdTB24: {
    padding: "16px 0",
    overflowX: "auto",
    // mantis : 8439
    // page가 table width에 맞춰 스크롤이 생성되어 width 값 100% 수정
    width: "100%",
    height: "100%",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 672,
      overflowX: "hidden",
    },
  },
  pdT16: {
    paddingTop: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  pdT32: {
    paddingTop: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  pdLR16: {
    padding: "0 16px",    
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
    },
    "& .MuiTableCell-root": {
      color: (props: any) => props.colors.primary["1"],
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  cardInfo: {
    paddingTop: 8,
    display: "flex",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
    },
  },
  title: {
    padding: "32px 0 8px 0",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
  },
  Link: {
    color: LightColors.primary["7"],
    cursor: "pointer",
    "&:hover": { color: LightColors.primary["8"] },
  },
  cardSvg: {
    paddingTop: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: { paddingTop: 0 },
  },
  cardText: {
    ...(theme.direction === "rtl"
      ? { paddingRight: theme.spacing(1) }
      : { paddingLeft: theme.spacing(1) }),
  },
  hidden: {
    display: "none",
  },
  noTransTo: {
    maxWidth: 672,
    padding: "0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
    },
  },
  padding4: {
    ...(theme.direction === "rtl"
      ? { paddingLeft: theme.spacing(4) }
      : { paddingRight: theme.spacing(4) }),
  },
  onePlanWrap: {
    maxWidth: 672,
  },
  onePlanName: {
    paddingBottom: 24,
  },
  onePlanAccMenu: {
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 12,
    overflow: "hidden",
    padding: "12px 4px",
    "& .summaryWrap": {
      display: "flex",
      justifyContent: "space-between",
      minHeight: 40,
      "& .summary": {
        flex: 1,
        display: "flex",
        alignItems: "center"
      },
    },
    "& ul": {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      margin: 0,
      "& li": {
        minHeight: 32,
        display: "flex",
        alignItems: "center",
        flexBasis: "50%",
        color: `${LightColors.primary["3"]}`,
      },
    },
  },
  restoreDiv: {
    color: (props: any) => props.color === "dark" ? `${LightColors.primary["3"]}` : "#68686E",
    marginTop: "8px",
    "& svg": {
      fill: (props: any) => props.color === "dark" ? `${LightColors.primary["3"]}` : "#7E7E83",
    },
  },
}));

export interface SubscriptionPanelProps {
  // cardInfo?: boolean;
  // inApp?: boolean;
  // freePlan?: boolean;
  // mode?: "cardInfo"|"inApp"|"freePlan"
}

export const SubscriptionPanel = () => {
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const dispatch = useDispatch();
  const history = useHistory();

  const { color, colors } = useSelector((state: RootState) => state[THEME]);
  console.log("color", color);
  const classes = useStyles({ color, colors });

  const {
    subscriptionInfo,
    webSubscriptionInfo,
    transactionHistory,
    loading,
    type,
  } = useSelector((state: RootState) => state[PAYMENT]);
  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );
  
  const camLimitCount = useSelector(
    (state: RootState) => state[CAMERA].cameraList?.deviceLimitCount
  );

  const [openOnePlanAcc01, setOpenOnePlanAcc01] = useState(false);
  const [openOnePlanAcc02, setOpenOnePlanAcc02] = useState(false);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);

  const loadSubscriptionInfo = useCallback(() => {
    dispatch(loadSubscription());
    dispatch(loadWebSubscription());
    dispatch(loadTransactions());
    //  mantis-12206, smart,lite계정의 경우 deviceLimitCount를 출력하기위해 loadCameras추가 (Leehj)
    dispatch(loadCameras());
  }, [dispatch]);

  const [userType, setUserType] = useState<"Owner" | "Admin" | undefined>(
    undefined
  );
  const [, /*rols*/ setRoles] = useState<
    "Master" | "SubMaster" | "User" | "Etc"
  >("Etc");

  useEffect(() => {
    loadSubscriptionInfo();
  }, [loadSubscriptionInfo]);

  const getDateTime = useCallback((endOfMonthDT: moment.Moment) => {
    return moment(endOfMonthDT).format("MMM D, YYYY, h:m A");
  }, []);

  const isLitePlan = useMemo(() => {
    return (
      subscriptionInfo &&
      getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Lite plan"
    );
  }, [subscriptionInfo]);

  const inApp =
    subscriptionInfo &&
    (subscriptionInfo?.servicePlanID === 1004 ||
      subscriptionInfo?.servicePlanID === 1005 ||
      (subscriptionInfo?.servicePlanID >= 4001 &&
        subscriptionInfo?.servicePlanID <= 4029) ||
      // mantis - 12314, smart, lite 계정 service id추가 (Leehj)
      (subscriptionInfo.servicePlanID >= 10001 &&
        subscriptionInfo.servicePlanID <= 10015) ||
      (subscriptionInfo?.servicePlanID >= 11001 &&
        subscriptionInfo?.servicePlanID <= 11015));

  useEffect(() => {
    if (userProfile?.userType === "Master") {
      setRoles("Master");
      setUserType("Owner");
    } else if (userProfile?.userType === "SubMaster") {
      setRoles("Master");
      setUserType("Admin");
    } else if (userProfile?.userType === "User") {
      setRoles("User");
    } else {
      setRoles("Etc");
    }
  }, [
    t,
    userProfile?.profilePath,
    userProfile?.pwLastChangedDT,
    userProfile?.userType,
    userType,
  ]);

  return (
    <div className={classes.pdTB24}>
      <div className={classes.pdLR16}>
        {/* Payment method */}
        <div style={{ paddingBottom: 8 }}>
          <Typography category="Default" variant="H6">
            {t("Payment method")}
          </Typography>
        </div>
        <div>
          {/* Payment method - Fleet plan */}
          {subscriptionInfo &&
            getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
              "Fleet plan" &&
            !inApp && (
              <>
                <div className={classes.cardInfo}>
                  <div className={classes.cardSvg}>
                    <ReactSVG src="/images/payment-cards.svg" />
                  </div>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.cardText}
                  >
                    {webSubscriptionInfo?.customer.brand} ***
                    {webSubscriptionInfo?.customer.last4} (valid thru:{" "}
                    {webSubscriptionInfo?.customer.expMonth
                      .toString()
                      .padStart(2, "0")}
                    /
                    {webSubscriptionInfo?.customer.expYear
                      .toString()
                      .padStart(2, "0")}
                    )
                  </Typography>
                </div>
                {webSubscriptionInfo &&
                  !webSubscriptionInfo.upcomming.canceled && (
                    <div style={{ paddingTop: 8 }}>
                      {mobile && (
                        <Typography
                          category="Default"
                          variant="Body"
                          className={classes.Link}
                          onClick={() => setOpenChangeModal(true)}
                        >
                          {t("Change payment method")}
                        </Typography>
                      )}
                      {!mobile && (
                        <Typography
                          category="Default"
                          variant="Small"
                          className={classes.Link}
                          onClick={() => setOpenChangeModal(true)}
                        >
                          {t("Change payment method")}
                        </Typography>
                      )}
                    </div>
                  )}
              </>
            )}

          {/* Payment method : oneplan */}
          {subscriptionInfo &&
            subscriptionInfo.paymentMethod === "oneplan" && (
              <div>
                <Typography category="Default" variant="Body">
                  {t("Purchased by Payment Service")}
                </Typography>
              </div>
            )}

          {/* Payment method - inApp : ios */}
          {subscriptionInfo &&
            inApp &&
            _.includes(
              ["ios", "ios_sandbox"],
              subscriptionInfo.paymentMethod
            ) && (
              <div>
                <Typography category="Default" variant="Body">
                  {t("iOS in-app purchase")}
                </Typography>
              </div>
            )}
          {/* Payment method -inApp : android */}
          {subscriptionInfo &&
            inApp &&
            subscriptionInfo.paymentMethod === "android" && (
              <div>
                <Typography category="Default" variant="Body">
                  {t("Android in-app_")}
                </Typography>
              </div>
            )}
          {/* Payment method - Free plan */}
          {subscriptionInfo &&
            getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
              "Free plan" && (
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("You are subscribed_")}
                </Typography>
              </div>
            )}
        </div>
        {/* Subscription */}
        <div className={classes.title}>
          <Typography
            category="Default"
            variant="H6"
            style={{ padding: "32px 0 8px 0" }}
          >
            {t("Subscription")}
          </Typography>
        </div>
        {/* Subscription - Fleet plan / Basic plan */}
        {((subscriptionInfo &&
          getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
            "Fleet plan") ||
          (subscriptionInfo &&
            getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
              "Basic plan")) && (
          <div className={classes.flex}>
            <div className={clsx(classes.column, classes.padding4)}>
              {subscriptionInfo &&
                getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
                  "Fleet plan" &&
                !inApp && (
                  <Typography
                    category="Default"
                    variant="Body"
                    style={{ paddingBottom: 8 }}
                  >
                    {`${
                      subscriptionInfo &&
                      getPlanFromServiceID(subscriptionInfo.servicePlanID)
                    } · ${userType ? t(userType) : userType}`}
                  </Typography>
                )}
              {((subscriptionInfo &&
                getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
                  "Basic plan") ||
                inApp ||
                (inApp &&
                  subscriptionInfo &&
                  getPlanFromServiceID(subscriptionInfo.servicePlanID) !==
                    "Fleet plan")) && (
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {subscriptionInfo &&
                    getPlanFromServiceID(subscriptionInfo.servicePlanID)}
                </Typography>
              )}
              {/* cancel */}
              {!inApp && (
                <>
                  {subscriptionInfo?.cancelled === 0 && (
                    <>
                      <Typography
                        category="Default"
                        variant="Small"
                        style={{ paddingBottom: 8 }}
                      >
                        {t("Next billing_", {
                          a: moment(
                            subscriptionInfo?.endOfMonthDT,
                            "YYYY-MM-DD hh:mm:ss"
                          ).format("MMM D, YYYY"),
                          b: `${
                            (webSubscriptionInfo?.upcomming.total ?? 0) / 100
                          } USD`,
                        })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant={mobile ? "Body" : "Small"}
                        className={classes.Link}
                        onClick={() => setOpenCancelModal(true)}
                      >
                        {t("Cancel Fleet Plan")}
                      </Typography>
                    </>
                  )}
                  {subscriptionInfo?.cancelled === 1 && (
                    <div
                      className={classes.column}
                      style={{ paddingRight: 32, minWidth: 320 }}
                    >
                      <Typography
                        category="Default"
                        variant="SmallBold"
                        htmlColor={LightColors.secondary["11"]}
                      >
                        {t("Canceled Fleet Plan_", {
                          a: moment(
                            subscriptionInfo.cancelDT,
                            "YYYY-MM-DD hh:mm:ss"
                          ).format("MMM D, YYYY"),
                        })}
                      </Typography>
                      <div style={{ maxWidth: 320 }}>
                        <Typography
                          category="Default"
                          variant="Small"
                          htmlColor={LightColors.secondary["11"]}
                        >
                          {t("The new plan_2", {
                            a: moment(
                              subscriptionInfo.endOfMonthDT,
                              "YYYY-MM-DD hh:mm:ss"
                            ).format("MMM D, YYYY"),
                          })}
                        </Typography>
                      </div>
                    </div>
                  )}
                  {/* Restore purchase */}
                  <div className={classes.restoreDiv}>
                    <HelpIcon
                      style={{
                        fontSize: "1rem",
                        verticalAlign: "text-top",
                        marginRight: "4px",
                      }}
                    />
                    <Typography category="Default" variant="Small">
                      {t("Have a problem with subscription?")}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      category="Default"
                      variant={mobile ? "Body" : "Small"}
                      className={classes.Link}
                      onClick={() => setOpenRestoreModal(true)}
                    >
                      {t("Restore purchase")}
                    </Typography>
                  </div>
                </>
              )}
            </div>

            {/* Cameras */}
            <div className={clsx(classes.column, classes.pdT16)}>
              <Typography
                category="Default"
                variant="Body"
                style={{ paddingBottom: 8 }}
              >
                {t("Cameras")}
              </Typography>
              {mobile && (
                <>
                  <Typography
                    category="Default"
                    variant="Small"
                    style={{ paddingBottom: 8 }}
                  >
                    {t("available on your_2", {
                      a: subscriptionInfo.cameraLimit ?? 0,
                      b: 999,
                    })}
                  </Typography>
                  {webSubscriptionInfo &&
                    !webSubscriptionInfo.upcomming.canceled && (
                      <Typography
                        category="Default"
                        variant="Body"
                        className={classes.Link}
                        onClick={() => setOpenManageModal(true)}
                      >
                        {t("Manage cameras")}
                      </Typography>
                    )}
                </>
              )}
              {!mobile && (
                <>
                  <Typography
                    category="Default"
                    variant="Small"
                    style={{ paddingBottom: 8 }}
                  >
                    {t("available on your_2", {
                      a: subscriptionInfo.cameraLimit ?? 0,
                      b: 999,
                    })}
                  </Typography>

                  {webSubscriptionInfo &&
                    !webSubscriptionInfo.upcomming.canceled && (
                      <Typography
                        category="Default"
                        variant="Small"
                        className={classes.Link}
                        onClick={() => setOpenManageModal(true)}
                      >
                        {t("Manage cameras")}
                      </Typography>
                    )}
                </>
              )}
            </div>
          </div>
        )}
        {/* Subscription - Free plan */}
        {subscriptionInfo &&
          getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
            "Free plan" && (
            <div className={classes.flex}>
              <div className={clsx(classes.column, classes.padding4)}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {t("Free Plan")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  className={classes.Link}
                  onClick={() =>
                    history.push("/billing", {
                      origin: "/account/subscription",
                    })
                  }
                >
                  {t("Upgrade to Fleet_")}
                </Typography>
                {/* Restore purchase */}
                <div style={{ color: "#68686E", marginTop: "8px" }}>
                  <HelpIcon
                    style={{
                      fontSize: "1rem",
                      color: "#7E7E83",
                      verticalAlign: "text-top",
                      marginRight: "4px",
                    }}
                  />
                  <Typography category="Default" variant="Small">
                    {/* mantis - 12287, 대소문자 규정 적용 (Leehj) */}
                    {t("Already purchased Fleet Plan?")}
                  </Typography>
                </div>
                <div>
                  <Typography
                    category="Default"
                    variant={mobile ? "Body" : "Small"}
                    className={classes.Link}
                    onClick={() => setOpenRestoreModal(true)}
                  >
                    {t("Restore purchase")}
                  </Typography>
                </div>
              </div>
              <div className={clsx(classes.column, classes.pdT32)}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {t("Cameras")}
                </Typography>
                <Typography category="Default" variant="Small">
                  {t("available on your_2", {
                    a: webSubscriptionInfo?.customer.quantity ?? 0,
                    b: 1,
                  })}
                </Typography>
              </div>
            </div>
          )}
        {/* Subscription - One plan */}
        {subscriptionInfo && 
          subscriptionInfo.paymentMethod === "oneplan" && (
            <div className={classes.onePlanWrap}>
              <div className={classes.onePlanName}>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["7"]}
                  style={{ paddingBottom: 8 }}
                >
                  {t("One Plan")}
                </Typography>
              </div>
              <div className={classes.onePlanAccMenu}>
                <Accordion
                  elevation={0}
                  expanded={openOnePlanAcc01}
                  onChange={() => setOpenOnePlanAcc01((o) => !o)}
                >
                  <AccordionSummary className="summaryWrap">
                    <div className="summary">
                      <CheckIcon color="primary" style={theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }} /> 
                      <Typography 
                        category="Default" 
                        variant="Body"
                        htmlColor={!openOnePlanAcc01 ? `${LightColors.primary["1"]}` : `${LightColors.primary["7"]}`}
                      >
                        {t("Up to N Cameras", { a: 3 })}
                      </Typography>
                    </div>
                    {
                      !openOnePlanAcc01 
                        ? <ArrowDropDownIcon htmlColor={`${LightColors.primary["3"]}`} /> 
                        : <ArrowDropUpIcon color="primary" />
                    }
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <ul>
                        <li><CheckIcon color="primary" style={{ marginRight: 8 }} /> {t("Unlimited Live view")} *</li>
                        <li><CheckIcon color="primary" style={{ marginRight: 8 }} /> {t("N Days GPS Tracking", { a: 90 })}</li>
                        <li><CheckIcon color="primary" style={{ marginRight: 8 }} /> {t("Unlimited Playback and Download")} *</li>
                        <li><CheckIcon color="primary" style={{ marginRight: 8 }} /> {t("N days", { a: 30 })} {t("Live event upload")}</li>
                        <li><CheckIcon color="primary" style={{ marginRight: 8 }} /> {t("More Cloud Storage")} ({t("GB", {a: 10})} {t("per camera")})</li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  elevation={0}
                  expanded={openOnePlanAcc02}
                  onChange={() => setOpenOnePlanAcc02((o) => !o)}
                >
                  <AccordionSummary className="summaryWrap">
                    <div className="summary">
                      <CheckIcon color="primary" style={theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }} />
                      <Typography 
                        category="Default" 
                        variant="Body"
                        htmlColor={!openOnePlanAcc02 ? `${LightColors.primary["1"]}` : `${LightColors.primary["7"]}`}
                      >
                        {t("3GB SIM Data Plan")}
                      </Typography>
                    </div>
                    {
                      !openOnePlanAcc02 
                        ? <ArrowDropDownIcon htmlColor={`${LightColors.primary["3"]}`} /> 
                        : <ArrowDropUpIcon color="primary" />
                    }
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <ul style={{ flexDirection: "column" }}>
                        <li><CheckIcon color="primary" style={{ marginRight: 8 }} /> 8+ {t("hours")} {t("Live view")} *</li>
                        <li><CheckIcon color="primary" style={{ marginRight: 8 }} /> {t("Live Event Upload or_")}</li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>            
              </div>
              <div className={classes.flex} style={{ paddingTop: 24 }}>
                <div>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    style={{ paddingBottom: 8 }}
                  >
                    {t("Service Period (Include SIM Data)")}
                  </Typography>
                  <div>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["7"]}
                    >
                      {t(moment(subscriptionInfo?.startOfMonthDT).format("MMMM"))} {moment(subscriptionInfo?.startOfMonthDT).format("D, YYYY")}
                      &nbsp;~&nbsp;
                      {t(moment(subscriptionInfo?.endOfMonthDT).format("MMMM"))} {moment(subscriptionInfo?.endOfMonthDT).format("D, YYYY")} 
                    </Typography>
                  </div>
                </div>
                <div className={clsx(classes.column, classes.pdT32)} style={theme.direction === "rtl" ? { marginRight: 24 } : { marginLeft: 24 }}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                  >
                    {t("Cameras")}
                  </Typography>
                  <Typography category="Default" variant="Small">
                    {t("available on your_2", {
                      a: camLimitCount ?? 0,
                      b: 3,
                    })}
                  </Typography>
                </div>
              </div>
              <div style={{ paddingTop: 24 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("During the usage period of the One Plan_")}
                </Typography>
              </div>
            </div>
        )}
        {/* Subscription - Lite, Smart plan */}
        {subscriptionInfo &&
          subscriptionInfo.paymentMethod !== "oneplan" &&
          (getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
            "Smart plan" ||
            isLitePlan) && (
            <div className={classes.flex}>
              <div style={{ paddingRight: 32 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {isLitePlan ? t("Lite Plan") : t("Smart Plan")}
                </Typography>
              </div>
              <div className={clsx(classes.column, classes.pdT32)}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {t("Cameras")}
                </Typography>
                {/* mantis-12206, 구매한 slot 수 / 구매 가능한 slot로 적용 (Leehj)*/}
                <Typography category="Default" variant="Small">
                  {t("available on your_2", {
                    a: camLimitCount ?? 0,
                    // mantis - 12314, smart, lite 계정 최대 15대 구매가능 (Leehj)
                    b: 15,
                  })}
                </Typography>
              </div>
            </div>
          )}
        {/* Transaction history */}
        <div className={classes.title}>
          <Typography category="Default" variant="H6">
            {t("Transaction history")}
          </Typography>{" "}
        </div>
      </div>

      {/* HistoryTable */}
      {subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Fleet plan" &&
        !inApp && (
          <div className={classes.pdLR16}>
            <HistoryTable
              t={t}
              history={_.map(transactionHistory, (t) => ({
                date: getDateTime(moment.utc(t.createdDT * 1000)),
                amount: t.amount,
                method: t.brand && t.last4 ? `${t.brand} ***${t.last4}` : "",
                details: t.detail,
              }))}
            />
          </div>
        )}
      {/* Transaction history - FreePlan */}
      {/* {subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
          "Free plan" && (
          <>
            {mobile && (
              <div style={{ paddingLeft: 16 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
            {!mobile && (
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
          </>
        )} */}
      {subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Free plan" &&
        !inApp &&
        (transactionHistory.length > 0 ? (
          <div className={classes.pdLR16}>
            <HistoryTable
              t={t}
              history={_.map(transactionHistory, (t) => ({
                date: getDateTime(moment.utc(t.createdDT * 1000)),
                amount: t.amount,
                method: t.brand && t.last4 ? `${t.brand} ***${t.last4}` : "",
                details: t.detail,
              }))}
            />
          </div>
        ) : (
          <>
            {mobile && (
              <div style={{ paddingLeft: 16 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
            {!mobile && (
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
          </>
        ))}
      {/* Transaction history - inApp*/}
      {subscriptionInfo && inApp && (
        <div className={classes.noTransTo}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
            dangerouslySetInnerHTML={{ __html: t("No transactions to_") }}
          ></Typography>
        </div>
      )}
      {/* Modal */}
      {openCancelModal && (
        <SubCancelModal
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
          onClickNegative={() => setOpenCancelModal(false)}
          onClickPositive={() => {
            setOpenCancelModal(false);
            // setNextFleetPlan(false);
            // setCancelFleetPlan(true);
            dispatch(cancelSubscription());
          }}
          loading={type === cancelSubscription.type && loading}
        />
      )}
      {openChangeModal && (
        <SubChangeModal
          open={openChangeModal}
          onClose={() => {
            setOpenChangeModal(false);
          }}
          onClickNegative={() => setOpenChangeModal(false)}
          onClickPositive={() => {
            loadSubscriptionInfo();
            setOpenChangeModal(false);
          }}
        />
      )}
      <div>
        {openManageModal && (
          <SubMgrModal
            open={openManageModal}
            onClose={() => setOpenManageModal(false)}
            onClickNegative={() => setOpenManageModal(false)}
            onClickPositive={() => {
              setOpenManageModal(false);
            }}
          />
        )}
      </div>
      <div>
        {openRestoreModal && (
          <SubRestoreModal
            open={openRestoreModal}
            subscription={subscriptionInfo?.cancelled}
            onClose={() => setOpenRestoreModal(false)}
            onClickNegative={() => setOpenRestoreModal(false)}
            onClickPositive={() => {
              loadSubscriptionInfo();
              setOpenRestoreModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
