import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { useMediaQuery } from "@material-ui/core";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";

interface FirmwareCautionModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalTitle: {
    color: LightColors.secondary["11"],
    minHeight: 16,
    padding: `18px 24px 0 24px`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: `13px 24px 0 13px` }
        : { padding: `13px 13px 0 24px` }),
    },
  },
  exceedModalRoot: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 502,
    },
  },
  exceedModalContentDiv: {
    padding: "24px 24px 24px 24px",
    justifyContent: "center",
  },
  topSvg: {
    textAlign: "center",
    paddingTop: "17px",
  },
  contentTop: {
    textAlign: "center",
    color: LightColors.secondary["11"],
    padding: "13px 13px 16px 13px",
  },
  contentMiddle: {
    backgroundColor: "none",
    textAlign: "center",
    padding: "8px 24px",
  },
  contentCard: {
    padding: "16px 24px 24px",
    backgroundColor: `${LightColors.secondary["14"]}35`,
  },
  contentCardText: {
    padding: "16px 0px 16px 0px",
  },
  RButton: {
    backgroundColor: LightColors.primary["0"],
    color: LightColors.primary["1"],
    border: `1px solid ${LightColors.primary["4"]}`,
    "&:hover, &:focus": {
      backgroundColor: `${LightColors.primary["0"]}`,
      color: `${LightColors.primary["7"]}`,
    },
    "&:active": {
      backgroundColor: `${LightColors.primary["10"]}`,
      color: `${LightColors.primary["7"]}`,
    },
  },
  LButton: {
    border: `1px solid ${LightColors.primary["4"]}`,
  },
  buttonWrap: {
    alignSelf: "center",
  },
}));

export const FirmwareCautionModal = ({
  open,
  onClose,
  onClickPositive,
  onClickNegative,
}: FirmwareCautionModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(
    theme.breakpoints.down(Webviewer.reportMobileView)
  );

  return (
    <Modal
      open={open}
      close
      fullSize={mobile}
      onClose={onClose}
      onClickPositive={onClickPositive}
      onClickNegative={onClickNegative}
      heading={""}
      content={
        <div>
          <div className={classes.topSvg}>
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="27"
                cy="27"
                r="25.5"
                stroke="#D81A26"
                stroke-width="3"
              />
              <rect x="25" y="11" width="4" height="24" rx="1" fill="#D81A26" />
              <rect x="25" y="39" width="4" height="4" rx="1" fill="#D81A26" />
            </svg>
          </div>
          <div className={classes.contentTop}>
            <Typography category="Default" variant="H3">
              {t("Firmware Update Caution")}
            </Typography>
          </div>
          <div className={classes.contentMiddle}>
            <Typography category="Default" variant="Body">
              {t("Please check below_")}
            </Typography>
          </div>
          <div className={classes.contentCard}>
            <div className={classes.contentCardText} style={{ paddingTop: 9 }}>
              <Typography category="Default" variant="H6">
                {t("The camera's Wi-Fi_")}
              </Typography>

              <div style={{ marginTop: 8 }}>
                <Typography category="Default" variant="Body">
                  {t("Check if your_")}
                </Typography>
              </div>
            </div>

            <div
              className={classes.contentCardText}
              style={{
                borderTop: `1px solid ${LightColors.primary["6"]}`,
                borderBottom: `1px solid ${LightColors.primary["6"]}`,
              }}
            >
              <Typography category="Default" variant="H6">
                {t("DO NOT disconnect_")}
              </Typography>
              <div style={{ marginTop: 6 }}>
                <Typography category="Default" variant="Body">
                  {t("The camera and_")}
                </Typography>
              </div>
            </div>

            <div
              className={classes.contentCardText}
              style={{ paddingBottom: 0 }}
            >
              <Typography category="Default" variant="H6">
                {t("DO NOT try_")}
              </Typography>
              <div style={{ marginTop: 6 }}>
                <Typography category="Default" variant="Body">
                  {t("Expecially Reboot camera_")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      }
      RButton={t("Update anyway")}
      LButton={t("Exit")}
      RButtonClassName={classes.RButton}
      LButtonClassName={classes.LButton}
      titleClassName={classes.modalTitle}
      className={classes.exceedModalRoot}
      contentClassName={classes.exceedModalContentDiv}
      actionClassName={classes.buttonWrap}
    />
  );
};
