import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Modal, Typography } from "@thingsw/pitta-design-system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import { getFreetrialState } from "../features/Payment/slice";
import { PlanType } from "../utils/Service";
import { Webviewer, LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  btnDiv: {
    marginTop: theme.spacing(3),
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
}));

export interface UpgradePlanPanelProps {
  plan: PlanType;
  gpstracking?: boolean;
}

export const UpgradePlanPanel = ({
  plan,
  gpstracking,
}: UpgradePlanPanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getFreetrialState());
  }, [dispatch]);

  const handleUpgradeNow = () => {
    if (plan === "Free plan") {
      history.push("/billing", { origin: location.pathname });
    } else {
      setOpen(true);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.primary["2"]}
        >
          {/* mantis - 12369, gps tracking 업그레이화면인 경우 출력 문구 다르게 적용 (Leehj) */}
          {location.pathname === "/gps-tracking"
            ? t("This feature is_smart")
            : t("This feature is_")}
        </Typography>
        <div className={classes.btnDiv}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpgradeNow}
          >
            {t("Upgrade now")}
          </Button>
        </div>
      </div>
      <Modal
        open={open}
        close
        onClose={() => setOpen(false)}
        onClickPositive={() => {
          setOpen(false);
        }}
        heading={t("Cancel your inapp_")}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: t("You have cancel_").replace(
                // eslint-disable-next-line no-control-regex
                new RegExp("\n", "g"),
                "<br/>"
              ),
            }}
          />
        }
        RButton={t("OK")}
        actionClassName={classes.modalBottom}
        className={classes.modalWrap}
      />
    </div>
  );
};
