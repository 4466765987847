import { detect } from "detect-browser";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ServerMaintenanceModal } from "../../components/modals/ServerMaintenanceModal";
import { useQuery } from "../../hooks/useQuery";

export const MaintenancingScreen = () => {
  const query = useQuery();
  const [start, setStart] = useState<moment.Moment>();
  const [end, setEnd] = useState<moment.Moment>();

  useEffect(() => {
    if (query.has("start")) {
      setStart(moment.utc(query.get("start")));
    }
    if (query.has("end")) {
      setEnd(moment.utc(query.get("end")));
    }
  }, [query]);

  const handleClose = useCallback(() => {
    const browser = detect();
    if (browser?.name === "ios-webview") {
      //@ts-ignore
      return webkit.messageHandlers.back.postMessage({});
    }
    //@ts-ignore
    if (
      browser?.name === "chromium-webview" &&
      //@ts-ignore
      window.Webviewer?.back
    ) {
      //@ts-ignore
      return window.Webviewer.back();
    }
  }, []);

  return start && end ? (
    <ServerMaintenanceModal
      open={true}
      start={start}
      end={end}
      onClose={handleClose}
      close={false}
    />
  ) : (
    <></>
  );
};
