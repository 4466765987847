import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { IconButton, Modal, Typography } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../features/store";
import {
  clearDownloadVOD,
  successLoadVODToken,
  updateVOD,
  VOD,
} from "../../features/VOD/slice";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import RefreshIcon from "@material-ui/icons/Refresh";

import { getVODToken } from "../../apis";
import { USER } from "../../features/User/slice";

import { CAMERA } from "../../features/Camera/slice";
import { downloadBlob, getLink } from "../../utils/VOD";
import { useTranslation } from "react-i18next";
import { AutoSizer, List, ListRowRenderer } from "react-virtualized";
import Scrollbars from "react-custom-scrollbars";
import { useMediaQuery } from "@material-ui/core";
import { renderListHelper } from "../../utils/List";
import {
  ICameraInfo,
  IVOD,
  IVODToken,
  jwtAxiosInst,
  LightColors,
  RESULT_CODE,
  sleep,
  Webviewer,
} from "@thingsw/pitta-modules";

interface FileDownloadModalProps {
  camera?: ICameraInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: LightColors.primary["0"],
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    width: "calc(100% - 28px)",
    right: 14,
    bottom: 23,
    overflowY: "hidden",
    transition: theme.transitions.create(["height", "width"]),
    zIndex: 3,
    maxHeight: 436,
    overflow: "hidden",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 437,
    },
  },
  rootHide: {
    display: "none",
  },
  rootClose: {
    height: 40,

    width: "calc(100% - 28px)",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 335,
    },
  },
  header: {
    width: "100%",
    minHeight: 40,
    backgroundColor: LightColors.primary["1"],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  cancelBtn: {
    padding: 0,
    color: LightColors.primary["0"],
  },
  iconBtn: {
    padding: 0,
    marginRight: theme.spacing(2),
    color: LightColors.primary["0"],
  },
  marginLeft3: {
    marginLeft: 3,
  },
  itemDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 36,
    padding: theme.spacing(0, 2),
    userSelect: "none",
  },
  circleRoot: {
    position: "relative",
  },
  listDiv: {
    height: "calc(100% - 40px)",
    overflowY: "auto",
  },

  cancelDiv: {
    width: "100%",
    height: 36,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  downloadIconDiv: {
    position: "relative",
    overflow: "hidden",
  },
  downloadIcon: {
    position: "absolute",
  },
  downloadArrowIcon: {
    position: "absolute",
    animation: `$donwloadArrow 1500ms`,
    animationIterationCount: "infinite",
    left: 0,
    zIndex: 9,
  },
  "@keyframes donwloadArrow": {
    from: {
      top: -24,
    },
    to: {
      top: 24,
    },
  },
  autoSizerDiv: {
    // width: "100%!important",
    // [theme.breakpoints.up(Webviewer.mobile)]: {
    //   width: 0,
    // },
  },
}));

export const FileDownloadModal = ({ camera }: FileDownloadModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);
  const { downloadVODList, tokens: vod_tokens, closeDownload } = useSelector(
    (state: RootState) => state[VOD]
  );
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

  const forceScrollRef = useRef(true);
  const scrollRef = useRef<Scrollbars>(null);
  const listRef = useRef<List>(null);

  const [open, setOpen] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  // mantis - 12598, 다운로드 취소하는 경우 진행중인 업로드 취소하기위해 psn 필요 (Leehj)
  const [currentCamPsn, setCurrentCamPsn] = useState<string>();
  // mantis - 12598, 다운로드 요청을 중단하기 위함 (Leehj)
  const [abortController, setAbortController] = useState(new AbortController());
  const abortSignal = abortController.signal;

  useEffect(() => {
    if (mobile) {
      setOpen(false);
    }
  }, [mobile]);

  useEffect(() => {
    setVisible(downloadVODList.length > 0);
  }, [downloadVODList.length]);

  useEffect(() => {
    if (closeDownload) {
      setOpen(false);
    }
  }, [closeDownload]);

  useEffect(() => {
    const downloadFile = async (
      vod: IVOD,
      vodIndex: number,
      email: string,
      user_token: string,
      vodTokens: { [key: string]: IVODToken }
    ) => {
      if (!vod.psn) return;
      let link: string | undefined = undefined;
      let filename: string | undefined = undefined;
      filename = vod.filename;
      if (scrollRef.current && forceScrollRef.current) {
        scrollRef.current.scrollTop(36 * Math.max(vodIndex - 2, 0));
      }

      dispatch(updateVOD({ ...vod, downloading: true }));
      if (!vod.cloudNative) {
        if (vod.lowFilename && vod.quality === "low") {
          filename = vod.lowFilename;
        }
        let token = vodTokens[filename];
        if (!token) {
          const res = await getVODToken(email, user_token, vod.psn, filename);
          const response = res.data as {
            resultcode: RESULT_CODE;
          } & IVODToken;

          token = response;
          dispatch(successLoadVODToken({ filename, token: token }));
        }
        const cam = _.find(
          cameraList?.deviceListInfo,
          (cam) => cam.device.psn === vod.psn
        )?.device;

        setCurrentCamPsn(cam && cam.psn);
        if (cam) {
          link = getLink(
            cam,
            email,
            user_token,
            vod,
            token,
            vod.quality ?? "low"
          );
        }
      } else {
        let token = vodTokens[filename];
        if (!token) {
          const res = await getVODToken(email, user_token, vod.psn, filename);
          const response = res.data as {
            resultcode: RESULT_CODE;
          } & IVODToken;
          const { resultcode } = response;

          if (resultcode === "BC_ERR_OK") {
            token = response;
            dispatch(successLoadVODToken({ filename, token: response }));
          } else {
            dispatch(updateVOD({ ...vod, failed: true, downloading: false }));
            setDownloading(false);
            return;
          }
        }
        const resp = await jwtAxiosInst.post(`/IoT/devicecommand`, {
          command: "DownSDVideo",
          email,
          user_token,
          tokenType: "web",
          psn: vod.psn,
          param1: filename,
          param2: token.vod_token,
          param3: vod.quality === "original" ? "m" : "s",
        });
        const { data } = resp;
        if (data.resultcode === "BC_ERR_OK") {
          if (data.status === "free") {
            link = `${data.streamURL}?filesize=${data.filesize}`;
          } else {
            await sleep(500);
            setDownloading(false);
            return;
          }
        } else {
          dispatch(updateVOD({ ...vod, failed: true, downloading: false }));
          setDownloading(false);
          return;
        }
      }

      console.log("CloudNative3", "download VOD", link);
      if (link && filename) {
        try {
          // let response = await fetch(link);
          // mantis - 12598,
          let response = await fetch(link, { signal: abortSignal });

          const reader = response.body?.getReader();

          // Step 2: get total length
          const contentLength = +(
            response.headers.get("Content-Length") ?? "0"
          );
          dispatch(updateVOD({ ...vod, size: contentLength }));
          console.log(
            "CloudNative3",
            "FileDownloadModal",
            "contentLength",
            contentLength
          );

          if (reader) {
            // Step 3: read the data
            let receivedLength = 0; // received that many bytes at the moment
            let chunks = []; // array of received binary chunks (comprises the body)
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                break;
              }

              chunks.push(value);
              receivedLength += value.length;
              dispatch(updateVOD({ ...vod, downloaded: receivedLength }));

              // console.log(
              //   "CloudNative3",
              //   "FileDownloadModal",
              //   `Received ${receivedLength} of ${contentLength}`
              // );
            }

            // Step 4: concatenate chunks into single Uint8Array
            let chunksAll = new Uint8Array(receivedLength); // (4.1)
            let position = 0;
            for (let chunk of chunks) {
              chunksAll.set(chunk, position); // (4.2)
              position += chunk.length;
            }

            console.log(
              "CloudNative3",
              "FileDownloadModal",
              filename,
              chunksAll.length
            );
            downloadBlob(
              chunksAll,
              vod.quality === "low" ? vod.lowFilename ?? filename : filename,
              "video/mp4"
            );
            dispatch(updateVOD({ ...vod, downloadDone: true }));
          }
        } catch (err) {
          //  mantis - 12598, `abortController.abort();` 실행으로 인한 중단 에러 처리 (Leehj)
          if ((err as Error).name === "AbortError") {
            await sleep(500);
            setDownloading(false);
            return;
          } else {
            dispatch(updateVOD({ ...vod, failed: true, downloading: false }));
          }
        }
      }
      await sleep(500);
      setDownloading(false);
    };
    const downloadVOD = _.chain(downloadVODList)
      .filter((vod) => !vod.downloadDone && !vod.cancelled && !vod.failed)
      .first()
      .value();
    if (downloadVOD && !downloading && email && loginInfo) {
      setDownloading(true);

      const downloadVODIndex = _.chain(downloadVODList)
        .findIndex((vod) => _.isEqual(vod, downloadVOD))
        .value();
      downloadFile(
        downloadVOD,
        downloadVODIndex,
        email,
        loginInfo.user_token,
        vod_tokens
      );
    }
  }, [
    dispatch,
    downloadVODList,
    downloadVODList.length,
    downloading,
    email,
    loginInfo,
    vod_tokens,
    cameraList,
    abortSignal,
  ]);

  const downloadedVODLength = useMemo(() => {
    return _.filter(downloadVODList, (vod) => vod.downloadDone).length;
  }, [downloadVODList]);

  const totalVODLength = useMemo(() => {
    return _.filter(downloadVODList, (vod) => !vod.cancelled).length;
  }, [downloadVODList]);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const indx = props.index;
      const vod = downloadVODList[indx];
      let icon: any = (
        <IconButton
          className={classes.cancelBtn}
          onClick={() => {
            dispatch(updateVOD({ ...vod, cancelled: true }));
          }}
        >
          <CloseIcon htmlColor={LightColors.primary["2"]} />
        </IconButton>
      );

      if (vod.cancelled) {
        icon = (
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            {t("Canceled")}
          </Typography>
        );
      } else if (vod.failed) {
        icon = (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
              style={{ marginRight: 4 }}
            >
              {t("failed")}
            </Typography>
            <IconButton
              className={classes.cancelBtn}
              onClick={() => {
                dispatch(updateVOD({ ...vod, failed: false }));
              }}
            >
              <RefreshIcon htmlColor={LightColors.primary["2"]} />
            </IconButton>
          </div>
        );
      } else if (vod.downloadDone) {
        icon = <CheckCircleIcon htmlColor={LightColors.secondary["15"]} />;
      } else if (vod.downloading) {
        icon = (
          <div className={classes.downloadIconDiv}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ zIndex: 11 }}
            >
              <path d="M5 20.5H19V18.5H5V20.5Z" fill="#68686E" />
            </svg>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: 10,
                backgroundColor: "white",
                zIndex: 10,
              }}
            />
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.downloadArrowIcon}
            >
              <path d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5Z" fill="#68686E" />
            </svg>
          </div>
        );
      }

      return (
        <div className={classes.itemDiv} key={props.key} style={props.style}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["1"]}
          >
            {vod.quality === "low" ? vod.lowFilename : vod.filename}
          </Typography>
          <div className={classes.circleRoot}>{icon}</div>
        </div>
      );
    },
    [
      classes.cancelBtn,
      classes.circleRoot,
      classes.downloadArrowIcon,
      classes.downloadIconDiv,
      classes.itemDiv,
      dispatch,
      downloadVODList,
      t,
    ]
  );

  const listMarkup = useCallback(
    (width: number, height: number) => (
      <List
        ref={listRef}
        width={width}
        height={height}
        rowHeight={36}
        rowCount={totalVODLength}
        rowRenderer={rowRenderer}
        overscanRowCount={5}
        style={
          mobile
            ? {}
            : {
                overflowY: "visible",
                overflowX: "visible",
              }
        }
      />
    ),
    [mobile, rowRenderer, totalVODLength]
  );

  const handleCancelDownload = useCallback(async () => {
    setOpenCancelModal(false);
    // mantis - 12598, AbortController가 요청을 중단시키고, fetch 요청에서 해당 중단 신호를 받아 중단 (Leehj)
    // 다시 downloadFile실행했을 경우를 위해, 에러와 취소를 독립적으로 처리하기 위함(Leehj)
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    // 다운로드 요청 중단
    abortController.abort();
    // mantis - 12598, 다운로드 취소하는 경우 진행중인 업로드 취소 (Leehj)
    await jwtAxiosInst.post(`/IoT/devicecommand`, {
      command: "CancelUpload",
      email,
      user_token: loginInfo && loginInfo.user_token,
      tokenType: "web",
      psn: currentCamPsn,
    });
    dispatch(clearDownloadVOD());
  }, [abortController, currentCamPsn, dispatch, email, loginInfo]);

  return (
    <>
      <div
        className={clsx(classes.root, {
          [classes.rootClose]: !open,
          [classes.rootHide]: !visible,
        })}
        style={
          open ? { height: 40 + Math.min(5, downloadVODList.length) * 36 } : {}
        }
      >
        <div className={classes.header}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["0"]}
          >
            {t("Downloaded videos: ")}
            {`${downloadedVODLength}/${totalVODLength}`}
          </Typography>
          <div>
            <IconButton
              className={classes.iconBtn}
              onClick={() => {
                setOpen((o) => !o);
              }}
            >
              {open ? (
                <ExpandMoreIcon />
              ) : (
                <ExpandLessIcon className={classes.marginLeft3} />
              )}
            </IconButton>
            <IconButton
              className={classes.iconBtn}
              onClick={() => {
                if (totalVODLength === downloadedVODLength) {
                  dispatch(clearDownloadVOD());
                } else {
                  setOpenCancelModal(true);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <AutoSizer className={classes.autoSizerDiv}>
            {({ height, width }) => {
              console.log("height", height);
              return mobile ? (
                <div>{listMarkup(width, height)}</div>
              ) : (
                renderListHelper(
                  width,
                  height,
                  listMarkup(width, height),
                  false,
                  theme.direction,
                  listRef
                )
                // <Scrollbars
                //   style={{ height, width }}
                //   autoHide
                //   ref={scrollRef}
                //   onScroll={() => (forceScrollRef.current = false)}
                // >
                //   {downloadListMarkup}
                // </Scrollbars>
              );
            }}
          </AutoSizer>
        </div>
      </div>
      <Modal
        open={openCancelModal}
        headingIcon={<ErrorIcon htmlColor={LightColors.secondary["11"]} />}
        heading={t("Cancel download?")}
        content={
          <Typography category="Default" variant="Body">
            {t("Your download is not_")}
          </Typography>
        }
        close
        onClose={() => setOpenCancelModal(false)}
        onClickPositive={() => setOpenCancelModal(false)}
        onClickNegative={handleCancelDownload}
        RButton={t("Continue download")}
        LButton={t("Cancel download")}
      />
    </>
  );
};
