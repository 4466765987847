import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@thingsw/pitta-design-system";
import React from "react";

import moment from "moment";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

import { IUXUILatestEvent } from "../../features/Event/slice";
import _ from "lodash";
import { LightColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2, 0),
    cursor: "pointer",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  time: {
    marginLeft: theme.spacing(5),
  },
}));

interface LatestEventProps {
  event: IUXUILatestEvent;
  onClick?: (event: IUXUILatestEvent) => void;
}

// eslint-disable-next-line no-control-regex
const regex = new RegExp(/(\[[\u0000-\uFFFF]+\])?([\u0000-\uFFFF]+)/);

export const LatestEvent = ({ event, onClick }: LatestEventProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={() => onClick?.(event)}>
      <div className={classes.title}>
        <NotificationImportantIcon
          className={classes.icon}
          htmlColor={LightColors.primary["3"]}
        />
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.secondary["11"]}
        >
          {_.last(event.msg.match(regex))}
        </Typography>
      </div>
      <div className={classes.time}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {event.cdate &&
            moment(event.cdate).utcOffset(0).format("MMM DD, h:m:ss A")}
        </Typography>
      </div>
    </div>
  );
};
