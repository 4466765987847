import {
  AppStoreBadge,
  GooglePlayBadge,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { APPSTORE, GOOGLEPLAY } from "@thingsw/pitta-modules";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";

interface DownLoadAppAddBatteryModalProps {
  openDownloadModal: boolean;
  setOpenDownloadModal: (open: boolean) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "16px 24px 12px 24px",
  },
  questionDiv: {
    marginBottom: 8,
  },
  titleDiv: {
    marginBottom: 29,
    marginTop: 8,
  },
  storeDiv: {
    display: "flex",
    width: "100%",
    // justifyContent: "space-evenly",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    [theme.breakpoints.down(361)]: {
      flexDirection: "column",
    },
  },
  store: {
    display: "flex",
    flexDirection: "column",
    minHeight: 152,
    alignItems: "start",
    justifyContent: "center",
    gap: 10,
  },
  appStore: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(5) }
      : { marginRight: theme.spacing(5) }),
    [theme.breakpoints.down(361)]: {
      margin: 0,
      marginBottom: 32,
    },
  },
  qr: {
    width: 120,
    height: 120,
  },
}));

const DownLoadAppAddBatteryModal = (props: DownLoadAppAddBatteryModalProps) => {
  const { openDownloadModal, setOpenDownloadModal } = props;
  const { t } = useTranslation();

  const classes = useStyles(props);

  return (
    <Modal
      open={openDownloadModal}
      close
      noPadding
      onClose={() => setOpenDownloadModal(false)}
      content={
        <div className={classes.container}>
          <div className={classes.titleDiv}>
            <Typography variant="H6">
              {t("Download the Blackvue app to check")}
            </Typography>
          </div>
          <div>
            <Typography variant="Body">{t("By Using the app")}</Typography>
          </div>
          <div className={classes.storeDiv}>
            <div className={clsx(classes.store, classes.appStore)}>
              <img
                className={classes.qr}
                src="/images/QR_Code.png"
                alt="qr code for BlackVue App"
              />
            </div>
            <div className={classes.store}>
              <a href={APPSTORE} target="_blank" rel="noreferrer">
                <AppStoreBadge
                  style={{
                    minWidth: 120,
                    height: 40,
                  }}
                />
              </a>
              <a href={GOOGLEPLAY} target="_blank" rel="noreferrer">
                <GooglePlayBadge
                  style={{
                    minWidth: 135,
                    height: 40,
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DownLoadAppAddBatteryModal;
