import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DarkColors,
  FONT_FAMILY,
  IThemeState,
  LightColors,
} from "@thingsw/pitta-modules";

let initialState: IThemeState = {
  direction: "ltr",
  menuOpen: true,
  fontFamily: "Roboto",
  needToChangeLang: false,
  lang: "en",
  loadingLocation: false,
  fullscreen: false,
  colors: LightColors,
  color: "light",
};

const slice = createSlice({
  name: "Theme",
  initialState,
  reducers: {
    changeDirection: (state, { payload }: PayloadAction<"rtl" | "ltr">) => {
      state.direction = payload;
    },
    setMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.menuOpen = payload;
    },
    setFontFamily: (state, { payload }: PayloadAction<FONT_FAMILY>) => {
      state.fontFamily = payload;
    },
    setNeedToChangeLang: (state, { payload }: PayloadAction<boolean>) => {
      state.needToChangeLang = payload;
    },
    setLang: (state, { payload }: PayloadAction<string>) => {
      state.lang = payload;
    },
    setLoadingLocation: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingLocation = payload;
    },
    setExitFullscreen: (
      state,
      { payload }: PayloadAction<object | undefined>
    ) => {
      state.exitFullscreen = payload;
    },
    setFullscreen: (state, { payload }: PayloadAction<boolean>) => {
      state.fullscreen = payload;
    },
    changeColor: (state, { payload }: PayloadAction<"dark" | "light">) => {
      state.color = payload;
      if (payload === "dark") {
        state.colors = DarkColors;
      } else {
        state.colors = LightColors;
      }
    },
  },
});

export const {
  changeDirection,
  setMenuOpen,
  setFontFamily,
  setNeedToChangeLang,
  setLang,
  setLoadingLocation,
  setExitFullscreen,
  setFullscreen,
  changeColor,
} = slice.actions;
export const THEME = slice.name;
export default slice.reducer;
