import React, { useCallback, useMemo } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import {
  Avatars,
  Typography,
  WebMenuItem,
  Menu,
  Link,
  Button,
  Fonts,
  MobileMenu,
  Trash,
} from "@thingsw/pitta-design-system";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { Trans, useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment";

import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../features/store";
import { CAMERA } from "../features/Camera/slice";
import { USER } from "../features/User/slice";
import {
  LightColors,
  IMembersInfo,
  StatusCode,
  UserNames,
  RoleNames,
  StatusNames,
  Webviewer,
  DashCamList,
} from "@thingsw/pitta-modules";

const useCardHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(3, 3, 5.25),
    backgroundColor: LightColors.primary["7"],
    textAlign: "center",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      textAlign: "inherit",
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { margin: "0px 0px 0px 32px" }
        : { margin: "0px 32px 0px 0px" }),
    },
  },
  title: {
    marginBottom: theme.spacing(0.25),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(0.375),
    },
  },
  subheader: {
    display: "grid",
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    boxShadow: "none",
    border: `1px solid ${LightColors.primary["4"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 672,
    },
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(3, 16.9, 3, 3) }
        : { padding: theme.spacing(3, 3, 3, 16.9) }),
    },
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
  statusIcon: {
    ...(theme.direction === "rtl"
      ? { marginRight: -2, marginLeft: 1.5 }
      : { marginLeft: -2, marginRight: 1.5 }),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  groupDiv: {
    marginBottom: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: "100%",
    },
  },
  iconBtn: {
    padding: 3,
    position: "absolute",
    top: 11,
    right: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      position: "inherit",
      marginRight: 3,
      marginTop: 3,
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  webMenuItem: {
    minWidth: 142,
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0.75, 2),
    },
  },
  mobileMemutext: {
    marginLeft: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  emailDiv: {
    marginBottom: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: LightColors.primary["0"],
    fontSize: 16,
  },
}));

export const MemberCard = (props: {
  member: IMembersInfo;
  myGroup?: DashCamList;

  onDeleteMember?: (member: IMembersInfo) => void;
  onClickPermission?: (member: IMembersInfo) => void;
}) => {
  const { member, myGroup, onDeleteMember, onClickPermission } = props;
  const {
    email,
    role,
    groupName,
    dateInvited,
    mailStatus,
    firstName,
    lastName,
    profileImg,
    hostfirstName,
    hostlastName,
    hostRole,
  } = member;
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const cardHeaderClasses = useCardHeaderStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const [open, setOpen] = React.useState(false);
  // const [clickMenu, setClickMenu] = React.useState(false);
  const isDateTime = moment(dateInvited).format("MMM D, YYYY h:m A");
  const { userProfile } = useSelector((state: RootState) => state[USER]);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const statusColors: { [key in StatusCode]: string } = {
    P: LightColors.secondary["17"],
    A: LightColors.secondary["15"],
    E: LightColors.secondary["11"],
  };

  const cameras = useSelector((state: RootState) => state[CAMERA].cameraList);

  // 할당 카메라의 PSN
  const camPSN = useMemo(() => {
    return _.compact(
      _.map(myGroup?.DashCamUser, (n) => {
        if (_.find(n.Users, (u) => u.Email === email)) {
          return n.PSN;
        }
      })
    )[0];
  }, [email, myGroup?.DashCamUser]);

  // 할당 카메라
  const foundUserCam = useMemo(() => {
    return _.find(cameras?.deviceListInfo, (dev) => dev.device.psn === camPSN);
  }, [camPSN, cameras?.deviceListInfo]);

  const handleRemove = () => {
    setOpen(false);
    onDeleteMember?.(member);
  };

  const handleClick = () => {
    // setClickMenu((prevOpen) => !prevOpen);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
    // setClickMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
      // setClickMenu(false);
    }
  }

  const checkDisabled = useCallback(() => {
    if (role === "SubMaster" && mailStatus === "A") {
      return false;
    } else {
      return true;
    }
  }, [role, mailStatus]);

  const getGroupName = useCallback(() => {
    if (groupName) {
      return (
        <Link
          category="Default"
          variant="Body"
          color="primary"
          to={`/groups/${myGroup?.GroupID}`}
        >
          {groupName}
        </Link>
      );
    } else {
      return t("none");
    }
  }, [groupName, myGroup?.GroupID, t]);

  // mantis - 8541
  // 초대한 사람이 owner인 경우 You · Owner / 그렇지 않고 현재 login계정이 admin이면 링크없이 이름 및 hostRole,
  // 현재 login계정이 owner이면 이름 및 hostRole에 링크
  const invitedMarkup = useMemo(() => {
    if (hostRole === "Master") {
      return t("Invited on_", {
        "Invited Date": isDateTime,
        user: t("You"),
        role: t("Master"),
      });
    } else {
      return (
        <Trans
          t={t}
          components={{
            "Invited Date": isDateTime,
            a:
              userProfile?.userType === "SubMaster" ? (
                <></>
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="/"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                />
              ),
            user: `${hostfirstName} ${hostlastName}`,
            role: t(UserNames[hostRole]),
          }}
        >
          Invited on_2
        </Trans>
      );
    }
  }, [
    hostRole,
    hostfirstName,
    hostlastName,
    isDateTime,
    t,
    userProfile?.userType,
  ]);

  return (
    <Card className={classes.cardRoot}>
      <CardHeader
        classes={cardHeaderClasses}
        avatar={
          <Avatars
            imgSrc={profileImg}
            // Mantis-8566
            // name={lastName} >> {userProfile?.email}로 수정함.
            name={userProfile?.email}
            style={{
              width: 80,
              height: 80,
              backgroundColor: LightColors.primary["8"],
              ...Fonts.Default.H1,
            }}
          ></Avatars>
        }
        action={
          <>
            <IconButton
              ref={anchorRef}
              onClick={handleClick}
              className={classes.iconBtn}
              disableRipple
            >
              <MoreVertIcon style={{ color: LightColors.primary["0"] }} />
            </IconButton>
            {mobile ? (
              <MobileMenu open={open} onClose={() => setOpen(false)}>
                <WebMenuItem
                  disabled={
                    userProfile?.userType &&
                    UserNames[userProfile.userType] === "Admin"
                  }
                  className={classes.mobileMemuItem}
                  onClick={handleRemove}
                  startIcon={<Trash htmlColor={LightColors.secondary["11"]} />}
                >
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.secondary["11"]}
                    className={classes.mobileMemutext}
                  >
                    {t("Delete")}
                  </Typography>
                </WebMenuItem>
              </MobileMenu>
            ) : (
              <Menu
                open={open}
                anchorEl={anchorRef.current}
                onClickAway={handleClose}
                onKeyDown={handleListKeyDown}
                placement="bottom-end"
                dense
              >
                <WebMenuItem
                  disabled={
                    userProfile?.userType &&
                    UserNames[userProfile.userType] === "Admin"
                  }
                  className={classes.webMenuItem}
                  onClick={handleRemove}
                  startIcon={<Trash htmlColor={LightColors.secondary["11"]} />}
                  dense
                >
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.secondary["11"]}
                  >
                    {t("Delete")}
                  </Typography>
                </WebMenuItem>
              </Menu>
            )}
          </>
        }
        title={
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["0"]}
          >
            {firstName && lastName ? `${firstName} ${lastName}` : "–"}
          </Typography>
        }
        subheader={
          <>
            <div className={classes.emailDiv}>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["0"]}
              >
                {email}
              </Typography>
            </div>

            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["0"]}
            >
              {RoleNames[role]}
            </Typography>
          </>
        }
      />

      <CardContent className={classes.contentDiv}>
        <div>
          <div className={classes.statusDiv}>
            <FiberManualRecordIcon
              className={classes.statusIcon}
              style={{
                color: `${statusColors[mailStatus]}`,
                fontSize: 15,
              }}
            />
            <Typography
              category="Default"
              variant="Body"
              htmlColor={`${statusColors[mailStatus]}`}
            >
              {StatusNames[mailStatus]}
            </Typography>
          </div>
          <div className={classes.marginB2}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["1"]}
            >
              {invitedMarkup}
            </Typography>
          </div>
          <div className={classes.groupDiv}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["1"]}
            >
              {t("Group")}:{" "}
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["2"]}
              >
                {getGroupName()}
              </Typography>
            </Typography>
          </div>
          {role === "User" ? (
            <>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["1"]}
              >
                {t("Camera")}:{" "}
              </Typography>
              {foundUserCam ? (
                <Link
                  category="Default"
                  variant="Body"
                  color="primary"
                  to={`/groups/${foundUserCam.groupManagementID}`}
                >
                  {foundUserCam.device.dev_name}
                </Link>
              ) : (
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("none")}
                </Typography>
              )}
            </>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              disabled={checkDisabled()}
              onClick={() => onClickPermission?.(member)}
            >
              {t("Permissions")}
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
