import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGoogleUserInfo {
  id?: string;
  email: string;
  verified_email?: boolean;
  name?: string;
  given_name: string;
  family_name: string;
  picture?: string;
  locale?: string;
  hd?: string;
}

interface IState {
  socialLoading: boolean;
  googleUser?: IGoogleUserInfo;
  type?: string;
}

let initialState: IState = {
  socialLoading: false,
  googleUser: undefined,
};

const slice = createSlice({
  name: "social",
  initialState,
  reducers: {
    clearSocial: (state) => {
      state.socialLoading = false;
      state.googleUser = undefined;
      state.type = undefined;
    },
    loadGoogleUserInfo: (
      state,
      action: PayloadAction<{ accessToken: string }>
    ) => {
      state.socialLoading = true;
      state.type = action.type;
    },
    successLoadGoogleUserInfo: (
      state,
      { payload }: PayloadAction<IGoogleUserInfo>
    ) => {
      state.socialLoading = false;
      state.googleUser = payload;
    },
    failLoad: (state) => {
      state.socialLoading = false;
    },
  },
});

export const {
  clearSocial,
  loadGoogleUserInfo,
  successLoadGoogleUserInfo,
  failLoad,
} = slice.actions;
export const SOCIAL = slice.name;
export default slice.reducer;
