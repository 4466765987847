import { ISubscriptionInfo, IUserUsageInfo } from "@thingsw/pitta-modules";

export const isFreePlan = (subscriptionInfo: ISubscriptionInfo) => {
  return subscriptionInfo && subscriptionInfo?.servicePlanID === 1;
};

export function isFree100Check(
  subscriptionInfo: ISubscriptionInfo,
  userUsage: IUserUsageInfo
) {
  if (
    isFreePlan(subscriptionInfo) &&
    userUsage &&
    userUsage.remoteVideoPlayBackAndDownLoad >= userUsage.LimitVOD
  ) {
    return true;
  } else {
    return false;
  }
}
