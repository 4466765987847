import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as turf from "@turf/turf";
import _ from "lodash";
import {
  DrawingMode,
  FenceTypeName,
  IGeofence,
  IGeofenceState,
} from "@thingsw/pitta-modules";

let initialState: IGeofenceState = {
  loading: false,
  geofences: [],
  speedGeofences: [],
  drawingMode: "polygon",
  fillColor: "#F21212",
  fillOpacity: 0.5,
  undoList: [],
  redoList: [],
  showDetail: false,
  redrawing: {},
  openAdjustPriority: false,
};

const slice = createSlice({
  name: "geofence",
  initialState,
  reducers: {
    clearGeofence: (state) => {
      state.loading = false;
      state.type = undefined;
      state.geofences = [];
    },
    loadGeofences: (state, action: PayloadAction<{ priority: boolean } | undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGeofences: (state, { payload }: PayloadAction<IGeofence[]>) => {
      state.loading = false;
      state.geofences = payload;
    },
    successLoadSpeedGeofences: (state, { payload }: PayloadAction<IGeofence[]>) => {
      state.loading = false;
      state.speedGeofences = payload;
    },
    setSpeedGeofences: (state, { payload }: PayloadAction<IGeofence[]>) => {
      state.speedGeofences = payload;
    },
    saveSpeedPriority: (state, action: PayloadAction<IGeofence[]>) => {
      state.loading = true;
    },
    addGeofence: (state, action: PayloadAction<IGeofence>) => {
      state.loading = true;
      state.type = action.type;
    },
    updateGeofence: (state, action: PayloadAction<IGeofence>) => {
      state.loading = true;
      state.type = action.type;
    },
    successRequestGeofence: (state) => {
      state.loading = false;
    },
    deleteGeofence: (
      state,
      action: PayloadAction<{ fenceId: string; fenceName: string }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    setCurrentGeofence: (
      state,
      { payload }: PayloadAction<IGeofence | undefined>
    ) => {
      state.currentGeofence = payload;
      if (payload) {
        let geom: any;
        // polygon
        if (payload.fenceType === 1 && payload.polygon) {
          const coordinates = _.chain([
            ...payload.polygon,
            _.first(payload.polygon),
          ])
            .compact()
            .map((p) => [p.lng, p.lat])
            .value();
          const polygon = turf.polygon([coordinates]);

          geom = { id: payload.fenceId, ...polygon };
        }
        // rectangle
        if (payload.fenceType === 2 && payload.rectangle) {
          const rect = payload.rectangle;
          const p01 = [rect[2].lng, rect[2].lat];
          const p03 = [rect[1].lng, rect[1].lat];
          const p05 = [rect[0].lng, rect[0].lat];
          const p07 = [rect[3].lng, rect[3].lat];
          const p00 = [(p01[0] + p07[0]) / 2, (p01[1] + p07[1]) / 2];
          const p02 = [(p01[0] + p03[0]) / 2, (p01[1] + p03[1]) / 2];
          const p04 = [(p05[0] + p03[0]) / 2, (p05[1] + p03[1]) / 2];
          const p06 = [(p05[0] + p07[0]) / 2, (p05[1] + p07[1]) / 2];

          const polygon = turf.polygon(
            [[p00, p01, p02, p03, p04, p05, p06, p07, p00]],
            { isRectangle: true }
          );
          geom = { id: payload.fenceId, ...polygon };
        }
        //circle
        if (payload.fenceType === 3 && payload.circle) {
          const circle = payload.circle;
          const center = [circle[0].lng, circle[0].lat];
          const radiusInKm = circle[0].radius / 1000;
          const c = turf.circle(center, radiusInKm, {
            units: "kilometers",
            properties: {
              center,
              radiusInKm,
              isCircle: true,
            },
          });
          geom = { id: payload.fenceId, ...c };
        }
        // line
        if (payload.fenceType === 4 && payload.polyline) {
          const coordinates = _.map(payload.polyline, (p) => [p.lng, p.lat]);
          const polyline = turf.lineString(coordinates);
          geom = { id: payload.fenceId, ...polyline };
        }

        state.drawingGeometry = geom;
        state.undoList = [geom];
        state.drawingMode = FenceTypeName[payload.fenceType];
      }
    },
    setDrawingMode: (state, { payload }: PayloadAction<DrawingMode>) => {
      state.drawingMode = payload;
    },
    setUndoList: (
      state,
      { payload }: PayloadAction<turf.helpers.Feature<any, any>[]>
    ) => {
      state.undoList = payload;
      state.redoList = [];
    },
    popUndoList: (state) => {
      const undo = _.last(state.undoList);
      state.undoList = [..._.initial(state.undoList)];
      if (undo) {
        state.redoList = [...state.redoList, undo];
      }
    },
    popRedoList: (state) => {
      const redo = _.last(state.redoList);
      state.redoList = [..._.initial(state.redoList)];
      if (redo) {
        state.undoList = [...state.undoList, redo];
      }
    },
    clearUndoList: (state) => {
      state.undoList = [];
    },
    clearRedoList: (state) => {
      state.redoList = [];
    },
    setDrawingGeometry: (
      state,
      { payload }: PayloadAction<turf.helpers.Feature<any, any> | undefined>
    ) => {
      state.drawingGeometry = payload;
    },
    setFillColor: (state, { payload }: PayloadAction<string>) => {
      state.fillColor = payload;
    },
    setFillOpacity: (state, { payload }: PayloadAction<number>) => {
      state.fillOpacity = payload;
    },
    setShowDetail: (state, { payload }: PayloadAction<boolean>) => {
      state.showDetail = payload;
    },
    reset: (state) => {
      state.fillColor = "#F21212";
      state.fillOpacity = 0.5;
    },
    requestRedrawing: (state) => {
      state.redrawing = {};
    },
    setOpenAdjustPriority: (state, { payload }: PayloadAction<boolean>) => {
      state.openAdjustPriority = payload;
    },
  },
});

export const {
  clearGeofence,
  loadGeofences,
  successLoadGeofences,
  successLoadSpeedGeofences,  
  addGeofence,
  updateGeofence,
  successRequestGeofence,
  deleteGeofence,
  clearLoading,
  setCurrentGeofence,
  setDrawingMode,
  setUndoList,
  setDrawingGeometry,
  setFillColor,
  setFillOpacity,
  popUndoList,
  popRedoList,
  clearUndoList,
  clearRedoList,
  setShowDetail,
  reset,
  requestRedrawing,
  setSpeedGeofences,
  saveSpeedPriority,
  setOpenAdjustPriority,
} = slice.actions;
export const GEOFENCE = slice.name;
export default slice.reducer;
