import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPermissionState, PermissionProps } from "@thingsw/pitta-modules";

const initialState: IPermissionState = {
  permission: {
    rebootCamPerm: false,
    uploadVodPerm: false,
    // mantis - 8882, 권한에 따라 다운로드 가능하도록하기 위해 2개로 분리(Leehj)
    downloadCameraVodPerm: false,
    downloadCloudVodPerm: false,
    deleteVodPerm: false,
    moveToCloudVodPerm: false,
    cameraVodPerm: false,
    cloudVodPerm: false,

    fotaCamPerm: false,
    settingsCamPerm: false,
    renameCamPerm: false,
    deleteCamPerm: false,

    gpsTrackingPerm: false,
    geofencePerm: false,
    reportPerm: false,
    liveviewPerm: false,
    multiLiveviewPerm: false,

    privacyPerm: false,
    pushNotificationsPerm: false,
    eventAutoUploadPerm: false,
    firmwareSettingPerm: false,

    downloadCSVReportPerm: false,

    // Playback more Tab 권한 추가
    moreTabPerm: false,
    shareToEventmapPerm: false,
  },
};

const slice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    clearPermissions: (state) => {
      state.permission = {
        rebootCamPerm: false,
        uploadVodPerm: false,
        // mantis - 8882, 권한에 따라 다운로드 가능하도록하기 위해 2개로 분리(Leehj)
        downloadCameraVodPerm: false,
        downloadCloudVodPerm: false,
        deleteVodPerm: false,
        moveToCloudVodPerm: false,
        cameraVodPerm: false,
        cloudVodPerm: false,

        fotaCamPerm: false,
        settingsCamPerm: false,
        renameCamPerm: false,
        deleteCamPerm: false,

        gpsTrackingPerm: false,
        geofencePerm: false,
        reportPerm: false,
        liveviewPerm: false,
        multiLiveviewPerm: false,

        privacyPerm: false,
        pushNotificationsPerm: false,
        eventAutoUploadPerm: false,
        firmwareSettingPerm: false,

        downloadCSVReportPerm: false,

        // Playback more Tab 권한 추가
        moreTabPerm: false,
        shareToEventmapPerm: false,
      };
    },
    setPermission: (state, { payload }: PayloadAction<PermissionProps>) => {
      state.permission = payload;
    },
  },
});

export const { clearPermissions, setPermission } = slice.actions;
export const PERMISSION = slice.name;
export default slice.reducer;
