import { Action } from "@reduxjs/toolkit";
import _ from "lodash";
import { buffers } from "redux-saga";

export const PriorityBuffer = (
  initialSize: number,
  highPrioActions: Action[],
  loPrioActions: Action[]
) => {
  const highPrioBuffer = buffers.expanding<Action>(initialSize);
  const loPrioBuffer = buffers.expanding<Action>(initialSize);

  const put = (it: Action) => {
    if (_.find(highPrioActions, (action) => action.type === it.type)) {
      highPrioBuffer.put(it);
    }
    if (_.find(loPrioActions, (action) => action.type === it.type)) {
      loPrioBuffer.put(it);
    }
  };
  const take = () => {
    if (!highPrioBuffer.isEmpty()) {
      return highPrioBuffer.take();
    }
    if (!loPrioBuffer.isEmpty()) {
      return loPrioBuffer.take();
    }
  };
  const flush = () => {
    const items = highPrioBuffer.flush();
    items.concat(loPrioBuffer.flush());
    return items;
  };
  const isEmpty = () => {
    return highPrioBuffer.isEmpty() && loPrioBuffer.isEmpty();
  };

  return {
    put,
    take,
    flush,
    isEmpty,
  };
};
