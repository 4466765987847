import { Card, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Button, Switch, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../CameraPlaybackScreen";
import { useCallback, useEffect, useState } from "react";
import { CAMERA, loadCameras } from "../../features/Camera/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import * as Api from "../../apis";

import _ from "lodash";
import { openToast } from "../../features/Toast/slice";
import { PrivacyModal } from "../../components/cameras/PrivacyModal";
import { TimeSyncModal } from "../../components/cameras/TimeSyncModal";
import clsx from "clsx";
import { Prompt, useHistory } from "react-router-dom";
import { DiscardChangeUXUIModal } from "../../components/uxui/DiscardChangeUXUIModal";
import {
  Webviewer,
  ICameraInfo,
  OnOffType,
  RESULT_CODE,
  LightColors,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#E8E8E8",
    minHeight: "100%",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: "calc(var(--vh, 1vh) * 100 - 101px)",
    },
  },

  container: {
    padding: "24px 16px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 24,
    },
  },

  title: {
    marginBottom: 15,
    marginLeft: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 20,
    },
  },

  content: {
    padding: "0px 27px 0px 19px",
    marginBottom: 9,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0px 28px 0px 20px",
      marginBottom: 8,
    },
  },

  contentInfo: {
    padding: "0px 23px 0px 24px",
    marginTop: 9,
    marginBottom: 7,
    lineHeight: "14px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 671,
    },
  },

  card: {
    display: "flex",
    minHeight: 48,
    alignItems: "center",
    padding: "4px 20px 4px 24px",
    borderRadius: 12,
    maxWidth: 671,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  accessCard: {
    display: "flex",
    minHeight: 48,
    alignItems: "center",
    padding: "9px 20px 8px 24px",
    borderRadius: 12,
    maxWidth: 671,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "7px 20px 6px 24px",
    },
  },

  shareLiveViewCard: {
    padding: "12px 20px 14px 24px",
  },

  autoCard: {
    display: "flex",
    minHeight: 48,
    alignItems: "center",
    padding: "9px 20px 8px 24px",
    borderRadius: 12,
    maxWidth: 671,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "11px 20px 10px 24px",
    },
  },

  cardContainer: {
    marginBottom: 8,
  },

  flex: {
    display: "flex",
    alignItems: "center",
  },

  colum: {
    display: "flex",
    flexDirection: "column",
  },

  switch: {
    marginRight: 19,
    height: 38,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
  },

  privateDivider: {
    margin: "11px 0px 16px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "14px 0 16px",
      maxWidth: 671,
    },
  },

  divider: {
    margin: "15px 0 17px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "15px 0 16px",
      maxWidth: 671,
    },
  },

  button: {
    marginTop: 8,
  },

  typoAccess: {
    color: "#7E7E83 !important",
    paddingTop: 3,
  },
  disabledText: {
    opacity: 0.35,
  },
}));

export const CameraPrivacySettingScreen = (
  props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { camera, cameraList, cameraTimeSync } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);
  const [cameraInfo, setCameraInfo] = useState<ICameraInfo>();

  const [loading, setLoading] = useState(false);
  const [agree_gps, setAgreeGPS] = useState(false);
  const [share_gps, setShareGPS] = useState(false);
  const [share_video, setShareVideo] = useState(false);
  const [share_audio, setShareAudio] = useState(false);
  const [share_leu_bigdata, setShareLeuBigdata] = useState(false);
  const [share_dev_name, setShareDevName] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [openNoPermModal, setOpenNoPermModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);

  const [targetLocation, setTargetLocation] = useState<Location>();
  const [openTimeSyncModal, setOpenTimeSyncModal] = useState(false);

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  useEffect(() => {
    if (!camera) return;
    // Time Sync 안내 팝업 한번만 노출
    const noRemind = localStorage.getItem("pitta-webviewer:time-sync-notice");
    if (cameraTimeSync && !noRemind) {
      localStorage.setItem("pitta-webviewer:time-sync-notice", "true");
      setOpenTimeSyncModal(true);
    } else if (cameraTimeSync && noRemind === "true") {
      setOpenTimeSyncModal(true);
    }
  }, [cameraTimeSync, camera]);

  useEffect(() => {
    if (!disableSave) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
        // 새로고침이나 창 종료시에는 해당 모달 출력 방지
        setOpenDiscardModal(false);
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disableSave]);

  const getBoolean = useCallback((val: "on" | "off" | undefined) => {
    return val === "on" ? true : false;
  }, []);

  useEffect(() => {
    const cam = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === camera?.psn)
      .value();
    setCameraInfo((c) => {
      if (c?.psn !== cam?.psn) {
        return cam;
      } else if (c?.active !== cam?.active) {
        return cam;
        // mantis - 9593, Allow GPS access 체크가 변경된 상태로 보여지지 않는 이슈 수정 / 변경된 상태가 있는 경우 cam으로 set(Leehj)
        // 저장시 체크 깜빡이는 이슈 - return c;로 항상 빠지는 것 방지하여 else안으로 실행문 이동.(Leehj)
      } else if (
        c?.agree_gps !== cam?.agree_gps ||
        c?.share_gps !== cam?.share_gps ||
        c?.share_video !== cam?.share_video ||
        c?.share_audio !== cam?.share_audio ||
        c?.share_dev_name !== cam?.share_dev_name ||
        c?.share_leu_bigdata !== cam?.share_leu_bigdata
      ) {
        return cam;
      } else {
        return c;
      }
    });
  }, [camera?.psn, cameraList?.deviceListInfo]);

  const getOnOff = (val: boolean): OnOffType => {
    return val ? "on" : "off";
  };

  useEffect(() => {
    setAgreeGPS(getBoolean(cameraInfo?.agree_gps));
    setShareGPS(getBoolean(cameraInfo?.share_gps));
    setShareVideo(getBoolean(cameraInfo?.share_video));
    setShareAudio(getBoolean(cameraInfo?.share_audio));
    setShareDevName(getBoolean(cameraInfo?.share_dev_name));
    setShareLeuBigdata(getBoolean(cameraInfo?.share_leu_bigdata));
  }, [getBoolean, cameraInfo]);

  useEffect(() => {
    if (
      getBoolean(cameraInfo?.agree_gps) !== agree_gps ||
      getBoolean(cameraInfo?.share_gps) !== share_gps ||
      getBoolean(cameraInfo?.share_video) !== share_video ||
      getBoolean(cameraInfo?.share_audio) !== share_audio ||
      getBoolean(cameraInfo?.share_leu_bigdata) !== share_leu_bigdata ||
      getBoolean(cameraInfo?.share_dev_name) !== share_dev_name
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [
    getBoolean,
    agree_gps,
    share_gps,
    share_video,
    share_audio,
    share_dev_name,
    share_leu_bigdata,
    cameraInfo?.agree_gps,
    cameraInfo?.share_gps,
    cameraInfo?.share_video,
    cameraInfo?.share_audio,
    cameraInfo?.share_leu_bigdata,
    cameraInfo?.share_dev_name,
  ]);

  const handleUpdate = useCallback(async () => {
    if (cameraInfo && email && loginInfo) {
      try {
        setDisableSave(true);
        setLoading(true);

        const payload = {
          psn: cameraInfo.psn,
          agree_gps: getOnOff(agree_gps),
          share_gps: getOnOff(share_gps),
          share_video: getOnOff(share_video),
          share_audio: getOnOff(share_audio),
          share_dev_name: getOnOff(share_dev_name),
          share_leu_bigdata: getOnOff(share_leu_bigdata),
        };

        const resp = await Api.updatePrivacy(
          email,
          loginInfo.user_token,
          cameraInfo.psn,
          payload.agree_gps,
          payload.share_gps,
          payload.share_audio,
          payload.share_video,
          payload.share_dev_name,
          payload.share_leu_bigdata
        );
        const { resultcode, response } = resp.data as {
          resultcode: RESULT_CODE;
          message: string;
          response?: {
            reason:
              | "NO_PERMISSION_PRIVACY"
              | "NO_PERMISSION_SHARING_VIDEO_TO_EVENTMAP";
          };
        };
        if (resultcode === "BC_ERR_OK") {
          if (response?.reason) {
            if (
              response.reason === "NO_PERMISSION_SHARING_VIDEO_TO_EVENTMAP" &&
              payload.share_leu_bigdata !== cameraInfo?.share_leu_bigdata
            ) {
              // console.log("SHARING_VIDEO_TO_EVENTMAP", response);
              setOpenNoPermModal(true);
            }
            if (
              response.reason === "NO_PERMISSION_PRIVACY" &&
              payload.share_leu_bigdata === cameraInfo?.share_leu_bigdata
            ) {
              // console.log("PRIVACY", response);
              setOpenNoPermModal(true);
            }
          } else {
            dispatch(openToast({ message: "Camera settings saved_" }));
          }
        } else if (resultcode === "BC_ERR_PERMISSION") {
          setOpenNoPermModal(true);
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(loadCameras());
        setLoading(false);
      }
    }
  }, [
    cameraInfo,
    email,
    loginInfo,
    agree_gps,
    share_gps,
    share_video,
    share_audio,
    share_dev_name,
    share_leu_bigdata,
    dispatch,
  ]);

  return (
    <div className={classes.root}>
      <Prompt
        when={!disableSave && !targetLocation}
        message={(location: any) => {
          setTargetLocation(location);
          if (location.pathname.indexOf("settings") === -1) {
            setOpenDiscardModal(true);
            return false;
          } else {
            return true;
          }
        }}
      />
      <div className={classes.container}>
        <div className={clsx(classes.flex, classes.title)}>
          <Typography category="Default" variant="H6">
            {t("Privacy")}
          </Typography>
        </div>
        <div
          className={classes.cardContainer}
          style={{ marginBottom: mobile ? 17 : 16 }}
        >
          <div className={classes.content}>
            <Typography category="Default" variant="SmallBold">
              {t("Private_2")}
            </Typography>
          </div>
          <Card className={classes.accessCard}>
            <div className={classes.switch}>
              <Switch
                checked={agree_gps}
                onChange={(_e, checked) => {
                  setAgreeGPS(checked);
                  if (agree_gps === !checked) {
                    setShareGPS(false);
                  }
                  // uxui 설정3차수정 - 비활성화 된 경우 on상태로 비활성화 되는 이슈 수정(Leehj)
                  if (!checked) {
                    setShareVideo(false);
                    setShareAudio(false);
                    setShareDevName(false);
                    // GPS acess OFF설정시 이벤트맵 자동공유 설정을 OFF시키고 비활성화 처리 (Leehj)
                    setShareLeuBigdata(false);
                  }
                }}
              />
            </div>
            <div className={classes.colum}>
              <Typography category="Default" variant="Body">
                {t("Allow GPS access")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                className={classes.typoAccess}
              >
                {t("If not allowed_2")}
              </Typography>
            </div>
          </Card>
        </div>

        <Divider className={classes.privateDivider} />

        <div>
          <div className={classes.cardContainer}>
            <div className={classes.content} style={{ marginBottom: 8 }}>
              <Typography category="Default" variant="SmallBold">
                {t("Public")}
              </Typography>
            </div>
            <Card className={classes.card}>
              <div className={classes.switch}>
                <Switch
                  // Allow GPS access off인경우 해당 항목 비활성화 처리(Leehj) 23.01.09
                  disabled={!agree_gps}
                  checked={share_gps}
                  onChange={(_e, checked) => {
                    if (agree_gps === !checked) {
                      setShareGPS(false);
                    } else {
                      setShareGPS(checked);
                    }
                    // uxui 설정3차수정 - 비활성화 된 경우 on상태로 비활성화 되는 이슈 수정(Leehj)
                    if (!checked) {
                      setShareVideo(false);
                      setShareAudio(false);
                      setShareDevName(false);
                    } else {
                      // uxui 설정3차수정 - Share location on으로 변경할때, Share camera profile도 on으로 적용(Leehj)
                      if (agree_gps) {
                        setShareDevName(true);
                      }
                    }
                  }}
                />
              </div>
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                  className={clsx(!agree_gps && classes.disabledText)}
                >
                  {t("Share location")}
                </Typography>
              </div>
            </Card>
          </div>
          <div className={classes.cardContainer}>
            <Card className={clsx(classes.card, classes.shareLiveViewCard)}>
              <div className={classes.colum}>
                <div className={classes.flex} style={{ paddingBottom: 10 }}>
                  <div className={classes.switch}>
                    <Switch
                      disabled={!share_gps}
                      checked={share_video}
                      onChange={(_e, checked) => {
                        if (agree_gps === !checked) {
                          setShareVideo(false);
                        } else {
                          setShareVideo(checked);
                        }
                        // uxui 설정3차수정 - 비활성화 된 경우 on상태로 비활성화 되는 이슈 수정(Leehj)
                        if (!checked) {
                          setShareAudio(false);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      category="Default"
                      variant="Body"
                      // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                      className={clsx(!share_gps && classes.disabledText)}
                    >
                      {t("Share Live View_")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.flex}>
                  <div className={classes.switch}>
                    <Switch
                      // KimSeongHyun
                      // Share location 비활성화 시 버튼 비활성화 추가
                      disabled={!share_gps || !share_video}
                      checked={share_audio}
                      onChange={(_e, checked) => {
                        if (agree_gps === !checked) {
                          setShareAudio(false);
                        } else {
                          setShareAudio(checked);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      category="Default"
                      variant="Body"
                      // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                      className={clsx(
                        (!share_gps || !share_video) && classes.disabledText
                      )}
                    >
                      {t("Share Live View_2")}
                    </Typography>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={classes.cardContainer}>
            <Card className={classes.card}>
              <div className={classes.switch}>
                <Switch
                  checked={share_dev_name}
                  disabled={!share_gps}
                  onChange={(_e, checked) => setShareDevName(checked)}
                />
              </div>
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                  className={clsx(!share_gps && classes.disabledText)}
                >
                  {t("Share camera profile")}
                </Typography>
              </div>
            </Card>
            <div className={classes.contentInfo}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                className={clsx(!agree_gps && classes.disabledText)}
              >
                {t("By sharing your_")}
              </Typography>{" "}
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                className={clsx(!agree_gps && classes.disabledText)}
              >
                {t("However, as persona_")}
              </Typography>{" "}
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                className={clsx(!agree_gps && classes.disabledText)}
              >
                {t("Public cameras may_")}
              </Typography>
            </div>
          </div>
        </div>
        {/* <Divider className={classes.divider} />
        <div className={classes.cardContainer}>
          <Card className={classes.autoCard}>
            <div className={classes.switch}>
              <Switch
                // GPS acess OFF설정시 이벤트맵 자동공유 설정을 OFF시키고 비활성화 처리 (Leehj)
                disabled={!props.shareToEventmapPerm || !agree_gps}
                checked={share_leu_bigdata}
                onChange={(_e, checked) => {
                  setShareLeuBigdata(checked);
                }}
              />
            </div>
            <div className={classes.colum}>
              <Typography
                category="Default"
                variant="Body"
                // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                className={clsx(
                  (!props.shareToEventmapPerm || !agree_gps) &&
                    classes.disabledText
                )}
              >
                {t("Automatically Add Live_")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                style={{ paddingTop: 3 }}
                // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
                className={clsx(
                  (!props.shareToEventmapPerm || !agree_gps) &&
                    classes.disabledText
                )}
              >
                {t("If you want to remove videos_")}
              </Typography>
            </div>
          </Card>
        </div> */}
        <Button
          className={classes.button}
          color="primary"
          onClick={handleUpdate}
          disabled={disableSave}
          loading={loading}
        >
          {t("Save")}
        </Button>
      </div>
      <PrivacyModal
        open={openNoPermModal}
        onClose={() => setOpenNoPermModal(false)}
        onClickPositive={() => setOpenNoPermModal(false)}
      />
      <TimeSyncModal
        open={openTimeSyncModal}
        onClose={() => setOpenTimeSyncModal(false)}
        onClickPositive={() => {
          localStorage.setItem("pitta-webviewer:time-sync-notice", "false");
          setOpenTimeSyncModal(false);
          if (camera) history.push(`/cameras/${camera.psn}/settings/system`);
        }}
        guideType="feature"
      />
      {openDiscardModal && (
        <DiscardChangeUXUIModal
          open={openDiscardModal}
          onClose={() => {
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          // mantis - 11445, 디자인 변경되면서 버튼 기능도 변경 (Leehj)
          onClickNegative={() => {
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          onClickPositive={() => {
            handleUpdate();
            if (targetLocation) {
              history.push(targetLocation.pathname);
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
        />
      )}
    </div>
  );
};
