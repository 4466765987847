import React from "react";
import { Button, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";
import { RootState } from "../../features/store";
import { GEOFENCE } from "../../features/Geofence/slice";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  subHeaderDiv: {
    display: "flex",
    width: "100%",
    margin: "0 7px",
    zIndex: 90,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  subHeaderDivClosed: {
    left: 73,
  },
  subHeaderDivMobile: {
    left: 0,
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    height: 56,
    backgroundColor: LightColors.primary["0"],
  },
  btnDiv: {
    transition: theme.transitions.create("width"),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: "auto",
    },
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(3) }
      : { marginRight: theme.spacing(3) }),
  },
  addBtn: {
    borderRadius: 12,
    minWidth: 158,
    margin: theme.spacing(2, 0),
  },
}));

interface GeofenceSubHeaderProps {
  openMenu: boolean;
  disabled: boolean;
}

export const GeofenceSubHeader = ({
  openMenu,
  disabled,
}: GeofenceSubHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const history = useHistory();
  const { geofences } = useSelector((state: RootState) => state[GEOFENCE]);

  return (
    <div
      className={clsx(classes.subHeaderDiv, {
        [classes.subHeaderDivClosed]: !openMenu,
        [classes.subHeaderDivMobile]: mobile,
      })}
    >
      <div className={classes.subHeader}>
        <div className={clsx(classes.btnDiv)}>
          <Button
            startIcon={<AddIcon />}
            color="primary"
            onClick={() => history.push("/geofences/add-geofence")}
            className={clsx(classes.addBtn)}
            disabled={disabled || geofences.length >= 20}
          >
            {t("Add geofence")}
          </Button>
        </div>
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.primary["2"]}
        >
          {geofences.length} / 20
        </Typography>
      </div>
    </div>
  );
};
