import { BatteryBaseInfo, IBatteryInfo } from "@thingsw/pitta-modules";
import moment from "moment";
import { useMemo } from "react";

type UseCalculateBatteryPercentProps = {
  battery: BatteryBaseInfo | undefined;
  batteryInfo: IBatteryInfo;
};

const useCalculateBatteryPercent = ({
  battery,
  batteryInfo,
}: UseCalculateBatteryPercentProps) => {
  const isCurrentCam = battery?.ssid === batteryInfo?.ssid;

  const calculateBatteryPercent = useMemo(() => {
    if (!isCurrentCam) {
      return battery?.battery_percent || 0;
    }

    const cameraDataTime = new Date(battery?.createdAt);
    const batteryDataTime = new Date(batteryInfo?.date);

    const momentCameraDataTime = moment(cameraDataTime);
    const momentBatteryDataTime = moment(batteryDataTime);

    if (momentCameraDataTime.isBefore(momentBatteryDataTime)) {
      return batteryInfo?.percent;
    } else {
      return battery?.battery_percent;
    }
  }, [battery, batteryInfo, isCurrentCam]);

  return calculateBatteryPercent;
};

export default useCalculateBatteryPercent;
