import React, { useState, useRef, useEffect, } from "react";
import {
  GeofenceCircle,
  GeofencePolygon,
  GeofencePolyline,
  GeofenceRectangle,
  IconButton,
  Typography,
} from "@thingsw/pitta-design-system";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";
import { IGeofence, LightColors } from "@thingsw/pitta-modules";
import clsx from "clsx";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export interface GeofenceListItemProps {
  geofence: IGeofence;
  onClick: () => void;
  onClickMore: (ref: React.RefObject<HTMLButtonElement>) => void;
  openAdjustPriority: boolean;
  velocityUnit?: string;
  adjustPriority: (fence: IGeofence, type: "up" | "down") => void;
  geofences?: IGeofence[],
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {    
    padding: theme.spacing(1.25, 0.75, 0.75, 1.25),
    "&.pointer": {
      cursor: "pointer",
    },
    "&.over:hover": {
      backgroundColor: LightColors.primary["10"],
    },
  },
  geofenceTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  geofenceTitle: {
    display: "flex",
    alignItems: "center",
  },
  geofencePriorityNo: {
    minWidth: 16,
    textAlign: "center",
  },
  shapeIcon: {
    width: 18,
    height: 24,
    margin: theme.spacing(0, 1),
  },
  camDiv: {
    margin: theme.spacing(0.25, 4.5) 
  },
  priorityBtnDiv: {
    color: "#7E7E83",
    height: 30,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      width: 24,
      height: 24,
    },
    "& .disabled": {
      color: "#D4D4D5",
    },
  },
}));

export const GeofenceListItem = ({
  geofence,
  onClick,
  onClickMore,
  openAdjustPriority,
  velocityUnit,
  adjustPriority,
  geofences,
}: GeofenceListItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [speed, setSpeed] = useState<string>("");
  const [camCount, setCamCount] = useState<number>(0);
  const moreDivRef = useRef<HTMLButtonElement>(null);
  
  useEffect(() => {
    if(!geofence) return;
    geofence.newPsn 
      ? setCamCount([...geofence.psn, ...geofence?.newPsn].length)
      : setCamCount(geofence.psn.length)
  }, [geofence]);

  useEffect(() => {
    if (!velocityUnit || !geofence) return;
    if (velocityUnit === "0" && geofence.speed?.Km) {
      setSpeed(`${geofence.speed.Km} Km/h`) 
    } else if (velocityUnit === "1" && geofence.speed?.Mph) {
      setSpeed(`${geofence.speed.Mph} Mph`) 
    }
  }, [geofence, velocityUnit]);

  return (
    <div
      className={clsx(classes.root, {
        "pointer": !openAdjustPriority,
        "over": !openAdjustPriority,
      })}
      dir={theme.direction}
      onClick={(e) => {
        if (!openAdjustPriority) onClick();
      }}
    >
      <div className={classes.geofenceTitleDiv}>
        <div className={classes.geofenceTitle}>
          {openAdjustPriority && (
            <Typography
              className={classes.geofencePriorityNo}
              category="Default" 
              variant="Body"
            >
              {geofence?.priority}
            </Typography>
          )}
          {geofence?.fenceType === 1 && (
            <GeofencePolygon
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          {geofence?.fenceType === 2 && (
            <GeofenceRectangle
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          {geofence?.fenceType === 3 && (
            <GeofenceCircle
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          {geofence?.fenceType === 4 && (
            <GeofencePolyline
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          <Typography category="Default" variant="Body">
            {geofence?.fenceName}
          </Typography>
        </div>
        {openAdjustPriority ? (
          <div className={classes.priorityBtnDiv}>
            <KeyboardArrowUpIcon
              className={clsx({
                "disabled": geofence?.priority === 1,
              })}
              onClick={() => adjustPriority(geofence, "up")}
            />
            <KeyboardArrowDownIcon
              className={clsx({
                "disabled": geofence?.priority === geofences?.length,
              })}
               onClick={() => adjustPriority(geofence, "down")}
            />
          </div>
        ) : (
          <IconButton
            style={{ padding: 0 }}
            ref={moreDivRef}
            onClick={(e) => {
              e.stopPropagation();
              onClickMore(moreDivRef);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}        
      </div>
      <div className={classes.camDiv}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {t("n cameras", { n: camCount })}
        </Typography>
        {speed && (
          <>
             <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={LightColors.primary["5"]}
              style={{ marginLeft: 4 }}
            >
              &#8226;
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginLeft: 4 }}
            >
              {speed}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
