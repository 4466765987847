import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initGPSWs, LOCATION_WS } from "../../features/LocationWS/slice";
import { RootState } from "../../features/store";
import { loadUsageInfo, USER } from "../../features/User/slice";
import React from "react";
import {
  disableRotate,
  getFirmwareConfig,
  ICameraInfo,
  sendMessageBack,
} from "@thingsw/pitta-modules";
import { MapboxLocationMapWrapper } from "../../components/maps/MapboxLocationMapWrapper";
import {
  CAMERA,
  clearPublicCamera,
  loadCameras,
  loadPublicCamera,
} from "../../features/Camera/slice";
import _ from "lodash";
import { LiveViewMapboxModal } from "../../components/uxui/LiveViewMapboxModal";
import { getCamInfo } from "../../apis";
import { detect } from "detect-browser";
import MapboxOnboarding from "../../components/onboarding/MapboxOnboarding";
import { setRemovePlayer } from "../../features/LiveView/slice";
import { PAYMENT } from "../../features/Payment/slice";

interface MapViewScreenProps {
  showOnboarding?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    height: "calc(var(--vh, 1vh) * 100)",
  },
}));

export const MapViewScreen = ({ showOnboarding }: MapViewScreenProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const mapRef = useRef<HTMLDivElement>(null);

  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const { cameraList, publicCamera } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const { gpsWsReadyState } = useSelector(
    (state: RootState) => state[LOCATION_WS]
  );
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const [filteredCams /*, setFilteredCams*/] = useState<ICameraInfo[]>();
  const [publicCam, setPublicCam] = useState(false);
  const [liveCam, setLiveCam] = useState<ICameraInfo>();
  const [openLiveModal, setOpenLiveModal] = useState(false);

  // 라이브뷰용 카메라 목록, 백그라운드에서 cameraList가 계속 업데이트 되서, handleLiveView가 변경되어 LocationMap이 반복적으로 업데이트되는 문제 수정
  // 위 이슈로 인해서 지도상의 아이콘 동작이 이상함
  const [liveCams, setLiveCams] = useState<ICameraInfo[]>([]);

  useEffect(() => {
    if (
      subscriptionInfo?.servicePlanName &&
      (subscriptionInfo?.servicePlanName === "free" ||
        subscriptionInfo?.servicePlanName.indexOf("lite") > -1)
    ) {
      dispatch(loadUsageInfo());
    }
  }, [dispatch, subscriptionInfo?.servicePlanName]);

  useEffect(() => {
    setLiveCam(publicCamera);
  }, [publicCamera]);

  useEffect(() => {
    if (
      cameraList?.deviceListInfo &&
      liveCams.length !== cameraList.deviceListInfo.length
    ) {
      setLiveCams(_.map(cameraList.deviceListInfo, (dev) => dev.device));
    }
  }, [cameraList?.deviceListInfo, liveCams.length]);

  // Cloud Native 2차 대시캠 위치 얻어오는 websocket 처리
  useEffect(() => {
    console.log("GPS_WS", "initGPSWs", loginInfo, email, gpsWsReadyState);
    if (loginInfo && email && gpsWsReadyState === WebSocket.CLOSED) {
      console.log("GPS_WS", "initGPSWs");
      dispatch(initGPSWs());
      const interval = setInterval(() => {
        console.log("GPS_WS", "initGPSWs");
        dispatch(initGPSWs());
      }, 10 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, email, gpsWsReadyState, loginInfo]);
  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  const closeLiveview = useCallback(() => {
    setOpenLiveModal(false);
    setLiveCam(undefined);
    dispatch(clearPublicCamera());
    disableRotate();
    dispatch(setRemovePlayer(false));
  }, [dispatch]);

  //   const handleLiveView = useCallback((psn: string, isPublic: boolean) => {
  //     const browser = detect();
  //     const msg = {
  //       psn,
  //       isPublic,
  //     };
  //     if (browser?.name === "ios-webview") {
  //       //@ts-ignore
  //       return webkit.messageHandlers.openLiveview.postMessage(msg);
  //     }
  //     if (browser?.name === "chromium-webview") {
  //       //@ts-ignore
  //       window.Webviewer?.openLiveview(JSON.stringify(msg));
  //     }
  //   }, []);

  const handleLiveView = useCallback(
    async (psn: string, isPublic: boolean) => {
      console.log("onLiveView", psn, isPublic);
      if (!email || !loginInfo) return;
      const resp = await getCamInfo(
        email,
        loginInfo?.user_token,
        psn,
        tokenType
      );

      const config = getFirmwareConfig(resp.data.response["device info"]);
      const cloudNative = !!config?.CloudNative;
      // console.log(
      //   "handleLiveView",
      //   resp.data.response["device info"],
      //   config,
      //   cloudNative
      // );
      if (!cloudNative) {
        const browser = detect();
        const msg = {
          psn,
          isPublic,
        };
        if (browser?.name === "ios-webview") {
          //@ts-ignore
          return webkit.messageHandlers.openLiveview.postMessage(msg);
        }
        if (browser?.name === "chromium-webview") {
          //@ts-ignore
          window.Webviewer?.openLiveview(JSON.stringify(msg));
        }
      } else {
        setPublicCam(isPublic);
        if (isPublic) {
          dispatch(loadPublicCamera(psn));
          setOpenLiveModal(true);
        } else {
          const cam = _.find(liveCams, (dev) => dev.psn === psn);
          if (cam) {
            setLiveCam(cam);
            setOpenLiveModal(true);
          }
        }
      }
    },
    [dispatch, email, liveCams, loginInfo, tokenType]
  );

  return (
    <div className={classes.root} dir={theme.direction}>
      <MapboxLocationMapWrapper
        app
        filteredCams={filteredCams}
        onLiveView={handleLiveView}
        mapRef={mapRef}
        onClose={() => {
          sendMessageBack();
        }}
        // onChangeGroup 추가
        // 그룹id?이름?으로 CameraListPanelUXUI onUpdateFilteredCams처럼 구현
      />
      <LiveViewMapboxModal
        open={openLiveModal}
        camera={liveCam}
        isPublic={publicCam}
        fullScreen={true}
        noMargin
        mobile
        app
        onClose={() => {
          closeLiveview();
        }}
        container={mapRef.current}
      />
      <MapboxOnboarding isFirstDevice={showOnboarding} />
    </div>
  );
};
