import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Theme,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  RadioButton,
  Typography,
} from "@thingsw/pitta-design-system";

import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { CAMERA, updateFirmwareSettings } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
import {
  LightColors,
  Webviewer,
  TabNameInfo4,
  TabNameInfo5,
  TrueFalsetype,
  NON_WIFI_SLEEP_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 372,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  boderNoneBox: {
    width: "100%",
    minWidth: 288,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  boderBottomBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "12px 16px",
    minWidth: 288,
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      padding: "12px 15px",
      maxWidth: 672,
    },
  },
  input: {
    minWidth: 256,
    paddingRight: 0,
    paddingBottom: 3,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: "max-content",
      maxWidth: 265,
    },
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  mgR16: {
    marginRight: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 16 } : { marginRight: 16 }),
      flex: "1",
      maxWidth: 265,
    },
  },
  mgNone: {
    margin: 0,
  },
  flex1: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flex: "1",
      maxWidth: 270,
    },
  },
  inputValueStyle: {
    // width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: "3px 16px 0px",
  },
}));

export const WifiFirmwarePanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { camera, firmware, firmwareConfig, loading } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [current, setCurrent] = useState<TabNameInfo4>();
  const [disabledChange, setDisabledChange] = useState(true);
  const [ssidError, setSsidError] = useState(false);
  const [pwError, setPwError] = useState(false);

  const settings = useMemo(() => {
    return firmwareConfig?.Wifi;
  }, [firmwareConfig?.Wifi]);

  const cloudSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Cloud")
      ?.section_info as TabNameInfo5;
  }, [firmware?.cloud_settings]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Wifi"
      );

      if (currentTab) {
        setCurrent(currentTab.section_info as TabNameInfo4);
      }
    }
  }, [firmware]);

  const checkSSID = useCallback(
    (value: string) => {
      const ssid = value;
      if (ssid.length === 0 && current?.ap_pw.length === 0) {
        setSsidError(false);
        setPwError(false);
        return true;
      } else if (ssid.length < 5) {
        setSsidError(true);
        return false;
      } else {
        setSsidError(false);
        return true;
      }
    },
    [current?.ap_pw.length]
  );

  const checkPW = useCallback(
    (value: string) => {
      const pw = value;
      if (pw.length === 0 && current?.ap_ssid.length === 0) {
        setSsidError(false);
        setPwError(false);
        return true;
      } else if (pw.length < 8) {
        setPwError(true);
        return false;
      } else {
        setPwError(false);
        return true;
      }
    },
    [current?.ap_ssid.length]
  );

  const getBoolean = (TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };
  const handleChange = (key: keyof TabNameInfo4, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo4));
  };

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Wifi"
      );

      const predicts = _.keys(currentTab?.section_info as TabNameInfo4).map(
        (k) => {
          const key = k as keyof TabNameInfo4;
          return (
            (currentTab?.section_info as TabNameInfo4)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [firmware, current]);

  const handleUpdate = () => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Wifi"
        ),
      };
      updated.cloud_settings = [
        ...updated.cloud_settings,
        { section_info: current, section_name: "Wifi" },
      ];
      if (!checkPW(current.ap_pw) && !checkSSID(current.ap_ssid)) {
        return;
      } else if (!checkPW(current.ap_pw) || !checkSSID(current.ap_ssid)) {
        return;
      } else {
        dispatch(updateFirmwareSettings({ firmware: updated }));
      }
    }
  };
  return (
    <>
      <div className={classes.bigBox}>
        {settings?.["LoginCredentials"] && (
          <>
            <div className={classes.boderNoneBox} style={{ paddingLeft: 16 }}>
              <Typography category="Default" variant="Body">
                {t("Login credentials")}
              </Typography>
            </div>
            <div className={classes.boderBottomBox}>
              <div className={clsx(classes.mgR16, classes.flex1)}>
                <Typography variant="Body" category="Default">
                  {t("SSID")}
                </Typography>
                <Input
                  value={current?.ap_ssid}
                  onChange={(e) => {
                    if (e.target.value.length <= 32) {
                      setCurrent((c) => {
                        return c && { ...c, ap_ssid: e.target.value };
                      });
                    }
                  }}
                  dense
                  className={classes.input}
                  error={ssidError}
                ></Input>
                {ssidError ? (
                  <FormHelperText
                    style={{
                      color: LightColors.secondary["11"],
                      margin: "3px 16px 0",
                    }}
                  >
                    {t("SSID must be_")}
                  </FormHelperText>
                ) : (
                  <div className={classes.inputValueStyle}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("5 to 32 characters long")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {current?.ap_ssid.length}/32
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.flex1}>
                <Typography variant="Body" category="Default">
                  {t("Password")}
                </Typography>
                <Input
                  value={current?.ap_pw}
                  onChange={(e) => {
                    if (e.target.value.length <= 32) {
                      setCurrent((c) => {
                        return c && { ...c, ap_pw: e.target.value };
                      });
                    }
                  }}
                  dense
                  password
                  className={classes.input}
                  error={pwError}
                ></Input>
                {pwError ? (
                  <FormHelperText
                    style={{
                      color: LightColors.secondary["11"],
                      margin: "3px 16px 0",
                    }}
                  >
                    {t("Password must be_")}
                  </FormHelperText>
                ) : (
                  <div className={classes.inputValueStyle}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("8 to 32 characters long")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {current?.ap_pw.length}/32
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {settings?.["WifiBand"] && (
          <>
            <div className={classes.boderNoneBox} style={{ paddingLeft: 16 }}>
              <Typography category="Default" variant="Body">
                {t("Wi-Fi band")}
              </Typography>
            </div>
            <div
              className={classes.boderNoneBox}
              style={{
                minHeight: 96,
                borderBottom: `1px solid ${LightColors.primary["6"]}`,
              }}
            >
              <FormControl style={{ paddingLeft: 10 }}>
                <RadioGroup
                  value={current?.WiFiBand ?? "0"}
                  onChange={(e) =>
                    setCurrent((c) => {
                      return (
                        c && { ...c, WiFiBand: e.target.value as TrueFalsetype }
                      );
                    })
                  }
                >
                  <div style={{ paddingBottom: 18 }}>
                    <RadioButton
                      style={{ padding: 3 }}
                      value="0"
                      label={
                        <Typography category="Default" variant="Body">
                          {t("5 GHz")}
                        </Typography>
                      }
                    />
                  </div>
                  <RadioButton
                    style={{ padding: 3 }}
                    value="1"
                    label={
                      <Typography category="Default" variant="Body">
                        {t("2.4 GHz")}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}
        {settings?.["onstart"] && (
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  disabled={cloudSettings.CloudService === "1"}
                  checked={getBoolean(current?.onstart)}
                  onChange={(e) => {
                    handleChange("onstart", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {/* {t("Turn on at startup")} */}
                  {t("Wi-Fi auto turn on")}
                </Typography>
              }
            />
          </div>
        )}
        {/* 
        mantis ID : 8653
        NON_WIFI_SLEEP_MODELS 외에 다른 모델들은 WifiSleepMode가 나타나도록 수정
        FW설정표(Sigmastar&DMC200)_20220315 참고
        */}
        {!_.includes(NON_WIFI_SLEEP_MODELS, camera?.model) &&
          settings?.["WifiSleepMode"] && (
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    disabled={cloudSettings?.CloudService === "1"}
                    checked={getBoolean(current?.WifiSleepMode)}
                    onChange={(e) => {
                      handleChange("WifiSleepMode", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Wi-Fi auto turn off")}
                  </Typography>
                }
              />
            </div>
          )}
        {settings?.["onstart_LTE"] && (
          <div
            className={classes.boderNoneBox}
            style={{ borderTop: `1px solid ${LightColors.primary["6"]}` }}
          >
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(current?.onstart_LTE)}
                  onChange={(e) => {
                    handleChange("onstart_LTE", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("LTE")}
                </Typography>
              }
            />
          </div>
        )}
      </div>
      <Button
        className={classes.buttonSize}
        variant="contained"
        color="primary"
        disabled={disabledChange}
        onClick={() => {
          console.log(current?.ap_ssid.length, "ssid");
          console.log(current?.ap_pw.length, "pw");
          handleUpdate();
        }}
        loading={loading}
      >
        {t("Save")}
      </Button>
    </>
  );
};
