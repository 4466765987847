import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Button, Modal, Typography } from "@thingsw/pitta-design-system";
import { useMediaQuery } from "@material-ui/core";
import SimpleBarReact from "simplebar-react";
import { Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 764,
  },
  title: {
    marginTop: theme.spacing(4) + 1,
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: theme.spacing(4) - 1,
      textAlign: "inherit",
    },
  },
  content: {
    marginTop: theme.spacing(2) + 2,
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      textAlign: "inherit",
    },
  },
  contentWrap: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  modalTitle: {
    minHeight: 16,
    paddingBottom: theme.spacing(2),
  },
  modalContentWrap: {
    display: "flex",
    border: "none",
    padding: theme.spacing(3.75, 1.875, 2, 1.875),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(3.25, 0, 2.75, 0),
    },
  },
  webBtnDiv: {
    marginTop: theme.spacing(5.75),
    display: "flex",
    width: "94%",
    justifyContent: "flex-end",
  },
  scrollbarStyle: {
    height: "100%",
    overflowX: "hidden",
    flex: 1,
    maxHeight: "calc(100vh - 250px)",
  },
}));

interface OnboardingStep1Props {
  open: boolean;
  onStartTour: () => void;
  onClose: () => void;
}

export const Onboarding = ({
  open,
  onStartTour,
  onClose,
}: OnboardingStep1Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  return (
    <Modal
      className={classes.root}
      open={open}
      heading={"     "}
      close
      onClose={onClose}
      content={
        <SimpleBarReact className={classes.scrollbarStyle}>
          <div className={classes.contentWrap}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt*/}
            <img
              srcSet="/images/onboarding.png 1x"
              alt="onboarding image"
              width={mobile ? "100%" : 670}
            />
            <Typography
              category="Default"
              variant="H3"
              className={classes.title}
            >
              {t("Welcome to the BlackVue_")}
            </Typography>
            <Typography
              category="Default"
              variant="Body"
              className={classes.content}
            >
              {t("You can access BlackVue_")}
            </Typography>

            <div className={classes.webBtnDiv}>
              <Button onClick={onStartTour} color="primary">
                {t("Start tour")}
              </Button>
            </div>
          </div>
        </SimpleBarReact>
      }
      titleClassName={classes.modalTitle}
      contentClassName={classes.modalContentWrap}
    />
  );
};
