import { Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { Webviewer, LightColors } from "@thingsw/pitta-modules";
import React from "react";
import { useTranslation } from "react-i18next";

interface VideoModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalRoot: {
    margin: 16,
  },
  modalHeadingText: {
    fontSize: "25px !important",
  },
  modalTitleDiv: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 28,
    minHeight: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-start",
      paddingTop: 24,
    },
  },
  subTitle: {
    marginBottom: 24,
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      textAlign: "start",
    },
  },
  modalContentDiv: {
    padding: "5px 41px 40px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "13px 24px 24px",
    },
  },
  voiceOptionsWrap: {
    border: `3px solid ${LightColors.primary["7"]}`,
    borderRadius: 10,
    padding: "8px 7px",
    backgroundColor: LightColors.primary["10"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0 76px",
    },
  },
  voiceOption: {
    padding: "5px 0",
    "&:first-child": {
      paddingTop: 0,
    },
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  voiceOptionText: {
    letterSpacing: -0.5,
  },
  divider: {
    backgroundColor: LightColors.primary["8"],
    borderRadius: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      backgroundColor: LightColors.primary["9"],
    },
  },
  // mantis - 10496, 모바일일 때 버튼 가운데로 정렬 (Leehj)
  modalBtnDiv: {
    padding: "0px 16px 24px",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0px 24px 24px",
      justifyContent: "flex-end",
    },
  },
  modalBtnStyle: {
    minWidth: 311,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 139.5,
    },
  },
}));

export const VoiceGuidanceModal = (props: VideoModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, onClose, onClickPositive } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  return (
    <Modal
      open={open}
      close={!mobile}
      onClose={onClose}
      onClickPositive={onClickPositive}
      heading={t("Voice Guidance")}
      content={
        <div>
          <div className={classes.subTitle}>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("If you turn_")}:
            </Typography>
          </div>

          <div className={classes.voiceOptionsWrap}>
            <div className={classes.voiceOption}>
              <Typography
                category="Default"
                variant="Small"
                className={classes.voiceOptionText}
              >
                {t("Power ON / OFF")}
              </Typography>
            </div>
            <Divider className={classes.divider} />

            <div className={classes.voiceOption}>
              <Typography
                category="Default"
                variant="Small"
                className={classes.voiceOptionText}
              >
                {t("Parking Mode ON/OFF ")}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.voiceOption}>
              <Typography
                category="Default"
                variant="Small"
                className={classes.voiceOptionText}
              >
                {t("Normal Recording ON/OFF")}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.voiceOption}>
              <Typography
                category="Default"
                variant="Small"
                className={classes.voiceOptionText}
              >
                {t("Cloud Connected / Disconnected")}
              </Typography>
            </div>
          </div>
        </div>
      }
      RButton={t("OK")}
      headingTextClassName={classes.modalHeadingText}
      titleClassName={classes.modalTitleDiv}
      contentClassName={classes.modalContentDiv}
      className={classes.modalRoot}
      actionClassName={classes.modalBtnDiv}
      RButtonClassName={classes.modalBtnStyle}
    />
  );
};
