import React from "react";
import { Modal } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Webviewer } from "@thingsw/pitta-modules";

interface DiscardChangeModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 344,
    minHeight: 180,
    "&>.MuiDialogActions-root": {
      justifyContent: "center",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        justifyContent: "flex-end",
      },
    },
    "&>.buttonPadding": {
      padding: "0px 23px 24px",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 295,
    },
  },
}));

export const DiscardChangeModal = ({
  open,
  onClose,
  onClickNegative,
  onClickPositive,
}: DiscardChangeModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  return (
    <Modal
      btnCenterMobile={mobile}
      className={classes.root}
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      close={false}
      heading={t("Discard unsaved changes?")}
      LButton={t("No")}
      RButton={t("Yes")}
      content={<></>}
      Secondary={false}
    />
  );
};
