import { makeStyles, Theme } from "@material-ui/core";
import { Sliders, Typography } from "@thingsw/pitta-design-system";
import { LightColors } from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  marked: {
    marginBottom: 0,
  },

  label: {
    padding: "2px 7px",
    border: `1px solid ${LightColors.primary["4"]}`,
    borderRadius: 4,
    textAlign: "center",
    color: `${LightColors.primary["4"]}`,
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    fontSize: "0.875rem",
    top: 30,
    marginLeft: 2,

    [theme.breakpoints.down("sm")]: {
      top: "-25px",
      marginLeft: 1,
    },
  },

  sliderDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0 1px 0 9px",

    [theme.breakpoints.up("sm")]: {
      width: 288,
    },
  },

  markPoint: {
    backgroundColor: `${LightColors.primary["4"]}`,
    height: "6px!important",
    width: "2px!important",
    borderRadius: 4,
    marginTop: "-2px",
  },

  maxDiv: {
    marginLeft: 11,
    width: 24,
    height: 30,
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      width: 24,
      height: 21,
    },
  },

  minDiv: {
    marginRight: 11,
    width: 24,
    height: 30,
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      width: 24,
      height: 21,
    },
  },
}));

interface FSliderProps {
  max: number;
  min: number;
  value: number;
  step: number;
  mark: number;
  disabled?: boolean;
  // marks: number;
  onChange?: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
}

export const FirmwareSlider = (props: FSliderProps) => {
  const { max, min, value, step, mark, disabled, onChange } = props;

  const classes = useStyles();

  return (
    <div className={classes.sliderDiv}>
      <Typography
        className={classes.minDiv}
        category="Default"
        variant="Small"
        // mantis - 8717
        // Reckless driving 체크 해제시 Sliders 해제 색상처럼 변하도록 htmlColor 수정
        htmlColor={
          disabled ? LightColors.primary["4"] : LightColors.primary["2"]
        }
      >
        {min}
      </Typography>
      <Sliders
        markStyle={classes.markPoint}
        markClasses={classes.marked}
        labelClasses={classes.label}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        marks={[
          {
            value: mark,
            label: `${mark}`,
          },
        ]}
        step={step}
        valueLabelDisplay="auto"
        labelLeft
        disabled={disabled}
      />

      <Typography
        className={classes.maxDiv}
        category="Default"
        variant="Small"
        // mantis - 8717
        // Reckless driving 체크 해제시 Sliders 해제 색상처럼 변하도록 htmlColor 수정
        htmlColor={
          disabled ? LightColors.primary["4"] : LightColors.primary["2"]
        }
      >
        {max}
      </Typography>
    </div>
  );
};
