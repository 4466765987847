"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Fonts = void 0;
var Fonts = {
  Default: {
    H1: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "2rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H2: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.75rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H3: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.56rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H4: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.375rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H5: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.25rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H6: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.125rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    BodyBold: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: undefined
    },
    Body: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: undefined
    },
    SmallBold: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "0.875rem",
      lineHeight: 1.5,
      letterSpacing: undefined
    },
    Small: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "0.875rem",
      lineHeight: 1.5,
      letterSpacing: undefined
    },
    CaptionBold: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "0.75rem",
      lineHeight: 1.333,
      letterSpacing: undefined
    },
    Caption: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "0.75rem",
      lineHeight: 1.333,
      letterSpacing: undefined
    },
    Button: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1rem",
      lineHeight: 1,
      letterSpacing: undefined
    }
  },
  Large: {
    H1: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "3.8125rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H2: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "3.0625rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H3: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "2.4375rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H4: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.9375rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H5: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.5625rem",
      lineHeight: 1.1718,
      letterSpacing: undefined
    },
    H6: {
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "1.25rem",
      lineHeight: 1.15,
      letterSpacing: 0.1
    }
  }
};
exports.Fonts = Fonts;