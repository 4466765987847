import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { detect } from "detect-browser";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EventMap } from "../components/maps/EventMap";
import { EventMapOnboardingMobile } from "../components/onboarding/EventMapOnboardingMobile";
import { EventMapOnboardingStepWeb } from "../components/onboarding/EventMapOnboardingStepWeb";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { PermissionProps, Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    height: (props: ScreenDefaultProps) => {
      if (props.noHeader) {
        return "calc(var(--vh, 1vh) * 100)";
      }
      if (props.app) {
        return "calc(var(--vh, 1vh) * 100 - 56px)";
      }
      return props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px)"
        : "calc(var(--vh, 1vh) * 100 - 65px)";
    },
    marginTop: (props: ScreenDefaultProps) => {
      if (props.app) {
        return 0;
      }
      return props.error ? 127 : 65;
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) => {
        if (props.app) {
          return 0;
        }
        return props.error ? 85 : 56;
      },
    },
  },
}));

type EventmapScreenProps = ScreenDefaultProps & {
  hideTour: boolean;
};

export const EventmapScreen = (
  props: EventmapScreenProps & PermissionProps
) => {
  const { hideTour, shareToEventmapPerm } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const { email } = useSelector((state: RootState) => state[USER]);
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const [eventMapStep, setEventMapStep] = useState<number>(0);

  useEffect(() => {
    const tourString = localStorage.getItem("pitta-eventmap-tour");
    const tour = JSON.parse(tourString ?? "[]");

    const browserName = detect()?.name;
    const isApp =
      browserName === "ios-webview" || browserName === "chromium-webview";

    console.log("hideTour", tour, hideTour);
    if (!hideTour && isApp) {
      let link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding_mo.png";
      link.imageSrcset = "/images/eventMaponboarding_mo.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding2_mo.png";
      link.imageSrcset = "/images/eventMaponboarding2_mo.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding3_mo.png";
      link.imageSrcset = "/images/eventMaponboarding3_mo.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding4_mo.png";
      link.imageSrcset = "/images/eventMaponboarding4_mo.png 1x";
      document.head.appendChild(link);
      setEventMapStep(1);
    } else if (!isApp && !_.includes(tour, email)) {
      let link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding.png";
      link.imageSrcset = "/images/eventMaponboarding.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding2.png";
      link.imageSrcset = "/images/eventMaponboarding2.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding3.png";
      link.imageSrcset = "/images/eventMaponboarding3.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/eventMaponboarding4.png";
      link.imageSrcset = "/images/eventMaponboarding4.png 1x";
      document.head.appendChild(link);
      setEventMapStep(1);
    } else {
      setEventMapStep(0);
    }
  }, [email, hideTour, mobile]);

  const handleEventMapCloseTour = useCallback(() => {
    setEventMapStep(0);
    const tourString = localStorage.getItem("pitta-eventmap-tour");
    const tour = JSON.parse(tourString ?? "[]");
    localStorage.setItem(
      "pitta-eventmap-tour",
      JSON.stringify([...tour, email])
    );
  }, [email]);

  const handleEventMapNext = useCallback(() => {
    if (eventMapStep === 1) {
      setEventMapStep(2);
    } else if (eventMapStep === 2) {
      setEventMapStep(3);
    } else if (eventMapStep === 3) {
      setEventMapStep(4);
    } else if (eventMapStep === 4) {
      handleEventMapCloseTour();
    }
  }, [handleEventMapCloseTour, eventMapStep]);

  const handleEventMapBack = useCallback(() => {
    if (eventMapStep === 2) {
      setEventMapStep(1);
    } else if (eventMapStep === 3) {
      setEventMapStep(2);
    } else if (eventMapStep === 4) {
      setEventMapStep(3);
    }
  }, [eventMapStep]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <EventMap shareToEventmapPerm={shareToEventmapPerm} />
      {/* 이벤트맵 웹가이드 */}
      {eventMapStep !== 0 && !mobile && (
        <EventMapOnboardingStepWeb
          eventMapStep={eventMapStep}
          onNext={handleEventMapNext}
          onBack={handleEventMapBack}
          onClose={handleEventMapCloseTour}
        />
      )}

      {/* 이벤트맵 모바일 가이드 */}
      {mobile && eventMapStep !== 0 && (
        <EventMapOnboardingMobile
          eventMapStep={eventMapStep}
          onNext={handleEventMapNext}
          onBack={handleEventMapBack}
          onTourClose={handleEventMapCloseTour}
        />
      )}
    </div>
  );
};
