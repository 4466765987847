import {
  BatteryManualStartProps,
  BatterySettings,
  jwtAxiosInst,
} from "@thingsw/pitta-modules";

export const getBatteryHistory = (psn: string, ssid: string, period: 1 | 7) => {
  return jwtAxiosInst.get(
    `/battery/history?psn=${psn}&ssid=${ssid}&period=${period}`
  );
};

export const getBatterySetting = (psn: string, ssid: string) => {
  return jwtAxiosInst.get(`/battery/config?psn=${psn}&ssid=${ssid}`);
};

export const changeBatterySetting = (
  psn: string,
  ssid: string,
  setting: Partial<BatterySettings>
) => {
  return jwtAxiosInst.post(`/battery/config`, { psn, ssid, battery: setting });
};

export const batteryManual = ({
  command,
  psn,
  ssid,
}: BatteryManualStartProps) => {
  return jwtAxiosInst.post(`/battery/manual`, {
    command,
    psn,
    ssid,
  });
};

export const unpairBattery = (email: string, psn: string, ssid: string) => {
  return jwtAxiosInst.delete(`/battery`, {
    data: {
      email,
      psn,
      ssid,
    },
  });
};
