import React, { useState } from "react";
import { LoginHeader } from "../components/LoginHeader";
import { MinimalFooter } from "../components/MinimalFooter";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Error, Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Pricing } from "../components/Pricing";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(var(--vh, 1vh) * 100 - 56px)",
    marginTop: 56,
    padding: theme.spacing(0, 2),
    backgroundColor: LightColors.primary["0"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
    },
  },

  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    padding: theme.spacing(2),
    backgroundColor: `${LightColors.primary["6"]}73`,
    borderRadius: 4,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 438,
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(3.375, 0, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.625, 0, 0),
    },
  },

  marginB1: {
    marginBottom: theme.spacing(1),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  paddingB1: {
    paddingBottom: theme.spacing(1),
  },
  paddingB2: {
    paddingBottom: theme.spacing(2),
  },
  paddingT1: {
    paddingTop: theme.spacing(1),
  },
  paddingT2: {
    paddingTop: theme.spacing(2),
  },
  btnDiv: {
    display: "flex",
    marginTop: theme.spacing(4),
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 438,
      flexDirection: "row",
    },
  },
  btn: {
    flex: 1,
  },
  btnSpace: {
    width: theme.spacing(2),
    height: theme.spacing(1),
  },
  webModalContent: {
    justifyContent: "center",
  },
  textCenter: {
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      textAlign: "inherit",
    },
  },
}));

export const SubscriptionFailScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [openPricing, setOpenPricing] = useState(false);

  return (
    <div>
      <LoginHeader />
      <div className={classes.root}>
        <div className={classes.content}>
          <Error
            style={{
              width: 108,
              height: 108,
              fill: "transparent",
              marginBottom: 16,
            }}
          />
          <Typography
            category="Default"
            variant="H1"
            style={{ marginBottom: 25 }}
          >
            {t("Failed to subscribe")}
          </Typography>

          <Typography
            category="Default"
            variant="Body"
            className={classes.textCenter}
          >
            {t("An error occurred_subscription")}
          </Typography>
          <Typography category="Default" variant="Body">
            {t("Please try again")}
          </Typography>

          <div className={classes.btnDiv}>
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t("OK")}
            </Button>
            <div className={classes.btnSpace}></div>
            <Button
              className={classes.btn}
              variant="outlined"
              color="primary"
              onClick={() => setOpenPricing(true)}
            >
              {t("Go to Pricing")}
            </Button>
          </div>
        </div>
        <MinimalFooter />
        <Modal
          open={openPricing}
          content={<Pricing modal onClose={() => setOpenPricing(false)} />}
          close
          onClose={() => setOpenPricing(false)}
          contentClassName={classes.webModalContent}
          fullSize
        />
      </div>
    </div>
  );
};
