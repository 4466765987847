import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { clearLoading, MEMBER } from "../features/Member/slice";
import { MemberCard } from "../components/MemberCard";

import { RootState } from "../features/store";
import { PermissionDrawer } from "../components/PermissionDrawer";
import { GROUP } from "../features/Group/slice";
import { IMembersInfo, Webviewer, DashCamList } from "@thingsw/pitta-modules";

interface MemberDetailScreenProps {
  error?: string;
  openMenu?: boolean;
  onDeleteMember?: (member: IMembersInfo) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(2),
    height: "calc(var(--vh, 1vh) * 100 - 56px)",
    marginTop: 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 4),
      height: (props: MemberDetailScreenProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px)"
          : "calc(var(--vh, 1vh) * 100 - 56px)",
      marginTop: (props: MemberDetailScreenProps) => (props.error ? 85 : 56),
    },
  },
}));

export const MemberDetailScreen = (props: MemberDetailScreenProps) => {
  const { onDeleteMember } = props;
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const memberList = useSelector((state: RootState) =>
    _.map(state[MEMBER].memberList?.inviteMember)
  );
  const groupsList = useSelector(
    (state: RootState) => state[GROUP].groupsList?.DashCamList
  );
  const { email } = useParams<{ email: string }>();

  const [openDrawer, setOpenDrawer] = useState(false);

  const member = useMemo(() => {
    return _.find(memberList, function (o) {
      return o.email === email;
    });
  }, [email, memberList]);

  useEffect(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const myGroup = useMemo(() => {
    if (member) {
      return _.find(groupsList, function (o) {
        return o.GroupName === member.groupName;
      });
    }
  }, [groupsList, member]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <div>
        {member && (
          <MemberCard
            member={member}
            myGroup={myGroup as DashCamList}
            onDeleteMember={onDeleteMember}
            onClickPermission={() => setOpenDrawer(true)}
          />
        )}
      </div>
      <PermissionDrawer
        email={email}
        openDrawer={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
    </div>
  );
};
