import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  BatteryPieAlert,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { LightColors } from "@thingsw/pitta-modules";

interface RequireCloudConnectModalProps {
  openBatteryRequireModal: boolean;
  setOpenBatteryRequireModal: (open: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 480,
    maxHeight: 253,
  },
  container: {
    padding: "20px 32px",
  },
  questionDiv: {
    marginBottom: 24,
  },
  titleDiv: {
    marginBottom: 24,
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
}));

const RequireCloudConnectModal = (props: RequireCloudConnectModalProps) => {
  const { openBatteryRequireModal, setOpenBatteryRequireModal } = props;
  const { t } = useTranslation();

  const classes = useStyles(props);

  return (
    <Modal
      open={openBatteryRequireModal}
      onClose={() => setOpenBatteryRequireModal(false)}
      close
      noPadding
      className={classes.root}
      LButton="OK"
      onClickNegative={() => setOpenBatteryRequireModal(false)}
      onClickPositive={() => setOpenBatteryRequireModal(false)}
      content={
        <div className={classes.container}>
          <div className={classes.titleDiv}>
            <BatteryPieAlert />

            <Typography variant="H6" htmlColor={LightColors.primary["1"]}>
              {t("Cannot connect to the battery")}
            </Typography>
          </div>
          <div>
            <Typography variant="Body" htmlColor={LightColors.primary["1"]}>
              {t("Please check the Cloud connection of the camera")}
            </Typography>
          </div>
        </div>
      }
    />
  );
};

export default RequireCloudConnectModal;
