import React, { useMemo, useRef, useState } from "react";

import clsx from "clsx";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import {
  Button,
  Fonts,
  GooglePlay,
  IconButton,
  LogoFull,
  LogoOnly,
  Menu,
  Typography,
  WebMenuItem,
  Tooltip,
} from "@thingsw/pitta-design-system";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import AppleIcon from "@material-ui/icons/Apple";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";

import { LangButton } from "../components/LangButton";
import Divider from "@material-ui/core/Divider";
import { genTooltip } from "../utils";
import { WebviewerMenuList } from "./WebviewerMenuList";
import { RootState } from "../features/store";
import { PAYMENT } from "../features/Payment/slice";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useEffect } from "react";
import { PricingModal } from "./modals/PricingModal";
import { LightColors, Languages } from "@thingsw/pitta-modules";

const drawerWidth = 235;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    display: "flex",
  },
  drawerPaper: {
    backgroundColor: "#f8f8f8",
    overflow: "visible",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    minHeight: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
  toolbarOpen: {
    justifyContent: "flex-start",
    padding: theme.spacing(0, 3),
  },
  toolbarClose: {
    padding: theme.spacing(0, 2.25),
  },
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  listItemText: {
    ...Fonts.Default.BodyBold,
  },
  listItemTextDivider: {
    marginBottom: 0,
    marginTop: 8,
  },
  langDivOpen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 80,
    borderBottom: `1px solid ${LightColors.primary["5"]}`,

    ...(theme.direction === "rtl" ? { marginRight: 2 } : { marginLeft: 2 }),
    paddingTop: 8,
  },
  langDivClose: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
  },
  drawerDivOpen: {
    height: 55,
    display: "flex",
    justifyContent: "flex-end",
  },
  drawerDivClose: {
    height: 55,
    display: "flex",
    justifyContent: "center",
  },
  btnIconOpen:
    theme.direction === "rtl" ? { marginLeft: -12 } : { marginRight: -12 },
  btnIconClose: {
    padding: theme.spacing(0.75, 1.5),
    marginBottom: 18,
  },
  menuOpenIcon:
    theme.direction === "rtl"
      ? {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        }
      : {},
  menuCloseIcon:
    theme.direction === "rtl"
      ? {}
      : {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        },
  upgradeBtn: {
    transition: theme.transitions.create("width"),
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  upgradeBtnClose: {
    minWidth: 50,
    maxWidth: 50,
    margin: "0 11px",
  },
  btnDivOpen: {
    padding: theme.spacing(0, 3),
  },
  langBtnDiv: {
    padding: theme.spacing(1, 0),
    // mantis-8443 langBtn 클릭 시 모달창 절반이 뒤로 숨겨지는 현상 수정을 위해 zIndex값 각주 처리 -SW-
    // zIndex: 99,
  },
  langBtnDivClose: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
    margin: "20px 0 12px 0",
  },
  langBtnClose: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  langBtnOpen: { marginBottom: 0 },

  divider: {
    margin: theme.spacing(1, 0),
  },
  appIcon: {
    fontSize: 20,
    color: LightColors.primary["1"],
    marginRight: theme.spacing(1),
  },
  tnpDiv: {
    ...(theme.direction === "rtl" ? { paddingRight: 44 } : { paddingLeft: 44 }),
  },
  modalClose: {
    right: 32,
  },
  modalContent: { padding: 0 },
  webModalContent: { justifyContent: "center" },
  appIconItem: {
    ...(theme.direction === "rtl" && {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: 42,
    }),
  },
}));

interface WebviewerMenuProps {
  open: boolean;
  onToggleMenu: () => void;
}

export const WebviewerMenu = ({ open, onToggleMenu }: WebviewerMenuProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const anchorRef = useRef<HTMLDivElement>(null);
  const appAnchorRef = useRef<HTMLButtonElement>(null);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  const [openAppMenu, setOpenAppMenu] = useState(false);
  const [openPricing, setOpenPricing] = useState(false);
  const [pathname, setPathname] = useState<string>();
  const [langOpenMenu, setLangOpenMenu] = useState(false);

  useEffect(() => {
    setPathname(location.pathname);
    if (pathname) {
      setOpenPricing(false);
    }
  }, [location.pathname, pathname]);

  const upgradeBtnMarkup = useMemo(() => {
    if (
      subscriptionInfo &&
      subscriptionInfo.paymentMethod !== "oneplan" &&
      (_.includes(
        [1, 1004, 1005, 11001, 11002],
        subscriptionInfo.servicePlanID
      ) ||
        (subscriptionInfo.servicePlanID >= 10001 &&
          subscriptionInfo.servicePlanID <= 10003))
    ) {
      return (
        <Button
          color="primary"
          fullWidth
          startIcon={<CardGiftcardIcon />}
          className={clsx(classes.upgradeBtn, {
            [classes.upgradeBtnClose]: !open,
          })}
          onClick={() => {
            setOpenPricing(true);
          }}
        >
          {open && t("Upgrade Plan")}
        </Button>
      );
    }
  }, [classes.upgradeBtn, classes.upgradeBtnClose, open, subscriptionInfo, t]);

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div
          dir={theme.direction}
          className={clsx(classes.toolbar, {
            [classes.toolbarOpen]: open,
            [classes.toolbarClose]: !open,
          })}
        >
          <Link to="/cameras" className={classes.vcenter}>
            {open ? (
              <LogoFull width={166} height={29} />
            ) : (
              <LogoOnly width={36} height={36} />
            )}
          </Link>
        </div>
        <WebviewerMenuList open={open} onClose={() => undefined} />
        <div
          className={clsx({
            [classes.btnDivOpen]: open,
          })}
          style={{ overflow: "hidden" }}
        >
          {upgradeBtnMarkup}
          <div
            dir={theme.direction}
            className={clsx({
              [classes.langDivOpen]: open,
              [classes.langDivClose]: !open,
            })}
          >
            <div
              ref={anchorRef}
              className={clsx(classes.langBtnDiv, {
                [classes.langBtnDivClose]: !open,
              })}
              onClick={() => setLangOpenMenu((o) => !o)}
            >
              {genTooltip(
                <LangButton
                  mode="webviewer"
                  // placement="top-start"
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: theme.direction === "rtl" ? -8 : 8,
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    flip: {
                      enabled: false,
                    },
                  }}
                  openMenu={langOpenMenu}
                  onClose={() => setLangOpenMenu(false)}
                  anchorRef={anchorRef}
                  placement={"top-start"}
                  iconOnly={!open}
                  className={clsx({
                    [classes.langBtnOpen]: open,
                    [classes.langBtnClose]: !open,
                  })}
                />,
                Languages[i18n.language],
                open,
                undefined,
                langOpenMenu
              )}
            </div>
            <Tooltip
              title={
                <Typography category="Default" variant="Caption">
                  {t("Apps and more...")}
                </Typography>
              }
              placement="right"
            >
              <IconButton
                ref={appAnchorRef}
                className={clsx({
                  [classes.btnIconOpen]: open,
                  [classes.btnIconClose]: !open,
                })}
                onClick={() => setOpenAppMenu(true)}
              >
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div
            dir={theme.direction}
            className={clsx({
              [classes.drawerDivOpen]: open,
              [classes.drawerDivClose]: !open,
            })}
          >
            {open ? (
              <Tooltip
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Collapse")}
                  </Typography>
                }
                placement="right"
              >
                <IconButton
                  color="secondary"
                  className={clsx({ [classes.btnIconOpen]: open })}
                  onClick={onToggleMenu}
                >
                  <MenuOpenIcon
                    className={clsx({
                      [classes.menuOpenIcon]: open,
                      [classes.menuCloseIcon]: !open,
                    })}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["5"]}
                  >
                    {t("Expand")}
                  </Typography>
                }
                placement="right"
              >
                <IconButton
                  color="secondary"
                  className={clsx({ [classes.btnIconOpen]: open })}
                  onClick={onToggleMenu}
                >
                  <MenuOpenIcon
                    className={clsx({
                      [classes.menuOpenIcon]: open,
                      [classes.menuCloseIcon]: !open,
                    })}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <div style={{ zIndex: 99 }}>
          <Menu
            open={openAppMenu}
            anchorEl={appAnchorRef?.current}
            onClickAway={() => {
              setOpenAppMenu(false);
            }}
            placement="top-start"
            modifiers={{ preventOverflow: { enabled: true } }}
          >
            <WebMenuItem
              appIcon
              className={classes.appIconItem}
              startIcon={<AppleIcon className={classes.appIcon} />}
              onClick={() => {
                setOpenAppMenu(false);
                window.open(
                  "https://apps.apple.com/kr/app/blackvue/id1049209637",
                  "_blank"
                );
              }}
            >
              {t("iOS")}
            </WebMenuItem>
            <WebMenuItem
              appIcon
              className={classes.appIconItem}
              startIcon={<GooglePlay className={classes.appIcon} />}
              onClick={() => {
                setOpenAppMenu(false);
                window.open(
                  "https://play.google.com/store/apps/details?id=comb.blackvuec",
                  "_blank"
                );
              }}
            >
              {t("Android")}
            </WebMenuItem>
            <WebMenuItem
              startIcon={<DesktopMacIcon className={classes.appIcon} />}
              onClick={() => {
                setOpenAppMenu(false);
                window.open(
                  "https://blackvue.com/downloads/#app-viewer",
                  "_blank"
                );
              }}
            >
              {t("Mac")}
            </WebMenuItem>
            <WebMenuItem
              startIcon={<DesktopWindowsIcon className={classes.appIcon} />}
              onClick={() => {
                setOpenAppMenu(false);
                window.open(
                  "https://blackvue.com/downloads/#app-viewer",
                  "_blank"
                );
              }}
            >
              {t("Windows")}
            </WebMenuItem>
            <Divider className={classes.divider} />
            <WebMenuItem
              className={classes.tnpDiv}
              onClick={() => {
                setOpenAppMenu(false);
                window.open(
                  // 한국어 / 일본어 일시 해당 국가의 Terms & Policy로 이동 (KimSH)
                  i18n.language === "ko"
                    ? "https://blackvue.com/ko/terms-conditions-korean/"
                    : i18n.language === "ja"
                    ? "https://blackvue.com/ja/terms-conditions-japan/"
                    : "https://blackvue.com/warranty-terms-conditions/",
                  "_blank"
                );
              }}
            >
              {t("Terms & Policies")}
            </WebMenuItem>
          </Menu>
        </div>
      </Drawer>
      <PricingModal open={openPricing} onClose={() => setOpenPricing(false)} />
    </>
  );
};
