import React from "react";
import { Card, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Switch, Typography } from "@thingsw/pitta-design-system";

import { CAMERA } from "../../features/Camera/slice";

import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  Webviewer,
  LightColors,
  ILiveEventSettings,
  OnOffType,
  MODELS_2CH,
  MODELS_3CH,
  OPTION_CAMERA_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: "0 21px 7px",
    paddingTop: 1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    paddingBottom: 9,
    maxWidth: 673,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },

  switch: {
    marginRight: 19,
    height: 38,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
  },

  boderBottomBox: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
  },

  boderBottomNoneBox: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
  },
}));

interface LiveEventUploadPanelProps {
  currentLiveEvent?: ILiveEventSettings;
  onLiveEventUpload: (key: keyof ILiveEventSettings, val: boolean) => void;
}

export const LiveEventUploadPanel = ({
  currentLiveEvent,
  onLiveEventUpload,
}: LiveEventUploadPanelProps) => {
  const classes = useStyles();

  const { camera } = useSelector((state: RootState) => state[CAMERA]);

  const { t } = useTranslation();

  const getBoolean = (onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  };

  return (
    <>
      <div className={classes.title}>
        <Typography category="Default" variant="SmallBold">
          {t("Live event upload")}
        </Typography>
      </div>

      {/* Reckless driving unit card */}

      <Card className={classes.card}>
        <div className={classes.boderBottomNoneBox}>
          <Typography category="Default" variant="Body">
            {/* mantis - 11122, Video source -> Video Source로 문구 수정 (Leehj) */}
            {t("Video source_2")}
          </Typography>
        </div>
        <div className={classes.boderBottomNoneBox}>
          <div className={classes.switch}>
            <Switch
              checked={getBoolean(currentLiveEvent?.Vs1Chan)}
              onChange={(event) => {
                onLiveEventUpload("Vs1Chan", event.target.checked);
              }}
            />
          </div>

          <Typography category="Default" variant="Body">
            {t("Front camera_2")}
          </Typography>
        </div>
        {(_.includes(MODELS_2CH, camera?.model) ||
          _.includes(MODELS_3CH, camera?.model)) && (
          <div className={classes.boderBottomNoneBox}>
            <div className={classes.switch}>
              <Switch
                checked={getBoolean(currentLiveEvent?.Vs2Chan)}
                onChange={(event) => {
                  onLiveEventUpload("Vs2Chan", event.target.checked);
                }}
              />
            </div>

            <Typography category="Default" variant="Body">
              {t("Rear camera_2")}
            </Typography>
          </div>
        )}

        {/* BOX 인 경우에만 출력 */}
        {_.includes(OPTION_CAMERA_MODELS, camera?.model) && (
          <div className={classes.boderBottomNoneBox}>
            <div className={classes.switch}>
              <Switch
                checked={getBoolean(currentLiveEvent?.Vs3Chan)}
                onChange={(event) => {
                  onLiveEventUpload("Vs3Chan", event.target.checked);
                }}
              />
            </div>

            <Typography category="Default" variant="Body">
              {t("Optional Camera")}
            </Typography>
          </div>
        )}
      </Card>
    </>
  );
};
