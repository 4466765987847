import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@thingsw/pitta-design-system";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, updateFirmwareSettings } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { DMSPanel } from "./DMSPanel";
import {
  LightColors,
  Webviewer,
  TabNameInfo3,
  DMC200_MODELS,
  BOXPRO_MODELS,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 1353,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      // minHeight: 1171,
    },
  },

  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
}));

export const FirmwareDMSPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { firmware, camera } = useSelector((state: RootState) => state[CAMERA]);

  const [system, setSystem] = useState<TabNameInfo3>();
  // mantis - 8837, 초기 DsmRotate 값이 undefined인 경우 "0"값을 넣어주기 위해 추가(Leehj)
  const [currentTab3, setCurrentTab3] = useState<TabNameInfo3>();
  const [disabledChange, setDisabledChange] = useState(true);
  // mantis - 8837(Leehj)
  const dmc200_DMS = useMemo(() => _.includes([...DMC200_MODELS, ...BOXPRO_MODELS], camera?.model), [
    camera?.model,
  ]);

  const handleChange = useCallback(
    (key: keyof TabNameInfo3, value: boolean | string) => {
      if (typeof value === "boolean") {
        setSystem((c) => ({ ...c, [key]: value ? "1" : "0" } as TabNameInfo3));
      } else if (typeof value === "string") {
        setSystem((c) => ({ ...c, [key]: value } as TabNameInfo3));
      }
    },
    []
  );

  const handleUpdate = () => {
    if (system && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab3"
        ),
      };
      updated.cloud_settings = [
        { section_info: system, section_name: "Tab3" },
        ...updated.cloud_settings,
      ];

      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      // mantis - 8837, DsmRotate 값이 undefined인 경우 설정변경사항이 없어도 save버튼 활성화 되어있는 이슈 수정을 위해 조건에 따라 setCurrentTab3(Leehj)
      if (
        dmc200_DMS &&
        (currentTab?.section_info as TabNameInfo3).DsmRotate === undefined
      ) {
        setCurrentTab3(
          (c) =>
            ({
              ...(currentTab?.section_info as TabNameInfo3),
              DsmRotate: "0",
            } as TabNameInfo3)
        );
      } else {
        setCurrentTab3(currentTab?.section_info as TabNameInfo3);
      }
    }
  }, [dmc200_DMS, firmware]);

  useEffect(() => {
    if (firmware) {
      const tab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      if (tab3) {
        setSystem(tab3.section_info as TabNameInfo3);
      }
    }
  }, [firmware]);

  // mantis - 8837, dmc200이면서 DsmRotate 값이 undefined인 경우 설정변경사항이 없어도 save버튼 활성화 되어있는 이슈 수정(Leehj)
  // currentTab -> currentTab3로 변경 (Leehj)
  useEffect(() => {
    const predicts = _.keys(system).map((k) => {
      const key = k as keyof TabNameInfo3;
      const value = (currentTab3 as TabNameInfo3)[key];

      return value !== undefined && value === system?.[key];
    });
    setDisabledChange(
      _.every(predicts) || system?.kmLimit === "" || system?.mileLimit === ""
    );
  }, [currentTab3, system]);

  return (
    <div style={{ minHeight: 800 }}>
      <div className={classes.bigBox}>
        {system && <DMSPanel onChange={handleChange} tabInfo3={system} />}
      </div>

      <Button
        className={classes.buttonSize}
        variant="contained"
        color="primary"
        disabled={disabledChange}
        onClick={handleUpdate}
      >
        {t("Save")}
      </Button>
    </div>
  );
};
