import _ from "lodash";

export const pushArray = (arr: number[], x: number, length: number = 20) => {
  arr.push(x);
  if (arr.length > length) {
    arr.shift();
  }
  return arr;
};
const getLast10 = (arr: number[]) => {
  const tmp = arr.slice(Math.max(0, arr.length - 10), arr.length);
  // return tmp.length === 1 ? tmp : _.range(0, tmp.length-1).map((i) => tmp[i + 1] - tmp[i]);
  return tmp;
};

const getLast2Sec = (arr: number[]) => {
  return arr.slice(Math.max(0, arr.length - 30), Math.max(0, arr.length - 10));
};

export const calcValue = (arr: number[]) => {
  const last10 = _.maxBy(getLast10(arr), (v) => Math.abs(v)) ?? 0;
  const last2Sec = _.mean(getLast2Sec(arr)) ?? 0;
  const value = _.maxBy([last10, last2Sec], (v) => Math.abs(v)) ?? 0;
  const value2 = _.min([_.max([value, -150]), 150]) ?? 0;
  // console.log(arr, getLast10(arr), getLast2Sec(arr), value2);
  return (300 - (value2 + 150)) / 300;
  // return _.min([_.max([value, -150]), 150]) ?? 0;
};
