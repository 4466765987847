import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  ChangeEvent,
  useCallback,
} from "react";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  IconButton,
  RadioButton,
  Typography,
} from "@thingsw/pitta-design-system";
import { DateTimeSelector } from "@thingsw/pitta-design-system/dist/components/DateTimeSelector";
import { FirmwareFormatModal } from "./FirmwareFormatModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  CAMERA,
  loadFirmwareSettings,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import _ from "lodash";
import moment from "moment";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DiscardChangeModal } from "./DiscardChangeModal";
import { Location } from "history";
import { Prompt, useHistory } from "react-router-dom";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  LightColors,
  Webviewer,
  TabNameInfo1,
  TimeZone,
  TrueFalsetype,
  // 시간 설정 빈화면 이슈 수정 (Leehj)
  TimeZoneCountries,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 144,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  borderBox: {
    width: "100%",
    minWidth: 288,
    height: 48,
    display: "flex",
    justifyContent: "flex-start",
    padding: "9px 11px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-end",
      ...(theme.direction === "rtl"
        ? { padding: "9px 295px 9px 0px" }
        : { padding: "9px 0px 9px 295px" }),
      flexDirection: "column",
      maxWidth: 672,
    },
  },

  borderNoneBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "9px 11px",
    minWidth: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      maxHeight: 48,
      borderRadius: 4,
      padding: "9px 10px",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  select: {
    paddingTop: 9,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flex: "1",
      maxWidth: 360,
      paddingTop: 0,
    }, // maxWidth: 256,
    // [theme.breakpoints.up(Webviewer.mobile)]: {
    //   minWidth: 360,
    // },
  },
  inputGPStime: {
    paddingTop: 9,
    "& .MuiInputBase-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-input": {
      height: "24px",
      padding: "6px 11px",
    },
    "& .MuiSvgIcon-root": {
      color: "#7E7E83",
    },
    "& .Mui-disabled .MuiSvgIcon-root": {
      color: "#BEBEC1",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flex: "1",
      maxWidth: 360,
      paddingTop: 0,
    },
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  mgB0: {
    marginBottom: 9,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      ...(theme.direction === "rtl"
        ? { paddingRight: 0, marginRight: 0 }
        : { marginLeft: 0 }),
    },
    "&.MuiFormControlLabel-root": {
      ...(theme.direction === "rtl" ? { paddingRight: 0 } : { marginLeft: 0 }),
    },
  },
  w226: {
    minWidth: 226,
  },
  ArrowBackPd: {
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 7 }
      : { paddingLeft: 0, paddingRight: 7 }),
  },
  ArrowBack: {
    maxWidth: "100%",
    maxHeight: 21,
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  timeListPaper: {
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    border: `1px solid ${LightColors.primary["5"]}`,
  },
  timeListDiv: {
    width: 360,
    minHeight: 36,
    maxHeight: "50vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    "& ul": {
      margin: 0,
      listStyle: "none",
      padding: "0",
    },
    "& ul > li": {
      padding: "6px 6px 6px 32px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#E7F5FC",
      },
    },
  },
}));

interface TimeFirmwarePanelProps {
  onClose: () => void;
}

export const TimeFirmwarePanel = ({ onClose }: TimeFirmwarePanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveSettingModal, setOpenSaveSettingModal] = useState(false);
  const { camera, firmware, firmwareConfig, loading } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [current, setCurrent] = useState<TabNameInfo1>();
  const [currentTab, setCurrentTab] = useState<TabNameInfo1>();
  const [disabledChange, setDisabledChange] = useState(true);
  const [day, setDay] = useState<moment.Moment>(moment());
  const [timeRadioValue, setTimeRadioValue] = useState("0");
  const [targetLocation, setTargetLocation] = useState<Location>();

  const [timeZoneValue, setTimeZoneValue] = useState<string>();
  const [preTimeZoneValue, setPreTimeZoneValue] = useState<string>();
  const [onChangeTimeValue, setOnChangeTimeValue] = useState("");
  const [openTimelist, setOpenTimelist] = useState(false);
  const timeListAnchorRef = useRef<HTMLDivElement>(null);
  const [language, setLanguage] = useState<string>();

  const settings = useMemo(() => {
    return firmwareConfig?.Time;
  }, [firmwareConfig?.Time]);

  useEffect(() => {
    if (!loading) {
      setOpenSaveSettingModal(false);
    }
  }, [loading]);

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        console.log("unload");
        event.preventDefault();
        return (event.returnValue = "");
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      if (currentTab) {
        const section = currentTab.section_info as TabNameInfo1;
        setCurrent(section);
        if (section.SetTime) {
          setDay(moment(section.SetTime, "YYYYMMDDHHmm"));
        }
        if (section.GpsSync === "0") {
          setTimeRadioValue("1");
        } else {
          setTimeRadioValue("0");
        } //TimeSet || GpsSync 저장 후 다시 로드 했을 때 저장한 키 값이 나오게 하기 위한 식 //
      }
    }
  }, [firmware]);

  const handleChange = (key: keyof TabNameInfo1, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo1));
  };

  const setPreTimeZone = useCallback(() => {
    _.chain(TimeZone)
      .keys()
      .map((k) => {
        if (k === current?.TimeZone) {
          setPreTimeZoneValue(TimeZone[k].valueOf());
          setTimeZoneValue(TimeZone[k].valueOf());
        }
        return false;
      })
      .value();
  }, [current?.TimeZone]);

  const onClickOutsideListener = useCallback(() => {
    document.removeEventListener("click", onClickOutsideListener);
    if (openTimelist) {
      setOpenTimelist(false);
    }
    setPreTimeZone();
  }, [openTimelist, setPreTimeZone]);

  const onChangeTimeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeZoneValue(e.currentTarget.value);
    setOnChangeTimeValue(e.currentTarget.value);
    setOpenTimelist(true);
  };

  const timeZoneList = useMemo(() => {
    setLanguage(i18n.language);
    const countryTimeList: any[] = [];
    _.filter(TimeZoneCountries, (obj) =>
      _.forEach(obj, (item: any, key) => {
        if (key === language)
          countryTimeList.push({
            key: String(obj.value),
            time: obj.prefix,
            country: item,
          });
      })
    );
    return countryTimeList;
  }, [i18n.language, language]);

  const getTimelist = useCallback(() => {
    let searchWords = onChangeTimeValue.toLowerCase().split(" ");

    return _.chain(timeZoneList)
      .filter((item) =>
        searchWords.every((word: string) =>
          (item.time + item.country).toLowerCase().includes(word)
        )
      )
      .sortBy((item) => item.value)
      .map((item) => {
        return (
          <li
            onClick={() => {
              setCurrent((c) => {
                return c && { ...c, TimeZone: item.key };
              });
              setTimeZoneValue(item.time + "  " + item.country);
              setOpenTimelist(false);
            }}
          >
            {item.time + "  " + item.country}
          </li>
        );
      })
      .value();
  }, [onChangeTimeValue, timeZoneList]);

  const timeListMarkup = useMemo(() => {
    const timelist = getTimelist();
    if (timelist) {
      return <ul>{timelist}</ul>;
    }
  }, [getTimelist]);

  useEffect(() => {
    if (timeZoneValue === undefined && current?.TimeZone) {
      setPreTimeZone();
    }
  }, [current?.TimeZone, timeZoneValue, setPreTimeZone]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      setCurrentTab(currentTab?.section_info as TabNameInfo1);
      const predicts = _.keys(currentTab?.section_info as TabNameInfo1).map(
        (k) => {
          const key = k as keyof TabNameInfo1;
          return (
            (currentTab?.section_info as TabNameInfo1)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [current, firmware]);

  const handleUpdate = () => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab1"
        ),
        update_date: undefined,
      };
      const timeSetting =
        timeRadioValue === "0"
          ? { GpsSync: "1" as TrueFalsetype, SetTime: "" }
          : {
              GpsSync: "0" as TrueFalsetype,
              SetTime: day.format("YYYYMMDDHHmm"),
            };
      updated.cloud_settings = [
        {
          section_info: { ...current, TimeSet: "1", ...timeSetting },
          section_name: "Tab1",
        },
        ...updated.cloud_settings,
      ];
      // console.log("updated", updated);
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  return (
    <>
      <Prompt
        when={!disabledChange && !targetLocation}
        message={(location) => {
          setTargetLocation(location);
          setOpenDiscardModal(true);
          return false;
        }}
      />
      <div
        className={classes.ArrowBack}
        onClick={() => {
          // mantis - 8590, TimeZone, Daylight이 변경된 경우도 discardModal open
          if (
            currentTab?.TimeSet !== current?.TimeSet ||
            currentTab?.GpsSync !== current?.GpsSync ||
            currentTab?.TimeZone !== current?.TimeZone ||
            currentTab?.Daylight !== current?.Daylight
          ) {
            setOpenDiscardModal(true);
          } else {
            onClose();
          }
        }}
      >
        <IconButton className={classes.ArrowBackPd}>
          <ArrowBackIcon></ArrowBackIcon>
        </IconButton>
        <Typography category="Default" variant="H6">
          {t("Time")}
        </Typography>
      </div>
      <div className={classes.bigBox}>
        <FormControl className={classes.mgB0}>
          <RadioGroup
            value={timeRadioValue}
            onChange={(e) => {
              setTimeRadioValue(e.target.value);
              if (e.target.value === "0") {
                setCurrent((c) => {
                  return c && { ...c, GpsSync: "1", SetTime: "" };
                });
              } else if (e.target.value === "1") {
                setCurrent((c) => {
                  return (
                    c && {
                      ...c,
                      // mantis - 8591, 설정변화 없는 경우에도 save버튼 활성화되는 이슈 수정
                      // SetTime: day.format("YYYYMMDDhhmm"),
                      GpsSync: "0",
                    }
                  );
                });
              }
            }}
          >
            {settings?.["TimeSet"] && (
              <div
                className={classes.borderNoneBox}
                style={{
                  borderBottom: `1px solid ${LightColors.primary["6"]}`,
                }}
              >
                <div style={{ minWidth: 200 }}>
                  <RadioButton
                    value="1"
                    labelClassName={classes.mgB0}
                    style={{ padding: 3 }}
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Manual time settings")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.w226}>
                  <DateTimeSelector
                    day={day}
                    onChangeDay={(e) => {
                      setDay(e);
                      setCurrent((c) => {
                        return c && { ...c, SetTime: e.toString() };
                      });
                    }}
                    dense
                    mobile={mobile}
                    disabled={timeRadioValue === "0"}
                    // mantis - 10649, manual 설정은 무조건 저장할 수 있게 activeBtn옵션을 통해 항상 ok버튼 활성화 되도록 수정(Leehj),
                    activeBtn
                    // mantis - 10649, timeRadioValue === "1" && current?.SetTime === "" 인 경우 ok누르면 save버튼 활성화 되도록 수정하여 저장가능하도록 하였습니다. (leehj)
                    onClick={(day) => {
                      if (timeRadioValue === "1" && current?.SetTime === "") {
                        setCurrent((c) => {
                          return c && { ...c, SetTime: day.toString() };
                        });
                      }
                    }}
                  ></DateTimeSelector>
                </div>
              </div>
            )}
            {settings?.["GpsSync"] && (
              <>
                <div className={classes.borderNoneBox}>
                  <div style={{ minWidth: 180 }}>
                    <RadioButton
                      value="0"
                      labelClassName={classes.mgB0}
                      style={{ padding: 3 }}
                      label={
                        <Typography
                          category="Default"
                          variant="Body"
                          style={{ minWidth: 130 }}
                        >
                          {t("Sync with GPS_")}
                        </Typography>
                      }
                    />{" "}
                  </div>
                </div>
                <div className={classes.borderNoneBox}>
                  <div
                    style={{
                      minWidth: 180,
                      paddingLeft: "32px",
                      fontSize: "1rem",
                    }}
                  >
                    {t("Time zone (GMT)")}
                  </div>
                  <div
                    className={classes.inputGPStime}
                    ref={timeListAnchorRef}
                    onMouseLeave={() => {
                      if (openTimelist) {
                        document.addEventListener(
                          "click",
                          onClickOutsideListener
                        );
                      }
                    }}
                  >
                    <OutlinedInput
                      placeholder={preTimeZoneValue}
                      value={timeZoneValue}
                      disabled={timeRadioValue === "1"}
                      onChange={onChangeTimeInput}
                      onFocus={() => {
                        setPreTimeZone();
                        setTimeZoneValue("");
                        setOnChangeTimeValue("");
                        setOpenTimelist(true);
                      }}
                      endAdornment={<ArrowDropDownIcon />}
                    />
                    <Popper
                      dir={theme.direction}
                      open={openTimelist}
                      anchorEl={timeListAnchorRef.current}
                      modifiers={{
                        offset: {
                          enabled: true,
                          offset: "0, 4",
                        },
                      }}
                      onClick={() => {
                        document.removeEventListener(
                          "click",
                          onClickOutsideListener
                        );
                      }}
                      style={{
                        zIndex: 99,
                        position: "absolute",
                      }}
                      placement="bottom-start"
                      transition
                    >
                      <Paper classes={{ root: classes.timeListPaper }}>
                        <div className={classes.timeListDiv}>
                          {timeListMarkup}
                        </div>
                      </Paper>
                    </Popper>
                  </div>
                </div>
                <div className={classes.borderBox} style={{ border: "none" }}>
                  <div style={{ minWidth: 180 }}>
                    <FormControlLabel
                      className={classes.mgB0}
                      control={
                        <CheckBox
                          disabled={timeRadioValue === "1"}
                          checked={current?.Daylight === "1"}
                          onChange={(e) => {
                            handleChange("Daylight", e.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Daylight saving time")}
                        </Typography>
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonSize}
          onClick={() => setOpenSaveSettingModal(true)}
          disabled={disabledChange}
        >
          {t("Save")}
        </Button>
        {openSaveSettingModal && (
          <FirmwareFormatModal
            loading={loading}
            open={openSaveSettingModal}
            onClose={() => setOpenSaveSettingModal(false)}
            onClickNegative={() => setOpenSaveSettingModal(false)}
            onClickPositive={() => {
              handleUpdate();
            }}
          />
        )}
        {openDiscardModal && (
          <DiscardChangeModal
            open={openDiscardModal}
            onClose={() => {
              setTargetLocation(undefined);
              setOpenDiscardModal(false);
            }}
            onClickNegative={() => {
              setTargetLocation(undefined);
              setOpenDiscardModal(false);
            }}
            onClickPositive={() => {
              if (targetLocation) {
                history.push(targetLocation.pathname);
              } else {
                onClose();
              }
              setTargetLocation(undefined);
              setOpenDiscardModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};
