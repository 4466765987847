import { Card, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Switch,
  Typography,
  PersonAddIcon,
  ScrollBar,
} from "@thingsw/pitta-design-system";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CAMERA,
  deleteEmail,
  fixMasterEmailNotification,
  invite,
  loadNotification,
  loadNotificationEmailList,
  updateNotification,
} from "../../features/Camera/slice";
import { PAYMENT } from "../../features/Payment/slice";
import { RootState } from "../../features/store";
import { getPlanFromServiceID } from "../../utils/Service";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { USER } from "../../features/User/slice";
import { InviteModal } from "../cameras/InviteModal";
import { PersonDeleteModal } from "../cameras/PersonDeleteModal";
import clsx from "clsx";
import { MEMBER, loadMembers } from "../../features/Member/slice";
import {
  Webviewer,
  LightColors,
  IEmailNotiSettings,
  OnOffType,
  statusType,
  IEmailList,
  DMC100_MODELS,
  DMC200_MODELS,
  BOXPRO_MODELS,
  LOW_VOLTAGE_MODELS,
} from "@thingsw/pitta-modules";
import InfoIcon from "@material-ui/icons/Info";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { NotificationSetTimeModal } from "./NotificationSetTimeModal";
import { NotificationInfoModal } from "./NotificationInfoModal";
import ableExtendBoxPro from "../../utils/ableExtendBoxPro";

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapNoDivider: {
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05);",
    marginBottom: 15,
    color: (props: any) => (props.darkMode ? "#FFFFFF" : "#1E1E23"),
    backgroundColor: (props: any) => (props.darkMode ? "#1E1E23" : "#FFFFFF"),
    border: (props: any) => (props.darkMode ? `1px solid #404046` : "none"),
  },
  cardWrap: {
    borderRadius: 12,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05);",
    marginBottom: 15,
    color: (props: any) => (props.darkMode ? "#FFFFFF" : "#1E1E23"),
    backgroundColor: (props: any) => (props.darkMode ? "#1E1E23" : "#FFFFFF"),
  },

  cardDiv: {
    display: "flex",
    padding: "12px 0",
    margin: "0 12px",
    flexDirection: (props: EmailNotificationSettngPanelProps) =>
      props.app ? "row-reverse" : "row",
    "&.disabled": {
      opacity: 0.35,
      cursor: "auto",
    },
  },
  switch: {
    marginRight: (props: EmailNotificationSettngPanelProps) =>
      props.app ? 0 : 19,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
    "& .MuiSwitch-switchBase": {
      color: (props: any) =>
        props.darkMode ? "#121216 !important" : "#FFFFFF",
    },
  },
  emailNotiSwitch: {
    marginRight: (props: EmailNotificationSettngPanelProps) =>
      props.app ? 0 : 11,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 12,
      width: 48,
    },
    "& .MuiSwitch-switchBase": {
      color: (props: any) =>
        props.darkMode ? "#121216 !important" : "#FFFFFF",
    },
  },
  cardDivider: {
    margin: "0 0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0 21px 16px",
    },
  },
  firstCardDivider: {
    margin: "0 0 15px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0 21px 15px",
    },
  },
  subTitle: {
    margin: "0 21px 8px",
    color: (props: any) => (props.darkMode ? "#0095E0" : "#13131C"),
  },
  recipientsDiv: {
    display: "flex",
    alignItems: "center",
    padding: (props: EmailNotificationSettngPanelProps) =>
      props.app ? "12px 12px" : "12px 24px 12px 76px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "12px 24px 12px 84px",
    },
  },
  addPersonIcon: {
    marginLeft: 21,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 29,
    },
    "& button path": {
      fill: (props: any) => (props.darkMode ? "#EEEEF3" : "#13131C"),
    },
  },
  addPersonIconDisabled: {
    "& path": {
      fill: LightColors.primary["4"],
    },
  },
  invitePersonWrap: {
    display: "flex",
    alignItems: "center",
    padding: "4px 30px 11.5px",
    "& button path": {
      fill: (props: any) => (props.darkMode ? "#EEEEF3" : "#13131C"),
    },
  },
  invitePersonDiv: {
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 28,
    },
  },
  personIcon: {
    display: "flex",
    marginRight: 22,
    marginTop: -3,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 30,
    },
  },
  accessTimeIcon: {
    display: "flex",
    marginRight: 22,
    color: LightColors.primary["4"],
    marginTop: -4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 30,
    },
  },
  accessInfoText: {
    display: "flex",
    flexDirection: "column",
  },
  guestEmailBox: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  saveBtn: {
    marginTop: 16,
  },
  disabledText: {
    opacity: 0.35,
  },
  flex1: {
    flex: 1,
  },
  infoIcon: {
    color: LightColors.primary["3"],
    marginLeft: 8,
    "&:active": {
      color: LightColors.primary["1"],
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardRow: {
    display: "flex",
    flexDirection: "column",
  },
  arrowCardRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  arrowIcon: {
    color: LightColors.primary["3"],
  },
  contDiv: {
    position: "absolute",
    padding: "16px 15px",
    overflow: "auto",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transition: theme.transitions.create("transform"),
  },
  contentDivider: {
    margin: "3px 20px",
  },
  appOnly: {
    display: (props: EmailNotificationSettngPanelProps) =>
      props.app ? "block" : "none",
  },
}));

export interface EmailNotificationSettngPanelProps {
  back?: boolean;
  app?: boolean;
  darkMode?: boolean;
}

export const EmailNotificationSettngPanel = (
  props: EmailNotificationSettngPanelProps
) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { back, app, darkMode } = props;

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { loading, type, camera, firmwareConfig } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const emailSettings = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.EmailSettings
  );
  const emailSend = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.EmailSend
  );
  const notificationEmail = useSelector(
    (state: RootState) => state[CAMERA].notificationEmail?.emails
  );

  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );

  const [current, setCurrent] = useState<IEmailNotiSettings>();
  const [push, setPush] = useState<OnOffType>("off");
  const [fatigueTime, setFatigueTime] = useState<number | undefined>();
  const [fatigueSend, setFatigueSend] = useState<OnOffType | undefined>();
  const [disableSave, setDisableSave] = useState(true);
  const [deleteRecipients, setDeleteRecipients] = useState<string>("");

  const [mailStatus, setMailStatus] = useState<statusType>("D");
  const [infoTitle, setInfoTitle] = useState("");
  const [infoDescription, setInfoDescription] = useState("");
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openSetTimeModal, setOpenSetTimeModal] = useState(false);

  // mantis : 8512
  // modal이 자동으로 닫히지 않도록 boolean 추가
  const [emailModal, setEmailModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  // mantis : 8512
  // send & delete email 팝업 창 대기 시 자동으로 닫히지 않도록 useState 추가
  const [sendEmail, setSendEmail] = useState(false);

  const isExtendBoxPro = useMemo(() => {
    return ableExtendBoxPro(camera?.model, camera?.fw_ver);
  }, [camera]);

  const ownerEmail = _.find(
    useSelector((state: RootState) => state[MEMBER].members?.inviteMember),
    (m) => m.email === userProfile?.email
  )?.hostEmail;

  // mantis - 11685추가수정, admin인 경우 owner계정의 상태를 찾도록 수정 (Leehj)
  const masterEmailStatus = _.find(
    useSelector((state: RootState) => state[CAMERA].notificationEmail?.emails),
    (email) => {
      if (userProfile?.userType === "Master") return true; // mantis - 13669 수정, 마스터 계정인 경우 (hongcs)
      if (userProfile?.userType === "SubMaster") {
        return email.guestEmail === ownerEmail;
      } else {
        return email.guestEmail === userProfile?.email;
      }
    }
  )?.mailStatus;

  // mantis - 11685추가수정, owner계정을 알기위해 (Leehj)
  useEffect(() => {
    dispatch(loadMembers());
  }, [dispatch]);

  const isFree = useMemo(() => {
    if (subscriptionInfo) {
      return (
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Free plan"
      );
    }
    return false;
  }, [subscriptionInfo]);

  const getBoolean = useCallback((onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  }, []);

  const getBooleanEmail = useCallback((onOff?: statusType) => {
    return onOff === "A" ? true : false;
  }, []); // A : 수신동의 / D : 수신거부

  // mantis - 10636 뒤로가기 클릭시 저장 되었던 값 적용 (Leehj)
  useEffect(() => {
    if (back && emailSend) {
      setPush(emailSend);
    }
  }, [back, emailSend]);

  // mantis - 10636 뒤로가기 클릭시 저장 되었던 값 적용 (Leehj)
  useEffect(() => {
    if (back && masterEmailStatus) {
      setMailStatus(masterEmailStatus);
    }
  }, [back, masterEmailStatus]);

  // mantis - 10636 뒤로가기 클릭시 저장 되었던 값 적용 (Leehj)
  useEffect(() => {
    if (back && emailSettings) {
      setCurrent(emailSettings);
    }
  }, [emailSettings, back]);

  // mantis - 13586 이전 page 이동 시 팝업 사라짐 (hongcs)
  useEffect(() => {
    if (!back) return;
    setOpenInfoModal(false);
    setOpenSetTimeModal(false);
  }, [back]);

  useEffect(() => {
    if (!isExtendBoxPro) return;

    if (!current?.["ALARM_FATIGUE"]) {
      setFatigueTime(135);
      setFatigueSend("off");
      return;
    }
    setFatigueTime(current["ALARM_FATIGUE"].SETTIME || 135);
    setFatigueSend(current["ALARM_FATIGUE"].SEND);
  }, [current, camera, isExtendBoxPro]);

  // save버튼 비활성화 유무
  useEffect(() => {
    const predicts = _.keys(current).map((k) => {
      const key = k as keyof IEmailNotiSettings;
      if (key === "ALARM_FATIGUE" && emailSettings?.["ALARM_FATIGUE"]) {
        if (
          (emailSettings?.[key].SEND ?? "off") === current?.[key].SEND &&
          emailSettings?.[key].SETTIME === current?.[key].SETTIME
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return (emailSettings?.[key] ?? "off") === current?.[key];
      }
    });
    setDisableSave(
      _.every(predicts) &&
        emailSend === push &&
        masterEmailStatus === mailStatus
    );
  }, [
    getBoolean,
    getBooleanEmail,
    emailSettings,
    current,
    emailSend,
    push,
    masterEmailStatus,
    mailStatus,
  ]);

  useEffect(() => {
    if (camera) {
      dispatch(loadNotification());
      dispatch(loadNotificationEmailList(camera.psn));
    }
  }, [camera, dispatch]);

  const handleUpdateMail = useCallback(() => {
    if (camera && userProfile) {
      dispatch(
        fixMasterEmailNotification({
          psn: camera.psn,
          guestEmail: userProfile.email,
          mailStatus: mailStatus,
        })
      );
    }
  }, [camera, dispatch, mailStatus, userProfile]);

  const handleUpdate = useCallback(() => {
    if (current) {
      dispatch(
        updateNotification({
          setType: "email",
          settings: current,
          send: push,
          fatigueSend,
          fatigueTime,
          // mantis - 11079, app과 동일하게 모바일에서도 토스트팝업 가운데로 나오도록 수정 (Leehj)
          positionCenter: mobile,
        })
      );
    }
  }, [current, dispatch, mobile, push, fatigueSend, fatigueTime]);

  useEffect(() => {
    // mantis : 8512
    // modal이 자동으로 닫히지 않도록 boolean 추가
    if (!loading && emailModal) {
      setOpenInviteModal(false);
      setopenDeleteModal(false);
    }
  }, [emailModal, loading, type]);

  useEffect(() => {
    // mantis : 8512
    // send & delete email 팝업 창 대기 시 자동으로 닫히지 않도록 useState 추가
    if (sendEmail && !loading) {
      setOpenInviteModal(false);
      setopenDeleteModal(false);
    }
  }, [loading, sendEmail, type]);

  const handleChange = useCallback(
    (
      key: keyof IEmailNotiSettings,
      checked: boolean,
      key2?: keyof IEmailNotiSettings
    ) => {
      let newCurrent = {
        ...current,
        [key]: checked ? "on" : "off",
      } as IEmailNotiSettings;
      if (key2) {
        newCurrent = {
          ...newCurrent,
          [key2]: checked ? "on" : "off",
        };
      }
      setCurrent(newCurrent);
      if (app) {
        dispatch(
          updateNotification({
            setType: "email",
            settings: newCurrent,
            fatigueSend,
            fatigueTime,
            send: push,
            // mantis - 11079, app에서 토스트팝업 가운데로 나오도록 수정 (Leehj)
            positionCenter: true,
          })
        );
      }
    },
    [app, current, dispatch, push, fatigueSend, fatigueTime]
  );

  const handleFatigueRiskChange = useCallback(
    (checked: boolean, time?: number) => {
      let newCurrent = {
        ...current,
        ALARM_FATIGUE: checked
          ? { SEND: "on", SETTIME: time }
          : { SEND: "off", SETTIME: time },
      } as IEmailNotiSettings;
      setCurrent(newCurrent);
      if (app) {
        dispatch(
          updateNotification({
            setType: "phone",
            settings: newCurrent,
            send: push,
            fatigueSend,
            fatigueTime,
            positionCenter: true,
          })
        );
      }
    },
    [app, current, dispatch, push, fatigueSend, fatigueTime]
  );

  const handleUpdatePush = useCallback(
    (checked: boolean) => {
      const newPush = checked ? "on" : "off";
      setPush(newPush);

      console.log("handleUpdatePush", newPush, current);

      if (app && current) {
        dispatch(
          updateNotification({
            setType: "email",
            settings: current,
            fatigueSend: current?.ALARM_FATIGUE?.SEND ?? undefined,
            fatigueTime: current?.ALARM_FATIGUE?.SETTIME ?? undefined,
            send: newPush,
            // mantis - 11079, app에서 토스트팝업 가운데로 나오도록 수정 (Leehj)
            positionCenter: true,
          })
        );
      }
    },
    [app, current, dispatch]
  );

  const handleUpdateMasterEmail = useCallback(
    (checked: boolean) => {
      const newMailStatus = checked ? "A" : "D";
      setMailStatus(newMailStatus);

      if (app) {
        if (camera && userProfile) {
          dispatch(
            fixMasterEmailNotification({
              psn: camera.psn,
              guestEmail: userProfile.email,
              mailStatus: newMailStatus,
            })
          );
        }
      }
    },
    [app, camera, dispatch, userProfile]
  );

  const renderSimpleSwitch = useCallback(
    (
      label: string,
      key: keyof IEmailNotiSettings,
      defaultVal: OnOffType,
      key2?: keyof IEmailNotiSettings
    ) => {
      return (
        <div className={classes.cardDiv}>
          <div className={classes.switch}>
            <Switch
              checked={
                key2
                  ? getBoolean(current?.[key] ?? defaultVal) &&
                    getBoolean(current?.[key2] ?? defaultVal)
                  : getBoolean(current?.[key] ?? defaultVal)
              }
              onChange={(e) => {
                handleChange(key, e.target.checked, key2);
              }}
              disabled={!getBoolean(push)}
            />
          </div>
          <Typography
            category="Default"
            variant="Body"
            // 비활성화인 경우 텍스트도 비활성화 (Leehj) 23.01.12
            className={clsx({
              [classes.disabledText]: !getBoolean(push),
              [classes.flex1]: app,
            })}
          >
            {t(label)}
          </Typography>
        </div>
      );
    },
    [
      app,
      classes.cardDiv,
      classes.disabledText,
      classes.flex1,
      classes.switch,
      current,
      getBoolean,
      handleChange,
      push,
      t,
    ]
  );

  const renderGuestRecipient = useCallback(
    (gEmail: IEmailList) => {
      return gEmail.mailStatus === "A" ? (
        <div className={classes.invitePersonDiv}>
          <div className={classes.personIcon}>
            <AccountCircleIcon
              // mantis - 10651 , Email Notifications off면 비활성화 (Leehj)
              className={clsx(!getBoolean(push) && classes.disabledText)}
            />
          </div>
          <Typography
            category="Default"
            variant="Body"
            // mantis - 10651 , Email Notifications off면 비활성화 (Leehj)
            className={clsx(!getBoolean(push) && classes.disabledText)}
          >
            {gEmail.guestEmail}
          </Typography>
        </div>
      ) : (
        <div
          key={`guest-recipient-${gEmail.guestEmail}`}
          className={classes.invitePersonDiv}
        >
          <div className={classes.accessTimeIcon}>
            <AccessTimeIcon />
          </div>
          <div className={classes.accessInfoText}>
            <div className={classes.guestEmailBox}>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={
                  push === "on"
                    ? LightColors.primary["3"]
                    : LightColors.primary["1"]
                }
                // mantis - 10651 , Email Notifications off면 비활성화 (Leehj)
                className={clsx(!getBoolean(push) && classes.disabledText)}
              >
                {gEmail.guestEmail}
              </Typography>
            </div>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={
                push === "on"
                  ? LightColors.primary["4"]
                  : LightColors.primary["2"]
              }
              style={{ marginTop: 1 }}
              // mantis - 10651 , Email Notifications off면 비활성화 (Leehj)
              className={clsx(!getBoolean(push) && classes.disabledText)}
            >
              {gEmail.mailStatus === "E" ? t("Expired") : t("Pending")}
            </Typography>
          </div>
        </div>
      );
    },
    [
      classes.accessInfoText,
      classes.accessTimeIcon,
      classes.disabledText,
      classes.guestEmailBox,
      classes.invitePersonDiv,
      classes.personIcon,
      getBoolean,
      push,
      t,
    ]
  );

  const recipientMe = useMemo(() => {
    // const found = _.find(
    //   notificationEmail,
    //   (e) => e.guestEmail === userProfile?.email
    // );

    // if (found) {
    return (
      <div className={classes.cardDiv}>
        <div className={classes.emailNotiSwitch}>
          <Switch
            checked={getBooleanEmail(mailStatus)}
            // mantis - 10651 , Email Notifications off면 스위치 비활성화 (Leehj)
            // mantis - 11685 , admin인 경우 비활성화 되도록 수정, owner의 선택에 따라 체크표시되며 비활성화 처리.(Leehj)
            disabled={push === "off" || userProfile?.userType === "SubMaster"}
            onChange={(e) => {
              handleUpdateMasterEmail(e.target.checked);
            }}
          />
        </div>
        <Typography
          category="Default"
          variant="Body"
          // mantis - 10651 , 비활성화인 경우 텍스트도 비활성화 (Leehj)
          className={clsx({
            [classes.disabledText]: !getBoolean(push),
            [classes.flex1]: app,
          })}
        >
          {/* mantis - 11685 , 항상 owner이메일로 표시 (Leehj)  */}
          {userProfile?.userType === "SubMaster"
            ? t("(Owner)", {
                a: ownerEmail,
              })
            : t("(Owner)", {
                a: userProfile?.email,
              })}
        </Typography>
      </div>
    );
    // }
  }, [
    app,
    classes.cardDiv,
    classes.disabledText,
    classes.emailNotiSwitch,
    classes.flex1,
    getBoolean,
    getBooleanEmail,
    handleUpdateMasterEmail,
    mailStatus,
    ownerEmail,
    push,
    t,
    userProfile?.email,
    userProfile?.userType,
  ]);

  const fastiguRiskTime = useMemo(() => {
    if (!fatigueTime) return;
    let time: string | number;
    time =
      Math.floor(fatigueTime / 60) < 10
        ? `0${Math.floor(fatigueTime / 60)}h:`
        : `${Math.floor(fatigueTime / 60)}h:`;
    time =
      fatigueTime % 60 < 10
        ? time + `0${fatigueTime % 60}m`
        : time + `${fatigueTime % 60}m`;
    return time;
  }, [fatigueTime]);

  return (
    <div className={classes.contDiv}>
      <ScrollBar>
        <Card variant="outlined" className={classes.cardWrapNoDivider}>
          <div className={classes.cardDiv}>
            <div className={classes.emailNotiSwitch}>
              <Switch
                checked={getBoolean(push)}
                onChange={(e) => handleUpdatePush(e.target.checked)}
              />
            </div>
            <Typography
              category="Default"
              variant="Body"
              className={clsx({ [classes.flex1]: app })}
            >
              {t("Email Notifications")}
            </Typography>
          </div>
          <div className={classes.recipientsDiv}>
            <Typography
              category="Default"
              variant="Body"
              style={{ marginRight: 3 }}
              // mantis - 10651 , 비활성화인 경우 텍스트도 비활성화 (Leehj)
              className={clsx(!getBoolean(push) && classes.disabledText)}
            >
              {t("Recipients")}
            </Typography>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={
                darkMode ? LightColors.primary["0"] : LightColors.primary["3"]
              }
              style={{ marginTop: 3 }}
              // mantis - 10651 , 비활성화인 경우 텍스트도 비활성화 (Leehj)
              className={clsx(!getBoolean(push) && classes.disabledText)}
            >
              {notificationEmail?.length ?? 1}/3
            </Typography>

            <div className={classes.addPersonIcon}>
              <IconButton
                onClick={() => {
                  setOpenInviteModal(true);
                  setEmailModal(false);
                }}
                // mantis - 10651 , Email Notifications off면 추가버튼 비활성화 (Leehj)
                disabled={notificationEmail?.length === 3 || push === "off"}
              >
                <PersonAddIcon
                  className={clsx(
                    (notificationEmail?.length === 3 || push === "off") &&
                      classes.addPersonIconDisabled
                  )}
                />
              </IconButton>
            </div>
          </div>
          {recipientMe}
          {_.chain(notificationEmail)
            // mantis - 11685추가 수정, admin인 경우 owner계정의 메일은 미출력으로 수정 (Leehj)
            .filter((e) => {
              if (userProfile?.userType === "SubMaster") {
                return e.guestEmail !== ownerEmail;
              } else {
                return e.guestEmail !== userProfile?.email;
              }
            })
            .map((e) => (
              <div className={classes.invitePersonWrap}>
                {renderGuestRecipient(e)}

                <div>
                  <IconButton
                    onClick={() => {
                      // mantis : 8512
                      // modal이 자동으로 닫히지 않도록 boolean 추가
                      setEmailModal(false);
                      setDeleteRecipients(e.guestEmail);
                      setopenDeleteModal(true);
                      setSendEmail(false);
                    }}
                    // mantis - 10651 , Email Notifications off면 비활성화 (Leehj)
                    disabled={push === "off"}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              </div>
            ))
            .value()}
        </Card>
        <Divider
          className={clsx(classes.cardDivider, classes.firstCardDivider)}
        />

        {/* mantis - 10627, Manual recording항목 추가 (Leehj) */}
        <Card variant="outlined" className={classes.cardWrap}>
          {renderSimpleSwitch("Manual recording", "ALARM_MANUAL", "on")}
        </Card>

        <Divider
          className={clsx(classes.cardDivider, classes.firstCardDivider)}
        />

        <div className={classes.subTitle}>
          <Typography category="Default" variant="SmallBold">
            {t("Normal event recording")}
          </Typography>
        </div>

        <Card variant="outlined" className={classes.cardWrap}>
          {/* 디자인 수정요청건 반영 - 운행중, 주차중 어휘로 변경, 띄어쓰기 다음 단어는 필터와 같이 대문자로 변경. (Leehj) */}
          {renderSimpleSwitch("Driving Impact", "ALARM_EVENT", "on")}
          {renderSimpleSwitch("Overspeed", "ALARM_SPEED", "off")}
          {renderSimpleSwitch("Hard Braking", "ALARM_HARSHBRAKING", "off")}
          {renderSimpleSwitch("Hard Acceleration", "ALARM_ACCELERATION", "off")}
          {renderSimpleSwitch("Hard Cornering", "ALARM_SHARPTURN", "off")}
        </Card>

        <Divider className={classes.cardDivider} />

        <div className={classes.subTitle}>
          <Typography category="Default" variant="SmallBold">
            {t("Parking event recording")}
          </Typography>
        </div>

        <Card variant="outlined" className={classes.cardWrap}>
          {renderSimpleSwitch("Parking impact", "ALARM_PARK_EVENT", "on")}
          {renderSimpleSwitch("Motion Detection", "ALARM_PARK_MOTION", "off")}
          {renderSimpleSwitch(
            "Parking mode enter_",
            "ALARM_PARK_IN",
            "off",
            "ALARM_PARK_OUT"
          )}
        </Card>

        {/* Geofencing항목은 Fleet Plan사용자에게만 보이는 항목 */}
        {!isFree && (
          <>
            <Divider className={classes.cardDivider} />

            <div className={classes.subTitle}>
              <Typography category="Default" variant="SmallBold">
                {t("Geofencing")}
              </Typography>
            </div>
            <Card variant="outlined" className={classes.cardWrap}>
              {renderSimpleSwitch("Entering", "ALARM_GEOFENCE_ENTER", "off")}
              {renderSimpleSwitch("Exiting", "ALARM_GEOFENCE_EXIT", "off")}
              {renderSimpleSwitch("Passing", "ALARM_GEOFENCE_PASS", "off")}
              {isExtendBoxPro &&
                renderSimpleSwitch(
                  "Speed Alert",
                  "ALARM_GEOFENCE_SPEED",
                  "off"
                )}
            </Card>
          </>
        )}

        {/* mantis- 10584 시그마모델에서는 DR770X모델만 DMS항목 보이도록 수정 /
        DR770X에만 표시됨 (2023.01.27 sigma DMS적용모델 정책 기준) (Leehj) */}
        {/* 코드 단순화 수정 (2024.04.30, hongcs) */}
        {_.includes(
          [...DMC100_MODELS, ...DMC200_MODELS, ...BOXPRO_MODELS],
          camera?.model
        ) &&
          firmwareConfig?.["DMS"] && (
            <>
              <Divider className={classes.cardDivider} />

              <div className={classes.subTitle}>
                <Typography category="Default" variant="SmallBold">
                  {t("Driver Monitoring System (DMS)")}
                </Typography>
              </div>

              <Card variant="outlined" className={classes.cardWrap}>
                {renderSimpleSwitch("Drowsiness", "ALARM_DROWSY", "on")}
                {/* mantis - 10743, 해당 항목 제거 (Leehj) */}
                {/* {_.includes(DMC200_MODELS, camera?.model) &&
                renderSimpleSwitch(
                  "Second Drowsiness Alert",
                  "ALARM_DROWSY_SECOND",
                  "off"
                )} */}
                {renderSimpleSwitch("Distracted", "ALARM_DISTRACTED", "on")}
                {_.includes(BOXPRO_MODELS, camera?.model) &&
                  renderSimpleSwitch("Seat Belt", "ALARM_SEATBELT", "on")}
                {renderSimpleSwitch("Detected", "ALARM_DETECTED", "on")}
                {renderSimpleSwitch("Undetected", "ALARM_UNDETECTED", "on")}
                {_.includes(DMC200_MODELS, camera?.model) &&
                  renderSimpleSwitch(
                    "Hand Distraction",
                    "ALARM_CALLING",
                    "off",
                    "ALARM_SMOKING"
                  )}
                {_.includes(DMC200_MODELS, camera?.model) &&
                  renderSimpleSwitch("Mask", "ALARM_MASK_OFF", "off")}
              </Card>
            </>
          )}

        <Divider className={classes.cardDivider} />

        <div className={classes.subTitle}>
          <Typography category="Default" variant="SmallBold">
            {t("Others")}
          </Typography>
        </div>

        <Card variant="outlined" className={classes.cardWrap}>
          {_.includes(LOW_VOLTAGE_MODELS, camera?.model) &&
            // mantis - 10258  Low voltage warning 항목 문구 수정 (Leehj)
            renderSimpleSwitch(
              "Low voltage warning",
              "ALARM_LOW_VOLTAGE",
              "on"
            )}
          {renderSimpleSwitch(
            "Cloud Connectivity",
            "DEVICE_CONNECT",
            "off",
            "DEVICE_DISCONNECT"
          )}
          {renderSimpleSwitch("Cloud Storage", "ALARM_CLOUDSTORAGE", "on")}
        </Card>

        {isExtendBoxPro && (
          <>
            <Divider className={classes.cardDivider} />
            <Card
              variant="outlined"
              className={classes.cardWrap}
              style={{ marginBottom: 0 }}
            >
              <div className={classes.cardDiv}>
                <div className={classes.switch}>
                  <Switch
                    checked={getBoolean(current?.ALARM_FATIGUE?.SEND ?? "off")}
                    onChange={(e: any) => {
                      handleFatigueRiskChange(
                        e.target.checked,
                        current?.ALARM_FATIGUE?.SETTIME
                      );
                    }}
                    disabled={!getBoolean(push)}
                  />
                </div>
                <div className={classes.arrowCardRow}>
                  <div className={classes.cardRow}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        category="Default"
                        variant="Body"
                        className={clsx({
                          [classes.disabledText]: !getBoolean(push),
                          [classes.flex1]: app,
                        })}
                      >
                        {t("Fatigue Risk Alert")}
                      </Typography>
                      <InfoIcon
                        className={classes.infoIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          setInfoTitle(t("Fatigue Risk"));
                          setInfoDescription(t("Provides a voice alert_"));
                          setOpenInfoModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={clsx(classes.cardDiv, {
                  disabled:
                    !getBoolean(current?.ALARM_FATIGUE?.SEND ?? "off") ||
                    !getBoolean(push),
                })}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    !getBoolean(current?.ALARM_FATIGUE?.SEND ?? "off") ||
                    !getBoolean(push)
                  )
                    return;
                  setOpenSetTimeModal(true);
                }}
              >
                <div className={classes.arrowCardRow}>
                  <div className={classes.cardRow}>
                    <Typography
                      category="Default"
                      variant="Body"
                      className={clsx({
                        [classes.flex1]: app,
                      })}
                    >
                      {t("Set Time")}
                    </Typography>
                    {fastiguRiskTime && (
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["7"]}
                      >
                        {fastiguRiskTime}
                      </Typography>
                    )}
                  </div>
                  <IconButton>
                    <ChevronRightIcon className={classes.arrowIcon} />
                  </IconButton>
                </div>
              </div>
            </Card>

            <div className={classes.appOnly} style={{ padding: 8 }}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["3"]}
              >
                {t("The time is synchronized_Push")}
              </Typography>
            </div>
          </>
        )}

        {!app && (
          <Button
            className={classes.saveBtn}
            color="primary"
            disabled={disableSave}
            loading={
              loading &&
              (type === loadNotification.type ||
                type === updateNotification.type ||
                type === loadNotificationEmailList.type ||
                type === fixMasterEmailNotification.type)
            }
            onClick={() => {
              // mantis - 7450 Email notifications항목과 masterEmail항목을 함께 변경하는 경우handleUpdate()도 실행되도록 추가
              if (push !== emailSend && masterEmailStatus !== mailStatus) {
                handleUpdate();
                handleUpdateMail();
              } else if (masterEmailStatus !== mailStatus) {
                handleUpdateMail();
                // mantis - 11685, masterEmailStatus !== mailStatus인 경우에도 emailsetting 설정 변경가능하도록 함수 추가 (Leehj)
                handleUpdate();
              } else {
                handleUpdate();
              }
            }}
          >
            {t("Save")}
          </Button>
        )}

        {openInviteModal && (
          <InviteModal
            loading={loading && type === invite.type}
            open={openInviteModal}
            onClose={() => {
              // mantis : 8512
              // modal이 자동으로 닫히지 않도록 boolean 추가
              setEmailModal(true);
              setOpenInviteModal(false);
              // mantis : 8512
              // send & Delete 버튼 미클릭 시 팝업 닫히지 않도록 true
              setSendEmail(true);
            }}
            onClickNegative={() => {
              // mantis : 8512
              // modal이 자동으로 닫히지 않도록 boolean 추가
              setEmailModal(true);
              setOpenInviteModal(false);
            }}
            onClickPositive={() => {
              // mantis : 8512
              // modal이 자동으로 닫히지 않도록 boolean 추가
              // Send 버튼 클릭 시 닫히도록 setOpenInviteModal 추가
              setOpenInviteModal(false);
            }}
            darkMode={darkMode}
          />
        )}
        {/* DeleteModal */}
        <PersonDeleteModal
          open={openDeleteModal}
          loading={loading && type === deleteEmail.type}
          deleteRecipients={deleteRecipients}
          onClose={() => {
            // mantis : 8512
            // modal이 자동으로 닫히지 않도록 boolean 추가
            setEmailModal(true);
            setopenDeleteModal(false);
          }}
          onClickNegative={() => {
            // mantis : 8512
            // modal이 자동으로 닫히지 않도록 boolean 추가
            setEmailModal(true);
            setopenDeleteModal(false);
          }}
          onClickPositive={() => {
            // mantis : 8512
            // modal이 자동으로 닫히지 않도록 boolean 추가
            // delete 버튼 클릭 시 모달이 닫히도록 setopenDeleteModal 값 추가
            setEmailModal(true);
            setopenDeleteModal(false);
          }}
          darkMode={darkMode}
        />
        <NotificationSetTimeModal
          open={openSetTimeModal}
          onClose={() => setOpenSetTimeModal(false)}
          app={app}
          fatigueTime={current?.ALARM_FATIGUE?.SETTIME}
          setFatigueTime={(min: number) => {
            handleFatigueRiskChange(
              getBoolean(current?.ALARM_FATIGUE?.SEND ?? "off"),
              min
            );
          }}
          type={"email"}
          darkMode={darkMode}
        />
        <NotificationInfoModal
          open={openInfoModal}
          onClose={() => setOpenInfoModal(false)}
          title={infoTitle}
          description={infoDescription}
          darkMode={darkMode}
        />
      </ScrollBar>
    </div>
  );
};
