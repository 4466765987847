import { detect } from "detect-browser";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ShareEvent } from "../../components/events/ShareEvent";
import { HashtagModal } from "../../components/modals/HashtagModal";
import { loadHashtags } from "../../features/VOD/slice";

interface EventShareScreenProps {
  psn: string;
  filename: string;
  rid?: string | null;
  thmRid?: string | null;
  type?: "sd" | "cloud" | "liveevent" | string | null;
  onClose: VoidFunction;
}

export const EventShareScreen = (props: EventShareScreenProps) => {
  const dispatch = useDispatch();
  const [openTagModal, setOpenTagModal] = useState(false);

  useEffect(() => {
    dispatch(loadHashtags());
  }, [dispatch]);

  //@ts-ignore
  window.backPressed = useCallback(() => {
    const browser = detect();
    if (browser?.name === "ios-webview") {
      //@ts-ignore
      return webkit.messageHandlers.back.postMessage({});
    }
    if (browser?.name === "chromium-webview") {
      //@ts-ignore
      window.Webviewer?.back();
    }
  }, []);

  const mode = useMemo(() => {
    switch (props.type) {
      case "sd":
        return 0;
      case "cloud":
        return 1;
      default:
        return 2;
    }
  }, [props.type]);

  return (
    <>
      <ShareEvent
        {...props}
        mode={mode}
        onOpenHashtag={() => setOpenTagModal(true)}
        onSuccessSave={() => {
          const browser = detect();
          if (browser?.name === "ios-webview") {
            //@ts-ignore
            return webkit.messageHandlers.successShare.postMessage({});
          }
          if (
            browser?.name === "chromium-webview" &&
            //@ts-ignore
            window.Webviewer?.successShare
          ) {
            //@ts-ignore
            window.Webviewer?.successShare();
          }
        }}
        onFailSave={(reason: string) => {
          const browser = detect();
          if (browser?.name === "ios-webview") {
            //@ts-ignore
            return webkit.messageHandlers.failShare.postMessage({
              reason,
            });
          }
          if (
            browser?.name === "chromium-webview" &&
            //@ts-ignore
            window.Webviewer?.failShare
          ) {
            //@ts-ignore
            window.Webviewer?.failShare(JSON.stringify({ reason }));
          }
        }}
      />
      {openTagModal && (
        <HashtagModal
          open={openTagModal}
          onClose={() => {
            setOpenTagModal(false);
          }}
        />
      )}
    </>
  );
};
