import React, { useEffect, useMemo, useRef, useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Avatars,
  Button,
  Container,
  LogoFull,
  LogoOnly,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import { AppBar } from "./AppBar";
import { Toolbar } from "./Toolbar";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { clearLoading, loadUserProfile, USER } from "../features/User/slice";
import { useMediaQuery } from "@material-ui/core";
import { getPlanFromServiceID } from "../utils/Service";
import { loadSubscription, PAYMENT } from "../features/Payment/slice";
import { ProfileMenu } from "./ProfileMenu";
import _ from "lodash";
import { MaintenanceHandler } from "./MaintenanceHandler";
import { LightColors, UserNames, Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  grow: {
    display: "flex",
    flexGrow: 1,
    // justifyContent: "flex-end",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  marginR4: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(4) }
      : { marginRight: theme.spacing(4) }),
  },
  marginL7: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(7) }
      : { marginRight: theme.spacing(7) }),
  },
  tootip: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const LoginHeader = () => {
  interface IState {
    mode?: string;
  }

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const mode = (location.state as IState)?.mode;

  const user_token = useSelector(
    (state: RootState) => state[USER].loginInfo?.user_token
  );
  const { userProfile, needToConfirmEmail } = useSelector(
    (state: RootState) => state[USER]
  );

  const parsedHash = new URLSearchParams(location.hash.substr(1));
  const stateVal = parsedHash.get("state");
  const [openAvatarTooltip, setOpenAvatarTooltip] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { path } = useRouteMatch();

  const profileAnchorRef = useRef<HTMLDivElement>(null);
  const [planName, setPlanName] = useState<string>();
  const [imgSrc, setImgSrc] = useState<string>();

  const subscriptionInfo = useSelector(
    (state: RootState) => state[PAYMENT].subscriptionInfo
  );

  // mantis - 8413 billingPage에서만 profile정보 미표시되어 load
  useEffect(() => {
    if (path === "/billing") {
      dispatch(loadUserProfile());
      dispatch(loadSubscription());
    }
  }, [dispatch, path]);

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    setImgSrc((s) => {
      if (userProfile?.profilePath) {
        return s === userProfile.profilePath ? s : userProfile.profilePath;
      }
      return s;
    });
  }, [userProfile?.lastName, userProfile?.profilePath]);

  useEffect(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const AvatarMarkup = useMemo(() => {
    return (
      <>
        <Tooltip
          disableTouchListener={mobile}
          placement="bottom"
          open={openAvatarTooltip && !openProfileMenu}
          onOpen={() => setOpenAvatarTooltip(true)}
          onClose={() => setOpenAvatarTooltip(false)}
          title={
            <div className={classes.tootip}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {userProfile?.firstName} {userProfile?.lastName}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {userProfile?.email}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {t(planName ?? "")}
                {planName === "Fleet plan" &&
                  userProfile?.userType &&
                  ` · ${t(UserNames[userProfile.userType])}`}
              </Typography>
            </div>
          }
        >
          <Avatars
            style={{ cursor: "pointer" }}
            ref={profileAnchorRef}
            imgSrc={imgSrc}
            // Mantis-8566
            // name={lastName} >> {userProfile?.email}로 수정함.
            name={userProfile?.email}
            onClick={() => {
              setOpenProfileMenu(true);
            }}
          />
        </Tooltip>
      </>
    );
  }, [
    classes.tootip,
    imgSrc,
    mobile,
    openAvatarTooltip,
    openProfileMenu,
    planName,
    t,
    userProfile?.email,
    userProfile?.firstName,
    userProfile?.lastName,
    userProfile?.userType,
  ]);

  const headerTextMarkup = useMemo(() => {
    if (
      user_token &&
      _.includes(
        ["/billing", "/subscription-success", "/subscription-fail"],
        path
      )
    ) {
      return AvatarMarkup;
    } else if (
      (user_token && path === "/signup") ||
      mode !== undefined ||
      needToConfirmEmail
    ) {
      return (
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.primary["2"]}
          className={classes.headerText}
        >
          {t("Already have an account?")}
        </Typography>
      );
    } else {
      return (
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.primary["2"]}
          className={classes.headerText}
        >
          {t("Don’t have an account?")}
        </Typography>
      );
    }
  }, [
    AvatarMarkup,
    classes.headerText,
    mode,
    needToConfirmEmail,
    path,
    t,
    user_token,
  ]);

  const headerBtnMarkup = useMemo(() => {
    if (
      user_token &&
      _.includes(
        ["/billing", "/subscription-success", "/subscription-fail"],
        path
      )
    ) {
      return "";
    } else if (
      (user_token && path === "/signup") ||
      mode !== undefined ||
      stateVal === "free" ||
      needToConfirmEmail
    ) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.push("/login")}
        >
          {t("Log in")}
        </Button>
      );
    } else {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.push("/signup", { mode: "free" })}
        >
          {t("Sign up")}
        </Button>
      );
    }
  }, [history, mode, needToConfirmEmail, path, stateVal, t, user_token]);

  const getLinkName = useMemo(() => {
    if (user_token && (path === "/signup" || path === "/login")) {
      return "/";
    } else if (user_token) {
      return "/cameras";
    } else {
      return "/";
    }
  }, [path, user_token]);

  return (
    <AppBar color="default">
      <Container>
        <Toolbar>
          {/* mobile */}
          <Link to={getLinkName} className={classes.vcenter}>
            <Hidden mdUp>
              <LogoOnly width={36} height={36} />
            </Hidden>
            <Hidden smDown>
              <LogoFull width={166} height={29} />
            </Hidden>
          </Link>
          <div className={classes.grow}>
            <div>
              <Hidden smDown>
                {mode === "fleet" ? (
                  <div style={{ marginLeft: 56.81 }}>
                    <Typography
                      category="Default"
                      variant={path === "/signup" ? "BodyBold" : "Body"}
                      htmlColor={LightColors.primary["1"]}
                      className={clsx(classes.marginR4, classes.marginL7)}
                    >
                      1. {t("Sign up")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant={path === "/billing" ? "BodyBold" : "Body"}
                      htmlColor={LightColors.primary["1"]}
                    >
                      2. {t("Billing information")}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </Hidden>
            </div>
            <div>
              <Hidden smDown>{headerTextMarkup}</Hidden>
              {headerBtnMarkup}
            </div>
            {/* mantis - 8418, safari에서 hover시 아이콘 움직이는 이슈 / AvatarMarkup에서 menu분리하여 수정 */}
            <ProfileMenu
              open={openProfileMenu}
              anchorEl={profileAnchorRef.current}
              placement="bottom-end"
              onClickAway={() => setOpenProfileMenu(false)}
              onCloseMenu={() => setOpenProfileMenu(false)}
            />
          </div>
        </Toolbar>
      </Container>
      <MaintenanceHandler disablePre />
    </AppBar>
  );
};
