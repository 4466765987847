import React, { useEffect, useMemo } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IconButton, Modal, Typography } from "@thingsw/pitta-design-system";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";
import { Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  modalBottom: {
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  modalContentWrap: {
    border: "none",
    padding: theme.spacing(2),
    paddingTop: 9,
    flexDirection: "column",
  },
  step0Content: {
    justifyContent: "center",
  },
  contentWrap: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    marginTop: theme.spacing(4) + 1,
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: theme.spacing(4) - 1,
      textAlign: "inherit",
    },
  },
  content: {
    marginTop: theme.spacing(2) + 2,
    textAlign: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      textAlign: "inherit",
    },
  },
  webBtnDiv: {
    marginTop: theme.spacing(5.75),
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
}));

interface OnboardingMobileProps {
  step: number;
  onNext: () => void;
  onBack: () => void;
  onClose: () => void;
}

const DONE_STEP = 5;

export const OnboardingMobile = ({
  step,
  onNext,
  onClose,
  onBack,
}: OnboardingMobileProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  useEffect(() => {
    // const tourString = localStorage.getItem("pitta-webviewer-tour");
    // const tour = JSON.parse(tourString ?? "[]");
    // if (!_.includes(tour, email)) {
    //   let link = document.createElement("link");
    //   link.rel = "preload";
    //   link.as = "image";
    //   link.href = "/images/onboarding.png";
    //   link.imageSrcset = "/images/onboarding.png 1x";
    //   document.head.appendChild(link);
    //   link = document.createElement("link");
    //   link.rel = "preload";
    //   link.as = "image";
    //   link.href = "/images/onboarding1.png";
    //   link.imageSrcset = "/images/onboarding1.png 1x";
    //   document.head.appendChild(link);
    //   link = document.createElement("link");
    //   link.rel = "preload";
    //   link.as = "image";
    //   link.href = "/images/onboarding2.png";
    //   link.imageSrcset = "/images/onboarding2.png 1x";
    //   document.head.appendChild(link);
    //   link = document.createElement("link");
    //   link.rel = "preload";
    //   link.as = "image";
    //   link.href = "/images/onboarding3.png";
    //   link.imageSrcset = "/images/onboarding3.png 1x";
    //   document.head.appendChild(link);
    //   link = document.createElement("link");
    //   link.rel = "preload";
    //   link.as = "image";
    //   link.href = "/images/onboarding4.png";
    //   link.imageSrcset = "/images/onboarding4.png 1x";
    //   document.head.appendChild(link);
    //   link = document.createElement("link");
    //   link.rel = "preload";
    //   link.as = "image";
    //   link.href = "/images/onboarding5.png";
    //   link.imageSrcset = "/images/onboarding5.png 1x";
    //   document.head.appendChild(link);
    //   link = document.createElement("link");
    //   link.rel = "preload";
    //   link.as = "image";
    //   link.href = "/images/onboarding6.png";
    //   link.imageSrcset = "/images/onboarding6.png 1x";
    //   document.head.appendChild(link);
    //   setStep(0);
    // } else {
    //   setStep(-1);
    // }
  }, []);

  const titleMarkup = useMemo(() => {
    if (step === 0) {
      return "    ";
    } else if (step === 1) {
      return "Map view";
    } else if (step === 2) {
      return "Camera’s features";
    } else if (step === 3) {
      return "GPS tracking";
    } else if (step === 4) {
      return "Geofence";
    } else if (step === 5) {
      return "Manage Fleet members";
    } else if (step === 6) {
      return "Push Notifications";
    } else if (step === 7) {
      return "Event map";
    }
  }, [step]);

  const contentMarkup = useMemo(() => {
    if (step === 0) {
      return (
        <div className={classes.contentWrap}>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            srcSet="/images/onboarding.png 1x"
            alt="onboarding image"
            width={mobile ? "100%" : 670}
          />
          <Typography category="Default" variant="H3" className={classes.title}>
            {t("Welcome to the BlackVue_")}
          </Typography>
          <Typography
            category="Default"
            variant="Body"
            className={classes.content}
          >
            {t("You can access BlackVue_")}
          </Typography>
        </div>
      );
    }
    if (step === 1) {
      return (
        <>
          <div style={{ marginBottom: 95 }}>
            <Typography category="Default" variant="Body">
              {t("Check the current_")}
            </Typography>
          </div>
          <div>
            <img
              srcSet="/images/onboarding1.png 1x"
              alt="onboarding1"
              style={{ width: "100%", minWidth: 292 }}
            />
          </div>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <div style={{ marginBottom: 124 }}>
            <Typography category="Default" variant="Body">
              {t("Take a look_")}
            </Typography>
          </div>
          <div>
            <img
              srcSet="/images/onboarding2.png 1x"
              alt="onboarding2"
              style={{ width: "100%" }}
            />
          </div>
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <div>
            <Typography category="Default" variant="Body">
              {t("Visualize your vehicle’s_")}
            </Typography>
          </div>
          <div style={{ marginBottom: 80 }}>
            {/* Smart 요금제 GPS Tracking 기간 변경에 따라 온보딩 가이드 내용 삭제 24.01.08 (hongcs) */}
            {/* <Typography category="Default" variant="Body">
              ({t("Smart plan_")})
            </Typography> */}
          </div>
          <div>
            <img
              srcSet="/images/onboarding3.png 1x"
              alt="onboarding3"
              style={{ width: "100%" }}
            />
          </div>
        </>
      );
    } else if (step === 4) {
      return (
        <>
          <div style={{ marginBottom: 105 }}>
            <Typography category="Default" variant="Body">
              {t("Create geofences and2_")}
            </Typography>
          </div>
          <div>
            <img
              srcSet="/images/onboarding4.png 1x"
              alt="onboarding4"
              style={{ width: "100%" }}
            />
          </div>
        </>
      );
    } else if (step === 5) {
      return (
        <>
          <div style={{ marginBottom: 111 }}>
            <Typography category="Default" variant="Body">
              {t("Invite fleet members_")}
            </Typography>
          </div>
          <div>
            <img
              srcSet="/images/onboarding5.png 1x"
              alt="onboarding5"
              style={{ width: "100%" }}
            />
          </div>
        </>
      );
    } else if (step === 6) {
      return (
        <>
          <div style={{ marginBottom: 80 }}>
            <Typography category="Default" variant="Body">
              {t("The moment the_")}
            </Typography>
          </div>
          <div>
            <img
              srcSet="/images/onboarding6.png 1x"
              alt="onboarding6"
              style={{ width: "100%" }}
            />
          </div>
        </>
      );
    } else if (step === 7) {
      return (
        <>
          <div style={{ marginBottom: 80 }}>
            <Typography category="Default" variant="Body">
              {t("Explore the graphic_")}
            </Typography>
          </div>
          <div>
            <img
              srcSet="/images/onboarding7.png 1x"
              alt="onboarding7"
              style={{ width: "100%" }}
            />
          </div>
        </>
      );
    }
  }, [classes.content, classes.contentWrap, classes.title, mobile, step, t]);

  const pageNumMarkup = useMemo(() => {
    if (step === 0) {
      return;
    } else if (step === 1) {
      return "1 / 5";
    } else if (step === 2) {
      return "2 / 5";
    } else if (step === 3) {
      return "3 / 5";
    } else if (step === 4) {
      return "4 / 5";
    } else if (step === 5) {
      return "5 / 5";
    } else if (step === 6) {
      return "6 / 7";
    } else if (step === 7) {
      return "7 / 7";
    }
  }, [step]);

  return (
    <Modal
      open
      mobile={mobile}
      onClose={onClose}
      onClickPositive={onNext}
      onClickNegative={onBack}
      heading={t(`${titleMarkup}`)}
      actionClassName={classes.modalBottom}
      contentClassName={clsx(
        classes.modalContentWrap,
        step === 0 && classes.step0Content
      )}
      content={contentMarkup}
      RButton={
        step === 0
          ? t("Start tour")
          : step === DONE_STEP
          ? t("Done")
          : t("Next")
      }
      LButton={step >= 1 ? t("Back") : ""}
      LButtonDisabled={step === 1}
      btnNoMargin={step === 0}
      // mantis - 11030, 모바일에서도 x 버튼 나오도록 수정(Leehj)
      headerBtn={
        <div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      }
      btnCenter
      fullSize={mobile}
      selectedNum={
        <Typography category="Default" variant="Body">
          {pageNumMarkup}
        </Typography>
      }
    />
  );
};
