import React, { useEffect } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { USER } from "../features/User/slice";
import { RootState } from "../features/store";
import { changeDirection, setFontFamily } from "../features/Theme/slice";
import _ from "lodash";
import { Languages } from "@thingsw/pitta-modules";

const AuthorizedRoute = (props: RouteProps) => {
  const dispatch = useDispatch();
  const { i18n: i18next } = useTranslation();
  useEffect(() => {
    const prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
    let lang = prefLang || navigator.language.toLowerCase().substring(0, 2);

    if (!_.includes(_.keys(Languages), lang)) {
      lang = "en";
    }
    switch (lang) {
      case "ar":
        i18next.changeLanguage("ar");
        dispatch(changeDirection("rtl"));
        dispatch(setFontFamily("Noto Naskh Arabic UI"));
        break;
      case "de":
      case "es":
      case "fi":
      case "fr":
      case "id":
      case "it":
      case "nb":
      case "nl":
      case "pl":
      case "ru":
      case "vi":
        i18next.changeLanguage(lang);
        dispatch(setFontFamily("Roboto"));
        break;
      case "th":
        i18next.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans Thai UI"));
        break;
      case "ja":
        i18next.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans JP"));
        break;
      case "ko":
        i18next.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans KR"));
        break;
      case "zh":
      case "sc":
        i18next.changeLanguage("sc");
        dispatch(setFontFamily("Noto Sans SC"));
        break;
      default:
        i18next.changeLanguage("en");
        dispatch(setFontFamily("Roboto"));
    }
  }, [dispatch, i18next]);

  const loginInfo = useSelector((state: RootState) => state[USER].loginInfo);

  return loginInfo ? (
    <>
      <Route {...props} />
    </>
  ) : (
    <Redirect to="/login" />
  );
};

export default AuthorizedRoute;
