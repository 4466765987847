import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CheckBox,
  DateRangePicker,
  Fonts,
  IconButton,
  Menu,
  MobileMenu,
  Modal,
  RadioButton,
  RangeModifier,
  Tab,
  Tabs,
  TaskAlt,
  Typography,
  WebMenuItem,
  Tooltip,
  LocationType,
  MoveToInBox,
  DeleteOutLine,
  MapOutLine,
  CloudUpload,
} from "@thingsw/pitta-design-system";

import { useTranslation } from "react-i18next";
import List, {
  ListRowRenderer,
  RenderedRows,
} from "react-virtualized/dist/commonjs/List";
import axios, { AxiosError } from "axios";
import moment from "moment";
import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import _ from "lodash";

import {
  CAMERA,
  // loadMyCemrasLocation,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import {
  deleteCloudVODFile,
  deleteEventVODFile,
  // IVODToken,
  loadCloudVODList,
  loadCloudVODThm,
  loadEventThumbnail,
  loadEventVODList,
  loadThumbnail,
  loadUrgentVODToken,
  loadVODList,
  moveEventVODFiles,
  // successLoadVODToken,
  updateThmRequest,
  uploadVODFile,
  VOD,
  clearVOD as sliceClearVOD,
  successloadVODList,
  setCancelDelete,
  loadHashtags,
  setSelectedHashtags,
  downloadVOD,
  clearBuffer,
  setLoading,
  setRequestThumb,
  resetThmRequest,
  setCloudLimit,
} from "../../features/VOD/slice";

import FilterListIcon from "@material-ui/icons/FilterList";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/Help";

import { loadUsageInfo, USER } from "../../features/User/slice";

import { VideoPlayer } from "./VideoPlayer";
import { GSensorPlayer } from "../GSensorPlayer";
import { DrowsyGraph } from "../DrowsyGraph";
import FileListItem from "@thingsw/pitta-design-system/dist/components/FileListItem";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { doDownload, multiDownload } from "../../utils/VOD";
import { ScreenDefaultProps } from "../../hoc/withViewerTemplate";
// import { getVODToken } from "../../apis";
import { PAYMENT } from "../../features/Payment/slice";
import { getPlanFromServiceID } from "../../utils/Service";
import RadioGroup from "@material-ui/core/RadioGroup";

// import { ExceedModal } from "../modals/ExceedModal";
import { isFree100Check } from "../../utils/isFree100Check";
import { renderListHelper } from "../../utils/List";
import { AutoSizer } from "react-virtualized";
import { eventmapJwtAxiosInst } from "../../utils";
import { EventShareModal } from "../modals/EventShareModal";
import { HashtagModal } from "../modals/HashtagModal";
import { EVENTMAP_MODE_TO_TYPE } from "../events/ShareEvent";
import { VideoExceedModal } from "../modals/VideoExceedModal";
import { DownloadVideoExceedModal } from "../modals/DownloadVideoExceedModal";
import { PricingModal } from "../modals/PricingModal";
import {
  AI_EVENTS,
  API_GATEWAY_URI,
  DROWS_SUPPORTED,
  FileTypeName,
  IAccel,
  ICameraInfo,
  ICameraVOD,
  IDrow,
  IGPSLocation,
  IVOD,
  jwtAxiosInst,
  LightColors,
  MODEL_KEY,
  OPTION_CAMERA_MODELS,
  PermissionProps,
  PSN650,
  RESULT_CODE,
  VideoQualityType,
  Webviewer,
} from "@thingsw/pitta-modules";
import { MapboxLocationMapWrapper } from "../maps/MapboxLocationMapWrapper";

export interface PlaybackPanelProps {
  className?: string;
  onBackScroll?: (scroll: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      overflow: "hidden",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        paddingTop: theme.spacing(3),
        margin: theme.spacing(0, 4, 2, 4),
      },
      position: "relative",
    },
    topContainerDiv: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      "& .transitionHeight": {
        overflow: "hidden",
        transition: theme.transitions.create("height"),
        height: 0,
      },
    },
    topContainerDivMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        flexDirection: "row",
        flexWrap: "wrap",
      },
    },
    topContainerDivMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        flexDirection: "row",
        flexWrap: "wrap",
      },
    },
    playDiv: {
      flex: "1 1 50%",
      transition: theme.transitions.create("width"),
      marginBottom: theme.spacing(1),
    },
    playDivMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        marginBottom: theme.spacing(0),
        ...(theme.direction === "rtl"
          ? { marginLeft: theme.spacing(3) }
          : { marginRight: theme.spacing(3) }),
      },
    },
    playDivMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(0),
      },
    },
    playDivTheater: {
      width: "100%",
      marginRight: 0,
      flex: "none",
    },
    listDiv: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minWidth: 330,

      [theme.breakpoints.down(Webviewer.playbackMobile)]: {
        minHeight: 400,
      },
    },
    listDivTheater: {
      order: 2,
      marginTop: theme.spacing(3),
      height: 188,
      flexBasis: 321,
    },
    listBorder: {
      border: `1px solid ${LightColors.primary["6"]}`,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    tabDiv: {
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
      minHeight: theme.spacing(6),

      "& .MuiTabScrollButton-root": {
        width: 32,
      },
      "& .MuiTabScrollButton-root.Mui-disabled": {
        display: "none",
      },
    },
    filterDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0.9735, 1.375),
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
    filterBtn: {
      padding: 3,
      color: LightColors.primary["2"],
      marginRight: theme.spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
    },
    fileListDiv: {
      marginTop: 4,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: LightColors.primary["6"],
      },
    },
    playList: {
      // width: "100%!important",
      "&>div": {
        maxWidth: "100%!important",
      },
    },
    mapDiv: {
      height: 288,
      width: "100%",
      position: "relative",
      overflow: "hidden",
      transition: theme.transitions.create("height"),
      marginTop: theme.spacing(3),
      [theme.breakpoints.down(Webviewer.mobile)]: {
        height: 320,
        marginTop: 0,
      },
      "&.noDrows": {
        width: "calc(50% - 12px)",
        [theme.breakpoints.down(1440)]: {
          width: "100%",
        },
      },
      "&.opened": {
        height: 368,
      },
    },
    mapDivMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        height: "max(188px, calc(100vh - 920px))",
        flex: "1 1 50%",
        marginTop: theme.spacing(3),
      },
    },
    mapDivMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        height: "max(188px, calc(100vh - 920px))",
        flex: "1 1 50%",
        marginTop: theme.spacing(3),
      },
    },
    mapDivWideMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        flex: "unset",
        width: "calc(50% - 12px)",
        height: 205,
        marginTop: theme.spacing(3),
        ...(theme.direction === "rtl"
          ? { marginLeft: theme.spacing(1.5) }
          : { marginRight: theme.spacing(1.5) }),
      },
    },
    mapDivWideMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        flex: "unset",
        width: "calc(50% - 12px)",
        height: 205,
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(1.5),
      },
    },
    mapDivTheater: {
      order: 1,
      width: "calc(100% - 321px - 24px)",
      minWidth: 1,
      marginRight: theme.spacing(3),
    },
    theaterBlankDiv: {
      flexBasis: 321,
      order: 4,
    },
    delete: {
      color: LightColors.secondary["11"],
    },
    divider: {
      // margin: theme.spacing(1, 0),
    },
    actionDiv: {
      borderTop: `1px solid ${LightColors.primary["5"]}`,
    },
    actionRoot: {
      "&.Mui-disabled > span": {
        opacity: 0.25,
      },
    },
    actionWrapper: {
      color: LightColors.primary["1"],
      "&:hover": {
        color: LightColors.primary["7"],
      },
      "&:active": {
        color: LightColors.primary["8"],
      },
    },
    actionWrapperRed: {
      color: LightColors.secondary["11"],
      "&:hover": {
        color: LightColors.secondary["12"],
      },
      "&:active": {
        color: LightColors.secondary["11"],
      },
    },
    //@ts-ignore
    actionLabel: {
      ...Fonts.Default.Caption,
    },
    loadingDiv: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(19, 19, 28, 0.45)",
    },
    circularLoading: {
      color: LightColors.primary["0"],
    },
    mobileMemuItem: {
      padding: "15px 16px 11px",
      // padding: theme.spacing(1.5, 2),
    },
    drowsyDiv: {
      display: "inline-block",
      width: "calc(50% - 12px)",
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
      verticalAlign: "top",
      border: `1px solid ${LightColors.primary["6"]}`,
      [theme.breakpoints.down(1440)]: {
        width: "100%",
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 0,
      },
      [theme.breakpoints.down(Webviewer.mobile)]: {
        height: 0,
        border: 0,
      },
      "&.opened": {
        height: 318,
      },
    },
    gSensorDiv: {
      width: "calc(50% - 12px)",
      [theme.breakpoints.down(1440)]: {
        width: "100%",
      },
      [theme.breakpoints.up(Webviewer.mobile)]: {
        display: "inline-block",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        border: `1px solid ${LightColors.primary["6"]}`,
      },
      "&.noDrows": {
        width: "calc(50% - 12px)",
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down(1440)]: {
          width: "100%",
          marginLeft: 0,
        },
      },
      "&.opened": {
        height: 252,
      },
    },
    gSensorDivMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        width: "100%",
        marginTop: theme.spacing(3),
      },
    },
    gSensorDivMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        width: "100%",
        marginTop: theme.spacing(3),
      },
    },
    gSensorDivWideMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        width: "calc(50% - 12px)",
        order: 4,
        marginTop: theme.spacing(3),
        ...(theme.direction === "rtl"
          ? { marginRight: theme.spacing(1.5) }
          : { marginLeft: theme.spacing(1.5) }),
      },
    },
    gSensorDivWideMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        width: "calc(50% - 12px)",
        order: 4,
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1.5),
      },
    },
    gSensorDivTheaterMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        width: "calc(100% - 321px - 24px)",
        order: 2,
        marginLeft: 0,
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(3),
      },
    },
    gSensorDivTheaterMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        width: "calc(100% - 321px - 24px)",
        order: 2,
        marginLeft: 0,
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(3),
      },
    },
    filterDrawerDiv: {
      width: "100vw",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        width: 469,
      },
    },
    filterTitleDiv: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
    filterListDiv: {
      flex: 1,
      margin: theme.spacing(1, 2),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        margin: theme.spacing(1, 3),
      },
    },
    filterItemDiv: {
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
      "&:last-child": {
        borderBottom: 0,
      },
    },
    radioBtn: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
    radioFormControl: {
      marginLeft: 8,
    },
    filterBtnDiv: {
      display: "flex",
      justifyContent: "flex-end",
      borderTop: `1px solid ${LightColors.primary["6"]}`,
      padding: theme.spacing(2),
    },
    listEmptyDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
      width: "100%",
    },
    listEmptyDivMenuOpen: {
      [theme.breakpoints.up(Webviewer.playbackMobile)]: {
        // mantis - 11661, 영상 없는 상태 문구 가운데 정렬 (Leehj)
        // width: 328,
      },
    },
    listEmptyDivMenuClose: {
      [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
        width: 328,
      },
    },
    usedProgDiv: {
      position: "relative",
      width: 112,
      height: 3,
      marginTop: 3,
      backgroundColor: LightColors.primary["5"],
    },
    usedBg: {
      height: "100%",
    },
    selectCloseBtn: {
      marginRight: theme.spacing(1.5),
      padding: theme.spacing(0.5),
    },
    modalContent: (props: { openMenu: boolean }) => ({
      padding: theme.spacing(0, 3.5, 5, 2),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: theme.spacing(2.25, 3, 6),
      },
    }),
    modalBottom: {
      padding: theme.spacing(0.5, 2, 1.625, 3),
    },
    resultModalBottom: {
      padding: theme.spacing(0.5, 3, 2.625, 3),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: theme.spacing(0.5, 3, 2.625, 3),
      },
    },
    resultModalTitle: {
      padding: "16px 13px 0 18px",
      [theme.breakpoints.up(Webviewer.mobile)]: {},
    },
    modalWrap: {
      [theme.breakpoints.up(Webviewer.mobile)]: {
        ...(theme.direction === "rtl"
          ? { marginRight: 235 }
          : { marginLeft: 235 }),
      },
    },
    resultModalWrap: {
      borderRadius: 12,
      margin: 16,
      [theme.breakpoints.up(Webviewer.mobile)]: {
        ...(theme.direction === "rtl"
          ? { marginRight: 235 }
          : { marginLeft: 235 }),
      },
    },
    modalTitle: {
      minHeight: 16,
      padding: `18px 24px 0 24px`,
      [theme.breakpoints.up(Webviewer.mobile)]: {
        ...(theme.direction === "rtl"
          ? { padding: `13px 24px 0 13px` }
          : { padding: `13px 13px 0 24px` }),
      },
    },
    downloadModalTitle: {
      minHeight: 16,
      padding: `24px 24px 0 16px`,
      [theme.breakpoints.up(Webviewer.mobile)]: {
        ...(theme.direction === "rtl"
          ? { padding: `13px 22px 0 13px` }
          : { padding: `13px 13px 0 22px` }),
      },
    },
    closeIconStyle: { marginTop: 6 },

    exceedModalRoot: {
      minWidth: 288,
    },
    downloadModalRoot: {
      margin: theme.spacing(2),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        maxWidth: 437,
        boxSizing: "border-box",
      },
    },
    exceedModalContentDiv: {
      padding: theme.spacing(3),
    },
    downloadModalContentDiv: {
      padding: theme.spacing(2.125, 2.25, 2.75),
    },
    downloadMobileModal: {
      padding: theme.spacing(3, 1.125, 2.25),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: theme.spacing(3, 2.375),
      },
    },
    downloadModalbottom: {
      padding: "0px 24px 24px",
    },
    eventmapModalTitle: {
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      fontSize: 18,
    },
    eventmapModalContent: {
      padding: "12px 21px 32px",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: "12px 21px 20px",
      },
    },
    modalContentStyle: {
      padding: "12px 21px 18px",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: "12px 24px 20px",
      },
    },
    modalRBtn: {
      fontWeight: 400,
    },
    formControlLabel: {
      marginLeft: 5,
      marginRight: 0,
    },
    subTitle: {
      marginBottom: theme.spacing(2) - 3,
      ...(theme.direction === "rtl" ? { marginRight: 4 } : { marginLeft: 4 }),
    },
    typeTitleDiv: {
      margin: theme.spacing(4, 0, 3),
      ...(theme.direction === "rtl" ? { marginRight: 6 } : { marginLeft: 6 }),
    },
    checkMargin: {
      marginBottom: "0px",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        marginBottom: theme.spacing(1.25),
      },
    },
    checkBoxSpacing: {
      ...(theme.direction === "rtl" ? { marginLeft: 3 } : { marginRight: 3 }),
    },
    dataTitle: {
      display: "block",
      padding: "12px 12px 0",
      marginBottom: "10px",
      [theme.breakpoints.down(Webviewer.mobile)]: {
        display: "none",
      },
    },
    contMobMenu: {
      width: "100%",
      padding: "18px",
      borderTop: `1px solid ${LightColors.primary["6"]}`,
      marginTop: "-1px",
      cursor: "pointer",
      "& svg": {
        transition: "transform .2s ease-in-out",
      },
      "&.contMobMenuOpened": {
        backgroundColor: "#E7F5FC",
      },
      "&.contMobMenuOpened svg": {
        transform: "rotate(180deg)",
      },
    },
    iconMap: {
      display: "inline-block",
      verticalAlign: "text-bottom",
      height: "22px",
      width: "22px",
      marginRight: "8px",
      background: "url(/images/iconMap.svg) no-repeat 0 -32px",
    },
    iconGsens: {
      display: "inline-block",
      verticalAlign: "text-bottom",
      height: "22px",
      width: "22px",
      marginRight: "8px",
      background: "url(/images/iconGsens.svg) no-repeat 0 -32px",
    },
    iconDrows: {
      display: "inline-block",
      verticalAlign: "text-bottom",
      height: "22px",
      width: "22px",
      marginRight: "8px",
      background: "url(/images/iconDrows.svg) no-repeat 0 -32px",
    },
    expandIcon: {
      padding: 0,
      position: "absolute",
      right: "15px",
    },
    eventmapOkDiv: {
      marginTop: 20,
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        marginTop: 12,
      },
    },

    gutter: {
      width: 343,
      minHeight: 48,
    },
    paperMobile: {
      borderRadius: "12px 12px 0px 0px",
      padding: 16,
    },
    dateMobile: {
      padding: "6px 58px 21px",
    },
    iconMobile: {
      marginRight: 15,
    },
    mobileModalTitle: {
      [theme.breakpoints.down(Webviewer.mobile)]: {
        padding: "24px 16px 0px",
      },
    },
    mobileModalBtn: {
      [theme.breakpoints.down(Webviewer.mobile)]: {
        padding: "0px 16px 24px",
        // mantis 10496 모바일 일 때 버튼 가운데로 이동(Leehj)
        justifyContent: "center",
      },
    },
    downloadModalBtn: {
      [theme.breakpoints.down(Webviewer.mobile)]: {
        padding: "0px 14px 24px 16px",
        // mantis 10496 모바일 일 때 버튼 가운데로 이동(Leehj)
        justifyContent: "center",
      },
    },
    modalBtn: {
      [theme.breakpoints.down(Webviewer.mobile)]: {
        minWidth: 151.5,
      },
    },
    downloadRadipGruop: {
      paddingLeft: 7,
      paddingTop: 4,
    },
  })
);

export const PlaybackPanel = ({
  className,
  uploadVodPerm,
  downloadCameraVodPerm,
  downloadCloudVodPerm,
  deleteVodPerm,
  moveToCloudVodPerm,
  cameraVodPerm,
  cloudVodPerm,
  shareToEventmapPerm,
  openMenu,
  onBackScroll,
}: PlaybackPanelProps & PermissionProps & ScreenDefaultProps) => {
  const classes = useStyles({ openMenu });
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const thumbRequestTimerRef = useRef<NodeJS.Timeout>();
  const rowInfoRef = useRef<RenderedRows>({
    overscanStartIndex: 0,
    overscanStopIndex: 0,
    startIndex: 1,
    stopIndex: 5,
  });
  const buttonsRef = useRef<RefObject<HTMLButtonElement>[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLButtonElement>(null);
  // const firstTabRef = useRef<HTMLDivElement>(null);
  const secondTabRef = useRef<HTMLDivElement>(null);
  const thirdTabRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);
  const rootDivRef = useRef<HTMLDivElement>(null);
  const [GPSMobMenu, setGPSMobMenu] = useState(false);
  const [drowsyMobMenu, setDrowsyMobMenu] = useState(false);
  const [gSensMobMenu, setGSensMobMenu] = useState(false);
  const [openHelpTooltip, setOpenHelpTooltip] = useState(false);
  const firstRender = useRef(true);
  const [drowsExist, setDrowsExist] = useState(false);

  const [firstTab, setFirstTab] = useState<HTMLDivElement | null>(null);

  const userState = useSelector((state: RootState) => state[USER]);
  const cameraState = useSelector((state: RootState) => state[CAMERA]);

  const { cameraList, camera: cam, firmwareConfig } = cameraState;
  const vodListRef = useRef<List>(null);

  const {
    loading,
    vodList,
    thmRequests,
    cloudVodList,
    eventVodList,
    thm,
    type,
    cloudLimit,
  } = useSelector((state: RootState) => state[VOD]);

  const {
    email,
    loginInfo,
    tokenType,
    usageInfo: userUsage,
    userProfile,
  } = userState;

  const [gpsRecording, setGPSRecording] = useState<boolean>();
  const [onScroll, setOnScroll] = useState(false);
  const [requestPause, setRequestPause] = useState<any>();
  const [value, setValue] = useState<0 | 1 | 2>(2);
  const [, /*preValue*/ setPreValue] = useState(2);
  // vod 재생 유지를 위해서 추가
  const [vodValue, setVodValue] = useState<0 | 1 | 2>(2);
  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [currentVOD, setCurrentVOD] = useState<ICameraVOD>();
  const [playVOD, setPlayVOD] = useState<ICameraVOD>();
  const [playVODIndx, setPlayVODIndx] = useState<number>();
  const [listHeight, setListHeight] = useState<number>();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openExceedModal, setOpenExceedModal] = useState(false);
  const [openDownloadExceedModal, setOpenDownloadExceedModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openEventShareModal, setOpenEventShareModal] = useState(false);
  const [openHashtagModal, setOpenHashtagModal] = useState(false);
  const [sharingVOD, setSharingVOD] = useState<ICameraVOD>();
  const [selectedVODs, setSelectedVODs] = useState<ICameraVOD[]>([]);
  const [selectMode, setSelectMode] = useState(false);
  const [allSelect, setAllSelect] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [theater, setTheater] = useState(false);
  const [location, setLocation] = useState<IGPSLocation>();
  const [locations, setLocations] = useState<IGPSLocation[]>([]);
  const [accels, setAccels] = useState<IAccel[]>([]);
  const [drowsData, setDrowsData] = useState<IDrow[]>([]);
  const [gpsTime, setGpsTime] = useState<string>();
  const [updatedTimestamp, setUpdatedTimestamp] = useState<number>();
  const [timestamp, setTimestamp] = useState<number>();
  const [totalTimestamp, setTotalTimestamp] = useState<number>();
  const [quality, setQuality] = useState<VideoQualityType>("low");
  const [downloadQuality, setDownloadQuality] = useState<VideoQualityType>(
    "low"
  );
  const [downloadDirections, setDownloadDirections] = useState<LocationType[]>([
    "Front",
  ]);
  const [uploadDirection, setUploadDirection] = useState<LocationType>("Front");
  const [videoLoading, setVideoLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterEventN, setFilterEventN] = useState(true);
  const [filterEventE, setFilterEventE] = useState(true);
  const [filterEventP, setFilterEventP] = useState(true);
  const [filterEventM, setFilterEventM] = useState(true);
  const [appliedEventN, setAppliedEventN] = useState(true);
  const [appliedEventE, setAppliedEventE] = useState(true);
  const [appliedEventP, setAppliedEventP] = useState(true);
  const [appliedEventM, setAppliedEventM] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [openWarnModal, setOpenWarnModal] = useState(false);
  const [openCloudLimitModal, setOpenCloudLimitModal] = useState(false);

  // mantis - 12484, 탭 이동 시 cloud storage full 팝업이 노출되지 않도록 적용 (Leehj)
  const [openWarn100Modal, setOpenWarn100Modal] = useState(false);
  // const [screenMode, setScreenMode] = useState<"wide" | "default">("default");
  const [planName, setPlanName] = useState<string>();
  const [menuDate, setMenuDate] = useState<string>();
  const [menuTime, setMenuTime] = useState<string>();

  const [filterDate, setFilterDate] = useState<RangeModifier>({
    from: null,
    to: null,
  });
  const [appliedDate, setAppliedDate] = useState<RangeModifier>({
    from: null,
    to: null,
  });
  const [downloadMode, setDownloadMode] = useState<"single" | "multiple">(
    "single"
  );
  const [camera, setCamera] = useState<ICameraInfo>();
  const [latLng, setLatLng] = useState<{
    latitude?: number;
    longitude?: number;
  }>({});
  const init = useRef(false);
  const [anchorRef, setAnchorRef] = useState<
    RefObject<HTMLButtonElement> | undefined
  >();

  const [openPricing, setOpenPricing] = useState(false);

  const CLOUD_NATIVE3 = useMemo(() => {
    if (firmwareConfig === undefined) {
      return undefined;
    }
    return !!firmwareConfig?.CloudNative3;
  }, [firmwareConfig]);

  const showDrows = useMemo(() => {
    if (!camera || !playVOD) return false;
    const eventCode = playVOD.eventCode;
    const model = camera.model as MODEL_KEY;
    const version = DROWS_SUPPORTED[model];
    return version && (version === 1.0 || version <= parseFloat(camera.fw_ver)) && eventCode === "D"
      ? true
      : false;
  }, [camera, playVOD]);

  const [eventShareStatus, setEventShareStatus] = useState<
    "success" | "exist" | "nogps" | "noperm" | undefined
  >();

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const ExceptionPSN = useMemo(
    () => _.includes(PSN650, camera?.psn.substr(0, 4)),
    [camera?.psn]
  );

  // mantis - 8882, 다운로드 버튼 disabled조건 추가(Leehj)
  const downloadDisabled = useMemo(() => {
    if (ExceptionPSN) {
      return true;
    } else if (value === 0 && !downloadCameraVodPerm) {
      return true;
    } else if ((value === 1 || value === 2) && !downloadCloudVodPerm) {
      return true;
    } else {
      return false;
    }
  }, [ExceptionPSN, downloadCameraVodPerm, downloadCloudVodPerm, value]);

  //7 BOX option카메라 관련 추가 (8474)
  const isOptionModel = useMemo(
    () => _.includes(OPTION_CAMERA_MODELS, camera?.model),
    [camera?.model]
  );

  const handleDownload = useCallback(async (url: string, filename?: string) => {
    setRequestPause({});
    return doDownload(url, filename);
  }, []);

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const free100Check = useMemo(() => {
    if (subscriptionInfo && userUsage) {
      return isFree100Check(subscriptionInfo, userUsage);
    }
  }, [subscriptionInfo, userUsage]);

  useEffect(() => {
    const countDrowsyViews = async () => {
      try {
        const resp = await axios.post(
          `${API_GATEWAY_URI}/BCS/countDrowsyGraphViews?type=load`
        );
        if (resp.data.resultcode === "BC_ERR_OK") {
          console.log("Drowsy View Counting Loaded!");
        }
      } catch (err) {
        console.log("errCode:", err);
      }
    };
    if (firstRender.current) firstRender.current = false;
    else {
      if (showDrows) {
        if (!playVOD) {
          setDrowsExist(false);
        } else if (playVOD.eventCode === "D" && drowsData.length > 0) {
          setDrowsExist(true);
          countDrowsyViews();
        } else {
          setDrowsExist(false);
        }
      }
      if (mobile) {
        setGSensMobMenu(true);
        setGSensMobMenu(false);
        setDrowsyMobMenu(false);
      }
    }
  }, [mobile, playVOD, drowsData.length, showDrows]);

  useEffect(() => {
    if (camera && latLng.latitude && latLng.longitude) {
      setLocation({
        lat: latLng.latitude,
        lng: latLng.longitude,
        name: camera.dev_name,
        mode: "0",
      } as IGPSLocation);
    }
    //  else {
    //   const cancel = new AbortController();
    //   dispatch(loadMyCemrasLocation({ cancel }));
    //   return () => {
    //     cancel.abort();
    //   };
    // }
  }, [camera, latLng.latitude, latLng.longitude]);

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    dispatch(loadHashtags());
    return () => {
      dispatch(clearBuffer());
      dispatch(sliceClearVOD());
    };
  }, [dispatch]);

  useEffect(() => {
    const cm = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === cam?.psn)
      .value();
    if (cm?.latitude && cm?.longitude) {
      setLatLng({
        latitude: parseFloat(cm.latitude),
        longitude: parseFloat(cm.longitude),
      });
    }
  }, [cam, cameraList?.deviceListInfo]);

  useEffect(() => {
    const cm = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === cam?.psn)
      .value();
    setCamera((c) => {
      if (c?.psn !== cm?.psn) {
        return cm;
      } else if (c?.active !== cm?.active) {
        return cm;
      }
      return c;
    });
  }, [cam, cameraList?.deviceListInfo]);

  useEffect(() => {
    if (requestPause) {
      setRequestPause(undefined);
    }
  }, [requestPause]);

  useEffect(() => {
    setFilterApplied(
      !(appliedEventN && appliedEventE && appliedEventP && appliedEventM) ||
        (!!appliedDate.from && !!appliedDate.to)
    );
  }, [
    appliedEventN,
    appliedEventE,
    appliedEventP,
    appliedEventM,
    appliedDate.from,
    appliedDate.to,
  ]);

  const getTabVOD = useCallback(
    (tab: number) => {
      let vods: ICameraVOD[] | undefined;
      if (tab === 0) {
        vods = vodList;
      } else if (tab === 1) {
        vods = cloudVodList?.filelist;
      } else if (tab === 2) {
        // mantis - 11259, eventVodList타입 수정하면서 아래 코드도 수정 (Leehj)
        vods = eventVodList?.filelist;
      }
      const eventList: FileTypeName[] = [];
      if (appliedEventN) {
        eventList.push("Normal");
      }
      if (appliedEventE) {
        eventList.push("Event");
      }
      if (appliedEventP) {
        eventList.push("Parking");
      }
      if (appliedEventM) {
        eventList.push("Manual");
      }

      console.log("currentVODs", vods);
      if (!vods) {
        init.current = true;
        return;
      }

      const filtered = _.filter(vods, (v) => _.includes(eventList, v.event));
      if (appliedDate.from && appliedDate.to) {
        return _.filter(
          filtered,
          (vod) =>
            vod.time.isSameOrAfter(appliedDate.from, "D") &&
            vod.time.isSameOrBefore(appliedDate.to, "D")
        );
      }
      init.current = true;

      return filtered;
    },
    [
      appliedDate.from,
      appliedDate.to,
      appliedEventE,
      appliedEventM,
      appliedEventN,
      appliedEventP,
      cloudVodList?.filelist,
      eventVodList?.filelist,
      vodList,
    ]
  );

  // 13814 - 재생중에 탭바꾸는 경우 기존 재생중이던 탭의 vod정보를 별도로 분리
  const playedTabVods = useMemo(() => {
    return getTabVOD(vodValue);
  }, [getTabVOD, vodValue]);

  // 현재 리스트에 표시되는 vod 리스트
  const currentVODs = useMemo(() => {
    if (loading) return;
    return getTabVOD(value);
  }, [getTabVOD, loading, value]);

  // mantis - 8827, 멀티전체선택 안되는 이슈 수정(Leehj) , return추가
  const handleAllSelect = useCallback(() => {
    setAllSelect((s) => !s);
    if (allSelect) {
      setSelectedVODs(currentVODs ?? []);
      return;
    }
    setSelectedVODs([]);
  }, [allSelect, currentVODs]);

  // const getLink = useCallback(
  //   (vod: IVOD, token: IVODToken, vodQuality: VideoQualityType) => {
  //     if (camera && loginInfo) {
  //       const { lb_server_name, lb_http_port, psn } = camera;
  //       const { user_token: userToken } = loginInfo;
  //       const { filename, lowFilename } = vod;
  //       const { vod_token } = token;
  //       let fname = filename;
  //       if (vodQuality === "low" && lowFilename) {
  //         fname = lowFilename;
  //       }
  //       return `${getLbURI(
  //         lb_server_name,
  //         lb_http_port
  //       )}/proc/vod_file?email=${email}&user_token=${userToken}&psn=${psn}&filename=${fname}&vod_token=${vod_token}&tokenType=web`;
  //     }
  //   },
  //   [camera, loginInfo, email]
  // );

  const getCloudLink = useCallback(
    async (vod, vodQuality: VideoQualityType) => {
      if (camera && loginInfo) {
        const { user_token: userToken } = loginInfo;
        const { filename, lowFilename } = vod;
        const { psn } = camera;
        let fname = filename;
        if (vodQuality === "low" && lowFilename) {
          fname = lowFilename;
        }
        return jwtAxiosInst.get(
          `/BCS/userS3PresignedUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&filename=${fname}&tokenType=web`
        );
      }
    },
    [email, camera, loginInfo]
  );

  const getEventLink = useCallback(
    async (vod) => {
      if (camera && loginInfo) {
        const { user_token: userToken } = loginInfo;
        const { rid } = vod;
        const { psn } = camera;
        return jwtAxiosInst.get(
          `/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
        );
      }
    },
    [email, camera, loginInfo]
  );

  // 클라우드연결 카메라 Playback진입시 디폴트 스토리지위치  Camera --> Live Event Upload로 변경 (Leehj) 23.01.10
  // useEffect(() => {
  //   if (!camera) return;
  //   if (camera.active === "on" && cameraVodPerm) {
  //     setValue(0);
  //   }
  // }, [camera, cameraVodPerm, cloudVodPerm]);

  useEffect(() => {
    if (timestamp) {
      // const indx = Math.min(Math.floor(timestamp), locations.length);
      const indx = Math.floor(timestamp);
      const loc = _.find(locations, (loc) => loc.time === indx);
      console.log("timestamp", timestamp, indx, loc);
      setLocation(loc);
      // if (loc) {

      // } else {
      //   setLocation(loc)
      // }
    }
  }, [timestamp, locations]);

  useEffect(() => {
    if (!loading) {
      setOpenDeleteModal(false);
      setSelectedVODs([]);
    }
  }, [loading]);

  // console.log(videoProps);

  useEffect(() => {
    if (camera) {
      if (loginInfo?.userType === "Master" || loginInfo?.userType === "Etc") {
        dispatch(loadUsageInfo());
      }
      // dispatch(loadCloudVODList(camera.psn));
    }
  }, [camera, dispatch, loginInfo?.userType]);

  useEffect(() => {
    const requestCNVod = async (camera: ICameraInfo) => {
      dispatch(setLoading());
      const resp = await jwtAxiosInst.post(`/IoT/devicecommand`, {
        command: "VODList",
        email,
        user_token: loginInfo?.user_token,
        tokenType: tokenType,
        psn: camera?.psn,
      });
      if (resp.data?.["filelist"]) {
        dispatch(successloadVODList(resp.data["filelist"]));
      }
    };

    if (camera) {
      setSelectedVODs([]);
      if (value === 0 && camera.active === "on") {
        if (CLOUD_NATIVE3 === true) {
          requestCNVod(camera);
        } else if (CLOUD_NATIVE3 === false) {
          dispatch(
            loadVODList({
              lb_server_name: camera.lb_server_name,
              lb_http_port: camera.lb_http_port,
              psn: camera.psn,
            })
          );
        }
      }
    }
  }, [
    CLOUD_NATIVE3,
    camera,
    dispatch,
    email,
    loginInfo?.user_token,
    tokenType,
    value,
  ]);

  useEffect(() => {
    if (camera) {
      if (value === 1) {
        dispatch(loadCloudVODList(camera.psn));
      } else if (value === 2) {
        dispatch(loadEventVODList(camera.psn));
      }
    }
  }, [camera, dispatch, value]);

  useEffect(() => {
    let vodLength = 0;
    if (value === 0) {
      vodLength = vodList?.length ?? 0;
    } else if (value === 1) {
      vodLength = cloudVodList?.filelist.length ?? 0;
    } else if (value === 2) {
      // mantis - 11259, eventVodList타입 수정하면서 아래 코드도 수정 (Leehj)
      vodLength = eventVodList?.filelist.length ?? 0;
    }
    if (vodLength !== buttonsRef.current.length) {
      buttonsRef.current = _.range(0, vodLength).map(() =>
        createRef<HTMLButtonElement>()
      );
    }
  }, [cloudVodList?.filelist, eventVodList, value, vodList]);

  // for test
  // const cloudUsage = useMemo(() => 70, []);
  // for production
  const cloudUsage = useMemo(() => {
    // 11672 수정
    if (cloudVodList) {
      return (cloudVodList.s3_usage / cloudVodList.s3_usage_limit) * 100;
    }
    if (eventVodList) {
      return (eventVodList.s3_usage / eventVodList.s3_usage_limit) * 100;
    }
    return -1;
  }, [cloudVodList, eventVodList]);

  useEffect(() => {
    if (value === 1 || value === 2) {
      const json = localStorage.getItem("pitta-storage-alert");
      console.log("PlaybackPanel", "cloudUsage", cloudUsage);
      // 11672 수정
      if (cloudUsage === -1) return;
      console.log("PlaybackPanel", "load", "pitta-storage-alert", json);
      let storageAlert = json
        ? JSON.parse(json)
        : { alert70: false, alert90: false, alert100: false };
      if (cloudUsage >= 100) {
        // mantis - 12421, 탭 이동 시 cloud storage full 팝업이 노출되지 않도록 수정 (Leehj)
        // mantis - 12484, 100%경고팝업 해당 화면 진입시 노출되며 한번 종료한 팝업은 탭이동시 노출하지 않도록 적용 (Leehj)
        if (!openWarn100Modal) {
          setOpenWarnModal(true);
        }
        storageAlert = { alert70: false, alert90: false, alert100: true };
      } else if (cloudUsage >= 90) {
        if (!storageAlert.alert90) {
          setOpenWarnModal(true);
        }
        storageAlert = { alert70: false, alert90: true, alert100: false };
      } else if (cloudUsage >= 70) {
        if (!storageAlert.alert70) {
          setOpenWarnModal(true);
        }
        storageAlert = { alert70: true, alert90: false, alert100: false };
      } else {
        storageAlert = { alert70: false, alert90: false, alert100: false };
      }
      console.log("PlaybackPanel", "save", "pitta-storage-alert", storageAlert);
      localStorage.setItem("pitta-storage-alert", JSON.stringify(storageAlert));
    }
  }, [cloudUsage, openWarn100Modal, value]);

  // mantis - 8479, 멀티삭제 할 경우 undefined 잠시 출력되는 이슈수정을 위해 currentVODs가 값이 있을때만 currentVODs.length출력
  const vodLength = useMemo(() => {
    if (loading && !type) {
      return 10;
    } else if (currentVODs) {
      return currentVODs.length;
    } else if (value === 1) {
      return cloudVodList?.filelist.length;
    } else if (value === 2) {
      // mantis - 11259, eventVodList타입 수정하면서 아래 코드도 수정 (Leehj)
      return eventVodList?.filelist.length;
    }
  }, [
    loading,
    type,
    currentVODs,
    value,
    cloudVodList?.filelist.length,
    eventVodList?.filelist.length,
  ]);

  const headerCountMarkup = useMemo(() => {
    if (loading && !type) {
      return "";
    }
    if (value === 0) {
      return (
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {vodLength}
        </Typography>
      );
      // mantis - 11259, eventVodList가 존재할 때도 사용량 노출되도록 수정, eventVodList타입에 s3_usage_limit, s3_usage 추가 (Leehj)
    } else if ((value === 1 || value === 2) && (cloudVodList || eventVodList)) {
      const per = eventVodList
        ? (eventVodList.s3_usage / eventVodList.s3_usage_limit) * 100
        : cloudVodList
        ? (cloudVodList.s3_usage / cloudVodList.s3_usage_limit) * 100
        : 0;
      let bgColor = LightColors.primary["7"];
      // mantis - 11677, Storage 용량 100% -> red, 70% 이상 yellow, 70% 미만 blue 로 적용 (Leehj)
      // mantis - 12413, 100%초과한 경우에도 빨간색으로 노출되도록 적용 (Leehj)
      if (per >= 100) {
        bgColor = LightColors.secondary["11"];
      } else if (per >= 70) {
        bgColor = LightColors.secondary["17"];
      }
      return (
        <div>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {eventVodList
              ? `${eventVodList.s3_usage}/${eventVodList.s3_usage_limit} GB used`
              : cloudVodList
              ? `${cloudVodList.s3_usage}/${cloudVodList.s3_usage_limit} GB used`
              : ""}
          </Typography>
          <div className={classes.usedProgDiv}>
            <div
              className={classes.usedBg}
              style={{
                width: `${per}%`,
                backgroundColor: bgColor,
              }}
            />
          </div>
        </div>
      );
    }
  }, [
    classes.usedBg,
    classes.usedProgDiv,
    cloudVodList,
    eventVodList,
    loading,
    type,
    value,
    vodLength,
  ]);

  const clearVOD = useCallback(() => {
    setGPSRecording(undefined);
    setPlayVOD(undefined);
    setAccels([]);
    setDrowsData([]);
    setTimestamp(undefined);
    setLocation(undefined);
    setLocations([]);
    setSelectedVODs([]);
    setSelectMode(false);
    setAllSelect(false);
  }, []);

  const changePlayVOD = useCallback(
    (cam: ICameraInfo, vod: IVOD, rowCount: number, indx: number) => {
      if (!_.isEqual(vod, playVOD)) {
        clearVOD();
        // console.log("changePlayVOD", cam, vod, quality);
        if (vodValue === 0) {
          if (quality === "low" && vod.lowFilename) {
            // cloud native 3차에서 vod token은 원본 영상 토큰으로 발급받아야함
            // 대시캠에 원본 영상으로 호출하고, 대신 substream으로 영상을 내려줌
            dispatch(
              loadUrgentVODToken({
                psn: cam.psn,
                filename: CLOUD_NATIVE3 ? vod.filename : vod.lowFilename,
                cloudNative: CLOUD_NATIVE3,
              })
            );
          } else {
            dispatch(
              loadUrgentVODToken({
                psn: cam.psn,
                filename: vod.filename,
                cloudNative: CLOUD_NATIVE3,
              })
            );
          }
        }
        setPlayVODIndx(indx);
        setPlayVOD(vod);
        setVodValue(value);
        if (rowCount === indx + 1) {
          setDisableNext(false);
          setDisablePrev(true);
        } else if (indx === 0) {
          setDisableNext(true);
          setDisablePrev(false);
        } else {
          setDisableNext(false);
          setDisablePrev(false);
        }
      }
    },
    [playVOD, clearVOD, vodValue, value, quality, dispatch, CLOUD_NATIVE3]
  );

  const requestThumbs = useCallback(() => {
    if (rowInfoRef.current && currentVODs) {
      const info = rowInfoRef.current;
      for (let i = info.startIndex; i <= info.stopIndex; i++) {
        const rowCount = currentVODs.length;
        const rowIndx = rowCount - i - 1;
        let vod = currentVODs[rowIndx];
        if (vod) {
          let filename = vod.filename;
          if (_.includes(AI_EVENTS, vod.eventCode)) {
            // 8632 - interior 영상 있는 경우 제외하고 DMS이벤트 파일 front thumb표시
            if (vod.interior) {
              filename = vod.interior.filename;
            }
          }
          if (!thmRequests[filename] && !thm[filename] && camera) {
            // 8882 - 권한 없는 경우 썸네일 로드 안하도록 수정 (mckim)
            if (
              value === 0 &&
              (downloadCameraVodPerm ||
                // mantis - 12302, driver계정의 경우 cameraVodPerm true이면 썸네일 노출되도록 수정(Leehj)
                (userProfile?.userType === "User" && cameraVodPerm))
            ) {
              dispatch(updateThmRequest({ filename, requested: true }));
              dispatch(
                loadThumbnail({
                  lb_server_name: camera.lb_server_name,
                  lb_http_port: camera.lb_http_port,
                  psn: camera.psn,
                  filename,
                  cloudNative: CLOUD_NATIVE3,
                })
              );
            }
          }
        }
      }
    }
  }, [
    CLOUD_NATIVE3,
    camera,
    cameraVodPerm,
    currentVODs,
    dispatch,
    downloadCameraVodPerm,
    thm,
    thmRequests,
    userProfile?.userType,
    value,
  ]);

  useEffect(() => {
    if (currentVODs && value === 0) {
      requestThumbs();
    }
  }, [currentVODs, requestThumbs, value]);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      if (!currentVODs) {
        return (
          <div
            key={props.key}
            className={classes.fileListDiv}
            style={props.style}
          >
            <FileListItem loading />
          </div>
        );
      }
      const rowCount = props.parent.props.rowCount;
      const rowIndx = rowCount - props.index - 1;
      let vod = currentVODs[rowCount - props.index - 1];
      if ((loading || !init.current) && !type) {
        return (
          <div
            key={props.key}
            className={classes.fileListDiv}
            style={props.style}
          >
            <FileListItem loading />
          </div>
        );
      } else if (vod) {
        // console.log("rowRenderer", vod);
        let filename = vod.filename;
        if (_.includes(AI_EVENTS, vod.eventCode)) {
          // 8632 - interior 영상 있는 경우 제외하고 DMS이벤트 파일 front thumb표시
          if (vod.interior) {
            filename = vod.interior.filename;
          }
        }
        if (!thmRequests[filename] && !thm[filename] && camera) {
          // 8882 - 권한 없는 경우 썸네일 로드 안하도록 수정 (mckim)
          if (value === 0 && downloadCameraVodPerm) {
            // dispatch(updateThmRequest({ filename, requested: true }));
            // dispatch(
            //   loadThumbnail({
            //     lb_server_name: camera.lb_server_name,
            //     lb_http_port: camera.lb_http_port,
            //     psn: camera.psn,
            //     filename,
            //     cloudNative: CLOUD_NATIVE3,
            //   })
            // );
          } else if (
            value === 1 &&
            (downloadCloudVodPerm ||
              // mantis - 12302, driver계정의 경우 cloudVodPerm true이면 썸네일 노출되도록 수정(Leehj)
              (userProfile?.userType === "User" && cloudVodPerm))
          ) {
            if (vod.thmName) {
              dispatch(
                loadCloudVODThm({
                  psn: camera.psn,
                  filename,
                  thmName: vod.thmName,
                })
              );
            }
          } else if (
            value === 2 &&
            vod.thmRid &&
            (downloadCloudVodPerm ||
              // mantis - 12302, driver계정의 경우 cloudVodPerm true이면 썸네일 노출되도록 수정(Leehj)
              (userProfile?.userType === "User" && cloudVodPerm))
          ) {
            dispatch(updateThmRequest({ filename, requested: true }));
            dispatch(
              loadEventThumbnail({
                psn: camera.psn,
                rid: vod.thmRid,
                filename,
              })
            );
          }
        }
        return (
          <div
            key={props.key}
            className={classes.fileListDiv}
            style={props.style}
            onClick={() => {
              if (selectMode) return;
              if (free100Check) {
                setOpenExceedModal(true);
              } else if (
                // mantis - 8882, admin myBlackVueFilePlay, cloudFilePlay권한 없을 때 재생 안되도록 수정(Leehj)
                userProfile?.userType !== "User" &&
                ((value === 0 && !downloadCameraVodPerm) ||
                  ((value === 1 || value === 2) && !downloadCloudVodPerm))
              ) {
                return;
              }
              // mantis - 12302, driver도 vod play가능하도록 조건 추가 (Leehj)
              else if (
                userProfile?.userType === "User" &&
                ((value === 0 && !cameraVodPerm) ||
                  ((value === 1 || value === 2) && !cloudVodPerm))
              ) {
                return;
              } else {
                if (!ExceptionPSN && camera && vod) {
                  // console.log("vod", vod);
                  // playback tab으로 이동시 기본 quality는 low로 변경
                  // original 재생중 다른 영상 재생 시, original영상 없으면 low로 퀄리티 자동 변경
                  // mantis - 8355
                  if (value === 0 || !vod.hasOriginal) {
                    setQuality("low");
                  }
                  if (value === 0 && vod.front) {
                    changePlayVOD(camera, vod.front, rowCount, rowIndx);
                  } else {
                    changePlayVOD(camera, vod, rowCount, rowIndx);
                  }
                }
              }
            }}
          >
            <FileListItem
              t={t}
              img={thm[filename]}
              time={vod.time.format("HH:mm:ss YYYY-MM-DD")}
              fileType={vod.event}
              eventCode={vod.eventCode}
              locationType={value === 0 ? undefined : vod.direction}
              exp={vod.exp && moment(vod.exp).format("YYYY-MM-DD HH:mm:ss")}
              ref={buttonsRef.current[props.index]}
              selectMode={selectMode}
              checked={_.includes(selectedVODs, vod)}
              playing={playVOD?.filename === vod.filename}
              onChecked={(e) => {
                if (e.target.checked) {
                  setSelectedVODs((vods) => _.compact([...vods, vod]));
                } else {
                  setSelectedVODs((vods) => _.filter(vods, (v) => v !== vod));
                }
              }}
              onMore={(e) => {
                // console.log(buttonsRef.current);
                e.preventDefault();
                e.stopPropagation();
                setOnScroll(false);
                setAnchorRef(buttonsRef.current[props.index]);
                setCurrentVOD(vod);
                if (currentVOD === vod) {
                  setOpenCamMenu((o) => !o);
                } else {
                  setOpenCamMenu(true);
                }
                setMenuDate(vod.time.format("HH:mm:ss"));
                setMenuTime(vod.time.format("YYYY-MM-DD"));
              }}
            />
          </div>
        );
      }
      // console.log("currentvod", currentVOD);
    },
    [
      currentVODs,
      loading,
      type,
      classes.fileListDiv,
      thmRequests,
      thm,
      camera,
      t,
      value,
      selectMode,
      selectedVODs,
      playVOD?.filename,
      downloadCameraVodPerm,
      downloadCloudVodPerm,
      userProfile?.userType,
      cloudVodPerm,
      dispatch,
      free100Check,
      cameraVodPerm,
      ExceptionPSN,
      changePlayVOD,
      currentVOD,
    ]
  );

  const handleUpdateAccel = useCallback((acc: IAccel[], totalT: number) => {
    setAccels(acc);
    setTotalTimestamp(totalT);
  }, []);

  const handleUpdateGPS = useCallback((loc) => {
    setLocations(loc);
  }, []);

  const handleUpdateDrows = useCallback(
    (drowsData: IDrow[], gpsTime: string | undefined) => {
      setDrowsData(drowsData);
      setGpsTime(gpsTime);
    },
    []
  );

  const videoPlayer = useMemo(() => {
    return (
      <VideoPlayer
        // mantis - 8561, playback에서 비디오 실행하는 경우 active 무조건 on으로 하기 위함 (속도출력위함)
        playback
        mode={vodValue}
        quality={quality}
        camera={camera}
        vod={playVOD}
        requestPause={requestPause}
        updatedTimestamp={updatedTimestamp}
        // screenMode={theater ? "wide" : screenMode}
        onResize={(width, height) => setListHeight(height)}
        disablePrev={disablePrev}
        disableNext={disableNext}
        showQuality={vodValue !== 2}
        showDrows={showDrows}
        disableQuality={vodValue === 0}
        disableUndefined={vodValue === 1 && playVOD === undefined}
        // theater={theater}
        noTheater
        onLoading={(load) => setVideoLoading(load)}
        onTheater={() => {
          setTheater((t) => !t);
        }}
        onPrev={() => {
          if (camera && playVODIndx !== undefined) {
            let list = playedTabVods ?? [];
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const newIndx = playVODIndx + 1;
            const rowCount = list.length;
            if (newIndx < rowCount) {
              const vod = list[newIndx];
              changePlayVOD(camera, vod, rowCount, newIndx);
            }
          }
        }}
        onNext={() => {
          if (camera && playVODIndx !== undefined) {
            let list = playedTabVods ?? [];
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const newIndx = playVODIndx - 1;
            const rowCount = list.length;
            if (newIndx > -1) {
              const vod = list[newIndx];
              changePlayVOD(camera, vod, rowCount, newIndx);
            }
          }
        }}
        onUpdateGPS={handleUpdateGPS}
        onUpdateAccel={handleUpdateAccel}
        onUpdateDrows={handleUpdateDrows}
        onUpdateTime={(time) => setTimestamp(time)}
        onRequestFront={(fname) => {
          if (camera) {
            const filename = fname.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const frontFname = `${names[0]}_${names[1]}_${ev[0]}F`;
            let list = playedTabVods ?? [];
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const rowCount = list.length;

            if (vodValue === 0 && playVODIndx !== undefined) {
              const front = list[playVODIndx].front;
              if (front) {
                changePlayVOD(camera, front, rowCount, playVODIndx);
              }
            } else {
              const frontVODIndx = _.findIndex(
                list,
                (v) => v.filename.indexOf(frontFname) > -1
              );
              if (frontVODIndx > -1) {
                const vod = list[frontVODIndx];
                changePlayVOD(camera, vod, rowCount, frontVODIndx);
              }
            }
          }
        }}
        onRequestRear={(fname) => {
          if (camera) {
            const filename = fname.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const rearFname = `${names[0]}_${names[1]}_${ev[0]}R`;
            let list = playedTabVods ?? [];
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const rowCount = list.length;
            console.log("onRequestRear", fname, list, playVODIndx);
            if (vodValue === 0 && playVODIndx !== undefined) {
              const rear = list[playVODIndx].rear;
              if (rear) {
                changePlayVOD(camera, rear, rowCount, playVODIndx);
              }
            } else {
              const rearVODIndx = _.findIndex(
                list,
                (v) => v.filename.indexOf(rearFname) > -1
              );
              if (rearVODIndx > -1) {
                const vod = list[rearVODIndx];
                changePlayVOD(camera, vod, rowCount, rearVODIndx);
              }
            }
          }
        }}
        onRequestInterior={(fname) => {
          if (camera) {
            const filename = fname.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const interiorFname = `${names[0]}_${names[1]}_${ev[0]}I`;
            let list = playedTabVods ?? [];
            const rowCount = list.length;
            if (vodValue === 0 && playVODIndx !== undefined) {
              const interior = list[playVODIndx].interior;
              if (interior) {
                changePlayVOD(camera, interior, rowCount, playVODIndx);
              }
            } else {
              const interiorVODIndx = _.findIndex(
                list,
                (v) => v.filename.indexOf(interiorFname) > -1
              );
              if (interiorVODIndx > -1) {
                const vod = list[interiorVODIndx];
                changePlayVOD(camera, vod, rowCount, interiorVODIndx);
              }
            }
          }
        }}
        // 7 BOX option카메라 관련 추가 (8467)
        onRequestOption={(fname) => {
          if (camera) {
            const filename = fname.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const optionFname = `${names[0]}_${names[1]}_${ev[0]}O`;
            let list = playedTabVods ?? [];
            const rowCount = list.length;
            if (vodValue === 0 && playVODIndx !== undefined) {
              const option = list[playVODIndx].option;
              if (option) {
                changePlayVOD(camera, option, rowCount, playVODIndx);
              }
            } else {
              const optionVODIndx = _.findIndex(
                list,
                (v) => v.filename.indexOf(optionFname) > -1
              );
              if (optionVODIndx > -1) {
                const vod = list[optionVODIndx];
                changePlayVOD(camera, vod, rowCount, optionVODIndx);
              }
            }
          }
        }}
        onChangeQuality={(q) => setQuality(q)}
        onUpdateGPSRecordingInfo={setGPSRecording}
      />
    );
  }, [
    vodValue,
    quality,
    camera,
    playVOD,
    requestPause,
    updatedTimestamp,
    disablePrev,
    disableNext,
    showDrows,
    handleUpdateGPS,
    handleUpdateAccel,
    handleUpdateDrows,
    playVODIndx,
    changePlayVOD,
    playedTabVods,
  ]);

  const handleFilterClose = useCallback(() => {
    setFilterEventN(appliedEventN);
    setFilterEventE(appliedEventE);
    setFilterEventP(appliedEventP);
    setFilterEventM(appliedEventM);
    // mantis - 12610,  X 버튼 선택 시 날짜 선택 내용 초기화(Leehj)
    setFilterDate({ from: null, to: null });
    setOpenFilter(false);
  }, [appliedEventN, appliedEventE, appliedEventP, appliedEventM]);

  const enabledDays = useMemo(() => {
    let vods: IVOD[] | undefined;
    if (value === 0) {
      vods = vodList;
    } else if (value === 1) {
      vods = cloudVodList?.filelist ?? [];
    } else if (value === 2) {
      // mantis - 11259, eventVodList타입 수정하면서 아래 코드도 수정 (Leehj)
      vods = eventVodList?.filelist ?? [];
    }
    return _.chain(vods)
      .map((vod) => vod.time.format("YYYYMMDD"))
      .uniq()
      .value();
  }, [cloudVodList?.filelist, eventVodList, value, vodList]);

  const renderFilterDrawer = useCallback(() => {
    return (
      <Drawer anchor="right" open={openFilter}>
        <div className={classes.filterDrawerDiv}>
          <div className={classes.filterTitleDiv}>
            <Typography category="Default" variant="H6">
              {t("Filter")}
            </Typography>
            <IconButton onClick={handleFilterClose} style={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </div>

          <div
            className={classes.filterListDiv}
            style={{ flexGrow: 1, overflow: "auto" }}
          >
            <div className={classes.filterItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Video types")}
              </Typography>
              <div
                className={classes.radioFormControl}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventN}
                      onChange={(_e, checked) => setFilterEventN(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Normal")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventE}
                      onChange={(_e, checked) => setFilterEventE(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Event")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventP}
                      onChange={(_e, checked) => setFilterEventP(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Parking")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventM}
                      onChange={(_e, checked) => setFilterEventM(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Manual")}
                    </Typography>
                  }
                />
              </div>
            </div>
            <DateRangePicker
              variant="range"
              range={filterDate}
              enabledDays={enabledDays}
              onChangeDay={(range) =>
                setFilterDate(range.range ?? { from: null, to: null })
              }
            />
          </div>

          <div className={classes.filterBtnDiv}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: theme.spacing(2) }}
              disabled={
                filterEventN &&
                filterEventE &&
                filterEventP &&
                filterEventM &&
                !filterDate.from &&
                !filterDate.to
              }
              onClick={() => {
                setFilterEventN(true);
                setFilterEventE(true);
                setFilterEventP(true);
                setFilterEventM(true);
                setFilterDate({ from: null, to: null });
              }}
            >
              {t("Clear")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAppliedEventN(filterEventN);
                setAppliedEventE(filterEventE);
                setAppliedEventP(filterEventP);
                setAppliedEventM(filterEventM);
                setAppliedDate(filterDate);
                setOpenFilter(false);
              }}
              disabled={
                filterEventN === appliedEventN &&
                filterEventE === appliedEventE &&
                filterEventP === appliedEventP &&
                filterEventM === appliedEventM &&
                filterDate.from === appliedDate.from &&
                filterDate.to === appliedDate.to
              }
            >
              {t("OK")}
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }, [
    openFilter,
    classes.filterDrawerDiv,
    classes.filterTitleDiv,
    classes.filterListDiv,
    classes.filterItemDiv,
    classes.radioFormControl,
    classes.radioBtn,
    classes.filterBtnDiv,
    t,
    handleFilterClose,
    filterEventN,
    filterEventE,
    filterEventP,
    filterEventM,
    filterDate,
    enabledDays,
    theme,
    appliedEventN,
    appliedEventE,
    appliedEventP,
    appliedEventM,
    appliedDate.from,
    appliedDate.to,
  ]);

  const downloadModalContentMarkup = useMemo(() => {
    let hasFront = false;
    let hasRear = false;
    let hasInterior = false;
    //7 BOX option카메라 관련 추가 (8474)
    let hasOption = false;
    if (downloadMode === "single") {
      hasFront = !!currentVOD?.hasFront;
      hasRear = !!currentVOD?.hasRear;
      hasInterior = !!currentVOD?.hasInterior;
      //7 BOX option카메라 관련 추가 (8474)
      hasOption = !!currentVOD?.hasOption;
    } else {
      hasFront = _.chain(selectedVODs)
        .map((v) => v.hasFront)
        .some()
        .value();
      hasRear = _.chain(selectedVODs)
        .map((v) => v.hasRear)
        .some()
        .value();
      hasInterior = _.chain(selectedVODs)
        .map((v) => v.hasInterior)
        .some()
        .value();
      //7 BOX option카메라 관련 추가 (8474)
      hasOption = _.chain(selectedVODs)
        .map((v) => v.hasOption)
        .some()
        .value();
    }
    return (
      <>
        <RadioGroup
          aria-label="quality"
          name="quality"
          value={downloadQuality}
          onChange={(e) =>
            setDownloadQuality(e.target.value as VideoQualityType)
          }
          className={classes.downloadRadipGruop}
        >
          <RadioButton
            value="low"
            label={t("Quick play file_")}
            disabled={downloadMode === "single" && !currentVOD?.hasLow}
          />
          <RadioButton
            value="original"
            label={t("Original file")}
            disabled={
              value === 2 ||
              (downloadMode === "single" && !currentVOD?.hasOriginal) ||
              (downloadMode === "multiple" &&
                _.chain(selectedVODs)
                  .map((vod) => vod.hasOriginal)
                  .compact()
                  .value().length !== selectedVODs.length)
            }
          />
        </RadioGroup>
        {value === 0 && (
          <>
            <div className={classes.typeTitleDiv}>
              <Typography category="Default" variant="H6">
                {t("Video type")}
              </Typography>
            </div>

            {/* mantis - 12577, 라디오버튼 하나만 선택되도록 수정(Leehj)  */}
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={classes.formControlLabel}
            >
              <RadioGroup
                aria-label="downloadDirection"
                name="downloadDirection"
                value={downloadDirections}
                onChange={(e) =>
                  setDownloadDirections([e.target.value as LocationType])
                }
              >
                <RadioButton
                  value="Front"
                  className={classes.checkBoxSpacing}
                  label={t("Front")}
                  disabled={!hasFront}
                  checked={!!_.includes(downloadDirections, "Front")}
                />
                <RadioButton
                  value="Rear"
                  label={t("Rear")}
                  className={classes.checkBoxSpacing}
                  disabled={!hasRear}
                  checked={!!_.includes(downloadDirections, "Rear")}
                />
                {!isOptionModel && (
                  <RadioButton
                    value="Interior"
                    label={t("Interior")}
                    className={classes.checkBoxSpacing}
                    disabled={!hasInterior}
                    checked={!!_.includes(downloadDirections, "Interior")}
                  />
                )}
                {/* 7 BOX option카메라 관련 추가 (8474) */}
                {isOptionModel && (
                  <RadioButton
                    value="Option"
                    label={t("Option")}
                    className={classes.checkBoxSpacing}
                    checked={!!_.includes(downloadDirections, "Option")}
                    disabled={!hasOption}
                  />
                )}
              </RadioGroup>
            </div>
          </>
        )}
      </>
    );
  }, [
    classes.checkBoxSpacing,
    classes.downloadRadipGruop,
    classes.formControlLabel,
    classes.typeTitleDiv,
    currentVOD?.hasFront,
    currentVOD?.hasInterior,
    currentVOD?.hasLow,
    currentVOD?.hasOption,
    currentVOD?.hasOriginal,
    currentVOD?.hasRear,
    downloadDirections,
    downloadMode,
    downloadQuality,
    isOptionModel,
    selectedVODs,
    t,
    value,
  ]);

  // useEffect(() => {
  //   const thres = 560;
  //   const handleResize = (e: UIEvent) => {
  //     if (window.innerHeight - 2 * 188 - 64 > thres) {
  //       setScreenMode("default");
  //     } else {
  //       setScreenMode("wide");
  //     }
  //   };
  //   window.addEventListener("resize", handleResize);
  //   if (window.innerHeight - 2 * 188 - 64 > thres) {
  //     setScreenMode("default");
  //   } else {
  //     setScreenMode("wide");
  //   }
  //   return () => {
  //     // cleanup
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const vodListMarkup = useMemo(() => {
    if (listHeight && tabRef.current && filterRef.current && listRef.current) {
      let height = 387;
      if (!mobile) {
        height = theater
          ? 400 -
            tabRef.current.clientHeight -
            filterRef.current.clientHeight -
            5
          : listHeight -
            tabRef.current.clientHeight -
            filterRef.current.clientHeight -
            (selectMode ? 60 : 4);
      }

      if (
        // mantis - 8793, Cloud / Live Evnet Upload 탭에 파일 없는 경우 빈 리스트 출력되는 이슈 수정(Leehj)
        // vodList === undefined ||
        vodLength === undefined ||
        vodLength > 0 ||
        loading ||
        !init.current
      ) {
        const listMarkup = (width: number, height: number) => (
          <List
            // className={classes.playList}
            ref={vodListRef}
            width={width}
            height={height}
            rowHeight={72}
            rowCount={vodLength ?? 0}
            rowRenderer={rowRenderer}
            overscanRowCount={3}
            onRowsRendered={(info) => {
              rowInfoRef.current = info;
            }}
            onScroll={() => {
              if (value === 0) {
                dispatch(setRequestThumb(false));
              }
              setOnScroll(true);
              setOpenCamMenu(false);
              clearTimeout(thumbRequestTimerRef.current);
              thumbRequestTimerRef.current = setTimeout(() => {
                console.log("onScroll", "timeout", rowInfoRef.current);
                dispatch(setRequestThumb(true));
                dispatch(resetThmRequest());
                requestThumbs();
              }, 1000);
            }}
            style={
              mobile
                ? {}
                : {
                    overflowY: "visible",
                    overflowX: "visible",
                  }
            }
          />
        );
        return mobile ? (
          listMarkup(listRef.current.clientWidth, height)
        ) : (
          <AutoSizer>
            {({ width, height }) =>
              renderListHelper(
                width,
                height,
                listMarkup(width, height),
                false,
                theme.direction,
                vodListRef
              )
            }
          </AutoSizer>
        );
      } else {
        return (
          <div
            className={clsx(classes.listEmptyDiv, {
              [classes.listEmptyDivMenuOpen]: openMenu,
              [classes.listEmptyDivMenuClose]: !openMenu,
            })}
            style={{ height }}
          >
            <Typography
              category="Default"
              variant="BodyBold"
              // mantis - 8793, Cloud / Live Evnet Upload 탭에 파일 없는 경우 문구색상 피그마에 맞추어 수정(Leehj)
              htmlColor={LightColors.primary["2"]}
            >
              {t("No videos yet")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              style={{ textAlign: "center" }}
              // mantis - 8793, Cloud / Live Evnet Upload 탭에 파일 없는 경우 문구색상 피그마에 맞추어 수정(Leehj)
              htmlColor={LightColors.primary["2"]}
            >
              {t("Your videos will_")}
            </Typography>
          </div>
        );
      }
    }
  }, [
    listHeight,
    mobile,
    vodLength,
    loading,
    theater,
    selectMode,
    rowRenderer,
    value,
    dispatch,
    requestThumbs,
    theme.direction,
    classes.listEmptyDiv,
    classes.listEmptyDivMenuOpen,
    classes.listEmptyDivMenuClose,
    openMenu,
    t,
  ]);

  const uploadCloud = useCallback(
    (vod: IVOD) => {
      setOpenCamMenu(false);
      if (camera) {
        const cam = _.chain(cameraList?.deviceListInfo)
          .map((c) => c.device)
          .find((c) => c.psn === camera.psn)
          .value();
        if (cam) {
          dispatch(
            uploadVODFile({
              camera: cam,
              filename: vod.filename,
            })
          );
        }
      }
    },
    [camera, cameraList?.deviceListInfo, dispatch]
  );

  // mantis - 12508, BC_ERR_DEVICE_S3_OVER 에러 코드 발생하는 경우 제한 팝업 open (Leehj)
  useEffect(() => {
    if (cloudLimit) {
      setOpenCloudLimitModal(true);
    } else {
      setOpenCloudLimitModal(false);
    }
  }, [cloudLimit]);

  //   async (psn: string, vod: IVOD, multiple?: boolean) => {
  //     if (email && loginInfo) {
  //       let filename = vod.filename;
  //       if (downloadQuality === "low" && vod.lowFilename && !CLOUD_NATIVE3) {
  //         filename = vod.lowFilename;
  //       }
  //       // const token = tokens[filename];
  //       let token = vod_tokens[filename];
  //       if (!token) {
  //         const res = await getVODToken(
  //           email,
  //           loginInfo.user_token,
  //           psn,
  //           filename
  //         );
  //         const { response } = res.data as {
  //           resultcode: RESULT_CODE;
  //           response: IVODToken;
  //         };

  //         token = response;
  //         dispatch(successLoadVODToken({ filename, token: response }));
  //       }
  //       let linkUrl = getLink(vod, token, downloadQuality);
  //       if (multiple) return linkUrl;
  //       if (linkUrl) return handleDownload(linkUrl, filename);
  //     }
  //   },
  //   [
  //     email,
  //     loginInfo,
  //     downloadQuality,
  //     CLOUD_NATIVE3,
  //     vod_tokens,
  //     getLink,
  //     handleDownload,
  //     dispatch,
  //   ]
  // );

  const downloadMultipleVOD = useCallback(
    async (vods: ICameraVOD[]) => {
      if (
        planName === "Free plan" &&
        userUsage &&
        userUsage.remoteVideoPlayBackAndDownLoad + vods.length >
          userUsage.LimitVOD
      ) {
        setOpenDownloadExceedModal(true);
      } else {
        const files = await _.reduce(
          vods,
          async (prevPromise, vod) => {
            const files = await prevPromise.then();
            if (value === 0 && camera) {
              // const f = await handleCamDownloadVOD(camera.psn, vod, true);
              // if (typeof f === "string") {
              //   return Promise.resolve([...files, f]);
              // }
              // dispatch(
              //   downloadVOD([
              //     {
              //       ...vod,
              //       psn: camera.psn,
              //       cloudNative: CLOUD_NATIVE3,
              //       quality: downloadQuality,
              //     },
              //   ])
              // );
              return Promise.resolve([
                ...files,
                {
                  ...vod,
                  psn: camera.psn,
                  cloudNative: CLOUD_NATIVE3,
                  quality: downloadQuality,
                },
              ]);
            } else if (value === 1) {
              const resp = await getCloudLink(vod, downloadQuality);
              if (resp) {
                const {
                  resultcode,
                  response: { presignedURL },
                } = resp.data as {
                  resultcode: RESULT_CODE;
                  response: { presignedURL: string };
                };
                if (resultcode === "BC_ERR_OK") {
                  // await handleDownload(presignedURL);
                  return Promise.resolve([...files, presignedURL]);
                }
              }
            } else if (value === 2) {
              const resp = await getEventLink(vod);
              if (resp) {
                // await handleDownload(resp.data["fileInfo"]["url"]);
                return Promise.resolve([
                  ...files,
                  resp.data["fileInfo"]["url"],
                ]);
              }
            }
            return Promise.resolve([...files]);
          },
          Promise.resolve<string[]>([])
        );
        if (value === 0) {
          dispatch(downloadVOD(files));
        } else {
          multiDownload(files);
        }

        dispatch(loadUsageInfo());
      }
    },
    [
      CLOUD_NATIVE3,
      camera,
      dispatch,
      downloadQuality,
      getCloudLink,
      getEventLink,
      planName,
      userUsage,
      value,
    ]
  );

  const downloadSingleVOD = useCallback(async () => {
    if (
      planName === "Free plan" &&
      userUsage &&
      userUsage.remoteVideoPlayBackAndDownLoad >= userUsage.LimitVOD
    ) {
      setOpenDownloadExceedModal(true);
    } else {
      if (camera && currentVOD) {
        if (value === 0) {
          if (downloadDirections[0] === "Front" && currentVOD.front) {
            dispatch(
              downloadVOD([
                {
                  ...currentVOD.front,
                  psn: camera.psn,
                  cloudNative: CLOUD_NATIVE3,
                  quality: downloadQuality,
                },
              ])
            );
            // await handleCamDownloadVOD(camera.psn, currentVOD.front);
          }
          if (downloadDirections[0] === "Rear" && currentVOD.rear) {
            dispatch(
              downloadVOD([
                {
                  ...currentVOD.rear,
                  psn: camera.psn,
                  cloudNative: CLOUD_NATIVE3,
                  quality: downloadQuality,
                },
              ])
            );
            // await handleCamDownloadVOD(camera.psn, currentVOD.rear);
          }
          if (downloadDirections[0] === "Interior" && currentVOD.interior) {
            dispatch(
              downloadVOD([
                {
                  ...currentVOD.interior,
                  psn: camera.psn,
                  cloudNative: CLOUD_NATIVE3,
                  quality: downloadQuality,
                },
              ])
            );
            // await handleCamDownloadVOD(camera.psn, currentVOD.interior);
          }
          if (downloadDirections[0] === "Option" && currentVOD.option) {
            dispatch(
              downloadVOD([
                {
                  ...currentVOD.option,
                  psn: camera.psn,
                  cloudNative: CLOUD_NATIVE3,
                  quality: downloadQuality,
                },
              ])
            );
          }
        } else if (value === 1) {
          const resp = await getCloudLink(currentVOD, downloadQuality);
          if (resp) {
            const {
              resultcode,
              response: { presignedURL },
            } = resp.data as {
              resultcode: RESULT_CODE;
              response: { presignedURL: string };
            };
            if (resultcode === "BC_ERR_OK") {
              await handleDownload(presignedURL);
            }
          }
        } else if (value === 2) {
          const resp = await getEventLink(currentVOD);
          if (resp) {
            await handleDownload(resp.data["fileInfo"]["url"]);
          }
        }
        dispatch(loadUsageInfo());
      }
    }
  }, [
    CLOUD_NATIVE3,
    camera,
    currentVOD,
    dispatch,
    downloadDirections,
    downloadQuality,
    getCloudLink,
    getEventLink,
    handleDownload,
    planName,
    userUsage,
    value,
  ]);

  const moveToCloud = useCallback(
    (vods: IVOD[]) => {
      setOpenCamMenu(false);
      console.log(vods);
      if (camera) {
        dispatch(
          moveEventVODFiles({
            psn: camera.psn,
            rids: _.chain(vods)
              .map((vod) => [vod.thmRid, vod.rid])
              .flattenDeep()
              .compact()
              .value(),
          })
        );
      }
    },
    [camera, dispatch]
  );

  const shareResultModal = useMemo(() => {
    let title: any = "";
    let content: React.ReactNode;
    const button = (
      <div className={classes.eventmapOkDiv}>
        <Button
          color="primary"
          variant="contained"
          style={{
            padding: "10px 16px",
            borderRadius: 10,
            height: 48,
            minWidth: 140,
          }}
          fullWidth={mobile}
          onClick={() => {
            if (openSuccessModal) {
              setOpenSuccessModal(false);
            }
            setEventShareStatus(undefined);
          }}
        >
          {t("OK")}
        </Button>
      </div>
    );
    if (openSuccessModal) {
      title = (
        <div className={classes.eventmapModalTitle}>
          <TaskAlt
            htmlColor={LightColors.primary["7"]}
            style={{ marginRight: 5 }}
          />
          &nbsp;{t("Added to Event Map")}
        </div>
      );

      content = (
        <>
          <Typography category="Default" variant="BodyBold">
            {t("Successfully added video_")}
          </Typography>
          <div style={{ height: 8 }} />
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("If you would like to check_")}
          </Typography>
          {button}
        </>
      );
    }
    if (eventShareStatus === "exist") {
      title = (
        <div className={classes.eventmapModalTitle}>
          <ErrorOutlineIcon
            htmlColor={LightColors.secondary["11"]}
            style={{ marginRight: 3 }}
          />
          &nbsp;{t("Already added to_")}
        </div>
      );
      content = (
        <>
          <Typography category="Default" variant="BodyBold">
            {/* {t("This video(s) is already added_")} */}
            {t("This video is_")}
          </Typography>
          <div style={{ height: 8 }} />
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("If you would like to check_")}
          </Typography>
          {button}
        </>
      );
    }
    if (eventShareStatus === "nogps") {
      title = (
        <div className={classes.eventmapModalTitle}>
          <ErrorOutlineIcon
            htmlColor={LightColors.secondary["11"]}
            style={{ marginRight: 3 }}
          />
          &nbsp;{t("This video cannot be shared")}
        </div>
      );
      content = (
        <>
          <div style={{ minHeight: 86 }}>
            <Typography category="Default" variant="BodyBold">
              {t("This video cannot_")}
            </Typography>
          </div>

          {button}
        </>
      );
    }

    if (eventShareStatus === "noperm") {
      title = <div></div>;
      content = (
        <>
          <Typography
            category="Default"
            variant="BodyBold"
            dangerouslySetInnerHTML={{
              __html: t("You do not have permission_"),
            }}
          />
          {button}
        </>
      );
    }

    return (
      <Modal
        open={eventShareStatus !== undefined || openSuccessModal}
        close
        onClose={() => {
          if (openSuccessModal) {
            setOpenSuccessModal(false);
          }
          setEventShareStatus(undefined);
        }}
        heading={title}
        content={content}
        actionClassName={classes.resultModalBottom}
        titleClassName={classes.resultModalTitle}
        contentClassName={clsx(classes.eventmapModalContent, {
          [classes.modalContentStyle]:
            openSuccessModal || eventShareStatus === "exist",
        })}
        className={classes.resultModalWrap}
        RButtonClassName={classes.modalRBtn}
      />
    );
  }, [
    classes.eventmapOkDiv,
    classes.resultModalBottom,
    classes.resultModalTitle,
    classes.eventmapModalContent,
    classes.modalContentStyle,
    classes.resultModalWrap,
    classes.modalRBtn,
    classes.eventmapModalTitle,
    mobile,
    t,
    openSuccessModal,
    eventShareStatus,
  ]);

  const shareEventVideo = useCallback(
    async (vod: IVOD) => {
      if (email && loginInfo && camera && userProfile) {
        try {
          await eventmapJwtAxiosInst.post(`/shared-events/check-sharable`, {
            psn: camera.psn,
            email:
              userProfile.userType === "SubMaster"
                ? subscriptionInfo?.masterEmail
                : email,
            filename: vod.filename,
            type: EVENTMAP_MODE_TO_TYPE[value],
            user_token: loginInfo.user_token,
            rid: value === 2 ? vod.lowRid : undefined,
          });
          // setEventShareStatus("success");
          dispatch(setSelectedHashtags([]));
          setOpenEventShareModal(true);
          onBackScroll?.(true);

          setSharingVOD(vod);
        } catch (err) {
          const axiosErr = err as AxiosError;
          if (axiosErr.response?.status === 409) {
            setEventShareStatus("exist");
          }
          if (axiosErr.response?.status === 404) {
            setEventShareStatus("nogps");
          }
          if (axiosErr.response?.status === 403) {
            setEventShareStatus("noperm");
          }
        }
      }
    },
    [
      camera,
      dispatch,
      email,
      loginInfo,
      onBackScroll,
      subscriptionInfo?.masterEmail,
      userProfile,
      value,
    ]
  );
  const canShareToEventmap = useMemo(() => {
    if (value === 0 && CLOUD_NATIVE3) {
      return true;
    } else if (value === 1 || value === 2) {
      return true;
    }
    return false;
  }, [CLOUD_NATIVE3, value]);

  const isTabScroll = useMemo(() => {
    if (
      firstTab &&
      secondTabRef.current &&
      thirdTabRef.current &&
      tabRef.current
    ) {
      const totalTabWidth =
        firstTab.clientWidth +
        secondTabRef.current.clientWidth +
        thirdTabRef.current.clientWidth;
      return totalTabWidth > tabRef.current.offsetWidth;
    }
    return false;
  }, [tabRef, firstTab, secondTabRef, thirdTabRef]);

  // 마지막 탭 선택된 상태에서 스크롤 가능한 상태면 버튼 찾아서 클릭
  useEffect(() => {
    if (value === 2 && isTabScroll) {
      setTimeout(() => {
        if (tabRef.current) {
          document.querySelectorAll(".MuiTabs-scrollButtons")?.[1]?.click();
        }
      }, 100);
    }
  }, [isTabScroll, value]);

  return (
    <div className={clsx(classes.root, className)} ref={rootDivRef}>
      <div
        className={clsx(classes.topContainerDiv, {
          [classes.topContainerDivMenuOpen]: openMenu,
          [classes.topContainerDivMenuClose]: !openMenu,
        })}
      >
        <div
          className={clsx(classes.playDiv, {
            [classes.playDivMenuOpen]: openMenu,
            [classes.playDivMenuClose]: !openMenu,
            [classes.playDivTheater]: theater,
          })}
        >
          {videoPlayer}
        </div>
        <div
          className={clsx(classes.listDiv, {
            [classes.listDivTheater]: theater,
          })}
        >
          <div className={classes.listBorder}>
            <Tabs
              value={value}
              className={classes.tabDiv}
              ref={tabRef}
              variant={isTabScroll ? "scrollable" : "standard"}
            >
              <Tab
                ref={(ref) => setFirstTab(ref)}
                label={t("Camera")}
                // mantis - 12308, 멀티선택인 경우 탭 비활성화 (Leehj)
                disabled={
                  camera?.active === "off" ||
                  !cameraVodPerm ||
                  (selectMode && value === 1) ||
                  (selectMode && value === 2)
                }
                onClick={() => {
                  // mantis - 12308, 멀티선택인 경우 탭 비활성화 (Leehj)
                  if (!selectMode) {
                    // clearVOD();
                    setOpenCamMenu(false);
                    setPreValue(value);
                    setRequestPause({});
                    // mantis : 8503
                    // openCamMenu가 닫힐 때 까지
                    // 탭 이동 지연
                    setTimeout(() => {
                      setValue(0);
                    }, 200);

                    // setQuality("low");
                  }
                }}
              />
              <Tab
                ref={secondTabRef}
                label={t("Cloud")}
                // mantis - 12308, 멀티선택인 경우 탭 비활성화 (Leehj)
                disabled={
                  !cloudVodPerm ||
                  (selectMode && value === 0) ||
                  (selectMode && value === 2)
                }
                onClick={() => {
                  // mantis - 12308, 멀티선택인 경우 탭 비활성화 (Leehj)
                  if (!selectMode) {
                    // clearVOD();
                    setOpenCamMenu(false);
                    setPreValue(value);
                    setRequestPause({});
                    // mantis : 8503
                    // openCamMenu가 닫힐 때 까지
                    // 탭 이동 지연
                    setTimeout(() => setValue(1), 200);
                    // setQuality("low");
                  }
                }}
              />
              <Tab
                ref={thirdTabRef}
                // mantis - 12308, 멀티선택인 경우 탭 비활성화 (Leehj)
                disabled={
                  ExceptionPSN ||
                  (selectMode && value === 0) ||
                  (selectMode && value === 1)
                }
                label={t("Live event upload")}
                onClick={() => {
                  // mantis - 12308, 멀티선택인 경우 탭 비활성화 (Leehj)
                  if (!selectMode) {
                    // clearVOD();
                    setOpenCamMenu(false);
                    setPreValue(value);
                    setRequestPause({});
                    // mantis : 8503
                    // openCamMenu가 닫힐 때 까지
                    // 탭 이동 지연
                    setTimeout(() => {
                      setValue(2);
                    }, 200);
                    // setQuality("low");
                  }
                }}
              />
            </Tabs>
            <div className={classes.filterDiv} ref={filterRef}>
              <div style={{ display: "flex" }}>
                {selectMode && (
                  <IconButton
                    className={classes.selectCloseBtn}
                    onClick={() => {
                      setSelectedVODs([]);
                      setSelectMode(false);
                      setAllSelect(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    style={{ marginLeft: selectMode ? 0 : 3 }}
                  >
                    {selectMode
                      ? `${selectedVODs.length}/${vodLength}`
                      : headerCountMarkup}
                  </Typography>
                </div>
              </div>
              <div>
                {!selectMode && (
                  <Tooltip
                    disableTouchListener={mobile}
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("Filter")}
                      </Typography>
                    }
                  >
                    <IconButton
                      className={classes.filterBtn}
                      onClick={() => setOpenFilter(true)}
                    >
                      <div style={{ position: "relative" }}>
                        <FilterListIcon style={{ display: "block" }} />
                        {filterApplied && (
                          <div
                            style={{
                              width: 6,
                              height: 6,
                              borderRadius: 3,
                              // mantis - 10553, 필터변경시 아이콘 색상 변경 빨간색->파란색 (Leehj)
                              backgroundColor: LightColors.primary["7"],
                              position: "absolute",
                              top: -3,
                              right: -3,
                            }}
                          />
                        )}
                      </div>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  disableTouchListener={mobile}
                  title={
                    <Typography category="Default" variant="Caption">
                      {t("Multi")}
                    </Typography>
                  }
                >
                  <IconButton
                    className={classes.filterBtn}
                    onClick={() => {
                      setSelectMode(true);
                      handleAllSelect();
                    }}
                  >
                    <DoneAllIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div ref={listRef} style={{ flexGrow: 1 }}>
              {vodListMarkup}
            </div>
            {selectMode && (
              <BottomNavigation className={classes.actionDiv} showLabels>
                {value === 0 && (
                  <BottomNavigationAction
                    classes={{
                      root: classes.actionRoot,
                      wrapper: classes.actionWrapper,
                      label: classes.actionLabel,
                    }}
                    disabled={
                      selectedVODs.length !== 1 ||
                      !uploadVodPerm ||
                      ExceptionPSN
                    }
                    label={t("Upload")}
                    icon={<CloudUpload />}
                    disableRipple
                    onClick={() => {
                      if (cloudUsage >= 100) {
                        setOpenWarnModal(true);
                      } else {
                        if (selectedVODs[0]) {
                          // uploadCloud(selectedVODs[0]);
                          setCurrentVOD(selectedVODs[0]);
                          setUploadDirection("Front");
                          setOpenUploadModal(true);
                        }
                      }
                    }}
                  />
                )}
                {value !== 0 && (
                  <BottomNavigationAction
                    classes={{
                      root: classes.actionRoot,
                      wrapper: classes.actionWrapper,
                      label: classes.actionLabel,
                    }}
                    // mantis - 11843,loading 중 일 때 다른 기능 사용불가하도록 비활성화 처리 (Leehj)
                    disabled={
                      selectedVODs.length === 0 || !deleteVodPerm || loading
                    }
                    label={t("Delete")}
                    icon={<DeleteOutLine />}
                    disableRipple
                    onClick={() => setOpenDeleteModal(true)}
                  />
                )}
                {value === 2 && (
                  <BottomNavigationAction
                    classes={{
                      root: classes.actionRoot,
                      wrapper: classes.actionWrapper,
                      label: classes.actionLabel,
                    }}
                    // mantis - 11843,loading 중 일 때 다른 기능 사용불가하도록 비활성화 처리 (Leehj)
                    disabled={
                      selectedVODs.length === 0 ||
                      !moveToCloudVodPerm ||
                      loading
                    }
                    label={t("Move to Cloud")}
                    icon={<ArrowForwardIcon />}
                    disableRipple
                    onClick={() => {
                      moveToCloud(selectedVODs);
                    }}
                  />
                )}
                <BottomNavigationAction
                  classes={{
                    root: classes.actionRoot,
                    wrapper: classes.actionWrapper,
                    label: classes.actionLabel,
                  }}
                  // mantis - 8882, admin 권한이 있을 때 활성화되도록 수정(Leehj)
                  // mantis - 11843,loading 중 일 때 다른 기능 사용불가하도록 비활성화 처리 (Leehj)
                  disabled={
                    selectedVODs.length === 0 || downloadDisabled || loading
                  }
                  label={t("Download")}
                  icon={<MoveToInBox />}
                  disableRipple
                  onClick={async () => {
                    setDownloadDirections(["Front"]);
                    setDownloadQuality("low");
                    setDownloadMode("multiple");
                    setOpenDownloadModal(true);
                  }}
                />
              </BottomNavigation>
            )}
          </div>
        </div>
        {mobile && (
          <div
            className={clsx(classes.contMobMenu, {
              contMobMenuOpened: GPSMobMenu === true,
            })}
            onClick={() => {
              GPSMobMenu === true ? setGPSMobMenu(false) : setGPSMobMenu(true);
              setDrowsyMobMenu(false);
              setGSensMobMenu(false);
            }}
          >
            <Typography category={"Default"} variant={"H6"}>
              <i className={classes.iconMap} /> {t("Live GPS map")}
            </Typography>
            <ExpandMoreIcon className={classes.expandIcon} />
          </div>
        )}
        <div
          className={clsx(classes.mapDiv, {
            noDrows: !drowsExist || !showDrows,
            transitionHeight: mobile,
            opened: mobile && GPSMobMenu === true,
          })}
        >
          {(!playVOD || gpsRecording !== false) && (
            <MapboxLocationMapWrapper
              location={gpsRecording === undefined ? undefined : location}
              playback
              resizeFlag={showDrows && drowsExist}
            />
          )}

          {playVOD && gpsRecording === false && (
            <div className={classes.loadingDiv}>
              <Typography
                category="Default"
                variant="BodyBold"
                htmlColor={LightColors.primary["0"]}
              >
                {t("GPS Location recording off")}
              </Typography>
            </div>
          )}
          {videoLoading && (
            <div className={classes.loadingDiv}>
              <CircularProgress
                className={classes.circularLoading}
                size={48}
                thickness={5}
              />
            </div>
          )}
        </div>
        {mobile && (
          <div
            className={clsx(classes.contMobMenu, {
              contMobMenuOpened: gSensMobMenu === true,
            })}
            onClick={() => {
              gSensMobMenu === true
                ? setGSensMobMenu(false)
                : setGSensMobMenu(true);
              setDrowsyMobMenu(false);
              setGPSMobMenu(false);
            }}
          >
            <Typography category={"Default"} variant={"H6"}>
              <i className={classes.iconGsens} /> {t("G-sensor graph")}
            </Typography>
            <ExpandMoreIcon className={classes.expandIcon} />
          </div>
        )}
        <div
          className={clsx(classes.gSensorDiv, {
            noDrows: !drowsExist || !showDrows,
            transitionHeight: mobile,
            opened: mobile && gSensMobMenu === true,
          })}
        >
          <Typography
            className={classes.dataTitle}
            category="Default"
            variant="H6"
          >
            <i className={classes.iconGsens} /> {t("G-sensor graph")}
          </Typography>
          <GSensorPlayer
            vod={playVOD}
            accels={accels}
            timestamp={timestamp}
            totalTimestamp={totalTimestamp}
            loading={videoLoading}
            onChangeTime={(t) => setUpdatedTimestamp(t)}
          />
        </div>
        {mobile && showDrows && drowsExist && (
          <div
            className={clsx(classes.contMobMenu, {
              contMobMenuOpened: drowsyMobMenu === true,
            })}
            onClick={() => {
              drowsyMobMenu === true
                ? setDrowsyMobMenu(false)
                : setDrowsyMobMenu(true);
              setGPSMobMenu(false);
              setGSensMobMenu(false);
            }}
          >
            <Typography category={"Default"} variant={"H6"}>
              <i className={classes.iconDrows} />{" "}
              {t("Drowsiness Tracking graph")}
            </Typography>
            <ExpandMoreIcon className={classes.expandIcon} />
          </div>
        )}
        {showDrows && drowsExist && (
          <div
            className={clsx(classes.drowsyDiv, {
              transitionHeight: mobile,
              opened: mobile && drowsyMobMenu === true,
            })}
          >
            <Typography
              className={classes.dataTitle}
              category="Default"
              variant="H6"
            >
              <i className={classes.iconDrows} />{" "}
              {t("Drowsiness Tracking graph")}
              <Tooltip
                disableTouchListener={mobile}
                placement="bottom-end"
                open={openHelpTooltip}
                onOpen={() => setOpenHelpTooltip(true)}
                onClose={() => setOpenHelpTooltip(false)}
                PopperProps={{
                  style: { maxWidth: "144px" },
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
                title={
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["0"]}
                  >
                    {t(
                      "The graph displays the drowsiness data for the Live Event Upload file and the minute before."
                    )}
                  </Typography>
                }
              >
                <HelpIcon
                  style={{
                    float: "right",
                    color: "#7E7E83",
                  }}
                />
              </Tooltip>
            </Typography>
            <DrowsyGraph
              drowsData={drowsData}
              gpsTime={gpsTime}
              timestamp={timestamp}
              loading={videoLoading}
              onChangeTime={(t) => setUpdatedTimestamp(t)}
            />
          </div>
        )}
      </div>

      {mobile && (
        <MobileMenu
          open={openCamMenu}
          onClose={() => setOpenCamMenu(false)}
          paperClasses={classes.paperMobile}
        >
          <div className={classes.dateMobile}>
            <div style={{ maxHeight: 19 }}>
              <Typography category={"Default"} variant={"H6"}>
                {menuDate}
              </Typography>
            </div>
            <div style={{ maxHeight: 16 }}>
              <Typography category={"Default"} variant={"Caption"}>
                {menuTime}
              </Typography>
            </div>
          </div>
          <WebMenuItem
            // mantis - 8882, admin 권한이 있을 때 활성화되도록 수정(Leehj)
            disabled={downloadDisabled}
            className={classes.mobileMemuItem}
            startIcon={<MoveToInBox fontSize="small" />}
            mobileStartIcons={classes.iconMobile}
            onClick={async (e) => {
              setOpenCamMenu(false);
              setDownloadDirections(["Front"]);
              setDownloadQuality("low");
              setDownloadMode("single");
              if (downloadQuality === "low" && !currentVOD?.hasLow) {
                setDownloadQuality("original");
              }
              if (downloadQuality === "original" && !currentVOD?.hasOriginal) {
                setDownloadQuality("low");
              }
              setOpenDownloadModal(true);
            }}
          >
            {t("Download")}
          </WebMenuItem>
          {value === 0 && (
            <WebMenuItem
              disabled={!uploadVodPerm || ExceptionPSN}
              className={classes.mobileMemuItem}
              startIcon={<CloudUpload fontSize="small" />}
              mobileStartIcons={classes.iconMobile}
              onClick={() => {
                setOpenCamMenu(false);
                if (cloudUsage >= 100) {
                  setOpenWarnModal(true);
                } else {
                  setUploadDirection("Front");
                  setOpenUploadModal(true);
                }
              }}
            >
              {t("Upload")}
            </WebMenuItem>
          )}
          {value === 2 && (
            <WebMenuItem
              disabled={!moveToCloudVodPerm}
              className={classes.mobileMemuItem}
              startIcon={<ArrowForwardIcon />}
              mobileStartIcons={classes.iconMobile}
              onClick={() => {
                setOpenCamMenu(false);
                if (currentVOD) {
                  moveToCloud([currentVOD]);
                }
              }}
            >
              {t("Move to Cloud")}
            </WebMenuItem>
          )}
          {canShareToEventmap && (
            <WebMenuItem
              disabled={!shareToEventmapPerm}
              startIcon={<MapOutLine />}
              className={classes.mobileMemuItem}
              mobileStartIcons={classes.iconMobile}
              onClick={() => {
                setOpenCamMenu(false);
                if (currentVOD) {
                  // moveToCloud([currentVOD]);
                  shareEventVideo(currentVOD);
                }
              }}
            >
              {t("Share to Event Map")}
            </WebMenuItem>
          )}
          {/* {value !== 0 && <Divider className={classes.divider} />} */}
          {value !== 0 && (
            <WebMenuItem
              disabled={!deleteVodPerm}
              className={clsx(classes.mobileMemuItem)}
              mobileStartIcons={classes.iconMobile}
              startIcon={
                <DeleteOutLine
                  fontSize="small"
                  // htmlColor={LightColors.secondary["11"]}
                />
              }
              onClick={() => {
                setOpenCamMenu(false);
                setOpenDeleteModal(true);
              }}
            >
              {t("Delete")}
            </WebMenuItem>
          )}
        </MobileMenu>
      )}

      {!mobile && !onScroll && (
        <Menu
          open={openCamMenu}
          anchorEl={anchorRef?.current}
          onClickAway={() => setOpenCamMenu(false)}
          modifiers={{ preventOverflow: { enabled: false } }}
          placement="bottom-end"
        >
          <WebMenuItem
            // mantis - 8882, admin 권한이 있을 때 활성화되도록 수정(Leehj)
            disabled={downloadDisabled}
            startIcon={<MoveToInBox fontSize="small" />}
            onClick={async (e) => {
              setOpenCamMenu(false);
              setDownloadDirections(["Front"]);
              setDownloadQuality("low");
              setDownloadMode("single");
              if (downloadQuality === "low" && !currentVOD?.hasLow) {
                setDownloadQuality("original");
              }
              if (downloadQuality === "original" && !currentVOD?.hasOriginal) {
                setDownloadQuality("low");
              }
              setOpenDownloadModal(true);
            }}
            dense
            gutterClassName={classes.gutter}
            mobileStartIcons={classes.iconMobile}
          >
            {t("Download")}
          </WebMenuItem>
          {value === 0 && (
            <WebMenuItem
              disabled={!uploadVodPerm || ExceptionPSN}
              startIcon={<CloudUpload fontSize="small" />}
              onClick={() => {
                setOpenCamMenu(false);
                if (cloudUsage >= 100) {
                  setOpenWarnModal(true);
                } else {
                  setUploadDirection("Front");
                  setOpenUploadModal(true);
                }
              }}
              dense
              gutterClassName={classes.gutter}
              mobileStartIcons={classes.iconMobile}
            >
              {t("Upload")}
            </WebMenuItem>
          )}
          {value === 2 && (
            <WebMenuItem
              disabled={!moveToCloudVodPerm}
              startIcon={<ArrowForwardIcon />}
              onClick={() => {
                setOpenCamMenu(false);
                if (currentVOD) {
                  // mantis : 8503
                  // openCamMenu가 닫힐 때 까지
                  // 클라우드 이동 지연
                  setTimeout(() => moveToCloud([currentVOD]), 200);
                }
              }}
              dense
              gutterClassName={classes.gutter}
              mobileStartIcons={classes.iconMobile}
            >
              {t("Move to Cloud")}
            </WebMenuItem>
          )}
          {canShareToEventmap && (
            <WebMenuItem
              disabled={!shareToEventmapPerm}
              startIcon={<MapOutLine />}
              onClick={() => {
                setOpenCamMenu(false);
                if (currentVOD) {
                  // moveToCloud([currentVOD]);
                  shareEventVideo(currentVOD);
                }
              }}
              dense
              gutterClassName={classes.gutter}
              mobileStartIcons={classes.iconMobile}
            >
              {t("Share to Event Map")}
            </WebMenuItem>
          )}
          {value !== 0 && <Divider className={classes.divider} />}
          {value !== 0 && (
            <WebMenuItem
              disabled={!deleteVodPerm}
              // className={classes.delete}
              startIcon={
                <DeleteOutLine
                  fontSize="small"
                  // htmlColor={LightColors.secondary["11"]}
                />
              }
              onClick={() => {
                setOpenCamMenu(false);
                setOpenDeleteModal(true);
              }}
              dense
              gutterClassName={classes.gutter}
              mobileStartIcons={classes.iconMobile}
            >
              {t("Delete")}
            </WebMenuItem>
          )}
        </Menu>
      )}

      {shareResultModal}

      <Modal
        open={openWarnModal}
        onClickPositive={() => {
          setOpenWarnModal(false);
          // mantis - 12484, 100%경고팝업 해당 화면 진입시 노출되며 한번 종료한 팝업은 탭이동시 노출하지 않도록 적용 (Leehj)
          if (cloudUsage >= 100) {
            setOpenWarn100Modal(true);
          }
        }}
        heading={
          cloudUsage >= 100
            ? t("Cloud Storage Full")
            : t("Cloud storage warning")
        }
        content={
          cloudUsage >= 100 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: t("Cloud storage usage_100").replace(
                  // eslint-disable-next-line no-control-regex
                  new RegExp("\n", "g"),
                  "<br/>"
                ),
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: t("Cloud storage usage_", {
                  a:
                    // cloudUsage.toFixed(0),
                    // mantis - 12333, App과 표시되는 용량 동일하도록 수정 (Leehj)
                    cloudUsage >= 100
                      ? 100
                      : cloudUsage >= 90
                      ? 90
                      : cloudUsage >= 70
                      ? 70
                      : cloudUsage.toFixed(0),
                  // eslint-disable-next-line no-control-regex
                }).replace(new RegExp("\n", "g"), "<br/>"),
              }}
            />
          )
        }
        RButton={t("OK")}
        actionClassName={classes.modalBottom}
        className={classes.modalWrap}
      />
      {/* mantis - 12508, 클라우드 사용량 초과 팝업 (Leehj) */}
      <Modal
        open={openCloudLimitModal}
        mobile={mobile}
        onClickPositive={() => {
          dispatch(setCloudLimit(false));
          setOpenCloudLimitModal(false);
        }}
        content={t("Exceeded your Cloud_")}
        RButton={t("OK")}
      />

      <Modal
        contentClassName={classes.modalContent}
        titleClassName={classes.mobileModalTitle}
        open={openDeleteModal}
        mobile={mobile}
        onClose={() => {
          if (loading) {
            dispatch(setCancelDelete(true));
          } else {
            setOpenDeleteModal(false);
          }
        }}
        onClickNegative={() => setOpenDeleteModal(false)}
        onClickPositive={() => {
          // console.log(selectedVODs, currentVOD);
          if (camera) {
            if (selectedVODs.length > 0) {
              if (value === 1) {
                dispatch(
                  deleteCloudVODFile({
                    camera: camera,
                    filenames: _.flattenDeep([
                      _.map(selectedVODs, (vod) => {
                        if (vod.hasOriginal && vod.hasLow) {
                          return [vod.filename, vod.lowFilename, vod.thmName];
                        } else if (vod.hasOriginal) {
                          return [vod.filename, vod.thmName];
                        } else {
                          return [vod.lowFilename ?? "", vod.thmName];
                        }
                      }),
                    ]),
                    vodCount: selectedVODs.length,
                  })
                );
              } else if (value === 2) {
                dispatch(
                  deleteEventVODFile({
                    psn: camera.psn,
                    vods: selectedVODs,
                    fileLength: selectedVODs.length,
                  })
                );
              }
            } else if (currentVOD) {
              if (value === 1) {
                dispatch(
                  deleteCloudVODFile({
                    camera: camera,
                    // filenames: [currentVOD.filename ],
                    filenames:
                      currentVOD.hasLow && currentVOD.hasOriginal
                        ? [
                            currentVOD.filename,
                            currentVOD.lowFilename ?? "",
                            currentVOD.thmName ?? "",
                          ]
                        : currentVOD.hasOriginal
                        ? [currentVOD.filename, currentVOD.thmName ?? ""]
                        : [
                            currentVOD.lowFilename ?? "",
                            currentVOD.thmName ?? "",
                          ],
                    vodCount: 1,
                  })
                );
              } else if (value === 2 && currentVOD) {
                dispatch(
                  deleteEventVODFile({
                    psn: camera.psn,
                    vods: [currentVOD],
                    fileLength: 1,
                  })
                );
              }
            }
          }
        }}
        heading={t("Delete")}
        close
        loading={
          loading &&
          (type === deleteEventVODFile.type || type === deleteCloudVODFile.type)
        }
        content={
          selectedVODs.length > 1
            ? `${t("Are you sure_videos")}`
            : `${t("Are you sure_video")}`
        }
        LButton={t("Cancel")}
        RButton={t("Delete")}
        Secondary
        actionClassName={classes.mobileModalBtn}
        RButtonClassName={classes.modalBtn}
        LButtonClassName={classes.modalBtn}
      />
      {/* mantis - 10521, free계정으로 비디오&다운로드 100번한 상태에서 재생시도시 출력되는 모달 (Leehj)*/}
      <VideoExceedModal
        open={openExceedModal}
        onClose={() => setOpenExceedModal(false)}
        onClickNegative={() => {
          setOpenExceedModal(false);
        }}
        onClickPositive={() => {
          setOpenPricing(true);
          setOpenExceedModal(false);
        }}
      />

      {/* mantis - 10521, free계정으로 비디오&다운로드 100번한 상태에서 다운로드 시도시 출력되는 모달 (Leehj)*/}
      {openDownloadExceedModal && (
        <DownloadVideoExceedModal
          open={openDownloadExceedModal}
          onClose={() => setOpenDownloadExceedModal(false)}
          onClickNegative={() => {
            setOpenDownloadExceedModal(false);
          }}
          onClickPositive={() => {
            setOpenPricing(true);
            setOpenDownloadExceedModal(false);
          }}
        />
      )}

      {/* mantis - 10521, 비디오 초과재생 모달에서 'Go to Fleet Plan'버튼 누르면 오픈되는 모달 (Leehj)*/}
      <PricingModal open={openPricing} onClose={() => setOpenPricing(false)} />

      <Modal
        open={openUploadModal}
        close
        onClose={() => setOpenUploadModal(false)}
        onClickNegative={() => setOpenUploadModal(false)}
        onClickPositive={() => {
          if (uploadDirection === "Front" && currentVOD?.front) {
            uploadCloud(currentVOD.front);
          }
          if (uploadDirection === "Rear" && currentVOD?.rear) {
            uploadCloud(currentVOD.rear);
          }
          if (uploadDirection === "Interior" && currentVOD?.interior) {
            uploadCloud(currentVOD.interior);
          }

          //7 BOX option카메라 관련 추가 (8474)
          if (uploadDirection === "Option" && currentVOD?.option) {
            uploadCloud(currentVOD.option);
          }
          //  mantis - 10104, Upload버튼 누르면 체크박스 해제되도록 수정(Leehj)
          setSelectedVODs([]);
          setOpenUploadModal(false);
        }}
        heading={t("Videos to upload")}
        content={
          <RadioGroup
            aria-label="uploadDirection"
            name="uploadDirection"
            value={uploadDirection}
            onChange={(e) => setUploadDirection(e.target.value as LocationType)}
          >
            <RadioButton
              value="Front"
              label={t("Front")}
              disabled={!currentVOD?.hasFront}
            />
            <RadioButton
              value="Rear"
              label={t("Rear")}
              disabled={!currentVOD?.hasRear}
            />
            {!isOptionModel && (
              <RadioButton
                value="Interior"
                label={t("Interior")}
                disabled={!currentVOD?.hasInterior}
              />
            )}
            {/* 7 BOX option카메라 관련 추가 (8474) */}
            {isOptionModel && (
              <RadioButton
                value="Option"
                label={t("Option")}
                disabled={!currentVOD?.hasOption}
              />
            )}
          </RadioGroup>
        }
        RButton={t("Upload")}
        LButton={t("Cancel")}
        titleClassName={classes.modalTitle}
        className={classes.downloadModalRoot}
        actionClassName={classes.downloadModalbottom}
        contentClassName={clsx(
          classes.exceedModalContentDiv,
          mobile && classes.downloadMobileModal
        )}
        closeStyle={mobile ? classes.closeIconStyle : ""}
      />
      <Modal
        open={openDownloadModal}
        // close={!mobile}
        onClose={() => setOpenDownloadModal(false)}
        onClickNegative={() => {
          setOpenDownloadModal(false);
        }}
        onClickPositive={() => {
          //  mantis - 10104, Download버튼 누르면 체크박스 해제되도록 수정(Leehj)
          setSelectedVODs([]);
          setOpenDownloadModal(false);
          if (value === 0 && downloadDirections.length > 1) {
            let s: ICameraVOD[] = [];
            if (downloadMode === "single") {
              if (
                _.includes(downloadDirections, "Front") &&
                currentVOD?.front
              ) {
                s.push(currentVOD.front);
              }
              if (_.includes(downloadDirections, "Rear") && currentVOD?.rear) {
                s.push(currentVOD.rear);
              }
              if (
                _.includes(downloadDirections, "Interior") &&
                currentVOD?.interior
              ) {
                s.push(currentVOD.interior);
              }
              //7 BOX option카메라 관련 추가 (8474)
              if (
                _.includes(downloadDirections, "Option") &&
                currentVOD?.option
              ) {
                s.push(currentVOD.option);
              }
            } else {
              if (_.includes(downloadDirections, "Front")) {
                s = _.chain(selectedVODs)
                  .map((v) => v.front)
                  .compact()
                  .value();
              }
              if (_.includes(downloadDirections, "Rear")) {
                s = _.concat(
                  s,
                  _.chain(selectedVODs)
                    .map((v) => v.rear)
                    .compact()
                    .value()
                );
              }
              if (_.includes(downloadDirections, "Interior")) {
                s = _.concat(
                  s,
                  _.chain(selectedVODs)
                    .map((v) => v.interior)
                    .compact()
                    .value()
                );
              }

              //7 BOX option카메라 관련 추가 (8474)
              if (_.includes(downloadDirections, "Option")) {
                s = _.concat(
                  s,
                  _.chain(selectedVODs)
                    .map((v) => v.option)
                    .compact()
                    .value()
                );
              }
            }

            downloadMultipleVOD(s);
          } else if (downloadMode === "single") {
            downloadSingleVOD();
          } else {
            downloadMultipleVOD(selectedVODs);
          }
        }}
        heading={t("Select video download quality")}
        content={downloadModalContentMarkup}
        RButton={t("OK")}
        LButton={t("Cancel")}
        RButtonDisabled={value === 0 && downloadDirections.length === 0}
        titleClassName={classes.downloadModalTitle}
        className={classes.downloadModalRoot}
        // actionClassName={classes.downloadModalbottom}
        contentClassName={clsx(
          classes.downloadModalContentDiv,
          mobile && classes.downloadMobileModal
        )}
        closeStyle={mobile ? classes.closeIconStyle : ""}
        actionClassName={classes.downloadModalBtn}
        RButtonClassName={classes.modalBtn}
        LButtonClassName={classes.modalBtn}
      />
      {renderFilterDrawer()}
      {camera && sharingVOD && (
        <>
          <EventShareModal
            mode={value}
            psn={camera.psn}
            filename={sharingVOD.filename}
            thmRid={sharingVOD.thmRid}
            rid={sharingVOD.lowRid}
            open={openEventShareModal}
            onClose={() => {
              setOpenEventShareModal(false);
              onBackScroll?.(false);
              setOpenSuccessModal(false);
            }}
            onOpenHashtag={() => {
              setOpenEventShareModal(false);
              setOpenHashtagModal(true);
            }}
            onSuccessSave={(check) => {
              setOpenEventShareModal(false);
              setOpenSuccessModal(true);
            }}
            onFailSave={() => {
              setOpenEventShareModal(false);
              setEventShareStatus("exist");
            }}
          />
          {openHashtagModal && (
            <HashtagModal
              open={openHashtagModal}
              onClose={() => {
                setOpenEventShareModal(true);
                setOpenHashtagModal(false);
                onBackScroll?.(true);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
