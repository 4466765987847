import { Link, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import {
  LightColors,
  Webviewer,
  TimeZoneList,
  SERVER_MAINTENANCE_NOTICE_URI,
} from "@thingsw/pitta-modules";
import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  modalDiv: {
    maxWidth: 500,
  },
  modalContentDiv: {
    padding: "56px 16px 0",
    textAlign: "center",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      padding: "40px 24px 22px",
      alignSelf: "normal",
    },
  },

  contentCard: {
    backgroundColor: LightColors.primary["6"],
    width: "100%",
    maxWidth: 454,
    maxHeight: 205,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "24px 0 24px",
    marginBottom: 16,
  },

  contentTop: {
    margin: "16px 0px 24px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 0px 22px",
    },
  },

  contentBottom: {
    padding: "0px 26px 6px",

    [theme.breakpoints.up("sm")]: {
      padding: "0px 64px 6px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0px 80px 6px",
    },
  },

  link: {
    cursor: "pointer",
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:visited": {
      color: LightColors.primary["7"],
    },
  },

  modalBtn: {
    padding: "14px 14px 16px 0px",
    [theme.breakpoints.up("md")]: {
      alignSelf: "center",
      padding: "0px 22px 24px 26px",
    },
  },
}));

// Date 미 지정시 피그마 날짜로 지정됨.
// StartTime은 점검 시작 시간, EndTime은 점검 끝나는 시간
interface MaintenanceNoticeProps {
  start: moment.Moment;
  end: moment.Moment;
  open: boolean;
  url?: string;
  close?: boolean;
  app?: boolean;
  onClose: () => void;
  onRemindClose: () => void;
  onOpenLink?: () => void;
}

export const MaintenanceNoticeModal = (props: MaintenanceNoticeProps) => {
  const {
    start,
    end,
    close,
    app,
    onClose,
    onOpenLink,
    open,
    onRemindClose,
  } = props;
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const tz = useMemo(() => moment.tz.guess(), []);

  const dateMarkup = useMemo(() => {
    const s = moment(start).tz(tz);
    const e = moment(end).tz(tz);
    if (s.isSame(e, "D")) {
      return (
        <Typography category="Default" variant="BodyBold">
          {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
        </Typography>
      );
    } else {
      return (
        <>
          <Typography category="Default" variant="BodyBold">
            {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
          <br />
          <Typography category="Default" variant="BodyBold">
            - {moment(end).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
        </>
      );
    }
  }, [end, start, tz]);

  return (
    <Modal
      className={classes.modalDiv}
      contentClassName={classes.modalContentDiv}
      open={open}
      onClose={onClose}
      content={
        <div>
          <div>
            <Typography category="Default" variant="H3">
              {t("Scheduled maintenance Notice")}
            </Typography>
          </div>
          <div className={classes.contentTop}>
            <div>
              <Typography category="Default" variant="Body">
                {t("Our Cloud server_")}
              </Typography>
            </div>
            <div>
              <Typography category="Default" variant="Body">
                {t("During that time_")}
              </Typography>
            </div>
          </div>
          <div className={classes.contentCard}>
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["3"]}
              >
                {t("Expected maintenance start_")}
              </Typography>
            </div>
            <div style={{ margin: "12px 0 16px" }}>
              <div>{dateMarkup}</div>
              <div style={{ padding: "6px 0px 2px" }}>
                <Typography category="Default" variant="H3">
                  {moment(start).tz(tz).format("HH:mm A")} -{" "}
                  {moment(end).tz(tz).format("HH:mm A")}
                </Typography>
              </div>
              <div>
                <Typography category="Default" variant="Caption">
                  {TimeZoneList[moment.tz(tz).zoneAbbr()]} (
                  {moment.tz(tz).zoneAbbr()})
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                category="Default"
                variant="Body"
                dangerouslySetInnerHTML={{
                  __html: t("Expected duration_", {
                    a: `<strong>${_.round(
                      moment.duration(end.diff(start)).asHours()
                    )} hours.</strong>`,
                  }),
                }}
              ></Typography>
            </div>
          </div>
          <div>
            <div className={classes.contentBottom}>
              <Typography category="Default" variant="Body">
                <Trans
                  t={t}
                  components={{
                    a: (
                      <Link
                        href={SERVER_MAINTENANCE_NOTICE_URI}
                        target="_blank"
                        onClick={() => app && onOpenLink?.()}
                        className={classes.link}
                      ></Link>
                    ),
                  }}
                >
                  For more information_
                </Trans>
              </Typography>
            </div>
          </div>
        </div>
      }
      heading=""
      close={close !== false}
      fullSize={mobile}
      actionClassName={classes.modalBtn}
      RButton={t("OK")}
      onClickPositive={onClose}
      onClickNegative={onRemindClose}
      LButton={t("Do not remind_")}
    />
  );
};
