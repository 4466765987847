import React from "react";
import { makeStyles, PopperPlacementType } from "@material-ui/core";
import { Menu, MobileMenu } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { Property } from "csstype";

import { LangMenuItems } from "./LangMenuItems";
import { ReferenceObject } from "@thingsw/pitta-modules";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    minWidth: (props: LangugaeMenuProps) => (props.mode === "main" ? 144 : 570),
    maxHeight: (props: LangugaeMenuProps) =>
      // mantis-8443 브라우저 축소 시 일본어 UI 밖으로 표시되어 값 수정 340->360. -SW-
      props.mode === "webviewer" ? 360 : undefined,
  },
}));

interface LangugaeMenuProps {
  open: boolean;
  mobile?: boolean;
  mode: "main" | "webviewer";
  onClickAway: React.MouseEventHandler<Document>;
  onClick: (lang: string) => void;
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);

  /**
   * Popper placement.
   */
  placement?: PopperPlacementType;
  /**
   * The **`transform-origin`** CSS property sets the origin for an element's transformations.
   *
   * **Syntax**: `[ <length-percentage> | left | center | right | top | bottom ] | [ [ <length-percentage> | left | center | right ] && [ <length-percentage> | top | center | bottom ] ] <length>?`
   *
   * **Initial value**: `50% 50% 0`
   *
   * | Chrome  |  Firefox  | Safari  |  Edge  |   IE    |
   * | :-----: | :-------: | :-----: | :----: | :-----: |
   * | **36**  |  **16**   |  **9**  | **12** | **10**  |
   * | 1 _-x-_ | 3.5 _-x-_ | 2 _-x-_ |        | 9 _-x-_ |
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/transform-origin
   */
  transformOrigin?: Property.TransformOrigin;
  /**
   * Popper.js is based on a "plugin-like" architecture,
   * most of its features are fully encapsulated "modifiers".
   *
   * A modifier is a function that is called each time Popper.js needs to
   * compute the position of the popper.
   * For this reason, modifiers should be very performant to avoid bottlenecks.
   * To learn how to create a modifier, [read the modifiers documentation](https://popper.js.org/docs/v1/#modifiers).
   */
  modifiers?: object;
}

export const LanguageMenu = (props: LangugaeMenuProps) => {
  const classes = useStyles(props);
  const {
    open,
    mobile,
    onClickAway,
    anchorEl,
    mode,
    onClick,
    placement,
    transformOrigin,
    modifiers,
  } = props;
  const { i18n } = useTranslation();

  const handleClickLang = (evt: any, lang: string) => {
    // mantis - 8445 메뉴닫는 함수는 이미 존재하므로 onClickAway(evt)는 제거.
    // onClickAway(evt)
    onClick(lang);
    localStorage.setItem("pitta-webviewer-pref-lang", lang);
  };

  return mobile ? (
    <MobileMenu open={open} onClose={onClickAway}>
      <LangMenuItems
        mode={mode}
        mobile={mobile}
        language={i18n.language}
        onChangeLanguage={handleClickLang}
      />
    </MobileMenu>
  ) : (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClickAway={onClickAway}
      classes={{ root: classes.root }}
      placement={placement}
      transformOrigin={transformOrigin}
      modifiers={modifiers}
    >
      <LangMenuItems
        mode={mode}
        language={i18n.language}
        onChangeLanguage={handleClickLang}
      />
    </Menu>
  );
};
