import { Card, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  ResolutionIcon,
  Sliders,
  Switch,
  Typography,
  VideoSegmentIcon,
  Selector,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next/";

import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { CameraPlaybackScreenProps } from "../CameraPlaybackScreen";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  loadFirmwareSettings,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { VideoOverlayPanel } from "../../components/cameras/VideoOverlayPanel";
import { CameraRotationPanel } from "../../components/cameras/CameraRotationPanel";
import { DashcamRestartingModal } from "../../components/cameras/DashcamRestartingModal";
import { FirmwareSettingModal } from "../../components/cameras/FirmwareSettingModal";
import { Prompt, useHistory } from "react-router-dom";
import { DiscardChangeUXUIModal } from "../../components/uxui/DiscardChangeUXUIModal";
import { FirmwareFormatModalUXUI } from "../../components/uxui/FirmwareFormatModalUXUI";
import {
  Webviewer,
  LightColors,
  TabNameInfo1,
  TabNameInfo3,
  TrueFalsetype,
  SIGMASTAR_XPLUS_MODELS,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#E8E8E8",
    minHeight: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: "calc(var(--vh, 1vh) * 100 - 101px)",
    },
  },
  rootPanel: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    transition: theme.transitions.create("transform"),
    position: "relative",
    padding: "24px 16px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 24,
    },
  },
  listPane: {
    flexDirection: "column",
    top: 16,
    bottom: 0,
    right: 0,
    left: 0,
    width: "inherit",
    transition: theme.transitions.create("transform"),
    transitionDuration: "0.5s",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // position: "absolute",
      top: 23,
      width: 673,
      flex: 1,
      display: "flex",
    },
  },
  videoHide: {
    transition: theme.transitions.create("transform"),
    transitionDuration: "0.5s",
    transform: "translateX(-100vw)",
    position: "absolute",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-675px)",
    },
  },
  rotationHide: {
    transitionDuration: "0.5s",
    transition: theme.transitions.create("transform"),
    transform: "translateX(100vw)",
    position: "absolute",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(100vw)",
    },
  },
  overlayHide: {
    transitionDuration: "0.5s",
    transition: theme.transitions.create("transform"),
    transform: "translateX(100vw)",
    position: "absolute",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(100vw)",
    },
  },

  titleDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2) - 2,
    marginLeft: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 20,
    },
  },
  firstBox: {
    width: "100%",
  },
  card: {
    display: "flex",
    minHeight: 46,
    alignItems: "center",
    borderRadius: 12,
    marginBottom: theme.spacing(2),
    maxWidth: 671,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.06), 0px 6px 40px rgba(0, 0, 0, 0.05)",
  },
  cardDescDiv: {
    padding: "0 12px",
    color: "#68686E",
    maxWidth: 671,
    marginBottom: theme.spacing(2),
    whiteSpace: "pre-line",
  },
  firstCard: {
    padding: "16px 20px 8px 20px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px 14px 20px",
    },
  },
  secondCard: {
    padding: "12px 20px 14px 20px",
  },
  thirdCard: {
    padding: "4px 24px",
    marginBottom: 8,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "10px 24px",
    },
  },
  fourthCard: {
    padding: "10px 24px 10px 24px",
    marginBottom: 8,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "10px 24px",
    },
  },
  moveCard: {
    padding: "12px 21px",
    marginBottom: 7,
  },

  optionDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
  },
  marginB2: {
    marginBottom: theme.spacing(2) - 2,
  },
  optionTitle: {
    display: "flex",
    alignItems: "center",
  },
  secondOptionTitle: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2.5),
  },
  resolutionWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: theme.spacing(2) - 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
  },
  optionTitleDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: -2,
    marginBottom: 11,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      marginBottom: 0,
    },
  },
  optionSecontTitleDiv: {
    marginBottom: 15,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },

  iconMargin: {
    marginRight: theme.spacing(0.125),
  },
  videoQualityContent: {
    padding: "0px 11px",
  },
  qualityDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  qualityPaddingDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 1px",
  },
  switch: {
    marginRight: 20,
    width: 48,
    height: 48,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 16,
    },
  },

  colum: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  boxMenu: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      cursor: "pointer",
    },
  },

  arrowIcon: {
    color: LightColors.primary["3"],
  },

  sliderDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginLeft: -4,
  },
  sliderRoot: {
    width: "94%",
    padding: "21px 0px 23px 0px",
  },
  sliderRail: {
    height: 6,
    borderRadius: 9,
  },
  sliderTrack: {
    height: 6,
    borderRadius: 9,
  },
  sliderThumb: {
    marginTop: -6,
    marginLeft: -8,
  },
  saveBtn: {
    width: 96,
    marginTop: 8,
    maxWidth: 673,
  },
  divider: {
    margin: "11px 0px 16px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0px 0 16px",
      maxWidth: 671,
    },
  },
  videoCodecContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  videoCodecTitle: {
    display: "flex",
    flexDirection: "column",
  },
  videoCodecSelect: {
    "& .MuiPaper-root": {
      padding: 0,
    },
  },
  select: {
    borderRadius: 12,
    padding: "8px 24px",
  },
  selectPaper: {
    width: 250,
  },
}));

interface VideoFirmwareSettingProps {
  onClose: () => void;
}

export const CameraVideoSettingScreen = (
  props: CameraPlaybackScreenProps &
    PermissionProps &
    ScreenDefaultProps &
    VideoFirmwareSettingProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme() as Theme;
  const history = useHistory();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera, firmware, firmwareConfig, loading, type } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const [menu, setMenu] = useState<number>();
  const [current, setCurrent] = useState<TabNameInfo1>();
  const [currentTab3Obj, setCurrentTab3Obj] = useState<TabNameInfo3>();
  // mantis - 12591, 설정 변경 전 값을 알기 위해서 (Leehj)
  // mantis - 12579, 설정 변경 전 값을 알기 위해서 (Leehj)
  const [currentTab1Obj, setCurrentTab1Obj] = useState<TabNameInfo1>();
  const [disabledChange, setDisabledChange] = useState(true);

  const [openRestartingdModal, setOpenRestartingModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openFirmwareFormat, setOpenFirmwareFormat] = useState(false);
  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);

  const [targetLocation, setTargetLocation] = useState<Location>();

  useEffect(() => {
    if (!loading) {
      setOpenFirmwareFormat(false);
    }
  }, [loading]);

  const settings = useMemo(() => {
    return firmwareConfig?.Recording;
  }, [firmwareConfig?.Recording]);

  const videoSettings = useMemo(() => {
    return firmwareConfig?.Video;
  }, [firmwareConfig?.Video]);

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        event.returnValue = "";
        // 새로고침이나 창 종료시에는 해당 모달 출력 방지
        setOpenDiscardModal(false);
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings(camera.psn));
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );

      if (currentTab) {
        // 1. video화면에서 저장 누르지않고 설정 변경 2.overlay, rotation페이지이동 다시 video페이지로 이동
        // 3.이전에 변경한 설정이 리셋되는부분 수정
        setCurrent((current) =>
          !current ? (currentTab.section_info as TabNameInfo1) : current
        );
      }
    }
  }, [firmware]);

  // userString값을 가져오기 위하여
  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );

      if (currentTab3) {
        setCurrentTab3Obj(currentTab3.section_info as TabNameInfo3);
      }
      // mantis - 12591, 설정 변경 전 값을 알기 위해서 (Leehj)
      // mantis - 12579, 설정 변경 전 값을 알기 위해서 (Leehj)
      if (currentTab) {
        setCurrentTab1Obj(currentTab.section_info as TabNameInfo1);
      }
    }
  }, [firmware]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      const currentTab3 = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      const predicts = _.keys(currentTab?.section_info as TabNameInfo1).map(
        (k) => {
          const key = k as keyof TabNameInfo1;
          return (
            (currentTab?.section_info as TabNameInfo1)[key] === current?.[key]
          );
        }
      );

      const predictsTab3 =
        (currentTab3?.section_info as TabNameInfo3)["userString"] ===
        currentTab3Obj?.userString;

      setDisabledChange(_.every(predicts) && predictsTab3);
    }
  }, [firmware, current, currentTab3Obj?.userString]);

  const getBoolean = (TrueFalse: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  };
  const handleChange = (key: keyof TabNameInfo1, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo1));
  };

  const handleUpdate = useCallback(() => {
    try {
      if (current && camera && firmware && currentTab3Obj) {
        const updated = {
          ...firmware,
          cloud_settings: _.filter(
            firmware.cloud_settings,
            (c) => c.section_name !== "Tab1" && c.section_name !== "Tab3"
          ),
        };

        updated.cloud_settings = [
          { section_info: current, section_name: "Tab1" },
          { section_info: currentTab3Obj, section_name: "Tab3" },
          ...updated.cloud_settings,
        ];

        dispatch(updateFirmwareSettings({ firmware: updated }));
      }
    } catch {}
  }, [camera, current, currentTab3Obj, dispatch, firmware]);

  return (
    <div className={classes.root}>
      <Prompt
        when={!disabledChange && !targetLocation}
        message={(location: any) => {
          setTargetLocation(location);
          // 세팅화면이 아닌 페이지로 이동하는 경우에만 discard모달 출력
          if (location.pathname.indexOf("settings") === -1) {
            setOpenDiscardModal(true);
            return false;
          } else {
            return true;
          }
        }}
      />
      <div className={classes.rootPanel}>
        <div
          className={clsx(classes.listPane, {
            [classes.videoHide]: menu !== undefined,
          })}
        >
          <div className={classes.titleDiv}>
            <Typography
              category="Default"
              variant="H6"
              style={{ marginTop: 2 }}
            >
              {t("Video")}
            </Typography>
          </div>
          <Card className={clsx(classes.card, classes.firstCard)}>
            <div className={classes.firstBox}>
              <div className={classes.resolutionWrap}>
                <div className={classes.optionTitleDiv}>
                  <ResolutionIcon className={classes.iconMargin} />
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {t("Resolution")}
                  </Typography>
                </div>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["3"]}
                >
                  {/* mantis - 10836, 각 캠의 펌웨어 설정표대로 출력되도록 적용 (Leehj) */}
                  {t(videoSettings?.ImageSetting?.options["0"].value ?? "")}
                  {/* {t("Full HD_")} */}
                </Typography>
              </div>
              <div className={classes.optionDiv}>
                <div
                  className={clsx(
                    classes.optionTitleDiv,
                    classes.optionSecontTitleDiv
                  )}
                >
                  <VideoSegmentIcon className={classes.iconMargin} />
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {t("Video segment length")}
                  </Typography>
                </div>

                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["3"]}
                >
                  {t("1 min")}
                </Typography>
              </div>
            </div>
          </Card>
          {videoSettings?.["VCodecType"] && (
            <>
              <Card className={clsx(classes.card, classes.secondCard)}>
                <div className={classes.videoCodecContent}>
                  <div className={classes.videoCodecTitle}>
                    <Typography category="Default" variant="Body">
                      {t("Video codec")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="SmallBold"
                      htmlColor={LightColors.primary["7"]}
                    >
                      {!current?.VCodecType || current?.VCodecType === "0"
                        ? t("H.265 (HEVC)")
                        : t("H.264 (AVC)")}
                    </Typography>
                  </div>
                  <div className={classes.videoCodecSelect}>
                    <Selector
                      outlinedInputClassName={classes.select}
                      menuItemClassName={classes.selectPaper}
                      t={t}
                      menuScrollTime
                      dense
                      mobile={mobile}
                      keyValue={current?.VCodecType ?? "0"}
                      onChange={(e) =>
                        setCurrent((c) => {
                          return (
                            c && {
                              ...c,
                              VCodecType: e.key as TrueFalsetype,
                            }
                          );
                        })
                      }
                      items={[
                        { key: "0", value: "H.265 (HEVC)" },
                        { key: "1", value: "H.264 (AVC)" },
                      ]}
                    />
                  </div>
                </div>
              </Card>
              <div className={classes.cardDescDiv}>
                <Typography category="Default" variant="Small">
                  {!current?.VCodecType || current?.VCodecType === "0"
                    ? t("Recommended for higher bitrate_")
                    : t("Recommended for for better compatibility_")}
                </Typography>
              </div>
            </>
          )}
          {videoSettings?.["VideoQuality"] && (
            <Card className={clsx(classes.card, classes.secondCard)}>
              <div className={classes.colum}>
                <div className={classes.secondOptionTitle}>
                  <Typography category="Default" variant="Body">
                    {t("Video quality")} ({t("Bitrate")})
                  </Typography>
                </div>
                <div className={classes.videoQualityContent}>
                  <div className={classes.qualityDiv}>
                    <div>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Normal")}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("High")}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Highest_3")}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Extreme_2")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.sliderDiv}>
                    <Sliders
                      noTooltip
                      rootClasses={classes.sliderRoot}
                      railClasses={classes.sliderRail}
                      trackClasses={classes.sliderTrack}
                      thumbClasses={classes.sliderThumb}
                      step={1}
                      min={0}
                      max={3}
                      //  "0": "Extreme","1": "Highest","2": "High", "3": "Normal"를 적용하기 위해 (Leehj)
                      value={Math.abs(
                        parseInt(current?.VideoQuality ?? "0") - 3
                      )}
                      onChange={(e, value) => {
                        const v = value as number;
                        setCurrent((c) => {
                          return (
                            c && {
                              ...c,
                              VideoQuality: Math.abs(v - 3).toString(),
                            }
                          );
                        });
                      }}
                    />
                  </div>

                  <div
                    className={clsx(
                      classes.qualityDiv,
                      classes.qualityPaddingDiv
                    )}
                  >
                    <div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {/* osd 키값 참고 */}
                        {t("More video files")}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Less recording files")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}
          {_.includes(SIGMASTAR_XPLUS_MODELS, camera?.model) && (
            <div className={classes.cardDescDiv}>
              <Typography category="Default" variant="SmallBold">
                {t("A minute of front camera_")}
              </Typography>
              <br />
              <Typography category="Default" variant="Small">
                {!current?.VCodecType || current?.VCodecType === "0"
                  ? t("Extreme(N Mbps): N MB_", {
                      ex1: 60,
                      ex2: 450,
                      ht1: 25,
                      ht2: 193,
                      hi1: 12,
                      hi2: 98,
                      no1: 8,
                      no2: 69,
                    })
                  : t("Extreme(N Mbps): N MB_", {
                      ex1: 40,
                      ex2: 303,
                      ht1: 25,
                      ht2: 193,
                      hi1: 12,
                      hi2: 98,
                      no1: 8,
                      no2: 69,
                    })}
              </Typography>
              <br />
              <Typography category="Default" variant="Small">
                {t("File size information reflects_")}
              </Typography>
            </div>
          )}
          <Divider className={classes.divider} />

          <Card className={clsx(classes.card, classes.thirdCard)}>
            <div className={classes.switch}>
              <Switch
                checked={getBoolean(current?.["VoiceRecord"] ?? "1")}
                onChange={(e) => {
                  handleChange("VoiceRecord", e.target.checked);
                }}
              />
            </div>
            <div className={classes.colum}>
              <Typography
                category="Default"
                variant="Body"
                style={{ marginBottom: mobile ? 3 : 4 }}
              >
                {t("Audio recording")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Toggle the mic_")}
              </Typography>
            </div>
          </Card>

          {settings?.["UseGpsInfo"] && (
            <Card className={clsx(classes.card, classes.fourthCard)}>
              <div className={classes.switch}>
                <Switch
                  checked={getBoolean(current?.["UseGpsInfo"] ?? "1")}
                  onChange={(e) => {
                    handleChange("UseGpsInfo", e.target.checked);
                  }}
                />
              </div>
              <div className={classes.colum}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ marginBottom: mobile ? 2 : 0 }}
                >
                  {t("GPS location recording")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["3"]}
                >
                  {t("When OFF, GPS_")}
                </Typography>
              </div>
            </Card>
          )}

          <Card className={clsx(classes.card, classes.moveCard)}>
            <div className={classes.boxMenu} onClick={() => setMenu(0)}>
              <Typography category="Default" variant="Body">
                {t("Camera rotation_2")}
              </Typography>

              <IconButton>
                <ChevronRightIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
          </Card>
          <Card
            variant="outlined"
            className={clsx(classes.card, classes.moveCard)}
          >
            <div className={classes.boxMenu} onClick={() => setMenu(1)}>
              <Typography category="Default" variant="Body">
                {t("Video overlay")}
              </Typography>
              <IconButton>
                <ChevronRightIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
          </Card>
          <Button
            disabled={disabledChange}
            loading={loading && type === updateFirmwareSettings.type}
            variant="contained"
            color="primary"
            className={classes.saveBtn}
            onClick={() => {
              // mantis - 12579, Video Codec 설정 값 변경 시 포맷 안내 팝업 노출 (Leehj)
              // mantis - 12591, Video Quality (Bitrate) 설정 값 변경 시 포맷 안내 팝업 노출 (Leehj)
              if (
                currentTab1Obj?.VCodecType !== current?.VCodecType ||
                currentTab1Obj?.VideoQuality !== current?.VideoQuality
              ) {
                setOpenFirmwareFormat(true);
              } else {
                handleUpdate();
              }
            }}
          >
            {t("Save")}
          </Button>
        </div>
        {/* 로테이션화면 */}
        <div
          className={clsx(classes.listPane, {
            [classes.rotationHide]: menu !== 0,
          })}
        >
          <CameraRotationPanel
            onClose={() => setMenu(undefined)}
            currentTab1={current}
            onSettingChange={(
              key: keyof TabNameInfo1,
              val: string | TrueFalsetype
            ) => {
              setCurrent((c) => ({ ...c, [key]: val } as TabNameInfo1));
            }}
          />
        </div>
        {/* 오버레이 화면 */}
        <div
          className={clsx(classes.listPane, {
            [classes.overlayHide]: menu !== 1,
          })}
        >
          <VideoOverlayPanel
            onClose={() => setMenu(undefined)}
            currentTab3={currentTab3Obj}
            currentTab1={current}
            onSettingChange={(
              key: keyof TabNameInfo1,
              val: string | TrueFalsetype
            ) => {
              setCurrent((c) => ({ ...c, [key]: val } as TabNameInfo1));
            }}
            onSettingTab3Change={(value: string) => {
              setCurrentTab3Obj((c) => {
                return (
                  c && {
                    ...c,
                    userString: value,
                  }
                );
              });
            }}
          />
        </div>
      </div>

      {/* save Button 누르면 나오는 경고창 모달*/}
      {openFirmwareFormat && (
        // mantis - 11443, 펌웨어 포맷 경고 모달 디자인 변경 (Leehj)
        <FirmwareFormatModalUXUI
          loading={loading}
          open={openFirmwareFormat}
          onClose={() => setOpenFirmwareFormat(false)}
          onClickNegative={() => setOpenFirmwareFormat(false)}
          onClickPositive={() => {
            handleUpdate();
            setOpenFirmwareFormat(false);
          }}
        />
      )}
      {openDiscardModal && (
        <DiscardChangeUXUIModal
          open={openDiscardModal}
          onClose={() => {
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          // mantis - 11445, 디자인 변경되면서 버튼 기능도 변경 (Leehj)
          onClickNegative={() => {
            if (targetLocation) {
              history.push(targetLocation.pathname);
            } else {
              props.onClose();
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
          onClickPositive={() => {
            handleUpdate();
            if (targetLocation) {
              history.push(targetLocation.pathname);
            } else {
              props.onClose();
            }
            setTargetLocation(undefined);
            setOpenDiscardModal(false);
          }}
        />
      )}
      <DashcamRestartingModal
        open={openRestartingdModal}
        onClose={() => setOpenRestartingModal(false)}
        onClickNegative={() => setOpenRestartingModal(false)}
        onClickPositive={() => {
          handleUpdate();
          setOpenRestartingModal(false);
        }}
      />
      <FirmwareSettingModal
        open={openFirmwareModal}
        onClose={() => setOpenFirmwareModal(false)}
        onClickNegative={() => setOpenFirmwareModal(false)}
        onClickPositive={() => {
          handleUpdate();
          setOpenFirmwareModal(false);
        }}
      />
    </div>
  );
};
