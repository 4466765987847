import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { ConnectToCloud } from "@thingsw/pitta-design-system";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AddCameraHeader } from "../components/AddCameraHeader";
import { MinimalFooter } from "../components/MinimalFooter";
import { MiniAddCameraHeader } from "../components/MiniAddCameraHeader";
import { useSelector } from "react-redux";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import {
  LightColors,
  Webviewer,
  SIMCARD_URI,
  CloudConnectionSettings,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    marginTop: 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 2),
    marginLeft: "auto",
    marginRight: "auto",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 512,
      marginTop: 153,
      marginBottom: 130,
      padding: "0 0 0 6px",
    },
  },
  appDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 2,
      marginTop: 21,
    },
  },
}));

export const ConnectToCloudScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <AddCameraHeader />
      {mobile && <MiniAddCameraHeader />}
      <ConnectToCloud
        bodyClassName={classes.body}
        heading={t("How to connect_")}
        firstTitle={t("Download BlackVue application")}
        firstContent={t("*If you are_", {
          a: `<a
      href=${`${SIMCARD_URI}?email=${email}&user_token=${loginInfo?.user_token}&token_type=web`}
      target="_blank"
      rel="noreferrer"
      style=color:${LightColors.primary["7"]}; > BlackVue SIM</a>`,
          b: "and add SIM card instead",
        })}
        secondTitle={t("Place your phone_", {
          a: `<a
      href=${CloudConnectionSettings}
      target="_blank"
      rel="noreferrer"
      style=color:${LightColors.primary["7"]}; > changing Cloud connection settings</a>`,
        })}
        LButton={t("Back to camera_")}
        LOnClick={() => {
          history.push("/cameras");
        }}
        RButton={t("Back")}
        ROnClick={() => {
          history.goBack();
        }}
        mobile={mobile}
        appCSS={classes.appDiv}
      />
      {mobile && (
        <div style={{ paddingTop: 27 }}>
          <MinimalFooter />
        </div>
      )}
    </div>
  );
};
