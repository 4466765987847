import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Modal,
  Button,
  Typography,
} from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import React, { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  LightColors,
  Webviewer,
} from "@thingsw/pitta-modules";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    margin: 15,
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#1E1E23" : "#FFFFFF",
    borderColor: (props: any) => props.darkMode ? "#323236" : "#D4D4D5",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      position: "absolute",
      right: 0,
      margin: 58,
      width: 343,
    },
  },
  modalTitle: {
    padding: "30px 23px 12px 23px",
    minHeight: 22,
  },
  modalContent: {
    padding: 0,
  },
  modalCloseIcon: {
    top: 24,
    right: 24,
  },
  divider: {
    height: 2,
    margin: "0 17px 0 11px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0 13px 0 11px",
    },
  },

  modalHeadingText: {
    fontSize: "16px !important",
    fontWeight: 500,
    marginLeft: 4,
    marginTop: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  headerBtn: {
    top: 30,
    right: 25,
  },
  btnContainer: {
    paddingTop: 24,
    justifyContent: "center",
    [theme.breakpoints.down(375)]: {
      padding: "24px 23px",
    },
  },
  LButton: {
    minWidth: 135,
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#323236" : "#FFFFFF",
    borderColor: (props: any) => props.darkMode ? "#636367" : "#D4D4D5",
  },
  RButton: {
    minWidth: 135,
    "&.Mui-disabled": {
      backgroundColor: (props: any) => props.darkMode ? "rgba(208, 235, 255, 0.3)" : "rgba(0, 149, 224, 0.35)",
    },
  },

  contDiv: {
    display: "flex", 
    justifyContent: "center",
  },
  btnWrap: {
    display: "flex",
    flexDirection: "column", 
    width: 130,
    margin: "0 8px",
  },
  arrBtn: {
    padding: 0,
    minHeight: 30,
    margin: "4px 16px",
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#323236" : "#FFFFFF",
    "&.MuiButton-root:hover": {
      backgroundColor: (props: any) => props.darkMode ? "#323236" : "transparent",
    },
  },
  input: {    
    "& .MuiInputBase-root": {
      padding: "10px 26px",
      display: "flex",
      color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
      backgroundColor: (props: any) => props.darkMode ? "#121216 " : "#FFFFFF",
    },
    "& .unit": {
      color: LightColors.primary["4"],
      fontSize: "1.125rem",
      flex: 1,
      paddingLeft: 6,
    },
    "& input": {      
      height: 36,
      textAlign: "end",
      fontSize: "1.125rem",
      flex: 2,
    },
  },
}));

export interface NotificationSetTimeModalProps {  
  open: boolean;
  onClose: () => void;
  app?: boolean;
  fatigueTime?: number;
  setFatigueTime: (min: number) => void;
  type: "push" | "email";
  darkMode?: boolean;
}

export const NotificationSetTimeModal = ({
  open,
  onClose,
  app,
  fatigueTime,
  setFatigueTime,
  type,
  darkMode,
}: NotificationSetTimeModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ darkMode });

  const [hour, setHour] = useState<number | undefined>();
  const [minute, setMinute] = useState<number | undefined>();
  const hourRef = useRef<HTMLInputElement>(null);
  const minRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fatigueTime) {
      setHour(Math.floor(fatigueTime /60));
      setMinute(fatigueTime % 60);
    }
  }, [open, fatigueTime]);

  const inputMinute = useMemo(() => {
    if (minute === undefined) return "";
    console.log("minute", minute);
    if (minute < 10) {
      return `0${minute}`;
    } else {
      return String(minute).replace(/^\0(.*)/, '$1');
    }    
  }, [minute]);

  const inputHour = useMemo(() => {
    if (hour === undefined) return "";
    if (hour < 10) {
      return `0${hour}`;
    } else {
      return String(hour).replace(/^\0(.*)/, '$1');
    }
  }, [hour]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      heading={t("Set Time")}      
      className={classes.modal}
      titleClassName={classes.modalTitle}
      contentClassName={classes.modalContent}
      closeStyle={classes.modalCloseIcon}
      headingTextClassName={classes.modalHeadingText}
      RButton={t("Apply")}
      LButton={t("Cancel")}
      RButtonDisabled={hour === undefined || minute === undefined || (hour * 60) + minute === 0 || fatigueTime === (hour * 60) + minute}
      onClickPositive={() => {
        if (hour !== undefined && minute !== undefined && (hour * 60) + minute > 0) {          
          setFatigueTime((hour * 60) + minute);
          onClose();
        }
      }}
      onClickNegative={onClose}
      actionClassName={classes.btnContainer}
      RButtonClassName={classes.RButton}
      LButtonClassName={classes.LButton}
      content={
        <>
          {!app && (
            <div style={{ margin: "0 24px 16px" }}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["3"]}
              >
                {type === "email" ? t("The time is synchronized_Push") : t("The time is synchronized_Email")}
              </Typography>
            </div>
          )}
          <div className={classes.contDiv}>
            <div className={classes.btnWrap}>
              <Button
                className={classes.arrBtn}
                variant="text"
                startIcon={<KeyboardArrowUp />}
                onClick={() => {
                  if (!hour) setHour(1)
                  if (hour  && hour < 23) setHour(hour + 1)
                }}
              />
              <Input
                ref={hourRef}
                className={classes.input}
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                value={inputHour}                
                endIcon={<span className="unit">h</span>}
                onChange={(e) => {
                  e.preventDefault();
                  if (typeof Number(e.target.value) === "number" && Number(e.target.value) >= 0 && Number(e.target.value) <= 23) setHour(Number(e.target.value))
                }}
                onClick={() => {
                  setHour(undefined);
                  hourRef?.current?.focus();
                }}
              />
              <Button
                className={classes.arrBtn}
                variant="text"
                startIcon={<KeyboardArrowDown />}
                onClick={() => {
                  if (!hour) setHour(0)
                  if (hour && hour > 0) setHour(hour - 1)
                }}
              />
            </div>
            <div className={classes.btnWrap}>
              <Button
                className={classes.arrBtn}
                variant="text"
                startIcon={<KeyboardArrowUp />}
                onClick={() => {
                  if (!minute) setMinute(1)
                  if (minute  && minute < 59) setMinute(minute + 1)
                }}
              />
              <Input
                ref={minRef}
                className={classes.input}
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                value={inputMinute}
                endIcon={<span className="unit">m</span>}
                onChange={(e) => {
                  e.preventDefault();
                  if (Number(e.target.value) >= 0 && Number(e.target.value) <= 59) setMinute(Number(e.target.value))
                }}
                onClick={(e) => {
                  setMinute(undefined);
                  minRef?.current?.focus();
                }}
              />
              <Button
                className={classes.arrBtn}
                variant="text"
                startIcon={<KeyboardArrowDown />}
                onClick={() => {
                  if (!minute) setMinute(0)
                  if (minute  && minute > 0) setMinute(minute - 1)
                }}
              />
            </div>
          </div>
        </>
      }
    />
  );
};
