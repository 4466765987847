import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BatteryChangeSettingResult,
  BatteryFailPart,
  BatteryHistoryItem,
  BatteryManualCommand,
  BatteryManualStatus,
  BatteryModel,
  BatterySettings,
  IBatteryInfo,
  IBatteryState,
  ICameraInfo,
} from "@thingsw/pitta-modules";
import { parseBatteryInfo } from "../../utils/parseBatteryInfo";

// 140초 경과 후 연결 실패로 간주
// 1회 요청에 5초 소요, 27회 실패 시 140초 소요로 계산
// const batteryConnectFailCount = 27;

const initBatteryInfo = {
  loading: false,
  value: "",
  date: "",
  state: "",
  manualStatus: BatteryManualStatus.NONE,
  model: BatteryModel.B130X,
  percent: 0,
  dischargeTime: 0,
  chargeTime: 0,
  temperature: 0,
  batteryVoltage: 0,
  inputVoltage: 0,
  outputVoltage: 0,
  accVoltage: 0,
  chargingCurrent: 0,
  dischargeCurrent: 0,
  isFullCharge: false,
  isLessThanOnePercent: false,
  isCharging: false,
  remainingTime: "-- : --",
  isOutputPowerCutOff: false,
  isInputPowerCutOff: false,
  isLowCapacity: false,
  isLowTemperature: false,
  isHighTemperature: false,
  chargingTime: "-- : --",
  isUSBConnected: false,
  isConnectFail: false,
  connectFailCount: 0,
  isPowerOff: false,
  ssid: "",
  isHighVoltage: false,
  isLowVoltage: false,
  chargeSystem: "",
  isLessFiveBatteryCapacity: false,
  isNotStarted: false,
};

let initialState: IBatteryState = {
  batteryDayHistory: {
    loading: false,
    items: undefined,
  },
  batteryWeekHistory: {
    loading: false,
    items: undefined,
  },
  batteryInfo: initBatteryInfo,
  batterySettings: {
    beep: "off",
    capacity: 0,
    spareBatteryCount: 0,
    loading: false,
    changeSettingResult: BatteryChangeSettingResult.NONE,
  },
};

const slice = createSlice({
  name: "battery",
  initialState,
  reducers: {
    loadBatteryHistory: (
      state,
      action: PayloadAction<{
        camera: ICameraInfo;
        period: 1 | 7;
      }>
    ) => {
      if (action.payload.period === 1) {
        state.batteryDayHistory.loading = true;
      }

      if (action.payload.period === 7) {
        state.batteryWeekHistory.loading = true;
      }
    },
    loadBatterySetting: (
      state,
      action: PayloadAction<{ camera: ICameraInfo }>
    ) => {
      state.batterySettings.loading = true;
      state.batterySettings.changeSettingResult =
        BatteryChangeSettingResult.SUCCESS;
    },
    loadBatteryManual: (
      state,
      action: PayloadAction<{
        camera:
          | ICameraInfo
          | {
              psn: string;
              battery: {
                ssid: string;
              };
            };
        command: BatteryManualCommand;
      }>
    ) => {
      state.batteryInfo.loading = true;
    },
    changeBatterySetting: (
      state,
      action: PayloadAction<{
        camera: ICameraInfo;
        setting: Partial<BatterySettings>;
      }>
    ) => {
      state.batterySettings.loading = true;
    },
    setBatteryInfo: (state, action: PayloadAction<IBatteryInfo>) => {
      state.batteryInfo = action.payload;
    },
    setSettingInfo: (state, action: PayloadAction<BatterySettings>) => {
      state.batterySettings = action.payload;
    },
    upateCurrentCam: (
      state,
      action: PayloadAction<ICameraInfo | undefined>
    ) => {
      state.currentCam = action.payload;
    },
    resetBatteryInfo: (state) => {
      state.batteryInfo = {
        ...initBatteryInfo,
        date: state.batteryInfo.date,
        percent: state.batteryInfo.percent,
        ssid: state.batteryInfo.ssid,
      };
    },
    resetBatteryChangeSettingResult: (state) => {
      state.batterySettings.changeSettingResult =
        BatteryChangeSettingResult.NONE;
    },
    resetBatteryHistory: (state) => {
      state.batteryDayHistory.items = undefined;
      state.batteryWeekHistory.items = undefined;
    },
    updateBatteryInfo: (
      state,
      action: PayloadAction<{
        percent: number;
        temperature: number;
      }>
    ) => {
      state.batteryInfo.percent = action.payload.percent;
      state.batteryInfo.temperature = action.payload.temperature;
    },
    successLoadBatteryHistory: (
      state,
      action: PayloadAction<{
        items: BatteryHistoryItem[];
        period: 1 | 7;
      }>
    ) => {
      if (action.payload.period === 1) {
        state.batteryDayHistory.loading = false;
        state.batteryDayHistory.items = action.payload.items;
      }

      if (action.payload.period === 7) {
        state.batteryWeekHistory.loading = false;
        state.batteryWeekHistory.items = action.payload.items;
      }
    },
    successLoadBatterySetting: (
      state,
      action: PayloadAction<BatterySettings>
    ) => {
      state.batterySettings.loading = false;
      state.batterySettings = action.payload;
    },
    successloadBatteryManual: (
      state,
      action: PayloadAction<
        | {
            status?: BatteryManualStatus;
            battery?: {
              psn: string;
              version: string;
              ssid: string;
              value: string;
              model: BatteryModel;
              date: string;
            };
          }
        | undefined
      >
    ) => {
      state.batteryInfo.loading = false;

      if (!action.payload) {
        state.batteryInfo.manualStatus = BatteryManualStatus.NONE;
      } else {
        state.batteryInfo.manualStatus =
          action.payload.status || BatteryManualStatus.NONE;

        state.batteryInfo.isConnectFail =
          action.payload.status === BatteryManualStatus.FAIL;

        if (action.payload.status === BatteryManualStatus.DOING) {
          state.batteryInfo.manualStatus = BatteryManualStatus.DOING;
        }

        // // 수동 연결이 성공 응답이 아닌 경우 연결 실패 카운트를 증가시킨다.
        // if (action.payload.status !== BatteryManualStatus.SUCCESS) {
        //   state.batteryInfo.connectFailCount += 1;

        //   if (state.batteryInfo.connectFailCount > batteryConnectFailCount) {
        //     state.batteryInfo.isConnectFail = true;
        //     state.batteryInfo.manualStatus = BatteryManualStatus.FAIL;
        //   }
        // }
        // // 수동 연결이 성공할 경우 연결 실패 카운트를 초기화한다.
        // if (action.payload.status === BatteryManualStatus.SUCCESS) {
        //   state.batteryInfo.connectFailCount = 0;
        //   state.batteryInfo.isConnectFail = false;
        // }

        if (
          action.payload.battery &&
          action.payload.status === BatteryManualStatus.SUCCESS
        ) {
          state.batteryInfo.value = action.payload.battery.value;
          state.batteryInfo.model = action.payload.battery.model;
          state.batteryInfo.ssid = action.payload.battery.ssid;
          state.batteryInfo.date = action.payload.battery.date;

          const parsedBatteryInfo = parseBatteryInfo(action.payload.battery);

          state.batteryInfo.percent = parsedBatteryInfo.percent;
          state.batteryInfo.dischargeTime = parsedBatteryInfo.dischargeTime;
          state.batteryInfo.chargeTime = parsedBatteryInfo.chargeTime;
          state.batteryInfo.temperature = parsedBatteryInfo.temperature;
          state.batteryInfo.batteryVoltage = parsedBatteryInfo.batteryVoltage;
          state.batteryInfo.inputVoltage = parsedBatteryInfo.inputVoltage;
          state.batteryInfo.outputVoltage = parsedBatteryInfo.outputVoltage;
          state.batteryInfo.accVoltage = parsedBatteryInfo.accVoltage;
          state.batteryInfo.chargingCurrent = parsedBatteryInfo.chargingCurrent;
          state.batteryInfo.dischargeCurrent =
            parsedBatteryInfo.dischargeCurrent;
          state.batteryInfo.isFullCharge = parsedBatteryInfo.isFullCharge;
          state.batteryInfo.isLessThanOnePercent =
            parsedBatteryInfo.isLessThanOnePercent;
          state.batteryInfo.isCharging = parsedBatteryInfo.isCharging;
          state.batteryInfo.remainingTime = parsedBatteryInfo.remainingTime;
          state.batteryInfo.isOutputPowerCutOff =
            parsedBatteryInfo.isOutputPowerCutOff;
          state.batteryInfo.isInputPowerCutOff =
            parsedBatteryInfo.isInputPowerCutOff;
          state.batteryInfo.isLowCapacity = parsedBatteryInfo.isLowCapacity;
          state.batteryInfo.isLowTemperature =
            parsedBatteryInfo.isLowTemperature;
          state.batteryInfo.isHighTemperature =
            parsedBatteryInfo.isHighTemperature;
          state.batteryInfo.chargingTime = parsedBatteryInfo.chargingTime;
          state.batteryInfo.isUSBConnected = parsedBatteryInfo.isUSBConnected;
          state.batteryInfo.isHighVoltage = parsedBatteryInfo.isHighVoltage;
          state.batteryInfo.isLowVoltage = parsedBatteryInfo.isLowVoltage;
          state.batteryInfo.chargeSystem = parsedBatteryInfo.chargeSystem;
          state.batterySettings.beep = parsedBatteryInfo.isOnBeep;
          state.batterySettings.spareBatteryCount =
            parsedBatteryInfo.numberOfBattery;
          state.batterySettings.capacity = parsedBatteryInfo.capacity;
          state.batteryInfo.isLessFiveBatteryCapacity =
            parsedBatteryInfo.isLessFiveBatteryCapacity;
        }
      }
    },
    batteryManualEnd: (
      state,
      action: PayloadAction<
        | ICameraInfo
        | {
            psn: string;
            battery: {
              ssid: string;
            };
          }
      >
    ) => {
      state.batteryInfo.loading = true;
    },
    BatteryManualFail: (state) => {
      state.batteryInfo.isPowerOff = true;
    },
    BatteryPowerOff: (state) => {
      if (state.batteryInfo.manualStatus !== BatteryManualStatus.SUCCESS) {
        // 배터리 연결 시도 중에 타임아웃이 발생하면 Battery Connect Fail로 간주한다.
        state.batteryInfo.manualStatus = BatteryManualStatus.FAIL;
        state.batteryInfo.isConnectFail = true;
      } else {
        // 배터리 연결이 완료된 상태에서 타임아웃이 발생하면 전원 Off로 간주한다.
        state.batteryInfo.isPowerOff = true;
      }
    },
    batteryNotStarted: (state) => {
      state.batteryInfo.isNotStarted = true;
    },
    successBatteryManualStart: (
      state,
      action: PayloadAction<BatteryManualStatus | undefined>
    ) => {
      state.batteryInfo.loading = false;

      if (action.payload === BatteryManualStatus.IN_USE) {
        state.batteryInfo.manualStatus = BatteryManualStatus.IN_USE;
      } else {
        state.batteryInfo.manualStatus = BatteryManualStatus.START;
      }
    },
    successBatteryManualEnd: (state) => {
      state.batteryInfo.loading = false;
      state.batteryInfo.manualStatus = BatteryManualStatus.NONE;
    },
    successChangeBatterySetting: (
      state,
      action: PayloadAction<Partial<BatterySettings>>
    ) => {
      state.batterySettings.loading = false;
      state.batterySettings.changeSettingResult =
        BatteryChangeSettingResult.SUCCESS;
      state.batterySettings = { ...state.batterySettings, ...action.payload };
    },
    failLoad: (state, action: PayloadAction<BatteryFailPart>) => {
      switch (action.payload) {
        case BatteryFailPart.DAYHISTORY:
          state.batteryDayHistory.loading = false;
          break;

        case BatteryFailPart.WEEKHISTORY:
          state.batteryWeekHistory.loading = false;
          break;

        case BatteryFailPart.SETTINGS:
          state.batterySettings.loading = false;
          state.batterySettings.changeSettingResult =
            BatteryChangeSettingResult.FAIL;
          break;

        case BatteryFailPart.INFO:
          state.batteryInfo.loading = false;
          state.batteryInfo.isConnectFail = true;
          state.batteryInfo.manualStatus = BatteryManualStatus.FAIL;

          break;
        default:
          break;
      }
    },
  },
});

export const {
  loadBatteryHistory,
  loadBatterySetting,
  loadBatteryManual,
  changeBatterySetting,
  successLoadBatteryHistory,
  successLoadBatterySetting,
  successloadBatteryManual,
  successBatteryManualEnd,
  batteryManualEnd,
  failLoad,
  resetBatteryInfo,
  resetBatteryChangeSettingResult,
  resetBatteryHistory,
  updateBatteryInfo,
  successChangeBatterySetting,
  successBatteryManualStart,
  BatteryManualFail,
  BatteryPowerOff,
  setBatteryInfo,
  upateCurrentCam,
  setSettingInfo,
  batteryNotStarted,
} = slice.actions;

export const BATTERY = slice.name;
export default slice.reducer;
