import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  Fonts,
  IconButton,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import SimpleBarReact from "simplebar-react";
import _ from "lodash";

import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { CAMERA, loadFirmwareConfig } from "../../features/Camera/slice";
import { PAYMENT } from "../../features/Payment/slice";
import { getPlanFromServiceID } from "../../utils/Service";
import {
  LightColors,
  Webviewer,
  MSG_CODE_TYPE,
  MsgType,
  DMC100_MODELS,
  DMC200_MODELS,
  BOXPRO_MODELS,
  LOW_VOLTAGE_MODELS,
  ICameraInfo,
} from "@thingsw/pitta-modules";
import useAbleBatteryCloud from "../../hooks/useAbleBatteryCloud";
import ableExtendBoxPro from "../../utils/ableExtendBoxPro";

const EXCLUDE_EVENT: string[] = [
  "ALARM_PARK_OUT",
  "DEVICE_DISCONNECT",
  "ALARM_SETTING_SAVED",
  "ALARM_CALLING",
];

export const FormControlLabel = withStyles((theme: Theme) => ({
  label: {
    ...Fonts.Default.Body,
    color: LightColors.primary["1"],
    marginLeft: 2,
    marginRight: 2,
  },
  root: {
    marginLeft: -6,
    marginRight: -6,
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
}))(MuiFormControlLabel);

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    // width: 343,
    margin: 15,
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#1E1E23" : "#FFFFFF",
    borderColor: (props: any) => props.darkMode ? "#1E1E23" : "#D4D4D5",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      position: "absolute",
      right: 0,
      margin: 58,
      width: 343,
    },
  },
  modalTitle: {
    padding: "30px 23px 24px 23px",
    minHeight: 22,
    "& button": {
      color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    },
  },
  modalContent: {
    // mantis - 10562, ios 스크롤 두개생기는 이슈 수정(Leehj)
    // overflow: "hidden",
    padding: 0,
  },
  modalCloseIcon: {
    top: 24,
    right: 24,
  },
  divider: {
    height: 2,
    margin: "0 17px 0 11px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0 13px 0 11px",
    },
  },

  option: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 12px",
    marginBottom: 8,
  },
  selectAllOption: {
    marginBottom: 16,
  },
  optionCheckedDiv: {
    backgroundColor: LightColors.primary["10"],
    borderRadius: 30,
  },
  icon: {
    fontSize: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 1,
    },
  },
  subTitle: {
    display: "flex",
    padding: "11px 0 8px",
    // justifyContent: "center",
  },
  subTitleDivider: {
    height: 2,
    margin: "0 0 8px",
    backgroundColor: LightColors.primary["9"],
  },
  subTitleGrayDivider: {
    height: 1,
    margin: "0 17px 8px 11px",
    backgroundColor: LightColors.primary["6"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0 15px 8px 9px",
    },
  },
  checkboxRoot: {
    color: LightColors.primary["4"],
    padding: 0,
    "&:hover": {
      color: LightColors.primary["7"],
      backgroundColor: "transparent",
    },
  },
  checkboxChecked: {
    color: `${LightColors.primary["1"]} !important`,
    "&:hover": {
      color: `${LightColors.primary["7"]} !important`,
      backgroundColor: "transparent !important",
    },
  },

  scrollbarStyle: {
    height: "100%",
    flex: 1,
    padding: "0 16px",
    // mantis - 10562, ios 스크롤 두개생기는 이슈 수정(Leehj)
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflowX: "hidden",
      maxHeight: "calc(100vh - 300px)",
    },
  },

  modalHeadingText: {
    fontSize: "16px !important",
    fontWeight: 500,
    marginLeft: 4,
    marginTop: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  headerBtn: {
    top: 30,
    right: 25,
  },
  btnContainer: {
    paddingTop: 24,
    // mantis - 10562, 버튼 가운데 정렬 (Leehj)
    justifyContent: "center",
    // mantis - 11782, 가로사이즈 375이하인 경우 버튼 ui수정 (Leehj)
    [theme.breakpoints.down(375)]: {
      padding: "24px 16px",
    },
    "& button": {
      // mantis - 11782, 가로사이즈 360이하인 경우 버튼 ui수정 (Leehj)
      [theme.breakpoints.down(360)]: {
        minWidth: 135,
      },
      [theme.breakpoints.down(342)]: {
        minWidth: 124,
      },
    },
  },
  LButton: {
    color: (props: any) => props.darkMode ? "#FFFFFF" : "#1E1E23",
    backgroundColor: (props: any) => props.darkMode ? "#323236" : "#FFFFFF",
    borderColor: (props: any) => props.darkMode ? "#636367" : "#D4D4D5",
  },
  RButton: {
    "&.Mui-disabled": {
      backgroundColor: (props: any) => props.darkMode ? "rgba(208, 235, 255, 0.3)" : "rgba(0, 149, 224, 0.35)",
    },
  },
}));

export interface NotificationEventFilterModalProps {
  open: boolean;
  onClose: () => void;
  onFilterMsgCodes: (code: string[]) => void;
  filterMsgCodes: string[];
  currentCam?: ICameraInfo;
  darkMode?: boolean;
}

export const NotificationEventFilterModal = (props: NotificationEventFilterModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { open, onClose, onFilterMsgCodes, filterMsgCodes, currentCam } = props;
  const isAbleBatteryCloud = useAbleBatteryCloud(currentCam);

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    MSG_CODE_TYPE
  );

  const exceptionFilterMsgCodes = useMemo(
    () => _.filter(selectedFilters, (f) => !_.includes(EXCLUDE_EVENT, f)),
    [selectedFilters]
  );

  const exceptionMSG_CODE_TYPE = useMemo(
    () => _.filter(MSG_CODE_TYPE, (f) => !_.includes(EXCLUDE_EVENT, f)),
    []
  );

  const { camera, firmwareConfig } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const isFree = useMemo(() => {
    if (subscriptionInfo) {
      return (
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Free plan"
      );
    }
    return false;
  }, [subscriptionInfo]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareConfig(camera));
    }
  }, [camera, dispatch]);

  useEffect(() => {
    if (open) {
      setSelectedFilters(filterMsgCodes);
    }
  }, [filterMsgCodes, open]);

  const handleEventCheck = useCallback(
    (msgNames: string[]) => (e: any, checked: boolean) => {
      if (checked) {
        setSelectedFilters(
          _.chain(selectedFilters).concat(msgNames).uniq().value()
        );
      } else {
        setSelectedFilters(
          _.filter(selectedFilters, (code) => !_.includes(msgNames, code))
        );
      }
    },
    [selectedFilters]
  );

  const renderSimpleOptionBox = useCallback(
    (
      label: string,
      key: MsgType,
      key2?: MsgType,
      options?: { className?: string; checkFn?: () => boolean }
    ) => {
      const check = key2
        ? _.includes(selectedFilters, key) || _.includes(selectedFilters, key2)
        : _.includes(selectedFilters, key);

      return (
        <div
          className={clsx(classes.option, {
            // [classes.optionCheckedDiv]: check,
          })}
        >
          <Typography category="Default" variant={"Body"}>
            {t(label)}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  root: classes.checkboxRoot,
                }}
                checked={check}
                color="primary"
                onChange={handleEventCheck(key2 ? [key, key2] : [key])}
                disableRipple
              />
            }
            label=""
          />
        </div>
      );
    },
    [classes.checkboxRoot, classes.option, handleEventCheck, selectedFilters, t]
  );

  return (
    <Modal
      open={open}
      // x누르면 저장될 수 있도록
      // onClose={onClose}
      heading={t("Filter")}
      headingIcon={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
      headerBtn={
        <div style={{ margin: "0 2px" }}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  root: classes.checkboxRoot,
                }}
                checked={exceptionFilterMsgCodes.length > 0}
                indeterminate={
                  exceptionFilterMsgCodes.length > 0 &&
                  exceptionFilterMsgCodes.length < exceptionMSG_CODE_TYPE.length
                }
                color="primary"
                onChange={(_e, checked) => {
                  if (!checked) {
                    setSelectedFilters([]);
                  } else {
                    setSelectedFilters(MSG_CODE_TYPE);
                  }
                }}
                disableRipple
              />
            }
            label=""
          />
        </div>
      }
      headerBtnClassName={classes.headerBtn}
      // close={!mobile}
      // X버튼이 눌러지지 않음(UI도 X가 두개로보임) 이슈 수정 (Leehj) 23.01.10
      // closeLeft={mobile}
      className={classes.modal}
      titleClassName={classes.modalTitle}
      contentClassName={classes.modalContent}
      closeStyle={classes.modalCloseIcon}
      headingTextClassName={classes.modalHeadingText}
      RButton={t("OK")}
      LButton={t("Reset")}
      onClickPositive={() => {
        onFilterMsgCodes(selectedFilters);
      }}
      onClickNegative={() => {
        setSelectedFilters(MSG_CODE_TYPE);
      }}
      actionClassName={classes.btnContainer}
      RButtonClassName={classes.RButton}
      LButtonClassName={classes.LButton}
      content={
        <>
          <SimpleBarReact className={classes.scrollbarStyle}>
            {renderSimpleOptionBox("Manual recording", "ALARM_MANUAL")}

            <div className={classes.subTitle}>
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["7"]}
              >
                {t("Normal event recording")}
              </Typography>
            </div>
            <Divider className={classes.subTitleDivider} />

            {renderSimpleOptionBox("Driving Impact", "ALARM_EVENT")}
            {renderSimpleOptionBox("Overspeed", "ALARM_SPEED")}
            {renderSimpleOptionBox("Hard Braking", "ALARM_HARSHBRAKING")}
            {/* osd 키값 참고 */}
            {renderSimpleOptionBox("Hard Acceleration", "ALARM_ACCELERATION")}
            {renderSimpleOptionBox("Hard Cornering", "ALARM_SHARPTURN")}

            <div className={classes.subTitle}>
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["7"]}
              >
                {t("Parking event recording")}
              </Typography>
            </div>
            <Divider className={classes.subTitleDivider} />
            {/* osd 키값 참고 */}
            {renderSimpleOptionBox("Parking impact", "ALARM_PARK_EVENT")}
            {renderSimpleOptionBox("Motion Detection", "ALARM_PARK_MOTION")}
            {renderSimpleOptionBox(
              "Parking mode enter_",
              "ALARM_PARK_IN",
              "ALARM_PARK_OUT"
            )}

            {/* mantis-10584, Geofencing항목은 Fleet Plan사용자에게만 보이는 항목(Leehj) */}
            {!isFree && (
              <>
                <div className={classes.subTitle}>
                  <Typography
                    category="Default"
                    variant="SmallBold"
                    htmlColor={LightColors.primary["7"]}
                  >
                    {t("Geofencing")}
                  </Typography>
                </div>
                <Divider className={classes.subTitleDivider} />

                {renderSimpleOptionBox("Entering", "ALARM_GEOFENCE_ENTER")}
                {renderSimpleOptionBox("Exiting", "ALARM_GEOFENCE_EXIT")}
                {renderSimpleOptionBox("Passing", "ALARM_GEOFENCE_PASS")}
                {_.includes(BOXPRO_MODELS, camera?.model) &&
                  renderSimpleOptionBox("Speed Alert", "ALARM_GEOFENCE_SPEED")}
              </>
            )}

            {/* mantis- 10584 시그마모델에서는 DR770X모델만 DMS항목 보이도록 수정 /
            DR770X에만 표시됨 (2023.01.27 sigma DMS적용모델 정책 기준) (Leehj) */}
            {/* 코드 단순화 수정 (2024.04.30, hongcs) */}
            {(_.includes([...DMC100_MODELS, ...DMC200_MODELS, ...BOXPRO_MODELS], camera?.model) &&
                firmwareConfig?.["DMS"]) && (
              <>
                <div className={classes.subTitle}>
                  <Typography
                    category="Default"
                    variant="SmallBold"
                    htmlColor={LightColors.primary["7"]}
                  >
                    {t("Driver monitoring system(DMS)")}
                  </Typography>
                </div>
                <Divider className={classes.subTitleDivider} />
                
                {renderSimpleOptionBox("Drowsiness", "ALARM_DROWSY")}
                {renderSimpleOptionBox(
                  "Second Drowsiness",
                  "ALARM_DROWSY_SECOND"
                )}
                {renderSimpleOptionBox("Distracted", "ALARM_DISTRACTED")}
                {(_.includes(BOXPRO_MODELS, camera?.model)) && 
                  renderSimpleOptionBox(
                    "Seat Belt",
                    "ALARM_SEATBELT"
                )}
                {renderSimpleOptionBox("Detected", "ALARM_DETECTED")}
                {renderSimpleOptionBox("Undetected", "ALARM_UNDETECTED")}                
                {(!_.includes(BOXPRO_MODELS, camera?.model)) && 
                  renderSimpleOptionBox(
                  "Hand distraction",
                  "ALARM_CALLING",
                  "ALARM_SMOKING"
                )}
                {/* osd 키값 참고 */}
                {(!_.includes(BOXPRO_MODELS, camera?.model)) && renderSimpleOptionBox("Mask", "ALARM_MASK_OFF")}
              </>
            )}

            <div className={classes.subTitle}>
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["7"]}
              >
                {t("Others")}
              </Typography>
            </div>
            <Divider className={classes.subTitleDivider} />
            {/* mantis - 10258  Low voltage warning 항목 문구 수정 (Leehj) */}
            {/* mantis - 10584, LOW_VOLTAGE_MODELS만 해당 항목 출력되도록 출력 조건추가 (Leehj) */}
            {_.includes(LOW_VOLTAGE_MODELS, camera?.model) &&
              renderSimpleOptionBox("Low voltage warning", "ALARM_LOW_VOLTAGE")}
            {renderSimpleOptionBox(
              "Cloud Connectivity",
              "DEVICE_CONNECT",
              "DEVICE_DISCONNECT"
            )}
            {renderSimpleOptionBox("Cloud Storage", "ALARM_CLOUDSTORAGE")}

            {ableExtendBoxPro(camera?.model, camera?.fw_ver) &&
              renderSimpleOptionBox("Fatigue Risk Alert", "ALARM_FATIGUE")}

            {isAbleBatteryCloud &&
              currentCam?.battery?.ssid?.startsWith("130") && (
                <>
                  {renderSimpleOptionBox(
                    "Battery Capacity",
                    "ALARM_BATTERY_DISCHARGE"
                  )}
                </>
              )}
          </SimpleBarReact>
        </>
      }
    />
  );
};
