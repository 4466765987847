import { makeStyles, Theme } from "@material-ui/core";
import { Avatars, Modal, Typography } from "@thingsw/pitta-design-system";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "@material-ui/icons/Edit";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { openToast } from "../../features/Toast/slice";
import { detect } from "detect-browser";
import {
  ICameraInfo,
  jwtAxiosInst,
  LightColors,
  Webviewer,
} from "@thingsw/pitta-modules";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  edit: {
    background: "#FFFFFF",
    borderRadius: "50%",
    width: 28,
    height: 28,
    border: "2px solid #D4D4D5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    bottom: 0,
    cursor: "pointer",
  },
  input: {
    minWidth: 265,
  },
  rbutton: {
    minWidth: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 343,
    },
  },
  typo: {
    height: 41,
    display: "flex",
    alignItems: "center",
  },
  closeStyle: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: 9,
      left: 9,
    },
  },
  modalContentDiv: {
    padding: "11px 24px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "11px 24px 24px",
    },
  },
  modalBtnDiv: {
    "& > div": {
      marginLeft: "0px !important",
      display: "contents",
    },
    padding: "24px 24px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 24px 24px",
    },
  },
  modalDiv: {
    margin: 16,
  },
}));

interface EditProfileModalProps {
  open: boolean;
  loading?: boolean;
  camera?: ICameraInfo;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditProfileModal = ({
  open,
  camera,
  onClose,
  onSuccess,
}: EditProfileModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const { email } = useSelector((state: RootState) => state[USER]);

  const [profileImage, setProfileImage] = useState<string>();
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [devName, setDevName] = useState("");
  const [camNameLengthErr, setCamNameLengthErr] = useState(false);

  const browser = detect();

  // useEffect(() => {
  //   setDevName(camera?.dev_name ?? "");
  //   setProfileImage(camera?.profile_img);
  // }, [camera]);

  useEffect(() => {
    if (!open) {
      setProfileImage(undefined);
      setFile(undefined);
    } else {
      // mantis - 11103, 모달 오픈되면 카메라 이미지 넣어주도록 수정 (Leehj)
      setDevName(camera?.dev_name ?? "");
      setProfileImage(camera?.profile_img);
    }
  }, [camera, open]);

  const handleFileChange = useCallback(async (event) => {
    const fileObj = event.target.files[0];
    if (!fileObj) {
      return;
    }
    setFile(fileObj);

    const reader = new FileReader();
    reader.onload = (e) => {
      setProfileImage(e.target?.result as string);
    };
    reader.readAsDataURL(fileObj);

    // 👇️ reset file input
    event.target.value = null;
  }, []);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      // mantis - 11070, 5~32자 기준에 맞지 않는 경우 에러메세지 출력 (Leehj)
      if (devName.match(/\s/g)?.length === devName.length) {
        setCamNameLengthErr(true);
      } else if (devName.trim().length < 5 || devName.trim().length > 32) {
        setCamNameLengthErr(true);
      } else {
        const form = new FormData();
        form.append(
          "body",
          JSON.stringify({
            email,
            psn: camera?.psn,
            devName,
          })
        );
        if (file) {
          form.append("file", file);
        }
        const resp = await jwtAxiosInst.post("/Account/CameraProfile", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (resp.data.resultcode === "BC_ERR_OK") {
          //문구 확인 필요
          dispatch(
            openToast({ message: "Camera profile saved successfully." })
          );
          onSuccess();
          setCamNameLengthErr(false);
        } else {
          //문구 확인 필요
          dispatch(openToast({ message: "Failed to save camera profile." }));
        }
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [camera?.psn, devName, dispatch, email, file, onSuccess]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setCamNameLengthErr(false);
      }}
      closeLeft
      heading=""
      content={
        <div className={classes.content}>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            accept="image/jpeg,image/png"
            onChange={handleFileChange}
          />
          <div className={classes.typo}>
            <Typography
              category="Default"
              variant="H6"
              style={{ fontWeight: 700 }}
            >
              {t("Camera profile")}
            </Typography>
          </div>

          <div style={{ position: "relative", margin: "34px 0 40px" }}>
            <Avatars
              imgSrc={profileImage || "/images/profile-default.jpg"}
              style={{ width: 72, height: 72 }}
            />
            <div
              className={classes.edit}
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              <EditIcon htmlColor={LightColors.primary["4"]} />
            </div>
          </div>

          <div style={{ marginBottom: 8, maxWidth: 266 }}>
            <Input
              inputID="devName"
              className={classes.input}
              label={t("Camera name")}
              value={devName}
              // mantis - 11074, 한글 입력시 33자도 입력되는 이슈 수정(Leehj)
              onChange={(e) => {
                setDevName((e.target.value = e.target.value.slice(0, 32)));
                // mantis - 11105, ios 한글 Buffer 이슈수정 (Leehj)
                if (browser?.os === "iOS") {
                  document.getElementById("hiddenInput")?.focus();
                  document.getElementById("devName")?.focus();
                }
              }}
              // mantis - 10465 입력제한 20->32로 수정(leehj)
              // maxLength={32}
              error={camNameLengthErr}
            />
            {/* mantis - 11105, ios 한글 Buffer 이슈수정 (Leehj) */}
            {browser?.os === "iOS" && (
              <Input
                inputID="hiddenInput"
                style={{ position: "absolute", left: -9999, top: -9999 }}
              />
            )}

            <div
              style={{
                padding: "4px 13px",
                display: "flex",
                justifyContent: camNameLengthErr ? "flex-start" : "flex-end",
              }}
            >
              {camNameLengthErr && (
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.secondary["11"]}
                >
                  {t("Camera name must_2")}
                </Typography>
              )}
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={
                  camNameLengthErr
                    ? LightColors.secondary["11"]
                    : LightColors.primary["2"]
                }
              >
                {/* mantis - 10465 입력제한 20->32로 수정(leehj) */}
                {devName.length}/32
              </Typography>
            </div>
          </div>
        </div>
      }
      RButton={t("Save")}
      loading={loading}
      RButtonClassName={classes.rbutton}
      closeStyle={classes.closeStyle}
      contentClassName={classes.modalContentDiv}
      // mantis - 10959, 아이폰se에서 스크롤 생기는 이슈 수정 (Leehj)
      actionClassName={classes.modalBtnDiv}
      className={classes.modalDiv}
      btnCenter
      onClickPositive={handleSave}
    />
  );
};
