import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  open: boolean;
  message?: string;
  option?: any;
  notification?: boolean;
}

const initialState: IState = {
  open: false,
  message: undefined,
  notification: false,
};
const slice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    openToast: (
      state,
      {
        payload,
      }: PayloadAction<{
        message: string;
        option?: any;
        notification?: boolean;
      }>
    ) => {
      state.open = true;
      state.message = payload.message;
      state.option = payload.option;
      // mantis - 11079, app과 모바일에서 notification설정 변경시 토스트팝업 가운데로 출력되도록 수정 (Leehj)
      state.notification = payload.notification;
    },
    closeToast: (state) => {
      state.open = false;
      state.message = undefined;
      state.option = undefined;
      state.notification = false;
    },
  },
});

export const { openToast, closeToast } = slice.actions;
export const TOAST = slice.name;
export default slice.reducer;
