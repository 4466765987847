import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { Hidden } from "@material-ui/core";
import {
  Button,
  Container,
  IconButton,
  LogoFull,
  LogoOnly,
  Menu,
  Tab,
  Tabs,
  WebMenuItem,
} from "@thingsw/pitta-design-system";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppBar } from "./AppBar";
import { Toolbar } from "./Toolbar";
import { MaintenanceHandler } from "./MaintenanceHandler";
import { HELPCENTER } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  menu1:
    theme.direction === "rtl"
      ? {
          marginRight: 56,
        }
      : {
          marginLeft: 16,
          [theme.breakpoints.up("lg")]: {
            marginLeft: 56,
          },
        },
  menu2:
    theme.direction === "rtl"
      ? { marginRight: "auto" }
      : {
          marginLeft: "auto",
        },
  menuButton:
    theme.direction === "rtl"
      ? {
          marginLeft: theme.spacing(2),
        }
      : {
          marginRight: theme.spacing(2),
        },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    ...(theme.direction === "rtl" ? { marginRight: 8 } : { marginLeft: 8 }),
  },
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  grow: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  mobileMenu: {
    paddingRight: 0,
  },
}));

export interface MainHeaderProps {
  color: "default" | "transparent";
  value?: number;
  onClickMenu: () => void;
  pricing?: boolean;
}

export const MainHeader = React.forwardRef((props: MainHeaderProps, ref) => {
  const { color, value, pricing, onClickMenu } = props;

  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);

  const [rightValue, setRightValue] = useState<number>(pricing ? 0 : 2);
  const [open, setOpen] = useState(false);
  const [maintenancing, setMaintenancing] = useState(false);
  const [showMaintModal, setShowMaintModal] = useState(false);
  const [disableModal, setDisableModal] = useState(true);

  useEffect(() => {
    if (location.pathname === "/") {
      setDisableModal(false);
    }
  }, [location.pathname]);

  const handleToggle = () => {
    if (open) {
      setRightValue(0);
    } else {
      setRightValue(2);
    }
    setOpen(!open);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleMaintenance = useCallback(() => {
    setMaintenancing(true);
  }, []);

  const maintHandler = useMemo(() => {
    return (
      <MaintenanceHandler
        disableModal={disableModal}
        forceShow={showMaintModal}
        onCloseModal={() => {
          setShowMaintModal(false);
        }}
        onMaintenance={handleMaintenance}
      />
    );
  }, [disableModal, handleMaintenance, showMaintModal]);

  const reload = useCallback(() => {
    history.push("/");
    history.go(0);
  }, [history]);

  return (
    <AppBar color={color} ref={ref}>
      <Container>
        <Toolbar>
          {/* mobile */}
          <Hidden mdUp>
            <Link to="/" className={classes.vcenter}>
              <LogoOnly width={36} height={36} />
            </Link>
            <div className={classes.grow}>
              <IconButton onClick={onClickMenu} className={classes.mobileMenu}>
                <MenuIcon />
              </IconButton>
            </div>
          </Hidden>
          {/* PC */}
          <Hidden smDown>
            <Link to="/" className={classes.vcenter} onClick={reload}>
              <LogoFull width={166} height={29} />
            </Link>

            <Tabs value={value} className={classes.menu1}>
              <Tab style={{ padding: 0 }} />
              <Tab label={t("Cloud")} href="/#cloud" />
              <Tab label={t("Fleet")} href="/#fleet" />
            </Tabs>
            <Tabs
              value={rightValue}
              color={value === 0 ? "primary" : "transparent"}
              className={classes.menu2}
            >
              <Tab
                label={t("Pricing")}
                onClick={() => {
                  history.push("/pricing");
                  setOpen(false);
                  setRightValue(0);
                }}
              />
              <Tab
                ref={anchorRef}
                onClick={handleToggle}
                label={
                  <>
                    {t("Support")} <ExpandMoreIcon />
                  </>
                }
              />

              <Tab style={{ padding: 0 }} />
            </Tabs>

            <div className={classes.vcenter}>
              <div className={classes.btnContainer}>
                <Button
                  variant="contained"
                  color={maintenancing ? "default" : "primary"}
                  onClick={() => {
                    if (maintenancing) {
                      setShowMaintModal(true);
                    } else {
                      history.push("/login");
                    }
                  }}
                >
                  {t("Start Web viewer")}
                </Button>
              </div>
            </div>

            <Menu
              open={open}
              anchorEl={anchorRef.current}
              onClickAway={() => {
                setOpen(false);
              }}
            >
              <WebMenuItem
                onClick={() => {
                  history.push("/compatible-dashcams");
                  setOpen(false);
                  setRightValue(2);
                }}
              >
                {t("Compatible dashcams")}
              </WebMenuItem>
              <WebMenuItem
                onClick={() => {
                  history.push("/downloads");
                  setOpen(false);
                  setRightValue(2);
                }}
              >
                {t("Downloads")}
              </WebMenuItem>
              <WebMenuItem
                endIcon={<OpenInNewIcon fontSize="inherit" />}
                onClick={() => {
                  setOpen(false);
                  window.open(HELPCENTER, "_blank");
                }}
              >
                {t("Help center")}
              </WebMenuItem>
              <WebMenuItem
                endIcon={<OpenInNewIcon fontSize="inherit" />}
                onClick={() => {
                  setOpen(false);
                  window.open(
                    "https://cloudmanual.blackvue.com/docs/blackvueweb/",
                    "_blank"
                  );
                }}
              >
                {t("Tutorials")}
              </WebMenuItem>
            </Menu>
          </Hidden>
        </Toolbar>
      </Container>
      {maintHandler}
    </AppBar>
  );
});
