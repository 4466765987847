import { makeStyles, Theme } from "@material-ui/core";
import { Modal, Typography } from "@thingsw/pitta-design-system";

import { useTranslation } from "react-i18next";

import InfoIcon from "@material-ui/icons/Info";
import { LightColors } from "@thingsw/pitta-modules";
import React from "react";

interface NotConnectedCameraModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: LightColors.secondary["11"],
  },
  container: {
    backgroundColor: "#FCF0F1",
    borderRadius: 12,
    padding: "34px 16px",
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    margin: "10px 13.5px",
    border: `1px dashed rgba(0, 0, 0, 0.14)`,
  },
  typography: {
    display: "flex",
    flexDirection: "row",
  },
}));

export const NotConnectedCameraModal = ({
  open,
  onClose,
}: NotConnectedCameraModalProps) => {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onClose}
      close
      titleClassName={classes.title}
      headingIcon={
        <InfoIcon
          htmlColor={LightColors.secondary["11"]}
          style={{ marginInlineEnd: 3, marginInlineStart: 11 }}
        />
      }
      heading={t("Camera is not connected to the Cloud yet")}
      content={
        <div className={classes.container}>
          <div className={classes.typography}>
            <Typography category="Default" variant="Body">
              ·&nbsp;&nbsp;
            </Typography>
            <Typography category="Default" variant="Body">
              {t("Try to wait 2-3 minutes if you just registered the camera.")}
            </Typography>
          </div>

          <div className={classes.divider} />
          <div className={classes.typography}>
            <Typography category="Default" variant="Body">
              ·&nbsp;&nbsp;
            </Typography>
            <Typography category="Default" variant="Body">
              {t("Try to check the Cloud Connection Settings again_")}
            </Typography>
          </div>
        </div>
      }
      RButton={t("OK")}
      onClickPositive={onClose}
    />
  );
};
