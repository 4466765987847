import React, { useEffect, useState } from "react";

import SettingsIcon from "@material-ui/icons/Settings";
import Divider from "@material-ui/core/Divider";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { PopperPlacementType } from "@material-ui/core/Popper";
import {
  DashcamSettings,
  Menu,
  Typography,
  WebMenuItem,
  Trash,
  EditIcon,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { CAMERA } from "../../features/Camera/slice";
import { useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import { RootState } from "../../features/store";
import { findNewFw } from "../../utils/Firmware";
import { LightColors, ICameraInfo, AvailableFw, IGeofence } from "@thingsw/pitta-modules";
import { ReactSVG } from "react-svg";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    // display: "block",
    // fill: LightColors.secondary["11"],
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  delete: {
    color: LightColors.secondary["11"],
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  newDiv: {
    backgroundColor: LightColors.secondary["11"],
    borderRadius: 12,
    width: 43,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
  priorityIcon: {
    width: 24,
    height: 24,
  },
}));

export interface CameraMenuProps<T> {
  open: boolean;
  camera?: ICameraInfo;
  hideDivider?: boolean;
  anchorRef?: React.RefObject<T>;
  /**
   * Popper placement.
   */
  placement?: PopperPlacementType;  

  onClickAway: React.MouseEventHandler<Document>;
  onRename?: (() => void) | null;
  onSettings?: (() => void) | null;
  onUpdate?: ((fw?: AvailableFw) => void) | null;
  onSpeedPriority?: (() => void) | null;
  onDelete?: (() => void) | null;
  onRebootCamera?: (() => void) | null;

  dense?: boolean;
  geofence?: IGeofence;
  isAdjustPriority: boolean;
}

type CameraMenuComponent<T = HTMLElement> = React.FC<CameraMenuProps<T>>;

export const CameraMenu: CameraMenuComponent = ({
  open,
  camera,
  hideDivider,
  anchorRef,
  placement,
  onClickAway,
  onRename,
  onSettings,
  onUpdate,  
  onSpeedPriority,
  onDelete,
  onRebootCamera,
  dense,
  geofence,
  isAdjustPriority,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const firmwares = useSelector((state: RootState) => state[CAMERA].firmwares);
  const [availableNewFw, setAvailableNewFw] = useState<AvailableFw>();

  useEffect(() => {
    if (camera) {
      const newFw = findNewFw(camera, firmwares);
      setAvailableNewFw(newFw);
      // setAvailableNewFw(!!(newFw.blackvue || newFw.dmc200));
    }
  }, [camera, firmwares]);

  return (
    <Menu
      open={open}
      anchorEl={anchorRef?.current}
      onClickAway={onClickAway}
      placement={placement}
      modifiers={{ preventOverflow: { enabled: false } }}
      style={{ zIndex: 101, position: "absolute" }}
      dense={dense}
    >
      {onRename !== undefined && (
        <WebMenuItem
          disabled={onRename === null || camera?.valid === "invalid"}
          startIcon={<EditIcon />}
          onClick={onRename === null ? undefined : onRename}
          dense={dense}
        >
          {t("Rename")}
        </WebMenuItem>
      )}
      {onSettings !== undefined && (
        <WebMenuItem
          disabled={onSettings === null || camera?.valid === "invalid"}
          startIcon={<SettingsIcon fontSize="small" />}
          onClick={onSettings === null ? undefined : onSettings}
          dense={dense}
        >
          {t("Settings")}
        </WebMenuItem>
      )}
      {onUpdate !== undefined && (
        <WebMenuItem
          startIcon={
            <div className={classes.icon}>
              <DashcamSettings fontSize="small" />
            </div>
          }
          endIcon={
            availableNewFw && (
              <div className={classes.newDiv}>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["0"]}
                >
                  NEW
                </Typography>
              </div>
            )
          }
          disabled={onUpdate === null || camera?.active === "off"}
          onClick={
            onUpdate === null ? undefined : () => onUpdate(availableNewFw)
          }
          dense={dense}
        >
          {t("Remote firmware update")}
        </WebMenuItem>
      )}

      {onRebootCamera !== undefined && (
        <WebMenuItem
          startIcon={<RefreshIcon fontSize="small" />}
          disabled={onRebootCamera === null || camera?.active === "off"}
          onClick={onRebootCamera === null ? undefined : onRebootCamera}
          dense={dense}
        >
          {t("Reboot Camera")}
        </WebMenuItem>
      )}

      {onDelete !== undefined && !hideDivider && (
        <Divider className={classes.divider} />
      )}

      {(onSpeedPriority
        && isAdjustPriority) && (
          <WebMenuItem
            disabled={!onSpeedPriority}
            startIcon={
              <ReactSVG
                src="/images/geofence-priority.svg"
                className={classes.priorityIcon}
              />
            }
            onClick={onSpeedPriority}
            dense={dense}
          >
            {t("Set Speed Priority")}
          </WebMenuItem>
        )}

      {onDelete !== undefined && (
        <WebMenuItem
          disabled={onDelete === null}
          className={classes.delete}
          startIcon={<Trash htmlColor={LightColors.secondary["11"]} />}
          onClick={onDelete === null ? undefined : onDelete}
          dense={dense}
        >
          {t("Delete")}
        </WebMenuItem>
      )}
    </Menu>
  );
};
