import { BatteryModel } from "@thingsw/pitta-modules";
import { calcRemainingTime } from "./calcRemainingTime";

enum ChargingState {
  NotUse,
  Charging,
  ChargingComplete,
  Discharging,
  LowVoltage,
  HighVoltage,
  HighTemperature,
  NotWorking,
}

export const parseBatteryInfo = (batteryInfo: {
  psn: string;
  version: string;
  ssid: string;
  value: string;
  model: BatteryModel;
}) => {
  const { value: data, version, model } = batteryInfo;
  const decodedBinaryString = atob(data);
  const firmwareVersion = parseFloat(version);

  // 문자열을 ArrayBuffer로 변환
  const arrayBuffer = new ArrayBuffer(decodedBinaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  const lengthTwoArrayBuffer = new ArrayBuffer(2);
  const uint16Array = new Uint16Array(lengthTwoArrayBuffer);

  for (let i = 0; i < decodedBinaryString.length; i++) {
    uint8Array[i] = decodedBinaryString.charCodeAt(i);
  }

  // 배터리 용량은 -100을 적용
  let percent = uint8Array[4];
  if (firmwareVersion >= 1.1) percent -= 100;
  if (percent >= 100) percent = 100;

  let temperature = uint8Array[9];

  if (temperature >= 100) {
    temperature -= 100;
  } else {
    // 펌웨어 1.10부터 영하의 경우 -15를 해줌.
    if (firmwareVersion >= 1.1) {
      temperature -= 15;
    }
    temperature *= -1;
  }

  const batteryVoltage = uint8Array[10] / 10;
  let inputVoltage;
  let outputVoltage;
  const accVoltage = uint8Array[13] / 10;
  let isUSBConnected = false;
  let config1 = uint8Array[20];

  let isInputPowerCutOff = false;
  let isCharging = false;
  let chargeSystem = "";

  let isHighVoltage = false;
  let isLowVoltage = false;

  // 1.6 버전을 기준으로 데이터를 분기 처리
  if (firmwareVersion >= 1.6) {
    uint16Array[0] =
      (decodedBinaryString.charCodeAt(23) << 8) |
      decodedBinaryString.charCodeAt(24);

    inputVoltage = uint16Array[0] / 10;

    uint16Array[0] =
      (decodedBinaryString.charCodeAt(25) << 8) |
      decodedBinaryString.charCodeAt(26);

    outputVoltage = uint16Array[0] / 10;

    let t = uint8Array[27];

    isCharging = inputVoltage > 0;

    const isBatteryCargingStatus = getConfigChargingState(
      config1,
      inputVoltage * 10
    );

    if (isBatteryCargingStatus === ChargingState.LowVoltage) {
      isLowVoltage = true;
      isInputPowerCutOff = true;
      isCharging = false;
    }

    if (isBatteryCargingStatus === ChargingState.HighVoltage) {
      isHighVoltage = true;
      isInputPowerCutOff = true;
      isCharging = false;
    }

    if (uint8Array.length > 23) {
      // 충전 시스템 확인
      if ((t & 0b00000010) !== 0) {
        chargeSystem = "24VDC";
      } else {
        chargeSystem = "12VDC";
      }

      let v = (t >> 4) & 0b00000011;

      // 과전압, 저전압 확인
      if (v === 2) {
        isHighVoltage = true;
        isInputPowerCutOff = true;
        isCharging = false;
      } else if (v === 1) {
        isLowVoltage = true;
        isInputPowerCutOff = true;
        isCharging = false;
      }
    }

    // 1.6 버전 이상, B130X 모델일 경우 USB 연결 여부 확인
    if (model === BatteryModel.B130X) {
      isUSBConnected = uint8Array[22] > 0 ? true : false;
    }
  } else {
    inputVoltage = uint8Array[11] / 10;
    outputVoltage = uint8Array[12] / 10;
    isCharging = inputVoltage > 0;

    const chargingState = getConfigChargingState(Number(config1), -1);

    if (chargingState === ChargingState.LowVoltage) {
      isLowVoltage = true;
      isInputPowerCutOff = true;
      isCharging = false;
    }

    if (chargingState === ChargingState.HighVoltage) {
      isHighVoltage = true;
      isInputPowerCutOff = true;
      isCharging = false;
    }
  }

  uint16Array[0] =
    (decodedBinaryString.charCodeAt(5) << 8) |
    decodedBinaryString.charCodeAt(6);

  const dischargeTime = uint16Array[0];

  uint16Array[0] =
    (decodedBinaryString.charCodeAt(7) << 8) |
    decodedBinaryString.charCodeAt(8);

  const chargeTime = uint16Array[0];

  uint16Array[0] =
    (decodedBinaryString.charCodeAt(14) << 8) |
    decodedBinaryString.charCodeAt(15);

  const chargingCurrent = uint16Array[0];

  uint16Array[0] =
    (decodedBinaryString.charCodeAt(16) << 8) |
    decodedBinaryString.charCodeAt(17);

  const dischargeCurrent = uint16Array[0];

  uint16Array[0] =
    (decodedBinaryString.charCodeAt(18) << 8) |
    decodedBinaryString.charCodeAt(19);

  const capacity = uint16Array[0];

  const isFullCharge = percent === 100;
  const isLessThanOnePercent = percent <= 1;
  const isLowCapacity = percent < 20;
  const isLowTemperature = temperature < 0;
  let isOutputPowerCutOff = false;
  let isHighTemperature = false;

  const remainingTime = calcRemainingTime(dischargeTime);
  const chargingTime = calcRemainingTime(chargeTime);

  uint16Array[0] =
    (decodedBinaryString.charCodeAt(20) << 8) |
    decodedBinaryString.charCodeAt(21);

  checkBits(uint16Array);

  const isOnBeep: "off" | "on" =
    ((uint16Array[0] >> 4) & 1) === 0 ? "off" : "on";

  if (outputVoltage <= 11.2) {
    isOutputPowerCutOff = true;
  }

  if (temperature >= 80) {
    isInputPowerCutOff = true;
    isHighTemperature = true;
    isCharging = false;
  }

  if (temperature < 0 && isCharging) {
    isInputPowerCutOff = true;
  }

  let numberOfBattery = 0;

  if (model === BatteryModel.B124X) {
    numberOfBattery = (capacity - 6000) / 6600;
  }

  const isLessFiveBatteryCapacity = percent < 5;

  return {
    percent,
    dischargeTime,
    chargeTime,
    temperature,
    batteryVoltage,
    inputVoltage,
    outputVoltage,
    accVoltage,
    chargingCurrent,
    dischargeCurrent,
    isCharging,
    isFullCharge,
    isLessThanOnePercent,
    remainingTime,
    isOutputPowerCutOff,
    isInputPowerCutOff,
    isLowCapacity,
    isLowTemperature,
    isHighTemperature,
    chargingTime,
    isUSBConnected,
    isOnBeep,
    chargeSystem,
    isHighVoltage,
    isLowVoltage,
    capacity,
    numberOfBattery,
    isLessFiveBatteryCapacity,
  };
};

function checkBits(uint16Array: Uint16Array) {
  for (let i = 0; i < uint16Array.length; i++) {
    let uint16Value = uint16Array[i];

    for (let j = 15; j >= 0; j--) {
      // 1비트씩 확인하여 콘솔에 출력
      let bit = (uint16Value >> j) & 1;
      console.log(`uint16Array[${i}] - Bit ${j}: ${bit}`);
    }
  }
}

function getConfigChargingState(value: number, voltage: number): ChargingState {
  const b1 = value & 0b00000001;
  const b2 = value & 0b00000010;
  const b3 = value & 0b00000100;
  const b4 = value & 0b00001000;

  // 배터리 사용안함
  if (b4 === 0 && b3 === 0 && b2 === 0 && b1 === 0) {
    return ChargingState.NotUse;
  }

  // 충전중
  if (b4 === 0 && b3 === 0 && b2 === 0 && b1 !== 0) {
    return ChargingState.Charging;
  }

  // 완전충전
  if (b4 === 0 && b3 === 0 && b2 !== 0 && b1 === 0) {
    return ChargingState.ChargingComplete;
  }

  // 방전중
  if (b4 === 0 && b3 === 0 && b2 !== 0 && b1 !== 0) {
    return ChargingState.Discharging;
  }

  // 전압낮음
  if (b4 === 0 && b3 !== 0 && b2 === 0 && b1 === 0) {
    // 기존버전
    if (voltage === -1) {
      return ChargingState.LowVoltage;
    } else {
      // v160이상 트럭에서 이용
      if (voltage < 250) {
        // 저전압 경고
        return ChargingState.LowVoltage;
      } else {
        // 고전압 경고
        return ChargingState.HighVoltage;
      }
    }
  }

  // 고온경고
  if (b4 === 0 && b3 !== 0 && b2 !== 0 && b1 !== 0) {
    return ChargingState.HighTemperature;
  }

  // 동작정지
  if (b4 === 0 && b3 !== 0 && b2 === 0 && b1 !== 0) {
    return ChargingState.NotWorking;
  }

  // 설정없음
  if (b4 !== 0 && b3 !== 0 && b2 !== 0 && b1 !== 0) {
    return ChargingState.NotUse;
  }

  return ChargingState.NotUse;
}
