import React, { ChangeEvent, useState } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
  updateSyncErrors,
} from "redux-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import validator from "./validator";
import { FormControlLabel, FormHelperText } from "@material-ui/core";
import { Button, CheckBox, Typography } from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { TFunction } from "i18next";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import {
  ISignupForm,
  LightColors,
  TermsConditions,
  Privacy_Policy,
  InformationWe,
  TermsOfLocation,
} from "@thingsw/pitta-modules";

interface FieldProps {
  label?: string;
  helperText?: string;
  value?: string;
  t: TFunction;
}

const schema = yup.object().shape({
  firstName: yup.string().trim().required("Enter first name"),
  lastName: yup.string().trim().required("Enter last name"),
  tos: yup
    .boolean()
    .oneOf([true], "Please agree to_")
    .required("Please agree to_"),
  age: yup
    .boolean()
    .oneOf([true], "Please agree to_")
    .required("You must be 14 years_"),
});

const renderEmailField = ({
  label,
  input,
  helperText,
  meta: { touched, invalid, error },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && invalid}
      helperText={(touched && t(error)) || helperText}
      {...input}
      {...custom}
    />
  );
};
const CheckboxFormControlLabel = withStyles({
  root: {
    marginLeft: -5,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
    "&:first-child": {
      marginBottom: 6,
    },
  },
  label: {
    padding: "3px 0 0 2px",
  },
})(FormControlLabel);

const useStyles = makeStyles((theme: Theme) => ({
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  agreeDiv: {
    display: "flex",
    flexDirection: "column",
  },
  noticeDiv: {
    textAlign: "center",
    marginBottom: theme.spacing(2.5),
  },
}));

const SocialSignin = (props: InjectedFormProps<ISignupForm, {}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, change, touch } = props;
  const { t } = useTranslation();
  const { loading } = useSelector((state: RootState) => state[USER]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { i18n } = useTranslation();
  const lang =
    i18n.language ?? navigator.language.toLowerCase().substring(0, 2);
  const [CheckList, setCheckList] = useState<any>([]);

  const getLink = (links: any) => {
    let link;
    switch (lang) {
      case "ko":
        link = links.ko;
        break;
      case "jp":
        link = links.jp;
        break;
      default:
        link = links.en;
    }
    return link;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 30) {
      setFirstName(value);
      setLastName(value);
    }
  };

  const handleSignup = () => {
    dispatch(submit("SocialSigninForm"));
  };

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("SocialSigninForm"));
    }
  };

  const handleAllCheck = () => {
    if (CheckList.length === 3) {
      setCheckList([]);
      change("tos", false);
      change("age", false);
      change("subscribe", false);
      dispatch(
        updateSyncErrors(
          "SignupForm",
          {
            tos: "Please agree to_",
            age: "You must be 14 years_",
          },
          undefined
        )
      );
    } else {
      setCheckList(["tos", "age", "subscribe"]);
      change("tos", true);
      change("age", true);
      change("subscribe", true);
      dispatch(
        updateSyncErrors(
          "SignupForm",
          {
            tos: undefined,
            age: undefined,
          },
          undefined
        )
      );
    }
    touch(...["tos", "age"]);
  };

  const onChangeEach = (e: any, input: any) => {
    if (e.target.checked) {
      setCheckList([...CheckList, input.name]);
      input.onChange(true);
    } else {
      setCheckList(
        CheckList.filter((checkedId: any) => checkedId !== input.name)
      );
      input.onChange(false);
    }
    touch(...[input.name]);
  };

  const renderCheckField = ({
    label,
    input,
    meta: { touched, invalid, error },
    t,
  }: WrappedFieldProps & FieldProps) => {
    return (
      <div>
        <CheckboxFormControlLabel
          control={
            <CheckBox
              name={input.name}
              color="primary"
              value={input.value}
              onChange={(e) => {
                onChangeEach(e, input);
              }}
              checked={input.value ? true : false}
            />
          }
          label={label}
        />
        {touched && error && input.value === false && (
          <FormHelperText
            style={{ color: LightColors.secondary["11"], marginLeft: 28 }}
          >
            {touched && t(error)}
          </FormHelperText>
        )}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleEnterkey}>
      <div className={classes.marginB3}>
        <Field
          name="firstName"
          label={t("First name")}
          component={renderEmailField}
          onChange={handleNameChange}
          value={firstName}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 30);
          }}
          t={t}
          autoFocus
        />
      </div>
      <div className={classes.marginB3}>
        <Field
          name="lastName"
          label={t("Last name")}
          component={renderEmailField}
          onChange={handleNameChange}
          value={lastName}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 30);
          }}
          t={t}
        />
      </div>
      <div className={classes.marginB3}>
        <Field
          disabled
          name="email"
          label={t("Email")}
          component={renderEmailField}
          t={t}
        />
      </div>
      <div className={classes.noticeDiv}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {t("Profile information_")}
        </Typography>
      </div>

      <Button
        fullWidth
        color="primary"
        onClick={handleSignup}
        loading={loading}
        style={{ marginBottom: 20 }}
      >
        {t("Sign up")}
      </Button>

      <div className={classes.agreeDiv}>
        <CheckboxFormControlLabel
          control={
            <CheckBox
              name="checkall"
              color="primary"
              onChange={handleAllCheck}
              checked={CheckList.length === 3 ? true : false}
            />
          }
          label={
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={LightColors.primary["1"]}
            >
              {t("I have read and agree_")}
            </Typography>
          }
        />
        <Field
          name="tos"
          value="terms"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("I agree to the_")}{" "}
              <a
                href={getLink(TermsConditions)}
                target="_blank"
                rel="noreferrer"
                style={{ color: LightColors.primary["7"] }}
              >
                {t("Terms of service")}
              </a>
              ,{" "}
              <a
                href={getLink(Privacy_Policy)}
                target="_blank"
                rel="noreferrer"
                style={{ color: LightColors.primary["7"] }}
              >
                {t("Privacy policy")}
              </a>
              ,{" "}
              <a
                href={getLink(InformationWe)}
                target="_blank"
                rel="noreferrer"
                style={{ color: LightColors.primary["7"] }}
              >
                {t("Information We Collect_")}
              </a>{" "}
              {t("And")}{" "}
              <a
                href={getLink(TermsOfLocation)}
                target="_blank"
                rel="noreferrer"
                style={{ color: LightColors.primary["7"] }}
              >
                {t("Terms of Location-Based_")}
              </a>
              ({t("Required")})
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />

        <Field
          name="age"
          value="Age"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("14 years old_")}({t("Required")})
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />

        <Field
          name="subscribe"
          value="Subscribe"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Subscribe to newsletter")}({t("Optional")})
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />
      </div>
    </form>
  );
};

const SocialSigninForm = reduxForm<ISignupForm, {}>({
  form: "SocialSigninForm",
  asyncValidate: validator(schema),
})(SocialSignin);

export default SocialSigninForm;
